import { cn } from '../../../libs/cn'
import { CSSProperties } from 'react'

interface LegendProps {
  classColor?: string
  style?: CSSProperties
  type: LegendItemType | undefined
}

export type LegendItemType = 'bar' | 'line' | 'dashed'

export const LegendItem = (props: LegendProps) => {
  let content = undefined
  switch (props.type) {
    case 'line':
      content = <div className={cn('h-1 w-4 rounded-sm', props.classColor)} style={props.style} />
      break
    case 'dashed':
      content = (
        <>
          <div className="w-1 rounded-full bg-fg-brand-primary opacity-30" style={{ height: '3px', ...props.style }} />
          <div className="w-1 rounded-full bg-fg-brand-primary opacity-30" style={{ height: '3px', ...props.style }} />
        </>
      )
      break
    default:
      content = <div className={cn('size-2 rounded-sm', props.classColor)} style={props.style} />
      break
  }

  return (
    <div className="flex items-center justify-center gap-0.5 rounded-full border border-border-secondary p-1">
      {content}
    </div>
  )
}
