import { AccountDetails } from '@/api/dashboard'
import { ButtonIcon } from '@/ui'
import { Edit03 } from '@/icons'
import i18next from 'i18next'
import { KeyValue } from '@/types'

interface VerifyCardProps {
  variant: keyof AccountDetails
  data: AccountDetails
  onEdit?: () => void
}

const getCountryLabel = (key: string) => {
  const countries = i18next.t('countries', { returnObjects: true }) as KeyValue
  return (countries[key] || key) as string
}

/**
 * TODO: Remove this component and make each card a widget and use the VerifyDataCard component @ds.pankov
 */
export const VerifyCard = ({ variant, data, onEdit }: VerifyCardProps) => (
  <div className="grid grid-cols-[1fr_auto] gap-6 break-words rounded-xl border-2 border-border-secondary bg-fg-secondary p-5 text-text-secondary">
    {variant === 'authorized_signatory' && (
      <div className="overflow-hidden">
        <p className="mb-1.5 text-paragraph-md font-medium">
          {[data.authorized_signatory?.first_name, data.authorized_signatory?.last_name].filter(Boolean).join(' ')}
        </p>
        <div className="mb-1.5 h-px bg-border-secondary" />
        <p className="mb-1.5 text-paragraph-sm">
          {[
            data.authorized_signatory?.country && getCountryLabel(data.authorized_signatory?.country),
            data.authorized_signatory?.job_title,
          ]
            .filter(Boolean)
            .join(', ')}
        </p>
        <p className="text-paragraph-sm">{data.authorized_signatory?.email}</p>
      </div>
    )}

    {variant === 'company_legal' && (
      <div className="overflow-hidden">
        <p className="mb-1.5 text-paragraph-md font-medium">{data.company_legal?.business_name}</p>
        <p className="mb-1.5 text-paragraph-sm">{data.company_legal?.tin}</p>
        <p className="mb-1.5 text-paragraph-sm">{data.company_legal?.dba}</p>
        <div className="mb-1.5 h-px bg-border-secondary" />
        {data.company_legal?.country && (
          <p className="mb-1.5 text-paragraph-sm">{getCountryLabel(data.company_legal?.country)}</p>
        )}
        <p className="mb-1.5 text-paragraph-sm">{data.company_legal?.address1}</p>
        <p className="mb-1.5 text-paragraph-sm">{data.company_legal?.address2}</p>
        <p className="mb-1.5 text-paragraph-sm">
          {[data.company_legal?.city, data.company_legal?.state, data.company_legal?.postal].filter(Boolean).join(', ')}
        </p>
        <p className="text-paragraph-sm">{data.company_legal?.phone}</p>
      </div>
    )}

    {onEdit && (
      <ButtonIcon variant="secondary-gray" size="sm" onClick={onEdit}>
        <Edit03 size={14} />
      </ButtonIcon>
    )}
  </div>
)
