import { CreateItemBannerActionNode } from '../../../../api/dashboard'
import { findPrevEntityNode } from '../../util'
import i18next from 'i18next'
import { DescContext } from './types'
import { getContextItemName } from '@/layouts/campaigns/blocks/descriptions/getContextItemName'

export function getCreateItemBannerActionNode(node: CreateItemBannerActionNode, context: DescContext) {
  let desc = node.title
  let itemId = ''
  if (node.link_created_offer) {
    let prevNode = findPrevEntityNode(context.graph.nodes!, node.id)
    if (prevNode) {
      itemId = prevNode.item_id!
    }
  } else if (node.config.featured_item_id) {
    itemId = node.config.featured_item_id
  }

  let item = context.items.find(it => it.id == itemId)
  if (item) {
    desc = item.name
  }

  if (node.use_event_item) {
    desc = getContextItemName(context.type)
  }

  return [node.title || i18next.t('campaign.node-type.CreateItemBannerActionNode'), desc]
}
