import i18next from 'i18next'
import {
  Button,
  DrawerContent,
  DrawerFooter,
  DrawerTopbar,
  FieldGroup,
  FieldValidationMessage,
  Input,
  InputExtraMark,
  ModalConfirm,
  Select,
  useModal,
} from '../../../components/ui'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  Campaign,
  CampaignEventType,
  ConditionNode,
  CouponRead,
  CouponType,
  Expression,
  ResourceState,
  Token,
} from '../../../api/dashboard'
import { ResponseError, getErrorText } from '../../../api'
import { useParams } from 'react-router-dom'
import { Settings } from '../../../components/ui/Settings'
import Divider from '../../../components/Divider'
import { useCouponUpdateMutate } from '../api/useCouponUpdateMutate'
import { useCouponCreateMutate } from '../api/useCouponCreateMutate'
import { useCouponDeleteMutate } from '../api/useCouponDeleteMutate'
import { CheckBoxContainer } from '../../../components/ui/CheckBoxContainer'
import { SelectSkuItem } from '../../campaigns/components/SelectSkuItem/SelectSkuItem'
import dayjs from 'dayjs'
import StyledDatePicker from '../../../components/StyledDatePicker'
import { ErrorMessage } from '@hookform/error-message'
import { CampaignContext, EditorMode, ICampaignContext } from '../../campaigns/Context'
import ConditionEditor from '../../campaigns/expr/ConditionEditor'
import { useGameSettingsQuery } from '../../../api/useGameSettingsQuery'
import { useGameItems } from '../../../api/useGameItems'
import { validateConditionTokens } from '../../campaigns/validation'
import { getTokenGroups } from '../../campaigns/util'
import { InputCopyButton } from '@/components/ui/InputCopyButton'

interface CouponSettingsProps {
  onClose: (saved: boolean) => void
  coupon: CouponRead
}

const defaultValue: CouponRead = {
  code: '',
  type: CouponType.Discount,
  name: '',
} as CouponRead

export default function CouponBaseSettings(props: CouponSettingsProps) {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [conditionError, setConditionError] = useState<string>('')
  const [errorTokens, setErrorTokens] = useState<Token[]>([])

  const { mutateAsync: updateMutateAsync } = useCouponUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useCouponCreateMutate(companyId, gameId)
  const { mutateAsync: deleteMutateAsync } = useCouponDeleteMutate(companyId, gameId)

  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)

  const { data: items = [] } = useGameItems(companyId, gameId, {
    limit: 100,
    state: ResourceState.Active,
  })

  const onConfirmDelete = async () => {
    await deleteMutateAsync({ id: props.coupon.id })
    reset()
    props.onClose(true)
    setErrorMessage('')
  }

  const {
    register,
    reset,
    watch,
    formState: { isDirty, errors },
    setFocus,
    control,
    handleSubmit,
  } = useForm<CouponRead>({
    mode: 'onChange',
    defaultValues: { ...defaultValue, ...props.coupon },
  })

  useEffect(() => {
    reset({ ...defaultValue, ...props.coupon })
  }, [props.coupon])

  const onSubmit: SubmitHandler<CouponRead> = async data => {
    setConditionError('')
    setErrorMessage('')

    if (data.requirements_expression?.tokens?.length) {
      let { error, errorTokens: errTokens } = validateConditionTokens(
        getTokenGroups(data.requirements_expression?.tokens || []),
        getCampaignContext(),
      )
      setErrorTokens(errTokens || [])
      setConditionError(error)

      if (errTokens?.length) {
        return
      }
    } else {
      data.requirements_expression = null as unknown as Expression
    }

    switch (data.type) {
      case CouponType.FreeItem:
        data.bonus_percent = undefined
        data.discount_percent = undefined
        data.applicable_item_ids = undefined
        data.bonus_percent = undefined
        data.discount_percent = undefined
        data.min_amount = undefined
        data.is_stackable_with_bonus_coupons = undefined
        data.stackable_bonus_limit_percent = undefined
        data.is_stackable_with_discount_coupons = undefined
        data.stackable_discount_limit_percent = undefined
        break
      case CouponType.Bonus:
        data.discount_percent = undefined
        data.item_id = undefined
        data.is_stackable_with_discount_coupons = undefined
        data.stackable_discount_limit_percent = undefined
        break
      case CouponType.Discount:
        data.bonus_percent = undefined
        data.item_id = undefined
        data.is_stackable_with_bonus_coupons = undefined
        data.stackable_bonus_limit_percent = undefined
        break
    }

    if (!data.is_stackable_with_discount_coupons) {
      data.stackable_discount_limit_percent = undefined
    }

    if (!data.is_stackable_with_bonus_coupons) {
      data.stackable_bonus_limit_percent = undefined
    }

    try {
      if (props.coupon?.id) {
        await updateMutateAsync({
          id: data.id,
          update: data,
        })
      } else {
        await createMutateAsync({
          create: data,
        })
      }
    } catch (e) {
      setErrorMessage(getErrorText(e as ResponseError))
      return
    }
    setErrorMessage('')
    props.onClose(true)
  }

  const onClose = () => {
    setErrorMessage('')
    props.onClose(false)
  }

  useEffect(() => {
    if (setFocus && props.coupon) {
      setTimeout(() => {
        setFocus('name')
      }, 10)
    }
  }, [setFocus, props.coupon])

  const getCampaignContext = () => {
    return {
      items: items,
      campaign: {
        type: CampaignEventType.CustomEvent,
      } as unknown as Campaign,
      playerCustomAttributes: gameSettings?.player_attributes || [],
      segments: [],
      readOnly: false,
      historyMode: false,
      mode: EditorMode.StoreItem,
    } as unknown as ICampaignContext
  }

  const openDeleteModal = useModal<NonNullable<unknown>>(props2 => (
    <ModalConfirm
      color={'error'}
      confirmButtonText={i18next.t('remove')}
      subMessage={i18next.t('confirm.text', { name: props.coupon?.name })}
      onConfirm={() => onConfirmDelete()}
      {...props2}
    />
  ))

  const type = watch('type') || props.coupon?.type || defaultValue.type
  const applicable_item_ids = watch('applicable_item_ids')
  const min_amount = watch('min_amount')
  const max_redemptions = watch('max_redemptions')
  const code = watch('code')

  const renderGeneral = () => {
    return (
      <Settings text={i18next.t('coupon.general')}>
        <FieldGroup label={i18next.t('coupon.name')}>
          <Input
            {...register('name', {
              required: i18next.t('validation.required'),
              maxLength: 300,
            })}
          />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('coupon.code')} caption={i18next.t('coupon.code.validation')}>
          {props.coupon?.id ? (
            <InputCopyButton value={code} />
          ) : (
            <>
              <Controller
                control={control}
                name="code"
                rules={{
                  required: i18next.t('validation.required'),
                  pattern: { value: /^[A-Z0-9]+$/g, message: i18next.t('coupon.code.validation') },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={v => {
                      field.onChange(v.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ''))
                    }}
                    disabled={!!props.coupon?.id}
                    autoComplete="off"
                  />
                )}
              />
              <ErrorMessage
                name="code"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </>
          )}
        </FieldGroup>

        <FieldGroup label={i18next.t('coupon.type')}>
          <Controller
            disabled={!!props.coupon?.id}
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={Object.values(CouponType).map(it => ({
                  value: it,
                  children: i18next.t(`coupon.type.${it}`),
                }))}
              />
            )}
          />
        </FieldGroup>
      </Settings>
    )
  }

  const renderBenefits = () => {
    return (
      <Settings text={i18next.t('coupon.benefits')}>
        {type == CouponType.Bonus && (
          <FieldGroup label={i18next.t('coupon.bonus')}>
            <Input
              disabled={!!props.coupon?.id}
              type="number"
              {...register('bonus_percent', {
                required: i18next.t('validation.required'),
                min: 1,
                max: 1000,
              })}
              extraLeft={<InputExtraMark>%</InputExtraMark>}
            />
            <ErrorMessage
              name="bonus_percent"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>
        )}

        {type == CouponType.Discount && (
          <FieldGroup label={i18next.t('coupon.discount')}>
            <Input
              disabled={!!props.coupon?.id}
              type="number"
              {...register('discount_percent', {
                required: i18next.t('validation.required'),
                min: { value: 1, message: i18next.t('coupon.discount_percent.validation') },
                max: { value: 99, message: i18next.t('coupon.discount_percent.validation') },
              })}
              extraLeft={<InputExtraMark>%</InputExtraMark>}
            />
            <ErrorMessage
              name="discount_percent"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>
        )}

        {type == CouponType.FreeItem && (
          <FieldGroup label={i18next.t('coupon.item')}>
            <Controller
              control={control}
              name="item_id"
              rules={{ required: i18next.t('validation.required') }}
              render={({ field }) => <SelectSkuItem {...field} />}
            />
            <ErrorMessage
              name="item_id"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>
        )}
      </Settings>
    )
  }

  const renderBenefitsCompatibility = () => {
    return (
      <Settings text={i18next.t('coupon.benefits-compatibility')}>
        {type == CouponType.Bonus && (
          <Controller
            name="is_stackable_with_bonus_coupons"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CheckBoxContainer
                checked={value}
                onChange={e => onChange(e.target.checked)}
                label={i18next.t('coupon.benefits.stackable')}
              >
                <FieldGroup caption={i18next.t('coupon.stackable-limit-desc')}>
                  <Input
                    extraLeft={<InputExtraMark>%</InputExtraMark>}
                    type="number"
                    {...register('stackable_bonus_limit_percent', {
                      required: value ? i18next.t('validation.required') : false,
                      min: 1,
                      max: 100,
                    })}
                  />
                  <ErrorMessage
                    name="stackable_bonus_limit_percent"
                    errors={errors}
                    render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                  />
                </FieldGroup>
              </CheckBoxContainer>
            )}
          />
        )}

        {type == CouponType.Discount && (
          <Controller
            name="is_stackable_with_discount_coupons"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CheckBoxContainer
                checked={value}
                onChange={e => onChange(e.target.checked)}
                label={i18next.t('coupon.stackable-discount-code')}
              >
                <FieldGroup caption={i18next.t('coupon.stackable-discount-limit-desc')}>
                  <Input
                    extraLeft={<InputExtraMark>%</InputExtraMark>}
                    {...register('stackable_discount_limit_percent', {
                      required: value ? i18next.t('validation.required') : false,
                      min: 1,
                      max: 100,
                    })}
                  />
                  <ErrorMessage
                    name="stackable_discount_limit_percent"
                    errors={errors}
                    render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                  />
                </FieldGroup>
              </CheckBoxContainer>
            )}
          />
        )}
      </Settings>
    )
  }

  const renderGeneralLimitations = () => {
    return (
      <Settings text={i18next.t('coupon.general-limitations')}>
        <Controller
          name="max_redemptions"
          control={control}
          rules={{ required: max_redemptions != undefined ? i18next.t('validation.required') : false }}
          render={({ field: { onChange, value } }) => (
            <CheckBoxContainer
              caption={i18next.t('coupon.reusable-desc')}
              label={i18next.t('coupon.reusable')}
              checked={value != undefined}
              onChange={v => {
                onChange(v.target.checked ? 1 : null)
              }}
            >
              <Input
                type="number"
                {...register('max_redemptions', {
                  min: { value: 1, message: i18next.t('coupon.max_redemptions.validation') },
                  max: { value: 100_000_000, message: i18next.t('coupon.max_redemptions.validation') },
                })}
              />
              <ErrorMessage
                name="max_redemptions"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </CheckBoxContainer>
          )}
        />

        {type != CouponType.FreeItem && (
          <>
            <Divider />
            <Controller
              name="applicable_item_ids"
              control={control}
              rules={{ required: applicable_item_ids ? i18next.t('validation.required') : false }}
              render={({ field: { onChange, value } }) => (
                <CheckBoxContainer
                  checked={!!value}
                  onChange={v => {
                    onChange(v.target.checked ? [] : undefined)
                  }}
                  label={i18next.t('coupon.sku-bound')}
                  caption={i18next.t('coupon.sku-bound-desc')}
                >
                  <SelectSkuItem
                    multiselect
                    value={value || []}
                    onChange={v => {
                      onChange(v)
                    }}
                  />
                  <ErrorMessage
                    name="applicable_item_ids"
                    errors={errors}
                    render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                  />
                </CheckBoxContainer>
              )}
            />

            <Divider />

            <Controller
              name="min_amount"
              rules={{ required: min_amount == null ? false : i18next.t('validation.required') }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <CheckBoxContainer
                  checked={value != null}
                  onChange={v => {
                    onChange(v.target.checked ? 100 : null)
                  }}
                  label={i18next.t('coupon.min_amount')}
                >
                  <Input
                    type="number"
                    value={isNaN(value!) ? '' : value! / 100}
                    extraLeft={<InputExtraMark>$</InputExtraMark>}
                    onChange={e => {
                      let v = parseFloat(e.target.value)
                      onChange(Math.max(1, v * 100))
                    }}
                  />
                  <ErrorMessage
                    name="min_amount"
                    errors={errors}
                    render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                  />
                </CheckBoxContainer>
              )}
            />
          </>
        )}
      </Settings>
    )
  }

  const renderTimeLimitations = () => {
    return (
      <Settings text={i18next.t('coupon.limit-limitations')}>
        <Controller
          name="expires_at"
          control={control}
          render={({ field: { onChange, value } }) => (
            <CheckBoxContainer
              checked={!!value}
              onChange={v => {
                onChange(v.target.checked ? dayjs().add(1, 'day').valueOf() / 1000 + 23 * 3600 + 3599 : null)
              }}
              label={i18next.t('campaign.block.ItemDiscountOfferActionNode.limit-timer')}
            >
              <StyledDatePicker
                label={i18next.t('campaign.right-side.end-date')}
                fullWidth
                margin
                value={value ? dayjs(value * 1000).format('YYYY-MM-DD') : ''}
                onChange={(value: string) => onChange(dayjs(value).valueOf() / 1000 + 23 * 3600 + 3599)}
              />
            </CheckBoxContainer>
          )}
        />
      </Settings>
    )
  }

  const renderConditions = () => {
    return (
      <CampaignContext.Provider value={getCampaignContext()}>
        <FieldGroup label={i18next.t('store.item.requirements_expression')}>
          <Controller
            control={control}
            name="requirements_expression"
            rules={{
              validate: (v: Expression | undefined) => {
                if (!v?.tokens?.length) {
                  return true
                }
                return true
              },
            }}
            render={({ field }) => (
              <ConditionEditor
                errTokens={errorTokens}
                errMessage={conditionError}
                mode="onchange"
                node={
                  {
                    id: props.coupon?.id,
                    expr: field.value || {
                      tokens: [],
                    },
                  } as ConditionNode
                }
                setNode={n => {
                  field.onChange(n.expr)
                }}
              />
            )}
          />
        </FieldGroup>
      </CampaignContext.Provider>
    )
  }

  return (
    <div style={{ width: '864px' }} className="flex h-full flex-col bg-fg-primary">
      <DrawerTopbar>
        <h2 className="mr-auto text-title-t5">{i18next.t('coupon.settings')}</h2>
        <div className="flex gap-2.5">
          <Button onClick={onClose}>{i18next.t('close')}</Button>
          <Button variant="primary" disabled={!isDirty} onClick={handleSubmit(onSubmit)}>
            {i18next.t('Save2')}
          </Button>
        </div>
      </DrawerTopbar>

      <div className="flex-1">
        <DrawerContent>
          {renderGeneral()}

          <Divider />

          {renderBenefits()}

          {type != CouponType.FreeItem && (
            <>
              <Divider />

              {renderBenefitsCompatibility()}
            </>
          )}

          <Divider />
          {renderGeneralLimitations()}

          <Divider />
          {renderTimeLimitations()}

          <Divider />
          {renderConditions()}

          {errorMessage && <FieldValidationMessage> {errorMessage} </FieldValidationMessage>}
        </DrawerContent>
      </div>

      {props.coupon?.id && (
        <DrawerFooter>
          <Button onClick={() => openDeleteModal({})} variant="error">
            {i18next.t('delete')}
          </Button>
        </DrawerFooter>
      )}
    </div>
  )
}
