import { CampaignEventType } from '@/api/dashboard'
import i18next from 'i18next'

export function getContextItemName(type: CampaignEventType | undefined) {
  switch (type) {
    case CampaignEventType.AbandonedCart:
      return i18next.t('campaign.abandoned-item')
    case CampaignEventType.PurchasedInWeb:
      return i18next.t('campaign.purchased-item')
  }
  return ''
}
