import { useNavigate, useParams } from 'react-router-dom'
import { FullscreenLayout } from '../../../components/layouts'
import { useGenerateCompanyPath } from '../../../libs/hooks'
import { SKU_ITEMS_PATH } from '../../../libs/routerPaths'
import { useItemCreateMutate } from '../api'
import { SkuForm, SkuFormData } from '../widgets/SkuForm'
import { cn } from '@/libs'
import { useBanner } from '@/libs/hooks/useBanner'

export const SkuCreatePage = () => {
  const navigate = useNavigate()
  const { generatePath } = useGenerateCompanyPath()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string; itemId: string }
  const { mutateAsync: createMutateAsync } = useItemCreateMutate(companyId, gameId)
  const { getStickyHeight } = useBanner()

  const onSubmit = async (data: SkuFormData) => {
    delete data.bonus_percent
    delete data.discount_percent
    await createMutateAsync({
      data: { ...data, price_point: data.price > 0 ? data.price : (null as unknown as undefined) },
    })
    navigate(generatePath(SKU_ITEMS_PATH), { replace: true })
    return data
  }

  return (
    <FullscreenLayout className="bg-linear-bg-gradient">
      <div className={cn(getStickyHeight(), 'overflow-auto')}>
        <SkuForm onSubmit={onSubmit} />
      </div>
    </FullscreenLayout>
  )
}
