import { ReactNode } from 'react'

interface ModalRushTitleProps {
  children: ReactNode
  extra?: ReactNode
}

export const ModalRushTitle = ({ children, extra }: ModalRushTitleProps) => (
  <div className="mb-5 flex items-center justify-between last:mb-0">
    <h3 className="flex max-w-[65%] items-center gap-1.5 truncate font-nohemi text-hero-h3">{children}</h3>
    {extra && <div className="flex items-center gap-3">{extra}</div>}
  </div>
)
