import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../../../components/icons/IconBase'

export const LogicIcon: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 18 18" ref={ref} size={33}>
      <path
        d="M4.90416 7.6153L2.02429 10.3389C1.46807 10.865 1.46807 11.7503 2.02429 12.2764L4.90416 15M9 3V8.25M9 8.25C9 9.87635 7.60595 11.3078 5.8863 11.3078H2M9 8.25C9 9.87635 10.394 11.3078 12.1137 11.3078H17M13.5257 7.6153L16.696 10.6136C17.1013 10.9969 17.1013 11.6184 16.696 12.0017L13.5257 15"
        stroke="#FF9F1C"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </IconBase>
  )
})
