import { AllData } from '@/layouts/dashboard/Context'
import { create7Data } from '@/layouts/dashboard/demo_data/data_7'
import dayjs from 'dayjs'
import { CategoryData, DateData } from '@/layouts/dashboard/demo_data/types'
import { create30Data } from '@/layouts/dashboard/demo_data/data_30'
import { Campaign, ItemRead } from '@/api/dashboard'

function moveDates(dates: DateData[] | CategoryData[], startIndex = 0) {
  dates = dates.reverse()
  dates.forEach((date, index) => {
    if ((date as DateData).date) {
      let dd = date as DateData
      dd.date = dayjs()
        .add(-index - startIndex, 'day')
        .format('YYYY-MM-DD')
    }
    if ((date as CategoryData).category) {
      let cd = date as CategoryData
      cd.category = dayjs()
        .add(-index - startIndex, 'day')
        .format('YYYY-MM-DD')
    }
  })

  return dates.reverse()
}

function moveDates3(dates: DateData[] | CategoryData[], startIndex = 0) {
  let index = startIndex
  dates = dates.reverse()
  for (let i = 0; i < dates.length; i++) {
    let date = dates[i]
    if ((date as DateData).date) {
      let dd = date as DateData
      dd.date = dayjs().add(-index, 'day').format('YYYY-MM-DD')
    }
    if ((date as CategoryData).category) {
      let cd = date as CategoryData
      cd.category = dayjs().add(-index, 'day').format('YYYY-MM-DD')
    }

    if (i % 3 == 0 && i != 0) {
      index++
    }
  }

  return dates.reverse()
}

export function createAllData(selectedFilter: string, campaigs: Campaign[], items: ItemRead[]): AllData {
  let data = selectedFilter == '7' ? create7Data(campaigs, items) : create30Data(campaigs, items)

  moveDates(data.revenue_by_dates)
  moveDates3(data.revenue_by_date_device)
  moveDates3(data.triggers_by_date.data)
  data.triggers_by_date.prev_data = data.triggers_by_date.data.map(it => ({
    category: dayjs(it.category).add(-parseInt(selectedFilter), 'day').format('YYYY-MM-DD'),
    value: it.value! / 1.5,
    series_name: it.series_name,
  }))
  moveDates(data.revenue_by_dates)
  moveDates(data.sales_by_date)
  moveDates(data.payments_success_fail_by_dates)
  moveDates(data.conversion_game_hub_first_purchase_by_date.prev_data, parseInt(selectedFilter))
  moveDates(data.conversion_game_hub_first_purchase_by_date.data)

  return data
}
