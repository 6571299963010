import { useEffect, useRef, useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ToolbarGroup } from './ToolbarGroup'
import { ToolbarDivider } from './ToolbarDivider'
import { ToolbarAlignButton } from './ToolbarAlignButton'
import { ToolbarFormattingButton } from './ToolbarFormattingButton'
import { ToolbarImageButton } from './ToolbarImageButton'
import { ToolbarListsButton } from './ToolbarListsButton'
import { cn } from '../../../../libs/cn'

export const ToolbarPlugin = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [editor] = useLexicalComposerContext()
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const observer = new IntersectionObserver(([e]) => setIsSticky(e.intersectionRatio < 1), {
      threshold: [1],
    })

    observer.observe(ref.current)
  }, [ref])

  return (
    <div
      ref={ref}
      className={cn(
        'sticky left-0 top-[-21px] z-50 flex items-center gap-1.5 rounded-t-md border-b border-b-border-secondary bg-fg-secondary p-1',
        isSticky && 'rounded-none pt-[4px]',
      )}
    >
      <ToolbarFormattingButton editor={editor} />

      <ToolbarDivider />

      <ToolbarGroup>
        <ToolbarListsButton editor={editor} />
      </ToolbarGroup>

      <ToolbarDivider />

      <ToolbarGroup>
        <ToolbarAlignButton editor={editor} />
      </ToolbarGroup>

      <ToolbarDivider />

      <ToolbarGroup>
        <ToolbarImageButton editor={editor} />
      </ToolbarGroup>
    </div>
  )
}
