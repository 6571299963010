import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const ExternalLink: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 14 14" ref={ref}>
      <path
        d="M4.52624 1.16663H5.83366C6.15583 1.16663 6.41699 1.42779 6.41699 1.74996C6.41699 2.07213 6.15583 2.33329 5.83366 2.33329H4.55033C4.05065 2.33329 3.71098 2.33375 3.44843 2.3552C3.19269 2.37609 3.0619 2.41397 2.97067 2.46045C2.75115 2.5723 2.57267 2.75078 2.46082 2.9703C2.41433 3.06154 2.37646 3.19232 2.35556 3.44807C2.33411 3.71062 2.33366 4.05029 2.33366 4.54996V9.44996C2.33366 9.94963 2.33411 10.2893 2.35556 10.5519C2.37646 10.8076 2.41433 10.9384 2.46082 11.0296C2.57267 11.2491 2.75115 11.4276 2.97067 11.5395C3.0619 11.586 3.19269 11.6238 3.44843 11.6447C3.71098 11.6662 4.05065 11.6666 4.55033 11.6666H9.45033C9.95 11.6666 10.2897 11.6662 10.5522 11.6447C10.808 11.6238 10.9387 11.586 11.03 11.5395C11.2495 11.4276 11.428 11.2491 11.5398 11.0296C11.5863 10.9384 11.6242 10.8076 11.6451 10.5519C11.6665 10.2893 11.667 9.94963 11.667 9.44996V8.16663C11.667 7.84446 11.9282 7.58329 12.2503 7.58329C12.5725 7.58329 12.8337 7.84446 12.8337 8.16663V9.47407C12.8337 9.94362 12.8337 10.3312 12.8079 10.6469C12.7811 10.9747 12.7236 11.2762 12.5793 11.5593C12.3556 11.9983 11.9987 12.3553 11.5596 12.579C11.2765 12.7232 10.9751 12.7807 10.6472 12.8075C10.3315 12.8333 9.94399 12.8333 9.47443 12.8333H4.52622C4.05666 12.8333 3.66912 12.8333 3.35343 12.8075C3.02554 12.7807 2.72413 12.7232 2.44102 12.579C2.00197 12.3553 1.64502 11.9983 1.42131 11.5593C1.27706 11.2762 1.21956 10.9747 1.19277 10.6469C1.16698 10.3312 1.16699 9.94362 1.16699 9.47406V4.52587C1.16699 4.05631 1.16698 3.66876 1.19277 3.35306C1.21956 3.02517 1.27706 2.72376 1.42131 2.44065C1.64502 2.0016 2.00197 1.64465 2.44102 1.42094C2.72413 1.27669 3.02554 1.2192 3.35343 1.19241C3.66912 1.16661 4.05667 1.16662 4.52624 1.16663Z"
        fill="currentColor"
      />
      <path
        d="M8.16699 1.74996C8.16699 1.42779 8.42816 1.16663 8.75033 1.16663H12.2503C12.5725 1.16663 12.8337 1.42779 12.8337 1.74996L12.8337 5.24996C12.8337 5.57212 12.5725 5.83329 12.2503 5.83329C11.9282 5.83329 11.667 5.57213 11.667 5.24996L11.667 3.15825L7.4128 7.41244C7.185 7.64024 6.81565 7.64024 6.58785 7.41244C6.36004 7.18463 6.36004 6.81529 6.58785 6.58748L10.842 2.33329H8.75033C8.42816 2.33329 8.16699 2.07213 8.16699 1.74996Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
