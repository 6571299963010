import { ReactNode } from 'react'
import { flip, useFloating } from '@floating-ui/react'

interface ComponentPickerMenuProps {
  children: ReactNode
}

export const ComponentPickerMenu = ({ children }: ComponentPickerMenuProps) => {
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [flip()],
  })

  return (
    <>
      <div ref={refs.setReference} />
      <ul
        ref={refs.setFloating}
        style={floatingStyles}
        className="w-[280px] rounded-md border border-border-secondary bg-fg-primary p-1 shadow-xl"
      >
        {children}
      </ul>
    </>
  )
}
