import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { HUB_THEMES_PATH } from '../../libs/routerPaths'
import { useEffect } from 'react'

export const HubIndexPage = () => {
  const { companyId, gameId } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    navigate(generatePath(HUB_THEMES_PATH, { companyId: companyId as string, gameId: gameId as string }), {
      replace: true,
    })
  }, [navigate])
  return null
}
