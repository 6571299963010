import { WebhookEventType, WebhookLogRead } from '@/api/dashboard'
import i18next from 'i18next'

export function validateResponse(log: WebhookLogRead) {
  if (log.event_type == WebhookEventType.PlayerVerify) {
    if (!log.response_body) {
      return { message: i18next.t('webhook.error.player-verify') }
    }

    try {
      let data = JSON.parse(log.response_body)

      if (!data.player_id) {
        return { message: i18next.t('webhook.error.player-verify', { value: 'player_id' }) }
      }

      if (!data.name) {
        return { message: i18next.t('webhook.error.player-verify', { value: 'name' }) }
      }
    } catch (e) {
      return { message: i18next.t('webhook.error.player-verify') }
    }
  }

  return null
}
