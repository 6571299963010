import { useContext, useEffect, useState } from 'react'
import { Link, generatePath, useParams } from 'react-router-dom'
import i18next from 'i18next'
import CenteredProgress from '../../components/CenteredProgress'
import PageBlock from '../game-items/components/PageBlock'
import { isSuperAdmin, uniqueList } from '../../util'
import PaymentStatusChip from './components/PaymentStatusChip'
import { PaymentStatus } from '../../pay-types'
import UserTransactionsTable from './UserTransactionsTable'
import { PADDING, getMinPaymentByStatus, getTransactionTotal, isDemo } from './util'
import EmptyTableMessage from '../../components/EmptyTableMessage'
import { dashboardClient, getErrorText } from '../../api'
import { Currency, CustomerCompanyRead, ItemRead, OrderRead, PaymentRead, User } from '../../api/dashboard'
import PlayerCard from '../player/PlayerCard'
import PayCardView from './PayCardView'
import { AuthContext, IAuthContext } from '../../Context'
import { canEdit } from '../../security'
import { Textarea } from '../../components/ui/Textarea'
import { Button, ConfirmDialog, MenuButton, ModalConfirm, useModal } from '../../components/ui'
import RefundingProgress from './components/RefundingProgress'
import { SKU_ITEMS_DETAILS_PATH, TRANSACTIONS_PATH } from '../../libs/routerPaths'
import { WebhookLogTimeline } from './components/WebhookLogTimeline'
import { ChevronLeft, Copy03, DotPoints } from '../../components/icons'
import { useToast } from '../../components/ui/Toast/useToast'
import { ToastSeverity } from '../../components/ui/Toast/types'
import { ReceiptEmailView } from './widgets/ReceiptEmailView'
import { GeneralTransactionDetails } from './widgets/GeneralTransactionDetails'
import { GeneralBlock } from './components/GeneralBlock'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '../../Settings'
import { getItemName } from '../../api/getItemName'
import { TransactionTimeline } from './widgets/TransactionTimeline'
import { useNavigateBack } from '../../libs/hooks'
import { useBanner } from '@/libs/hooks/useBanner'
import { cn } from '@/libs'

let refundTimeId: ReturnType<typeof setTimeout> | null = null

export default function TransactionDetails() {
  const { companyId, gameId, paymentNumber } = useParams() as {
    companyId: string
    gameId: string
    paymentNumber: string
  }
  const back = useNavigateBack({ fallback: TRANSACTIONS_PATH })
  const context = useContext(AuthContext) as IAuthContext
  const isReadOnly = !canEdit(context.customer)
  const { getStickyTop } = useBanner()
  const [payment, setPayment] = useState<PaymentRead[]>([])
  const [sourcePayments, setSourcePayments] = useState<PaymentRead[]>([])
  const [loading, setLoading] = useState(true)
  const [currentPaymentRefund, setCurrentPaymentRefund] = useState<PaymentRead | null>(null)
  const [item, setItem] = useState<ItemRead | null>(null)
  const [order, setOrder] = useState<OrderRead | null>(null)
  const [user, setUser] = useState<User | null>(null)

  const [isRefunding, setIsRefunding] = useState(false)
  const [refundReason, setRefundReason] = useState('')
  const [refundCustomer, setRefundCustomer] = useState<CustomerCompanyRead | null>(null)
  const showToast = useToast()

  const loadRefundCustomer = async (p: PaymentRead) => {
    if (p && p.status == PaymentStatus.refunded && p.refund_customer_id) {
      let { data: customer } = await dashboardClient.v1.getCompanyMember(p.refund_customer_id, companyId)
      setRefundCustomer(customer)
    }
  }

  const loadData = async () => {
    setLoading(true)

    try {
      let { data } = await dashboardClient.v1.getPayment(paymentNumber, companyId, gameId)
      loadRefundCustomer(data[0])
      setPayment(uniqueList(data, 'status'))
      setSourcePayments(data)

      dashboardClient.v1.getItem(companyId, gameId, data[0].item_id!).then(res => setItem(res.data as ItemRead))

      if (!isDemo(data[0].order_id)) {
        dashboardClient.v1.getUser(companyId, gameId, data[0].user_id!).then(res => setUser(res.data as User))
        dashboardClient.v1.getOrder(data[0].order_id!, companyId, gameId).then(res => setOrder(res.data as OrderRead))
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadData()
    return () => {
      if (refundTimeId) {
        clearTimeout(refundTimeId)
      }
    }
  }, [paymentNumber])

  const updateRefundStatus = async () => {
    try {
      let { data } = await dashboardClient.v1.getPayment(paymentNumber, companyId, gameId)
      if (data.find((it: PaymentRead) => it.status === PaymentStatus.refunded)) {
        setSourcePayments(data)
        setPayment(uniqueList(data, 'status'))
        loadRefundCustomer(data[0])
        setIsRefunding(false)
      } else {
        refundTimeId = setTimeout(updateRefundStatus, 3000)
      }
    } catch (e) {
      if (refundTimeId) {
        clearInterval(refundTimeId)
      }
      showToast({ severity: ToastSeverity.error, message: getErrorText(e) })
      console.log(e)
    }
  }

  const onRefundConfirm = async () => {
    if (currentPaymentRefund) {
      if (isRefunding) {
        return
      }
      setCurrentPaymentRefund(null)
      try {
        setIsRefunding(true)
        await dashboardClient.v1.refundOrder(currentPaymentRefund.payment_id!, companyId!, gameId!, {
          reason: refundReason,
          order_id: currentPaymentRefund.order_id!,
        })
        refundTimeId = setTimeout(updateRefundStatus, 3000)
      } catch (e) {
        console.log(e)
        showToast({ severity: ToastSeverity.error, message: getErrorText(e) })
        setIsRefunding(false)
      }
      setRefundReason('')
    }
  }

  const openResendEmailModal = useModal<{ payment: PaymentRead }>(props => (
    <ModalConfirm
      color={'primary'}
      confirmButtonText={i18next.t('yes')}
      subMessage={i18next.t('transactions-table.resend-email.confirm')}
      onConfirm={async () => {
        try {
          await dashboardClient.v1.resendReceiptEmail(companyId, gameId, props.payment.order_id!)
          showToast({ severity: ToastSeverity.success, message: i18next.t('transactions-table.resend-email.sent') })
        } catch (e) {
          showToast({ severity: ToastSeverity.error, message: getErrorText(e) })
        }
      }}
      {...props}
    />
  ))

  const openRenotifyModal = useModal<{ payment: PaymentRead }>(props => (
    <ModalConfirm
      color={'primary'}
      confirmButtonText={i18next.t('yes')}
      subMessage={i18next.t('transactions-table.renotify.confirm')}
      onConfirm={async () => {
        try {
          await dashboardClient.v1.renotifyPayment(props.payment.payment_id!, companyId, gameId, {
            reason: refundReason,
            order_id: props.payment.order_id!,
          })

          loadData()
        } catch (e) {
          showToast({ severity: ToastSeverity.error, message: getErrorText(e) })
        }
      }}
      {...props}
    />
  ))

  const renderGeneral = () => {
    const p = payment[0] ? getMinPaymentByStatus(sourcePayments, payment[0].status as PaymentStatus) : null

    return (
      <div>
        <div className="flex justify-between gap-5">
          <GeneralBlock title={i18next.t('transaction-details.item')}>
            {item && (
              <Link
                className="flex gap-3"
                to={
                  isReadOnly ? '' : generatePath(SKU_ITEMS_DETAILS_PATH, { companyId, gameId, itemId: item?.id || '' })
                }
              >
                <div
                  className="h-[38px] w-[50px] shrink-0 rounded-md bg-fg-primary-alt bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${item.image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
                  }}
                />
                <div className="flex flex-col truncate">
                  <div className="mb-0.5 flex	items-center gap-1.5 overflow-hidden whitespace-nowrap font-semibold leading-5 text-text-secondary">
                    {getItemName(item)}
                  </div>
                  <div className="text-xs font-medium	 leading-none text-text-quarterary-hover">{item.sku}</div>
                </div>
              </Link>
            )}
          </GeneralBlock>

          <GeneralBlock title={i18next.t('transaction-details.last-update')}>
            <div className="flex h-[38px] items-center gap-1 font-semibold text-text-secondary">
              <span>
                {p?.payment_date &&
                  i18next.t('intl.DateTime', {
                    val: new Date(p.payment_date),
                    formatParams: { val: { dateStyle: 'medium' } },
                  })}
              </span>
              <span>
                {p?.payment_date &&
                  i18next.t('intl.DateTime', {
                    val: new Date(p.payment_date),
                    formatParams: { val: { timeStyle: 'medium' } },
                  })}
              </span>
            </div>
          </GeneralBlock>

          <GeneralBlock title={i18next.t('transaction-details.payment-method')}>
            <div className="flex h-[38px] items-center font-semibold">{p && <PayCardView payment={p} />}</div>
          </GeneralBlock>
        </div>
      </div>
    )
  }

  const renderDetails = () => {
    let p = payment[0] || ({} as PaymentRead)

    return (
      <PageBlock title={i18next.t('transaction-details.details')} padding={PADDING}>
        <div
          className="gap-5"
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          <GeneralTransactionDetails
            item={item}
            payments={payment}
            refundCostumer={refundCustomer}
            order={order}
            sourcePayments={sourcePayments}
          />
          <ReceiptEmailView payment={p} />
        </div>
      </PageBlock>
    )
  }

  const renderPrevPurchases = () => {
    if (!payment[0]?.user_id || !payment[0].created_at) {
      return
    }
    return (
      <PageBlock
        style={{ overflowX: 'auto', width: '100%' }}
        title={i18next.t('players.other-transactions')}
        padding={PADDING}
      >
        <UserTransactionsTable
          userId={payment[0].user_id}
          endTs={payment[0].created_at}
          exclude_payment_number={payment[0].payment_number}
        />
      </PageBlock>
    )
  }

  const renderRightAction = () => {
    let p = payment[0] || ({} as PaymentRead)

    if (isReadOnly || isRefunding) {
      return
    }

    return (
      <div className="ml-auto flex gap-1">
        {p.payment_number && (
          <Button
            onClick={() => navigator.clipboard.writeText(p.payment_number + '')}
            color="secondary"
            variant="outline"
          >
            {p.payment_number}
            <Copy03 />
          </Button>
        )}

        {p.status == PaymentStatus.done && p.currency != Currency.RP && (
          <Button onClick={() => setCurrentPaymentRefund(p)} variant="secondary-brand">
            {i18next.t('transactions-table.refund')}
          </Button>
        )}
        {p.payment_id && isSuperAdmin(context.customer) && (
          <MenuButton
            placement={'bottom-end'}
            dropDownWidth="200px"
            onChange={v => {
              switch (v) {
                case 'renotify':
                  openRenotifyModal({ payment: p })
                  break
                case 'resend-email':
                  openResendEmailModal({ payment: p })
                  break
              }
            }}
            options={[
              { value: 'renotify', children: i18next.t('transactions-table.renotify') },
              (p.status == PaymentStatus.done || p.status == PaymentStatus.refunded) && {
                value: 'resend-email',
                children: i18next.t('transactions-table.resend-receipt-email'),
              },
            ].filter(it => !!it)}
          >
            <DotPoints />
          </MenuButton>
        )}
      </div>
    )
  }

  return (
    <div>
      <div className={cn('sticky z-10 flex items-center bg-fg-primary pb-[20px]', getStickyTop())}>
        <Button size={'md'} onClick={back.onClick}>
          <ChevronLeft />
        </Button>

        {payment.length > 0 && (
          <div className="ml-3 flex items-center gap-3">
            <div className="ph-no-capture relative flex items-center text-title-t1 text-text-primary">
              <div className="absolute top-[-12px] text-caption-sm uppercase text-text-tertiary">
                {i18next.t('transaction-details.total')}:
              </div>
              {getTransactionTotal(payment[0])} {payment[0].currency}
            </div>

            {isRefunding ? <RefundingProgress /> : <PaymentStatusChip payment={payment} />}
          </div>
        )}

        {renderRightAction()}
      </div>

      {currentPaymentRefund && (
        <ConfirmDialog
          color={'primary'}
          confirmButtonText={i18next.t('transactions-table.refund')}
          confirmEnabled={!!refundReason.trim()}
          message={i18next.t('transactions-table.refund-title', { value: getTransactionTotal(currentPaymentRefund) })}
          subMessage={i18next.t('transactions-table.refund.confirm')}
          onCancel={() => setCurrentPaymentRefund(null)}
          onConfirm={onRefundConfirm}
        >
          <Textarea
            className="mt-6"
            placeholder={i18next.t('transaction-details.write-refund-reason')}
            value={refundReason}
            onChange={e => setRefundReason(e.target.value)}
          />
        </ConfirmDialog>
      )}

      {loading ? (
        <CenteredProgress />
      ) : (
        <div className="flex flex-col gap-5">
          {!payment[0] ? (
            <EmptyTableMessage
              title={i18next.t('service-unavailable')}
              subtitle={i18next.t('SomethingWentWrong')}
              sx={{ height: '600px' }}
            />
          ) : (
            <>
              {renderGeneral()}
              {renderDetails()}
              <TransactionTimeline payments={payment} sourcePayments={sourcePayments} />
              {payment.length && !isDemo(payment[0].order_id) && (
                <WebhookLogTimeline orderId={payment[0].order_id!} paymentStatus={payment[0].status as PaymentStatus} />
              )}
              {user && !user.id.startsWith('demo_') && (
                <PlayerCard payLinkUserId={payment[0]?.paylink_user_id} user={user} />
              )}
              {renderPrevPurchases()}
            </>
          )}

          <div className="h-[100px]" />
        </div>
      )}
    </div>
  )
}
