import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { UserSegmentUpdate } from '../../../api/dashboard'

interface PlayerUpdateMutate {
  userId: string
  update: UserSegmentUpdate
}

export const usePlayerSegmentUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ userId, update }: PlayerUpdateMutate) =>
      dashboardClient.v1.updateUserSegments(companyId, gameId, userId, update),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['user-segment', variables.userId] })
    },
  })
}
