import { ClassValue, clsx } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        'text-title-t1',
        'text-title-t2',
        'text-title-t3',
        'text-title-t4',
        'text-title-t5',
        'text-hero-h1',
        'text-hero-h2',
        'text-hero-h3',
        'text-hero-h4',
        'text-hero-h5',
        'text-h2',
        'text-t4',
        'text-t5',
        'text-t6',
        'text-caption-xs',
        'text-caption-sm',
        'text-caption-md',
        'text-caption-lg',
        'text-paragraph-lg',
        'text-paragraph-md',
        'text-paragraph-sm',
        'text-paragraph-xs',
      ],
    },
    conflictingClassGroups: {
      'font-size': ['font-weight', 'tracking'],
    },
  },
})

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs))
}
