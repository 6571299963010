import i18next from 'i18next'

import DurationSelectorDHMS from '../../../components/DurationSelectorDHMS'
import { Node, PeriodType, TimerNode } from '../../../api/dashboard'
import { BlockError, EventActionNodeType } from '../types'

export function getTime(totalSeconds: number): { [key: string]: number } {
  let days = totalSeconds / (60 * 60 * 24)

  let remain = totalSeconds % (60 * 60 * 24)
  let hours = remain / 60 / 60

  remain = remain % (60 * 60)

  let minutes = remain / 60

  return {
    [PeriodType.Day]: Math.floor(days),
    [PeriodType.Hour]: Math.floor(hours),
    [PeriodType.Minute]: Math.floor(minutes),
    [PeriodType.Second]: remain % 60,
  }
}

export const TimerNodeEditor = (props: {
  node: TimerNode
  error?: BlockError | null
  setNode?: (node: Node) => void
}) => {
  return (
    <>
      <DurationSelectorDHMS
        duration={props.node.delay}
        label={i18next.t('campaign.editor.enter-time')}
        onChange={v => {
          if (props.setNode) {
            props.setNode({
              ...props.node,
              delay: v,
              model_type: EventActionNodeType.TimerNode,
            })
          }
        }}
      />
    </>
  )
}
