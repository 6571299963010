import { HTMLAttributes, createElement, forwardRef, useId } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useSelect } from 'downshift'
import { FloatingPortal, useMergeRefs } from '@floating-ui/react'
import { cn } from '@/libs'
import { AghanimIconOrCustom } from '@/icons'
import { useSelectFloating } from '../Select/useSelectFloating'
import { Menu } from '../Menu'
import { getFloatingMotionProps } from '../Floating'

export interface SelectIconOption {
  // TODO: remove custom icons
  icon: AghanimIconOrCustom
  value: string | number | null
}

export interface SelectIconProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  disabled?: boolean
  options: SelectIconOption[]
  value?: SelectIconOption['value']
  onChange: (value: SelectIconOption['value']) => void
}

export const SelectIcon = forwardRef<HTMLDivElement, SelectIconProps>(function SelectIcon(
  { options, value, onChange, disabled = false, ...rest },
  ref,
) {
  const id = useId()
  const {
    isOpen,
    selectedItem: selectedOption,
    highlightedIndex,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: options,
    itemToString: option => option?.value?.toString() || '',
    selectedItem: options.find(option => option!.value === value) || null,
    onSelectedItemChange: ({ selectedItem: option }) => {
      onChange(option!.value)
    },
  })

  const { refs, floatingStyles, placement } = useSelectFloating(isOpen)
  const referenceMergedRef = useMergeRefs([refs.setReference, ref])

  return (
    <>
      <div
        {...rest}
        {...getToggleButtonProps({ ref: referenceMergedRef, disabled })}
        className={cn(
          'flex size-12 cursor-pointer items-center justify-center rounded-md border border-border-primary bg-fg-primary text-text-secondary shadow-xs outline-none ring-brand transition-colors hover:border-border-primary-hover focus:border-border-brand focus:ring-2',
          isOpen && 'border-border-brand ring-2',
          disabled && 'pointer-events-none',
          rest.className,
        )}
        aria-disabled={disabled}
      >
        {selectedOption && <>{createElement(selectedOption.icon, { size: 24 })}</>}
      </div>

      <FloatingPortal>
        <div {...getMenuProps()}>
          <AnimatePresence>
            {isOpen && !!options.length && (
              <div ref={refs.setFloating} className="z-[3000]" style={floatingStyles}>
                <motion.div {...getFloatingMotionProps(placement)} style={{ maxHeight: 'inherit' }}>
                  <Menu style={{ maxWidth: 'none', width: 'max-content' }}>
                    <div className="grid grid-cols-4 gap-0.5 p-2">
                      {options.map((option, index) => (
                        <div
                          key={`menu-option-${id}-${option.value}`}
                          className={cn(
                            'flex size-12 cursor-pointer items-center justify-center rounded-md text-text-quarterary transition-colors hover:bg-fg-secondary hover:text-text-quarterary-hover active:border active:border-border-brand active:text-text-secondary',
                            index === highlightedIndex && 'border-fg-secondary',
                            option.value === selectedOption?.value && 'border border-border-brand text-text-secondary',
                          )}
                          {...getItemProps({ item: option, index })}
                          {...option}
                        >
                          {createElement(option.icon, { size: 24 })}
                        </div>
                      ))}
                    </div>
                  </Menu>
                </motion.div>
              </div>
            )}
          </AnimatePresence>
        </div>
      </FloatingPortal>
    </>
  )
})
