import ReactECharts from 'echarts-for-react'
import { useTheme } from '@mui/material'
import i18next from 'i18next'
import DashboardCard from '../../components/shared/DashboardCard'
import { formatMoney } from '../../util'
import { TableZeroState } from '../../components/ui'
import { LoaderContainer } from '../dashboard/components/Loader/LoaderContainer'
import Loader from '../dashboard/components/Loader/Loader'
import { usePayoutsByMonthQuery } from './api/usePayoutsByMonthQuery'
import { useParams } from 'react-router-dom'
import { getFormatYearMonthParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'

export const PayoutsChart = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const theme = useTheme()
  const { t } = useTranslation()
  const { data = [], isLoading } = usePayoutsByMonthQuery(companyId, gameId)

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  if (data.length == 0) {
    return (
      <TableZeroState
        className="w-full"
        title={i18next.t('payouts.empty-chart.title')}
        message={i18next.t('payouts.empty-chart.desc')}
      />
    )
  }

  const options = {
    title: {
      show: false,
    },
    tooltip: {
      backgroundColor: '#000000CC',
      borderColor: 'transparent',
      trigger: 'axis',
      formatter: function (params: { axisValue: string; value: number; dataIndex: number }[]) {
        let item = params[0]
        let value = formatMoney(item.value * 100)

        return `<div class="chart-tooltip" style="height: auto;">
                  <div class="chart-tooltip--subtitle">
                      <div style="width: 12px; height: 4px;" class="bg-border-brand rounded-md"></div>
                      <span>${item.axisValue}:</span>
                      <span>${value}</span>
                  </div>
              </div>`
      },
    },
    legend: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    grid: {
      top: 20,
      left: 50,
      right: 50,
      bottom: 20,
      containLabel: false,
    },
    xAxis: [
      {
        axisLabel: {
          fontSize: 12,
          color: theme.palette.text.tertiary,
          fontWeight: '500',
          fontFamily: 'InterAg',
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        type: 'category',
        boundaryGap: false,
        data: data.map(it => t('intl.DateTime', getFormatYearMonthParams(it.ending_date / 1_000_000))),
        splitLine: {
          show: true,
          lineStyle: {
            color: theme.palette.grey.borderSecondary,
            type: 'solid',
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
        //   type: 'value',
        //   splitLine: {
        //     show: false,
        //   },
        //   axisLabel: {
        //     formatter: function (value: number) {
        //       // Only show min and max values as labels
        //       if (value == 0 || value == maxValue) {
        //         return formatMoney(value * 100)
        //       }
        //       return ''
        //     },
        //   },
      },
    ],
    axisPointer: {
      lineStyle: {
        color: '#000',
        width: 1,
        type: 'solid',
      },
    },
    series: [
      {
        name: 'main',
        type: 'bar',
        barWidth: '60%',
        showSymbol: false,
        color: '#0EA5E9',
        lineStyle: {
          color: '#0EA5E9',
          width: 3,
        },
        smooth: 0.05,
        label: {
          show: true,
          position: 'top',
          formatter: (v: { data: { value: number } }) => {
            return formatMoney(v.data.value * 100)
          },
          color: '#000',
          fontSize: 12,
        },
        data: data.map(it => ({
          value: it.total / 100,
          itemStyle: {
            borderRadius: [4, 4, 4, 4],
          },
        })),
      },
    ],
  }

  return (
    <DashboardCard
      title={i18next.t('sidebar.payouts')}
      style={{ height: '289px', flexGrow: 1, gap: '8px', overflow: 'hidden' }}
    >
      <ReactECharts
        option={options}
        style={{
          height: '221px',
          width: '100%',
        }}
      />
    </DashboardCard>
  )
}
