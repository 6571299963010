import { useTranslation } from 'react-i18next'
import { LocaleRead } from '@/api/dashboard'
import { AiTranslateOutline } from '@/icons'
import { getPercentFrom } from '@/libs'
import { StepProgressBar } from '@/layouts/get-started/components'

interface L10nLocaleModalAiTranslateProps {
  locale: LocaleRead
}

export const L10nLocaleModalAiTranslate = ({ locale }: L10nLocaleModalAiTranslateProps) => {
  const { t } = useTranslation()
  return (
    <div className="absolute left-0 top-0 z-[5] flex size-full items-start justify-center rounded-md bg-gradient-to-b from-[rgba(100,116,139,0.00)] to-[rgba(100,116,139,0.30)]">
      <div className="sticky left-0 top-1/2 max-w-md rounded-2xl1 border border-border-primary bg-fg-primary p-12 shadow-xl">
        <div className="mb-6 text-title-t5">{t('localization.ai.all.modal-status.title')}</div>
        <div>
          <StepProgressBar value={getPercentFrom(locale.stats.done || 0, locale.stats.total || 100)} />
          <div className="mt-1.5 flex items-start gap-2 text-caption-md font-normal text-text-secondary">
            <AiTranslateOutline className="shrink-0" size={14} />
            <div>{t('localization.ai.all.modal-status.text')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
