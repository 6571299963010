import { useCallback, useContext, useEffect, useMemo } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TableBar from '../../../components/TableBar'
import { useGameItems } from '../../../api/useGameItems'
import {
  Button,
  Page,
  Pagination,
  TabLink,
  Table,
  TableCell,
  TableCellBulkCheckbox,
  TableCellBulkToolbar,
  TableHeaderSkeleton,
  TableRow,
  TableZeroState,
  Tabs,
  useModal,
  usePagination,
} from '../../../components/ui'
import { canEdit } from '../../../security'
import { ItemRead, ResourceState } from '../../../api/dashboard'
import { SKU_ITEMS_NEW_PATH, SKU_ITEMS_PATH } from '../../../libs/routerPaths'
import { Archive, Plus, Trash, Unarchive } from '../../../components/icons'
import { useBulkSelect } from '../../../libs/hooks/useBulkSelect'
import {
  BulkArchiveModal,
  BulkDeleteModal,
  BulkEditCategoryModal,
  BulkUnarchiveModal,
  SkuItemRow,
  SkuItemRowSkeleton,
} from '../components'
import { useGenerateCompanyPath } from '../../../libs/hooks'
import { useSkuCounts } from '../hooks'
import { AuthContext, IAuthContext } from '@/Context'

const useSearchReformatState = () => {
  const [searchParams] = useSearchParams()
  const state = searchParams.get('state')
  return useMemo(
    () => (state === null ? ResourceState.Active : state === 'all' ? undefined : (state as ResourceState | undefined)),
    [state],
  )
}

export const SkuListPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const state = useSearchReformatState()
  const { generatePath } = useGenerateCompanyPath()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)

  const { page, query, onPageChange } = usePagination(100)

  const { counts } = useSkuCounts()
  const { data: items = [], isLoading } = useGameItems(companyId, gameId, { ...query, state })
  const { selected, onSelect, onReset } = useBulkSelect()

  const openBulkArchiveModal = useModal<{ items: ItemRead[] }>(props => (
    <BulkArchiveModal {...props} onReset={onReset} />
  ))

  const openBulkUnarchiveModal = useModal<{ items: ItemRead[] }>(props => (
    <BulkUnarchiveModal {...props} onReset={onReset} />
  ))

  const openBulkDeleteModal = useModal<{ items: ItemRead[] }>(props => <BulkDeleteModal {...props} onReset={onReset} />)

  const openBulkEditCategoryModal = useModal<{ items: ItemRead[] }>(props => (
    <BulkEditCategoryModal {...props} onReset={onReset} />
  ))

  useEffect(() => {
    onReset([])
  }, [state])

  const onClickBulkArchive = useCallback(() => {
    openBulkArchiveModal({ items: items.filter(it => selected.has(it.id)) })
  }, [items, selected, openBulkArchiveModal])

  const onClickBulkUnarchive = useCallback(() => {
    openBulkUnarchiveModal({ items: items.filter(it => selected.has(it.id)) })
  }, [items, selected, openBulkUnarchiveModal])

  const onClickBulkDelete = useCallback(() => {
    openBulkDeleteModal({ items: items.filter(it => selected.has(it.id)) })
  }, [items, selected, openBulkDeleteModal])

  const onClickBulkEditCategory = useCallback(() => {
    openBulkEditCategoryModal({ items: items.filter(it => selected.has(it.id)) })
  }, [items, selected, openBulkEditCategoryModal])

  const renderDefaultHeader = () => (
    <>
      {canUserEdit && (
        <TableCellBulkCheckbox
          selected={selected.size > 0 && selected.size == items.length}
          disabled={items.length === 0}
          onChange={() => onReset(selected.size == items.length ? [] : items.map(i => i.id))}
        />
      )}
      <TableCell width="100%">{t('sku.item.product-name')}</TableCell>
      <TableCell width="25%">{t('sku.item.type')}</TableCell>
      <TableCell width="25%">{t('sku.item.category')}</TableCell>
      <TableCell width="25%">{t('sku.item.price')}</TableCell>
      <TableCell width={62} />
    </>
  )

  const renderBulkSelectionHeader = () => (
    <>
      <TableCellBulkCheckbox
        selected={selected.size > 0 && selected.size == items.length}
        disabled={items.length === 0}
        onChange={() => onReset(selected.size == items.length ? [] : items.map(i => i.id))}
      />
      <TableCellBulkToolbar selected={selected} onReset={() => onReset([])}>
        {state !== ResourceState.Archived ? (
          <>
            <Button onClick={onClickBulkEditCategory}>{t('item-category.edit')}</Button>

            {!!items.filter(it => selected.has(it.id)).filter(item => !item.archived_at).length && (
              <Button variant="tertiary-gray" onClick={onClickBulkArchive}>
                <Archive />
                <span>{t('sku.bulk-archive.label')}</span>
              </Button>
            )}

            {!!items.filter(it => selected.has(it.id)).filter(item => item.archived_at).length && (
              <Button variant="tertiary-gray" onClick={onClickBulkUnarchive}>
                <Unarchive />
                <span>{t('sku.bulk-unarchive.label')}</span>
              </Button>
            )}
          </>
        ) : (
          <>
            <Button variant="tertiary-gray" onClick={onClickBulkUnarchive}>
              <Unarchive />
              <span>{t('sku.bulk-unarchive.label')}</span>
            </Button>
            <Button variant="tertiary-destructive" onClick={onClickBulkDelete}>
              <Trash />
              <span>{t('sku.bulk-delete.label')}</span>
            </Button>
          </>
        )}
      </TableCellBulkToolbar>
    </>
  )

  return (
    <Page>
      <TableBar
        title={t('sidebar.items')}
        onBtnClick={canUserEdit ? () => navigate(generatePath(SKU_ITEMS_NEW_PATH)) : undefined}
        sticky
      />

      <Tabs>
        <TabLink to={`${generatePath(SKU_ITEMS_PATH)}?state=all`} count={counts.all?.total_count}>
          {t('sku.items.tabs.all')}
        </TabLink>
        <TabLink to={generatePath(SKU_ITEMS_PATH)} count={counts.active?.total_count}>
          {t('sku.items.tabs.active')}
        </TabLink>
        <TabLink to={`${generatePath(SKU_ITEMS_PATH)}?state=archived`} count={counts.archived?.total_count}>
          {t('sku.items.tabs.archived')}
        </TabLink>
      </Tabs>

      {isLoading ? (
        <>
          <TableHeaderSkeleton />
          {new Array(10).fill('').map((_, index) => (
            <SkuItemRowSkeleton key={`skeleton-${index}`} canEdit={canUserEdit} />
          ))}
        </>
      ) : items.length === 0 ? (
        <TableZeroState
          title={t(`sku.items.zero.${state || 'all'}.title`)}
          message={t(`sku.items.zero.${state || 'all'}.message`)}
          buttons={
            canUserEdit &&
            t(`sku.items.zero.${state || 'all'}.button`, { defaultValue: '' }) && (
              <Link to={generatePath(SKU_ITEMS_NEW_PATH)}>
                <Button variant="primary">
                  <Plus size={14} />
                  <span>{t(`sku.items.zero.${state || 'all'}.button`)}</span>
                </Button>
              </Link>
            )
          }
        />
      ) : (
        <>
          <Table>
            <TableRow className="top-[56px] h-12" variant="header">
              {selected.size ? renderBulkSelectionHeader() : renderDefaultHeader()}
            </TableRow>
            {items.map(item => (
              <SkuItemRow
                key={`sku-item-row-${item.id}`}
                item={item}
                canEdit={canUserEdit}
                bulk={{ isSelected: selected.has(item.id), onSelect }}
              />
            ))}
          </Table>
          <Pagination
            hasNext={items.length >= query.limit}
            page={page}
            onPageChange={onPageChange}
            pageItems={items.length}
          />
        </>
      )}
    </Page>
  )
}
