import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ItemCreate } from '../../../api/dashboard'
import { dashboardClient } from '../../../api'

interface ItemImageUpdateMutate {
  data: ItemCreate
}

/**
 *
 * @param companyId
 * @param gameId
 */
export const useItemCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ data }: ItemImageUpdateMutate) =>
      dashboardClient.v1.createItem(companyId, gameId, data).then(r => r.data),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['items', gameId], exact: false, stale: false })
    },
  })
}
