import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { HUB_API } from '../HubAPI'
import { INVITE_ID_STORAGE_KEY } from './AcceptInvite'
import { postLogin } from '../api/postLogin'

const Callback = () => {
  const navigate = useNavigate()
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0()

  const load = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: import.meta.env.VITE_AUTH0_API_AUDIENCE,
        scope: 'read:current_user',
      },
    })
    HUB_API.setToken(accessToken)

    postLogin(user?.name, user?.picture)

    let inviteId = localStorage.getItem(INVITE_ID_STORAGE_KEY)
    if (inviteId) {
      navigate(`/join/${inviteId}`)
    } else {
      navigate('/', { replace: true })
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      load()
    }
  }, [isAuthenticated])

  if (isLoading) {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return <></>
}

export default Callback
