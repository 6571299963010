import { useTranslation } from 'react-i18next'
import { cn } from '@/libs'

export const DateTimeValue = (props: { value: number | undefined; timeClassName?: string }) => {
  const { t } = useTranslation()

  if (!props.value) {
    return <></>
  }

  const timeClassName = props.timeClassName || 'text-text-disabled'

  return (
    <>
      <div className="mb-1 leading-normal">
        {t('intl.DateTime', {
          val: new Date(props.value * 1000),
          formatParams: { val: { dateStyle: 'medium' } },
        })}
      </div>
      <div className={cn('leading-normal', timeClassName)}>
        {t('intl.DateTime', {
          val: new Date(props.value * 1000),
          formatParams: { val: { timeStyle: 'medium' } },
        })}
      </div>
    </>
  )
}
