import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SingleValue from '../../../dashboard/components/SingleValue/SingleValue'
import i18next from 'i18next'
import { dashboardClient } from '../../../../api'
import { CommunicationType, MeasureValue, PointValue } from '../../../../api/dashboard'
import NotificationByDateChart from './NotificationByDateChart'
import Divider from '../../../../components/Divider'
import { FieldGroup, Select } from '../../../../components/ui'

export default function NotificationReport() {
  const { companyId, gameId, eventId } = useParams() as {
    companyId: string
    gameId: string
    eventId: string
  }

  const [selectedFilter, setSelectedFilter] = useState(7)
  const [type, setType] = useState<CommunicationType | undefined>(undefined)

  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState<MeasureValue | null>(null)
  const [readCount, setReadCount] = useState<MeasureValue | null>(null)

  const [totalByDate, setTotalByDate] = useState<PointValue[]>([])
  const [totalByDateRead, setTotalByDateRead] = useState<PointValue[]>([])

  const loadAllData = async () => {
    setLoading(true)

    const requests = [
      dashboardClient.v1.getCampaignCommunicationStat(eventId, companyId, gameId, {
        type: type,
        last_count_days: selectedFilter,
      }),
      dashboardClient.v1.getCampaignCommunicationStat(eventId, companyId, gameId, {
        is_read: true,
        type: type,
        last_count_days: selectedFilter,
      }),
      dashboardClient.v1.getCampaignCommunicationStatByDate(eventId, companyId, gameId, {
        type: type,
        last_count_days: selectedFilter,
      }),
      dashboardClient.v1.getCampaignCommunicationStatByDate(eventId, companyId, gameId, {
        is_read: true,
        type: type,
        last_count_days: selectedFilter,
      }),
    ]

    let res = await Promise.all(requests)

    setTotal(res[0].data as MeasureValue)
    setReadCount(res[1].data as MeasureValue)
    setTotalByDate(res[2].data as PointValue[])
    setTotalByDateRead(res[3].data as PointValue[])

    setLoading(false)
  }

  useEffect(() => {
    loadAllData()
  }, [companyId, gameId, eventId, selectedFilter, type])

  return (
    <div className="w-full px-6">
      <div className="flex  gap-2">
        <div style={{ width: '200px' }}>
          <FieldGroup label={i18next.t('campaign.date-filter')}>
            <Select
              options={[
                { children: i18next.t('filter.7days'), value: 7 },
                { children: i18next.t('filter.14days'), value: 14 },
              ]}
              value={selectedFilter}
              onChange={e => setSelectedFilter(e as number)}
            />
          </FieldGroup>
        </div>
        <div style={{ width: '200px' }}>
          <FieldGroup label={i18next.t('campaign.notifications.type')}>
            <Select
              options={[
                { children: i18next.t('campaign.notifications.all'), value: undefined },
                { children: i18next.t('campaign.notifications.HubPopup'), value: CommunicationType.HubPopup },
                { children: i18next.t('campaign.notifications.HubPush'), value: CommunicationType.HubPush },
                { children: i18next.t('campaign.notifications.Email'), value: CommunicationType.Email },
                { children: i18next.t('campaign.notifications.AppPush'), value: CommunicationType.AppPush },
                { children: i18next.t('campaign.notifications.AppPopup'), value: CommunicationType.AppPopup },
              ]}
              value={type}
              onChange={e => setType(e as CommunicationType)}
            />
          </FieldGroup>
        </div>
      </div>

      <Divider />

      <div
        className="mb-5 flex w-full items-center justify-start rounded-xl border border-border-secondary p-5"
        style={{
          boxShadow: '0 1px 2px 0 rgba(16, 24, 40, 0.05)',
        }}
      >
        <SingleValue
          width="20%"
          value={
            total
              ? {
                  value: total.value,
                }
              : undefined
          }
          label={i18next.t('campaign.notifications.total')}
        />
        <div style={{ width: '1px', height: '78px' }} className="mr-5 bg-bg-primary" />
        <SingleValue
          width="20%"
          value={
            readCount
              ? {
                  value: readCount.value,
                }
              : undefined
          }
          label={i18next.t('campaign.notifications.read')}
        />
      </div>

      <NotificationByDateChart totalByDate={totalByDate} totalByDateRead={totalByDateRead} loading={loading} />
    </div>
  )
}
