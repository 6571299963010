import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

/**
 *
 * @param companyId
 * @param gameId
 */
export const usePayoutsByMonthQuery = (companyId: string, gameId: string) => {
  return useQuery({
    queryKey: ['payouts-month', companyId],
    queryFn: () => dashboardClient.v1.getPayoutsByMonth(companyId, gameId),
    select: response => response.data,
  })
}
