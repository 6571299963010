import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const Logout: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 20 20" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.074 3.36465C14.6989 3.334 14.2137 3.33335 13.4998 3.33335H12.4998C12.0396 3.33335 11.6665 2.96026 11.6665 2.50002C11.6665 2.03978 12.0396 1.66669 12.4998 1.66669L13.5343 1.66669C14.2051 1.66668 14.7587 1.66667 15.2097 1.70352C15.6781 1.74179 16.1087 1.82392 16.5131 2.03C17.1403 2.34958 17.6503 2.85951 17.9699 3.48672C18.1759 3.89117 18.2581 4.32175 18.2963 4.79017C18.3332 5.24116 18.3332 5.79479 18.3332 6.46558V13.5345C18.3332 14.2053 18.3332 14.7589 18.2963 15.2099C18.2581 15.6783 18.1759 16.1089 17.9699 16.5133C17.6503 17.1405 17.1403 17.6505 16.5131 17.97C16.1087 18.1761 15.6781 18.2583 15.2097 18.2965C14.7587 18.3334 14.2051 18.3334 13.5343 18.3334H12.4998C12.0396 18.3334 11.6665 17.9603 11.6665 17.5C11.6665 17.0398 12.0396 16.6667 12.4998 16.6667H13.4998C14.2137 16.6667 14.6989 16.666 15.074 16.6354C15.4393 16.6055 15.6262 16.5514 15.7565 16.485C16.0701 16.3252 16.3251 16.0703 16.4848 15.7567C16.5513 15.6263 16.6054 15.4395 16.6352 15.0742C16.6659 14.6991 16.6665 14.2138 16.6665 13.5V6.50002C16.6665 5.78621 16.6659 5.30096 16.6352 4.92589C16.6054 4.56054 16.5513 4.3737 16.4848 4.24337C16.3251 3.92977 16.0701 3.6748 15.7565 3.51501C15.6262 3.4486 15.4393 3.3945 15.074 3.36465ZM7.25576 5.2441C7.5812 5.56954 7.5812 6.09717 7.25576 6.42261L4.51168 9.16669L12.4998 9.16669C12.9601 9.16669 13.3332 9.53978 13.3332 10C13.3332 10.4603 12.9601 10.8334 12.4998 10.8334L4.51168 10.8334L7.25576 13.5774C7.5812 13.9029 7.5812 14.4305 7.25576 14.7559C6.93032 15.0814 6.40268 15.0814 6.07725 14.7559L1.91058 10.5893C1.58514 10.2638 1.58514 9.7362 1.91058 9.41077L6.07725 5.2441C6.40269 4.91866 6.93032 4.91866 7.25576 5.2441Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
