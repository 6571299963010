import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const DotPoints: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12C8 11.4477 8.44772 11 9 11L21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13L9 13C8.44772 13 8 12.5523 8 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6C8 5.44772 8.44772 5 9 5L21 5C21.5523 5 22 5.44772 22 6C22 6.55229 21.5523 7 21 7L9 7C8.44772 7 8 6.55228 8 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 18C8 17.4477 8.44772 17 9 17L21 17C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19L9 19C8.44772 19 8 18.5523 8 18Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 10.8954 2.89543 10 4 10C5.10457 10 6 10.8954 6 12C6 13.1046 5.10457 14 4 14C2.89543 14 2 13.1046 2 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C2 4.89543 2.89543 4 4 4C5.10457 4 6 4.89543 6 6C6 7.10457 5.10457 8 4 8C2.89543 8 2 7.10457 2 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 18C2 16.8954 2.89543 16 4 16C5.10457 16 6 16.8954 6 18C6 19.1046 5.10457 20 4 20C2.89543 20 2 19.1046 2 18Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
