import { AgeSettings, Company, ErrorDetail, Game, HubApiError, JWTPayload, WebSite } from './types'

export class HubAPIException {
  detailObj: ErrorDetail[] | null
  detail: string
  field: string
  status: number

  constructor(detail: string, field: string, status: number, detailObj: ErrorDetail[] | null = null) {
    this.detail = detail
    this.field = field
    this.status = status
    this.detailObj = detailObj
  }

  getDetailStr() {
    return this.detail
  }
}

export async function callAPI<T>(
  fullUrl: string,
  body: unknown | null = null,
  method: string,
  authorizationHeaderValue: string,
): Promise<T> {
  let response
  try {
    response = await fetch(fullUrl, {
      method: method,
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: 'Bearer ' + authorizationHeaderValue,
      },
      body: body == null ? null : JSON.stringify(body),
    })
  } catch (e) {
    let needThrow = HUB_API.onGlobalErrorCallback(fullUrl, method, 500)
    return new Promise((_, reject) => {
      if (needThrow) {
        throw reject({ detail: 'Something went wrong' })
      }
    })
  }

  let jsonResponse = null
  try {
    jsonResponse = await response.json()
  } catch (e) {
    // @ts-ignore
    if (e.toString().indexOf('Unexpected end of JSON input') > -1) {
      return jsonResponse
    }
    //else ?
  }
  if (response.status >= 300) {
    let er
    if (jsonResponse) {
      if (Array.isArray(jsonResponse.detail)) {
        let loc = jsonResponse.detail[0].loc
        er = new HubAPIException(jsonResponse.detail[0].msg, loc[loc.length - 1], response.status, jsonResponse.detail)
      } else if (jsonResponse.detail) {
        er = new HubAPIException(jsonResponse.detail, '', response.status)
      }
    }

    let needThrow = HUB_API.onGlobalErrorCallback(fullUrl, method, response.status, er)
    if (needThrow) {
      throw er
    } else {
      return jsonResponse
    }
  } else {
    return jsonResponse
  }
}

export const URL_HUB_API = import.meta.env.VITE_HUB_API_URL

let _token: string
let companyCache: Company[] = []

export function parseJwt(token: string): JWTPayload {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

interface HubApi {
  onGlobalErrorCallback: (url: string, method: string, status: number, e?: HubApiError | undefined) => boolean
  setToken: (token: string) => void
  getToken: () => string
  get: <T>(url: string) => Promise<T>
  delet: <T>(url: string) => Promise<T>
  post: <T>(url: string, body: object | null) => Promise<T>
  postRoot: <T>(url: string, body: object | null) => Promise<T>
  postAbs: <T>(url: string, body: object | null) => Promise<T>
  put: <T>(url: string, body: object) => Promise<T>
  save: <T>(url: string, body: { id: string }) => Promise<T>
  loadCompanyAndGame: (companyId: string, gameId: string) => Promise<Game | null>
  getCompanies: (limit?: number, offset?: number) => Promise<Company[]>
  addToCache: (company: Company, game: Game) => void
  updateCompanyCache: (company: Company) => void
  getGame: (gameId: string | undefined) => Game | null
  getCompany: (id: string) => Company | undefined
  getAllGames: () => Game[]
  updateGameCache: (game: Game) => void
  getCompanyByGameId: (gameId: string) => Company | null
  getSite: (companyId: string, gameId: string, webSiteId?: string | null) => Promise<WebSite | null>
}

export const HUB_API: HubApi = {
  onGlobalErrorCallback: () => {
    return true
  },

  setToken: function (token) {
    _token = token
  },

  getToken: function () {
    return _token
  },

  get: async function (url) {
    return callAPI(URL_HUB_API + '/dashboard/v1' + url, null, 'GET', _token)
  },

  delet: async function (url) {
    return callAPI(URL_HUB_API + '/dashboard/v1' + url, null, 'DELETE', _token)
  },

  post: async function (url, body) {
    return callAPI(URL_HUB_API + '/dashboard/v1' + url, body, 'POST', _token)
  },

  postRoot: async function (url, body) {
    return callAPI(URL_HUB_API + url, body, 'POST', _token)
  },

  postAbs: async function (url, body) {
    return callAPI(url, body, 'POST', _token)
  },

  put: async function (url, body) {
    return callAPI(URL_HUB_API + '/dashboard/v1' + url, body, 'PUT', _token)
  },

  save: async function (url, body) {
    if (body.id) {
      return this.put(url + `/${body.id}`, body)
    } else {
      return this.post(url, body)
    }
  },

  loadCompanyAndGame: async function (companyId, gameId) {
    try {
      let company = await HUB_API.get<Company>(`/companies/${companyId}`)
      if (company) {
        let game = await HUB_API.get<Game>(`/companies/${companyId}/games/${gameId}`)
        if (game) {
          this.addToCache(company, game)
          return game
        }
      }
    } catch (e) {
      console.log(e)
    }
    return null
  },

  getCompanies: async function (limit = 10, offset = 0) {
    if (companyCache.length > offset) {
      return companyCache
    }

    let res = await this.get<Company[]>(`/companies/games?limit=${limit}&offset=${offset}`)

    if (!Array.isArray(res)) {
      res = []
    }

    let uniq = []

    for (let c of [...companyCache, ...res]) {
      let idx = uniq.findIndex(it => it.id == c.id)
      if (idx == -1) {
        uniq.push(c)
      }
    }

    companyCache = uniq

    return companyCache
  },

  addToCache: function (company, game) {
    let idx = companyCache.findIndex(it => it.id == company.id)
    if (idx > -1) {
      companyCache[idx].games.push(game)
    } else {
      company.games = [game]
      companyCache.push(company)
    }
  },

  updateCompanyCache: function (company) {
    let idx = companyCache.findIndex(it => it.id == company.id)
    if (idx > -1) {
      companyCache[idx] = company
    }
  },

  /**
   * deprecated use useCurrentGame() hook
   * @param gameId
   */
  getGame: function (gameId) {
    for (let c of companyCache) {
      let game = c?.games.find(it => it.id == gameId)
      if (game) {
        return game
      }
    }
    return null
  },

  getCompany: function (id) {
    return companyCache.find(it => it.id == id)
  },

  getAllGames: function () {
    let res = []
    for (let c of companyCache) {
      res.push(...c.games)
    }
    return res
  },

  updateGameCache: function (game) {
    for (let c of companyCache) {
      let gameIdx = c?.games.findIndex(it => it.id == game.id)
      if (gameIdx > -1) {
        c.games[gameIdx] = game
      }
    }
  },

  getCompanyByGameId: function (gameId) {
    for (let c of companyCache) {
      let game = c?.games.find(it => it.id == gameId)
      if (game) {
        return c
      }
    }
    return null
  },

  getSite: async function (companyId, gameId, webSiteId = null) {
    let site: WebSite | null = null
    if (webSiteId) {
      site = await HUB_API.get<WebSite>(`/companies/${companyId}/games/${gameId}/websites/${webSiteId}`)
    } else {
      let items = await HUB_API.get<WebSite[]>(`/companies/${companyId}/games/${gameId}/websites`)
      if (items?.length) {
        items.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
        site = await HUB_API.get<WebSite>(`/companies/${companyId}/games/${gameId}/websites/${items[0].id}`)
      }
    }
    if (site && !site.config.age_settings) {
      site.config.age_settings = {} as AgeSettings
    }

    return site
  },
}
