import { ReactNode, useMemo } from 'react'
import { Check } from '@/icons'
import { cn } from '@/libs'

export type StepperStepStatus = 'idle' | 'active' | 'done'

export interface StepperStepProps {
  pos: number
  step: { label: ReactNode; children: ReactNode; status: StepperStepStatus }
  neighborStatus?: StepperStepStatus
}

export const StepperStep = ({ pos, step, neighborStatus }: StepperStepProps) => {
  const lineStatus = useMemo(
    () => [step.status, neighborStatus].filter(Boolean).join('~'),
    [step.status, neighborStatus],
  )

  return (
    <div className="group mb-10 grid grid-cols-[auto_1fr] items-start gap-18">
      <div className="relative h-full">
        <div
          className={cn(
            'flex size-[28px] items-center justify-center rounded-md border-2 transition-colors',
            step.status === 'idle' && 'border-border-primary text-text-tertiary',
            step.status === 'active' && 'border-border-brand text-text-primary',
            step.status === 'done' && 'border-border-success bg-fg-success-solid text-text-inverse',
          )}
        >
          {step.status === 'done' ? (
            <Check strokeWidth={3} />
          ) : (
            <span className="-mb-0.5 font-nohemi font-semibold">{pos}</span>
          )}
        </div>

        <div
          className={cn(
            'absolute bottom-[-34px] left-[12px] top-[34px] w-[2px] rounded bg-border-secondary transition-all group-last:hidden',
            lineStatus === 'idle~idle' && '',
            lineStatus === 'idle~active' && 'bg-gradient-to-b from-border-secondary to-fg-brand-primary',
            lineStatus === 'idle~done' && 'bg-gradient-to-b from-border-secondary to-border-success',
            lineStatus === 'active~idle' && 'bg-gradient-to-b from-fg-brand-primary to-transparent',
            lineStatus === 'active~active' && 'bg-gradient-to-b from-fg-brand-primary to-fg-brand-primary',
            lineStatus === 'active~done' && 'bg-gradient-to-b from-fg-brand-primary to-border-success',
            lineStatus === 'done~idle' && 'bg-gradient-to-b from-border-success to-transparent',
            lineStatus === 'done~active' && 'bg-gradient-to-b from-border-success to-fg-brand-primary',
            lineStatus === 'done~done' && 'bg-gradient-to-b from-border-success to-border-success',
          )}
        />
      </div>
      <div className="overflow-hidden">
        <div className="mb-6 mt-1 text-t6">{step.label}</div>
        <div>{step.children}</div>
      </div>
    </div>
  )
}
