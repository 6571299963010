export default class SSE {
  url: string
  options: Record<string, unknown>
  private INITIALIZING: number
  private CONNECTING: number
  private OPEN: number
  private CLOSED: number
  private headers: unknown
  private payload: unknown
  private method: string | unknown
  private withCredentials: boolean
  private debug: boolean
  private FIELD_SEPARATOR: string
  private listeners: Record<string, (() => void)[]>
  private xhr: XMLHttpRequest | null
  private readyState: number
  private progress: number
  private chunk: string

  constructor(url: string, options: Record<string, unknown>) {
    this.url = url
    this.options = options || {}

    this.INITIALIZING = -1

    this.CONNECTING = 0

    this.OPEN = 1

    this.CLOSED = 2

    this.headers = options.headers || {}
    this.payload = options.payload !== undefined ? options.payload : ''
    this.method = options.method || (this.payload && 'POST') || 'GET'
    this.withCredentials = !!options.withCredentials
    this.debug = !!options.debug

    this.FIELD_SEPARATOR = ':'

    this.listeners = {}

    this.xhr = null

    this.readyState = this.INITIALIZING

    this.progress = 0

    this.chunk = ''

    if (options.start === undefined || options.start) {
      this.stream()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addEventListener(type: string, listener: any) {
    if (this.listeners[type] === undefined) {
      this.listeners[type] = []
    }

    if (this.listeners[type].indexOf(listener) === -1) {
      this.listeners[type].push(listener)
    }
  }

  removeEventListener(type: string, listener: () => void) {
    if (this.listeners[type] === undefined) {
      return
    }

    let filtered: (() => void)[] = []
    this.listeners[type].forEach(function (element: () => void) {
      if (element !== listener) {
        filtered.push(element)
      }
    })
    if (filtered.length === 0) {
      delete this.listeners[type]
    } else {
      this.listeners[type] = filtered
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatchEvent(e: any) {
    if (!e) {
      return true
    }

    if (this.debug) {
      console.debug(e)
    }

    e.source = this

    let onHandler = 'on' + e.type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((this as any).onHandler) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(this as any)[onHandler].call(this, e)
      if (e.defaultPrevented) {
        return false
      }
    }

    if (this.listeners[e.type]) {
      return this.listeners[e.type].every((callback: (e: unknown) => void) => {
        callback(e)
        return !e.defaultPrevented
      })
    }

    return true
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _setReadyState(state: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let event = new CustomEvent('readystatechange') as any
    event.readyState = state
    this.readyState = state
    this.dispatchEvent(event)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _onStreamFailure(e: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let event = new CustomEvent('error') as any
    event.data = e.currentTarget.response
    this.dispatchEvent(event)
    this.close()
  }

  _onStreamAbort() {
    this.dispatchEvent(new CustomEvent('abort'))
    this.close()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _onStreamProgress(e: any) {
    if (!this.xhr) {
      return
    }

    if (this.xhr.status !== 200) {
      this._onStreamFailure(e)
      return
    }

    if (this.readyState == this.CONNECTING) {
      this.dispatchEvent(new CustomEvent('open'))
      this._setReadyState(this.OPEN)
    }

    let data = this.xhr.responseText.substring(this.progress)

    this.progress += data.length
    let parts = (this.chunk + data).split(/(\r\n\r\n|\r\r|\n\n)/g)

    // we assume that the last chunk can be incomplete because of buffering or other network effects
    // so we always save the last part to merge it with the next incoming packet
    let lastPart = parts.pop()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parts.forEach((part: any) => {
      if (part.trim().length > 0) {
        this.dispatchEvent(this._parseEventChunk(part))
      }
    })
    if (lastPart) {
      this.chunk = lastPart
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _onStreamLoaded(e: any) {
    this._onStreamProgress(e)

    // Parse the last chunk.
    this.dispatchEvent(this._parseEventChunk(this.chunk))
    this.chunk = ''
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _parseEventChunk(chunk: any) {
    if (!chunk || chunk.length === 0) {
      return null
    }

    if (this.debug) {
      console.debug(chunk)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let e = { id: null, retry: null, data: null, event: 'message' } as any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chunk.split(/\n|\r\n|\r/).forEach((line: any) => {
      line = line.trimRight()
      let index = line.indexOf(this.FIELD_SEPARATOR)
      if (index <= 0) {
        // Line was either empty, or started with a separator and is a comment.
        // Either way, ignore.
        return
      }

      let field = line.substring(0, index)
      if (!(field in e)) {
        return
      }

      // only first whitespace should be trimmed
      let skip = line[index + 1] === ' ' ? 2 : 1
      let value = line.substring(index + skip)

      // consecutive 'data' is concatenated with newlines
      if (field === 'data' && e[field] !== null) {
        e['data'] += '\n' + value
      } else {
        e[field] = value
      }
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let event = new CustomEvent(e.event) as any
    event.data = e.data || ''
    event.id = e.id
    return event
  }

  _checkStreamClosed() {
    if (!this.xhr) {
      return
    }

    if (this.xhr.readyState === XMLHttpRequest.DONE) {
      this._setReadyState(this.CLOSED)
    }
  }

  stream() {
    if (this.xhr) {
      // Already connected.
      return
    }

    this._setReadyState(this.CONNECTING)

    this.xhr = new XMLHttpRequest()
    this.xhr.addEventListener('progress', this._onStreamProgress.bind(this))
    this.xhr.addEventListener('load', this._onStreamLoaded.bind(this))
    this.xhr.addEventListener('readystatechange', this._checkStreamClosed.bind(this))
    this.xhr.addEventListener('error', this._onStreamFailure.bind(this))
    this.xhr.addEventListener('abort', this._onStreamAbort.bind(this))
    this.xhr.open(this.method as string, this.url)
    // @ts-ignore
    for (let header in this.headers) {
      // @ts-ignore
      this.xhr.setRequestHeader(header, this.headers[header])
    }
    this.xhr.withCredentials = this.withCredentials
    // @ts-ignore
    this.xhr.send(this.payload)
  }

  close() {
    if (this.readyState === this.CLOSED) {
      return
    }

    this.xhr?.abort()
    this.xhr = null
    this._setReadyState(this.CLOSED)
  }
}
