export const AghanimLogotype = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M21.8358 28.272C20.0557 29.1348 18.06 29.6179 15.949 29.6179C13.8381 29.6179 11.8423 29.1348 10.0623 28.272H5.73324C8.51251 30.5998 12.0925 32 16.0007 32C19.9088 32 23.4901 30.5998 26.2681 28.272H21.8358ZM28.117 5.55036V10.2167C28.9799 11.9967 29.4629 13.9924 29.4629 16.1032C29.4629 18.2141 28.9799 20.2098 28.117 21.9897V26.4496C30.5376 23.6466 32 19.994 32 16C32 12.006 30.5362 8.35334 28.117 5.55036ZM16.0007 0C12.0925 0 8.51119 1.40017 5.73324 3.72804H10.5096C12.1745 2.99488 14.0141 2.58859 15.949 2.58859C17.8839 2.58859 19.7249 2.99488 21.3885 3.72804H26.2681C23.4888 1.40017 19.9088 0 16.0007 0ZM3.57335 5.92092C1.33934 8.67229 0 12.1793 0 16C0 19.8207 1.33802 23.3277 3.57335 26.0791V21.5438C2.84015 19.8789 2.43385 18.0394 2.43385 16.1045C2.43385 14.1697 2.84015 12.3289 3.57335 10.6653V5.92225V5.92092Z"
        fill="#0097E4"
      />
      <path
        d="M26.2016 4.60815H17.3542L18.6009 7.09352H24.5962C24.6822 7.09352 24.7511 7.16366 24.7511 7.24836V19.3522L27.2365 24.3058V5.64439C27.2365 5.07533 26.7707 4.60815 26.2003 4.60815H26.2016ZM5.4894 4.60815C4.92031 4.60815 4.45312 5.074 4.45312 5.64439V24.3944L6.93858 19.4356V7.24969C6.93858 7.16367 7.00873 7.09484 7.09343 7.09484H13.1231L14.3685 4.60948H5.48675L5.4894 4.60815Z"
        fill="#FF6CAF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0114 5.27765L30.6846 3.60446L30.6837 3.60352C31.146 3.14124 31.146 2.39073 30.6837 1.92845L30.1334 1.3782C29.6711 0.915921 28.9206 0.915927 28.4583 1.37821L26.785 3.0514L29.0114 5.27765ZM3.48279 1.40509L5.15605 3.07828L2.92972 5.30452L1.25646 3.63132C0.794159 3.16904 0.794153 2.41854 1.25645 1.95626L1.80672 1.40602C2.26902 0.943737 3.01955 0.943743 3.48185 1.40602L3.48279 1.40509ZM2.89893 26.8747L1.22567 28.5479L1.2266 28.5489C0.764302 29.0111 0.764314 29.7616 1.22661 30.2239L1.77688 30.7742C2.23918 31.2364 2.98971 31.2364 3.45201 30.7742L5.12527 29.101L2.89893 26.8747ZM26.7549 29.1279L28.4281 30.8011L28.4291 30.8001C28.8914 31.2624 29.6419 31.2624 30.1042 30.8001L30.6545 30.2499C31.1168 29.7876 31.1168 29.0371 30.6545 28.5748L28.9812 26.9016L26.7549 29.1279Z"
        fill="#FFDD40"
      />
      <path
        d="M27.1983 26.2697L16.5378 5.02241C16.2533 4.44805 15.434 4.44674 15.1468 5.01978L4.49695 26.2671C4.23888 26.7832 4.61474 27.3906 5.19177 27.3906H26.5022C27.0779 27.3906 27.4537 26.7859 27.1983 26.2697ZM23.0691 24.9397H8.5666C8.17353 24.9397 7.9009 24.9979 8.07692 24.6472L15.6365 9.42407C15.8311 9.03101 15.8377 8.9966 16.0508 9.42407L23.5416 24.6433C23.7163 24.994 23.4608 24.941 23.0678 24.941L23.0691 24.9397Z"
        fill="black"
      />
    </svg>
  )
}
