import { CSSProperties, forwardRef, useCallback, useState } from 'react'
import { Accept } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { AnimatePresence } from 'framer-motion'
import { fetchUploadImage } from '../../../api/files'
import { S3Bucket } from '../../../types'
import { PlaceholderContainer } from './PlaceholderContainer'
import { ImageSize, ValueContainer } from './ValueContainer'
import { Input } from '../Input'

interface ImageUploaderProps {
  accept: Accept
  value: string | null | undefined
  imageSize?: ImageSize
  bucket?: S3Bucket
  style?: CSSProperties
  disabled?: boolean
  onChange: (value: string | null) => void
  onLoadingChanged?: (loading: boolean) => void
}

export const ImageUploader = forwardRef<HTMLInputElement, ImageUploaderProps>(function ImageUploader(
  { accept, value, disabled = false, onChange, bucket = S3Bucket.news, style, onLoadingChanged, imageSize },
  ref,
) {
  const { t } = useTranslation()
  const [file, setFile] = useState<File | null>(null)
  const [progress, setProgress] = useState(0)

  const onUpload = useCallback(
    async (acceptedFiles: File[]) => {
      if (onLoadingChanged) {
        onLoadingChanged(true)
      }
      const file = acceptedFiles[0]

      setFile(file)
      setProgress(0.01)

      try {
        const data = await fetchUploadImage(file, { bucket: bucket, onProgress: setProgress })
        onChange(data.get_url)
        setFile(null)
        setProgress(0)
      } catch (err) {
        setFile(null)
        setProgress(0)
      }

      if (onLoadingChanged) {
        onLoadingChanged(false)
      }
    },
    [onChange],
  )

  const onDelete = () => {
    onChange('')
  }

  return (
    <div style={style}>
      <div className="mb-1.5">
        <AnimatePresence>
          {file || value ? (
            <ValueContainer
              file={file ? file : { src: value || '' }}
              progress={progress}
              disabled={disabled}
              imageSize={imageSize}
              onDelete={onDelete}
            />
          ) : (
            <PlaceholderContainer accept={accept} disabled={disabled} onUpload={onUpload} />
          )}
        </AnimatePresence>
      </div>
      <Input
        ref={ref}
        placeholder={t('upload-input.placeholder')}
        disabled={disabled || !!progress}
        value={value || ''}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  )
})
