const baselightTheme = {
  direction: 'ltr',
  palette: {
    primary: {
      main: '#0097E4',
      light0: '#EFF6FF',
      light: '#DEF1FF',
      light2: '#EFF9FF',
      light4: '#2CBCFF',
      light5: '#75D2FF',
      light6: '#74C6F0',
      light7: '#A2D9F5',
      light8: '#45B3EB',
      light9: '#E6F5FC',
      light10: '#D1ECFA',
      light11: '#68C9E7',
      dark: '#0082D4',
      dark1: '#0089CF',
      dark2: '#3B82F6',
      dark3: '#0067AB',
      dark4: '#006EA6',
      dark5: '#17A0E6',
      yellow: '#FFDD40',
    },
    secondary: {
      main: '#0097E4',
      light: '#DEF1FF',
      dark: '#0082D4',
      violet: '#8B5CF6',
    },
    success: {
      main: '#22C55E',
      light: '#BBF7D0',
      dark: '#15803D',
      contrastText: '#ffffff',
      text: '#14532D',
      background: '#F0FDF4',
      fgLimeSolid: '#84CC16',
    },
    info: {
      main: '#667085',
      light: '#F2F4F7',
      dark: '#475467',
      contrastText: '#ffffff',
    },
    error: {
      main: '#EF4444',
      light: '#FECACA',
      dark: '#B91C1C',
      contrastText: '#ffffff',
      light2: '#FCA5A5',
      dark2: '#991B1B',
      background: '#FFF0F7',
      light3: '#FF462D',
      textPrimary: '#DC2626',
    },
    warning: {
      main: '#F97316',
      light: '#FED7AA',
      dark: '#EA580C',
      contrastText: '#ffffff',
      light2: '#FDBA74',
      light3: '#FF9F1C',
    },
    purple: {
      A40: '#CFA9F3',
      A50: '#FFC4DF',
      A100: '#FF6CAF',
      A200: '#994169',
      A300: '#7C3AED',
      A400: '#e5d6fe',
    },
    grey: {
      50: '#F8FAFC',
      100: '#F2F4F7',
      130: '#FCFCFD',
      150: '#F9FAFB',
      200: '#EAECF0',
      250: '#E2E8F0',
      300: '#D0D5DD',
      400: '#98A2B3',
      500: '#667085',
      600: '#182230',
      1000: '#000',
      border: '#CBD5E1',
      buttonBorder: '#475569',
      borderPrimary: '#E2E8F0',
      borderSecondary: '#F1F5F9',
    },
    text: {
      primary: '#020617',
      primary2: '#101828',
      secondary: '#475569',
      table: '#475569',
      tertiary: '#64748B',
      input: '#94A3B8',
      focus: '#0F172A',
      inputLabel: '#020617',
      sideBar: '#475569',
      button: '#000000',
      hoverButton: '#fff',
      contrastText: '#ffffff',
      disabled: '#94A3B8',
      brandPrimary: '#0EA5E9',
      inverse: '#ffffff',
    },
    action: {
      disabledBackground: 'rgba(73,82,88,0.12)',
      hoverOpacity: 0.02,
      hover: '#f6f9fc',
    },
    divider: '#e5eaef',
    background: {
      fgskeleton: '#F1F5F9',
      primary: '#F1F5F9',
      body: '#F2F4F7',
      default: '#F9FAFB',
      content: '#fff',
      header: '#F1F5F9',
      progress: '#7F56D9',
      fgPrimary: '#fff',
      fgSecondary: '#F8FAFC',
      fgTooltip: '#000000CC',
      fgErrorTertiary: '#FEF2F2',
    },
  },
}

const baseDarkTheme = {
  direction: 'ltr',
  palette: {
    primary: {
      main: '#5D87FF',
      light: '#ECF2FF',
      dark: '#4570EA',
    },
    secondary: {
      main: '#777e89',
      light: '#1C455D',
      dark: '#173f98',
    },
    success: {
      main: '#13DEB9',
      light: '#1B3C48',
      dark: '#02b3a9',
      contrastText: '#ffffff',
      text: '#14532D',
    },
    info: {
      main: '#539BFF',
      light: '#223662',
      dark: '#1682d4',
      contrastText: '#ffffff',
    },
    error: {
      main: '#FA896B',
      light: '#4B313D',
      dark: '#f3704d',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#FFAE1F',
      light: '#4D3A2A',
      dark: '#ae8e59',
      contrastText: '#ffffff',
    },
    purple: {
      A50: '#EBF3FE',
      A100: '#6610f2',
      A200: '#557fb9',
    },
    grey: {
      100: '#333F55',
      200: '#465670',
      300: '#7C8FAC',
      400: '#DFE5EF',
      500: '#EAEFF4',
      600: '#F2F6FA',
    },
    text: {
      primary: '#EAEFF4',
      secondary: '#7C8FAC',
    },
    action: {
      disabledBackground: 'rgba(73,82,88,0.12)',
      hoverOpacity: 0.02,
      hover: '#333F55',
    },
    divider: '#333F55',
    background: {
      default: '#171c23',
      dark: '#171c23',
      paper: '#171c23',
    },
  },
}

export { baseDarkTheme, baselightTheme }

declare module '@mui/material/styles/createPalette' {
  export interface PaletteColor {
    main: string
    light0: string
    light: string
    light2: string
    light3: string
    light4: string
    light5: string
    light6: string
    light7: string
    light8: string
    light9: string
    light10: string
    light11: string
    dark: string
    dark1: string
    dark2: string
    dark3: string
    dark4: string
    dark5: string
    text: string
    background: string
    violet: string
    fgLimeSolid: string
    yellow: string
  }

  export interface TypeText {
    primary2: string
    table: string
    tertiary: string
    input: string
    focus: string
    inputLabel: string
    sideBar: string
    button: string
    hoverButton: string
    contrastText: string
  }

  export interface TypeBackground {
    default: string
    paper: string
    body: string
    primary: string
    header: string
    content: string
    progress: string
    fgPrimary: string
    fgSecondary: string
    fgskeleton: string
  }

  export interface Palette {
    purple: {
      A40: string
      A50: string
      A100: string
      A200: string
      A300: string
      A400: string
    }
  }
}

declare module '@mui/material' {
  export interface Color {
    50: string
    130: string
    150: string
    250: string
    1000: string
    border: string
    buttonBorder: string
    borderPrimary: string
    borderSecondary: string
  }
}
