import { EditIcon } from '../../../icons/Icons'
import i18next from 'i18next'
import { CSSProperties } from 'react'
import { cn } from '../../../libs/cn'
import { Button } from '@/ui'

export interface PageBlockProps {
  children?: React.ReactNode
  title?: string
  editClick?: () => void
  btnText?: string
  cancelBtnText?: string
  cancelClick?: () => void
  isLoading?: boolean
  btnIcon?: React.ReactNode
  padding?: number
  style?: CSSProperties
  className?: string
}

export default function PageBlock(props: PageBlockProps) {
  return (
    <div
      className={cn('rounded-2xl1 border-2 border-border-primary bg-fg-primary p-6', props.className)}
      style={{
        boxShadow: '0px 1px 2px 0px #1018280D',
        ...props.style,
      }}
    >
      {props.title && (
        <div className="mb-18 flex h-[33px] items-center justify-between">
          <div className="text-title-t4 font-semibold text-text-primary">{props.title}</div>

          <div className="flex gap-2">
            {props.cancelClick && (
              <Button onClick={props.cancelClick} color="secondary" variant="outline">
                {props.cancelBtnText || i18next.t('Cancel')}
              </Button>
            )}

            {props.editClick && (
              <Button onClick={props.editClick} color="primary" className="gap-2" size="md">
                {props.btnIcon || <EditIcon />}
                {props.btnText || i18next.t('Edit')}
              </Button>
            )}
          </div>
        </div>
      )}
      <div>{props.children}</div>
    </div>
  )
}
