import './index.css'
import { cn } from '../../../libs/cn'
import { CSSProperties } from 'react'

/**
 * @deprecated please use `src/components/ui/Spinner`
 * @param props
 * @constructor
 */
export const IconLoader = (props: { className?: string; style?: CSSProperties }) => {
  return (
    <span className={cn('icon-loader', props.className)} style={props.style}>
      <svg viewBox="22 22 44 44">
        <circle className="svg-loader" cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" />
      </svg>
    </span>
  )
}
