import {
  Campaign,
  CampaignEventType,
  ConditionNode,
  Expression,
  PeriodType,
  RotationType,
  StoreItemRead,
  StoreRead,
  StoreType,
  Token,
} from '@/api/dashboard'
import i18next from 'i18next'

import { SelectSkuItem } from '../../campaigns/components/SelectSkuItem/SelectSkuItem'
import { Controller, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { Settings } from '@/components/ui/Settings'
import {
  Button,
  DrawerContent,
  DrawerTopbar,
  FieldGroup,
  FieldValidationMessage,
  Input,
  InputExtraMark,
  Select,
} from '@/ui'
import { useParams } from 'react-router-dom'
import { ErrorMessage } from '@hookform/error-message'
import Divider from '../../../components/Divider'
import { useGameItem } from '@/api/useGameItems'
import ConditionEditor from '../../campaigns/expr/ConditionEditor'
import { CampaignContext, EditorMode, ICampaignContext } from '../../campaigns/Context'
import { validateConditionTokens } from '../../campaigns/validation'
import { getTokenGroups } from '../../campaigns/util'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { ItemTimelimit } from '@/layouts/store/components/ItemTimelimit'
import { clearStoreItemEmptyFields } from '@/layouts/store/util'
import { PropertyCheckboxControl } from '@/layouts/game-items/components'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import { InfoCircle } from '@/icons'

export default function StoreItemSettings(props: {
  item: StoreItemRead
  hideIds?: string[]
  onClose: (item: StoreItemRead | null) => void
  store: StoreRead
}) {
  const [errorTokens, setErrorTokens] = useState<Token[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const {
    setValue,
    watch,
    control,
    register,
    reset,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isDirty, errors },
  } = useForm<StoreItemRead>({
    values: {
      ...props.item,
    },
  })
  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)

  useEffect(() => {
    reset(props.item)
  }, [props.item])

  const [max_purchases, max_purchases_period_type, is_free_item, end_at, start_at, item_id] = watch([
    'max_purchases',
    'max_purchases_period_type',
    'is_free_item',
    'end_at',
    'start_at',
    'item_id',
  ])

  const { data: item = null } = useGameItem(companyId, gameId, item_id)

  const onSave = async (data: StoreItemRead) => {
    if (data.requirements_expression?.tokens?.length) {
      let { error, errorTokens: errTokens } = validateConditionTokens(
        getTokenGroups(data.requirements_expression?.tokens || []),
        getCampaignContext(),
      )
      setErrorTokens(errTokens || [])
      setErrorMessage(error)

      if (errTokens?.length) {
        return
      }
    } else {
      data.requirements_expression = null as unknown as Expression
    }

    if (data.start_at && data.end_at && data.start_at > data.end_at) {
      setError('start_at', { message: i18next.t('validation.start_date') })
      return
    }

    if (!data.max_purchases) {
      data.max_purchases = null as unknown as number
      data.max_purchases_period_type = null as unknown as PeriodType
      data.max_purchases_period_value = null as unknown as number
    }

    clearStoreItemEmptyFields(data, !!item?.is_stackable)

    if (item) {
      data.item = item
      props.onClose(data)
    }
  }

  const renderBenefits = () => {
    if (!item) {
      return null
    }
    return (
      <>
        <Settings text={i18next.t('store.item.benefits')}>
          <div className="mb-3 flex gap-3">
            <PropertyCheckboxControl
              size="md"
              className="w-1/2"
              value={!is_free_item}
              type="radio"
              label={i18next.t('store.item.paid_item')}
              desc={i18next.t('store.item.paid_item.desc')}
              onChange={() => {
                setValue('is_free_item', false, { shouldDirty: true })
              }}
            />

            <PropertyCheckboxControl
              size="md"
              type="radio"
              className="w-1/2"
              value={!!is_free_item}
              label={i18next.t('store.item.free_item')}
              desc={i18next.t('store.item.free_item.desc')}
              onChange={() => {
                setValue('is_free_item', true, { shouldDirty: true })
                if (!max_purchases) {
                  setValue('max_purchases', 1)
                }
              }}
            />
          </div>
          {!is_free_item && (
            <FieldGroup label={i18next.t('store.item.discount')}>
              <Input
                extraLeft={<InputExtraMark>%</InputExtraMark>}
                type={'number'}
                min={1}
                {...register('discount_percent', {
                  validate: value => {
                    if (value) {
                      if (value < 0 || value > 99) {
                        return i18next.t('campaign.block.ItemDiscountOfferActionNode.discount-percent.error')
                      }
                      return true
                    }
                  },
                })}
              />
              <ErrorMessage
                name="discount_percent"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </FieldGroup>
          )}

          {item?.is_stackable && (
            <FieldGroup label={i18next.t('store.item.bonus')}>
              <Input
                extraLeft={<InputExtraMark>%</InputExtraMark>}
                type={'number'}
                min={1}
                {...register('bonus_percent', {
                  required: false,
                  min: 1,
                  max: 1000,
                })}
              />
              <ErrorMessage
                name="bonus_percent"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </FieldGroup>
          )}

          {gameSettings?.enable_reward_points && !is_free_item && (
            <FieldGroup label={i18next.t('campaign.virtual-sku-editor.reward_point_percent')}>
              <Input
                type="number"
                min={1}
                {...register('reward_points_percent', {
                  min: { value: 1, message: i18next.t('campaign.virtual-sku-editor.reward_point_percent.validation') },
                  max: {
                    value: 100,
                    message: i18next.t('campaign.virtual-sku-editor.reward_point_percent.validation'),
                  },
                })}
                extraLeft={<InputExtraMark>%</InputExtraMark>}
              />
              <ErrorMessage
                name="reward_points_percent"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </FieldGroup>
          )}
        </Settings>
      </>
    )
  }

  const renderMaxPurchases = () => {
    return (
      <Settings
        text={i18next.t(is_free_item ? 'store.item.max_claim_limitation' : 'store.item.max_purchase_limitation')}
      >
        <FieldGroup label={i18next.t(is_free_item ? 'store.item.max_claim' : 'store.item.max_purchase')}>
          <Input
            type={'number'}
            min={1}
            {...register('max_purchases', {
              min: { value: 1, message: i18next.t('coupon.max_redemptions.validation') },
              max: { value: 100_000_000, message: i18next.t('coupon.max_redemptions.validation') },
            })}
          />
          <ErrorMessage
            name="max_purchases"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>
        <CheckBoxContainer
          variant="background"
          caption={i18next.t('store.item.max_purchases_period_value.desc')}
          label={i18next.t('store.item.max_purchases_period_value.title')}
          disabled={!max_purchases}
          checked={!!max_purchases_period_type && !!max_purchases}
          onChange={e => {
            setValue('max_purchases_period_type', e.target.checked ? PeriodType.Day : (null as unknown as undefined), {
              shouldDirty: true,
            })
          }}
        >
          <FieldGroup
            label={i18next.t(
              is_free_item ? 'store.item.max_purchases_period_value.free' : 'store.item.max_purchases_period_value.buy',
              { value: max_purchases },
            )}
          >
            <div className="flex gap-18">
              <div className="w-1/2">
                <Input
                  min={1}
                  type="number"
                  {...register('max_purchases_period_value', {
                    required: max_purchases_period_type ? i18next.t('validation.required') : false,
                    min: {
                      value: 1,
                      message: i18next.t('validation.min-no-field', { value: 1 }),
                    },
                  })}
                />
              </div>
              <div className="w-1/2">
                <Controller
                  control={control}
                  name="max_purchases_period_type"
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={Object.values(PeriodType)
                        .filter(it => it !== PeriodType.Second)
                        .map(value => ({
                          children: i18next.t(`store.rotation.period.type.${value}`),
                          value: value,
                        }))}
                    />
                  )}
                />
              </div>
            </div>
          </FieldGroup>

          <ErrorMessage
            name="max_purchases_period_value"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </CheckBoxContainer>
      </Settings>
    )
  }

  const getCampaignContext = () => {
    return {
      campaign: {
        type: CampaignEventType.CustomEvent,
      } as unknown as Campaign,
      playerCustomAttributes: gameSettings?.player_attributes || [],
      readOnly: false,
      historyMode: false,
      mode: EditorMode.StoreItem,
    } as unknown as ICampaignContext
  }

  const renderConditions = () => {
    if (!item) {
      return null
    }

    return (
      <CampaignContext.Provider value={getCampaignContext()}>
        <FieldGroup label={i18next.t('store.item.requirements_expression')}>
          <Controller
            control={control}
            name="requirements_expression"
            rules={{
              validate: (v: Expression | undefined) => {
                if (!v?.tokens?.length) {
                  return true
                }
                return true
              },
            }}
            render={({ field }) => (
              <ConditionEditor
                errTokens={errorTokens}
                errMessage={errorMessage}
                mode="onchange"
                node={
                  {
                    id: props.item.item_id,
                    expr: field.value || {
                      tokens: [],
                    },
                  } as ConditionNode
                }
                setNode={n => {
                  field.onChange(n.expr)
                }}
              />
            )}
          />
        </FieldGroup>
      </CampaignContext.Provider>
    )
  }

  const renderBadgeSettings = () => {
    if (!item) {
      return null
    }
    return (
      <>
        <Settings text={i18next.t('store.item.custom_badge')}>
          <FieldGroup label={i18next.t('store.item.custom_badge_text')}>
            <Input {...register('custom_badge')} maxLength={30} />
            <ErrorMessage
              name="custom_badge"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>
        </Settings>
      </>
    )
  }

  const renderWeightSettings = () => {
    if (!item) {
      return null
    }
    return (
      <>
        <Settings text={i18next.t('store.item.rotation_weight')}>
          <FieldGroup label={i18next.t('store.item.weight')}>
            <Input
              {...register('rotation_weight', {
                min: { value: 0, message: i18next.t('store.item.weight.validation') },
                max: { value: 1000, message: i18next.t('store.item.weight.validation') },
              })}
              min={0}
              type={'number'}
            />
            <ErrorMessage
              name="rotation_weight"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
            <div className="mt-1.5 flex items-start gap-2 text-text-secondary">
              <InfoCircle size={14} className="mt-1 min-w-[14px]" />
              <span className="text-sm leading-normal">{i18next.t('store.item.rotation_weight.desc')}</span>
            </div>
          </FieldGroup>
        </Settings>
      </>
    )
  }

  const renderTimeLimitations = () => {
    return (
      <ItemTimelimit
        showDuration={false}
        setValue={(prop, value) => {
          clearErrors('start_at')
          setValue(prop as 'start_at' | 'end_at', value, { shouldDirty: true })
        }}
        start_at={start_at}
        end_at={end_at}
      >
        <ErrorMessage
          name="start_at"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </ItemTimelimit>
    )
  }

  return (
    <div className="flex h-full flex-col overflow-auto bg-fg-primary">
      <DrawerTopbar>
        <h2 className="mr-auto text-title-t5">{i18next.t('store.item.settings')}</h2>
        <div className="flex gap-2.5">
          <Button onClick={() => props.onClose(null)} color="secondary" data-testid="sku/store/item/cancel">
            {i18next.t('Cancel')}
          </Button>
          <Button
            variant="primary"
            disabled={!isDirty}
            onClick={handleSubmit(onSave)}
            data-testid="sku/store/item/submit"
          >
            {i18next.t(props.item.item ? 'apply' : 'Add')}
          </Button>
        </div>
      </DrawerTopbar>

      <DrawerContent>
        <Settings text={i18next.t('store.item.general')}>
          <Controller
            control={control}
            name="item_id"
            rules={{ required: i18next.t('validation.required') }}
            render={({ field }) => (
              <SelectSkuItem
                showPrice={true}
                disabled={!!props.item.item}
                hideIds={props.item.item ? undefined : props.hideIds}
                label={i18next.t('store.item')}
                onChange={v => field.onChange(v)}
                value={field.value}
              />
            )}
          />
        </Settings>

        {item && (
          <>
            <Divider />
            {renderBenefits()}

            <Divider />
            {renderMaxPurchases()}
            {props.store.type == StoreType.Rotation &&
              props.store.rotation_settings?.type == RotationType.RandomByWeight && (
                <>
                  <Divider />
                  {renderWeightSettings()}
                </>
              )}
            <Divider />
            {renderTimeLimitations()}

            <Divider />
            {renderBadgeSettings()}

            <Divider />
            {renderConditions()}
          </>
        )}
      </DrawerContent>
    </div>
  )
}
