import { Fragment, useContext, useState } from 'react'

import {
  Checkbox,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowSkeleton,
  TableZeroState,
} from '../../../../components/ui'

import i18next from 'i18next'
import { useCampaignUsers } from '../../api/useUsersCampaignQuery'
import { generatePath, useParams } from 'react-router-dom'
import { CampaignContext, ICampaignContext } from '../../Context'
import HistoryAttrView from './HistoryAttrView'
import { LIVEOPS_ITEM_DETAILS_PATH } from '../../../../libs/routerPaths'
import { useDebounce } from '../../../../hooks'
import { TableSearch } from '../../../../components/ui/TableSearch'
import { CampaignUserHistoryItem, CampaignUserStatus } from '@/api/dashboard'
import ToolTipBox from '@/layouts/dashboard/components/ToolTip/ToolTipBox'
import { DateTimeValue } from '@/components/ui/DateTimeValue'

export default function RunHistory() {
  const { companyId, gameId, eventId, userCampaignId } = useParams() as {
    companyId: string
    gameId: string
    eventId: string
    userCampaignId: string
  }
  const LIMIT = 20
  const context = useContext(CampaignContext) as ICampaignContext
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState(userCampaignId || '')
  const debouncedSearch = useDebounce(search, 300)

  const { data: items = [], isLoading } = useCampaignUsers(companyId, gameId, context.campaign.id, {
    offset: (page - 1) * LIMIT,
    limit: LIMIT,
    search_string: debouncedSearch,
  })

  const needShowPagination = () => {
    if (isLoading) {
      return false
    }
    if (items.length === 0 && page == 1) {
      return false
    }

    return true
  }

  const isEmptyHistory = () => {
    if (items.length === 0 && page == 1 && !search) {
      return true
    }

    return false
  }

  const renderErrorCell = (it: CampaignUserHistoryItem) => {
    if (it.error_text == 'max_node_visit') {
      return (
        <div className="flex items-center gap-1">
          {i18next.t(`campaign.history.status.${it.status}`)}
          <ToolTipBox tooltip={i18next.t('campaign.history.max_node_visit')} />
        </div>
      )
    }

    return i18next.t(`campaign.history.status.${it.status}`)
  }

  return (
    <div
      style={{ pointerEvents: context?.readOnly ? 'none' : 'all', opacity: context?.readOnly ? 0.6 : 1 }}
      className="h-full"
    >
      {!isEmptyHistory() && (
        <div className="mb-2.5">
          <TableSearch
            placeholder={i18next.t('search')}
            value={search}
            onChange={e => {
              setSearch(e.target.value)
              setPage(1)
            }}
          />
        </div>
      )}

      {!isLoading && isEmptyHistory() && (
        <TableZeroState
          className="h-full"
          title={i18next.t('campaign.history.empty')}
          message={i18next.t('campaign.history.empty.desc')}
        />
      )}

      {isLoading && <TableRowSkeleton rowCount={10} columnCount={4} />}

      {!isLoading && items.length > 0 && (
        <Table>
          <TableRow variant="header">
            <TableCell width="10%" />
            <TableCell>{i18next.t('campaign.history.player_id')}</TableCell>
            <TableCell>{i18next.t('campaign.history.created_at')}</TableCell>
            <TableCell>{i18next.t('campaign.history.status')}</TableCell>
            <TableCell>{i18next.t('campaign.history.end_at')}</TableCell>
          </TableRow>

          {items.map(it => (
            <Fragment key={it.id}>
              <TableRow
                className={it.id == userCampaignId ? 'bg-fg-gray-secondary' : ''}
                to={generatePath(LIVEOPS_ITEM_DETAILS_PATH, { companyId, gameId, eventId, userCampaignId: it.id })}
              >
                <TableCell width="10%">
                  <Checkbox type="radio" checked={it.id == userCampaignId} onChange={() => {}} />
                </TableCell>
                <TableCell>{it.player_id}</TableCell>
                <TableCell>
                  <DateTimeValue value={it.created_at} />
                </TableCell>
                <TableCell>
                  {it.status == CampaignUserStatus.Failure
                    ? renderErrorCell(it)
                    : i18next.t(`campaign.history.status.${it.status}`)}
                </TableCell>
                <TableCell>{it.finish_at && <DateTimeValue value={it.finish_at} />}</TableCell>
              </TableRow>
              {it.id == userCampaignId && it.event_action_body && <HistoryAttrView body={it.event_action_body} />}
            </Fragment>
          ))}
        </Table>
      )}

      {needShowPagination() && (
        <Pagination
          hasNext={items.length >= LIMIT}
          page={page}
          onPageChange={p => setPage(p)}
          pageItems={items.length}
        />
      )}
    </div>
  )
}
