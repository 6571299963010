import { useState } from 'react'
import i18next from 'i18next'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { DotsHorIcon } from '../icons/Icons'
import { SxProps } from '@mui/system/styleFunctionSx'
import { Theme } from '@mui/material/styles'

interface IProps {
  onEditClick?: () => void
  onRemoveClick?: () => void
  customItems?: ({ label: string; onClick: () => void } | undefined)[]
  sx?: SxProps<Theme>
  menuWidth?: string
}

const GridRowEditButton = (props: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <>
      {anchorEl && (
        <Menu sx={{ width: props.menuWidth }} anchorEl={anchorEl} open={true} onClose={() => setAnchorEl(null)}>
          {props.onEditClick && (
            <MenuItem
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                setAnchorEl(null)
                if (props.onEditClick) {
                  props.onEditClick()
                }
              }}
            >
              {i18next.t('grid.edit')}
            </MenuItem>
          )}
          {props.onRemoveClick && (
            <MenuItem
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                setAnchorEl(null)
                if (props.onRemoveClick) {
                  props.onRemoveClick()
                }
              }}
            >
              {i18next.t('grid.remove')}
            </MenuItem>
          )}
          {props.customItems
            ?.filter(it => !!it)
            .map(it => (
              <MenuItem
                key={it!.label}
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  it!.onClick()
                  setAnchorEl(null)
                }}
              >
                {it!.label}
              </MenuItem>
            ))}
        </Menu>
      )}
      <IconButton
        sx={props.sx}
        onClick={e => {
          setAnchorEl(e.currentTarget)
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <DotsHorIcon />
      </IconButton>
    </>
  )
}

export default GridRowEditButton
