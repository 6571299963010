import { useTranslation } from 'react-i18next'
import { LocaleRead, LocaleStatus } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { ModalConfirm, ModalProps } from '@/ui'
import { useGenerateCompanyPath } from '@/libs'
import { localeUpdateMutation } from '../api'

interface L10nLocaleModalPublishProps extends ModalProps {
  locale: LocaleRead
}

export const L10nLocaleModalPublish = ({ locale, ...rest }: L10nLocaleModalPublishProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useGenerateCompanyPath()
  const { mutateAsync } = localeUpdateMutation()

  const onConfirm = async () => {
    await mutateAsync({ localeId: locale.id, data: { status: LocaleStatus.Synchronization } })
    await dashboardClient.v1.scheduleSyncAllJob(companyId, gameId, { locale_id: locale.id })
    rest.onClose?.()
  }

  return (
    <ModalConfirm
      {...rest}
      message={t('localization.locale.publish-modal.title')}
      subMessage={t('localization.locale.publish-modal.text')}
      confirmButtonText={t('localization.locale.publish-modal.confirm')}
      color="primary"
      onConfirm={onConfirm}
    />
  )
}
