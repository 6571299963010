import { InputHTMLAttributes, forwardRef, useId } from 'react'
import styled from '@emotion/styled'
import { cva } from 'class-variance-authority'
import { cn } from '@/libs'
import { css } from '@emotion/react'
import shouldForwardProp from '@emotion/is-prop-valid'
import { Spinner } from '@/components/ui'

type ToogleVariant = 'primary' | 'secondary' | 'dark-secondary'

const StyledToggle = styled('div', { shouldForwardProp })<{ $variant: ToogleVariant }>`
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    appearance: none;
  }

  input:checked + [data-id='track'] [data-id='thumb'] {
    transform: translateX(calc(100% + ${p => p.theme.spacing(0.25)}));
  }

  input[disabled] + [data-id='track'] {
    opacity: 0.7;
    cursor: initial;
  }

  ${p =>
    p.$variant === 'primary' &&
    css`
      input:focus + [data-id='track'] {
        box-shadow: 0 0 0 2px rgba(0, 151, 228, 0.14);
      }
    `}

  ${p =>
    p.$variant === 'secondary' &&
    css`
      input:focus + [data-id='track'] {
        box-shadow: 0 0 0 2px rgba(100, 116, 139, 0.1);
      }
    `}
`

const variants = cva('rounded-full bg-bg-secondary', {
  variants: {
    variant: {
      primary: 'bg-bg-primary',
      secondary: 'bg-bg-primary',
      'dark-secondary': 'bg-fg-black-10',
    },
    sizeThumb: {
      lg: 'size-6',
      md: 'size-4',
    },
    sizeTrack: {
      lg: 'h-28 w-[54px]',
      md: 'h-[20px] w-[38px]',
    },
  },
})

interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: ToogleVariant
  sizev?: 'lg' | 'md'
  loading?: boolean
}

const Track = styled.label``

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(function Toggle(props, ref) {
  const id = useId()
  const { variant = 'primary', sizev = 'lg', loading, disabled } = props
  return (
    <StyledToggle $variant={variant}>
      <input {...props} ref={ref} id={id} type="checkbox" disabled={disabled || loading} />
      <Track
        className={cn(
          variants({ sizeTrack: sizev, variant: variant }),
          'relative flex cursor-pointer rounded-[24px]  p-0.5 outline-none',
          (loading || props.checked) && (variant == 'primary' ? 'bg-fg-brand-primary' : 'bg-fg-warning-solid'),
        )}
        style={{
          transition: 'background-color ease-in-out 160ms',
        }}
        htmlFor={id}
        data-id="track"
      >
        {loading ? (
          <Spinner className="ml-auto text-text-inverse" size={sizev == 'md' ? 16 : 24} />
        ) : (
          <div
            data-id="thumb"
            className={cn(variants({ sizeThumb: sizev }))}
            style={{
              transition: 'transform ease-in-out 160ms',
              boxShadow: '0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06)',
            }}
          />
        )}
      </Track>
    </StyledToggle>
  )
})
