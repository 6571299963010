import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { NewsGroup, NewsSortField, SortOrder } from '../../../api/dashboard'

type Query = {
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 100
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number

  group?: NewsGroup

  sort_field?: NewsSortField

  sort_order?: SortOrder
}

/**
 *
 * @param companyId
 * @param gameId
 * @param query
 */
export const useNewsQuery = (companyId: string, gameId: string, query: Query) => {
  return useQuery({
    queryKey: ['news', gameId, query],
    queryFn: () => dashboardClient.v1.getNewsList(companyId, gameId, query),
    select: response => response.data,
  })
}
