import { useContext } from 'react'
import styled from '@emotion/styled'
import { Campaign } from '../../../../api/dashboard'
import { Link } from 'react-router-dom'
import { Badge, TableRowEditButton, Toggle } from '../../../../components/ui'
import i18next from 'i18next'
import { isCustomEvent } from '../../Settings'
import TimeLimitInfo from './TimeLimitInfo'
import getCampaignName from './getCampaignName'
import { AuthContext, IAuthContext } from '../../../../Context'
import { canEdit } from '../../../../security'
import { getCampaignIconUrl } from '../../util'
import { Trash } from '../../../../components/icons'
import { ByAghanim } from '@/layouts/campaigns/pages/CampaignTable/ByAghanim'

interface CampaignTableRowProps {
  campaign: Campaign
  to: string
  onDeleteClick?: () => void | undefined
  onClickToggle?: () => Promise<void>
}

const CampaignNameBox = styled.div`
  display: flex;
  gap: ${p => p.theme.spacing(1.5)};
  flex-direction: row;
`

const ToggleLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: ${p => p.theme.palette.text.tertiary};
`

const ToggleContainer = styled.div`
  margin-top: 16px;
`

export const CampaignRow = ({ campaign, to, onClickToggle, onDeleteClick }: CampaignTableRowProps) => {
  const isCustom = isCustomEvent(campaign.type)
  const context = useContext(AuthContext) as IAuthContext
  const isReadOnly = !canEdit(context.customer)
  return (
    <div key={campaign.id} className="flex items-center p-18 ">
      <Link to={to} className="flex gap-9">
        <img
          className="size-[98px] rounded-md border border-border-primary"
          src={getCampaignIconUrl(campaign.type)}
          alt={campaign.type}
        />
        <div className="flex flex-col gap-1">
          <CampaignNameBox>
            <div className="text-paragraph-md font-semibold  text-text-secondary">{getCampaignName(campaign.type)}</div>
            {isCustom && <Badge variant="gray-secondary">{i18next.t('campaign.type.desc.custom_event_title')}</Badge>}
          </CampaignNameBox>
          <div className="text-paragraph-sm text-text-tertiary">
            {isCustom ? campaign.name : i18next.t(`campaign.type.desc.${campaign.type}`)}
          </div>

          {!isCustom && <ByAghanim />}
        </div>
      </Link>
      <div className="ml-auto flex items-center gap-9">
        {campaign.time_limit && (campaign.end_at || campaign.start_at) && <TimeLimitInfo campaign={campaign} />}
        <ToggleContainer>
          <Toggle checked={campaign.enabled} onChange={onClickToggle} disabled={isReadOnly} />
          <ToggleLabel>{i18next.t(campaign.enabled ? 'campaign.enabled.on' : 'campaign.enabled.draft')}</ToggleLabel>
        </ToggleContainer>
        {onDeleteClick && (
          <TableRowEditButton
            onChange={() => onDeleteClick()}
            options={[
              {
                icon: <Trash className="text-text-error-primary" />,
                children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                value: 'remove',
              },
            ]}
          />
        )}
      </div>
    </div>
  )
}
