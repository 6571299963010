import i18next from 'i18next'
import { useParams } from 'react-router-dom'
import { AchievementRead, AchievementType } from '@/api/dashboard'
import { useState } from 'react'
import { Button, FieldGroup, FieldValidationMessage, ImageUploader, Input, Modal, ModalProps, Select } from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import DialogHeader from '../../components/ui/Dialog/DialogHeader'
import { ResponseError, getErrorText } from '@/api'

import { ErrorMessage } from '@hookform/error-message'
import { useAchievementUpdateMutate } from './api/useAchievementUpdateMutate'
import { useAchievementCreateMutate } from './api/useAchievementCreateMutate'

export interface EditAchievementDialogProps extends ModalProps {
  item: AchievementRead
}

const EditAchievementDialog = (props: EditAchievementDialogProps) => {
  const { ...rest } = props
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const [helperText, setHelperText] = useState('')
  const { mutateAsync: updateMutateAsync } = useAchievementUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useAchievementCreateMutate(companyId, gameId)

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<AchievementRead>({
    values: {
      ...props.item,
    },
  })

  const saveClick = async (data: AchievementRead) => {
    setHelperText('')
    try {
      if (props.item?.id) {
        await updateMutateAsync({
          id: data.id,
          update: data,
        })
        props.onClose?.()
      } else {
        await createMutateAsync({
          create: data,
        })
      }
      props.onClose?.()
    } catch (e) {
      setHelperText(getErrorText(e as ResponseError))
    }
  }

  return (
    <Modal {...rest}>
      <DialogHeader
        title={i18next.t(props.item.id ? 'edit-achievement-dialog.title.edit' : 'edit-achievement-dialog.title.create')}
      />

      <div className="mt-10">
        <FieldGroup label={i18next.t('edit-achievement-dialog.name')}>
          <Input autoFocus {...register('name', { required: i18next.t('validation.required') })} />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('edit-achievement-dialog.description')}>
          <Input {...register('description', { required: i18next.t('validation.required') })} />
          <ErrorMessage
            name="description"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('edit-achievement-dialog.type')}>
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={Object.values(AchievementType).map(it => ({
                  value: it,
                  children: i18next.t(`achievement.type.${it}`),
                }))}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup>
          <Controller
            control={control}
            name="image_url"
            rules={{ required: i18next.t('validation.required') }}
            render={({ field }) => (
              <ImageUploader
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                {...field}
              />
            )}
          />
          <ErrorMessage
            name="image_url"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        {helperText && <FieldValidationMessage>{helperText}</FieldValidationMessage>}
      </div>

      <div className="mt-10 flex justify-end gap-4">
        <Button size="lg" onClick={props.onClose}>
          {i18next.t('Cancel')}
        </Button>
        <Button size="lg" variant="primary" onClick={handleSubmit(saveClick)}>
          {i18next.t(props.item.id ? 'Save2' : 'Add')}
        </Button>
      </div>
    </Modal>
  )
}

export default EditAchievementDialog
