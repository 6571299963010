import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const Paragraph: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path d="M5 5V7H10.5L10.5 19L13.5 19L13.5 7H19V5H5Z" fill="currentColor" />
    </IconBase>
  )
})
