import { ItemType, ResourceState, StoreItemRead } from '@/api/dashboard'

import { generatePath, useParams } from 'react-router-dom'

import {
  FieldGroup,
  Select,
  SelectMultiple,
  SelectMultipleOption,
  SelectMultipleProps,
  SelectOption,
  SelectProps,
} from '../../../../components/ui'
import ImageCellNoText from '../../../../components/ImageCellNoText'
import { RefreshIcon } from '../../../../components/icons'
import styled from '@emotion/styled'
import i18next from 'i18next'
import { CONTEXT_ITEM } from '../../editors/common'
import { DEFAULT_LOAD_LIMIT, useGameItems } from '../../../../api/useGameItems'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '../../../../Settings'
import { getItemName } from '../../../../api/getItemName'
import { ReactElement, ReactNode } from 'react'
import { Trans } from 'react-i18next'
import { SKU_ITEMS_NEW_PATH } from '../../../../libs/routerPaths'
import { PriceView } from '@/layouts/game-items/components/PriceView'

// @ts-ignore
interface SelectSkuProps extends SelectProps {
  type?: ItemType | undefined
  options?: SelectOption[]
  label?: string
  error?: string
  multiselect?: boolean
  mode?: 'single' | 'multiple'
  value: string | string[] | undefined | null
  onChange: (value: SelectOption['value'] | SelectMultipleOption['value'][]) => void
  contextItemText?: string
  hideIds?: string[]
  showPrice?: boolean
  showLinkToCreateNewSKU?: boolean
  caption?: string
  customItemsList?: StoreItemRead[]
}

const RefreshButton = styled.div`
  height: 16px;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const SelectSkuItem = (props: SelectSkuProps) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const {
    data: items = [],
    refetch,
    isFetching,
  } = useGameItems(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })

  let visibleItems = items || []

  if (props.customItemsList) {
    visibleItems = props.customItemsList.map(it => it.item)
  } else if (props.type) {
    if (props.type == ItemType.Currency) {
      visibleItems = visibleItems.filter(it => it.type === props.type || it.is_stackable)
    } else {
      visibleItems = visibleItems.filter(it => it.type === props.type)
    }
  }

  if (props.hideIds) {
    visibleItems = visibleItems.filter(it => !props.hideIds || !props.hideIds.includes(it.id))
  }

  const updateClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    refetch()
    return false
  }

  visibleItems = visibleItems.sort((a, b) => a.name.localeCompare(b.name))

  let options = visibleItems.map(it => {
    let price: string | ReactNode = <></>
    if (props.showPrice !== false) {
      if (props.customItemsList?.find(customItem => customItem.item.id === it.id && customItem.is_free_item)) {
        price = i18next.t('coupon.type.free_item')
      } else {
        price = <PriceView item={it} />
      }
    }

    return {
      value: it.id as string,
      icon: (
        <div
          className="size-[38px] shrink-0 rounded-md bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${it.image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
          }}
        />
      ),
      desc: <div className="text-xs font-medium	 leading-none text-text-quarterary-hover">{it.sku}</div>,
      extraRight: price as unknown as string,
      children: getItemName(it),
    }
  })

  if (props.contextItemText) {
    options.push({
      value: CONTEXT_ITEM,
      icon: <ImageCellNoText image_size="38px" image_url={'/icons/default-item.svg'} />,
      desc: undefined as unknown as ReactElement,
      extraRight: undefined as unknown as string,
      children: props.contextItemText,
    })
  }

  let action = (
    <RefreshButton
      onClick={updateClick}
      style={{
        animation: `${isFetching ? 'spin 2s linear infinite' : ''}`,
      }}
    >
      <RefreshIcon />
    </RefreshButton>
  )

  let content = props.multiselect ? (
    <SelectMultiple
      {...(props as SelectMultipleProps)}
      options={options}
      action={action}
      filter={(opt, search) => {
        let id = opt.value
        let item = visibleItems.find(it => it.id == id)
        return !!item?.name.toLowerCase().includes(search)
      }}
      placeholder={i18next.t('select-sku')}
    />
  ) : (
    <Select
      {...(props as SelectProps)}
      name="select-sku"
      searchPlaceholder={i18next.t('select-sku.search.place-holder')}
      options={options}
      action={action}
      filter={(opt, search) => {
        let id = opt.value
        let item = visibleItems.find(it => it.id == id)
        if (!item) {
          return false
        }

        return (
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.sku.toLowerCase().includes(search.toLowerCase())
        )
      }}
      placeholder={i18next.t('select-skus')}
    />
  )

  if (!props.label) {
    return content
  }

  let caption: ReactElement | null | string | undefined = props.caption
  if (!isFetching && visibleItems.length == 0 && props.showLinkToCreateNewSKU) {
    const link = generatePath(SKU_ITEMS_NEW_PATH, { companyId: companyId, gameId: gameId })

    caption = <Trans i18nKey="select-sku.stackable-sku" values={{ link: link }} components={{ a: <a /> }} />
  }

  return (
    <FieldGroup label={props.label} error={props.error} caption={caption}>
      {content}
    </FieldGroup>
  )
}
