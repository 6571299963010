import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <>
      <path
        d="M13.5,9.75h-5.25c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h5.25c.41,0,.75.34.75.75s-.34.75-.75.75Z"
        fill="currentColor"
      />
      <path
        d="M8.25,11.25h5.25c.41,0,.75.34.75.75s-.34.75-.75.75h-5.25c-.41,0-.75-.34-.75-.75s.34-.75.75-.75Z"
        fill="currentColor"
      />
      <path
        d="M13.5,14.25h-5.25c-.41,0-.75.34-.75.75s.34.75.75.75h5.25c.41,0,.75-.34.75-.75s-.34-.75-.75-.75Z"
        fill="currentColor"
      />
      <path
        d="M17.25,6c0-.82-.67-1.5-1.5-1.5H6c-.82,0-1.5.68-1.5,1.5v9.75c0,2.07,1.68,3.75,3.75,3.75h7.51c1.64,0,3.03-1.06,3.54-2.52.09-.27.16-.56.19-.85.01-.12.01-.25.01-.38v-2.25c0-.41-.34-.75-.75-.75s-.75.34-.75.75v2.62c0,.09-.03.17-.08.24-.07.08-.17.14-.29.14-.21,0-.38-.17-.38-.38V6ZM16.6,17.83c.06-.02.06-.1,0-.13-.51-.34-.85-.92-.85-1.58V6.3c0-.16-.14-.3-.3-.3H6.3c-.16,0-.3.14-.3.3v9.45c0,1.25,1.01,2.25,2.25,2.25h7.5c.3,0,.58-.06.85-.17Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>,
  ],
  [
    'fill',
    <path
      d="M17.25,6c0-.82-.67-1.5-1.5-1.5H6c-.82,0-1.5.68-1.5,1.5v9.75c0,2.07,1.68,3.75,3.75,3.75h7.51c1.64,0,3.03-1.06,3.54-2.52.09-.27.16-.56.19-.85.01-.12.01-.25.01-.38v-2.25c0-.41-.34-.75-.75-.75s-.75.34-.75.75v2.62c0,.09-.03.17-.08.24-.07.08-.17.14-.29.14-.21,0-.38-.17-.38-.38V6ZM13.5,9.75h-5.25c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h5.25c.41,0,.75.34.75.75s-.34.75-.75.75ZM8.25,11.25h5.25c.41,0,.75.34.75.75s-.34.75-.75.75h-5.25c-.41,0-.75-.34-.75-.75s.34-.75.75-.75ZM13.5,14.25h-5.25c-.41,0-.75.34-.75.75s.34.75.75.75h5.25c.41,0,.75-.34.75-.75s-.34-.75-.75-.75Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'duotone',
    <>
      <rect y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12c0,3.31,2.69,6,6,6h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <path
        d="M17.25,6c0-.82-.67-1.5-1.5-1.5H6c-.82,0-1.5.68-1.5,1.5v9.75c0,2.07,1.68,3.75,3.75,3.75h7.51c1.64,0,3.03-1.06,3.54-2.52.09-.27.16-.56.19-.85.01-.12.01-.25.01-.38v-2.25c0-.41-.34-.75-.75-.75s-.75.34-.75.75v2.62c0,.09-.03.17-.08.24-.07.08-.17.14-.29.14-.21,0-.38-.17-.38-.38V6ZM13.5,9.75h-5.25c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h5.25c.41,0,.75.34.75.75s-.34.75-.75.75ZM8.25,11.25h5.25c.41,0,.75.34.75.75s-.34.75-.75.75h-5.25c-.41,0-.75-.34-.75-.75s.34-.75.75-.75ZM13.5,14.25h-5.25c-.41,0-.75.34-.75.75s.34.75.75.75h5.25c.41,0,.75-.34.75-.75s-.34-.75-.75-.75Z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </>,
  ],
])

export const Payouts = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
