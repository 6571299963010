import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { AccountDetails } from '@/api/dashboard'
import { accountDetailsQuery } from '@/layouts/company'

export const useAccountDetailsMutation = (companyId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: AccountDetails) => dashboardClient.v1.editAccountDetails(companyId, data).then(r => r.data),
    onSuccess: (_, variables) => {
      queryClient.setQueryData(accountDetailsQuery(companyId).queryKey, old => {
        if (!old) {
          return old
        }
        console.log(variables)
        return {
          ...old,
          details: { ...old.details, ...variables },
        }
      })
      queryClient.refetchQueries({ queryKey: accountDetailsQuery(companyId).queryKey })
    },
  })
}
