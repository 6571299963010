import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const AlignRight: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 10C7 9.44772 7.44772 9 8 9H21C21.5523 9 22 9.44772 22 10C22 10.5523 21.5523 11 21 11H8C7.44772 11 7 10.5523 7 10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 5.44772 3.44772 5 4 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H4C3.44772 7 3 6.55228 3 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 14C3 13.4477 3.44772 13 4 13H21C21.5523 13 22 13.4477 22 14C22 14.5523 21.5523 15 21 15H4C3.44772 15 3 14.5523 3 14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 18C7 17.4477 7.44772 17 8 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H8C7.44772 19 7 18.5523 7 18Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
