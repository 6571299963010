import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <>
      <path d="M12,14c1.1,0,2-.9,2-2s-.9-2-2-2-2,.9-2,2,.9,2,2,2Z" fill="currentColor" fillRule="evenodd" />
      <path
        d="M17.85,11.79c-.1.12-.1.3,0,.42.26.34.51.7.74,1.07.08.13.09.28.05.41-.22,1.17-.85,2.23-1.8,2.98-.1.1-.23.15-.38.15-.46,0-.92-.03-1.37-.08-.15-.02-.29.06-.35.2-.18.4-.38.79-.61,1.16-.06.1-.16.18-.28.23-.6.22-1.23.32-1.86.32s-1.26-.11-1.86-.32c-.12-.04-.22-.12-.28-.23-.22-.38-.43-.77-.61-1.16-.06-.14-.2-.22-.35-.2-.44.05-.9.08-1.37.08-.14,0-.28-.05-.38-.15,0,0-.02-.02-.03-.03-.94-.76-1.57-1.81-1.78-2.98-.03-.13-.02-.27.06-.39.23-.37.48-.73.74-1.07.1-.12.1-.3,0-.42-.26-.34-.51-.7-.74-1.07-.08-.13-.09-.28-.05-.41.22-1.17.85-2.23,1.8-2.98.1-.09.23-.15.38-.15h0c.46,0,.92.03,1.37.08.15.02.29-.06.35-.2.18-.4.38-.79.61-1.16.06-.1.16-.18.28-.23,1.2-.43,2.52-.43,3.71,0,.12.04.22.12.28.23.22.38.43.77.61,1.16.06.14.2.22.35.2.44-.05.9-.08,1.37-.08.14,0,.28.05.38.15,0,0,.02.02.03.02.94.76,1.57,1.81,1.78,2.98.03.13.02.27-.06.39-.23.37-.48.73-.74,1.07ZM17.28,13.7c-.15-.23-.32-.46-.48-.68-.47-.6-.47-1.45,0-2.05.17-.22.33-.44.48-.68-.18-.68-.55-1.3-1.1-1.79-.32,0-.64.03-.94.07-.72.08-1.41-.31-1.71-.97-.12-.26-.25-.52-.39-.78-.74-.21-1.53-.21-2.27,0-.14.26-.27.52-.39.78-.3.66-.99,1.05-1.71.97-.31-.03-.62-.06-.94-.07-.54.49-.92,1.11-1.1,1.79.15.23.32.46.48.68.47.6.47,1.45,0,2.05-.17.22-.33.44-.48.68.18.68.55,1.3,1.1,1.79.32,0,.64-.03.94-.07.72-.08,1.41.31,1.71.97.12.26.25.52.39.78.37.11.75.16,1.14.16s.77-.05,1.14-.16c.14-.26.27-.52.39-.78.3-.66.99-1.05,1.71-.97.31.03.62.06.94.07.54-.49.92-1.11,1.1-1.79Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>,
  ],
  [
    'fill',
    <path
      d="M17.85,12.21c.26.34.51.7.74,1.07.08.13.09.28.05.41-.22,1.17-.85,2.23-1.8,2.98-.1.1-.23.15-.38.15-.46,0-.92-.03-1.37-.08-.15-.02-.29.06-.35.2-.18.4-.38.79-.61,1.16-.06.1-.16.18-.28.23-.6.22-1.23.32-1.86.32s-1.26-.11-1.86-.32c-.12-.04-.22-.12-.28-.23-.22-.38-.43-.77-.61-1.16-.06-.14-.2-.22-.35-.2-.44.05-.9.08-1.37.08-.14,0-.28-.05-.38-.15,0,0-.02-.02-.03-.03-.94-.76-1.57-1.81-1.78-2.98-.03-.13-.02-.27.06-.39.23-.37.48-.73.74-1.07.1-.12.1-.3,0-.42-.26-.34-.51-.7-.74-1.07-.08-.13-.09-.28-.05-.41.22-1.17.85-2.23,1.8-2.98.1-.09.23-.15.38-.15h0c.46,0,.92.03,1.37.08.15.02.29-.06.35-.2.18-.4.38-.79.61-1.16.06-.1.16-.18.28-.23,1.2-.43,2.52-.43,3.71,0,.12.04.22.12.28.23.22.38.43.77.61,1.16.06.14.2.22.35.2.44-.05.9-.08,1.37-.08.14,0,.28.05.38.15,0,0,.02.02.03.02.94.76,1.57,1.81,1.78,2.98.03.13.02.27-.06.39-.23.37-.48.73-.74,1.07-.1.12-.1.3,0,.42ZM12,14c1.1,0,2-.9,2-2s-.9-2-2-2-2,.9-2,2,.9,2,2,2Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'duotone',
    <>
      <rect x="0" y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12C0,21.31,2.69,24,6,24h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <path
        d="M17.85,12.21c.26.34.51.7.74,1.07.08.13.09.28.05.41-.22,1.17-.85,2.23-1.8,2.98-.1.1-.23.15-.38.15-.46,0-.92-.03-1.37-.08-.15-.02-.29.06-.35.2-.18.4-.38.79-.61,1.16-.06.1-.16.18-.28.23-.6.22-1.23.32-1.86.32s-1.26-.11-1.86-.32c-.12-.04-.22-.12-.28-.23-.22-.38-.43-.77-.61-1.16-.06-.14-.2-.22-.35-.2-.44.05-.9.08-1.37.08-.14,0-.28-.05-.38-.15,0,0-.02-.02-.03-.03-.94-.76-1.57-1.81-1.78-2.98-.03-.13-.02-.27.06-.39.23-.37.48-.73.74-1.07.1-.12.1-.3,0-.42-.26-.34-.51-.7-.74-1.07-.08-.13-.09-.28-.05-.41.22-1.17.85-2.23,1.8-2.98.1-.09.23-.15.38-.15h0c.46,0,.92.03,1.37.08.15.02.29-.06.35-.2.18-.4.38-.79.61-1.16.06-.1.16-.18.28-.23,1.2-.43,2.52-.43,3.71,0,.12.04.22.12.28.23.22.38.43.77.61,1.16.06.14.2.22.35.2.44-.05.9-.08,1.37-.08.14,0,.28.05.38.15,0,0,.02.02.03.02.94.76,1.57,1.81,1.78,2.98.03.13.02.27-.06.39-.23.37-.48.73-.74,1.07-.1.12-.1.3,0,.42ZM12,14c1.1,0,2-.9,2-2s-.9-2-2-2-2,.9-2,2,.9,2,2,2Z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </>,
  ],
])

export const Cog = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
