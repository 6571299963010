import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ItemBulkUpdate } from '../../../api/dashboard'
import { dashboardClient } from '../../../api'

interface ItemBulkUpdateMutate {
  data: ItemBulkUpdate[]
}

/**
 *
 * @param companyId
 * @param gameId
 */
export const useItemBulkUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ data }: ItemBulkUpdateMutate) => dashboardClient.v1.bulkUpdateItems(companyId, gameId, data),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['items', gameId], exact: false })
    },
  })
}
