import { queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { GenerationProgressState } from '@/api/dashboard'
import { getGenerationProgressIsStuck } from '@/layouts/get-started/libs'

const defaultGenerationProgressData: GenerationProgressState = {
  colors: { current: 1, total: 1 },
  background_image: { current: 1, total: 1 },
  screenshots: { current: 1, total: 1 },
  news: { current: 1, total: 1 },
  sku_items: { current: 1, total: 1 },
  best_youtube: { current: 1, total: 1 },
  start_at: new Date().getTime() / 1000,
}

const MAX_WAITING_TIME_MS = 60 * 5 * 1000

export const getGenerationProgressQuery = (companyId: string, gameId: string) =>
  queryOptions({
    queryKey: [gameId, 'get-started', 'generation-progress'],
    queryFn: () =>
      dashboardClient.v1
        .getOnboardingProgress(companyId, gameId)
        .then(r => r.data)
        .catch(() => defaultGenerationProgressData),
    select: data => {
      // If the generation full stuck, we return default data (completed).
      if (getGenerationProgressIsStuck(data, MAX_WAITING_TIME_MS)) {
        return defaultGenerationProgressData
      }
      return data
    },
    retry: 0,
  })
