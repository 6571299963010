import { HTMLAttributes, ReactNode } from 'react'
import { cn } from '../../../../libs/cn'

interface TableCellProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode
  width?: number | string
}

export const TableCell = ({ children, className, width, ...rest }: TableCellProps) => (
  <div
    className={cn('w-full overflow-hidden break-words p-3', className)}
    style={{ width, flexShrink: typeof width === 'number' ? '0' : '1' }}
    {...rest}
  >
    {children}
  </div>
)
