import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

type Query = {
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 100
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number
}

/**
 *
 * @param companyId
 * @param gameId
 * @param userId
 */
export const usePlayerCouponQuery = (companyId: string, gameId: string, userId: string, query: Query = {}) => {
  return useQuery({
    queryKey: ['user-coupons', userId, query],
    queryFn: () => dashboardClient.v1.getUserCoupons(userId, companyId, gameId, query).then(r => r.data),
  })
}
