import { Link } from 'react-router-dom'
import { FlipBackward } from '@/icons'
import { UseNavigateBackValue } from '@/libs'
import { ButtonIcon } from '../ButtonIcon'

interface FullscreenTopbarBackProps extends UseNavigateBackValue {}

export const FullscreenTopbarBack = ({ ...rest }: FullscreenTopbarBackProps) => (
  <Link className="mr-5" data-testid="fullscreen-topbar/back-button" {...rest}>
    <ButtonIcon type="button" size="sm" variant="secondary-gray">
      <FlipBackward />
    </ButtonIcon>
  </Link>
)
