import i18next from 'i18next'
import { ItemCategoryRead } from '../../api/dashboard'
import {
  Button,
  FieldGroup,
  FieldValidationMessage,
  Input,
  Modal,
  ModalFooter,
  ModalProps,
  ModalTitle,
} from '../../components/ui'
import { useItemCategoryCreateMutate, useItemCategoryUpdateMutate } from './api'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useState } from 'react'
import { ResponseError, getErrorText } from '../../api'
import { prepareSLUG } from '../../util'

interface EditStoreDialogProps extends ModalProps {
  item: ItemCategoryRead
}

export default function EditItemCategoryDialog(props: EditStoreDialogProps) {
  const { ...rest } = props
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { mutateAsync: createMutateAsync } = useItemCategoryCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useItemCategoryUpdateMutate(companyId, gameId)

  const {
    control,
    setValue,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<ItemCategoryRead>({
    values: {
      ...props.item,
    },
  })

  const saveClick = async (data: ItemCategoryRead) => {
    try {
      if (props.item.id) {
        await updateMutateAsync({
          id: props.item.id,
          update: data,
        })
      } else {
        await createMutateAsync({
          create: data,
        })
      }
    } catch (e) {
      setErrorMessage(getErrorText(e as ResponseError))
      return
    }

    props.onClose?.()
  }

  return (
    <Modal {...rest}>
      <ModalTitle>{i18next.t(props.item.id ? 'item-category.edit' : 'item-category.create')}</ModalTitle>

      <div className="mt-10">
        <FieldGroup label={i18next.t('item-category.name')}>
          <Input autoFocus {...register('name', { required: i18next.t('validation.required') })} />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('item-category.description')}>
          <Input {...register('description')} />
          <ErrorMessage
            name="description"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('item-category.slug')}>
          <Controller
            control={control}
            name="slug"
            render={({ field }) => <Input {...field} onChange={e => setValue('slug', prepareSLUG(e.target.value))} />}
          />

          <ErrorMessage
            name="description"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <div className="inline-block">
          {errorMessage && <FieldValidationMessage> {errorMessage} </FieldValidationMessage>}
        </div>
      </div>

      <ModalFooter>
        <Button size="lg" onClick={() => props.onClose?.()}>
          {i18next.t('Cancel')}
        </Button>
        <Button size="lg" variant="primary" onClick={handleSubmit(saveClick)}>
          {i18next.t(props.item.id ? 'Save2' : 'Add')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
