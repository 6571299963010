import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const CoinsHandOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <g clipPath="url(#clip0_7768_14852)">
        <path
          d="M4.62289 0.0619091L1.70103 1.88147C1.43299 2.0484 1.43299 2.4516 1.70104 2.61853L4.62289 4.43809C4.89888 4.60996 5.25 4.40362 5.25 4.06957V3H9C10.2671 3 11.5016 3.40113 12.5267 4.1459C13.5518 4.89067 14.3148 5.94084 14.7063 7.1459C15.0979 8.35096 15.0979 9.64904 14.7063 10.8541C14.3148 12.0592 13.5518 13.1093 12.5267 13.8541C11.5016 14.5989 10.2671 15 9 15C7.73292 15 6.49837 14.5989 5.47329 13.8541C4.4482 13.1093 3.68521 12.0592 3.29366 10.8541C2.90211 9.64904 2.90211 8.35096 3.29366 7.1459C3.42166 6.75196 3.20607 6.32884 2.81213 6.20084C2.41819 6.07284 1.99508 6.28843 1.86708 6.68237C1.37764 8.1887 1.37764 9.8113 1.86708 11.3176C2.35651 12.824 3.31025 14.1367 4.59161 15.0676C5.87297 15.9986 7.41615 16.5 9 16.5C10.5838 16.5 12.127 15.9986 13.4084 15.0676C14.6897 14.1367 15.6435 12.824 16.1329 11.3176C16.6224 9.8113 16.6224 8.1887 16.1329 6.68237C15.6435 5.17605 14.6897 3.86333 13.4084 2.93237C12.127 2.00141 10.5838 1.5 9 1.5H5.25V0.430434C5.25 0.0963758 4.89888 -0.109962 4.62289 0.0619091Z"
          fill="#4C1D95"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.66431 12.6985C8.66431 12.8969 8.80165 13.0267 8.99239 13.0267C9.1755 13.0267 9.32047 12.8969 9.32047 12.6985V12.2176C10.5031 12.1183 11.3805 11.4389 11.3805 10.3397C11.3805 9.35496 10.7549 8.78244 9.49595 8.5458L9.32047 8.50763V6.77481C9.75536 6.85878 10.0682 7.11832 10.2055 7.53053C10.3123 7.77481 10.4802 7.91221 10.7472 7.91221C11.0524 7.91221 11.2584 7.71374 11.2584 7.42366C11.2584 7.36069 11.2455 7.29771 11.2327 7.23473C11.2284 7.21374 11.2241 7.19275 11.2203 7.17176C11.0219 6.4313 10.2971 5.90458 9.32047 5.79771V5.32443C9.32047 5.12595 9.1755 4.98855 8.99239 4.98855C8.80165 4.98855 8.66431 5.12595 8.66431 5.32443V5.79771C7.51223 5.91221 6.69585 6.60687 6.69585 7.62214C6.69585 8.5687 7.32912 9.11832 8.52698 9.39313L8.66431 9.42366V11.2557C8.10735 11.1718 7.77927 10.874 7.66482 10.4695C7.55801 10.2023 7.39015 10.0725 7.13074 10.0725C6.8103 10.0725 6.60429 10.2786 6.60429 10.5763C6.60429 10.6538 6.62378 10.7313 6.64477 10.8147C6.64653 10.8217 6.6483 10.8288 6.65007 10.8359C6.8637 11.584 7.61905 12.126 8.66431 12.2176V12.6985ZM8.66431 6.77481V8.34733L8.61854 8.33206C8.06157 8.17176 7.80216 7.92748 7.80216 7.55344C7.80216 7.16412 8.13023 6.85878 8.66431 6.77481ZM9.32047 11.2557V9.58397L9.45017 9.6145C10.0529 9.78244 10.2742 10.0267 10.2742 10.4237C10.2742 10.8664 9.95374 11.1794 9.32047 11.2557Z"
          fill="#4C1D95"
        />
      </g>
      <defs>
        <clipPath id="clip0_7768_14852">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </IconBase>
  )
})
