import { FLAGS } from '../../../util'
import { getCountries } from '@/translates'

export default function UserCountry(props: { country: string | undefined }) {
  if (!props.country) {
    return null
  }

  const countries = getCountries()

  return (
    <div className="inline-flex items-center gap-2">
      {props.country && (
        <img
          src={`${FLAGS}/${props.country.toUpperCase()}.svg`}
          alt={props.country}
          style={{
            width: '24px',
            height: '24px',
          }}
        />
      )}
      {(countries[props.country] as string) || props.country}
    </div>
  )
}
