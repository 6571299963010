import {
  Button,
  ButtonIcon,
  FieldValidationMessage,
  Input,
  InputExtraSection,
  Modal,
  ModalFooter,
  ModalProps,
  ModalTitle,
  SpinnerAghanim,
} from '../../../components/ui'
import { ItemRead, Price, PriceCreate } from '../../../api/dashboard'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import { useDebounce } from '../../../hooks'
import { KeyValue } from '../../../types'
import { Alert, AlertTitle, Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import i18next from 'i18next'
import { WarningIcon } from '../../../icons/Icons'
import { dashboardClient } from '../../../api'
import { FLAGS, currencySymbol, getPriceMinorUnitFactor, priceStringToMoney } from '../../../util'
import { LOCAL_PRICE_MAX, PRICE_MAX } from '../data'
import { XClose } from '../../../components/icons'
import { getCountries } from '@/translates'

export type PriceEditFormData = Pick<ItemRead, 'price' | 'price_point'>

export interface PriceEditModalData {
  item: ItemRead
  onChange: (data: PriceEditFormData) => void
}

interface PriceEditModalProps extends ModalProps, PriceEditModalData {}

export const PriceEditModal = ({ item, onChange, ...rest }: PriceEditModalProps) => {
  const [defaultPrice, setDefaultPrice] = useState(item.price)
  const theme = useTheme()
  const { gameId, companyId } = useParams() as { gameId: string; companyId: string }
  const [prices, setPrices] = useState<Price[]>([])
  const [changed, setChanged] = useState<{ [key: string]: Price }>({})
  const [pricePoint, setPricePoint] = useState<number | undefined>()
  const debouncedPrice = useDebounce(defaultPrice, 500)
  const [loading, setLoading] = useState(true)
  const itemId = item.id
  const [errors, setErrors] = useState<KeyValue>({})
  const [defaultPriceError, setDefaultPriceError] = useState('')

  const countries = getCountries()

  const cellStyle = {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  }

  const loadItems = async (p: number) => {
    setLoading(true)
    const pInteger = Math.floor(p)
    const { data } = await dashboardClient.v1.getPrices(companyId, gameId, itemId, { price_point: pInteger })
    setPrices(data)
    const pPoint = getPricePoint(data)
    setPricePoint(pPoint)
    setLoading(false)
  }

  const searchItems = async (p: number) => {
    setLoading(true)
    const pInteger = Math.floor(p)
    const { data } = await dashboardClient.v1.searchPrices(companyId, gameId, itemId, { price: pInteger })
    setPrices(data)
    const pPoint = getPricePoint(data)
    setPricePoint(pPoint)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    if (item.price_point) {
      loadItems(item.price_point)
    } else {
      searchItems(item.price)
    }
  }, [])

  const saveItem = async () => {
    onChange({
      price: debouncedPrice,
      price_point: pricePoint,
    })
  }

  const savePrices = async () => {
    const body = Object.keys(changed).map(key => changed[key]) as PriceCreate[]
    await dashboardClient.v1.createOrUpdatePrices(companyId, gameId, itemId, body)
  }

  const isValidDefaultPrice = (v: number) => {
    if (isNaN(v)) {
      return false
    }
    return v >= 0 && v <= PRICE_MAX
  }

  const defaultPriceChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const v = parseFloat(e.target.value)
    if (!isValidDefaultPrice(v)) {
      setDefaultPriceError(i18next.t('game-item-page.price-min-max', { min: 0, max: PRICE_MAX }))
    } else {
      setDefaultPriceError('')
    }

    // clear changes
    setChanged({})

    let strNumber = e.target.value.replace(',', '.')
    let parts = strNumber.split('.')
    let res = 100 * parseInt(parts[0])

    if (parts.length > 1) {
      let d = parts[1]
      if (d.length == 1) {
        d += '0'
      }
      res += parseInt(d)
    }

    setDefaultPrice(res)
  }

  const localPriceChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, price: Price, key: number) => {
    // need to store locally this price
    const newPrice = priceStringToMoney(e.target.value, price.currency)
    // skip if value the same
    if (newPrice === prices[key].price) {
      return
    }

    setChanged({
      ...changed,
      [price.country]: { ...price, price: newPrice, item_id: itemId },
    })
  }

  const getPricePoint = (pricePoints: Price[]) => {
    const points = pricePoints.map(p => p.price_point)
    const uniquePricePoints = [...new Set(points)]
    if (uniquePricePoints.length > 1) {
      throw Error('Only one price point should be used in prices')
    }
    return uniquePricePoints[0]
  }

  const onSaveClick = async () => {
    if (defaultPriceError) {
      return
    }

    let keys = Object.keys(changed)
    let newErrors: KeyValue = {}
    for (let key of keys) {
      let p = changed[key]
      let factor = getPriceMinorUnitFactor(p.currency)
      if (p.price < 0 || p.price > LOCAL_PRICE_MAX * factor) {
        newErrors[p.id] = i18next.t('game-item-page.price-min-max', { min: 0, max: LOCAL_PRICE_MAX })
      }
    }

    setErrors(newErrors)

    if (Object.keys(newErrors).length > 0) {
      return
    }

    // check need to save price point or price in item
    if (pricePoint !== item.price_point || debouncedPrice !== item.price) {
      await saveItem()
    }
    if (keys.length) {
      // store changed price points
      await savePrices()
    }
    // close modal after store data
    rest.onClose?.()
  }

  useEffect(() => {
    if (!defaultPrice || defaultPriceError) {
      return
    }
    searchItems(defaultPrice)
  }, [debouncedPrice])

  return (
    <Modal className="flex h-full max-h-[900px] max-w-screen-2xl flex-col overflow-hidden p-5" {...rest}>
      <ButtonIcon className="absolute right-5 top-5" variant="secondary-gray" onClick={rest.onClose}>
        <XClose />
      </ButtonIcon>

      <ModalTitle>{i18next.t('game-item-page.price-edit-title')}</ModalTitle>

      <div className="mb-5 flex w-full items-start justify-between gap-4 rounded-md border border-border-secondary bg-fg-secondary p-5">
        <div style={{ minWidth: '200px' }}>
          <Input
            type="number"
            placeholder={i18next.t('game-item-page.price-edit-default-placeholder')}
            value={defaultPrice / getPriceMinorUnitFactor('USD')}
            onChange={defaultPriceChange}
            extraRight={<InputExtraSection side="right">USD</InputExtraSection>}
          />
          {defaultPriceError && <FieldValidationMessage>{defaultPriceError}</FieldValidationMessage>}
        </div>
        <div className="text-sm text-text-tertiary">{i18next.t('game-item-page.price-edit-description')}</div>
      </div>

      {!item.price_point && (
        <Alert severity="warning" sx={{ marginTop: theme.spacing(2) }} icon={<WarningIcon />} style={{ width: '100%' }}>
          <AlertTitle>
            <Typography variant="h6" color="text.primary">
              {i18next.t('game-item-page.price-edit-alert')}
            </Typography>
          </AlertTitle>

          <Typography color="text.primary" variant="body1">
            {i18next.t('game-item-page.price-edit-alert-description')}
          </Typography>
        </Alert>
      )}

      <Box paddingY={theme.spacing(2)}>
        <Typography variant="h5" paddingBottom={theme.spacing()}>
          {i18next.t('game-item-page.price-edit-table-title')}
        </Typography>
        <Typography variant="body1">{i18next.t('game-item-page.price-edit-table-description')}</Typography>
      </Box>

      <div className="h-full overflow-x-auto">
        {loading ? (
          <div className="flex size-full items-center justify-center">
            <SpinnerAghanim />
          </div>
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '300px', ...cellStyle }}>
                  {i18next.t('game-item-page.price-edit-table-countries')} ({prices.length})
                </TableCell>
                <TableCell sx={cellStyle}>{i18next.t('game-item-page.price-edit-table-price')}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {prices.map((p, k) => (
                <TableRow key={p.id}>
                  <TableCell sx={cellStyle}>
                    <Box display="flex" alignItems="center">
                      <img
                        src={`${FLAGS}/${p.country}.svg`}
                        alt={p.country}
                        style={{
                          width: theme.spacing(3),
                          height: theme.spacing(3),
                          marginRight: theme.spacing(1.5),
                        }}
                      />
                      {(countries[p.country] as string) || p.country}
                    </Box>
                  </TableCell>
                  <TableCell sx={cellStyle}>
                    <div className="flex items-center gap-1">
                      <div style={{ width: '250px' }}>
                        <Input
                          type="number"
                          defaultValue={p.price / getPriceMinorUnitFactor(p.currency)}
                          onBlur={e => localPriceChange(e, p, k)}
                          extraLeft={
                            <InputExtraSection side="left">
                              <div className="w-9">{currencySymbol(p.currency)}</div>
                            </InputExtraSection>
                          }
                        />
                      </div>
                      {!!errors[p.id] && <FieldValidationMessage>{errors[p.id] as string}</FieldValidationMessage>}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>

      <ModalFooter>
        <Button size="lg" onClick={rest.onClose}>
          {i18next.t('game-item-page.price-edit-discard')}
        </Button>
        <Button size="lg" variant="primary" onClick={onSaveClick} isLoading={loading || !debouncedPrice}>
          {i18next.t('game-item-page.price-edit-apply')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
