import { useCurrentGame } from '@/api/useCompaniesQuery'

export const useBanner = () => {
  const { game, company } = useCurrentGame()
  let bannerMode = ''

  if (company?.is_demo) {
    bannerMode = 'demo'
  } else if (game?.sandbox) {
    bannerMode = 'test'
  }

  const getStickyTop = () => {
    return bannerMode ? 'top-banner' : 'top-0'
  }

  const getStickyHeight = () => {
    return bannerMode ? 'h-banner-cont' : 'h-screen'
  }

  return { bannerMode, getStickyTop, getStickyHeight }
}
