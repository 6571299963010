import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { User, UserUpdate } from '../../../api/dashboard'

interface PlayerUpdateMutate {
  userId: string
  update: UserUpdate
}

export const usePlayerUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ userId, update }: PlayerUpdateMutate) =>
      dashboardClient.v1.updateUser(companyId, gameId, userId, update),
    onSuccess: (_, variables) => {
      queryClient.setQueryData<User>(['players', gameId, variables.userId], old => ({
        ...(old as User),
        ...variables.update,
      }))
      queryClient.invalidateQueries({ queryKey: ['players', gameId] })
    },
  })
}
