import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

/**
 *
 * @param companyId
 * @param gameId
 * @param userId
 */
export const usePlayerDetailQuery = (companyId: string, gameId: string, userId: string) => {
  return useQuery({
    queryKey: ['players', gameId, userId],
    queryFn: () => dashboardClient.v1.getUser(companyId, gameId, userId).then(r => r.data),
  })
}

export const useFullPlayerDetailQuery = (companyId: string, gameId: string, userId: string) => {
  return useQuery({
    queryKey: ['full_players', gameId, userId],
    queryFn: () => dashboardClient.v1.getFullUser(companyId, gameId, userId).then(r => r.data),
  })
}
