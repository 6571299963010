import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from './index'
import { GameSettingsUpdateFeatures } from './dashboard'

interface GameSettingsUpdateMutate {
  settingsId: string
  data: GameSettingsUpdateFeatures
}

/**
 *
 * @param companyId
 * @param gameId
 */
export const useGameSettingsFeaturesUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ settingsId, data }: GameSettingsUpdateMutate) =>
      dashboardClient.v1.updateGameSettingsFeatureFlags(settingsId, companyId, gameId, data).then(r => r.data),
    onSuccess: data => {
      queryClient.setQueryData(['game-settings', gameId], data)
    },
  })
}
