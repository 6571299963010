import { TranslationRead } from '@/api/dashboard'

const preferredObjectPropertyKeys = ['name', 'title']

const sortByPreferredKeys = (a: TranslationRead, b: TranslationRead) => {
  const aIndex = preferredObjectPropertyKeys.indexOf(a.object_property)
  const bIndex = preferredObjectPropertyKeys.indexOf(b.object_property)
  return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex)
}

export const getGroupPreview = (translations: TranslationRead[]) => {
  const first = [...translations].sort(sortByPreferredKeys).at(0)
  return first?.label || first?.original_content || ''
}
