import { Tooltip } from '../../../../components/ui'

import styled from '@emotion/styled'

const ToolTipBoxCss = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.palette.grey.border};
  border-radius: 50%;
  width: 12px;
  height: 12px;
  color: #fff;
  font-size: 8px;
  cursor: help;
`

export default function ToolTipBox({ tooltip, placement }: { tooltip: string; placement?: 'top' | 'bottom' }) {
  return (
    <Tooltip
      message={tooltip}
      options={{
        placement: placement || 'bottom',
      }}
    >
      <ToolTipBoxCss>!</ToolTipBoxCss>
    </Tooltip>
  )
}
