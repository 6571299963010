import { ReactNode } from 'react'
import styled from '@emotion/styled'

interface FrequencyGroupProps {
  children: ReactNode
  label: ReactNode
}

const StyledFrequencyGroup = styled.div`
  margin-bottom: ${p => p.theme.spacing(2.5)};
  &:last-of-type {
    margin-bottom: 0;
  }
`

const Label = styled('div')`
  color: ${p => p.theme.palette.text.tertiary};
  margin-bottom: ${p => p.theme.spacing(1.25)};
  font-size: 22px;
  font-weight: 600;
  line-height: 120%;
`

const Entry = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(1.25)};
`

export const FrequencyGroup = ({ children, label }: FrequencyGroupProps) => {
  return (
    <StyledFrequencyGroup>
      <Label>{label}</Label>
      <Entry>{children}</Entry>
    </StyledFrequencyGroup>
  )
}
