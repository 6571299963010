import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <path
      d="M4.5,6c0-.83.67-1.5,1.5-1.5h2.25c.75,0,1.38.55,1.48,1.28.02.12.12.22.24.22h6.9c1.45,0,2.62,1.18,2.62,2.62v1.5c0,1.45-1.18,2.62-2.62,2.62h-.62c-.65,0-1.28.21-1.8.6l-1.1.82c-.8.6-1.9.6-2.7,0l-1.1-.82c-.52-.39-1.15-.6-1.8-.6h-.62c-.62,0-1.12.5-1.12,1.12v1.5c0,.62.5,1.12,1.12,1.12h6.9c.12,0,.22-.1.24-.22.11-.72.73-1.28,1.48-1.28h2.25c.83,0,1.5.67,1.5,1.5v1.5c0,.83-.67,1.5-1.5,1.5h-2.25c-.75,0-1.38-.55-1.48-1.28-.02-.12-.12-.22-.24-.22h-6.9c-1.45,0-2.62-1.18-2.62-2.62v-1.5c0-1.45,1.18-2.62,2.62-2.62h.62c.65,0,1.28-.21,1.8-.6l1.1-.82c.8-.6,1.9-.6,2.7,0l1.1.82c.52.39,1.15.6,1.8.6h.62c.62,0,1.12-.5,1.12-1.12v-1.5c0-.62-.5-1.12-1.12-1.12h-6.9c-.12,0-.22.1-.24.22-.11.72-.73,1.28-1.48,1.28h-2.25c-.83,0-1.5-.67-1.5-1.5v-1.5ZM15.75,17.85c0,.08.07.15.15.15h1.95c.08,0,.15-.07.15-.15v-1.2c0-.08-.07-.15-.15-.15h-1.95c-.08,0-.15.07-.15.15v1.2ZM8.25,6.15v1.2c0,.08-.07.15-.15.15h-1.95c-.08,0-.15-.07-.15-.15v-1.2c0-.08.07-.15.15-.15h1.95c.08,0,.15.07.15.15ZM11.55,11.02c.27-.2.63-.2.9,0l1.06.8c.12.09.12.27,0,.36l-1.06.8c-.27.2-.63.2-.9,0l-1.06-.8c-.12-.09-.12-.27,0-.36l1.06-.8Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'fill',
    <path
      d="M4.5,6c0-.83.67-1.5,1.5-1.5h2.25c.75,0,1.38.55,1.48,1.28.02.12.12.22.24.22h6.9c1.45,0,2.62,1.18,2.62,2.62v1.5c0,1.45-1.18,2.62-2.62,2.62h-.62c-.65,0-1.28.21-1.8.6l-1.1.82c-.8.6-1.9.6-2.7,0l-1.1-.82c-.52-.39-1.15-.6-1.8-.6h-.62c-.62,0-1.12.5-1.12,1.12v1.5c0,.62.5,1.12,1.12,1.12h6.9c.12,0,.22-.1.24-.22.11-.72.73-1.28,1.48-1.28h2.25c.83,0,1.5.67,1.5,1.5v1.5c0,.83-.67,1.5-1.5,1.5h-2.25c-.75,0-1.38-.55-1.48-1.28-.02-.12-.12-.22-.24-.22h-6.9c-1.45,0-2.62-1.18-2.62-2.62v-1.5c0-1.45,1.18-2.62,2.62-2.62h.62c.65,0,1.28-.21,1.8-.6l1.1-.82c.8-.6,1.9-.6,2.7,0l1.1.82c.52.39,1.15.6,1.8.6h.62c.62,0,1.12-.5,1.12-1.12v-1.5c0-.62-.5-1.12-1.12-1.12h-6.9c-.12,0-.22.1-.24.22-.11.72-.73,1.28-1.48,1.28h-2.25c-.83,0-1.5-.67-1.5-1.5v-1.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'duotone',
    <>
      <rect x="0" y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12C0,21.31,2.69,24,6,24h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <path
        d="M4.5,6c0-.83.67-1.5,1.5-1.5h2.25c.75,0,1.38.55,1.48,1.28.02.12.12.22.24.22h6.9c1.45,0,2.62,1.18,2.62,2.62v1.5c0,1.45-1.18,2.62-2.62,2.62h-.62c-.65,0-1.28.21-1.8.6l-1.1.82c-.8.6-1.9.6-2.7,0l-1.1-.82c-.52-.39-1.15-.6-1.8-.6h-.62c-.62,0-1.12.5-1.12,1.12v1.5c0,.62.5,1.12,1.12,1.12h6.9c.12,0,.22-.1.24-.22.11-.72.73-1.28,1.48-1.28h2.25c.83,0,1.5.67,1.5,1.5v1.5c0,.83-.67,1.5-1.5,1.5h-2.25c-.75,0-1.38-.55-1.48-1.28-.02-.12-.12-.22-.24-.22h-6.9c-1.45,0-2.62-1.18-2.62-2.62v-1.5c0-1.45,1.18-2.62,2.62-2.62h.62c.65,0,1.28-.21,1.8-.6l1.1-.82c.8-.6,1.9-.6,2.7,0l1.1.82c.52.39,1.15.6,1.8.6h.62c.62,0,1.12-.5,1.12-1.12v-1.5c0-.62-.5-1.12-1.12-1.12h-6.9c-.12,0-.22.1-.24.22-.11.72-.73,1.28-1.48,1.28h-2.25c-.83,0-1.5-.67-1.5-1.5v-1.5Z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </>,
  ],
])

export const LiveOps = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
