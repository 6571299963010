import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const Exit: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 14 14" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 3.70771C12.0002 3.274 11.5926 2.95576 11.1718 3.06095L8.50514 3.72762C8.20836 3.80181 8.00016 4.06847 8.00016 4.37438V5.66668C8.00016 6.03487 7.70169 6.33334 7.3335 6.33334C6.96531 6.33334 6.66683 6.03487 6.66683 5.66668V4.37438C6.66683 3.45664 7.29142 2.65668 8.18176 2.43409L10.8484 1.76743C12.1107 1.45185 13.3335 2.40657 13.3335 3.70771V12.2923C13.3335 13.5935 12.1107 14.5482 10.8484 14.2326L8.18176 13.5659C7.29142 13.3433 6.66683 12.5434 6.66683 11.6256V10.3333C6.66683 9.96515 6.96531 9.66668 7.3335 9.66668C7.70169 9.66668 8.00016 9.96515 8.00016 10.3333V11.6256C8.00016 11.9316 8.20836 12.1982 8.50514 12.2724L11.1718 12.9391C11.5926 13.0443 12.0002 12.726 12.0002 12.2923V3.70771ZM5.13823 4.86194C5.39858 5.12229 5.39858 5.5444 5.13823 5.80475L3.60964 7.33334H10.0002C10.3684 7.33334 10.6668 7.63182 10.6668 8.00001C10.6668 8.3682 10.3684 8.66668 10.0002 8.66668H3.60964L5.13823 10.1953C5.39858 10.4556 5.39858 10.8777 5.13823 11.1381C4.87788 11.3984 4.45577 11.3984 4.19543 11.1381L1.52876 8.47141C1.26841 8.21107 1.26841 7.78896 1.52876 7.52861L4.19543 4.86194C4.45577 4.60159 4.87788 4.60159 5.13823 4.86194Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
