import { HTMLAttributes, ReactNode, createElement } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { AghanimIcon, InfoCircle } from '@/icons'
import { cn } from '@/libs'

// https://www.figma.com/design/naGJpW5rHrJkcs3EmlWroC/UI-Kit-%E2%80%93-Atoms?node-id=72-36891&m=dev

const alertVariants = cva('flex gap-2 text-caption-md font-normal', {
  variants: {
    variant: {
      info: 'text-text-secondary',
    },
  },
})

const iconMapByVariant = new Map<VariantProps<typeof alertVariants>['variant'], AghanimIcon>([['info', InfoCircle]])

interface AlertProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof alertVariants> {
  children: ReactNode
}

export const Alert = ({ children, variant = 'info', ...rest }: AlertProps) => {
  const icon = iconMapByVariant.get(variant)
  return (
    <div {...rest} className={cn(alertVariants({ variant }), rest.className)}>
      {icon && (
        <div className="flex size-4 shrink-0 items-center justify-center">{createElement(icon, { size: 14 })}</div>
      )}
      <span>{children}</span>
    </div>
  )
}
