import { CSSProperties } from 'react'

export default function UserAvatar(props: {
  url?: string | undefined
  style?: CSSProperties
  name?: string | undefined
  size?: string
}) {
  let url = props.url
  let size = props.size || '38px'
  if (!url && props.name) {
    let arr = props.name.split(' ')

    return (
      <div
        className="flex items-center justify-center rounded-full bg-fg-brand-primary text-text-inverse"
        style={{
          minWidth: size,
          height: size,
          width: size,
        }}
      >
        {arr[0].charAt(0).toUpperCase()} {arr.length > 1 ? arr[1].charAt(0).toUpperCase() : ''}
      </div>
    )
  }

  return (
    <img
      src={url || '/icons/default-avatar.svg'}
      onError={e => ((e.target as HTMLImageElement).src = '/icons/default-avatar.svg')}
      alt="avatar"
      className="rounded-full"
      data-categories="essential"
      style={{
        minWidth: size,
        height: size,
        width: size,
        overflow: 'hidden',
        ...props.style,
      }}
    />
  )
}
