import { Table, TableCell, TableRow } from '@/ui'
import i18next from 'i18next'

export interface PropColumn {
  icon?: React.ReactNode
  label: string
  value: string | undefined | React.ReactNode
}

export default function TableProperty(props: { rows: PropColumn[] }) {
  return (
    <Table>
      <TableRow variant="header">
        <TableCell>{i18next.t('player-details.attr.name')}</TableCell>
        <TableCell>{i18next.t('player-details.attr.value')}</TableCell>
      </TableRow>
      {props.rows.map(row => (
        <TableRow key={row.label}>
          <TableCell>{row.label}</TableCell>
          <TableCell>{row.value}</TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
