import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

type Query = {
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 100
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number
}

/**
 *
 * @param companyId
 * @param gameId
 * @param query
 */
export const useCouponQuery = (companyId: string, gameId: string, query: Query = {}) => {
  return useQuery({
    queryKey: ['coupons', gameId, query],
    queryFn: () => dashboardClient.v1.getCoupons(companyId, gameId, query),
    select: response => response.data,
  })
}
