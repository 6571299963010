import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <>
      <path
        d="M14.33,6.67c-1.47,0-2.69,1.06-2.95,2.45-.07.36-.41.6-.78.54-.36-.07-.6-.41-.54-.78.37-2.02,2.14-3.55,4.26-3.55,2.39,0,4.33,1.94,4.33,4.33,0,2.12-1.53,3.89-3.55,4.26-.36.07-.71-.17-.78-.54-.07-.36.17-.71.54-.78,1.4-.26,2.45-1.48,2.45-2.95,0-1.66-1.34-3-3-3Z"
        fill="currentColor"
      />
      <path
        d="M9.67,18.67c2.39,0,4.33-1.94,4.33-4.33s-1.94-4.33-4.33-4.33-4.33,1.94-4.33,4.33,1.94,4.33,4.33,4.33ZM9.67,17.33c1.66,0,3-1.34,3-3s-1.34-3-3-3-3,1.34-3,3,1.34,3,3,3Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M5.97,10.43c.26,0,.51-.16.61-.4.28-.73.74-1.38,1.3-1.93l.32.48c.12.18.33.29.54.29.04,0,.07,0,.11,0,.26-.04.46-.23.52-.47l.44-1.86c.04-.16,0-.33-.08-.47-.09-.14-.24-.24-.4-.27l-1.94-.43c-.25-.06-.52.04-.67.25-.15.21-.15.48-.02.69l.48.73c-.79.71-1.41,1.6-1.8,2.58-.12.32.05.68.37.8.08.02.15.04.23.04h0Z"
        fill="currentColor"
      />
      <path
        d="M18.24,13.61c-.33-.12-.7.04-.83.36-.28.73-.74,1.38-1.3,1.93l-.32-.48c-.14-.21-.4-.32-.65-.28-.26.04-.46.23-.52.47l-.44,1.86c-.08.34.14.67.48.75l1.94.43s.1.02.14.02c.21,0,.41-.09.53-.26.15-.2.16-.48.02-.69l-.48-.73c.79-.71,1.41-1.6,1.8-2.58.12-.32-.05-.68-.37-.8h-.01Z"
        fill="currentColor"
      />
    </>,
  ],
  [
    'fill',
    <>
      <path
        d="M14.33,6.67c-1.47,0-2.69,1.06-2.95,2.45-.07.36-.41.6-.78.54-.36-.07-.6-.41-.54-.78.37-2.02,2.14-3.55,4.26-3.55,2.39,0,4.33,1.94,4.33,4.33,0,2.12-1.53,3.89-3.55,4.26-.36.07-.71-.17-.78-.54-.07-.36.17-.71.54-.78,1.4-.26,2.45-1.48,2.45-2.95,0-1.66-1.34-3-3-3Z"
        fill="currentColor"
      />
      <path
        d="M9.67,18.67c2.39,0,4.33-1.94,4.33-4.33s-1.94-4.33-4.33-4.33-4.33,1.94-4.33,4.33,1.94,4.33,4.33,4.33Z"
        fill="currentColor"
      />
      <path
        d="M5.97,10.43c.26,0,.51-.16.61-.4.28-.73.74-1.38,1.3-1.93l.32.48c.12.18.33.29.54.29.04,0,.07,0,.11,0,.26-.04.46-.23.52-.47l.44-1.86c.04-.16,0-.33-.08-.47-.09-.14-.24-.24-.4-.27l-1.94-.43c-.25-.06-.52.04-.67.25-.15.21-.15.48-.02.69l.48.73c-.79.71-1.41,1.6-1.8,2.58-.12.32.05.68.37.8.08.02.15.04.23.04h0Z"
        fill="currentColor"
      />
      <path
        d="M18.24,13.61c-.33-.12-.7.04-.83.36-.28.73-.74,1.38-1.3,1.93l-.32-.48c-.14-.21-.4-.32-.65-.28-.26.04-.46.23-.52.47l-.44,1.86c-.08.34.14.67.48.75l1.94.43s.1.02.14.02c.21,0,.41-.09.53-.26.15-.2.16-.48.02-.69l-.48-.73c.79-.71,1.41-1.6,1.8-2.58.12-.32-.05-.68-.37-.8h-.01Z"
        fill="currentColor"
      />
    </>,
  ],
  [
    'duotone',
    <>
      <rect y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12c0,3.31,2.69,6,6,6h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <path
        d="M14.33,6.67c-1.47,0-2.69,1.06-2.95,2.45-.07.36-.41.6-.78.54-.36-.07-.6-.41-.54-.78.37-2.02,2.14-3.55,4.26-3.55,2.39,0,4.33,1.94,4.33,4.33,0,2.12-1.53,3.89-3.55,4.26-.36.07-.71-.17-.78-.54-.07-.36.17-.71.54-.78,1.4-.26,2.45-1.48,2.45-2.95,0-1.66-1.34-3-3-3Z"
        fill="currentColor"
      />
      <path
        d="M9.67,18.67c2.39,0,4.33-1.94,4.33-4.33s-1.94-4.33-4.33-4.33-4.33,1.94-4.33,4.33,1.94,4.33,4.33,4.33Z"
        fill="currentColor"
      />
      <path
        d="M5.97,10.43c.26,0,.51-.16.61-.4.28-.73.74-1.38,1.3-1.93l.32.48c.12.18.33.29.54.29.04,0,.07,0,.11,0,.26-.04.46-.23.52-.47l.44-1.86c.04-.16,0-.33-.08-.47-.09-.14-.24-.24-.4-.27l-1.94-.43c-.25-.06-.52.04-.67.25s-.15.48-.02.69l.48.73c-.79.71-1.41,1.6-1.8,2.58-.12.32.05.68.37.8.08.02.15.04.23.04h0Z"
        fill="currentColor"
      />
      <path
        d="M18.24,13.61c-.33-.12-.7.04-.83.36-.28.73-.74,1.38-1.3,1.93l-.32-.48c-.14-.21-.4-.32-.65-.28-.26.04-.46.23-.52.47l-.44,1.86c-.08.34.14.67.48.75l1.94.43s.1.02.14.02c.21,0,.41-.09.53-.26.15-.2.16-.48.02-.69l-.48-.73c.79-.71,1.41-1.6,1.8-2.58.12-.32-.05-.68-.37-.8h-.01Z"
        fill="currentColor"
      />
    </>,
  ],
])

export const Transactions = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
