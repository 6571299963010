import { HTMLAttributes, ReactNode, createElement } from 'react'
import { AghanimIcon } from '../../../icons'

interface ToolbarAlignOptionProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  icon: AghanimIcon
}

export const ToolbarAlignOption = ({ children, icon, ...rest }: ToolbarAlignOptionProps) => {
  return (
    <div
      {...rest}
      className="flex cursor-pointer items-center gap-1.5 rounded-md px-2 py-[7px] text-caption-md text-text-secondary transition-colors hover:bg-fg-primary-alt hover:text-text-primary"
    >
      {createElement(icon, { size: 16 })}
      <span>{children}</span>
    </div>
  )
}
