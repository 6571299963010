import { CSSProperties, ReactNode, forwardRef } from 'react'
import styled from '@emotion/styled'
import ToolTipBox from '../../layouts/dashboard/components/ToolTip/ToolTipBox'

type Props = {
  title?: string
  action?: ReactNode
  footer?: ReactNode
  children: ReactNode
  style?: CSSProperties
  tooltip?: string
}

const Root = styled.div`
  padding: 18px;
  border: 1px solid ${p => p.theme.palette.grey.borderSecondary};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.palette.text.secondary};
  font-size: 16px;
  font-weight: 600;
  gap: 6px;
`

const DashboardCard = forwardRef<HTMLDivElement, Props>(function (props, ref) {
  const { title, children, action, style, tooltip } = props as Props
  return (
    <Root style={style} ref={ref}>
      {title || action ? (
        <TitleContainer>
          <div>
            {title && (
              <Title data-testid="dashboard/chart-title">
                {title}
                {tooltip && <ToolTipBox tooltip={tooltip} />}
              </Title>
            )}
          </div>
          {action}
        </TitleContainer>
      ) : null}
      {children}
    </Root>
  )
})

export default DashboardCard
