export enum PaymentStatus {
  done = 'done',
  all = 'all',
  refund_requested = 'refund_requested',
  refunded = 'refunded',
  created = 'created',
  canceled = 'canceled',
  rejected = 'rejected',
  failed = 'failed', // что-то пошло не так в момент оплаты, и он может позде ещё раз попытаться
  captured = 'captured', // начали обрабатывать платёж
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  TND = 'TND',
}

export interface CouponDiscount {
  id: string
  name: string
  code: string
  discount_percent: number
}

export interface CouponBonus {
  id: string
  name: string
  code: string
  bonus_percent: number
}

export interface Discount {
  amount: number
  currency: Currency
  coupon: CouponDiscount
}

export interface Bonus {
  bonus_percent: number
  coupon: CouponBonus
}

export const REWARD_POINTS_PAYMENT_METHOD_ID = 'pm_reward_points'
