import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { Drawer, SpinnerAghanim } from '@/ui'
import { GAME_WEBHOOKS_PATH, useNavigateBack } from '@/libs'
import { webhookDetailQuery, webhooksQuery } from '../api'
import { WebhookEditForm, WebhookFormData } from '../widgets'

export const WebhookEditPage = () => {
  const ql = useQueryClient()
  const { companyId, gameId, webhookId } = useParams() as {
    companyId: string
    gameId: string
    webhookId: string
  }

  const back = useNavigateBack({ fallback: GAME_WEBHOOKS_PATH })
  const { data: webhook, isLoading } = useQuery(webhookDetailQuery(companyId, gameId, webhookId))

  const onSubmit = async (data: WebhookFormData) => {
    if (!webhook) {
      return
    }
    await dashboardClient.v1.updateWebhook(companyId, gameId, webhook.id, data)
    await ql.refetchQueries({ queryKey: webhookDetailQuery(companyId, gameId, webhookId).queryKey })
    await ql.invalidateQueries({ queryKey: webhooksQuery(companyId, gameId).queryKey })
    back.onClick()
  }

  if (isLoading || !webhook) {
    return (
      <Drawer open={true} width={564}>
        <div className="flex size-full items-center justify-center">
          <SpinnerAghanim />
        </div>
      </Drawer>
    )
  }

  return (
    <Drawer open={true} width={564}>
      <WebhookEditForm webhook={webhook} onSubmit={onSubmit} />
    </Drawer>
  )
}
