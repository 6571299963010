import { ComingSoonLogo } from '@/components/ComingSoonLogo'
import i18next from 'i18next'

export default () => (
  <div className="flex size-full flex-col items-center justify-center bg-fg-primary-hover text-center">
    <div className="mb-6">
      <ComingSoonLogo />
    </div>
    <div>
      <div className="mb-3 text-title-t6">{i18next.t('coming-soon.title')}</div>
      <div className="max-w-96 text-paragraph-sm text-text-tertiary">{i18next.t('coming-soon.desc')}</div>
    </div>
  </div>
)
