import { ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Tab } from './Tab'

interface TabLinkProps {
  to: string
  children: ReactNode
  count?: number
}

export const TabLink = ({ children, to, count }: TabLinkProps) => {
  const location = useLocation()
  const isActive = useMemo(() => `${location.pathname}${location.search}` === to, [location])
  return (
    <Tab count={count} isActive={isActive} to={to}>
      {children}
    </Tab>
  )
}
