import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

interface CouponDeleteMutate {
  id: string
}

export const useCouponDeleteMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id }: CouponDeleteMutate) => dashboardClient.v1.deleteCoupon(companyId, gameId, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['coupons', gameId] })
    },
  })
}
