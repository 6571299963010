import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { useGenerateCompanyPath } from '@/libs'
import { localesQueryOptions } from './localesQueryOptions'

interface LocaleDeleteMutate {
  localeId: string
}

export const localeDeleteMutation = () => {
  const ql = useQueryClient()
  const { companyId, gameId } = useGenerateCompanyPath()

  return useMutation({
    mutationFn: async ({ localeId }: LocaleDeleteMutate) => {
      const r = await dashboardClient.v1.deleteLocale(companyId, gameId, localeId)
      return r.data
    },
    onSuccess: (_, variables) => {
      ql.setQueryData(localesQueryOptions(companyId, gameId).queryKey, (old = []) => {
        return old.filter(locale => locale.id !== variables.localeId)
      })
    },
  })
}
