import '../ToolTip/ToolTip.css'
import { HTMLAttributes, useContext } from 'react'
import DashboardCard from '../../../../components/shared/DashboardCard'
import ReactECharts from 'echarts-for-react'
import i18next from 'i18next'
import { useTheme } from '@mui/material'
import { formatMoney } from '../../../../util'
import Loader from '../Loader/Loader'
import SingleValue, { SingleValueType } from '../SingleValue/SingleValue'
import { LoaderContainer } from '../Loader/LoaderContainer'
import { AnalyticsDataContext, IAnalyticsDataContext } from '../../Context'
import { Legend } from '../Legend'
import { getDateCategoryParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'

interface ToolTipParam {
  axisValue: string
  value: number
  dataIndex: number
  seriesName: string
}
interface RevenueByDeviceProps extends HTMLAttributes<HTMLDivElement> {}

const RevenueByDevice = (props: RevenueByDeviceProps) => {
  const theme = useTheme()
  const context = useContext(AnalyticsDataContext) as IAnalyticsDataContext
  const { t } = useTranslation()
  const COLORS = ['#0EA5E9', '#86EFAC', '#94A3B8']

  const loading = !context.data.revenue_by_date_device
  const data = context.data.revenue_by_date_device || []

  let series = new Map<string, number[]>()
  series.set('Apple', [])
  series.set('Android', [])
  series.set(i18next.t('dashboard.other'), [])

  let categories: string[] = []

  data.forEach(it => {
    if (!categories.includes(it.category)) {
      categories.push(it.category)
    }
  })

  data.forEach(it => {
    let ser_name = it.series_name
    if (ser_name == 'Other') {
      ser_name = i18next.t('dashboard.other')
    }
    if (ser_name) {
      let s = series.get(ser_name)
      if (!s) {
        s = new Array(categories.length).fill(0)
        series.set(ser_name, s)
      }

      let idx = categories.indexOf(it.category)
      if (it.value && idx > -1) {
        s[idx] = it.value
      }
    } else {
      series.forEach(s => s.push(0))
    }
  })

  const renderToolTipHtml = (params: ToolTipParam[]) => {
    let res = params
      .filter(it => it.value !== null)
      .sort((a, b) => a.seriesName.localeCompare(b.seriesName))
      .map(
        it =>
          `<div class="chart-tooltip--subtitle">
            <span>${it.seriesName}</span>
            <span>${formatMoney(it.value || 0)}</span>
          </div>`,
      )
      .join('')

    return res ? res : `<div class="chart-tooltip--subtitle">${i18next.t('dashboard.chart.no-data')}</div>`
  }

  const categoriesLabels = Array.from(categories)

  const options = {
    title: {
      show: false,
    },
    tooltip: {
      show: true,
      backgroundColor: '#000000CC',
      trigger: 'axis',
      borderColor: 'transparent',
      formatter: function (params: ToolTipParam[]) {
        let cat = categoriesLabels[params[0].dataIndex]

        return `<div class="chart-tooltip" style="height: auto">
                  <div class="chart-tooltip--title">${cat ? t('intl.DateTime', getDateCategoryParams(cat, context.granularity)) : params[0].axisValue}</div>
                  <div class="chart-tooltip--separator"></div>
                  ${renderToolTipHtml(params)}
              </div>`
      },
    },
    legend: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 24,
      containLabel: false,
    },
    xAxis: [
      {
        axisLabel: {
          fontSize: 12,
          color: theme.palette.text.tertiary,
          fontWeight: '500',
          fontFamily: 'InterAg',
        },
        axisPointer: {
          type: 'line',
          label: {
            show: false,
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: theme.palette.grey.borderSecondary,
          },
        },
        axisTick: {
          show: false,
        },
        type: 'category',
        boundaryGap: true,
        data: categoriesLabels.map(it => t('intl.DateTime', getDateCategoryParams(it, context.granularity))),
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: false,
        splitLine: {
          show: false,
        },
      },
    ],
    axisPointer: {
      lineStyle: {
        color: '#000',
        width: 1,
        type: 'solid',
      },
    },
    series: Array.from(series.entries()).map(([name, data], idx) => ({
      stack: 'a',
      name: name,
      type: 'bar',
      barWidth: '70%',
      data: data.map(it => ({
        value: it,
        itemStyle: {
          borderRadius: [4, 4, 0, 0],
          borderColor: 'rgba(255,255,255,1)',
          borderWidth: 0.5,
        },
      })),
      color: COLORS[idx],
      label: {
        show: false,
      },
    })),
  }

  return (
    <DashboardCard
      title={i18next.t('dashboard.revenue-by-device')}
      tooltip={i18next.t('dashboard.revenue-by-device.tooltip')}
      style={props.style}
    >
      {loading ? (
        <LoaderContainer style={{ height: '382px' }}>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <SingleValue value={context.data.revenue} label={''} type={SingleValueType.money} />
          <div>
            <ReactECharts
              option={options}
              style={{
                height: '290px',
                width: '100%',
              }}
            />
            <Legend
              className="mt-2"
              items={[
                {
                  name: 'iOS',
                  classColor: 'bg-fg-brand-primary',
                  type: 'bar',
                },
                {
                  name: 'Android',
                  classColor: 'bg-fg-success-primary',
                  type: 'bar',
                },
                {
                  name: i18next.t('dashboard.other'),
                  classColor: 'bg-text-disabled',
                  type: 'bar',
                },
              ]}
            />
          </div>
        </>
      )}
    </DashboardCard>
  )
}

export default RevenueByDevice
