import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <path
      d="M13.14,4.8l4.83,2.59s.02,0,.02.01c.71.41,1.14,1.17,1.14,1.99v5.2c0,.82-.43,1.58-1.14,1.99,0,0-.01,0-.02.01l-4.83,2.59c-.35.2-.74.3-1.14.3s-.78-.1-1.14-.3l-4.83-2.59s-.02,0-.02-.01c-.71-.41-1.14-1.17-1.14-1.99v-5.2c0-.82.43-1.58,1.14-1.99,0,0,.01,0,.02-.01l4.83-2.59c.7-.4,1.57-.4,2.27,0ZM12.75,17.07c0,.28.3.46.55.33l3.93-2.11c.24-.14.39-.41.39-.69v-3.94c0-.28-.3-.46-.55-.33l-4.12,2.14c-.12.06-.2.19-.2.33v4.27ZM11.83,11.18c.11.06.24.06.35,0l4.28-2.23c.27-.14.27-.52,0-.66l-4.05-2.17s-.02,0-.02-.01c-.24-.14-.54-.14-.78,0,0,0-.01,0-.02.01l-4.82,2.59c-.24.14-.39.41-.39.69v5.2c0,.29.15.55.39.69l3.93,2.11c.25.13.55-.05.55-.33v-4.27c0-.14-.08-.27-.2-.33l-3.14-1.63c-.37-.19-.51-.64-.32-1.01.19-.37.64-.51,1.01-.32l3.23,1.68Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'fill',
    <path
      d="M13.14,4.8l4.83,2.59s.02,0,.02.01c.71.41,1.14,1.17,1.14,1.99v5.2c0,.82-.43,1.58-1.14,1.99,0,0-.01,0-.02.01l-4.83,2.59c-.35.2-.74.3-1.14.3s-.78-.1-1.14-.3l-4.83-2.59s-.02,0-.02-.01c-.71-.41-1.14-1.17-1.14-1.99v-5.2c0-.82.43-1.58,1.14-1.99,0,0,.01,0,.02-.01l4.83-2.59c.7-.4,1.57-.4,2.27,0ZM11.83,11.18c.11.06.24.06.35,0l4.28-2.23c.27-.14.27-.52,0-.66l-4.05-2.17s-.02,0-.02-.01c-.24-.14-.54-.14-.78,0,0,0-.01,0-.02.01l-4.82,2.59c-.24.14-.39.41-.39.69v5.2c0,.29.15.55.39.69l3.93,2.11c.25.13.55-.05.55-.33v-4.27c0-.14-.08-.27-.2-.33l-3.14-1.63c-.37-.19-.51-.64-.32-1.01.19-.37.64-.51,1.01-.32l3.23,1.68Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'duotone',
    <>
      <rect x="0" y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12C0,21.31,2.69,24,6,24h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <path
        d="M13.14,4.8l4.83,2.59s.02,0,.02.01c.71.41,1.14,1.17,1.14,1.99v5.2c0,.82-.43,1.58-1.14,1.99,0,0-.01,0-.02.01l-4.83,2.59c-.35.2-.74.3-1.14.3s-.78-.1-1.14-.3l-4.83-2.59s-.02,0-.02-.01c-.71-.41-1.14-1.17-1.14-1.99v-5.2c0-.82.43-1.58,1.14-1.99,0,0,.01,0,.02-.01l4.83-2.59c.7-.4,1.57-.4,2.27,0ZM11.83,11.18c.11.06.24.06.35,0l4.28-2.23c.27-.14.27-.52,0-.66l-4.05-2.17s-.02,0-.02-.01c-.24-.14-.54-.14-.78,0,0,0-.01,0-.02.01l-4.82,2.59c-.24.14-.39.41-.39.69v5.2c0,.29.15.55.39.69l3.93,2.11c.25.13.55-.05.55-.33v-4.27c0-.14-.08-.27-.2-.33l-3.14-1.63c-.37-.19-.51-.64-.32-1.01.19-.37.64-.51,1.01-.32l3.23,1.68Z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </>,
  ],
])

export const Sku = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
