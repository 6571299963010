import { AttrType } from '@/api/dashboard'
import { Input, InputExtraSection, Select, SelectCountry } from '@/ui'
import { Attribute, LastDateFilter, OperatorType } from './types'
import { SelectSegment } from '../../components/SelectSegment/SelectSegment'
import { SelectSkuItem } from '../components/SelectSkuItem/SelectSkuItem'
import i18next from 'i18next'
import { InputList } from './InputList'

type VType = string | number | null | undefined | string[] | boolean | number[]

export const AttrValueEditor = ({
  attr,
  onChange,
  value,
  isMoney,
  operator,
}: {
  isMoney: boolean
  attr: Attribute
  value: VType
  onChange: (v: VType) => void
  operator?: OperatorType
}) => {
  if (!attr) {
    return (
      <Input value={value as string} style={{ width: '100%' }} onChange={e => onChange(e.target.value as string)} />
    )
  }

  const attrType = attr.type

  if (!attrType) {
    return <Input value={''} disabled style={{ width: '100%' }} />
  }

  if (operator == OperatorType.in_list || operator == OperatorType.not_in_list) {
    return <InputList value={value as string[]} onChange={v => onChange(v)} />
  }

  if (attr?.selectorValueType == 'segment') {
    return (
      <SelectSegment
        mode="slug"
        dropDownWidth="400px"
        value={value as string}
        style={value ? {} : { minWidth: '120px' }}
        onChange={v => onChange(v as string)}
      />
    )
  }

  if (attr?.selectorValueType == 'sku') {
    return (
      <SelectSkuItem
        dropDownWidth="500px"
        onChange={v => {
          onChange(v as string)
        }}
        value={value as string}
      />
    )
  }

  if (attr?.selectorValueType == 'skus') {
    return (
      <SelectSkuItem
        multiselect={true}
        mode="single"
        dropDownWidth="300px"
        onChange={v => {
          onChange(v as string[])
        }}
        value={(value || []) as string[]}
      />
    )
  }

  if (attr?.selectorValueType == 'country') {
    return <SelectCountry onChange={v => onChange(v)} value={value as string} />
  }

  const getMoneyValue = (v: VType) => {
    return v || v == 0 ? (v as number) / 100 : ''
  }

  const renderNumber = () => {
    if (operator == OperatorType.not_in_range || operator == OperatorType.in_range) {
      let fromValue = (value as number[])[0]
      let toValue = (value as number[])[1]
      return (
        <div className="flex items-center gap-1">
          <Input
            type="number"
            value={isMoney ? getMoneyValue(fromValue) : fromValue}
            extraRight={isMoney ? <InputExtraSection side="right">USD</InputExtraSection> : null}
            onChange={e => {
              if (!e.target.value) {
                return onChange([null as unknown as number, toValue])
              }
              let v = parseFloat(e.target.value)
              if (e.target.value && isNaN(v)) {
                return false
              }
              onChange([isMoney ? v * 100 : v, toValue])
            }}
          />
          -
          <Input
            type="number"
            value={isMoney ? getMoneyValue(toValue) : toValue}
            extraRight={isMoney ? <InputExtraSection side="right">USD</InputExtraSection> : null}
            onChange={e => {
              if (!e.target.value) {
                return onChange([fromValue, null as unknown as number])
              }
              let v = parseFloat(e.target.value)
              if (e.target.value && isNaN(v)) {
                return false
              }
              onChange([fromValue, isMoney ? v * 100 : v])
            }}
          />
        </div>
      )
    }

    if (isMoney) {
      return (
        <Input
          type="number"
          value={getMoneyValue(value)}
          extraRight={<InputExtraSection side="right">USD</InputExtraSection>}
          onChange={e => {
            if (!e.target.value) {
              return onChange(null)
            }

            let v = parseFloat(e.target.value)
            if (e.target.value && isNaN(v)) {
              return false
            }
            onChange(v * 100)
          }}
        />
      )
    }

    return (
      <Input
        type="number"
        value={value as number}
        onChange={e => {
          let v = parseFloat(e.target.value)
          if (e.target.value && isNaN(v)) {
            return false
          }
          onChange(v)
        }}
      />
    )
  }

  switch (attrType) {
    case AttrType.String:
      return <Input value={value as string} onChange={e => onChange(e.target.value)} />
    case AttrType.Number:
      return renderNumber()
    case AttrType.Boolean:
      return (
        <Select
          placeholder={i18next.t('campaign.condition.select')}
          value={value == true ? 'True' : 'False'}
          style={value ? {} : { minWidth: '120px' }}
          options={[
            { value: 'True', children: 'True' },
            { value: 'False', children: 'False' },
          ]}
          onChange={v => onChange(v == 'True')}
        />
      )
    case AttrType.Date:
      return (
        <Select
          //errorText={errorTokens.includes(rightOperand) ? ' ' : undefined}
          value={value as string}
          style={{ minWidth: '150px' }}
          placeholder={i18next.t('campaign.condition.select')}
          onChange={v => onChange(v as string)}
          options={Object.values(LastDateFilter).map(it => ({
            value: it,
            children: i18next.t(`campaign.condition.date-filter.${it}`),
          }))}
        />
      )
    default:
      return <Input value={''} style={{ width: '100px' }} disabled />
  }
}
