import { Settings } from '@/components/ui/Settings'
import i18next from 'i18next'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import dayjs from 'dayjs'
import StyledDatePicker from '@/components/StyledDatePicker'
import DurationSelectorDHMS from '@/components/DurationSelectorDHMS'

export const formatDateTimeUTC = (value: number, addTime = false) => {
  let d = new Date(value * 1000)

  let ds = dayjs(d)
  ds = ds.add(d.getTimezoneOffset(), 'minute')

  if (addTime) {
    return ds.format('MM/DD/YYYY hh:mmA')
  }

  return ds.format('YYYY-MM-DD')
}

const getUtcStartDate = () => {
  let d = new Date()
  return dayjs(d).startOf('day').add(-d.getTimezoneOffset(), 'minute')
}

const getUTCSeconds = (value: string) => {
  let d = new Date()
  return dayjs(value).add(-d.getTimezoneOffset(), 'minute').toDate().getTime() / 1000
}

export const ItemTimelimit = ({
  start_at,
  end_at,
  duration,
  setValue,
  children,
  showDuration = true,
}: {
  showDuration?: boolean
  start_at?: number
  end_at?: number
  duration?: number
  children?: React.ReactNode
  setValue: (prop: 'duration' | 'start_at' | 'end_at', value?: number) => void
}) => {
  return (
    <Settings text={i18next.t('campaign.settings.limit-limitations')}>
      <CheckBoxContainer
        checked={start_at != undefined && end_at != undefined}
        onChange={v => {
          if (v.target.checked) {
            setValue('duration', null as unknown as undefined)
            setValue('start_at', Math.round(getUtcStartDate().toDate().getTime() / 1000))
            setValue('end_at', Math.round(getUtcStartDate().add(1, 'month').toDate().getTime() / 1000))
          } else {
            setValue('start_at', null as unknown as undefined)
            setValue('end_at', null as unknown as undefined)
          }
        }}
        label={i18next.t('campaign.virtual-sku-editor.limit-by-dates')}
      >
        <div className="flex gap-4">
          {start_at && (
            <StyledDatePicker
              label={i18next.t('campaign.virtual-sku-editor.start-date')}
              value={formatDateTimeUTC(start_at)}
              onChange={(value: string) => setValue('start_at', getUTCSeconds(value))}
            />
          )}

          {end_at && (
            <StyledDatePicker
              label={i18next.t('campaign.virtual-sku-editor.end-date')}
              value={formatDateTimeUTC(end_at)}
              onChange={(value: string) => setValue('end_at', getUTCSeconds(value) + 86399)}
            />
          )}
        </div>
      </CheckBoxContainer>

      {showDuration && (
        <CheckBoxContainer
          className="mt-4"
          checked={duration != undefined}
          onChange={v => {
            setValue('duration', v.target.checked ? 3600 : undefined)
            if (v.target.checked) {
              setValue('start_at', null as unknown as undefined)
              setValue('end_at', null as unknown as undefined)
            }
          }}
          label={i18next.t('campaign.virtual-sku-editor.limit-by-timer')}
        >
          <DurationSelectorDHMS
            duration={duration || 0}
            size="small"
            onChange={v => {
              setValue('duration', v)
            }}
          />
        </CheckBoxContainer>
      )}

      {children}
    </Settings>
  )
}
