import i18next from 'i18next'
import { Button, FieldGroup, FieldValidationMessage, Input, Modal, ModalFooter, ModalProps, ModalTitle } from '@/ui'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

interface AddBlockDialogProps extends ModalProps {
  onAdd: (name: string) => void
}

export const AddPageDialog = (props: AddBlockDialogProps) => {
  const { ...rest } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ name: string }>({
    values: {
      name: 'New Page',
    },
  })

  const saveClick = (data: { name: string }) => {
    props.onAdd(data.name)
    props.onClose?.()
  }

  return (
    <Modal {...rest}>
      <ModalTitle>{i18next.t('hub-editor.add-page')}</ModalTitle>

      <div className="mt-10">
        <FieldGroup label={i18next.t('hub-settings.banner.title')}>
          <Input {...register('name', { required: i18next.t('validation.required') })} />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>
      </div>

      <ModalFooter>
        <Button size="lg" onClick={() => props.onClose?.()}>
          {i18next.t('Cancel')}
        </Button>
        <Button size="lg" variant="primary" onClick={handleSubmit(saveClick)}>
          {i18next.t('Add')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
