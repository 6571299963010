import { forwardRef, useMemo } from 'react'

import { COUNTRY_BY_REGIONS } from './data'
import { useTranslation } from 'react-i18next'
import { ToolbarFilter, ToolbarFilterProps } from '@/ui'

export const SelectRegion = forwardRef<HTMLDivElement, Omit<ToolbarFilterProps, 'items' | 'label'>>(
  function SelectRegion(props) {
    const { t } = useTranslation()

    const regions = useMemo(() => {
      let items = [
        {
          value: 'Global',
          label: t('game-settings.ps-table.regions.global'),
        },
      ]

      items.push(
        ...Object.keys(COUNTRY_BY_REGIONS)
          .sort()
          .map(it => ({
            value: it,
            label: t(`game-settings.ps-table.regions.${it}`),
          })),
      )
      return items
    }, [])

    return (
      <ToolbarFilter
        label={t('game-settings.ps-table.region')}
        items={regions}
        onChange={props.onChange}
        value={props.value}
      />
    )
  },
)
