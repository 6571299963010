import i18next from 'i18next'
import AIDescriptionEditor from '../../ai/AIDescriptionEditor'
import { HUB_API } from '../../HubAPI'
import { useParams } from 'react-router-dom'
import { Controller, useFormContext } from 'react-hook-form'
import { Block } from '@/api/dashboard'
import { FieldGroup, FieldValidationMessage, Input } from '@/ui'
import { ErrorMessage } from '@hookform/error-message'

const TextBlockEditor = () => {
  const { gameId } = useParams()
  const game = HUB_API.getGame(gameId)

  const {
    register,
    setValue,
    formState: { errors },
    control,
  } = useFormContext<Block>()

  return (
    <div>
      <FieldGroup label="Title">
        <Input {...register('title', { required: i18next.t('validation.required') })} />
        <ErrorMessage
          name="title"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </FieldGroup>

      <Controller
        name="text"
        control={control}
        rules={{ required: i18next.t('validation.required') }}
        render={({ field }) => (
          <>
            <AIDescriptionEditor
              label={'Text'}
              prompt={'Create text for banner of Game Hub. Max answer symbols 200.'}
              context={`You are AI assistant for create description for banners hub websites if mobile game "${game?.name}" `}
              value={field.value || ''}
              onDone={text => {
                setValue('text', text)
              }}
              onChange={(text: string) => {
                setValue('text', text)
              }}
            />
            <ErrorMessage
              name="text"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </>
        )}
      />
    </div>
  )
}

export default TextBlockEditor
