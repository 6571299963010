import { HTMLAttributes, ReactElement, ReactNode, cloneElement, forwardRef, useMemo, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import {
  FloatingArrow,
  FloatingPortal,
  Placement,
  arrow,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useMergeRefs,
} from '@floating-ui/react'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { getFloatingMotionProps } from '../Floating'

interface Options {
  /**
   * Show an arrow pointing to the reference element.
   */
  arrow?: boolean
  /**
   * Placement of the tooltip near the trigger element.
   */
  placement?: Placement
  /**
   * Distance from trigger element.
   */
  distance?: number
  /**
   * Delay before the item appears and disappears, you can customize the stages individually.
   */
  delay?:
    | number
    | Partial<{
        open: number
        close: number
      }>
  /**
   * Disabling the operation of the tooltip.
   */
  disabled?: boolean
  /**
   * Display large text in the tooltip.
   */
  supportingText?: boolean
  /**
   * Will close the tooltip when the trigger element is clicked.
   */
  closeOnPress?: boolean
}

interface TooltipProps extends HTMLAttributes<HTMLElement> {
  /**
   * Element, the trigger for the tooltip, must necessarily have a `forwardRef`.
   */
  children: ReactElement
  /**
   * Tooltip content, you can use a custom element.
   */
  message: ReactNode
  /**
   * Tooltip behavior settings.
   */
  options?: Options
}

const Floating = styled.div`
  z-index: 2900;
`

const Message = styled(motion.div)`
  background-color: ${p => p.theme.palette.background.fgTooltip};
  color: ${p => p.theme.palette.text.inverse};
  border-radius: 6px;
  max-width: 240px;
  box-shadow:
    0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);

  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.06px;

  &[data-supporting-text='false'] {
    padding: 8px 12px;
    text-align: center;
  }

  &[data-supporting-text='true'] {
    padding: 12px;
  }
`

const defaultOptions: Options = {
  arrow: true,
  distance: 12,
  delay: { open: 250, close: 0 },
  disabled: false,
  supportingText: false,
  closeOnPress: false,
}

export const Tooltip = forwardRef<HTMLElement, TooltipProps>(function Tooltip(
  { children, message, options: _options, ...rest },
  ref,
) {
  const theme = useTheme()
  const arrowRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const options: Options = { ...defaultOptions, ..._options }

  const {
    refs,
    context,
    floatingStyles,
    placement: floatingPlacement,
  } = useFloating({
    open: isOpen,
    placement: options.placement,
    middleware: [
      options.arrow && arrow({ element: arrowRef }),
      shift({ padding: 8 }),
      offset(options.distance),
      flip(),
    ],
    onOpenChange: setIsOpen,
  })

  const { arrow: arrowData, shift: shiftData } = context.middlewareData

  const arrowStaticPosition = useMemo(
    () => ({
      left: (shiftData?.x ?? 0) > 0 ? (arrowData?.x ?? 0) - (shiftData?.x ?? 0) : (arrowData?.x ?? undefined),
      top: (shiftData?.y ?? 0) > 0 ? (arrowData?.y ?? 0) - (shiftData?.y ?? 0) : (arrowData?.y ?? undefined),
    }),
    [shiftData, arrowData],
  )

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      delay: options.delay,
      enabled: !options.disabled,
      handleClose: safePolygon({ requireIntent: true }),
    }),
    useDismiss(context, {
      referencePress: options.closeOnPress,
    }),
  ])

  const referenceMergedRef = useMergeRefs([refs.setReference, ref])

  return (
    <>
      {cloneElement(children, {
        ...rest,
        ...getReferenceProps(),
        ref: referenceMergedRef,
        'data-open-tooltip': isOpen,
      })}

      <AnimatePresence>
        {!options.disabled && isOpen && (
          <FloatingPortal>
            <Floating ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
              <Message
                {...getFloatingMotionProps(floatingPlacement)}
                data-supporting-text={options.supportingText || false}
              >
                {options.arrow && (
                  <FloatingArrow
                    ref={arrowRef}
                    context={context}
                    tipRadius={4}
                    width={12}
                    height={8}
                    staticOffset={arrowStaticPosition.left || arrowStaticPosition.top || undefined}
                    fill={theme.palette.background.fgTooltip}
                  />
                )}
                {message}
              </Message>
            </Floating>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </>
  )
})
