import i18next from 'i18next'
import { SegmentGroupRead, SegmentGroupType, SegmentRead } from '../../../api/dashboard'
import { Controller, useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  ColorSelector,
  FieldGroup,
  FieldValidationMessage,
  Input,
  Modal,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
} from '../../../components/ui'
import { Textarea } from '../../../components/ui/Textarea'
import { useSegmentGroupsQuery } from '../api/useSegmentGroupsQuery'
import { useSegmentCreateMutate } from '../api/useSegmentCreateMutate'
import { useSegmentUpdateMutate } from '../api/useSegmentUpdateMutate'
import { GAME_SEGMENT_EDIT_GROUP_GRAPH_PATH } from '../../../libs/routerPaths'
import EditSegmentGroupDialog from './EditSegmentGroupDialog'
import slugify from '../../../libs/slugify'
import { useState } from 'react'
import { ErrorMessage } from '@hookform/error-message'

interface EditSegmentDialogProps extends ModalProps {
  disableSelectGroup?: boolean
  item: SegmentRead
}

const ADD_GROUP = '__add__group__'

export default function EditSegmentDialog(props: EditSegmentDialogProps) {
  const { ...rest } = props
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const navigate = useNavigate()

  const [editSegmentGroupModal, setEditSegmentGroupModal] = useState<SegmentGroupRead | null>(null)
  const { data: items = [] } = useSegmentGroupsQuery(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useSegmentCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useSegmentUpdateMutate(companyId, gameId)

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<SegmentRead>({
    mode: 'onChange',
    values: { ...props.item },
  })
  const color = watch('color')

  const onSaveClick = async (data: SegmentRead) => {
    if (data.id) {
      await updateMutateAsync({
        id: data.id,
        update: data,
      })
    } else {
      await createMutateAsync({
        create: data,
      })

      if (data.segment_group_id) {
        navigate(
          generatePath(GAME_SEGMENT_EDIT_GROUP_GRAPH_PATH, { companyId, gameId, groupId: data.segment_group_id }),
        )
      }
    }
    props.onClose?.()
  }

  if (editSegmentGroupModal) {
    return (
      <EditSegmentGroupDialog
        item={editSegmentGroupModal}
        onClose={() => {
          setEditSegmentGroupModal(null)
        }}
        onAdded={item => {
          setValue('segment_group_id', item.id)
          setEditSegmentGroupModal(null)
        }}
      />
    )
  }

  return (
    <Modal {...rest}>
      <ModalTitle>{i18next.t(props.item.id ? 'segment.edit' : 'segment.add')}</ModalTitle>

      <div className="mt-10">
        <FieldGroup label={i18next.t('segment.name')}>
          <Controller
            control={control}
            name="name"
            rules={{ required: i18next.t('validation.required') }}
            render={({ field: { value, onChange } }) => (
              <Input
                autoFocus
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                  if (!props.item.id) {
                    setValue('slug', slugify(e.target.value))
                  }
                }}
              />
            )}
          />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('segment.slug')}>
          <Controller
            control={control}
            rules={{ required: i18next.t('validation.required') }}
            name="slug"
            render={({ field: { value, onChange } }) => (
              <Input value={value} onChange={e => onChange(slugify(e.target.value))} />
            )}
          />
          <ErrorMessage
            name="slug"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('segments.group')}>
          <Controller
            control={control}
            name="segment_group_id"
            render={({ field: { value, onChange } }) => (
              <Select
                disabled={props.disableSelectGroup}
                onChange={v => {
                  if (v == ADD_GROUP) {
                    setEditSegmentGroupModal({
                      name: 'New Group',
                      type: SegmentGroupType.Static,
                      enabled: true,
                      is_template: false,
                    } as SegmentGroupRead)
                  } else {
                    onChange(v as string)
                  }
                }}
                placeholder={i18next.t('segments.select-group')}
                value={value}
                options={[
                  ...items.map(it => ({ value: it.id, children: it.name })),
                  {
                    value: ADD_GROUP,
                    children: <span className="font-semibold"> {i18next.t('segments.group.add')}...</span>,
                  },
                ]}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('segment.description')}>
          <Textarea {...register('description')} />
        </FieldGroup>

        <FieldGroup label={i18next.t('segment.color')}>
          <ColorSelector {...register('color', { required: false })} value={color} />
        </FieldGroup>
      </div>

      <ModalFooter>
        <Button className="w-full" size="lg" onClick={props.onClose}>
          {i18next.t('Cancel')}
        </Button>
        <Button className="w-full" size="lg" variant="primary" onClick={handleSubmit(onSaveClick)}>
          {i18next.t(props.item.id ? 'Save2' : 'Add')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
