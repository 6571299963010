import { useContext } from 'react'
import {
  AttrDataType,
  Attribute,
  CAMPAIGN_ATTRIBUTES,
  DATE_TIME_ATTR,
  DETECTED_COUNTRY_ATTR,
  ExtAttrType,
  PLAYER_ATTRIBUTES,
  TokenValue,
} from './types'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { CampaignContext, EditorMode, ICampaignContext } from '../Context'
import { Token, TokenType } from '@/api/dashboard'
import { isCustomEvent } from '../Settings'
import { SettingsIcon } from '../icons/SettingsIcon'
import styled from '@emotion/styled'
import { LinkBrokenIcon } from '../icons/LinkBrokenIcon'
import i18next from 'i18next'
import { NestedMenuItemProps, SelectSubMenu, Tooltip } from '@/ui'
import { WebhookIcon } from '@/icons'

const CustomAttrIcon = styled.div`
  width: 14px;
  height: 14px;
  display: inline-block;
  border: 2px solid ${p => p.theme.palette.primary.main};
  border-radius: 3px;
`

const AttrIcon = styled.div`
  border-radius: 3px;
  width: 14px;
  height: 14px;
  display: inline-block;
  background-color: ${p => p.theme.palette.primary.main};
`

const LinkBroken = styled.div`
  background-color: ${p => p.theme.palette.background.fgskeleton};
  border: 1px solid ${p => p.theme.palette.grey.border};
  border-radius: 4px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.palette.text.input};
`

const getIcon = (attr: Attribute) => {
  switch (attr.dataType) {
    case AttrDataType.may_complement:
      return (
        <Tooltip message={i18next.t('campaign.attr.may_complement')}>
          <SettingsIcon />
        </Tooltip>
      )
    case AttrDataType.webhook_complement:
      return (
        <Tooltip message={i18next.t('campaign.attr.webhook_complement')}>
          <div className="text-fg-brand-primary">
            <WebhookIcon />
          </div>
        </Tooltip>
      )
    default:
      return (
        <Tooltip message={i18next.t('campaign.attr.built_in')}>
          <AttrIcon />
        </Tooltip>
      )
  }
}

const getRightIcon = (attr: Attribute) => {
  if (attr.isReady) {
    return undefined
  }

  return (
    <LinkBroken>
      <LinkBrokenIcon />
    </LinkBroken>
  )
}

export default function AttrSelector(props: {
  token: Token
  error?: boolean
  sx?: SxProps<Theme>
  onChange: (v: TokenValue, attrType: ExtAttrType) => void
}) {
  const context = useContext(CampaignContext) as ICampaignContext
  const cattr = context?.playerCustomAttributes || []

  let textFieldValue = props.token.value

  if (props.token.value) {
    switch (props.token.type) {
      case TokenType.Attribute:
        textFieldValue = 'Player.' + PLAYER_ATTRIBUTES[props.token.value as string].name
        break
      case TokenType.CustomAttribute:
        let at = cattr?.find(it => it.name == props.token.value)
        if (at) {
          textFieldValue = 'Player.' + at.name
        } else {
          textFieldValue = null
        }
        break
      case TokenType.EventAttribute:
        let attr = Object.values(CAMPAIGN_ATTRIBUTES)
          .flat()
          .find(it => it.id == props.token.value)
        textFieldValue = 'Event.' + attr?.name
        break
      case TokenType.CustomEventAttribute:
        let cuattr = context.campaign.attributes?.custom_attributes?.find(it => it.name == props.token.value)
        textFieldValue = 'Event.' + cuattr?.name
        break
    }
  }

  let campaignItems: NestedMenuItemProps[] = []

  switch (context.mode) {
    case EditorMode.Campaign:
      if (isCustomEvent(context.campaign.type)) {
        campaignItems = CAMPAIGN_ATTRIBUTES[''].map(it => ({
          value: it.id!,
          name: it.name,
          description: i18next.t(`campaign.attr-desc.${it.id}`),
          type: TokenType.EventAttribute,
          icon: getIcon(it),
        }))

        context.campaign.attributes?.custom_attributes?.forEach(it => {
          campaignItems.push({
            value: it.name,
            name: it.name,
            description: it.description,
            type: TokenType.CustomEventAttribute,
            icon: (
              <Tooltip message={i18next.t('campaign.attr.custom')}>
                <CustomAttrIcon />
              </Tooltip>
            ),
          })
        })
      } else {
        let campaign_attributes = [...CAMPAIGN_ATTRIBUTES['']]

        if (CAMPAIGN_ATTRIBUTES[context.campaign.type]) {
          campaign_attributes.push(...CAMPAIGN_ATTRIBUTES[context.campaign.type])
        }

        campaignItems = campaign_attributes.map(it => ({
          value: it.id!,
          name: it.name,
          description: i18next.t(`campaign.attr-desc.${it.id}`),
          type: TokenType.EventAttribute,
          icon: getIcon(it),
        }))
      }
      break
    case EditorMode.Store:
      campaignItems = [DATE_TIME_ATTR, DETECTED_COUNTRY_ATTR].map(it => ({
        value: it.id!,
        name: it.name,
        description: i18next.t(`campaign.attr-desc.${it.id}`),
        type: TokenType.EventAttribute,
        icon: getIcon(it),
      }))
      break
    case EditorMode.StoreItem:
      break
  }

  const playerAttr = [
    ...Object.keys(PLAYER_ATTRIBUTES).map(it => ({
      value: it,
      description: i18next.t(`campaign.attr-desc.${it}`),
      name: PLAYER_ATTRIBUTES[it].name,
      type: TokenType.Attribute,
      icon: getIcon(PLAYER_ATTRIBUTES[it]),
      rightIcon: getRightIcon(PLAYER_ATTRIBUTES[it]),
    })),
    ...cattr.map(it => ({
      value: it.name,
      name: it.name,
      description: it.description,
      type: TokenType.CustomAttribute,
      icon: (
        <Tooltip message={i18next.t('campaign.attr.custom')}>
          <CustomAttrIcon />
        </Tooltip>
      ),
    })),
  ].sort((a, b) => a.name.localeCompare(b.name))

  return (
    <SelectSubMenu
      style={{ width: '100%' }}
      placeholder={i18next.t('campaign.condition.select-attribute')}
      color={props.error ? 'error' : 'default'}
      value={textFieldValue as string}
      onChange={item => props.onChange(item.value as TokenValue, item.type as ExtAttrType)}
      items={
        [
          {
            name: 'Player',
            value: 'Player',
            children: playerAttr,
          },
          campaignItems.length > 0 && {
            name: 'Event',
            value: 'Event',
            children: campaignItems,
          },
        ].filter(it => !!it) as NestedMenuItemProps[]
      }
    />
  )
}
