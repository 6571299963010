import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

/**
 *
 * @param companyId
 * @param gameId
 * @param orderId
 */
export const useWebhookLogOrderQuery = (companyId: string, gameId: string, orderId: string) => {
  return useQuery({
    queryKey: ['webhook-log-order', gameId, orderId],
    queryFn: () => dashboardClient.v1.getWebhookLogByOrder(companyId, gameId, orderId).then(r => r.data),
  })
}
