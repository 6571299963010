import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { VerifyState } from '@/api/dashboard'
import { Button } from '@/ui'
import { GET_STARTED_VERIFY_ACCOUNT_PATH, useGenerateCompanyPath } from '@/libs'
import { GetStartedStepState } from '../types'
import { useGetStarted } from './useGetStarted'
import { useQuery } from '@tanstack/react-query'
import { accountDetailsQuery } from '@/layouts/company'
import { useEffect } from 'react'

const mapVerifyStatus = (rawStatus: VerifyState): GetStartedStepState['status'] => {
  switch (rawStatus) {
    case VerifyState.Verified:
      return 'completed'
    case VerifyState.UnderReview:
      return 'review'
    case VerifyState.Unverified:
      return 'idle'
  }
}

/**
 *
 */
export const useVerifyAccountStep = (): GetStartedStepState => {
  const { t } = useTranslation()
  const { complete } = useGetStarted()
  const { companyId, generatePath } = useGenerateCompanyPath()
  const { data: accountDetails } = useQuery(accountDetailsQuery(companyId))

  const id = 'verifyAccount'

  const status = accountDetails ? mapVerifyStatus(accountDetails.verify_state) : 'idle'

  useEffect(() => {
    if (accountDetails && accountDetails?.verify_state !== 'unverified') {
      complete('account_verified')
    }
  }, [accountDetails, complete])

  return {
    id,
    loading: false,
    status,
    title: t(`get-started.steps.${id}.title`),
    desc: t(`get-started.steps.${id}.desc`),
    cta: (
      <Link to={generatePath(GET_STARTED_VERIFY_ACCOUNT_PATH)}>
        <Button
          className="min-w-40"
          variant={status === 'review' || status === 'completed' ? 'outline' : 'secondary-brand'}
          size="lg"
        >
          {t(`get-started.steps.${id}.cta.label`)}
        </Button>
      </Link>
    ),
  }
}
