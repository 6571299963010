import { AnimatePresence, motion } from 'framer-motion'
import { useQuery } from '@tanstack/react-query'
import { ModalProps, ModalRush, useModalCloseWithFocus } from '@/ui'
import { LOCALIZATION_LOCALE_PATH, getMotionProps, useGenerateCompanyPath, useNavigateBack } from '@/libs'
import { localeQueryOptions, translationQueryOptions } from '../api'
import { TranslationForm, TranslationFormSkeleton } from '../components'

interface L10nTranslationModalRushProps extends ModalProps {}

export const formMotionProps = getMotionProps({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})

export const L10nTranslationModalRush = ({ ...rest }: L10nTranslationModalRushProps) => {
  const back = useNavigateBack({ fallback: LOCALIZATION_LOCALE_PATH })
  const { companyId, gameId, localeId, translationId } = useGenerateCompanyPath<{
    localeId: string
    translationId: string
  }>()

  const { data: locale } = useQuery(localeQueryOptions(companyId, gameId, localeId))

  const { data: translation } = useQuery({
    ...translationQueryOptions(companyId, gameId, translationId),
    enabled: !!translationId,
  })

  const onClose = useModalCloseWithFocus(() => {
    back.onClick()
  })

  return (
    <ModalRush {...rest} onClose={onClose}>
      <AnimatePresence mode="popLayout">
        {locale && translation ? (
          <motion.div {...formMotionProps} className="flex h-full flex-col" key="form">
            <TranslationForm locale={locale} translation={translation} onClose={onClose} />
          </motion.div>
        ) : (
          <motion.div {...formMotionProps} className="flex h-full flex-col" key="skeleton">
            <TranslationFormSkeleton />
          </motion.div>
        )}
      </AnimatePresence>
    </ModalRush>
  )
}
