import { ReactNode } from 'react'

interface ComponentPickerMenuGroupProps {
  children: ReactNode
  label: ReactNode
}

export const ComponentPickerMenuGroup = ({ children, label }: ComponentPickerMenuGroupProps) => {
  return (
    <div className="mb-2 first:mt-1 last:mb-0">
      <div className="mb-1.5 px-1.5 text-xs font-medium text-text-tertiary">{label}</div>
      {children}
    </div>
  )
}
