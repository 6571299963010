import { ReactNode, useId } from 'react'
import { StepperStep, StepperStepStatus } from './StepperStep'

interface StepperProps {
  steps: { label: ReactNode; children: ReactNode; status: StepperStepStatus }[]
}

export const Stepper = ({ steps }: StepperProps) => {
  const id = useId()
  return (
    <div className="w-full overflow-hidden">
      {steps.map((step, index) => (
        <StepperStep
          key={`stepper-step-${id}-${index}`}
          pos={index + 1}
          step={step}
          neighborStatus={steps.at(index + 1)?.status}
        />
      ))}
    </div>
  )
}
