import { Select } from '../../../../components/ui'
import i18next from 'i18next'
import { FilterType } from '../../../../api/dashboard'
import { CSSProperties } from 'react'
import { useParams } from 'react-router-dom'

export function DateFilter(props: {
  value: string
  showNoFilter?: boolean
  onChange: (value: string) => void
  style?: CSSProperties
}) {
  const { gameId } = useParams() as { gameId: string }

  const options =
    import.meta.env.VITE_DEMO_GAME_ID == gameId
      ? [
          { children: i18next.t('filter.7days'), value: '7' },
          { children: i18next.t('filter.30days'), value: '30' },
        ]
      : [
          { children: i18next.t('filter.12hours'), value: '12hours' },
          { children: i18next.t('filter.24hours'), value: '24hours' },
          { children: i18next.t('filter.today'), value: FilterType.Today },
          { children: i18next.t('filter.yesterday'), value: FilterType.Yesterday },
          { children: i18next.t('filter.7days'), value: '7' },
          { children: i18next.t('filter.14days'), value: '14' },
          { children: i18next.t('filter.30days'), value: '30' },
        ]

  if (props.showNoFilter) {
    options.push({ children: i18next.t('filter.all'), value: '-1' })
  }

  return (
    <Select options={options} style={props.style} value={props.value} onChange={e => props.onChange(e as string)} />
  )
}
