import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const Country: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 17 17" ref={ref}>
      <path
        d="M11.4197 10.1027L9.71955 9.3741C9.46047 9.26307 9.17677 9.1739 8.90247 9.10522C8.81893 9.0843 8.70012 9.06233 8.56 9.07737C8.37577 9.09714 8.20112 9.16948 8.05687 9.28577C7.94715 9.37422 7.87868 9.47376 7.8344 9.54762L7.80284 9.6002C7.59664 9.94352 7.35309 10.349 7.26276 10.7382C7.23985 10.837 7.23262 10.9387 7.24134 11.0397C7.25164 11.159 7.2894 11.2697 7.30768 11.3233L7.31263 11.3379C7.3648 11.4945 7.41046 11.6702 7.45777 11.8522C7.57901 12.3186 7.71106 12.8265 7.99108 13.1614C8.09482 13.2854 8.22803 13.3814 8.37852 13.4406C8.95566 13.6676 9.97852 13.6365 10.5402 13.466C10.6617 13.4291 10.7747 13.3686 10.8728 13.288C11.1898 13.0274 11.4247 12.6236 11.6469 12.2418C11.7389 12.0836 11.8288 11.9291 11.9214 11.7902C11.9719 11.7145 12.0303 11.627 12.0743 11.5467C12.1238 11.4562 12.19 11.3151 12.2011 11.135C12.2152 10.9072 12.1482 10.6818 12.012 10.4987C11.9043 10.354 11.7717 10.2719 11.6808 10.2232C11.6001 10.18 11.5034 10.1386 11.4197 10.1027Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5833 9.00008C15.5833 12.9121 12.412 16.0834 8.49999 16.0834C4.58797 16.0834 1.41666 12.9121 1.41666 9.00008C1.41666 5.08806 4.58797 1.91675 8.49999 1.91675C12.412 1.91675 15.5833 5.08806 15.5833 9.00008ZM14.1667 9.00008C14.1667 12.1297 11.6296 14.6667 8.49999 14.6667C5.37038 14.6667 2.83332 12.1297 2.83332 9.00008C2.83332 8.35535 2.94099 7.73578 3.13934 7.15835L5.15234 8.59618C5.21339 8.63983 5.28659 8.69216 5.35494 8.73308C5.43438 8.78064 5.55252 8.84152 5.70538 8.86556C5.90385 8.89677 6.10711 8.86531 6.28687 8.77557C6.4253 8.70646 6.51952 8.61272 6.58087 8.54337C6.63364 8.48371 6.68762 8.41169 6.73262 8.35163L7.30477 7.58876C7.56492 7.47316 7.86154 7.36685 8.16831 7.25691C8.87776 7.00266 9.64145 6.72897 10.1337 6.27585C10.6349 5.81448 10.6068 4.99345 10.5822 4.27312C10.5756 4.08016 10.5692 3.89438 10.5734 3.72472C12.6774 4.55232 14.1667 6.60228 14.1667 9.00008Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
