import { ReactNode } from 'react'

interface DrawerTopbarProps {
  children: ReactNode
}

export const DrawerTopbar = ({ children }: DrawerTopbarProps) => (
  <div className="sticky left-0 top-0 z-50 flex h-[70px] w-full shrink-0 items-center gap-5 border-b border-border-secondary bg-fg-primary px-5">
    {children}
  </div>
)
