import { useTranslation } from 'react-i18next'
import { TranslationRead } from '@/api/dashboard'
import { FloatingMenuProps, MenuItem, Menu_v2 } from '@/ui'

interface TranslationMenuProps extends FloatingMenuProps {
  translation: TranslationRead
  onOpenOverlay: () => void
}

export const TranslationMenu = ({ onOpenOverlay, onClose }: TranslationMenuProps) => {
  const { t } = useTranslation()

  const onClickOpenOverlay = () => {
    onClose?.()
    window.requestAnimationFrame(() => {
      onOpenOverlay()
    })
  }

  return (
    <Menu_v2>
      {/*<MenuItem>{t('localization.translation.menu.ignore')}</MenuItem>*/}
      {/*<MenuItem disabled={!translation.id} onClick={onClickToReview}>*/}
      {/*  {t('localization.translation.menu.to-review')}*/}
      {/*</MenuItem>*/}
      {/*<MenuItemDivider />*/}
      <MenuItem onClick={onClickOpenOverlay}>{t('localization.translation.menu.open-overlay')}</MenuItem>
    </Menu_v2>
  )
}
