import { ReactNode, forwardRef, useId } from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, CheckboxProps } from '../../../components/ui'
import { ArrowUpRight } from '../../../components/icons'

interface WebhookEventCheckboxProps extends Omit<CheckboxProps, 'children'> {
  event: {
    label: ReactNode
    desc: ReactNode
    moreLink?: string
  }
}

export const WebhookEventCheckbox = forwardRef<HTMLInputElement, WebhookEventCheckboxProps>(
  function WebhookEventCheckbox({ event, ...rest }, ref) {
    const id = useId()

    return (
      <div className="group bg-fg-primary">
        <div className="group flex gap-3 px-3 py-4">
          <Checkbox id={id} ref={ref} {...rest} />
          <div>
            <div className="mb-3">
              <label
                htmlFor={id}
                className="cursor-pointer text-paragraph-sm font-semibold text-text-tertiary transition-colors group-has-[input:checked]:text-text-primary"
              >
                {event.label}
              </label>
            </div>
            <div className="text-paragraph-sm text-text-secondary-hover transition-colors group-has-[input:checked]:text-text-primary">
              {event.desc}
            </div>
            {event.moreLink && (
              <Link
                className="mt-3 flex items-center gap-2 font-semibold text-text-secondary hover:text-text-brand-primary"
                to={event.moreLink}
                target="_blank"
              >
                <span>Learn more about webhook</span>
                <ArrowUpRight size={14} />
              </Link>
            )}
          </div>
        </div>
        <div className="ml-[44px] h-px w-full bg-border-secondary group-last:hidden" />
      </div>
    )
  },
)
