import { useCallback, useContext } from 'react'
import { useGameSettingsUpdateMutate } from '@/api/useGameSettingsUpdateMutate'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { OnboardingProgress } from '@/api/dashboard'
import { useGenerateCompanyPath } from '@/libs'
import { AuthContext, IAuthContext } from '@/Context'
import { isAdmin } from '@/security'

export const useGetStarted = () => {
  const { companyId, gameId } = useGenerateCompanyPath()
  const { data: settings } = useGameSettingsQuery(companyId, gameId)
  const { mutateAsync } = useGameSettingsUpdateMutate(companyId, gameId)
  const context = useContext(AuthContext) as IAuthContext
  const check = useCallback(
    (key: keyof OnboardingProgress) => {
      return settings?.onboarding_progress?.[key] || false
    },
    [settings?.onboarding_progress],
  )

  const complete = useCallback(
    async (key: keyof OnboardingProgress) => {
      if (!settings?.id || !isAdmin(context.customer)) {
        return
      }
      await mutateAsync({
        settingsId: settings.id,
        data: {
          onboarding_progress: {
            visit_game_hub: false,
            customize_your_store: false,
            setup_item_store: false,
            setup_webhook: false,
            test_webhook: false,
            account_verified: false,
            liveops_published: false,
            ...settings?.onboarding_progress,
            [key]: true,
          },
        },
      })
    },
    [settings, mutateAsync],
  )

  return {
    check,
    complete,
  }
}
