import {
  HTMLAttributes,
  MouseEvent,
  MutableRefObject,
  KeyboardEvent as ReactKeyboardEvent,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { useEnsuredForwardedRef } from 'react-use'
import { cva } from 'class-variance-authority'
import { cn } from '@/libs'

interface MenuProps extends HTMLAttributes<HTMLUListElement> {
  children: ReactNode
}

interface MenuItemProps extends HTMLAttributes<HTMLLIElement> {
  children: ReactNode
  variant?: 'primary' | 'danger'
  disabled?: boolean
}

const menuItemChildVariants = cva(
  'flex w-full rounded-md bg-fg-primary px-1.5 py-2 text-caption-md leading-none transition-colors',
  {
    variants: {
      variant: {
        primary: [
          'text-text-secondary',
          'group-hover/menu-item:bg-fg-primary-hover group-hover/menu-item:text-text-secondary-hover',
          'group-focus-visible/menu-item:bg-fg-primary-hover group-focus-visible/menu-item:text-text-secondary-hover',
          'group-focus-visible/menu-item:ring-2 group-focus-visible/menu-item:ring-border-brand',
          'group-active/menu-item:bg-fg-primary-alt group-active/menu-item:text-text-primary',
          'group-[&[data-disabled="true"]]/menu-item:text-text-disabled',
        ],
        danger: [
          'text-text-error-primary',
          'group-hover/menu-item:bg-fg-error-tertiary-alt group-hover/menu-item:text-text-error-primary',
          'group-focus-visible/menu-item:bg-fg-error-tertiary-alt group-focus-visible/menu-item:text-text-error-primary',
          'group-focus-visible/menu-item:ring-2 group-focus-visible/menu-item:ring-border-error',
          'group-[&[data-disabled="true"]]/menu-item:text-text-disabled',
        ],
      },
      defaultVariants: {
        variant: 'primary',
      },
    },
  },
)

export const MenuItem = ({ children, variant = 'primary', disabled = false, onClick, ...rest }: MenuItemProps) => {
  const onClickOrPressEnter = (e: MouseEvent<HTMLLIElement> | ReactKeyboardEvent<HTMLLIElement>) => {
    if (!(e as ReactKeyboardEvent<HTMLLIElement>).key || (e as ReactKeyboardEvent<HTMLLIElement>).key === 'Enter') {
      onClick?.(e as MouseEvent<HTMLLIElement>)
      return
    }
  }

  return (
    <li
      {...rest}
      className={cn(
        'group/menu-item flex cursor-pointer px-3 py-0.5 outline-none',
        disabled && 'pointer-events-none',
        rest.className,
      )}
      data-disabled={disabled}
      tabIndex={0}
      role={!disabled ? 'option' : ''}
      onClick={onClickOrPressEnter}
      onKeyDown={onClickOrPressEnter}
    >
      <div className={cn(menuItemChildVariants({ variant }))}>{children}</div>
    </li>
  )
}

export const MenuItemDivider = () => {
  return <div className="my-1.5 h-px bg-border-secondary last:mb-0" />
}

export const Menu_v2 = forwardRef<HTMLUListElement, MenuProps>(function Menu({ children, ...rest }, ref) {
  const ensuredRef = useEnsuredForwardedRef(ref as MutableRefObject<HTMLUListElement | null>)
  const lastActiveElement = useRef<Element | null>(document.activeElement)

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault()
        ;(ensuredRef.current?.firstElementChild as HTMLElement | null)?.focus()
      }
    },
    [ensuredRef],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      ;(lastActiveElement.current as HTMLElement | null)?.focus()
    }
  }, [lastActiveElement, handleKeyDown])

  return (
    <ul
      ref={ensuredRef}
      className={cn(
        'group/menu',
        'box-content min-w-44 rounded-xl border border-border-primary bg-fg-primary py-2.5 shadow-lg',
        rest.className,
      )}
      role="listbox"
      onClick={e => e.stopPropagation()}
    >
      {children}
    </ul>
  )
})
