import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <path
      d="M17.88,9.1c.48,1.07.99,2.46,1.3,3.56h0c.22.76.31,1.37.31,1.91,0,1.58-1.05,2.67-2.54,2.67-.87,0-1.53-.34-2.09-1.06l-.79-1h-4.16l-.79,1.01c-.56.72-1.23,1.06-2.09,1.06-1.5,0-2.54-1.1-2.54-2.67,0-.53.1-1.16.32-1.92.31-1.09.82-2.48,1.3-3.56h0c.39-.88.73-1.64,2.11-1.97,1.01-.25,2.32-.38,3.76-.38s2.75.13,3.77.38c1.38.33,1.72,1.1,2.11,1.97ZM16.04,15.27c.27.35.51.48.91.48h0c.66,0,1.04-.42,1.04-1.16,0-.4-.09-.89-.26-1.52-.28-1.01-.78-2.36-1.22-3.35-.37-.83-.45-.97-1.09-1.13-.63-.16-1.73-.34-3.42-.34s-2.78.18-3.41.33c-.65.16-.73.3-1.1,1.13-.45,1-.94,2.35-1.22,3.35-.18.62-.26,1.12-.26,1.52,0,.74.38,1.17,1.04,1.17.4,0,.63-.13.91-.48l.84-1.07c.34-.42.73-.51.99-.51h4.45c.26,0,.64.09.98.5l.85,1.07ZM12.87,11.73c0-.41.32-.75.71-.75s.7.34.7.75-.32.75-.7.75-.71-.34-.71-.75ZM10.93,10.58h-.79v-.87c0-.27-.15-.44-.39-.44s-.4.17-.4.44v.87h-.84c-.26,0-.41.17-.41.44s.15.44.41.44h.84v.87c0,.27.15.44.4.44s.39-.17.39-.44v-.87h.79c.28,0,.45-.17.45-.44s-.16-.44-.45-.44ZM14.28,10.21c0-.41.32-.75.71-.75s.7.34.7.75-.31.75-.7.75-.71-.34-.71-.75Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'fill',
    <path
      d="M17.89,9.1c.48,1.07.99,2.46,1.3,3.56h0c.22.76.31,1.37.31,1.91,0,1.58-1.05,2.67-2.54,2.67-.87,0-1.53-.34-2.09-1.06l-.79-1h-4.16l-.79,1.01c-.56.72-1.23,1.06-2.09,1.06-1.5,0-2.54-1.1-2.54-2.67,0-.53.1-1.16.32-1.92.31-1.09.82-2.48,1.3-3.56h0c.39-.88.73-1.64,2.11-1.97,1.01-.25,2.32-.38,3.76-.38s2.75.13,3.77.38c1.38.33,1.72,1.1,2.11,1.97h.02ZM12.88,11.73c0-.41.32-.75.71-.75s.7.34.7.75-.32.75-.7.75-.71-.34-.71-.75ZM10.94,10.58h-.79v-.87c0-.27-.15-.44-.39-.44s-.4.17-.4.44v.87h-.84c-.26,0-.41.17-.41.44s.15.44.41.44h.84v.87c0,.27.15.44.4.44s.39-.17.39-.44v-.87h.79c.28,0,.45-.17.45-.44s-.16-.44-.45-.44ZM14.29,10.21c0-.41.32-.75.71-.75s.7.34.7.75-.31.75-.7.75-.71-.34-.71-.75Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'duotone',
    <>
      <rect y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12c0,3.31,2.69,6,6,6h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <path
        d="M17.89,9.1c.48,1.07.99,2.46,1.3,3.56h0c.22.76.31,1.37.31,1.91,0,1.58-1.05,2.67-2.54,2.67-.87,0-1.53-.34-2.09-1.06l-.79-1h-4.16l-.79,1.01c-.56.72-1.23,1.06-2.09,1.06-1.5,0-2.54-1.1-2.54-2.67,0-.53.1-1.16.32-1.92.31-1.09.82-2.48,1.3-3.56h0c.39-.88.73-1.64,2.11-1.97,1.01-.25,2.32-.38,3.76-.38s2.75.13,3.77.38c1.38.33,1.72,1.1,2.11,1.97h.02ZM12.88,11.73c0-.41.32-.75.71-.75s.7.34.7.75-.32.75-.7.75-.71-.34-.71-.75ZM10.94,10.58h-.79v-.87c0-.27-.15-.44-.39-.44s-.4.17-.4.44v.87h-.84c-.26,0-.41.17-.41.44s.15.44.41.44h.84v.87c0,.27.15.44.4.44s.39-.17.39-.44v-.87h.79c.28,0,.45-.17.45-.44s-.16-.44-.45-.44ZM14.29,10.21c0-.41.32-.75.71-.75s.7.34.7.75-.31.75-.7.75-.71-.34-.71-.75Z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </>,
  ],
])

export const Game = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
