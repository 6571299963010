import styled from '@emotion/styled'
import { Campaign, CampaignEventType } from '@/api/dashboard'
import { isManualLaunchCampaign } from '../../Settings'
import i18next from 'i18next'
import { Badge, Button } from '@/ui'
import { getCampaignIconUrl } from '../../util'
import { ReactNode } from 'react'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const CampaignIconView = styled.div`
  width: 180px;
  height: 180px;
  border: 1px solid ${p => p.theme.palette.grey.borderPrimary};
  border-radius: 18px;
  transition: 0.3s filter ease-out;
  background-size: contain;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  & > div {
    position: absolute;
    bottom: 12px;
    left: 12px;
  }
`

const CampaignBox = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;

  &:hover > div:first-child {
    filter: blur(12px);
  }

  &:hover > div:first-child > div {
    display: none;
  }

  &:hover > div:last-child {
    opacity: 1;
  }
`

const Header = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${p => p.theme.palette.text.tertiary};
`

const CampaignHoverDesc = styled.div`
  transition: 0.3s opacity ease-out;
  opacity: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 180px;
  height: 180px;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.secondary};
  border: 1px solid ${p => p.theme.palette.grey.borderPrimary};
  border-radius: 18px;
`

export const CampaignItem = ({
  type,
  onNewClick,
  children,
  buttonText,
}: {
  children?: ReactNode
  type: CampaignEventType
  onNewClick?: () => void
  buttonText?: string
}) => {
  return (
    <CampaignBox>
      <CampaignIconView>
        <img
          className="size-full"
          src={getCampaignIconUrl(type)}
          style={{
            opacity: onNewClick ? 1 : 0.3,
          }}
        />
        {!onNewClick && <Badge variant="gray-secondary"> {i18next.t('campaign.created')}</Badge>}
      </CampaignIconView>
      <div className="mt-3 text-caption-md font-semibold text-text-secondary">{i18next.t(`campaign.type.${type}`)}</div>

      <CampaignHoverDesc>
        {i18next.t(`campaign.type.desc.${type}`)}

        <div className="mt-auto flex flex-col gap-2">
          {children}
          {onNewClick && (
            <Button onClick={onNewClick} variant="primary" data-testid={`new-campaign/choose/${type}`}>
              {buttonText || i18next.t('campaign.choose')}
            </Button>
          )}
        </div>
      </CampaignHoverDesc>
    </CampaignBox>
  )
}

export default function CampaignSelector(props: {
  createdCampaigns: Campaign[]
  onNewClick: (type: CampaignEventType) => void
}) {
  const isCreated = (type: CampaignEventType) => {
    return props.createdCampaigns.find(it => it.type === type) != null
  }

  const sortFunc = (a: CampaignEventType, b: CampaignEventType) => {
    if (isCreated(a) && !isCreated(b)) {
      return 1
    }
    if (!isCreated(a) && isCreated(b)) {
      return -1
    }
    return a.localeCompare(b)
  }

  let types = Object.values(CampaignEventType).filter(it => it != CampaignEventType.GameHubFirstLogin)

  let standardCampaigns = types
    .filter(it => !isManualLaunchCampaign(it) && it != CampaignEventType.CustomEvent)
    .sort(sortFunc)

  let manualCampaigns = types
    .filter(it => isManualLaunchCampaign(it) && it != CampaignEventType.CustomEvent)
    .sort(sortFunc)

  return (
    <Root>
      <Header>{i18next.t('campaign.type.auto-trigger-events')}</Header>
      <div className="flex flex-wrap gap-[42px]">
        {standardCampaigns.map(it => (
          <CampaignItem key={it} type={it} onNewClick={isCreated(it) ? undefined : () => props.onNewClick(it)} />
        ))}
      </div>

      <Header>{i18next.t('campaign.type.manual-trigger-events')}</Header>
      <div className="flex flex-wrap gap-[42px]">
        {manualCampaigns.map(it => (
          <CampaignItem key={it} type={it} onNewClick={isCreated(it) ? undefined : () => props.onNewClick(it)} />
        ))}

        <CampaignItem
          type={'custom_event' as CampaignEventType}
          onNewClick={() => props.onNewClick('Custom Event' as CampaignEventType)}
        />
      </div>
    </Root>
  )
}
