import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { useContext, useEffect, useState } from 'react'
import i18next from 'i18next'
import { Campaign, CampaignEventType, CampaignTemplateRead } from '@/api/dashboard'
import { dashboardClient, getErrorText } from '@/api'
import CampaignSelector, { CampaignItem } from '../components/CampaignSelector/CampaignSelector'
import { LIVEOPS_DETAILS_PATH } from '@/libs'
import {
  Button,
  ButtonIcon,
  Modal,
  ModalConfirm,
  ModalProps,
  ModalTitle,
  ToastSeverity,
  useModal,
  useToast,
} from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { campaignsQuery } from '@/layouts/campaigns/api'
import { campaignTemplatesQuery } from '@/layouts/campaigns/api/campaignTemplatesQuery'
import { ChevronLeft, CloseOutline } from '@/icons'
import CampaignSettings from '@/layouts/campaigns/widgets/CampaignSettings'
import AddNewCampaignButton from '@/layouts/campaigns/widgets/AddNewCampaignButton'
import { AuthContext, IAuthContext } from '@/Context'
import { isSuperAdmin } from '@/util'
import { clearEntityIdFields } from '@/layouts/campaigns/clearEntityIdFields'
import { ByAghanim } from '@/layouts/campaigns/pages/CampaignTable/ByAghanim'

export default function CreateCampaignModal(props: ModalProps) {
  const { ...rest } = props
  const navigate = useNavigate()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const showToast = useToast()
  const authContext = useContext(AuthContext) as IAuthContext
  const [step, setStep] = useState<number>(0)

  const { data: createdCampaigns = [] } = useQuery(campaignsQuery(companyId, gameId))
  const {
    data: templates = [],
    isLoading: isTemplateLoading,
    refetch,
  } = useQuery(campaignTemplatesQuery(companyId, gameId))

  const [newCampaign, setNewCampaign] = useState<Campaign>({
    type: CampaignEventType.CustomEvent,
    name: '',
    id: '',
    enabled: false,
    attributes: {
      custom_attributes: [],
    },
  })

  const saveClick = async () => {
    try {
      newCampaign.time_limit = !!(newCampaign.start_at || newCampaign.end_at)
      let { data: created } = await dashboardClient.v1.createCampaign(companyId, gameId, newCampaign)
      navigate(generatePath(LIVEOPS_DETAILS_PATH, { companyId, gameId, eventId: created.id }))
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  }

  const onTemplateSelected = async (template: CampaignTemplateRead) => {
    let { data: created } = await dashboardClient.v1.createCampaign(companyId, gameId, {
      ...newCampaign,
      type: template.type,
    })

    clearEntityIdFields(template.graph)

    navigate(generatePath(LIVEOPS_DETAILS_PATH, { companyId, gameId, eventId: created.id }), {
      state: { graph: template.graph },
    })
  }

  const backClick = () => {
    setStep(step - 1)
  }

  const hasStepZero = () => {
    return templates.length > 0
  }

  useEffect(() => {
    if (!isTemplateLoading && !hasStepZero() && step == 0) {
      setStep(1)
    }
  }, [isTemplateLoading])

  const isCreated = (type: CampaignEventType) => {
    return createdCampaigns.find(it => it.type === type) != null
  }

  const openConfirmDeleteTemplateModal = useModal<{ t: CampaignTemplateRead }>(props => (
    <ModalConfirm
      color="primary"
      subMessage={i18next.t('campaign.confirm.delete-template')}
      confirmButtonText={i18next.t('yes')}
      cancelButtonText={i18next.t('no')}
      onConfirm={() => onRemoveTemplate(props.t)}
      showClose={true}
      {...props}
    />
  ))

  const onRemoveTemplate = async (t: CampaignTemplateRead) => {
    await dashboardClient.v1.deleteCampaignTemplate(companyId, gameId, t.id)
    showToast({ message: i18next.t('removed'), severity: ToastSeverity.success })
    refetch()
  }

  const renderTemplateList = () => {
    return (
      <div className="flex flex-wrap gap-[42px]">
        <AddNewCampaignButton onClick={() => setStep(1)} />
        {templates.map(it => (
          <div className="flex flex-col gap-2">
            <CampaignItem
              key={it.id}
              buttonText={i18next.t('campaign.use-template')}
              type={it.type}
              onNewClick={isCreated(it.type) ? undefined : () => onTemplateSelected(it)}
            >
              {isSuperAdmin(authContext.customer) && (
                <Button onClick={() => openConfirmDeleteTemplateModal({ t: it })} variant="error">
                  {i18next.t('remove')}
                </Button>
              )}
            </CampaignItem>
            <ByAghanim />
          </div>
        ))}
      </div>
    )
  }

  const getStepName = () => {
    switch (step) {
      case 0:
        return i18next.t('campaign.step1')
      case 1:
        return i18next.t('campaign.step2')
      case 2:
        return i18next.t('campaign.step3') + ''
    }
    return ''
  }

  const onEventTypeSelected = (type: CampaignEventType) => {
    setNewCampaign({
      ...newCampaign,
      type,
    })
    switch (newCampaign.type) {
      case CampaignEventType.GameHubVisit:
      case CampaignEventType.GameHubVisitStore:
        if (!newCampaign.attributes?.visit_duration) {
          newCampaign.attributes = {
            visit_duration: 60 * 60 * 24,
          }
        }
        break
      case CampaignEventType.AbandonedCart:
        if (!newCampaign.attributes?.visit_duration) {
          newCampaign.attributes = {
            abandoned_cart_duration: 60 * 10,
          }
        }
        break
    }
    setStep(2)
  }

  return (
    <Modal {...rest} isLoading={isTemplateLoading} className="flex h-[90vh] max-w-[1144px] flex-col px-0">
      <div className="px-12">
        <ModalTitle>
          <div className="flex items-center">
            {(hasStepZero() ? step > 0 : step > 1) && (
              <ButtonIcon variant="secondary-gray" size="xs" onClick={backClick} className="mr-6">
                <ChevronLeft size={16} />
              </ButtonIcon>
            )}
            {getStepName()}
            <div className="ml-auto flex items-center gap-3">
              {step == 2 && (
                <Button onClick={saveClick} variant="primary" data-testid={'new-campaign/create-button'}>
                  {i18next.t('campaign.create')}
                </Button>
              )}
              <ButtonIcon variant="secondary-gray" size="sm" onClick={props.onClose}>
                <CloseOutline size={16} />
              </ButtonIcon>
            </div>
          </div>
        </ModalTitle>
      </div>
      <div className="w-full grow overflow-auto pl-12">
        {step == 0 && renderTemplateList()}
        {step == 1 && <CampaignSelector createdCampaigns={createdCampaigns} onNewClick={onEventTypeSelected} />}
        {step == 2 && (
          <CampaignSettings
            campaign={newCampaign}
            setCampaign={c => {
              setNewCampaign(prev => ({ ...prev, ...c }))
            }}
          />
        )}
      </div>
    </Modal>
  )
}
