import { SidebarGroup } from './SidebarGroup'
import { SidebarLink } from './SidebarLink'
import { SidebarSubLink } from './SidebarSubLink'
import { AppMenuItem } from './MenuData'

export const MenuList = (props: { items: AppMenuItem[] }) => {
  const preventDefault = (e: React.MouseEvent) => {
    e.preventDefault()
  }

  return props.items.map(item => {
    if (item.items) {
      return (
        <SidebarGroup
          key={item.to}
          drill={item.drill}
          trigger={
            <SidebarLink to={item.to || ''} icon={item.icon!} externalLink={item.externalLink}>
              {item.text}
            </SidebarLink>
          }
        >
          {item.items.map(child => {
            return (
              <SidebarSubLink key={child.to} to={child.to || ''}>
                {child.text}
              </SidebarSubLink>
            )
          })}
        </SidebarGroup>
      )
    }

    return (
      <SidebarLink
        to={item.to || ''}
        disabled={item.disabled}
        externalLink={item.externalLink}
        onClick={item.to ? undefined : preventDefault}
        key={item.to}
        hideArrow={item.hideArrow}
        icon={item.icon!}
        className={item.className}
        secondaryAction={item.secondaryAction}
      >
        {item.text}
      </SidebarLink>
    )
  })
}
