import { BlockInterface, GameItem, Page, WebsiteConfig } from '../types'

export enum EditorMode {
  Mobile,
  Tablet,
  Desktop,
  Responsive,
}

export type Side = 'up' | 'down' | 'right' | 'left'

export interface HubEditorEventMessage {
  config: WebsiteConfig
  page: Page
  item: GameItem
  block: BlockInterface
  command: 'start-add-block' | 'remove-block' | 'move-block' | 'edit-block' | 'add-block' | 'ai' | 'page-switched'
  id: string
  fromId: string
  addTo: Side
  moveTo: 'up' | 'down'
  aiCommand: string
}
