import { ReactNode } from 'react'
import { Edit03, Trash } from '@/icons'
import { ButtonIcon } from '@/ui'

interface VerifyDataCardProps {
  title: ReactNode
  children?: ReactNode
  onClickDelete?: () => void
  onClickEdit?: () => void
}

export const VerifyDataCard = ({ children, title, onClickDelete, onClickEdit }: VerifyDataCardProps) => (
  <div className="grid min-h-[98px] grid-cols-[1fr_auto] gap-6 break-words rounded-xl border-2 border-border-secondary bg-fg-secondary p-5 text-text-secondary">
    <div className="overflow-hidden">
      <p className="mb-1.5 text-paragraph-md font-medium">{title}</p>
      {children && (
        <>
          <div className="mb-1.5 h-px bg-border-secondary" />
          <div className="text-paragraph-sm">{children}</div>
        </>
      )}
    </div>
    {(onClickDelete || onClickEdit) && (
      <div className="flex gap-1.5">
        {onClickDelete && (
          <ButtonIcon variant="secondary-gray" size="sm" onClick={onClickDelete}>
            <Trash size={14} />
          </ButtonIcon>
        )}
        {onClickEdit && (
          <ButtonIcon variant="secondary-gray" size="sm" onClick={onClickEdit}>
            <Edit03 size={14} />
          </ButtonIcon>
        )}
      </div>
    )}
  </div>
)
