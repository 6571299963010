import { useTranslation } from 'react-i18next'
import { Button, FieldGroup, Modal, ModalFooter, ModalProps, ModalTitle } from '@/ui'
import { localeTranslateAllMutation } from '../api'
import { LocaleRead } from '@/api/dashboard'

interface L10nLocaleModalConfirmTranslateAllProps extends ModalProps {
  locale: LocaleRead
}

export const L10nLocaleModalConfirmTranslateAll = ({ locale, ...rest }: L10nLocaleModalConfirmTranslateAllProps) => {
  const { t } = useTranslation()
  const { mutateAsync } = localeTranslateAllMutation()

  const onClickConfirm = async () => {
    await mutateAsync({ data: locale })
    rest.onClose?.()
  }

  return (
    <Modal {...rest}>
      <ModalTitle>{t('localization.ai.all.modal-confirm.title')}</ModalTitle>

      <FieldGroup>
        <p className="text-paragraph-md text-text-secondary">
          {t('localization.ai.all.modal-confirm.text', { locale: t(`locales.${locale?.locale}`) })}
        </p>
      </FieldGroup>

      <ModalFooter>
        <Button type="button" size="lg" onClick={rest.onClose}>
          {t('localization.ai.all.modal-confirm.cancel')}
        </Button>
        <Button variant="primary" size="lg" type="button" onClick={onClickConfirm}>
          {t('localization.ai.all.modal-confirm.confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
