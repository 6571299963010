import { queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

interface Query {
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 100
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number
}

export const campaignsQuery = (companyId: string, gameId: string, query: Query = {}) =>
  queryOptions({
    queryKey: [gameId, 'campaigns', { query }],
    queryFn: () => dashboardClient.v1.getCampaigns(companyId, gameId, query).then(r => r.data),
  })
