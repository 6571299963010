import { CSSProperties, useEffect, useMemo, useState } from 'react'

export const IFrame = (props: { html: string; className?: string; style?: CSSProperties; zoom?: number }) => {
  const [height, setHeight] = useState('700px')

  const html = useMemo(() => {
    return (
      props.html +
      `
      <script type='text/javascript'>
        window.onload = function() {
          let z = ${props.zoom || 1}
          window.top.postMessage(z * document.body.offsetHeight, '*');
        }
      </script>
    `
    )
  }, [props.html])

  useEffect(() => {
    function handleMessage(ev: MessageEvent) {
      if (typeof ev.data === 'number') {
        setHeight(`${ev.data}px`)
      }
    }
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  return (
    <iframe
      src={'data:text/html,' + encodeURIComponent(html)}
      frameBorder={0}
      scrolling="no"
      className={props.className}
      style={{
        height: height,
        ...props.style,
      }}
    />
  )
}
