import { Chip } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export default function ErrorChip(props: { text: string; margin?: boolean }) {
  const theme = useTheme()
  return (
    <Chip
      sx={{
        backgroundColor: theme.palette.error.light2,
        color: theme.palette.error.dark2,
        px: '4px',
        textTransform: 'capitalize',
        marginTop: props.margin ? theme.spacing(1.5) : 0,
      }}
      size="small"
      label={props.text}
    />
  )
}
