import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { useGenerateCompanyPath } from '@/libs'
import { LocaleCreate } from '@/api/dashboard'
import { localesQueryOptions } from './localesQueryOptions'

interface LocaleCreateMutate {
  data: LocaleCreate
}

export const localeCreateMutation = () => {
  const ql = useQueryClient()
  const { companyId, gameId } = useGenerateCompanyPath()

  return useMutation({
    mutationFn: async ({ data }: LocaleCreateMutate) => {
      const r = await dashboardClient.v1.createLocale(companyId, gameId, data)
      return r.data
    },
    onSuccess: data => {
      ql.setQueryData(localesQueryOptions(companyId, gameId).queryKey, (old = []) => {
        return [...old, data]
      })
    },
  })
}
