/**
 * prod-snapshot-20240711 -> v2024.07.11
 * prod-snapshot-20240711_1 -> v2024.07.11_1
 * prod-snapshot-20240711_2 -> v2024.07.11_2
 * prod-snapshot-1.0.0 -> v1.0.0
 * prod-snapshot-1.2.3 -> v1.2.3
 * prod-snapshot-1.2 -> v1.2
 * @param branch
 */
export const getReleaseFromBranch = (branch: string): string => {
  let match = branch.match(/prod-snapshot-(\d{4})(\d{2})(\d{2})(?:_(\w+))?/)
  if (match) {
    const [_, year, month, day, patch] = match
    const postfix = patch ? `_${patch}` : ''
    return `v${year}.${month}.${day}${postfix}`
  }

  match = branch.match(/prod-snapshot-(\d+)\.(\d+)\.(\d+)/)
  if (match) {
    const [_, major, minor, patch] = match
    return `v${major}.${minor}.${patch}`
  }

  match = branch.match(/prod-snapshot-(\d+)\.(\d+)/)
  if (match) {
    const [_, major, minor] = match
    return `v${major}.${minor}.0`
  }

  return branch
}
