import { AttrType, Token, TokenType } from '../../../api/dashboard'
import { CAMPAIGN_ATTRIBUTES, PLAYER_ATTRIBUTES } from './types'
import { ICampaignContext } from '../Context'

export function getAttrType(token: Token, context: ICampaignContext): AttrType {
  switch (token.type) {
    case TokenType.Attribute: {
      let attr = PLAYER_ATTRIBUTES[token.value as string]
      if (attr) {
        return attr.type
      }
      return AttrType.String
    }
    case TokenType.CustomAttribute:
      return context.playerCustomAttributes?.find(it => it.name == token.value)?.type || AttrType.String
    case TokenType.EventAttribute: {
      let attr = Object.values(CAMPAIGN_ATTRIBUTES)
        .flat()
        .find(it => it.id == (token.value as string))
      if (attr) {
        return attr.type
      }
      return AttrType.String
    }
    case TokenType.CustomEventAttribute: {
      let attr = context.campaign.attributes?.custom_attributes?.find(it => it.name == (token.value as string))
      if (attr) {
        return attr.type
      }
      return AttrType.String
    }
    default:
      return AttrType.String
  }
}
