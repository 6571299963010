import { useContext, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import i18next from 'i18next'
import TableBar from '../../components/TableBar'
import { useTheme } from '@mui/material/styles'
import { dashboardClient } from '../../api'
import { SegmentRead } from '../../api/dashboard'
import EditSegmentDialog from './widgets/EditSegmentDialog'
import { AuthContext, IAuthContext } from '../../Context'
import { canEdit } from '../../security'
import {
  Button,
  ConfirmDialog,
  Page,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  ToolbarFilter,
  useModal,
  usePagination,
} from '../../components/ui'
import { DrawerSidebar } from '../../components/ui/DrawerSidebar'
import SegmentGroupTable from './SegmentGroupTable'
import { useSegmentsQuery } from './api/useSegmentsQuery'
import { useSegmentGroupsQuery } from './api/useSegmentGroupsQuery'
import { GAME_SEGMENTS_PATH, GAME_SEGMENT_GROUPS_PATH } from '../../libs/routerPaths'
import { EditIcon } from '../../icons/Icons'
import { Plus, Trash } from '../../components/icons'
import SegmentBadge from '@/layouts/segment/components/SegmentBadge'

export default function SegmentTable(props: { segmentGroupVisible?: boolean }) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const context = useContext(AuthContext) as IAuthContext
  const isReadOnly = !canEdit(context.customer)
  const theme = useTheme()
  const segmentGroupVisible = !!props.segmentGroupVisible
  const navigate = useNavigate()
  const { data: groups = [], isLoading: isGroupLoading } = useSegmentGroupsQuery(companyId, gameId)
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null)
  const [deleteConfirmItem, setDeleteConfirmItem] = useState<SegmentRead | undefined>(undefined)

  const { page, query, onPageChange, needShowPagination } = usePagination(20)
  const {
    data: items = [],
    isLoading,
    refetch,
  } = useSegmentsQuery(companyId, gameId, {
    ...query,
    segment_group_id: selectedGroupId ? selectedGroupId : undefined,
  })

  const onDeleteItemConfirm = async () => {
    if (deleteConfirmItem) {
      await dashboardClient.v1.deleteSegment(companyId, gameId, deleteConfirmItem.id)
      refetch()
    }
    setDeleteConfirmItem(undefined)
  }

  const getGroupName = (id: string | undefined) => {
    if (!id) {
      return ''
    }
    const group = groups.find(g => g.id === id)
    return group?.name || ''
  }

  const openEditModal = useModal<{ item: SegmentRead }>(props => <EditSegmentDialog {...props} />)

  return (
    <Page>
      <TableBar
        title={i18next.t('sidebar.segments')}
        sticky
        customAction={
          <div className="flex gap-2">
            {!isReadOnly && (
              <Button onClick={() => navigate(generatePath(GAME_SEGMENT_GROUPS_PATH, { companyId, gameId }))}>
                {i18next.t('segments.edit-groups')}
              </Button>
            )}
            {!isReadOnly && !!items.length && (
              <Button
                variant="primary"
                onClick={() =>
                  openEditModal({
                    item: {
                      name: '',
                      color: theme.palette.primary.main,
                    } as SegmentRead,
                  })
                }
              >
                {i18next.t('segment.add')}
              </Button>
            )}
          </div>
        }
      />

      {(!!items.length || selectedGroupId) && (
        <div className="flex justify-end py-1">
          <ToolbarFilter
            label={i18next.t('segments.group')}
            value={selectedGroupId}
            onChange={v => setSelectedGroupId(v as string)}
            items={[
              ...groups.map(it => ({
                value: it.id,
                label: it.name,
              })),
            ]}
          />
        </div>
      )}

      {deleteConfirmItem && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={i18next.t('remove')}
          subMessage={i18next.t('confirm.text', { name: deleteConfirmItem.name })}
          onCancel={() => setDeleteConfirmItem(undefined)}
          onConfirm={onDeleteItemConfirm}
        />
      )}

      <DrawerSidebar open={segmentGroupVisible} width="864px" position="right">
        <SegmentGroupTable onClose={() => navigate(generatePath(GAME_SEGMENTS_PATH, { companyId, gameId }))} />
      </DrawerSidebar>

      {isGroupLoading || isLoading ? (
        <TableRowSkeleton rowCount={query.limit} columnCount={3} />
      ) : items.length === 0 ? (
        <TableZeroState
          title={i18next.t('segment.zero.title')}
          message={i18next.t('segment.zero.message')}
          buttons={
            !isReadOnly && (
              <Button
                variant="primary"
                onClick={() =>
                  openEditModal({
                    item: {
                      name: '',
                      color: theme.palette.primary.main,
                    } as SegmentRead,
                  })
                }
              >
                <Plus size={14} />
                <span>{i18next.t('segment.zero.button-add')}</span>
              </Button>
            )
          }
        />
      ) : (
        <>
          <Table>
            <TableRow>
              <TableCell>{i18next.t('segment.name')}</TableCell>
              <TableCell width="200%">{i18next.t('segment.description')}</TableCell>
              <TableCell>{i18next.t('segment.group')}</TableCell>
              <TableCell />
            </TableRow>

            {items.map(it => (
              <TableRow key={it.id}>
                <TableCell data-testid={`segment-table/row/${it.id}`}>
                  <SegmentBadge segment={it} />
                </TableCell>
                <TableCell width="200%">{it.description}</TableCell>
                <TableCell>{getGroupName(it.segment_group_id)}</TableCell>
                <TableCell>
                  {!isReadOnly && (
                    <div className="flex justify-end" data-testid={'segment-table/edit-row-button'}>
                      <TableRowEditButton
                        onChange={v => {
                          switch (v) {
                            case 'edit':
                              openEditModal({ item: it })
                              break
                            case 'remove':
                              setDeleteConfirmItem(it)
                              break
                          }
                        }}
                        options={[
                          {
                            icon: <EditIcon />,
                            children: i18next.t('grid.edit'),
                            value: 'edit',
                          },
                          {
                            icon: (
                              <span className="text-text-error-primary">
                                <Trash />
                              </span>
                            ),
                            children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                            value: 'remove',
                          },
                        ]}
                      />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>

          {needShowPagination(isLoading, items) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </Page>
  )
}
