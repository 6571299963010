import { HTMLAttributes, MouseEvent, ReactNode, forwardRef } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { XClose } from '../../icons'

interface TagProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  size?: 'md' | 'lg'
  onClickDelete?: (e: MouseEvent<HTMLDivElement>) => void
}

const StyledTag = styled.div<{ $size: TagProps['size'] }>`
  background-color: transparent;
  border: 1px solid #e2e8f0;
  color: #344054;
  border-radius: 6px;
  padding: 3px 4px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;
  max-width: 232px;
  outline: none;
  transition: border-color ease-in-out 160ms;

  ${p =>
    p.$size === 'md' &&
    css`
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    `}

  ${p =>
    p.$size === 'lg' &&
    css`
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
    `}
  
  &:focus {
    border-color: ${p => p.theme.palette.primary.main};
  }
`

const Label = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  min-width: 0;

  & svg {
    flex-shrink: 0;
  }

  & span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const XButton = styled.div<{ $size: TagProps['size'] }>`
  background-color: transparent;
  color: #64748b;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  outline: none;
  transition: color ease-in-out 160ms;

  ${p =>
    p.$size === 'md' &&
    css`
      width: 16px;
      height: 16px;

      & svg {
        width: 12px;
        height: 12px;
      }
    `}

  ${p =>
    p.$size === 'lg' &&
    css`
      width: 20px;
      height: 20px;

      & svg {
        width: 16px;
        height: 16px;
      }
    `}
  
  &:hover {
    color: ${p => p.theme.palette.primary.main};
  }
`

export const Tag = forwardRef<HTMLDivElement, TagProps>(function Tag(
  { children, size = 'md', onClickDelete, ...rest }: TagProps,
  ref,
) {
  return (
    <StyledTag ref={ref} $size={size} {...rest}>
      <Label>{children}</Label>
      {onClickDelete && (
        <XButton $size={size} onClick={onClickDelete}>
          <XClose strokeWidth={3} />
        </XButton>
      )}
    </StyledTag>
  )
})
