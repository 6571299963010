import { ReactNode } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

export interface SidebarSubLinkProps extends NavLinkProps {
  children: ReactNode
}

export const SidebarSubLink = ({ children, ...rest }: SidebarSubLinkProps) => {
  return (
    <NavLink {...rest} className="group/sub-link flex items-center py-0.5">
      <div className="relative z-10 ml-3.5 mr-5 h-4 w-0.5 shrink-0 rounded-lg transition-colors group-[.active]/sub-link:bg-border-brand" />
      <div className="flex h-8 w-full items-center gap-1 rounded-lg px-2 font-medium transition-colors group-hover/sub-link:bg-white group-[.active]/sub-link:bg-white">
        {children}
      </div>
    </NavLink>
  )
}
