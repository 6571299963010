import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material'

const PlaceBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  borderRadius: '9999px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}))

export default function AgSwitch(props: {
  checked: boolean
  size?: 'small' | 'middle'
  onChange: (value: boolean) => void
  disabled?: boolean
}) {
  const theme = useTheme()

  const styles = {
    root: {
      width: theme.spacing(props.size == 'middle' ? 6.75 : 4.5),
      height: theme.spacing(props.size == 'middle' ? 3.5 : 2.5),
      '&:hover > div': {
        boxShadow: '0px 0px 0px 3px rgba(0, 151, 228, 0.24)',
      },
      cursor: 'pointer',
      opacity: props.disabled ? 0.7 : 1,
    },
    trackBox: {
      position: 'relative',
      width: theme.spacing(props.size == 'middle' ? 3 : 2),
      height: theme.spacing(props.size == 'middle' ? 3 : 1.975),
      borderRadius: '50%',
      backgroundColor: theme.palette.background.content,
      transition: 'left 0.2s ease-in-out',
      left: props.checked ? theme.spacing(props.size == 'middle' ? 3 : 2.2) : theme.spacing(0.375),
    },
  }

  return (
    <Box onClick={() => (props.disabled ? void 0 : props.onChange(!props.checked))} sx={styles.root}>
      <PlaceBox
        sx={{
          backgroundColor: props.checked ? theme.palette.primary.dark5 : theme.palette.grey[200],
          '&:hover': !props.checked
            ? {
                backgroundColor: theme.palette.grey[300],
              }
            : null,
        }}
      >
        <Box sx={styles.trackBox} />
      </PlaceBox>
    </Box>
  )
}
