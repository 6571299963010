import { DataGranularity, FilterType } from '../../api/dashboard'
import i18next from 'i18next'
import { IAnalyticsDataContext } from './Context'

export const getUrlParamsObject = (filter: string | null) => {
  if (!filter) {
    return {}
  }
  let granularity = DataGranularity.Day
  let type = FilterType.Period
  let last_count = 7

  switch (filter) {
    case '12hours':
      granularity = DataGranularity.Hour
      last_count = 12
      break
    case '24hours':
      granularity = DataGranularity.Hour
      last_count = 24
      break
    case FilterType.Yesterday:
      granularity = DataGranularity.Hour
      type = filter as FilterType
      last_count = 24
      break
    case FilterType.Today:
      granularity = DataGranularity.Hour
      type = filter as FilterType
      last_count = new Date().getUTCHours()
      break
    default:
      last_count = parseInt(filter)
      break
  }
  return {
    granularity,
    type,
    last_count,
  }
}

export const getUrlParams = (filter: string) => {
  if (!filter) {
    return ''
  }
  const obj = getUrlParamsObject(filter)
  return `last_count=${obj.last_count}&granularity=${obj.granularity}&type=${obj.type}`
}

export const getDataGranularity = (filter: string) => {
  switch (filter) {
    case '12hours':
    case '24hours':
    case FilterType.Yesterday:
    case FilterType.Today:
      return DataGranularity.Hour
    default:
      return DataGranularity.Day
  }
}

export const getSerName = (context: IAnalyticsDataContext) => {
  let serName = i18next.t('dashboard.revenue.last-n-days', { value: context.selectedFilter })
  switch (context.type) {
    case FilterType.Today:
      serName = i18next.t('filter.today')
      break
    case FilterType.Yesterday:
      serName = i18next.t('filter.yesterday')
      break
    default:
      if (context.granularity == DataGranularity.Hour) {
        serName = i18next.t('dashboard.revenue.last-n-hours', { value: parseInt(context.selectedFilter) })
      }
      break
  }

  return serName
}
