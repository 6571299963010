import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <path
      d="M8.66634 3.66667L7.92265 2.17928C7.70861 1.7512 7.60158 1.53715 7.44192 1.38078C7.30073 1.24249 7.13056 1.13732 6.94372 1.07287C6.73245 1 6.49315 1 6.01454 1H3.46634C2.7196 1 2.34624 1 2.06102 1.14532C1.81014 1.27316 1.60616 1.47713 1.47833 1.72801C1.33301 2.01323 1.33301 2.3866 1.33301 3.13333V3.66667M1.33301 3.66667H11.4663C12.5864 3.66667 13.1465 3.66667 13.5743 3.88465C13.9506 4.0764 14.2566 4.38236 14.4484 4.75869C14.6663 5.18651 14.6663 5.74656 14.6663 6.86667V9.8C14.6663 10.9201 14.6663 11.4802 14.4484 11.908C14.2566 12.2843 13.9506 12.5903 13.5743 12.782C13.1465 13 12.5864 13 11.4663 13H4.53301C3.4129 13 2.85285 13 2.42503 12.782C2.0487 12.5903 1.74274 12.2843 1.55099 11.908C1.33301 11.4802 1.33301 10.9201 1.33301 9.8V3.66667Z"
      stroke="#475569"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
  [
    'fill',
    <path
      d="M8.66634 3.66667L7.92265 2.17928C7.70861 1.7512 7.60158 1.53715 7.44192 1.38078C7.30073 1.24249 7.13056 1.13732 6.94372 1.07287C6.73245 1 6.49315 1 6.01454 1H3.46634C2.7196 1 2.34624 1 2.06102 1.14532C1.81014 1.27316 1.60616 1.47713 1.47833 1.72801C1.33301 2.01323 1.33301 2.3866 1.33301 3.13333V3.66667M1.33301 3.66667H11.4663C12.5864 3.66667 13.1465 3.66667 13.5743 3.88465C13.9506 4.0764 14.2566 4.38236 14.4484 4.75869C14.6663 5.18651 14.6663 5.74656 14.6663 6.86667V9.8C14.6663 10.9201 14.6663 11.4802 14.4484 11.908C14.2566 12.2843 13.9506 12.5903 13.5743 12.782C13.1465 13 12.5864 13 11.4663 13H4.53301C3.4129 13 2.85285 13 2.42503 12.782C2.0487 12.5903 1.74274 12.2843 1.55099 11.908C1.33301 11.4802 1.33301 10.9201 1.33301 9.8V3.66667Z"
      stroke="#475569"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
  [
    'duotone',
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666993 4.10782C0.666981 3.7564 0.666972 3.45321 0.687374 3.2035C0.708908 2.93994 0.756452 2.67779 0.884981 2.42554C1.07673 2.04921 1.38269 1.74325 1.75901 1.5515C2.01127 1.42298 2.27341 1.37543 2.53698 1.3539C2.78669 1.33349 3.08986 1.33351 3.4413 1.33352L6.0981 1.33344C6.4938 1.33292 6.84179 1.33246 7.16176 1.44283C7.44201 1.53949 7.69726 1.69725 7.90905 1.90468C8.15086 2.14151 8.30607 2.45297 8.48257 2.80713L9.07895 4.00004H11.4945C12.0312 4.00003 12.4741 4.00003 12.8349 4.0295C13.2096 4.06012 13.5541 4.12583 13.8776 4.29069C14.3794 4.54635 14.7873 4.9543 15.043 5.45607C15.2079 5.77962 15.2736 6.12409 15.3042 6.49882C15.3337 6.85962 15.3337 7.30252 15.3337 7.83916V10.8276C15.3337 11.3642 15.3337 11.8071 15.3042 12.1679C15.2736 12.5427 15.2079 12.8871 15.043 13.2107C14.7873 13.7124 14.3794 14.1204 13.8776 14.3761C13.5541 14.5409 13.2096 14.6066 12.8349 14.6372C12.4741 14.6667 12.0312 14.6667 11.4945 14.6667H4.50614C3.9695 14.6667 3.52657 14.6667 3.16578 14.6372C2.79104 14.6066 2.44658 14.5409 2.12302 14.3761C1.62125 14.1204 1.2133 13.7124 0.957643 13.2107C0.792782 12.8871 0.727073 12.5427 0.696456 12.1679C0.666978 11.8071 0.666985 11.3642 0.666993 10.8276V4.10782ZM6.01519 2.66685C6.54213 2.66685 6.64658 2.67555 6.727 2.70329C6.82041 2.73551 6.9055 2.78809 6.97609 2.85724C7.03687 2.91676 7.09136 3.00629 7.32701 3.4776L7.58823 4.00004L2.00038 4.00004C2.00067 3.69005 2.00271 3.47815 2.01628 3.31207C2.03108 3.13097 2.05619 3.06382 2.07299 3.03086C2.13691 2.90542 2.23889 2.80343 2.36433 2.73951C2.3973 2.72272 2.46444 2.6976 2.64555 2.6828C2.83444 2.66737 3.08263 2.66685 3.46699 2.66685H6.01519Z"
      fill="black"
    />,
  ],
])

export const Collection = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
