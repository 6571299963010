import { MouseEvent } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Badge, Button, FullscreenTopbar, FullscreenTopbarBack, FullscreenTopbarTitle, useModal } from '@/ui'
import { AlertTriangleOutline } from '@/icons'
import { SKU_ITEMS_PATH, useNavigateBack } from '@/libs'
import { useItemUpdateMutate } from '../../api'
import { UnsavedChangesModal } from '../../components'
import { SkuFormData } from './SkuForm'

export const SkuFormTopbar = () => {
  const { t } = useTranslation()
  const back = useNavigateBack({ fallback: SKU_ITEMS_PATH })
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const {
    formState: { isSubmitting, isDirty },
    setValue,
    watch,
  } = useFormContext<SkuFormData>()

  const { mutateAsync, isPending } = useItemUpdateMutate(companyId, gameId)

  const openUnsavedChangesModal = useModal(props => <UnsavedChangesModal {...props} />)
  const item = watch()

  const onClickBack = (e?: MouseEvent) => {
    e?.preventDefault()
    if (isDirty) {
      openUnsavedChangesModal()
    } else {
      back.onClick()
    }
  }

  const onClickRestore = async () => {
    // @ts-ignore
    await mutateAsync({ data: { ...item, archived_at: null } })
    setValue('archived_at', undefined)
  }

  return (
    <FullscreenTopbar>
      <FullscreenTopbarBack {...back} onClick={onClickBack} />
      <FullscreenTopbarTitle>
        <span className="shrink-0 text-text-tertiary">
          {item.id
            ? item.is_currency
              ? t('sku.item.is_currency')
              : item.is_stackable
                ? t('sku.item.is_stackable')
                : t('sku.item.is_non_stackable')
            : t('AddItem')}
        </span>
        {item.name && <span className="truncate">{item.name}</span>}
      </FullscreenTopbarTitle>
      {isDirty && (
        <Badge className="ml-3 text-text-warning-primary" variant="orange-tertiary">
          <AlertTriangleOutline size={16} />
          <span>{t('game-item-page.unsaved-changes')}</span>
        </Badge>
      )}
      <div className="ml-auto flex gap-2">
        {item.archived_at && (
          <Button type="button" onClick={onClickRestore} isLoading={isPending}>
            {t('sku.item.unarchive')}
          </Button>
        )}
        <Button type="submit" variant="primary" isLoading={isSubmitting} disabled={!isDirty}>
          {item.id ? t('Save2') : t('AddItem')}
        </Button>
      </div>
    </FullscreenTopbar>
  )
}
