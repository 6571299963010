import { FocusEvent, KeyboardEvent, useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { LocaleRead, State, TranslationRead } from '@/api/dashboard'
import { cn } from '@/libs'
import { translationCreateOrUpdateMutation } from '../api'
import { stripHtml } from '../libs'

interface TranslationFormInlineProps {
  locale: LocaleRead
  translation: TranslationRead
  onClose: (restoreFocus: boolean) => void
}

export const TranslationFormInline = ({ locale, translation, onClose }: TranslationFormInlineProps) => {
  const { mutateAsync, isPending } = translationCreateOrUpdateMutation()

  const { handleSubmit, register } = useForm({
    defaultValues: translation,
  })

  const strippedContent = useMemo(
    () => ({
      original: stripHtml(translation.original_content || ''),
      translated: stripHtml(translation.content || ''),
    }),
    [translation.original_id, translation.content],
  )

  const onSubmit = handleSubmit(async data => {
    const isCreate = !translation.id
    const strippedContent = stripHtml(data.content || '')

    if ((isCreate && strippedContent.length) || !isCreate) {
      mutateAsync({
        localeId: locale.id,
        data: {
          ...data,
          content: strippedContent,
          locale: locale.locale,
          state: strippedContent ? State.Done : State.New,
        },
      })
    }

    setTimeout(() => {
      onClose(true)
    }, 10)
  })

  const onKeyDownInlineInput = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation()
      if (e.key === 'Escape') {
        onClose(true)
      }
    },
    [onClose],
  )

  const onFocusInlineInput = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (translation.content) {
        e.target.select()
      }
    },
    [translation.content],
  )

  const onBlurInlineInput = useCallback(() => {
    onClose(false)
  }, [onClose])

  return (
    <form className={cn(isPending && 'animate-pulse')} onSubmit={onSubmit}>
      <input
        {...register('content')}
        className="-m-2.5 w-full bg-transparent p-2.5 text-text-secondary outline-none placeholder:text-text-quarterary"
        type="text"
        defaultValue={strippedContent.translated}
        placeholder={strippedContent.original}
        autoFocus={true}
        onKeyDown={onKeyDownInlineInput}
        onFocus={onFocusInlineInput}
        onBlur={onBlurInlineInput}
      />
    </form>
  )
}
