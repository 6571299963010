import i18next from 'i18next'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { AttrType, CustomAttribute } from '../../../api/dashboard'
import { CopyIcon, TrashIcon } from '../../../icons/Icons'
import TextIconButton from '../../../components/buttons/TextIconButton'
import { capitalizeFirstLetter, prepareSLUG } from '../../../util'
import { Button, FieldGroup, Input, Select } from '@/ui'
import { PlusContained } from '@/icons'

export default function CustomAttributeTable(props: {
  attributes: CustomAttribute[]
  setAttributes: (value: CustomAttribute[]) => void
}) {
  const theme = useTheme()

  const setItem = (index: number, attr: CustomAttribute) => {
    props.setAttributes([...props.attributes.map((it, idx) => (index == idx ? attr : it))])
  }

  const attributes = props.attributes

  const onDeleteGameItemClick = (attr: CustomAttribute) => {
    let newAttributes = attributes.filter(it => it !== attr)
    props.setAttributes(newAttributes)
  }

  const onAddClick = () => {
    props.setAttributes([
      ...attributes,
      {
        name: '',
        type: AttrType.String,
        description: '',
      },
    ])
  }

  const renderAttribute = (idx: number, attr: CustomAttribute) => {
    return (
      <Box sx={{ border: `1px solid ${theme.palette.grey.borderPrimary}` }} key={idx} p={theme.spacing(2)}>
        <Box display="flex" gap={theme.spacing(1.5)} alignItems="flex-start">
          <Box display="flex" gap={theme.spacing(1)} width="64%">
            <div>
              <FieldGroup label={i18next.t('campaign.attr.name')}>
                <Input
                  value={attr.name}
                  style={{ width: '76%' }}
                  onChange={e =>
                    setItem(idx, {
                      ...attr,
                      name: prepareSLUG(e.target.value),
                    })
                  }
                />
              </FieldGroup>
            </div>

            <Button
              onClick={() => navigator.clipboard.writeText(attr.name)}
              style={{
                height: theme.spacing(6),
                width: '24%',
                alignSelf: 'flex-end',
                gap: theme.spacing(1),
              }}
            >
              <CopyIcon />
              {i18next.t('copy')}
            </Button>
          </Box>

          <Box display="flex" gap={theme.spacing(1)} width="36%">
            <div style={{ flexGrow: 1 }}>
              <FieldGroup label={i18next.t('player.attr-table.type')}>
                <Select
                  value={attr.type as string}
                  onChange={v =>
                    setItem(idx, {
                      ...attr,
                      type: v as AttrType,
                    })
                  }
                  options={Object.values(AttrType).map(it => ({
                    value: it,
                    children: capitalizeFirstLetter(it),
                  }))}
                />
              </FieldGroup>
            </div>

            <Button
              onClick={() => onDeleteGameItemClick(attr)}
              style={{ alignSelf: 'flex-end', height: '48px', marginLeft: 'auto' }}
            >
              <TrashIcon />
            </Button>
          </Box>
        </Box>

        <Typography color={theme.palette.text.tertiary} margin={theme.spacing(1, 0)}>
          {i18next.t('campaign.attr.name.desc')}
        </Typography>

        <FieldGroup label={i18next.t('campaign.attr.name.field-desc')}>
          <Input
            value={attr.description}
            onChange={e => {
              setItem(idx, {
                ...attr,
                description: e.target.value,
              })
            }}
          />
        </FieldGroup>
      </Box>
    )
  }

  return (
    <Box display="flex" gap={theme.spacing(1.5)} flexDirection="column">
      {attributes.map((it, i) => renderAttribute(i, it))}

      <TextIconButton onClick={onAddClick} text={i18next.t('campaign.add-attribute')}>
        <PlusContained />
      </TextIconButton>
    </Box>
  )
}
