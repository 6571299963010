import { ReactNode } from 'react'
import { cn } from '../../../libs/cn'

export const Header = (props: {
  title: string | ReactNode
  children: ReactNode
  titleAction?: ReactNode
  className?: string
}) => {
  return (
    <div
      style={{ minWidth: '800px', minHeight: '56px' }}
      className={cn(
        'flex w-full items-center  justify-center border-b border-b-border-secondary bg-fg-primary px-6',
        props.className,
      )}
    >
      {props.titleAction}
      <span className="font-semibold text-text-primary" style={{ fontSize: '20px' }}>
        {props.title}
      </span>
      <div className="ml-auto">{props.children}</div>
    </div>
  )
}
