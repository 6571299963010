import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { LeaderboardUpdate } from '../../../api/dashboard'

interface LeaderboardUpdateMutate {
  id: string
  update: LeaderboardUpdate
}

export const useLeaderboardUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, update }: LeaderboardUpdateMutate) =>
      dashboardClient.v1.updateLeaderboard(companyId, gameId, id, update),
    onSuccess: data => {
      queryClient.setQueryData(['leaderboards', gameId], data.data)
      queryClient.invalidateQueries({ queryKey: ['leaderboards', gameId] })
    },
  })
}
