import { Fragment, KeyboardEvent, MouseEvent, useCallback, useMemo, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { SSETranslationCallback, fetchSSETranslation } from '@/api/ai'
import { LocaleRead, State, TranslationRead } from '@/api/dashboard'
import { ButtonIcon, Floating, Skeleton } from '@/ui'
import { AiTranslateOutline, DotsHorizontal } from '@/icons'
import { LOCALIZATION_TRANSLATION_PATH, cn, getMotionProps, useGenerateCompanyPath } from '@/libs'
import { AiFallingStar } from '@/layouts/get-started/components'
import { isHtmlTranslation, stripHtml } from '../libs'
import { TranslationFormAi } from './TranslationFormAi'
import { TranslationFormInline } from './TranslationFormInline'
import { TranslationMenu } from './TranslationMenu'
import { TranslationStatusBadge } from './TranslationStatusBadge'

interface TranslationRowItemProps {
  locale: LocaleRead
  translation: TranslationRead
}

const startMotionProps = getMotionProps({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})

const defaultAiTranslateState: { parts: string[]; isDone: boolean; isError: boolean } = {
  parts: [],
  isDone: false,
  isError: false,
}

export const TranslationRowItem = ({ locale, translation }: TranslationRowItemProps) => {
  const ref = useRef<HTMLLIElement>(null)
  const navigate = useNavigate()
  const { generatePath } = useGenerateCompanyPath()

  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isInlineEdit, setIsInlineEdit] = useState(false)
  const [aiTranslate, setAiTranslate] = useState<{ parts: string[]; isDone: boolean; isError: boolean } | null>(null)

  const strippedContent = useMemo(
    () => ({
      original: stripHtml(translation.original_content || ''),
      translated: stripHtml(translation.content || ''),
    }),
    [translation.original_id, translation.content],
  )

  const handleSSEEvent: SSETranslationCallback = useCallback(
    e => {
      switch (e.type) {
        case 'done':
          setAiTranslate(prev => ({ ...(prev || defaultAiTranslateState), isDone: true }))
          break
        case 'error':
          setAiTranslate(prev => ({ ...(prev || defaultAiTranslateState), isError: true }))
          break
        case 'part':
          setAiTranslate(prev => ({
            ...(prev || defaultAiTranslateState),
            parts: [...(prev || defaultAiTranslateState).parts, e.payload],
          }))
          break
      }
    },
    [setAiTranslate],
  )

  const onOpenOverlay = useCallback(() => {
    navigate(
      generatePath(
        LOCALIZATION_TRANSLATION_PATH,
        {
          localeId: locale.id,
          translationId: translation.id || translation.original_id,
        },
        true,
      ),
      { preventScrollReset: true },
    )
  }, [navigate, generatePath, translation])

  const onInteractItemRow = (e: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLLIElement>) => {
    if (!ref.current?.contains(e.target as HTMLElement)) {
      return
    }

    if (translation.is_truncated || isHtmlTranslation(translation) || e.altKey) {
      onOpenOverlay()
    } else {
      e.preventDefault()
      setIsInlineEdit(true)
    }
  }

  const onCloseInlineForm = (restoreFocus: boolean) => {
    if (restoreFocus) {
      ref.current?.focus()
    }
    setIsInlineEdit(false)
  }

  const onClickAiTranslate = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    if (!translation.original_content) {
      return
    }

    setAiTranslate(defaultAiTranslateState)

    fetchSSETranslation({
      locale: translation.locale,
      content: translation.original_content,
      callback: handleSSEEvent,
    })
  }

  return (
    <li
      data-testid={`l10n/outlet/translation-row-${translation.cursor_id}`}
      ref={ref}
      className={cn(
        'group/item-row relative grid h-11 w-full shrink-0 cursor-pointer grid-cols-[1fr_1fr_100px] content-center items-center gap-6 px-4 text-left text-text-secondary outline-none outline-2 transition-[background-color]',
        'border-b border-border-secondary last:border-none',
        'hover:bg-fg-primary-hover',
        'focus-visible:z-10 focus-visible:rounded-md focus-visible:border-transparent focus-visible:outline-border-brand',
        translation.state === State.New && 'text-text-quarterary hover:text-text-quarterary-hover',
        !!aiTranslate && 'pointer-events-none',
      )}
      tabIndex={0}
      role="option"
      onClick={onInteractItemRow}
      onKeyDown={e => {
        e.key === 'Enter' ? onInteractItemRow(e) : ''
      }}
    >
      <div className="truncate text-paragraph-sm text-text-secondary-hover">{strippedContent.original}</div>
      <div className={cn('text-paragraph-sm', !isInlineEdit && 'truncate')}>
        {isInlineEdit ? (
          <TranslationFormInline translation={translation} locale={locale} onClose={onCloseInlineForm} />
        ) : aiTranslate ? (
          <TranslationFormAi
            locale={locale}
            translation={translation}
            state={aiTranslate}
            parts={aiTranslate.parts}
            onDone={() => setAiTranslate(null)}
          />
        ) : (
          <span>{strippedContent.translated || strippedContent.original}</span>
        )}
      </div>
      <div className="relative flex justify-end gap-1.5">
        <div
          className={cn(
            'absolute flex gap-1.5 transition-all',
            'invisible -top-1.5 right-0 opacity-0',
            'group-hover/item-row:visible group-hover/item-row:opacity-100',
            (isOpenMenu || !!aiTranslate) && 'visible opacity-100',
          )}
        >
          <div className="relative">
            <AnimatePresence mode="popLayout">
              {!!aiTranslate && (
                <motion.div {...startMotionProps} className="pointer-events-none absolute">
                  <AiFallingStar speed={1} />
                </motion.div>
              )}
            </AnimatePresence>
            <ButtonIcon variant="secondary-brand" size="sm" isLoading={!!aiTranslate} onClick={onClickAiTranslate}>
              <AiTranslateOutline />
            </ButtonIcon>
          </div>
          <Floating
            menu={<TranslationMenu translation={translation} onOpenOverlay={onOpenOverlay} />}
            placement="bottom-end"
            stopPropagation={true}
            onOpenCallback={setIsOpenMenu}
          >
            <ButtonIcon variant="secondary-gray" size="sm">
              <DotsHorizontal />
            </ButtonIcon>
          </Floating>
        </div>
        <div
          className={cn(
            'transition-all',
            'group-hover/item-row:pointer-events-none group-hover/item-row:-translate-x-1 group-hover/item-row:opacity-0',
            (isOpenMenu || !!aiTranslate) && 'pointer-events-none -translate-x-1 opacity-0',
          )}
        >
          <TranslationStatusBadge state={translation.state} />
        </div>
      </div>
    </li>
  )
}

export const TransactionRowItemSkeleton = () => {
  const renderMultipleRows = () => {
    return (
      <>
        <div className="grid h-11 w-full grid-cols-[1fr_1fr_100px] content-center items-center gap-6 border-b border-border-secondary bg-fg-primary-hover px-4 last:border-none">
          <Skeleton className="h-4 w-1/2" />
        </div>
        <div className="grid h-11 w-full grid-cols-[1fr_1fr_100px] content-center items-center gap-6 border-b border-border-secondary px-4 last:border-none">
          <Skeleton className="h-4 w-1/2" />
          <Skeleton className="h-4 w-1/2" />
          <Skeleton className="ml-auto h-4 w-1/2" />
        </div>
        <div className="grid h-11 w-full grid-cols-[1fr_1fr_100px] content-center items-center gap-6 border-b border-border-secondary px-4 last:border-none">
          <Skeleton className="h-4 w-1/2" />
          <Skeleton className="h-4 w-1/2" />
          <Skeleton className="ml-auto h-4 w-1/2" />
        </div>
      </>
    )
  }

  return (
    <div className="relative min-h-screen animate-pulse">
      <div className="relative">
        <div className="grid h-11 w-full grid-cols-[1fr_1fr_100px] content-center items-center gap-6 border-b border-border-secondary px-4 last:border-none">
          <div className="flex items-center gap-3">
            <Skeleton className="size-7" />
            <Skeleton className="h-4 w-1/3" />
          </div>
        </div>
        {new Array(3).fill('').map((_, index) => (
          <Fragment key={`skeleton-${index}`}>{renderMultipleRows()}</Fragment>
        ))}
        <div className="absolute bottom-0 left-0 h-32 w-full bg-gradient-to-b from-transparent to-fg-primary" />
      </div>
    </div>
  )
}
