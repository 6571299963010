import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <path
      d="M9.3,5.25c.41,0,.75.34.75.75v.58h3.3c.41,0,.75.34.75.75s-.34.75-.75.75h-1.07c-.34,1.57-.96,3.03-1.81,4.31.16.1.33.18.49.25.38.17.55.61.38.99s-.61.55-.99.38c-.26-.12-.53-.26-.79-.42-1.09,1.27-2.43,2.31-3.98,3.07-.37.18-.82.03-1-.34-.18-.37-.03-.82.34-1,1.34-.66,2.5-1.55,3.44-2.64-.75-.68-1.4-1.52-1.78-2.4-.16-.38.01-.82.39-.98.38-.16.82.01.98.39.26.6.73,1.24,1.31,1.78.66-1.02,1.16-2.17,1.47-3.4h-5.49c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h3.3v-.58c0-.41.34-.75.75-.75ZM15.04,12.05l-1.22,2.53h2.45l-1.22-2.53ZM16.99,16.08l1.09,2.24c.18.37.63.53,1,.35.37-.18.53-.63.35-1l-3.24-6.68c-.07-.14-.14-.29-.21-.41-.07-.11-.21-.33-.47-.46-.3-.14-.64-.14-.94,0-.26.13-.4.34-.47.46-.07.12-.14.26-.21.41,0,0,0,.02-.01.02l-3.23,6.66c-.18.37-.02.82.35,1s.82.02,1-.35l1.09-2.24h3.9Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'fill',
    <path
      d="M9.3,5.25c.41,0,.75.34.75.75v.58h3.3c.41,0,.75.34.75.75s-.34.75-.75.75h-1.07c-.34,1.57-.96,3.03-1.81,4.31.16.1.33.18.49.25.38.17.55.61.38.99s-.61.55-.99.38c-.26-.12-.53-.26-.79-.42-1.09,1.27-2.43,2.31-3.98,3.07-.37.18-.82.03-1-.34-.18-.37-.03-.82.34-1,1.34-.66,2.5-1.55,3.44-2.64-.75-.68-1.4-1.52-1.78-2.4-.16-.38.01-.82.39-.98.38-.16.82.01.98.39.26.6.73,1.24,1.31,1.78.66-1.02,1.16-2.17,1.47-3.4h-5.49c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h3.3v-.58c0-.41.34-.75.75-.75ZM15.04,12.05l-1.22,2.53h2.45l-1.22-2.53ZM16.99,16.08l1.09,2.24c.18.37.63.53,1,.35.37-.18.53-.63.35-1l-3.24-6.68c-.07-.14-.14-.29-.21-.41-.07-.11-.21-.33-.47-.46-.3-.14-.64-.14-.94,0-.26.13-.4.34-.47.46-.07.12-.14.26-.21.41,0,0,0,.02-.01.02l-3.23,6.66c-.18.37-.02.82.35,1s.82.02,1-.35l1.09-2.24h3.9Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'duotone',
    <>
      <rect x="0" y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12C0,21.31,2.69,24,6,24h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <path
        d="M9.3,5.25c.41,0,.75.34.75.75v.58h3.3c.41,0,.75.34.75.75s-.34.75-.75.75h-1.07c-.34,1.57-.96,3.03-1.81,4.31.16.1.33.18.49.25.38.17.55.61.38.99s-.61.55-.99.38c-.26-.12-.53-.26-.79-.42-1.09,1.27-2.43,2.31-3.98,3.07-.37.18-.82.03-1-.34-.18-.37-.03-.82.34-1,1.34-.66,2.5-1.55,3.44-2.64-.75-.68-1.4-1.52-1.78-2.4-.16-.38.01-.82.39-.98.38-.16.82.01.98.39.26.6.73,1.24,1.31,1.78.66-1.02,1.16-2.17,1.47-3.4h-5.49c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h3.3v-.58c0-.41.34-.75.75-.75ZM15.04,12.05l-1.22,2.53h2.45l-1.22-2.53ZM16.99,16.08l1.09,2.24c.18.37.63.53,1,.35.37-.18.53-.63.35-1l-3.24-6.68c-.07-.14-.14-.29-.21-.41-.07-.11-.21-.33-.47-.46-.3-.14-.64-.14-.94,0-.26.13-.4.34-.47.46-.07.12-.14.26-.21.41,0,0,0,.02-.01.02l-3.23,6.66c-.18.37-.02.82.35,1s.82.02,1-.35l1.09-2.24h3.9Z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </>,
  ],
])

export const Localization = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
