import { ReactNode } from 'react'
import styled from '@emotion/styled'

interface TableProps {
  children: ReactNode
  className?: string
  role?: string
}

const StyledTable = styled.div`
  position: relative;
`

export const Table = ({ children, role, className }: TableProps) => {
  return (
    <StyledTable role={role} className={className}>
      {children}
    </StyledTable>
  )
}
