import { CSSProperties } from 'react'

export default function TextIconButton(props: {
  onClick: () => void
  text: string
  children: React.ReactNode
  style?: CSSProperties
}) {
  return (
    <div
      style={props.style}
      className="inline-flex cursor-pointer items-center gap-2 text-[14px] font-semibold text-fg-brand-primary"
      onClick={props.onClick}
    >
      {props.children}
      {props.text}
    </div>
  )
}
