import { ReactNode } from 'react'
import styled from '@emotion/styled'

interface InputExtraMarkProps {
  children: ReactNode
}

const StyledInputExtraMark = styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  height: 100%;
  flex-shrink: 0;
  user-select: none;
`

export const InputExtraMark = ({ children }: InputExtraMarkProps) => {
  return <StyledInputExtraMark>{children}</StyledInputExtraMark>
}
