import { useState } from 'react'
import { useParams } from 'react-router-dom'
import TransactionsTableView from './components/TransactionsTableView'
import { usePaymentsQuery } from './api/usePaymentsQuery'
import { Pagination } from '../../components/ui'

const UserTransactionsTable = (props: { userId: string; endTs?: number; exclude_payment_number?: string }) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const LIMIT = 10

  const [page, setPage] = useState(1)

  const query = { offset: (page - 1) * LIMIT, limit: LIMIT, user_id: props.userId }

  const { data: payments = [], isLoading } = usePaymentsQuery(companyId, gameId, {
    ...query,
  })

  const needShowPagination = () => {
    if (isLoading) {
      return false
    }
    if (payments.length === 0 && page == 1) {
      return false
    }

    if (payments.length < LIMIT && page == 1) {
      return false
    }

    return true
  }

  return (
    <>
      <TransactionsTableView payments={payments} isLoading={isLoading} showUserCol={false} />
      {needShowPagination() && (
        <Pagination
          style={{
            position: 'relative',
          }}
          hasNext={payments.length >= query.limit}
          page={page}
          onPageChange={p => setPage(p)}
          pageItems={payments.length}
        />
      )}
    </>
  )
}

export default UserTransactionsTable
