import { QueryClient, useQuery } from '@tanstack/react-query'
import { dashboardClient } from './index'

export const useWebsitesQuery = (companyId: string, gameId: string) => {
  return useQuery({
    queryKey: ['websites', companyId, gameId],
    queryFn: () => dashboardClient.v1.getWebsites(companyId, gameId).then(r => r.data),
  })
}

export const invalidateWebsitesQuery = (queryClient: QueryClient, companyId: string, gameId: string) => {
  queryClient.invalidateQueries({ queryKey: ['websites', companyId, gameId] })
}
