import { Currency, ItemRead } from '../../../api/dashboard'
import { ShoppingBag03, Trash, UploadCloud2 } from '../../icons'
import { HTMLAttributes, forwardRef } from 'react'
import { formatMoney, formatNumber } from '../../../util'
import { SkuIcon } from './SkuIcon'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { ButtonIcon } from '../ButtonIcon'
import { RewardPointsPrice } from '@/layouts/game-items/components/RewardPointsPrice'

interface PreviewProductCardProps extends HTMLAttributes<HTMLDivElement> {
  item: ItemRead
  onDropImage?: DropzoneOptions['onDrop']
  onDeleteImage?: () => void
}

export const PreviewProductCard = forwardRef<HTMLDivElement, PreviewProductCardProps>(function PreviewProductCard(
  { item, onDropImage, onDeleteImage, ...rest },
  ref,
) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': [],
      'image/webp': [],
    },
    onDrop: onDropImage,
    maxFiles: 1,
  })

  return (
    <div
      ref={ref}
      {...rest}
      className="flex h-[511px] w-[330px] select-none flex-col rounded-[12px] border border-[#3F3F46] bg-black p-18 shadow-sku-card-preview"
    >
      <div className="group relative m-[-10px] mb-8 flex h-full">
        <div className="pointer-events-none absolute left-0 top-0 z-10 size-full max-h-[312px] rounded-[12px] shadow-inner-img-white" />

        {item.image_url ? (
          <div className="relative flex size-full max-h-[312px] overflow-hidden rounded-[12px]">
            <img className="flex size-full object-cover" src={item.image_url} alt="" />
            {onDeleteImage && (
              <div className="absolute right-3 top-3">
                <ButtonIcon variant="overlay" type="button" onClick={onDeleteImage}>
                  <Trash />
                </ButtonIcon>
              </div>
            )}
          </div>
        ) : (
          <div className="flex size-full max-h-[312px] items-center justify-center rounded-[12px] bg-[#27272A] text-[#3F3F46]">
            <SkuIcon size={74} />
            {onDropImage && (
              <div
                {...getRootProps()}
                className="absolute left-0 top-0 flex size-full cursor-pointer items-center justify-center rounded-[12px] bg-[rgba(0,0,0,0.5)] text-text-inverse opacity-0 transition-opacity group-hover:opacity-100"
              >
                <input {...getInputProps()} />
                <UploadCloud2 size={74} />
              </div>
            )}
          </div>
        )}
      </div>

      <div className="shrink-0">
        <div className="mb-6">
          <div className="mb-2 flex items-center justify-center gap-1.5 truncate text-center text-t5 text-white">
            {item.quantity && (item.quantity > 1 || item.is_stackable) && <span>{formatNumber(item.quantity)}</span>}
            <span>{item.name}</span>
          </div>
          <div className="line-clamp-2 min-h-10 text-center text-sm text-[#D4D4D8]">{item.description}</div>
        </div>

        {item.currency === Currency.RP ? (
          <button className="pointer-events-none flex w-full items-center justify-center gap-3 rounded-[6px] bg-[#9333EA] px-4 py-3 text-center text-t6 text-white">
            <RewardPointsPrice price={item.reward_points_price || 0} />
          </button>
        ) : (
          <button className="pointer-events-none flex w-full items-center justify-center gap-3 overflow-hidden rounded-[6px] bg-[#3F3F46] px-4 py-3 text-center text-t6 text-white">
            <ShoppingBag03 className="shrink-0" size={20} />
            <span className="truncate">{formatMoney(item.price)}</span>
          </button>
        )}
      </div>
    </div>
  )
})
