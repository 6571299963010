import { useCallback, useEffect, useRef } from 'react'

interface StickyAttrParams {
  offsetY: number
}

/**
 * The hook will set the `is-sticky=true` attribute if the element is sticky and in a sticking state.
 * @param offsetY
 */
export const useStickyAttr = ({ offsetY }: StickyAttrParams) => {
  const ref = useRef<HTMLDivElement>(null)

  const handleScroll = useCallback(
    (e: Event) => {
      ref.current?.setAttribute('is-sticky', (e.currentTarget as Window).scrollY > offsetY ? 'true' : 'false')
    },
    [ref.current, offsetY],
  )

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return { ref }
}
