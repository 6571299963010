import { HTMLAttributes, createElement, forwardRef } from 'react'
import { cn } from '../../../../libs/cn'
import { ComponentPickerOption } from '../../plugins/ComponentPickerPlugin'

interface ComponentPickerMenuOptionProps extends HTMLAttributes<HTMLLIElement> {
  option: ComponentPickerOption
  isSelected?: boolean
}

export const ComponentPickerMenuOption = forwardRef<HTMLLIElement, ComponentPickerMenuOptionProps>(
  function ComponentPickerMenuOption({ option, isSelected, ...rest }, ref) {
    return (
      <li
        {...rest}
        ref={ref}
        className={cn(
          'group mb-0.5 flex cursor-pointer gap-3 rounded-md p-1.5 transition-colors last:mb-0 hover:bg-fg-primary-alt',
          isSelected && 'bg-fg-primary-alt',
          rest.className,
        )}
        role="option"
        aria-selected={isSelected}
        tabIndex={-1}
      >
        <div
          className={cn(
            'flex size-9 shrink-0 items-center justify-center rounded-md bg-fg-primary-alt text-text-tertiary transition-colors group-hover:bg-fg-primary group-hover:text-text-brand-primary group-[[aria-selected="true"]]:bg-fg-primary group-[[aria-selected="true"]]:text-text-brand-primary',
            isSelected && 'bg-fg-primary',
          )}
        >
          {createElement(option.icon, { size: 24 })}
        </div>
        <div>
          <div className="mb-0.5 text-sm font-medium">{option.label}</div>
          <div className="text-[10px] leading-none text-text-secondary">{option.desc}</div>
        </div>
      </li>
    )
  },
)
