import { ReactNode } from 'react'
import { ChevronRightOutline } from '@/icons'

interface TranslationRowGroupProps {
  children: ReactNode
  group: ReactNode
  preview: ReactNode
}

export const TranslationRowGroup = ({ children, group, preview }: TranslationRowGroupProps) => (
  <>
    <div className="flex h-11 items-center gap-2 overflow-hidden border-b border-border-secondary bg-fg-primary-hover px-4">
      <div className="text-paragraph-sm font-semibold text-text-primary">{group}</div>
      <ChevronRightOutline size={16} />
      <div className="overflow-hidden text-paragraph-sm text-text-secondary">
        <span className="block w-96 overflow-hidden truncate whitespace-nowrap">{preview}</span>
      </div>
    </div>
    {children}
  </>
)
