export const CODE_EXAMPLES = {
  python: `from fastapi import FastAPI
from pydantic import BaseModel
import enum


class WebhookEventType(enum.StrEnum):
    PLAYER_VERIFY = "player.verify"
    ITEM_ADD = "item.add"
    ITEM_REMOVE = "item.remove"
    ORDER_PAID = "order.paid"
    ORDER_CANCELED = "order.canceled"
    ORDER_REFUNDED = "order.refunded"
    COUPON_REDEEMED = "coupon.redeemed"
    IN_GAME_PUSH = "ingame.push"
    IN_GAME_POPUP = "ingame.popup"
    PLAYER_IS_IDLE = "player.is_idle"

class WebhookRequest(BaseModel):
    event_id: str
    event_type: WebhookEventType
    event_time: int
    event_data: dict
    idempotency_key: str | None


app = FastAPI()


@app.post("/api/v1/webhook")
async def create_item(body: WebhookRequest):
    print(body)
`,
}
