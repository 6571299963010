import { AriaRole, Fragment } from 'react'
import { uuid4 } from '../../../util'
import { cn } from '../../../libs/cn'

export interface Property {
  label: string | React.ReactNode
  value: string | undefined | React.ReactNode
  items?: Property[]
}

export const PropTable = (props: { rows: Property[] }) => {
  const renderRow = (row: Property, isChild = false, isLast = false) => {
    return (
      <Fragment key={uuid4()}>
        <tr className="h-[42px] pl-3 align-middle" style={{ fontSize: '14px' }}>
          <td
            className={cn(isChild ? 'pl-8 font-medium text-text-tertiary' : 'px-3 font-semibold text-text-secondary')}
          >
            {row.label}
          </td>
          <td className="whitespace-normal pr-3 text-right align-middle">
            <div className="flex items-center justify-end font-normal text-text-secondary" role={row.label as AriaRole}>
              {row.value}
            </div>
          </td>
        </tr>
        {!isLast && (
          <tr>
            <td colSpan={2}>
              <div className={cn(isChild ? 'ml-8' : 'ml-3', 'border-b border-border-secondary')} />
            </td>
          </tr>
        )}
      </Fragment>
    )
  }

  const noEmptyRows = props.rows.filter(it => !!it)

  return (
    <div className="w-full">
      <table className="w-full text-nowrap">
        <tbody>
          {noEmptyRows.map((row, idx) => {
            const isLastParentRow = idx == noEmptyRows.length - 1
            const content = renderRow(row, false, isLastParentRow)
            const child = row.items ? (
              <>{row.items.map((it, idx) => renderRow(it, true, row.items!.length - 1 == idx && isLastParentRow))}</>
            ) : null
            return (
              <>
                {content}
                {child}
              </>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
