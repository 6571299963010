import { useParams } from 'react-router-dom'
import { useQueries } from '@tanstack/react-query'
import { skuItemsCountQuery } from '../api'
import { ResourceState } from '../../../api/dashboard'

export const useSkuCounts = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const [{ data: all }, { data: active }, { data: archived }] = useQueries({
    queries: [
      skuItemsCountQuery(companyId, gameId, {}),
      skuItemsCountQuery(companyId, gameId, { state: ResourceState.Active }),
      skuItemsCountQuery(companyId, gameId, { state: ResourceState.Archived }),
    ],
  })
  return { counts: { all, active, archived } }
}
