import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { PageCreate } from '../../../api/dashboard'

interface PageCreateMutate {
  create: PageCreate
}

export const usePageCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ create }: PageCreateMutate) => dashboardClient.v1.createPage(companyId, gameId, create),
    onSuccess: data => {
      queryClient.setQueryData(['pages', gameId], data.data)
      queryClient.invalidateQueries({ queryKey: ['pages', gameId] })
    },
  })
}
