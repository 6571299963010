import Box from '@mui/material/Box'
import SaveButton from './SaveButton'
import { Theme, useTheme } from '@mui/material/styles'
import { SxProps } from '@mui/system/styleFunctionSx'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, Typography } from '@mui/material'
import { CloseIcon } from '../icons/Icons'

export const DIALOG_PADDING = 4.5

export function DialogFooter(props: {
  sx?: SxProps<Theme>
  text?: string
  onSaveClick: () => boolean | undefined | void | Promise<unknown>
  saveDisabled?: boolean
}) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        padding: theme.spacing(DIALOG_PADDING),
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        ...props.sx,
      }}
    >
      <SaveButton disabled={props.saveDisabled} onClick={props.onSaveClick} size="medium">
        {props.text}
      </SaveButton>
    </Box>
  )
}

export function DialogHeader(props: {
  sx?: SxProps<Theme>
  text: string
  onCloseClick?: () => boolean | undefined | void | Promise<unknown>
}) {
  const theme = useTheme()

  return (
    <DialogTitle
      sx={{
        padding: theme.spacing(4.8125, DIALOG_PADDING),
        minHeight: theme.spacing(13.25),
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        lineHeight: '',
        ...props.sx,
      }}
    >
      <Typography variant="h2">{props.text}</Typography>

      {props.onCloseClick && (
        <Button
          onClick={props.onCloseClick}
          size="small"
          sx={{
            padding: theme.spacing(0.625),
            marginLeft: 'auto',
            minWidth: '',
          }}
        >
          <CloseIcon />
        </Button>
      )}
    </DialogTitle>
  )
}
