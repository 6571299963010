import { useCallback, useState } from 'react'

/**
 * Select multiple items base on array of objects
 * @param initial
 */
export const useBulkSelectGeneric = <T>(initial: T[] = []) => {
  const [selected, setSelected] = useState<T[]>(initial)

  const onSelect = useCallback(
    (item: T) => {
      if (!selected.includes(item)) {
        setSelected(value => [...value, item])
      } else {
        setSelected(value => value.filter(v => v !== item))
      }
    },
    [selected, setSelected],
  )

  const onReset = useCallback(
    (items: T[]) => {
      setSelected(items)
    },
    [setSelected],
  )

  return { selected, onSelect, onReset }
}
