import { HTMLAttributes, ReactElement, ReactNode, forwardRef } from 'react'
import styled from '@emotion/styled'

export interface MenuOptionProps extends HTMLAttributes<HTMLDivElement> {
  children: string | ReactNode
  desc?: ReactElement | string | undefined | null
  icon?: ReactElement
  extraRight?: ReactElement | string | undefined | null
}

export const StyledMenuOption = styled.div`
  border-bottom: 1px solid #f1f5f9;
  color: #475569;
  display: flex;
  align-items: center;
  margin: 0 -4px;
  gap: 14px;
  padding: 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  transition: background-color ease-in-out 160ms;
  box-sizing: content-box;

  & span {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &[data-higlighted='true'],
  &[data-selected='true'],
  &:hover {
    background-color: #f8fafc;
  }

  &[data-selected='true'],
  &:focus {
    color: #0ea5e9;
  }

  &:last-of-type {
    border-color: transparent;
  }
`

const Icon = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: flex-start;
`

export const MenuOption = forwardRef<HTMLDivElement, MenuOptionProps>(function MenuOption(
  { children, icon, desc, ...rest },
  ref,
) {
  return (
    <StyledMenuOption ref={ref} {...rest}>
      {icon && <Icon>{icon}</Icon>}
      {desc ? (
        <div className="flex flex-col gap-2">
          <div className="font-semibold">{children}</div>
          <div>{desc}</div>
        </div>
      ) : (
        children
      )}
      {rest.extraRight && <div className="ml-auto">{rest.extraRight}</div>}
    </StyledMenuOption>
  )
})
