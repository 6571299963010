import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { AccountDetails } from '@/api/dashboard'
import { Button, Drawer, FullscreenTopbar, FullscreenTopbarBack, FullscreenTopbarTitle, Stepper, useModal } from '@/ui'
import { GET_STARTED_PATH, cn, useGenerateCompanyPath, useNavigateBack } from '@/libs'
import { Plus } from '@/icons'
import { FullscreenLayout } from '@/components/layouts'
import { accountDetailsQuery, useAccountDetailsMutation } from '@/layouts/company'
import { VerifyCard, VerifyDataCard } from '../components'
import {
  VerifyAuthorizedSignatoryForm,
  VerifyBusinessOwnerCard,
  VerifyBusinessOwnerForm,
  VerifyCompanyLegalForm,
  VerifySubmittingModal,
} from '../widgets'
import { useBanner } from '@/libs/hooks/useBanner'

const MAX_BO = 4

export const GetStartedVerifyPage = () => {
  const back = useNavigateBack({ fallback: GET_STARTED_PATH })
  const { t } = useTranslation()
  const { companyId } = useGenerateCompanyPath()
  const { data: accountDetails, isLoading } = useQuery(accountDetailsQuery(companyId))
  const { mutateAsync } = useAccountDetailsMutation(companyId)
  const [form, setForm] = useState<'authorized-signatory' | 'business-owners' | 'company-legal' | null>(null)
  const [formDataKey, setFormDataKey] = useState<number | null>(null)
  const openVerifySubmittingModal = useModal(props => <VerifySubmittingModal {...props} />)
  const { getStickyHeight } = useBanner()
  const allowSubmit = useMemo(() => {
    return (
      accountDetails?.verify_state !== 'under_review' &&
      accountDetails?.verify_state !== 'verified' &&
      accountDetails?.details.authorized_signatory &&
      (accountDetails?.details.have_no_ubo || accountDetails?.details.business_owners?.length) &&
      accountDetails?.details.company_legal
    )
  }, [accountDetails])

  const onClickDeleteUboRecord = useCallback(
    async (uboIndex: number) => {
      if (!accountDetails?.details) {
        return
      }

      await mutateAsync({
        ...accountDetails?.details,
        business_owners: accountDetails?.details.business_owners?.filter((_, index) => index !== uboIndex),
      })
    },
    [accountDetails, mutateAsync],
  )

  const onSubmit = useCallback(
    async (data: AccountDetails) => {
      await mutateAsync({ ...accountDetails?.details, ...data })
      setForm(null)
    },
    [accountDetails, mutateAsync, setForm],
  )

  const renderBusinessOwner = () => {
    return (
      <div>
        <div className="mb-3 flex flex-col gap-3 empty:hidden">
          {accountDetails?.details.business_owners?.map((bo, index) => (
            <VerifyBusinessOwnerCard
              key={`bo-card-${bo.entity_legal_name}-${bo.country}`}
              data={bo}
              onClickDelete={() => {
                onClickDeleteUboRecord(index)
              }}
              onClickEdit={() => {
                setForm('business-owners')
                setFormDataKey(index)
              }}
            />
          ))}
          {accountDetails?.details.have_no_ubo && (
            <VerifyDataCard
              title={t('get-started.verify.steps.business-owner.no-owner')}
              onClickEdit={() => {
                setForm('business-owners')
                setFormDataKey(null)
              }}
            />
          )}
        </div>

        {!accountDetails?.details.have_no_ubo && (accountDetails?.details.business_owners?.length || 0) < MAX_BO && (
          <Button
            className="w-full"
            variant="outline"
            size="lg"
            onClick={() => {
              setForm('business-owners')
              setFormDataKey(null)
            }}
          >
            <Plus size={22} />
            <span>{t('get-started.verify.steps.business-owner.card.add')}</span>
          </Button>
        )}
      </div>
    )
  }

  return (
    <FullscreenLayout isLoading={isLoading}>
      <div className={cn(getStickyHeight(), 'overflow-auto')}>
        <FullscreenTopbar>
          <FullscreenTopbarBack {...back} />
          <FullscreenTopbarTitle>{t('get-started.verify.title')}</FullscreenTopbarTitle>
          <div className="ml-auto">
            <Button variant="primary" type="button" disabled={!allowSubmit} onClick={() => openVerifySubmittingModal()}>
              {t('get-started.verify.submit')}
            </Button>
          </div>
        </FullscreenTopbar>

        <Drawer open={form === 'authorized-signatory'} width={600}>
          <VerifyAuthorizedSignatoryForm
            data={accountDetails?.details.authorized_signatory}
            onSubmit={onSubmit}
            onCancel={() => setForm(null)}
          />
        </Drawer>

        <Drawer open={form === 'business-owners'} width={600}>
          <VerifyBusinessOwnerForm
            accountDetails={accountDetails}
            data={formDataKey !== null ? accountDetails?.details.business_owners?.[formDataKey] : undefined}
            dataKey={formDataKey}
            onSubmit={onSubmit}
            onCancel={() => setForm(null)}
          />
        </Drawer>

        <Drawer open={form === 'company-legal'} width={600}>
          <VerifyCompanyLegalForm
            data={accountDetails?.details.company_legal}
            onSubmit={onSubmit}
            onCancel={() => setForm(null)}
          />
        </Drawer>

        <div className="container mt-12 max-w-lg">
          <Stepper
            steps={[
              {
                label: t('get-started.verify.steps.authorized-signatory.card.title'),
                children: accountDetails?.details.authorized_signatory ? (
                  <VerifyCard
                    data={accountDetails.details}
                    variant="authorized_signatory"
                    onEdit={() => setForm('authorized-signatory')}
                  />
                ) : (
                  <Button variant="outline" size="lg" onClick={() => setForm('authorized-signatory')}>
                    <Plus size={22} />
                    <span>{t('get-started.verify.steps.authorized-signatory.card.add')}</span>
                  </Button>
                ),
                status: accountDetails?.details.authorized_signatory ? 'done' : 'idle',
              },
              {
                label: t('get-started.verify.steps.business-owner.card.title'),
                children: renderBusinessOwner(),
                status:
                  accountDetails?.details.have_no_ubo || accountDetails?.details.business_owners?.length
                    ? 'done'
                    : 'idle',
              },
              {
                label: t('get-started.verify.steps.company-legal.card.title'),
                children: accountDetails?.details.company_legal ? (
                  <VerifyCard
                    data={accountDetails.details}
                    variant="company_legal"
                    onEdit={() => setForm('company-legal')}
                  />
                ) : (
                  <Button variant="outline" size="lg" onClick={() => setForm('company-legal')}>
                    <Plus size={22} />
                    <span>{t('get-started.verify.steps.company-legal.card.add')}</span>
                  </Button>
                ),
                status: accountDetails?.details.company_legal ? 'done' : 'idle',
              },
            ]}
          />
        </div>
      </div>
    </FullscreenLayout>
  )
}
