import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <path
      d="M12,6.4c-1.6,1.2-3.14,2.49-4.71,3.74-.34.27-.39.32-.42.36-.04.06-.08.13-.1.2-.02.06-.02.14-.02.57v4.92c0,.39,0,.64.02.83.01.18.04.24.05.27.06.12.15.21.26.26.02,0,.07.03.25.05.18.02.43.02.81.02h7.73c.39,0,.63,0,.81-.02.17-.01.23-.04.25-.05.11-.06.2-.15.26-.26.01-.02.04-.08.05-.27.02-.19.02-.44.02-.83v-4.92c0-.43,0-.51-.02-.57-.02-.07-.05-.14-.1-.2-.03-.05-.09-.1-.42-.36-1.57-1.25-3.11-2.54-4.71-3.74ZM11.63,4.93c.24-.07.5-.07.75,0,.17.05.31.13.42.2.1.07.2.15.31.23l4.54,3.6s.04.03.05.04c.25.2.48.38.65.61.15.21.26.44.33.68.08.28.08.57.08.9,0,.02,0,.05,0,.07v4.95c0,.36,0,.67-.02.93-.02.27-.07.55-.2.82-.2.4-.51.72-.91.93-.27.14-.54.19-.81.21-.25.02-.56.02-.91.02h-7.79c-.35,0-.66,0-.91-.02-.27-.02-.55-.07-.81-.21-.39-.2-.71-.53-.91-.93-.13-.27-.18-.54-.2-.82-.02-.26-.02-.57-.02-.93v-4.95s0-.05,0-.07c0-.32,0-.62.08-.9.07-.25.18-.48.33-.68.17-.24.4-.42.65-.61.02-.01.04-.03.05-.04,1.51-1.2,3.02-2.4,4.54-3.6.1-.08.21-.17.31-.23.11-.08.25-.16.42-.2Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'fill',
    <path
      d="M12.37,4.93c-.24-.07-.5-.07-.75,0-.17.05-.31.13-.42.2-.1.07-.2.15-.31.23l-4.59,3.65c-.25.2-.48.38-.65.61-.15.21-.26.44-.33.68-.08.28-.08.57-.08.9v5.02c0,.36,0,.67.02.93.02.27.07.55.2.82.2.4.51.72.91.93.27.14.54.19.81.21.25.02.56.02.91.02h7.79c.35,0,.66,0,.91-.02.27-.02.55-.07.81-.21.39-.2.71-.53.91-.93.13-.27.18-.54.2-.82.02-.26.02-.57.02-.93v-5.02c0-.32,0-.62-.08-.9-.07-.25-.18-.48-.33-.68-.17-.24-.4-.42-.65-.61l-4.59-3.65c-.1-.08-.21-.17-.31-.23-.11-.08-.25-.16-.42-.2Z"
      fill="currentColor"
    />,
  ],
  [
    'duotone',
    <>
      <rect x="0" y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12C0,21.31,2.69,24,6,24h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <path
        d="M12.37,4.93c-.24-.07-.5-.07-.75,0-.17.05-.31.13-.42.2-.1.07-.2.15-.31.23l-4.59,3.65c-.25.2-.48.38-.65.61-.15.21-.26.44-.33.68-.08.28-.08.57-.08.9v5.02c0,.36,0,.67.02.93.02.27.07.55.2.82.2.4.51.72.91.93.27.14.54.19.81.21.25.02.56.02.91.02h7.79c.35,0,.66,0,.91-.02.27-.02.55-.07.81-.21.39-.2.71-.53.91-.93.13-.27.18-.54.2-.82.02-.26.02-.57.02-.93v-5.02c0-.32,0-.62-.08-.9-.07-.25-.18-.48-.33-.68-.17-.24-.4-.42-.65-.61l-4.59-3.65c-.1-.08-.21-.17-.31-.23-.11-.08-.25-.16-.42-.2Z"
        fill="currentColor"
      />
    </>,
  ],
])

export const Dashboard = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
