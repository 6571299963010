import { useCallback, useContext, useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import i18next from 'i18next'
import TableBar from '../../components/TableBar'
import { canEdit } from '../../security'
import {
  Badge,
  Button,
  ModalConfirm,
  Pagination,
  Table,
  TableCell,
  TableCellBulkCheckbox,
  TableCellBulkToolbar,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  Tooltip,
  useModal,
  usePagination,
} from '../../components/ui'
import { EditIcon } from '../../icons/Icons'
import { Plus, Trash } from '../../components/icons'
import { useStoreDeleteMutate, useStoresQuery } from './api'
import EditStoreDialog from './EditStoreDialog'
import { StoreGraph, StoreRead, StoreReadStat, StoreType } from '../../api/dashboard'
import { dashboardClient } from '../../api'
import { STORE_ITEMS_PATH, STORE_RULES_PATH } from '../../libs/routerPaths'
import ToolTipBox from '../dashboard/components/ToolTip/ToolTipBox'
import { useBulkSelect } from '../../libs/hooks/useBulkSelect'
import { cn } from '../../libs/cn'
import { AuthContext, IAuthContext } from '@/Context'

export default function StorePage() {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const navigate = useNavigate()

  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)

  const { page, query, onPageChange, needShowPagination } = usePagination(10)
  const { data: items = [], isLoading, refetch } = useStoresQuery(companyId, gameId)
  const { mutateAsync: deleteMutateAsync } = useStoreDeleteMutate(companyId, gameId)
  const [storeGraph, setStoreGraph] = useState<StoreGraph>({
    enabled: false,
  } as StoreGraph)
  const { selected, onSelect, onReset } = useBulkSelect()

  const loadStoreGraph = async () => {
    let { data: base } = await dashboardClient.v1.getBaseStoreGraph(companyId, gameId)
    if (base.graph) {
      setStoreGraph(base.graph)
    }
  }

  useEffect(() => {
    loadStoreGraph()
  }, [gameId])

  const openBulkDeleteModal = useModal<{ subMessage: string }>(props => (
    <ModalConfirm
      color={'error'}
      confirmButtonText={i18next.t('remove')}
      message={i18next.t('store.remove-n-title')}
      onConfirm={() => onConfirmDeleteItems()}
      {...props}
    />
  ))

  const openDeleteModal = useModal<{ item: StoreReadStat }>(props => (
    <ModalConfirm
      color={'error'}
      subMessage={i18next.t('confirm.text', { name: props.item.name })}
      confirmButtonText={i18next.t('remove')}
      onConfirm={() => onDeleteGameItemClick(props.item)}
      {...props}
    />
  ))

  const openEditModal = useModal<{ item: StoreRead }>(props => <EditStoreDialog {...props} />)

  const onDeleteGameItemClick = async (deleteConfirmItem: StoreRead) => {
    if (deleteConfirmItem) {
      await deleteMutateAsync({ id: deleteConfirmItem.id })
    }
  }

  const onClickBulkDelete = useCallback(() => {
    openBulkDeleteModal({ subMessage: i18next.t('store.remove-n-items', { N: selected.size }) })
  }, [items, selected, openBulkDeleteModal])

  const onConfirmDeleteItems = async () => {
    await dashboardClient.v1.bulkDeleteStores(
      companyId,
      gameId,
      Array.from(selected).map(id => ({ id })),
    )

    onReset([])

    refetch()
  }

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        {items.length > 0 && canUserEdit && (
          <TableCellBulkCheckbox
            selected={selected.size > 0 && selected.size == items.length}
            disabled={items.length === 0}
            onChange={() => onReset(selected.size == items.length ? [] : items.map(i => i.id))}
          />
        )}
        <TableCell>{i18next.t('store.store')}</TableCell>
        <TableCell width="25%">{i18next.t('store.item_count')}</TableCell>
        <TableCell width="25%">{i18next.t('store.type')}</TableCell>
        <TableCell width="20%" />
      </TableRow>
    )
  }

  const renderBulkSelectionHeader = () => (
    <TableRow variant="header">
      <TableCellBulkCheckbox
        selected={selected.size > 0 && selected.size == items.length}
        disabled={items.length === 0}
        onChange={() => onReset(selected.size == items.length ? [] : items.map(i => i.id))}
      />
      <TableCellBulkToolbar selected={selected} onReset={() => onReset([])}>
        <Button variant="tertiary-destructive" onClick={onClickBulkDelete}>
          <Trash />
          <span>{i18next.t('remove')}</span>
        </Button>
      </TableCellBulkToolbar>
    </TableRow>
  )

  const renderEditRulesButton = () => {
    return (
      <Button onClick={() => navigate(generatePath(STORE_RULES_PATH, { companyId, gameId }))}>
        <Tooltip message={i18next.t(storeGraph.enabled ? 'store.status.published' : 'store.status.unpublished')}>
          <div
            className={cn('size-2 rounded-full', storeGraph.enabled ? 'bg-fg-lime-primary' : 'bg-fg-gray-primary')}
          />
        </Tooltip>
        {i18next.t('store.edit-store-rules')}
      </Button>
    )
  }

  return (
    <div className="flex h-full flex-col">
      <TableBar
        sticky
        customAction={
          canUserEdit && (
            <div className="flex gap-2">
              {renderEditRulesButton()}
              {items.length > 0 && (
                <Button
                  variant={'primary'}
                  onClick={() =>
                    openEditModal({
                      item: {
                        name: '',
                        description: '',
                      } as StoreRead,
                    })
                  }
                >
                  {i18next.t('store.create-store')}
                </Button>
              )}
            </div>
          )
        }
        btnText={i18next.t('store.create-store')}
        title={i18next.t('sidebar.store')}
      />

      {isLoading ? (
        <TableRowSkeleton rowCount={query.limit} columnCount={3} />
      ) : items.length === 0 ? (
        <TableZeroState
          title={i18next.t(canUserEdit ? 'store.empty-table.title' : 'store.empty-table.title.read-only')}
          message={i18next.t(canUserEdit ? 'store.empty-table' : 'store.empty-table.read-only')}
          buttons={
            canUserEdit && (
              <Button
                variant="primary"
                onClick={() =>
                  openEditModal({
                    item: { name: '', description: '', type: StoreType.Special } as StoreRead,
                  })
                }
              >
                <Plus size={14} />
                <span>{i18next.t('store.zero-state.new-button')}</span>
              </Button>
            )
          }
        />
      ) : (
        <>
          <Table>
            {selected.size == 0 ? renderDefaultHeader() : renderBulkSelectionHeader()}
            {items.map(it => (
              <TableRow key={it.id} to={generatePath(STORE_ITEMS_PATH, { storeId: it.id, companyId, gameId })}>
                {canUserEdit && (
                  <TableCellBulkCheckbox selected={selected.has(it.id)} onChange={() => onSelect(it.id)} />
                )}
                <TableCell>
                  <div>
                    <div className="font-medium text-text-secondary">{it.name}</div>
                    <div className="truncate text-text-tertiary">{it.description}</div>
                  </div>
                </TableCell>
                <TableCell width="25%">{it.item_count}</TableCell>
                <TableCell width="25%">
                  {it.type == StoreType.Default ? (
                    <Badge variant="gray-secondary">
                      {i18next.t('store.type.default')}
                      <ToolTipBox tooltip={i18next.t('store.type.default.tooltip')} />
                    </Badge>
                  ) : (
                    <div className="ml-2 flex items-center gap-1">
                      {i18next.t(`store.type.${it.type}`)}
                      <ToolTipBox tooltip={i18next.t(`store.type.${it.type}.desc`)} />
                    </div>
                  )}
                </TableCell>
                <TableCell width="20%">
                  <div className="text-right">
                    {canUserEdit && (
                      <TableRowEditButton
                        onChange={v => {
                          switch (v) {
                            case 'edit':
                              openEditModal({ item: it })
                              break
                            case 'remove':
                              openDeleteModal({
                                item: it,
                              })
                              break
                          }
                        }}
                        options={[
                          {
                            icon: <EditIcon />,
                            children: i18next.t('grid.edit'),
                            value: 'edit',
                          },
                          {
                            icon: <Trash className="text-text-error-primary" />,
                            children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                            value: 'remove',
                          },
                        ]}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </Table>
          {needShowPagination(isLoading, items) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </div>
  )
}
