import { Grid } from '@mui/material'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { useTheme } from '@mui/material/styles'
import { StyledInputLabel } from './StyledTextField'

export interface ITextFieldProps {
  label?: string
  helperText?: string
  value: string | null //2023-09-23
  margin?: boolean
  fullWidth?: boolean
  onChange: (value: string) => void
  minDate?: Dayjs
  maxDate?: Dayjs
}

export default function StyledDatePicker(props: ITextFieldProps) {
  const theme = useTheme()

  return (
    <Grid container sx={{ marginTop: props.margin ? theme.spacing(2) : '' }}>
      <Grid item xs={12}>
        {props.label && <StyledInputLabel label={props.label} />}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            minDate={props.minDate}
            maxDate={props.maxDate}
            value={props.value ? dayjs(props.value) : null}
            slotProps={{ textField: { size: 'small', fullWidth: true } }}
            onChange={newValue => {
              if (newValue?.isValid()) {
                props.onChange((newValue as Dayjs).format('YYYY-MM-DD'))
              }
            }}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  )
}
