import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import i18next from 'i18next'
import { Button } from './ui'

export default function EmptyTableMessage(props: {
  title?: string
  subtitle?: string
  addButtonText?: string
  onAddButtonClick?: () => void
  sx?: SxProps<Theme>
  className?: string
}) {
  const theme = useTheme()

  return (
    <Box
      className={props.className}
      sx={{
        backgroundColor: theme.palette.grey['150'],
        border: `1px solid ${theme.palette.grey['100']}`,
        overflow: 'hidden',
        borderRadius: theme.spacing(0),
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(3),
        ...props.sx,
      }}
    >
      <img
        src="/icons/empty-table.svg"
        style={{ flex: '1 2 50px', height: '50px', maxHeight: '199px' }}
        alt="empty table"
      />
      <Typography variant="h1" sx={{ flexShrink: 0 }}>
        {props.title || i18next.t('empty-table.title')}
      </Typography>

      <Typography variant="h6" fontWeight={400} sx={{ flexShrink: 0 }}>
        {props.subtitle}
      </Typography>

      {props.addButtonText && props.onAddButtonClick && (
        <Button style={{ flexShrink: 0 }} variant="primary" size="lg" onClick={props.onAddButtonClick}>
          {props.addButtonText}
        </Button>
      )}
    </Box>
  )
}
