import { Globe1 } from '../../../../components/icons'

interface NewsSlugCaptionProps {
  base: string | undefined
  slug: string
}

export const NewsSlugCaption = ({ base, slug }: NewsSlugCaptionProps) => {
  if (!slug) {
    return null
  }

  return (
    <a className="flex items-center gap-3 p-1.5" target="_blank" href={`${base}/news/${slug}`}>
      <Globe1 size={18} />
      <div className="text-text-secondary">
        {base}/news/{slug}
      </div>
    </a>
  )
}
