import { Currency, PaymentRead } from '../../api/dashboard'
import { formatMoney } from '../../util'
import { RewardPointsPrice } from '../game-items/components/RewardPointsPrice'
import { PaymentStatus } from '@/pay-types'

export const PADDING = 2.25

export function getTransactionTotal(it: PaymentRead) {
  if (it.currency == Currency.RP) {
    return <RewardPointsPrice price={it.amount || 0} showText={false} />
  }

  let value = it.total_order_currency_billing
  if (value == undefined) {
    value = it.total
  }

  return formatMoney(value, it.currency, it.currency_minor_unit)
}

export function isDemo(orderId: string | undefined) {
  return orderId?.startsWith('demo_ord')
}

export function getMinPaymentByStatus(sourcePayments: PaymentRead[], status: PaymentStatus) {
  let pStatusItems = sourcePayments.filter(it => it.status == status)
  return pStatusItems[pStatusItems.length - 1]
}
