import { S3Bucket } from '../../types'
import i18next from 'i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Block, VideoPlatform } from '../../api/dashboard'
import { FieldGroup, FieldValidationMessage, ImageUploader, Input } from '../../components/ui'
import { ErrorMessage } from '@hookform/error-message'

export function extractYouTubeID(url: string | undefined): string | null {
  if (!url) {
    return null
  }
  try {
    const urlObj = new URL(url)
    let videoId: string | null = null

    if (urlObj.hostname === 'www.youtube.com' || urlObj.hostname === 'youtube.com') {
      // For standard YouTube URLs, the video ID is in the 'v' search parameter
      videoId = urlObj.searchParams.get('v')
    } else if (urlObj.hostname === 'youtu.be') {
      // For shortened YouTube URLs, the video ID is in the pathname
      videoId = urlObj.pathname.slice(1)
    } else if (urlObj.hostname === 'www.youtube-nocookie.com') {
      // For privacy-enhanced mode YouTube URLs
      videoId = urlObj.pathname.split('/')[2]
    }

    // Validate the extracted ID
    if (videoId && /^[a-zA-Z0-9_-]{11}$/.test(videoId)) {
      return videoId
    }

    return null
  } catch (error) {
    // The URL constructor throws a TypeError if the URL is not valid
    console.error(error)
    return null
  }
}

const SelectVideoPanel = () => {
  const {
    setValue,
    formState: { errors },
    control,
  } = useFormContext<Block>()

  // let initUrl = null
  // if (props.block) {
  //   let id = (props.block.video?.data as YouTubeData)?.video_id
  //   if (id) {
  //     initUrl = `https://www.youtube.com/watch?v=${id}`
  //   }

  return (
    <div>
      <FieldGroup label={i18next.t('hub-editor.video-panel.url')}>
        <Controller
          name="video.data.video_id"
          control={control}
          rules={{ required: i18next.t('validation.required') }}
          render={({ field }) => (
            <Input
              value={field.value ? `https://www.youtube.com/watch?v=${field.value}` : ''}
              onChange={e => {
                const videoID = extractYouTubeID(e.target.value)
                const thumbnailURL = `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`

                setValue('video', {
                  title: '',
                  description: '',
                  platform: VideoPlatform.Youtube,
                  thumbnail_url: thumbnailURL,
                  data: {
                    video_id: videoID as string,
                  },
                })
              }}
            />
          )}
        />
        <ErrorMessage
          name="video.data.video_id"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </FieldGroup>

      <FieldGroup label={i18next.t('hub-editor.video-panel.thumbnail')}>
        <Controller
          name="video.thumbnail_url"
          control={control}
          rules={{ required: i18next.t('validation.required') }}
          render={({ field }) => (
            <ImageUploader
              {...field}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': [],
                'image/webp': [],
                'image/gif': [],
              }}
              bucket={S3Bucket.hub}
            />
          )}
        />
        <ErrorMessage
          name="video.thumbnail_url"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </FieldGroup>
    </div>
  )
}

export default SelectVideoPanel
