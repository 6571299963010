import { CSSProperties, forwardRef, useState } from 'react'
import { S3Bucket } from '@/types'
import { fetchUploadImage } from '@/api/files'
import { Button } from '@/ui'
import i18next from 'i18next'

interface ImageUploaderProps {
  value: string | null | undefined
  style?: CSSProperties
  onChange: (value: string | null) => void
}

export const AvatarUploader = forwardRef<HTMLInputElement, ImageUploaderProps>(function ImageUploader(
  { value, onChange, style },
  ref,
) {
  const [, setFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const onFileSelected = async (acceptedFiles: FileList | null) => {
    if (!acceptedFiles) {
      return
    }
    setIsLoading(true)
    const file = acceptedFiles[0]

    setFile(file)

    try {
      const data = await fetchUploadImage(file, { bucket: S3Bucket.dashboard })
      onChange(data.get_url)
      setFile(null)
    } catch (err) {
      setFile(null)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div style={style} ref={ref} className="flex items-center gap-18">
      <img src={value || '/icons/default-avatar.svg'} alt="img" className="size-[64px] rounded-full" />
      <Button className="relative w-[145px]" isLoading={isLoading}>
        {i18next.t('account-profile.upload-photo')}
        <input
          className="absolute left-0 top-0 size-full opacity-0"
          type="file"
          accept={'image/png, image/jpg, image/jpeg, image/webp'}
          onChange={e => onFileSelected(e.target.files)}
        />
      </Button>
    </div>
  )
})
