import { useAuth0 } from '@auth0/auth0-react'
import { HUB_API } from '../HubAPI'
import { useEffect, useState } from 'react'

export const useAuthToken = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0()
  const [authDone, setAuthDone] = useState<boolean>(false)
  const loadToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: import.meta.env.VITE_AUTH0_API_AUDIENCE,
          scope: 'read:current_user',
        },
      })
      HUB_API.setToken(accessToken)
      setAuthDone(true)
    } catch (e) {
      loginWithRedirect()
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      loadToken()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (!HUB_API.getToken()) {
      loadToken()
    } else {
      setAuthDone(true)
    }
  }, [])

  if (authDone) {
    return { isAuthenticated: true, isLoading: false, loginWithRedirect, authDone: true }
  }

  return { isAuthenticated, isLoading, loginWithRedirect, authDone }
}
