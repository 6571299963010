import { PeriodType, TimerNode } from '../../../../api/dashboard'
import { getTime } from '../../editors/TimerEditor'
import i18next from 'i18next'

export function getDescTimerNode(node: TimerNode) {
  let time = getTime(node.delay)
  let subTitle = ''
  if (time[PeriodType.Day]) {
    subTitle = `${time[PeriodType.Day]}${i18next.t('time.interval.days')} `
  }
  if (time[PeriodType.Hour]) {
    subTitle += `${time[PeriodType.Hour]}${i18next.t('time.interval.hours')} `
  }
  if (time[PeriodType.Minute]) {
    subTitle += `${time[PeriodType.Minute]}${i18next.t('time.interval.minutes')} `
  }
  if (time[PeriodType.Second]) {
    subTitle += `${time[PeriodType.Second]}${i18next.t('time.interval.seconds')}`
  }

  return [i18next.t('campaign.block.wait'), subTitle]
}
