import i18next from 'i18next'
import { useParams } from 'react-router-dom'
import { useLeaderboardQuery } from '../../layouts/leader-board/api'
import { FieldGroup, FieldValidationMessage, Select } from '../../components/ui'
import { Controller, useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { Block } from '../../api/dashboard'

const SelectLeaderBoardPanel = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const { data: items = [] } = useLeaderboardQuery(companyId, gameId)

  const {
    setValue,
    formState: { errors },
    control,
  } = useFormContext<Block>()

  return (
    <FieldGroup label={i18next.t('hub-editor.leaderboard')}>
      <Controller
        name="leaderboard_id"
        control={control}
        rules={{ required: i18next.t('validation.required') }}
        render={({ field }) => (
          <>
            <Select
              value={field.value}
              options={items.map(it => ({
                value: it.id,
                children: it.name,
              }))}
              onChange={v => setValue('leaderboard_id', v as string)}
            />
            <ErrorMessage
              name="leaderboard_id"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </>
        )}
      />
    </FieldGroup>
  )
}

export default SelectLeaderBoardPanel
