import { createContext } from 'react'
import { WSMessage } from './types'
import { CustomerCompany } from './api/dashboard'

export interface IAppState {
  activeTask: string
  wsMessage: WSMessage<unknown>
  gameProp: string | null
}

export interface IAuthContext {
  customer: CustomerCompany
  setCustomer: React.Dispatch<React.SetStateAction<CustomerCompany | null>>
  currentCompanyId: string | null
  setCurrentCompanyId: React.Dispatch<React.SetStateAction<string | null>>
  state: IAppState
  setState: (value: IAppState) => void
}

export const AuthContext = createContext<IAuthContext | null>(null)
