import { Link, generatePath, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCompaniesQuery, useCurrentGame } from '../../../api/useCompaniesQuery'
import { setCompanyGameId, setLastGameId } from '../../../Settings'
import { HOME_PATH } from '../../../libs/routerPaths'
import { cn } from '../../../libs/cn'
import { Button } from '../Button'
import { Floating } from '../Floating'
import { SidebarGameButton } from './SidebarGameButton'
import { useMemo } from 'react'
import { AghanimGameSchemasGame, CompanyGame } from '../../../api/dashboard'

interface GameItemProps {
  game: AghanimGameSchemasGame
  selected?: boolean
  onSelect: (game: AghanimGameSchemasGame) => void
}

const GameItem = ({ game, selected = false, onSelect }: GameItemProps) => {
  const onClick = () => {
    onSelect(game)
  }

  return (
    <Link
      className="flex cursor-pointer items-center gap-3 rounded-md px-3 py-1 transition-colors hover:bg-fg-primary-alt"
      to={generatePath(HOME_PATH, { companyId: game.company_id, gameId: game.id })}
      onClick={onClick}
    >
      <div className="shrink-0">
        <div
          className={cn(
            'size-4 rounded-full border border-border-primary bg-fg-primary',
            selected && 'border-4 border-fg-brand-primary shadow-radio',
          )}
        />
      </div>
      <div className="shrink-0">
        <img className="flex size-8 rounded-md object-cover" src={game.logo_url} alt="" />
      </div>
      <div className="font-medium leading-none text-text-secondary">{game.name}</div>
    </Link>
  )
}

interface GameSwitchMenuProps {
  selectedGameId: string | undefined
  onClose?: () => void
  companiesWithGames: CompanyGame[]
  onAddGameClick: (currentCompanyGame: CompanyGame) => void
}

const GameSwitchMenu = ({
  selectedGameId,
  onClose = () => void 0,
  companiesWithGames,
  onAddGameClick,
}: GameSwitchMenuProps) => {
  const { t } = useTranslation()
  const { getSandboxGameState, game } = useCurrentGame()
  const onSelectGame = (game: AghanimGameSchemasGame) => {
    onClose()
    setLastGameId(game.id)
    setCompanyGameId(game.company_id)
  }

  const getSandBoxGame = (id: string, company: CompanyGame) => {
    if (game?.id == id) {
      return undefined
    }

    if (getSandboxGameState(id)) {
      return company.games?.find(g => g.live_game_id == id && g.sandbox)
    }
    return undefined
  }

  const renderCompany = (company: CompanyGame) => {
    return (
      <div className="mb-3 border-b border-border-secondary pb-2 last:border-none last:pb-0">
        <div className="mb-2 text-[10px] font-semibold leading-none text-text-tertiary">{company.name}</div>
        {company.games
          ?.filter(g => !!g)
          .filter(g => !g.sandbox) // list only by live games
          .map(game => (
            <GameItem
              key={`game-item-${game.id}`}
              game={getSandBoxGame(game.id, company) || (game as AghanimGameSchemasGame)}
              selected={game.sandbox_game_id == selectedGameId || game.id === selectedGameId}
              onSelect={onSelectGame}
            />
          ))}
      </div>
    )
  }

  const selectedCompany = companiesWithGames.find(c => c.games?.find(g => g.id == selectedGameId))
  const demoCompany = companiesWithGames.find(c => c.is_demo)

  return (
    <div
      className="max-w-[380px] overflow-auto rounded-xl border border-border-primary bg-fg-primary shadow-lg"
      style={{ maxHeight: '500px' }}
    >
      <div style={{ height: '12px' }} />
      <div className="px-3 pb-3">
        <Button
          className="w-full"
          variant="outline"
          onClick={() => {
            onClose()

            let currentCompany = companiesWithGames.find(c => c.games?.find(g => g.id == selectedGameId))

            if (currentCompany) {
              onAddGameClick(currentCompany)
            }
          }}
        >
          {t('AddGame')}
        </Button>
      </div>

      <div className="px-3">
        {demoCompany && renderCompany(demoCompany)}
        {selectedCompany && selectedCompany.id != demoCompany?.id && renderCompany(selectedCompany)}
        {companiesWithGames
          .filter(c => c != selectedCompany && c != demoCompany)
          .map(company => renderCompany(company))}
      </div>
    </div>
  )
}

export const SidebarGameSwitch = (props: { onAddGameClick: (currentCompanyGame: CompanyGame) => void }) => {
  const { gameId } = useParams()
  const { data: dataCompanies } = useCompaniesQuery()
  const companiesWithGames = useMemo(() => {
    let res = dataCompanies?.data?.filter(company => company.games?.length) || []

    let demoCompany = res.find(it => it.is_demo)
    if (demoCompany) {
      res = res.filter(it => it.id != demoCompany.id || it.is_demo)
    }

    return res
  }, [dataCompanies])

  const game = useMemo(() => {
    for (let c of companiesWithGames) {
      let game = c.games?.find(it => it.id == gameId)
      if (game) {
        return game
      }
    }

    return undefined
  }, [dataCompanies, gameId])

  return (
    <Floating
      menu={
        <GameSwitchMenu
          selectedGameId={gameId}
          companiesWithGames={companiesWithGames}
          onAddGameClick={props.onAddGameClick}
        />
      }
      placement="bottom-start"
      referenceWidth={true}
    >
      <SidebarGameButton game={game} />
    </Floating>
  )
}
