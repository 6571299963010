import '../ToolTip/ToolTip.css'
import { useContext, useEffect, useMemo, useState } from 'react'
import DashboardCard from '../../../../components/shared/DashboardCard'
import ReactECharts from 'echarts-for-react'
import * as echarts from 'echarts'
import Loader from '../Loader/Loader'
import { LoaderContainer } from '../Loader/LoaderContainer'
import { formatMoney } from '../../../../util'
import { KeyValue } from '../../../../types'
import { AnalyticsDataContext, IAnalyticsDataContext } from '../../Context'
import ErrorBoundary from '../../../../components/ErrorBoundary'
import { useTranslation } from 'react-i18next'
import { getCountries } from '@/translates'

const RevenueMap = () => {
  const { t } = useTranslation()
  const context = useContext(AnalyticsDataContext) as IAnalyticsDataContext
  const countries = getCountries()
  const loading = !context.data.revenue_by_country
  const [mapLoading, setMapLoading] = useState(false)
  const data = context.data.revenue_by_country || []

  const loadTopobase = async () => {
    if (echarts.getMap('world')) {
      setMapLoading(false)
      return
    }
    let response = await fetch('/topobase/world.json', { method: 'GET' })
    let json = await response.json()

    echarts.registerMap('world', json)
    setMapLoading(false)
  }

  useEffect(() => {
    loadTopobase()
  }, [])

  const [min, max] = useMemo(() => {
    let min = 0
    let max = 0
    data.forEach(it => {
      if (!it.value) {
        return
      }

      if (it.value < min) {
        min = it.value
      }
      if (it.value > max) {
        max = it.value
      }
    })
    return [min, max]
  }, [data])

  if (!echarts.getMap('world')) {
    return null
  }

  const options = {
    title: {
      show: false,
    },
    tooltip: {
      trigger: 'item',
      backgroundColor: '#000000CC',
      borderColor: 'transparent',
      formatter: function (params: { seriesName: string; name: string; value: number }) {
        return `<div class="chart-tooltip" style="height: 24px">
                  <div class="chart-tooltip--subtitle">
                    ${params.name + ' : ' + (params.value ? formatMoney(params.value) : t('dashboard.chart.no-data'))}
                  </div>
              </div>`
      },
    },
    visualMap: {
      min: min,
      max: max,
      left: 'left',
      top: 'bottom',
      text: ['High', 'Low'],
      seriesIndex: [0],
      inRange: {
        color: ['#0089CF', '#74C6F0'],
      },
      calculable: true,
    },
    dataRange: {
      min: min,
      max: max,
      text: ['High', 'Low'],
      realtime: false,
      calculable: true,
      color: ['#0089CF', '#74C6F0'],
      formatter: function (value: number) {
        return formatMoney(value)
      },
    },
    series: [
      {
        mapType: 'world',
        name: 'World Map',
        type: 'map',
        roam: false,
        nodeClick: false,
        map: 'world',
        itemStyle: {
          normal: {
            areaColor: '#CBD5E1',
            borderColor: '#fff',
          },
          emphasis: {
            areaColor: '#0089CF',
          },
        },
        data: data.map(it => ({
          name: (countries as KeyValue)[it.category] || it.category,
          value: it.value,
        })),
        nameMap: countries,
      },
    ],
  }

  return (
    <DashboardCard title={t('dashboard.map-name')} tooltip={t('dashboard.map-name.tooltip')} style={{ width: '100%' }}>
      {mapLoading || loading ? (
        <LoaderContainer style={{ height: '700px' }}>
          <Loader />
        </LoaderContainer>
      ) : (
        <ErrorBoundary>
          <ReactECharts
            option={options}
            style={{
              height: '700px',
              width: '100%',
            }}
          />
        </ErrorBoundary>
      )}
    </DashboardCard>
  )
}

export default RevenueMap
