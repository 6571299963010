import AgSwitch from './AgSwitch'

import Box from '@mui/material/Box'
import { Theme, useTheme } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { SxProps } from '@mui/system/styleFunctionSx'

export default function ListItemSwitch(props: {
  checked: boolean
  onChange: (value: boolean) => void
  label: string
  sx?: SxProps<Theme>
}) {
  const theme = useTheme()

  return (
    <Box
      display="flex"
      gap={theme.spacing(1.75)}
      padding={theme.spacing(1.3125, 1.75, 1.3125, 1.75)}
      sx={props.sx}
      flexDirection="row"
    >
      <AgSwitch checked={props.checked} onChange={(value: boolean) => props.onChange(value)} />
      <Typography variant="subtitle2" fontWeight="500">
        {props.label}
      </Typography>
    </Box>
  )
}
