import { createTheme } from '@mui/material/styles'

import components from './Components'
import typography from './Typography'
import { darkshadows, shadows } from './Shadows'
import { DarkThemeColors } from './DarkThemeColors'
import { LightThemeColors } from './LightThemeColors'
import { baseDarkTheme, baselightTheme } from './DefaultColors'
import * as locales from '@mui/material/locale'
import { deepMerge } from '../util'

export const buildTheme = (mode: 'dark' | 'light', dir: 'rtl' | 'ltr' = 'ltr') => {
  const themeOptions = LightThemeColors.find(theme => theme.name === mode)
  const darkthemeOptions = DarkThemeColors.find(theme => theme.name === mode)
  //const customizer = useSelector((state: AppState) => state.customizer);
  const defaultTheme = mode === 'dark' ? baseDarkTheme : baselightTheme
  const defaultShadow = mode === 'dark' ? darkshadows : shadows
  const themeSelect = mode === 'dark' ? darkthemeOptions : themeOptions
  const baseMode = {
    palette: {
      mode: mode,
    },
    shape: {
      //borderRadius: customizer.borderRadius,
    },
    shadows: defaultShadow,
    typography: typography,
  }
  const theme = createTheme(
    deepMerge({}, baseMode, defaultTheme, locales, themeSelect, {
      direction: dir,
    }),
  )
  // @ts-ignore
  theme.components = components(theme)

  return theme
}

// const ThemeSettings = () => {
//   const activDir = useSelector((state: AppState) => state.customizer.activeDir);
//   const activeTheme = useSelector((state: AppState) => state.customizer.activeTheme);
//   const theme = BuildTheme({
//     direction: activDir,
//     theme: activeTheme,
//   });
//   useEffect(() => {
//     document.dir = activDir;
//   }, [activDir]);
//
//   return theme;
// };
//
// export { ThemeSettings };
