import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from './index'

export const useEmailTemplatesQuery = (companyId: string, gameId: string) => {
  return useQuery({
    queryKey: ['email-templates', companyId, gameId],
    select: data => data?.data,
    queryFn: () => dashboardClient.v1.getEmailTemplates(companyId, gameId),
  })
}
