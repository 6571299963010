import { ReactNode } from 'react'
import styled from '@emotion/styled'

const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const LabelBox = styled.div`
  width: 280px;
  color: ${p => p.theme.palette.text.secondary};
  font-size: 14px;
  font-weight: 500;
`

const ContentBox = styled.div`
  width: 62%;
  display: flex;
  flex-direction: column;
`

export const Settings = ({ children, text, gap }: { children: ReactNode; text: string; gap?: boolean }) => {
  return (
    <Root>
      <LabelBox>{text}</LabelBox>
      <ContentBox style={{ gap: gap ? '12px' : '' }}>{children}</ContentBox>
    </Root>
  )
}
