import { useLocalStorage } from 'react-use'
import { useTranslation } from 'react-i18next'
import { InfoCircle } from '../../../components/icons'
import { Callout } from '../../../components/ui'

export const WebhookDiscoverCallout = () => {
  const [visibleCallout, setVisibleCallout] = useLocalStorage('@aghanim/webhook-discover-callout', true)
  const { t } = useTranslation()

  const onClickClose = () => {
    setVisibleCallout(false)
  }

  if (!visibleCallout) {
    return null
  }

  return (
    <div className="mb-2.5">
      <Callout
        icon={InfoCircle}
        action={
          <a href="https://docs.aghanim.com/webhooks/overview" target="_blank">
            {t('webhook.discover.learn-more')}
          </a>
        }
        onClose={onClickClose}
      >
        {t('webhook.discover.label')}
      </Callout>
    </div>
  )
}
