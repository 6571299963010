import { SelectProps } from '../Select'
import { Button, ButtonVariant } from '../Button'
import { Floating } from '../Floating'
import { Menu, MenuOption } from '../Menu'
import { Placement } from '@floating-ui/react'
import { ReactNode, useId } from 'react'

interface FilterButtonProps extends SelectProps {
  children?: ReactNode
  variant?: ButtonVariant
  placement?: Placement
  className?: string
}

interface CMenuProps extends FilterButtonProps {
  onClose?: () => void
}

const CMenu = (props: CMenuProps) => {
  const id = useId()
  return (
    <Menu style={{ maxWidth: 'none', width: props.dropDownWidth }}>
      {props.options.map(option => (
        <MenuOption
          key={`menu-option-${id}-${option?.value}`}
          {...option}
          onClick={() => {
            props.onClose?.()
            props.onChange(option?.value)
          }}
        >
          {option?.children}
        </MenuOption>
      ))}
    </Menu>
  )
}

export const MenuButton = (props: FilterButtonProps) => {
  const { children, variant, className, style, placement, dropDownWidth, options, onChange, disabled = false } = props

  return (
    <Floating
      menu={<CMenu options={options} dropDownWidth={dropDownWidth} onChange={onChange} />}
      placement={placement}
      referenceWidth={true}
    >
      <Button variant={variant} style={style} disabled={disabled} className={className}>
        {children}
      </Button>
    </Floating>
  )
}
