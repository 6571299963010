import { createElement, useCallback, useEffect, useMemo, useState } from 'react'
import {
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  ElementFormatType,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import { AlignCenter, AlignLeft, AlignRight } from '../../../icons'
import { Floating } from '../../../ui'
import { ToolbarAlignMenu } from './ToolbarAlignMenu'
import { ToolbarButton } from './ToolbarButton'
import { getSelectedNode } from '../../libs'
import { mergeRegister } from '@lexical/utils'

interface ToolbarAlignButtonProps {
  editor: LexicalEditor
}

const alignIconMap = new Map([
  ['left', AlignLeft],
  ['center', AlignCenter],
  ['right', AlignRight],
])

export const ToolbarAlignButton = ({ editor }: ToolbarAlignButtonProps) => {
  const [elementFormat, setElementFormat] = useState<ElementFormatType>('left')

  const alignIcon = useMemo(() => alignIconMap.get(elementFormat) || AlignLeft, [elementFormat])

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection)
      setElementFormat($isElementNode(node) ? node.getFormatType() : node.getParent()?.getFormatType() || 'left')
    }
  }, [editor])

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        $updateToolbar()
        return false
      },
      COMMAND_PRIORITY_CRITICAL,
    )
  }, [editor, $updateToolbar])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar()
        })
      }),
    )
  }, [$updateToolbar, editor])

  return (
    <Floating menu={<ToolbarAlignMenu editor={editor} />} placement="bottom-start">
      <ToolbarButton hasMenu={true}>{createElement(alignIcon, { size: 16 })}</ToolbarButton>
    </Floating>
  )
}
