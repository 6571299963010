import UserAvatar from '../../../components/UserAvatar'
import i18next from 'i18next'

interface UserAvNameProps {
  name: string
  email: string
  avatarUrl?: string
  isMe?: boolean
}

export default function UserAvName({ name, email, avatarUrl, isMe }: UserAvNameProps) {
  if (!name) {
    name = email.split('@')[0]
  }

  return (
    <table className="w-full">
      <tbody>
        <tr>
          <td className="pr-3">
            <UserAvatar name={name} url={avatarUrl} />
          </td>
          <td style={{ maxWidth: 0 }} className="w-full overflow-hidden font-[14px]">
            <div className="w-full truncate text-sm font-semibold text-text-secondary">
              {name}
              {isMe && (
                <div className="ml-1 inline-block rounded-md bg-fg-success-tertiary px-2 py-1 text-xs text-text-success-primary">
                  {i18next.t('users.you')}
                </div>
              )}
            </div>
            <div className="w-full truncate text-sm font-normal text-text-tertiary">{email}</div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
