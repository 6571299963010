import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Currency, ItemRead, ItemType } from '@/api/dashboard'
import { FieldDivider, FieldGroup, Input, Select, Textarea, ToastSeverity, useToast } from '@/ui'
import { useItemCategoriesQuery } from '../../../item-category/api'
import { prepareSLUG } from '@/util'
import { FormSection } from '../../components'
import { SkuFormMediaSection } from './SkuFormMediaSection'
import { SkuFormPropertiesSection } from './SkuFormPropertiesSection'
import { SkuFormSidebar } from './SkuFormSidebar'
import { SkuFormTopbar } from './SkuFormTopbar'
import { SkuFormPriceSection } from './SkuFormPriceSection'
import i18next from 'i18next'
import { getErrorText } from '@/api'
import { useEffect } from 'react'

export type SkuFormData = ItemRead

interface SkuFormProps {
  item?: ItemRead
  onSubmit: (data: SkuFormData) => Promise<SkuFormData>
}

export const SkuForm = ({ item, onSubmit }: SkuFormProps) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const methods = useForm<SkuFormData>({
    defaultValues: {
      name: '',
      description: '',
      currency: Currency.USD,
      price: 0,
      price_point: 0,
      sku: '',
      image_url: '',
      type: ItemType.Item,
      is_currency: false,
      is_stackable: false,
      quantity: 1,
      ...item,
    },
  })
  const showToast = useToast()
  const { data: categories = [] } = useItemCategoriesQuery(companyId, gameId)

  useEffect(() => {
    const firstError = Object.keys(methods.formState.errors)[0]

    if (firstError) {
      methods.setFocus(firstError as 'image_url')
    }
  }, [methods.formState.errors, methods.setFocus])

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    control,
    setError,
  } = methods

  const _onSubmit = handleSubmit(async data => {
    try {
      await onSubmit(data)
      reset(data)
    } catch (e) {
      if ((e as { status: number }).status == 409) {
        setError('sku', { message: i18next.t('sku.item.sku.error', { value: data.sku }) })
      } else {
        showToast({ message: getErrorText(e), severity: ToastSeverity.error })
      }
    }
  })

  return (
    <FormProvider {...methods}>
      <form className="mb-6" onSubmit={_onSubmit}>
        <SkuFormTopbar />
        <div className="container grid grid-cols-[auto_380px] gap-10">
          <div>
            <FormSection>
              <FieldGroup label={t('game-item-edit-dialog.name')}>
                <Input
                  {...register('name', {
                    required: t('validation.required'),
                    maxLength: {
                      message: t('validation.maxLength', {
                        field: t('game-item-edit-dialog.name'),
                        value: 255,
                      }),
                      value: 255,
                    },
                  })}
                  placeholder={t('sku.item.name.placeholder')}
                  autoFocus={true}
                  errors={errors}
                />
              </FieldGroup>

              <FieldGroup label={t('game-item-edit-dialog.description')}>
                <Textarea
                  {...register('description', {
                    maxLength: {
                      message: t('validation.maxLength', {
                        field: t('game-item-edit-dialog.description'),
                        value: 255,
                      }),
                      value: 255,
                    },
                  })}
                  placeholder={t('sku.item.description.placeholder')}
                  errors={errors}
                />
              </FieldGroup>

              <FieldDivider />

              <FieldGroup label={t('sku.item.sku')} tooltip={t('sku.sku-desc')}>
                <Controller
                  control={control}
                  name="sku"
                  rules={{
                    required: t('validation.required'),
                    maxLength: {
                      message: t('validation.maxLength', {
                        field: t('sku.item.sku'),
                        value: 100,
                      }),
                      value: 100,
                    },
                  }}
                  render={({ field }) => (
                    <Input {...field} errors={errors} onChange={e => field.onChange(prepareSLUG(e.target.value))} />
                  )}
                />
              </FieldGroup>

              <FieldGroup label={t('sku.item.category')}>
                <Controller
                  control={control}
                  name="categories"
                  render={({ field }) => (
                    <Select
                      options={[
                        {
                          value: '',
                          children: t('sku.item.no-category'),
                        },
                        ...categories.map(it => ({
                          value: it.id,
                          children: it.name,
                        })),
                      ]}
                      value={field.value ? field.value[0] : ''}
                      onChange={v => {
                        field.onChange(v ? [v] : null)
                      }}
                      placeholder={t('sku.item.select-category')}
                    />
                  )}
                />
              </FieldGroup>
            </FormSection>

            <SkuFormPriceSection />
            <SkuFormPropertiesSection />
            <SkuFormMediaSection />
          </div>

          <SkuFormSidebar />
        </div>
      </form>
    </FormProvider>
  )
}
