import { ItemOfferActionNode } from '@/api/dashboard'
import i18next from 'i18next'
import { DescContext } from './types'
import { Badge } from '@/ui'
import { getContextItemName } from '@/layouts/campaigns/blocks/descriptions/getContextItemName'

export function getDescVirtualSKUActionNode(n: ItemOfferActionNode, context: DescContext) {
  let benefits = ''

  if (n.bonus_percent) {
    benefits = `${n.bonus_percent}% ${i18next.t('campaign.virtual-sku-editor.bonus')}`
  }

  if (!benefits && n.discount_percent) {
    benefits = `${n.discount_percent}% ${i18next.t('campaign.virtual-sku-editor.discount')}`
  }

  if (n.use_event_item) {
    return [i18next.t('campaign.node-type.CreateVirtualSKUNode'), getContextItemName(context.type) + ' ' + benefits]
  }

  let item = context.items.find(it => it.id == n.item_id)
  if (item) {
    return [
      i18next.t('campaign.node-type.CreateVirtualSKUNode'),
      <>
        {item.name} {benefits} <Badge variant="gray-secondary">{item.sku}</Badge>
      </>,
    ]
  }
  return [n.title || i18next.t('campaign.node-type.CreateVirtualSKUNode'), benefits]
}
