import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { SegmentGroupReadStat, SegmentGroupUpdate } from '../../../api/dashboard'

interface SegmentGroupUpdateQuery {
  id: string
  update: SegmentGroupUpdate
}

/**
 * @param companyId
 * @param gameId
 */
export const useSegmentGroupsQuery = (companyId: string, gameId: string) => {
  return useQuery({
    queryKey: ['segment_groups', gameId],
    queryFn: () => dashboardClient.v1.getSegmentGroups(companyId, gameId).then(r => r.data),
  })
}

export const useSegmentGroupUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()

  const updateCacheFieldById = (id: string, updatedData: Partial<SegmentGroupReadStat>) => {
    queryClient.setQueryData<SegmentGroupReadStat[]>(['segment_groups', gameId], oldData => {
      if (!oldData) {
        return []
      }

      return oldData.map(segment => (segment.id === id ? { ...segment, ...updatedData } : segment))
    })
  }

  const mutate = useMutation({
    mutationFn: ({ id, update }: SegmentGroupUpdateQuery) =>
      dashboardClient.v1.updateSegmentGroup(companyId, gameId, id, update),
    onSuccess: data => {
      queryClient.setQueryData(['segment_groups', gameId], data.data)
      queryClient.invalidateQueries({ queryKey: ['segment_groups', gameId] })
    },
  })

  return { mutate, updateCacheFieldById }
}
