import { ReactNode } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import shouldForwardProp from '@emotion/is-prop-valid'

interface InputExtraSectionProps {
  children: ReactNode
  pointerEvents?: 'all' | 'none'
  side?: 'left' | 'right'
}

const StyledInputExtraSection = styled('div', { shouldForwardProp })<{
  $pointerEvents: InputExtraSectionProps['pointerEvents']
  $side: InputExtraSectionProps['side']
}>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 70%;
  height: 100%;

  ${p =>
    p.$pointerEvents === 'all' &&
    css`
      position: relative;
      z-index: 1;
    `}

  ${p =>
    p.$side === 'left' &&
    css`
      border-right: 1px solid #f1f5f9;
      padding-right: 12px;
      margin-right: 6px;
    `}

  ${p =>
    p.$side === 'right' &&
    css`
      border-left: 1px solid #f1f5f9;
      padding-left: 12px;
      margin-left: 6px;
    `}
`

export const InputExtraSection = ({ children, pointerEvents = 'none', side = 'left' }: InputExtraSectionProps) => {
  return (
    <StyledInputExtraSection $pointerEvents={pointerEvents} $side={side}>
      {children}
    </StyledInputExtraSection>
  )
}
