import { cn } from '../../../libs/cn'

export default function PayMethodView({
  paymentMethodName,
  logoUrl,
  className,
}: {
  paymentMethodName: string | undefined
  logoUrl?: string
  className?: string
}) {
  const default_image_url = `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/placeholder.png`

  return (
    <div className={cn('flex items-center gap-3', className)} data-testid={`pay-method-${paymentMethodName}`}>
      <img
        src={logoUrl || default_image_url}
        alt={paymentMethodName}
        onError={e => {
          ;(e.target as HTMLImageElement).src = default_image_url || ''
        }}
        className="h-[22px]"
      />
      {paymentMethodName}
    </div>
  )
}
