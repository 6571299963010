import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { LeaderboardCreate } from '../../../api/dashboard'

interface LeaderboardCreateMutate {
  create: LeaderboardCreate
}

export const useLeaderboardCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ create }: LeaderboardCreateMutate) =>
      dashboardClient.v1.createLeaderboard(companyId, gameId, create),
    onSuccess: data => {
      queryClient.setQueryData(['leaderboards', gameId], data.data)
      queryClient.invalidateQueries({ queryKey: ['leaderboards', gameId] })
    },
  })
}
