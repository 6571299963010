import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const FilterLines: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        id="Icon"
        d="M6 12H18M3 6H21M9 18H15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  )
})
