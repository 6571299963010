import { PlusIcon } from '../../../icons/Icons'
import { getLineColor } from './common'
import { useTheme } from '@mui/material/styles'
import { CSSProperties } from 'react'
import styled from '@emotion/styled'
import shouldForwardProp from '@emotion/is-prop-valid'
import { css } from '@emotion/react'

interface PlusButtonProps {
  id: string
  transform?: string
  onClick: (target: EventTarget & HTMLDivElement) => void
  color?: string
  style?: CSSProperties
  visible?: boolean
}

const Root = styled('div', { shouldForwardProp })<{ $visible: boolean; $color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${p => p.theme.palette.primary.main};
  border: 1px solid ${p => p.$color};
  transition:
    box-shadow 0.1s ease-in-out,
    width 0.1s ease-in-out,
    height 0.1s ease-in-out;
  &:hover {
    background-color: #fff;
    width: 24px;
    height: 24px;
    box-shadow: 0 1px 12px 0 ${p => p.$color};
  }

  ${p =>
    p.$visible &&
    css`
      background-color: #fff;
      width: 24px;
      height: 24px;
    `}
`

const PlusButton: React.FC<PlusButtonProps> = ({ id, style, transform, onClick, color, visible }) => {
  const theme = useTheme()
  let clr = color || getLineColor(theme)
  return (
    <Root
      $color={clr}
      $visible={!!visible}
      style={{
        transform: transform,
        ...style,
      }}
      id={id}
      onMouseDown={e => e.stopPropagation()}
      onClick={e => onClick(e.currentTarget)}
    >
      <PlusIcon style={{ color: color || theme.palette.primary.main }} />
    </Root>
  )
}

export default PlusButton
