import { Skeleton, ThemeProvider, styled } from '@mui/material'
import Box from '@mui/material/Box'
import { MAX_WIDTH } from '../Settings'
import DrawerSkeleton from './DrawerSkeleton'
import { useState } from 'react'
import { lightTheme } from '../App'
import { TextSkeleton } from './TextSkeleton'
import DashboardSkeleton from './dashboard/DashboardSkeleton'

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

export default function AppSkeleton() {
  const [theme] = useState(lightTheme)

  const renderHeader = () => {
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
        height={theme.spacing(7)}
        sx={{
          gap: theme.spacing(1.25),
          maxWidth: MAX_WIDTH,
        }}
      >
        <TextSkeleton variant="text" width="505px" sx={{ marginRight: 'auto' }} height={theme.spacing(3.5)} />

        <TextSkeleton variant="text" width="104px" height={theme.spacing(5)} />
        <TextSkeleton variant="text" width="104px" height={theme.spacing(5)} />
        <TextSkeleton variant="text" width="104px" height={theme.spacing(5)} />
        <Skeleton
          sx={{
            transform: 'none',
            borderRadius: '20px',
            backgroundColor: theme.palette.background.fgskeleton,
          }}
          width={theme.spacing(5)}
          height={theme.spacing(5)}
        />
        <Skeleton
          sx={{
            transform: 'none',
            borderRadius: '20px',
            backgroundColor: theme.palette.background.fgskeleton,
          }}
          width={theme.spacing(5)}
          height={theme.spacing(5)}
        />
      </Box>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'row',
          backgroundColor: theme.palette.background.content,
        }}
      >
        <DrawerSkeleton />
        <Box
          flexGrow={1}
          sx={{
            overflowY: 'auto',
            padding: theme.spacing(0, 2.5),
          }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100%"
        >
          {renderHeader()}
          <Box
            sx={{
              maxWidth: MAX_WIDTH,
              width: '100%',
            }}
          >
            <DashboardSkeleton />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
