import { HTMLAttributes, ReactNode } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '../../../libs/cn'

// https://www.figma.com/design/tvFgz5Bw27xiNy5PdjhP3Q/Dashboard-UI-Kit-%E2%80%93-Atoms?node-id=16963-164152&m=dev

const badgeVariants = cva('inline-flex items-center gap-3 transition-colors', {
  variants: {
    variant: {
      /**
       * Gray
       */
      'gray-primary': 'bg-fg-gray-primary text-text-gray-primary',
      'gray-secondary': 'bg-fg-gray-secondary text-text-gray-primary',
      'gray-tertiary': 'bg-fg-gray-tertiary text-text-gray-primary',
      'outline-secondary': 'border border-border-secondary text-text-gray-primary',

      /**
       * Brand blue
       */
      'brand-blue-primary': 'bg-brand-300 text-text-brand-solid',
      'brand-blue-secondary': ' bg-fg-brand-secondary text-text-brand-solid',
      'brand-blue-tertiary': 'bg-fg-brand-tertiary text-text-brand-solid',
      /**
       * Brand pink
       */
      'brand-pink-primary': 'bg-fg-pink-primary text-text-pink-solid',
      'brand-pink-secondary': 'bg-fg-pink-secondary text-text-pink-solid',
      'brand-pink-tertiary': 'bg-fg-pink-tertiary text-text-pink-solid',
      /**
       * Green
       */
      'green-primary': 'bg-fg-success-primary text-text-success-solid',
      'green-secondary': 'bg-fg-success-secondary text-text-success-solid',
      'green-tertiary': 'bg-fg-success-tertiary text-text-success-solid',
      /**
       * Orange
       */
      'orange-primary': 'bg-fg-warning-primary text-text-warning-solid',
      'orange-secondary': 'bg-fg-warning-secondary text-text-warning-solid',
      'orange-tertiary': 'bg-fg-warning-tertiary text-text-warning-solid',
      /**
       * Red
       */
      'red-primary': 'bg-fg-error-primary text-text-error-solid',
      'red-secondary': 'bg-fg-error-secondary text-text-error-solid',
      'red-tertiary': 'bg-fg-error-tertiary text-text-error-solid',
      /**
       * Lime
       */
      'lime-primary': 'bg-fg-lime-primary text-text-lime-solid',
      'lime-secondary': 'bg-fg-lime-secondary text-text-lime-solid',
      'lime-tertiary': 'bg-fg-lime-tertiary text-text-lime-solid',

      'violet-secondary': 'bg-fg-violet-secondary text-text-violet-solid',
    },
    size: {
      sm: 'h-5 gap-0.5 rounded-md px-1.5 text-caption-sm',
      md: 'h-6 gap-1 rounded-md px-2 text-caption-md',
    },
  },
})

export interface BadgeProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
  children: ReactNode
}

export const Badge = ({ children, variant = 'gray-primary', size = 'md', ...rest }: BadgeProps) => (
  <div {...rest} className={cn(badgeVariants({ variant, size }), rest.className)}>
    {children}
  </div>
)
