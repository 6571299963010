import { CSSProperties } from 'react'

export function BranchRight(props: { style?: CSSProperties; animate?: boolean }) {
  return (
    <svg width="184" height="191" viewBox="-1 0 188 191" fill="none" style={props.style}>
      <path
        style={
          props.animate
            ? {
                animation: 'animate_svg_branch 5s linear infinite',
                strokeDasharray: 5,
                strokeWidth: 2,
              }
            : { strokeWidth: 2 }
        }
        d="M1.00001 1V84.4136C1.00001 93.2502 8.16346 100.414 17 100.414H90H163C171.837 100.414 179 107.577 179 116.414V189"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M185.404 180L179.202 185.969L173 180"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ strokeWidth: 2 }}
      />
    </svg>
  )
}
