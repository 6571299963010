import { Theme } from '@mui/material/styles'

export function getImageBorderRadius(theme: Theme) {
  return theme.spacing(1)
}

export function getTableIconStyle(theme: Theme) {
  return {
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    borderRadius: getImageBorderRadius(theme),
  }
}

export function getTypoInCellStyle() {
  return { width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }
}
