import { useContext, useEffect, useMemo, useState } from 'react'
import i18next from 'i18next'
import { StyledInputLabel } from '../../../components/StyledTextField'
import { EMPTY_VALUE } from '../expr/types'
import { Box, useTheme } from '@mui/material'
import { BrowserPopupNotificationActionNode, Node, ResourceState } from '../../../api/dashboard'
import { BlockError } from '../types'

import {
  TEMPLATE_PROPS,
  findPrevEntityNode,
  getMergedPreviewProps,
  getPreviewHubItemParams,
  renderTitleBodyProps,
} from '../util'
import { InputVars } from '../components/InputVars/InputVars'
import { SelectSkuItem } from '../components/SelectSkuItem/SelectSkuItem'
import { useParams } from 'react-router-dom'
import { useWebsitesQuery } from '../../../api/useWebsitesQuery'
import { CampaignContext, ICampaignContext } from '../Context'
import { useStoresQuery } from '@/layouts/store/api'
import { useStoreItemsQuery } from '@/layouts/store/api/useStoreItemsQuery'
import { FieldGroup, FieldValidationMessage, Select, Tab, Tabs } from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import SettingsHeader from '@/layouts/campaigns/SettingsHeader'
import { DEFAULT_LOAD_LIMIT, useGameItems } from '@/api/useGameItems'

let updateId: ReturnType<typeof setTimeout> | null = null

export default function HubPopupNodeEditor(props: {
  node: BrowserPopupNotificationActionNode
  error?: BlockError | null
  setNode: (node: Node) => void
  onClose: () => void
}) {
  const context = useContext(CampaignContext) as ICampaignContext
  const theme = useTheme()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)
  const { data: items = [] } = useGameItems(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })
  const { data: stores = [] } = useStoresQuery(companyId, gameId, {
    limit: 100,
  })

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    control,
    getValues,
  } = useForm<BrowserPopupNotificationActionNode>({
    values: {
      ...props.node,
    },
  })

  const link_created_offer = watch('link_created_offer')
  const item_id = watch('item_id')
  const title = watch('title')
  const body = watch('body')
  const store_item_id = watch('store_item_id')
  const store_id = watch('store_id')

  const { data: storeItems = [] } = useStoreItemsQuery(companyId, gameId, store_id!)
  const [fromStore, setFromStore] = useState(!!store_id)
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (!websites.length) {
      return
    }

    if (updateId) {
      clearTimeout(updateId)
    }

    updateId = setTimeout(() => {
      let baseWebSiteUrl = websites[0].url

      if (window.location.href.startsWith('http://localhost')) {
        baseWebSiteUrl = 'http://localhost:3001'
      }

      let params: string[] = []
      //@ts-ignore
      let { merged_props, item, prev_node } = getMergedPreviewProps(getValues(), context, storeItems)

      if (item) {
        params.push(
          ...getPreviewHubItemParams(
            item,
            prev_node,
            storeItems?.find(it => it.id === store_item_id),
          ),
        )
      }

      if (title) {
        params.push(`title=${renderTitleBodyProps(title, merged_props)}`)
      }
      if (body) {
        params.push(`body=${renderTitleBodyProps(body, merged_props)}`)
      }

      let searchParams = params.join('&')
      setUrl(`${baseWebSiteUrl}/preview/popup?${searchParams}`)
    }, 1500)
  }, [item_id, websites, title, body, link_created_offer, store_item_id, store_id])

  const prevEntityNode = useMemo(
    () => findPrevEntityNode(context.graph.nodes!, props.node.id),
    [context.graph.nodes, props.node.id],
  )

  const onSaveClick = (data: BrowserPopupNotificationActionNode) => {
    props.setNode(data as Node)
    props.onClose()
  }

  let typeItem = EMPTY_VALUE
  if (item_id || store_id) {
    typeItem = 'item'
  } else if (link_created_offer && prevEntityNode) {
    typeItem = 'offer'
  }

  return (
    <div>
      <SettingsHeader onSave={handleSubmit(onSaveClick)} onClose={props.onClose} node={props.node as Node} />

      <div className="flex flex-col gap-3 p-6">
        <InputVars
          value={title}
          onChange={e => {
            setValue('title', e.target.value)
          }}
          maxLength={50}
          items={[...TEMPLATE_PROPS.ITEM, ...TEMPLATE_PROPS.COUPON]}
          label={i18next.t('campaign.block.EmailActionNode.title')}
        />

        <InputVars
          maxLength={200}
          value={body}
          onChange={e => {
            setValue('body', e.target.value)
          }}
          items={[...TEMPLATE_PROPS.ITEM, ...TEMPLATE_PROPS.COUPON]}
          label={i18next.t('campaign.block.EmailActionNode.body')}
        />

        <FieldGroup label={i18next.t('campaign.block.Communication.attach-item')}>
          <Select
            value={typeItem}
            onChange={value => {
              switch (value) {
                case EMPTY_VALUE:
                  setValue('item_id', null as unknown as string)
                  setValue('store_item_id', null as unknown as string)
                  setValue('store_id', null as unknown as string, { shouldDirty: true })
                  setFromStore(false)
                  break
                case 'item':
                  setValue('link_created_offer', false)
                  setValue('item_id', items.length > 0 ? items[0].id : '')
                  setValue('store_item_id', null as unknown as string)
                  setValue('store_id', null as unknown as string, { shouldDirty: true })
                  break
                case 'offer':
                  setValue('link_created_offer', true)
                  setValue('item_id', null as unknown as string)
                  setValue('store_item_id', null as unknown as string)
                  setValue('store_id', null as unknown as string, { shouldDirty: true })
                  break
              }
            }}
            options={[
              { value: EMPTY_VALUE, children: i18next.t('campaign.block.Communication.none') },
              { value: 'item', children: i18next.t('campaign.block.Communication.item') },
              prevEntityNode
                ? {
                    value: 'offer',
                    children: i18next.t('campaign.block.Communication.link_created_offer'),
                  }
                : undefined,
            ]}
          />
        </FieldGroup>

        {typeItem == 'item' && (
          <>
            <Tabs>
              <Tab
                data-testid="tab-item-base"
                isActive={!fromStore}
                onClick={() => {
                  setFromStore(false)
                  setValue('store_item_id', null as unknown as string)
                  setValue('store_id', null as unknown as string, { shouldDirty: true })
                }}
              >
                Base item
              </Tab>
              <Tab
                data-testid="tab-item-store"
                isActive={fromStore}
                onClick={() => {
                  setFromStore(true)
                }}
              >
                From store
              </Tab>
            </Tabs>
            {fromStore && (
              <Controller
                name="store_id"
                control={control}
                rules={{ required: i18next.t('validation.required') }}
                render={({ field }) => (
                  <FieldGroup label={i18next.t('store.store')}>
                    <Select
                      value={field.value}
                      //onChange={v => field.onChange(v as string)}
                      onChange={v => {
                        setValue('store_id', v as string, { shouldDirty: true })
                        setValue('item_id', null as unknown as string, { shouldDirty: true })
                        setValue('store_item_id', null as unknown as string, { shouldDirty: true })
                      }}
                      options={stores.map(s => ({
                        value: s.id,
                        children: s.name,
                      }))}
                    />
                    <ErrorMessage
                      name="store_id"
                      errors={errors}
                      render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                    />
                  </FieldGroup>
                )}
              />
            )}
            <Controller
              name="item_id"
              control={control}
              rules={{ required: i18next.t('validation.required') }}
              render={({ field }) => (
                <>
                  <SelectSkuItem
                    customItemsList={store_id ? storeItems : undefined}
                    value={field.value}
                    onChange={v => {
                      field.onChange(v)
                      if (fromStore) {
                        setValue('store_item_id', storeItems.find(it => it.item.id === v)?.id)
                      }
                    }}
                  />
                  <ErrorMessage
                    name="item_id"
                    errors={errors}
                    render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                  />
                </>
              )}
            />
          </>
        )}

        <div>
          <StyledInputLabel
            style={{ marginTop: theme.spacing(2) }}
            label={i18next.t('campaign.block.BrowserPopupNotificationActionNode.preview')}
          />

          <Box
            height="630px"
            minWidth="300px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: '#fff',
              overflow: 'hidden',
              borderRadius: theme.spacing(0.5),
            }}
          >
            <iframe
              src={url}
              frameBorder={0}
              scrolling="no"
              style={{
                background: "url('/icons/waiter.svg') center center no-repeat #fff",
                transform: 'scale(0.9)',
                transformOrigin: '50%',
                overflowY: 'hidden',
                pointerEvents: 'none',
                height: '700px',
                width: '90%',
              }}
            />
          </Box>
        </div>
      </div>
    </div>
  )
}
