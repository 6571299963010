import { cn } from '../../../libs/cn'
import { CSSProperties } from 'react'

export interface PageBlockProps {
  children: React.ReactNode
  className?: string
  style?: CSSProperties
}

export default function Block(props: PageBlockProps) {
  return (
    <div
      style={props.style}
      className={cn('rounded-2xl1 border-2 border-border-primary bg-fg-primary p-12 shadow-xs', props.className)}
    >
      {props.children}
    </div>
  )
}
