import { HTMLAttributes } from 'react'
import { useKey } from 'react-use'
import { SpinnerAghanim } from '@/components/ui'
import { cn } from '@/libs'
import { ModalProps } from './types'

export const ModalRush = ({ children, isLoading, onClose, ...rest }: ModalProps & HTMLAttributes<HTMLDivElement>) => {
  useKey('Escape', onClose)

  return (
    <div
      className={cn(
        'relative m-auto flex size-full flex-col overflow-hidden rounded-t-2xl1 bg-fg-primary p-5',
        rest.className,
      )}
    >
      {isLoading ? (
        <div className="flex size-full items-center justify-center">
          <SpinnerAghanim />
        </div>
      ) : (
        children
      )}
    </div>
  )
}
