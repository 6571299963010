export default function Logo(props: { style?: React.CSSProperties }) {
  return (
    <svg
      width="106"
      height="106"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
    >
      <g filter="url(#filter0_dd_1074_78682)">
        <path
          d="M3 38.8C3 25.9188 3 19.4782 5.50685 14.5582C7.71194 10.2305 11.2305 6.71194 15.5582 4.50685C20.4782 2 26.9188 2 39.8 2H66.2C79.0812 2 85.5218 2 90.4418 4.50685C94.7695 6.71194 98.2881 10.2305 100.493 14.5582C103 19.4782 103 25.9188 103 38.8V65.2C103 78.0812 103 84.5218 100.493 89.4418C98.2881 93.7695 94.7695 97.2881 90.4418 99.4931C85.5218 102 79.0812 102 66.2 102H39.8C26.9188 102 20.4782 102 15.5582 99.4931C11.2305 97.2881 7.71194 93.7695 5.50685 89.4418C3 84.5218 3 78.0812 3 65.2V38.8Z"
          fill="url(#paint0_linear_1074_78682)"
        />
        <path
          d="M3 38.8C3 25.9188 3 19.4782 5.50685 14.5582C7.71194 10.2305 11.2305 6.71194 15.5582 4.50685C20.4782 2 26.9188 2 39.8 2H66.2C79.0812 2 85.5218 2 90.4418 4.50685C94.7695 6.71194 98.2881 10.2305 100.493 14.5582C103 19.4782 103 25.9188 103 38.8V65.2C103 78.0812 103 84.5218 100.493 89.4418C98.2881 93.7695 94.7695 97.2881 90.4418 99.4931C85.5218 102 79.0812 102 66.2 102H39.8C26.9188 102 20.4782 102 15.5582 99.4931C11.2305 97.2881 7.71194 93.7695 5.50685 89.4418C3 84.5218 3 78.0812 3 65.2V38.8Z"
          fill="white"
        />
        <path
          d="M3.5 38.8C3.5 32.3511 3.50039 27.5446 3.8117 23.7344C4.12253 19.93 4.74083 17.163 5.95235 14.7852C8.1095 10.5516 11.5516 7.1095 15.7852 4.95235C18.163 3.74083 20.93 3.12253 24.7344 2.8117C28.5446 2.50039 33.3511 2.5 39.8 2.5H66.2C72.6489 2.5 77.4554 2.50039 81.2656 2.8117C85.07 3.12253 87.837 3.74083 90.2148 4.95235C94.4484 7.1095 97.8905 10.5516 100.048 14.7852C101.259 17.163 101.877 19.93 102.188 23.7344C102.5 27.5446 102.5 32.3511 102.5 38.8V65.2C102.5 71.6489 102.5 76.4554 102.188 80.2656C101.877 84.07 101.259 86.837 100.048 89.2148C97.8905 93.4484 94.4484 96.8905 90.2148 99.0476C87.837 100.259 85.07 100.877 81.2656 101.188C77.4554 101.5 72.6489 101.5 66.2 101.5H39.8C33.3511 101.5 28.5446 101.5 24.7344 101.188C20.93 100.877 18.163 100.259 15.7852 99.0476C11.5516 96.8905 8.1095 93.4484 5.95235 89.2148C4.74083 86.837 4.12253 84.07 3.8117 80.2656C3.50039 76.4554 3.5 71.6489 3.5 65.2V38.8Z"
          stroke="black"
          strokeOpacity="0.04"
        />
        <mask id="mask0_1074_78682" maskUnits="userSpaceOnUse" x="3" y="2" width="100" height="100">
          <path
            d="M3.5 38.8C3.5 32.3511 3.50039 27.5446 3.8117 23.7344C4.12253 19.93 4.74083 17.163 5.95235 14.7852C8.1095 10.5516 11.5516 7.1095 15.7852 4.95235C18.163 3.74083 20.93 3.12253 24.7344 2.8117C28.5446 2.50039 33.3511 2.5 39.8 2.5H66.2C72.6489 2.5 77.4554 2.50039 81.2656 2.8117C85.07 3.12253 87.837 3.74083 90.2148 4.95235C94.4484 7.1095 97.8905 10.5516 100.048 14.7852C101.259 17.163 101.877 19.93 102.188 23.7344C102.5 27.5446 102.5 32.3511 102.5 38.8V65.2C102.5 71.6489 102.5 76.4554 102.188 80.2656C101.877 84.07 101.259 86.837 100.048 89.2148C97.8905 93.4484 94.4484 96.8905 90.2148 99.0476C87.837 100.259 85.07 100.877 81.2656 101.188C77.4554 101.5 72.6489 101.5 66.2 101.5H39.8C33.3511 101.5 28.5446 101.5 24.7344 101.188C20.93 100.877 18.163 100.259 15.7852 99.0476C11.5516 96.8905 8.1095 93.4484 5.95235 89.2148C4.74083 86.837 4.12253 84.07 3.8117 80.2656C3.50039 76.4554 3.5 71.6489 3.5 65.2V38.8Z"
            fill="url(#paint1_linear_1074_78682)"
            stroke="#DDE2EB"
          />
        </mask>
        <g mask="url(#mask0_1074_78682)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.0003 8.37138C28.9048 8.37138 9.37162 27.9046 9.37162 52C9.37162 76.0955 28.9048 95.6287 53.0003 95.6287C77.0957 95.6287 96.6289 76.0955 96.6289 52C96.6289 27.9046 77.0957 8.37138 53.0003 8.37138ZM9.12891 52C9.12891 27.7705 28.7708 8.12866 53.0003 8.12866C77.2298 8.12866 96.8716 27.7705 96.8716 52C96.8716 76.2295 77.2298 95.8714 53.0003 95.8714C28.7708 95.8714 9.12891 76.2295 9.12891 52Z"
            fill="#D0D5DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53 39.7435C46.2305 39.7435 40.7427 45.2312 40.7427 52.0007C40.7427 58.7702 46.2305 64.258 53 64.258C59.7695 64.258 65.2573 58.7702 65.2573 52.0007C65.2573 45.2312 59.7695 39.7435 53 39.7435ZM40.5 52.0007C40.5 45.0972 46.0964 39.5007 53 39.5007C59.9036 39.5007 65.5 45.0972 65.5 52.0007C65.5 58.9043 59.9036 64.5007 53 64.5007C46.0964 64.5007 40.5 58.9043 40.5 52.0007Z"
            fill="#D0D5DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.9993 43.6253C48.3746 43.6253 44.6255 47.3744 44.6255 51.9991C44.6255 56.6238 48.3746 60.3729 52.9993 60.3729C57.624 60.3729 61.3731 56.6238 61.3731 51.9991C61.3731 47.3744 57.624 43.6253 52.9993 43.6253ZM44.3828 51.9991C44.3828 47.2403 48.2406 43.3826 52.9993 43.3826C57.7581 43.3826 61.6158 47.2403 61.6158 51.9991C61.6158 56.7578 57.7581 60.6156 52.9993 60.6156C48.2406 60.6156 44.3828 56.7578 44.3828 51.9991Z"
            fill="#D0D5DD"
          />
          <path d="M52.8789 2H53.1216V102H52.8789V2Z" fill="#D0D5DD" />
          <path d="M103 51.8779L103 52.1206L3 52.1206L3 51.8779L103 51.8779Z" fill="#D0D5DD" />
          <path d="M86.1289 2H86.3716V102H86.1289V2Z" fill="#D0D5DD" />
          <path d="M36.252 2H36.4947V102H36.252V2Z" fill="#D0D5DD" />
          <path d="M69.5039 2H69.7466V102H69.5039V2Z" fill="#D0D5DD" />
          <path d="M19.625 2H19.8677V102H19.625V2Z" fill="#D0D5DD" />
          <path d="M103 85.1299L103 85.3726L3 85.3726L3 85.1299L103 85.1299Z" fill="#D0D5DD" />
          <path d="M103 35.252L103 35.4947L3 35.4947L3 35.2519L103 35.252Z" fill="#D0D5DD" />
          <path d="M103 68.5039L103 68.7466L3 68.7466L3 68.5039L103 68.5039Z" fill="#D0D5DD" />
          <path d="M103 18.626L103 18.8687L3 18.8687L3 18.626L103 18.626Z" fill="#D0D5DD" />
        </g>
        <path
          d="M65.0323 77.3121C61.3624 79.0926 57.2429 80.0899 52.8912 80.0899C48.5396 80.0899 44.42 79.0926 40.7501 77.3121H31.822C37.5516 82.1134 44.9386 85 53 85C61.0614 85 68.4447 82.1098 74.178 77.3121H65.0359H65.0323ZM77.9893 30.4485V40.0729C79.7699 43.7427 80.7671 47.8623 80.7671 52.214C80.7671 56.5656 79.7699 60.6852 77.9893 64.3551V73.5515C82.9792 67.7711 86 60.2355 86 52C86 43.7645 82.9829 36.2289 77.9893 30.4485ZM53 19C44.9386 19 37.5553 21.8902 31.822 26.6879H41.6712C45.1054 25.1757 48.8986 24.338 52.8912 24.338C56.8839 24.338 60.677 25.1757 64.1112 26.6879H74.1744C68.4447 21.8866 61.0578 19 52.9964 19H53ZM27.3688 31.2136C22.7597 36.8889 20 44.1235 20 52C20 59.8765 22.7597 67.1147 27.3688 72.7864V63.4303C25.8566 59.9962 25.0189 56.203 25.0189 52.2103C25.0189 48.2177 25.8566 44.4245 27.3688 40.9903V31.2064V31.2136Z"
          fill="#0097E4"
        />
        <path
          d="M74.0363 28.5047H55.7883L58.3594 33.6324H70.7254C70.9031 33.6324 71.0445 33.7774 71.0445 33.9515V58.9155L76.1722 69.131V30.6406C76.1722 29.4657 75.2112 28.5047 74.0363 28.5047ZM31.3176 28.5047C30.1426 28.5047 29.1816 29.4657 29.1816 30.6406V69.3123L34.3093 59.0859V33.9551C34.3093 33.7774 34.4544 33.636 34.6285 33.636H47.0633L49.6344 28.5083H31.3176V28.5047Z"
          fill="#FF6CAF"
        />
        <path
          d="M23.0919 77.3097L25.9766 74.425L30.5692 79.0175L27.6844 81.9023C26.4177 83.169 24.3612 83.169 23.0944 81.9023C21.8277 80.6356 21.8277 78.579 23.0944 77.3123L23.0919 77.3097Z"
          fill="#FDE047"
        />
        <path
          d="M78.0566 81.9609L75.1718 79.0761L79.7643 74.4836L82.6491 77.3683C83.9158 78.6351 83.9158 80.6916 82.6491 81.9583C81.3824 83.225 79.3259 83.225 78.0591 81.9583L78.0566 81.9609Z"
          fill="#FDE047"
        />
        <path
          d="M27.7501 22.4631L30.6348 25.3478L26.0423 29.9404L23.1575 27.0556C21.8908 25.7889 21.8908 23.7324 23.1575 22.4656C24.4243 21.1989 26.4808 21.1989 27.7475 22.4656L27.7501 22.4631Z"
          fill="#FDE047"
        />
        <path
          d="M82.7128 27L79.8281 29.8848L75.2355 25.2922L78.1203 22.4075C79.387 21.1407 81.4435 21.1407 82.7103 22.4075C83.977 23.6742 83.977 25.7307 82.7103 26.9975L82.7128 27Z"
          fill="#FDE047"
        />
        <path
          d="M76.093 73.1816L54.1063 29.3605C53.5188 28.1747 51.8289 28.1711 51.2378 29.3569L29.2728 73.178C28.7398 74.2442 29.5158 75.4953 30.7053 75.4953H74.6569C75.8464 75.4953 76.6188 74.2478 76.093 73.1816ZM67.5782 70.4401H37.6679C36.8556 70.4401 36.2971 70.5598 36.6561 69.8345L52.2496 38.4373C52.6485 37.6287 52.6666 37.5561 53.1054 38.4373L68.5574 69.8272C68.9164 70.5525 68.3905 70.4401 67.5819 70.4401H67.5782Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_1074_78682"
          x="0"
          y="0"
          width="106"
          height="106"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1074_78682" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1074_78682" result="effect2_dropShadow_1074_78682" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1074_78682" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_1074_78682" x1="53" y1="2" x2="53" y2="102" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#DDE2EB" />
        </linearGradient>
        <linearGradient id="paint1_linear_1074_78682" x1="53" y1="2" x2="53" y2="102" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#DDE2EB" />
        </linearGradient>
      </defs>
    </svg>
  )
}
