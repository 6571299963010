import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from './index'

export const usePaymentMethods = (companyId: string, gameId: string) =>
  useQuery({
    queryKey: ['payment_methods', gameId],
    queryFn: () => {
      return dashboardClient.v1
        .getPaymentMethods(companyId, gameId)
        .then(r => r.data)
        .catch(() => []) //service degraded
    },
    staleTime: 1000 * 60 * 30,
  })
