import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const RewardPoint: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 12 13" ref={ref}>
      <path
        d="M2.77947 8.065C4.40487 9.28404 5.31941 10.7745 5.6304 12.0238C5.67469 12.2017 5.82996 12.3337 6.01332 12.3337C6.19829 12.3337 6.35438 12.1991 6.3983 12.0194C6.53666 11.4532 6.80595 10.844 7.21524 10.1917C8.12815 8.75103 9.9117 7.33712 11.522 6.88071C11.7017 6.82978 11.8333 6.67021 11.8333 6.48342C11.8333 6.29819 11.7038 6.13934 11.5254 6.08982C10.7962 5.88753 10.0802 5.53484 9.38755 5.04199C7.88687 3.98019 6.75563 2.43022 6.39959 0.982283C6.35518 0.801661 6.19796 0.666992 6.01195 0.666992C5.82879 0.666992 5.67302 0.797765 5.62796 0.975302C5.45138 1.67105 5.12471 2.34814 4.64796 3.03678C3.63318 4.50894 2.08048 5.61801 0.47068 6.08563C0.293539 6.13709 0.166626 6.29625 0.166626 6.48071C0.166626 6.66921 0.299119 6.83043 0.480777 6.88075C1.24498 7.09244 2.0181 7.49063 2.77947 8.065Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
