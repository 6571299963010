import { Check, Copy03 } from '@/icons'
import { useEffect, useState } from 'react'

export function LabelCopyButton(props: { value: string }) {
  const [success, setSuccess] = useState(false)

  const onCopyClick = () => {
    setSuccess(true)
    navigator.clipboard.writeText(props.value)
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false)
      }, 2500)
    }
  }, [success, setSuccess])

  return (
    <div className="flex h-[33px] w-[443px] items-center rounded-md bg-fg-secondary-hover px-3 text-text-secondary">
      {props.value}
      {success ? (
        <Check className="ml-auto text-text-brand-primary" size={16} strokeWidth={3} />
      ) : (
        <Copy03 size={16} onClick={onCopyClick} className="ml-auto cursor-pointer" />
      )}
    </div>
  )
}
