import { useTheme } from '@mui/material/styles'
import { DRAWER_WIDTH } from '../Settings'
import Box from '@mui/material/Box'

import RoundedSkeleton from './RoundedSkeleton'
import { TextSkeleton } from './TextSkeleton'
import { Skeleton } from '@mui/material'

export default function DrawerSkeleton() {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: DRAWER_WIDTH,
        padding: theme.spacing(0, 1.5),
        borderRadius: 0,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box display="flex" alignItems="center" height={theme.spacing(7)}>
        <Skeleton
          sx={{
            transform: 'none',
            borderRadius: '20px',
            backgroundColor: theme.palette.background.fgskeleton,
          }}
          width={theme.spacing(5)}
          height={theme.spacing(5)}
        />

        <TextSkeleton
          variant="text"
          sx={{
            marginLeft: theme.spacing(1.25),
            borderRadius: theme.spacing(0.75),
            backgroundColor: theme.palette.background.fgskeleton,
          }}
          height="40px"
          width="183px"
        />
      </Box>

      <Box
        display="flex"
        gap={theme.spacing(0.25)}
        p={theme.spacing(1.25, 0)}
        mb={theme.spacing(2)}
        flexDirection="column"
      >
        <RoundedSkeleton variant="rounded" height={theme.spacing(4)} />

        <RoundedSkeleton variant="rounded" height={theme.spacing(4)} />

        <RoundedSkeleton variant="rounded" height={theme.spacing(4)} />

        <RoundedSkeleton variant="rounded" height={theme.spacing(4)} />

        <RoundedSkeleton variant="rounded" height={theme.spacing(4)} />

        <RoundedSkeleton variant="rounded" height={theme.spacing(4)} />

        <RoundedSkeleton variant="rounded" height={theme.spacing(4)} />

        <RoundedSkeleton variant="rounded" height={theme.spacing(4)} />
      </Box>

      <RoundedSkeleton variant="rounded" height={theme.spacing(4)} />
    </Box>
  )
}
