import { Box, Stack, Toolbar, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import i18next from 'i18next'
import { Link } from 'react-router-dom'
import { MAX_WIDTH, getAppBarHeight } from '../../Settings'
import { useEffect } from 'react'
import { initFeedbackIntegration } from '@/libs/sentry'
import { getLastLang } from '@/translates'

const Header = () => {
  const theme = useTheme()

  const styles = {
    root: {
      height: getAppBarHeight(theme),
      justifyContent: 'center',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    linkStyle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1.5),
      marginLeft: theme.spacing(1),
      fontWeight: '500',
      cursor: 'pointer',
    },
  }

  useEffect(() => {
    initFeedbackIntegration(undefined, getLastLang() || 'en')
  }, [])

  return (
    <Box sx={styles.root}>
      <Toolbar
        sx={{
          height: '100%',
          maxWidth: MAX_WIDTH,
          minHeight: getAppBarHeight(theme) + ' !important',
          width: '100%',
          paddingRight: '16px !important',
          paddingLeft: '8px !important',
        }}
      >
        <Box flexGrow={1} />
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography
            sx={styles.linkStyle}
            variant="body1"
            component={Link}
            target={'_blank'}
            to={'https://docs.aghanim.com/'}
          >
            {i18next.t('header.documentation')}
          </Typography>

          <Typography id="bug-report" sx={styles.linkStyle} variant="body1">
            {i18next.t('header.bug-report')}
          </Typography>
        </Stack>
      </Toolbar>
    </Box>
  )
}

export default Header
