import Box from '@mui/material/Box'
import { SxProps } from '@mui/system/styleFunctionSx'
import { Theme } from '@mui/material/styles'

export default function CenteredProgress(props: { sx?: SxProps<Theme> }) {
  return (
    <Box
      sx={{
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        ...props.sx,
      }}
    >
      <img src="/icons/waiter.svg" alt="Progress" />
    </Box>
  )
}
