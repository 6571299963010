import { GenerationProgressState } from '@/api/dashboard'

/**
 *
 * @param state
 * @param afterMs
 */
export const getGenerationProgressIsStuck = (state: GenerationProgressState, afterMs: number): boolean => {
  return state.start_at ? new Date(state.start_at * 1000) < new Date(Date.now() - afterMs) : false
}
