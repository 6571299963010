import { useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import i18next from 'i18next'
import TableBar from '../../components/TableBar'
import { AttrType, CustomAttribute } from '../../api/dashboard'
import EditGameAttrModal from './EditGameAttrModal'
import { AuthContext, IAuthContext } from '../../Context'
import { canEdit } from '../../security'
import { useGameSettingsQuery } from '../../api/useGameSettingsQuery'
import { useGameSettingsCreateMutate } from '../../api/useGameSettingsCreateMutate'
import { useGameSettingsUpdateMutate } from '../../api/useGameSettingsUpdateMutate'
import {
  Badge,
  Button,
  ConfirmDialog,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  useModal,
} from '../../components/ui'
import { EditIcon } from '../../icons/Icons'
import { Plus, Trash } from '../../components/icons'
import { capitalize } from '@mui/material'

export default function GameAttributeTable() {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const context = useContext(AuthContext) as IAuthContext
  const isReadonly = !canEdit(context.customer)
  const [deleteConfirmItem, setDeleteConfirmItem] = useState<CustomAttribute | undefined>(undefined)
  const { data: settings, isLoading } = useGameSettingsQuery(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useGameSettingsCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useGameSettingsUpdateMutate(companyId, gameId)

  const attributes = useMemo(() => settings?.player_attributes || [], [settings?.player_attributes])

  const onUpdateGameItemClick = async (attr: CustomAttribute) => {
    let items = attributes

    let existingIndex = items.findIndex(it => it.name === attr.name)

    let newItems = [...items]

    if (existingIndex > -1) {
      newItems[existingIndex] = attr
    } else {
      newItems.push(attr)
    }

    save(newItems)
  }

  const onDeleteGameItemClick = async () => {
    if (!settings?.player_attributes || !deleteConfirmItem) {
      return
    }

    let newItems = settings.player_attributes.filter(it => it !== deleteConfirmItem)

    save(newItems)
    setDeleteConfirmItem(undefined)
  }

  const save = async (newAttributes: CustomAttribute[]) => {
    if (!settings) {
      await createMutateAsync({
        data: { player_attributes: newAttributes },
      })
    } else {
      await updateMutateAsync({
        settingsId: settings.id,
        data: { ...settings, player_attributes: newAttributes },
      })
    }
  }

  const renderType = (type: AttrType) => {
    let typeStr = capitalize(type)
    switch (type) {
      case AttrType.String:
        return <Badge variant="orange-secondary">{typeStr}</Badge>
      case AttrType.Number:
        return <Badge variant="gray-primary">{typeStr}</Badge>
      case AttrType.Boolean:
        return <Badge variant="brand-pink-secondary">{typeStr}</Badge>
      case AttrType.Date:
        return <Badge variant="brand-blue-secondary">{typeStr}</Badge>
      case AttrType.List:
        return <Badge variant="lime-secondary">{typeStr}</Badge>
      default:
        return <Badge>{typeStr}</Badge>
    }
  }

  const openEditModal = useModal<{ item: CustomAttribute; isNew: boolean }>(props => (
    <EditGameAttrModal
      {...props}
      onSave={item => {
        onUpdateGameItemClick(item)
      }}
    />
  ))

  return (
    <div className="flex h-full flex-col">
      {attributes.length ? (
        <TableBar
          sticky
          onBtnClick={
            isReadonly || !attributes.length
              ? undefined
              : () =>
                  openEditModal({
                    item: {
                      name: '',
                      description: '',
                      type: AttrType.String,
                    },
                    isNew: true,
                  })
          }
          btnText={i18next.t('player.attr-table.add')}
          title={i18next.t('sidebar.players.attributes')}
        />
      ) : (
        <TableBar sticky title={i18next.t('sidebar.players.attributes')} />
      )}

      {deleteConfirmItem && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={i18next.t('remove')}
          subMessage={i18next.t('confirm.text', { name: deleteConfirmItem.name })}
          onCancel={() => setDeleteConfirmItem(undefined)}
          onConfirm={onDeleteGameItemClick}
        />
      )}

      {isLoading ? (
        <TableRowSkeleton rowCount={5} columnCount={2} />
      ) : attributes.length === 0 ? (
        <TableZeroState
          title={i18next.t('player.attr.zero.title')}
          message={i18next.t('player.attr.zero.message')}
          buttons={
            !isReadonly && (
              <Button
                variant="primary"
                onClick={() =>
                  openEditModal({
                    item: {
                      name: '',
                      description: '',
                      type: AttrType.String,
                    },
                    isNew: true,
                  })
                }
              >
                <Plus size={14} />
                <span>{i18next.t('player.attr.zero.button-add')}</span>
              </Button>
            )
          }
        />
      ) : (
        <>
          <Table>
            <TableRow variant="header">
              <TableCell>{i18next.t('player.attr-table.name')}</TableCell>
              <TableCell width="10%">{i18next.t('player.attr-table.type')}</TableCell>
              <TableCell width="10%" />
            </TableRow>
            <div>
              {attributes.map(it => (
                <TableRow key={it.name}>
                  <TableCell>{it.name}</TableCell>
                  <TableCell width="10%">{renderType(it.type)}</TableCell>

                  <TableCell width="10%">
                    <div className="text-right">
                      {!isReadonly && (
                        <TableRowEditButton
                          onChange={v => {
                            switch (v) {
                              case 'edit':
                                openEditModal({
                                  item: it,
                                  isNew: false,
                                })
                                break
                              case 'remove':
                                setDeleteConfirmItem(it)
                                break
                            }
                          }}
                          options={[
                            {
                              icon: <EditIcon />,
                              children: i18next.t('grid.edit'),
                              value: 'edit',
                            },
                            {
                              icon: (
                                <span className="text-text-error-primary">
                                  <Trash />
                                </span>
                              ),
                              children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                              value: 'remove',
                            },
                          ]}
                        />
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </div>
          </Table>
        </>
      )}
    </div>
  )
}
