import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/material'
import PlusButton from '../../blocks/PlusButton'
import { ADD_NEXT_PREFIX_ID } from '../../Settings'
import { ExitNode } from '../ExitNode/ExitNode'
import { useMemo } from 'react'
import { getLineColor, getWarningLineColor, hasNextNode } from '../../blocks/common'
import { IBlockProps } from '../../types'
import { calculateWarning } from '../../util'
import { ChevronDown } from '@/icons'

export interface NextBlockProps extends IBlockProps {
  warningText?: string
}

export function NextNode(props: NextBlockProps) {
  const theme = useTheme()

  const [warningText, warningDescText] =
    useMemo(() => {
      let warningText = props.warningText || ''

      if (warningText) {
        return [warningText, '']
      }

      return calculateWarning(props.data.node, props.data.graph)
    }, [props.data.graph]) || []

  if (hasNextNode(props.data.graph, props.data.node)) {
    return <></>
  }

  return (
    <Box
      component="span"
      sx={{
        position: 'absolute',
        top: '100%',
      }}
      width="69px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        sx={{
          width: '2px',
          backgroundColor: warningText ? getWarningLineColor(theme) : getLineColor(theme),
          height: '40px',
          top: `calc(100% + ${theme.spacing(1.5)})`,
        }}
      />

      <PlusButton
        id={ADD_NEXT_PREFIX_ID + props.data.node.id}
        style={{ position: 'relative', zIndex: 1 }}
        visible={true}
        onClick={props.data.addClick}
        color={warningText ? getWarningLineColor(theme) : getLineColor(theme)}
      />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '2px',
          height: '30px',
          marginBottom: theme.spacing(1),
          backgroundColor: warningText ? getWarningLineColor(theme) : getLineColor(theme),
          position: 'relative',
        }}
      >
        <ChevronDown
          style={{
            position: 'relative',
            top: '12px',
            color: warningText ? getWarningLineColor(theme) : getLineColor(theme),
          }}
        />
      </Box>

      <ExitNode warningText={warningText} warningDescText={warningDescText} />
    </Box>
  )
}
