import { useTranslation } from 'react-i18next'
import { State } from '@/api/dashboard'
import { Badge, BadgeProps } from '@/ui'

interface TranslationStatusBadgeProps {
  state: State
}

const variantStateStatusMap = new Map<State, BadgeProps['variant']>([
  [State.New, 'brand-blue-secondary'],
  [State.Fuzzy, 'orange-secondary'],
  [State.Done, 'gray-secondary'],
])

export const TranslationStatusBadge = ({ state }: TranslationStatusBadgeProps) => {
  const { t } = useTranslation()
  const variant = variantStateStatusMap.get(state)
  return (
    <Badge variant={variant} size="sm">
      {t(`localization.translation.status.${state}`)}
    </Badge>
  )
}
