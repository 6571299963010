import { AriaRole, useContext, useEffect, useState } from 'react'
import { User, UserPaymentMethod, UserUpdate } from '../../api/dashboard'
import { dashboardClient } from '../../api'
import { Link, generatePath, useParams } from 'react-router-dom'
import i18next from 'i18next'
import { PropColumn } from '../../components/TableProperty'
import { usePlayerSegmentQuery } from './api/usePlayerSegmentQuery'
import AnimatedDots from '../../components/animated-dots/AnimatedDots'
import UserCountry from './components/UserCountry'
import { DotsHorIcon } from '../../icons/Icons'
import { MenuButton, useModal } from '../../components/ui'
import SegmentBadge from '@/layouts/segment/components/SegmentBadge'
import { Country, Edit03, Email, GamePad, Segment, SlashCircle02, User as UserIcon } from '@/icons'
import { AuthContext } from '@/Context'
import { canEdit } from '@/security'
import EditPlayerModal from '@/layouts/player/widgets/EditPlayerModal'
import { usePlayerUpdateMutate } from '@/layouts/player/api'
import { LabelCopyButton } from '@/components/ui/LabelCopyButton/LabelCopyButton'
import BigPayMethodView from '@/layouts/player/components/BigPayMethodView'
import { BillingAddress } from '@/layouts/player/components/BillingAddress'
import { GAME_PLAYERS_DETAILS_PATH } from '@/libs'
import { useTranslation } from 'react-i18next'

export default function PlayerCard(props: { user: User; payLinkUserId?: string | undefined }) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const { mutateAsync } = usePlayerUpdateMutate(companyId, gameId)
  const context = useContext(AuthContext)
  const canUserEdit = canEdit(context?.customer)
  const [payMethods, setPayMethods] = useState<UserPaymentMethod[]>([])
  const { data: userSegments = [], isLoading: isSegmentLoading } = usePlayerSegmentQuery(
    companyId,
    gameId,
    props.user.id,
  )

  const loadData = async () => {
    try {
      if (props.payLinkUserId) {
        let { data } = await dashboardClient.v1.getUserPaymentMethods(props.payLinkUserId, companyId, gameId)
        setPayMethods(data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onUpdatePlayer = (data: UserUpdate) => {
    return mutateAsync({ userId: props.user.id, update: data })
  }

  const openEditPlayerModal = useModal(p => <EditPlayerModal item={props.user} {...p} />)

  useEffect(() => {
    loadData()
  }, [props.payLinkUserId, props.user])

  const onClickBan = () => {
    return onUpdatePlayer({ banned: !props.user.banned })
  }

  const renderPaymentMethods = () => {
    if (!props.payLinkUserId || !payMethods.length) {
      return <></>
    }
    return (
      <>
        <div className="my-4 border border-border-primary" />
        <div className="ph-no-capture">
          <div className="text-paragraph-lg font-semibold text-text-secondary">
            {i18next.t('transaction-details.saved-payment-methods')}
          </div>
          <div className="mt-3 flex gap-3">
            {payMethods.map(it => (
              <BigPayMethodView method={it} key={it.id} />
            ))}
          </div>
        </div>
      </>
    )
  }

  const renderGeneral = () => {
    return (
      <div className="flex items-center gap-6">
        <img
          src={props.user.avatar_url || '/icons/default-avatar.svg'}
          onError={e => ((e.target as HTMLImageElement).src = '/icons/default-avatar.svg')}
          alt="avatar"
          style={{ height: '98px', width: '98px', overflow: 'hidden', borderRadius: '100%' }}
        />

        <div className="flex flex-col gap-1">
          <div className="font-medium text-text-tertiary">{t('player.name')}</div>
          <div className="text-title-t3 font-semibold text-text-primary">
            <Link to={generatePath(GAME_PLAYERS_DETAILS_PATH, { companyId, gameId, userId: props.user.id })}>
              {props.user.email || props.user.name || props.user.player_id}
            </Link>
          </div>
        </div>
        <div className="ml-auto flex gap-2">
          {canUserEdit && (
            <MenuButton
              className="w-[38px]"
              onChange={v => {
                switch (v) {
                  case 'edit':
                    openEditPlayerModal()
                    break
                  case 'ban':
                    onClickBan()
                    break
                }
              }}
              options={[
                {
                  value: 'edit',
                  children: i18next.t('Edit'),
                  icon: <Edit03 />,
                },
                {
                  icon: <SlashCircle02 />,
                  value: 'ban',
                  children: i18next.t(props.user?.banned ? 'players.unban' : 'players.ban'),
                },
              ]}
            >
              <DotsHorIcon />
            </MenuButton>
          )}
        </div>
      </div>
    )
  }

  const renderUserProps = () => {
    let segments = (
      <div className="flex flex-wrap gap-1.5">
        {userSegments
          .filter(it => it.segment)
          .map(it => (
            <SegmentBadge key={it.segment_id} segment={it.segment!} />
          ))}
      </div>
    )

    let tableProps = [
      {
        icon: <UserIcon size={17} />,
        label: i18next.t('transaction-details.user-id'),
        value: <LabelCopyButton value={props.user.id} />,
      },
      {
        icon: <GamePad size={17} />,
        label: i18next.t('transaction-details.player-id'),
        value: <LabelCopyButton value={props.user.player_id} />,
      },
      {
        icon: <Country size={17} />,
        label: i18next.t('transaction-details.country'),
        value: <UserCountry country={props.user.country} />,
      },
      {
        icon: <Email size={17} />,
        label: i18next.t('transaction-details.email'),
        value: props.user.email,
      },
      {
        icon: <Segment size={17} />,
        label: i18next.t('transaction-details.segments'),
        value: isSegmentLoading ? <AnimatedDots /> : segments,
      },
    ] as PropColumn[]

    return (
      <div>
        <div className="text-paragraph-lg font-semibold text-text-tertiary">{i18next.t('player-details.title')}</div>
        <table className="ph-no-capture mt-4">
          <tbody>
            {tableProps
              .filter(it => !!it)
              .map((row, idx) => (
                <tr key={idx} className="h-[44px]">
                  <td className="w-[200px] align-middle text-caption-md font-semibold text-text-secondary">
                    <div className="flex h-full items-center gap-3">
                      {row.icon}
                      <div className="leading-tight">{row.label}</div>
                    </div>
                  </td>
                  <td className="w-[450px] whitespace-normal align-middle font-[14px]">
                    <div
                      className="flex h-full items-center font-medium text-text-secondary"
                      role={row.label as AriaRole}
                    >
                      {row.value}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="rounded-2xl border border-border-primary bg-fg-secondary p-6">
      {renderGeneral()}
      <div className="my-4 border border-border-primary" />
      {renderUserProps()}

      {props.payLinkUserId && <BillingAddress payMethods={payMethods} />}

      {renderPaymentMethods()}
    </div>
  )
}
