import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { FormProvider, useForm } from 'react-hook-form'
import { GeneralStage } from './GeneralStage'
import { SelectEventsStage } from './SelectEventsStage'
import { WebhookCreate, WebhookMethod } from '../../../../api/dashboard'

interface WebhookFormProps {
  onSubmit: (data: WebhookFormData) => Promise<void>
}

export type WebhookFormData = WebhookCreate

export type WebhookFormStage = 'general' | 'select-events'

export const WebhookForm = ({ onSubmit }: WebhookFormProps) => {
  const [stage, setStage] = useState<WebhookFormStage>('general')

  const formMethods = useForm<WebhookFormData>({
    defaultValues: {
      method: WebhookMethod.POST,
      url: '',
      description: '',
      events: [],
    },
  })

  return (
    <div className="flex size-full">
      <FormProvider {...formMethods}>
        <form className="flex size-full flex-col" onSubmit={formMethods.handleSubmit(onSubmit)}>
          <AnimatePresence initial={false}>
            {stage === 'general' && <GeneralStage setStage={setStage} />}
            {stage === 'select-events' && <SelectEventsStage setStage={setStage} />}
          </AnimatePresence>
        </form>
      </FormProvider>
    </div>
  )
}
