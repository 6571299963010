import { SelectSkuItem } from '@/layouts/campaigns/components/SelectSkuItem/SelectSkuItem'
import { Controller, useFormContext } from 'react-hook-form'
import { Block } from '@/api/dashboard'
import { FieldGroup, FieldValidationMessage, Select, Tab, Tabs } from '@/ui'
import { ErrorMessage } from '@hookform/error-message'
import i18next from 'i18next'
import { useStoresQuery } from '@/layouts/store/api'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStoreItemsQuery } from '@/layouts/store/api/useStoreItemsQuery'

const SelectSkuPanel = () => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: stores = [] } = useStoresQuery(companyId, gameId, {
    limit: 100,
  })

  const {
    watch,
    setValue,
    formState: { errors },
    control,
  } = useFormContext<Block>()

  const storeId = watch('store_id')

  const { data: storeItems = [] } = useStoreItemsQuery(companyId, gameId, storeId!)

  const [fromStore, setFromStore] = useState(!!storeId)

  return (
    <>
      <Tabs>
        <Tab
          data-testid="tab-item-base"
          isActive={!fromStore}
          onClick={() => {
            setFromStore(false)
            setValue('item_id', null as unknown as string)
            setValue('store_item_id', null as unknown as string)
            setValue('store_id', null as unknown as string)
          }}
        >
          {i18next.t('select.sku.base')}
        </Tab>
        <Tab
          data-testid="tab-item-store"
          isActive={fromStore}
          onClick={() => {
            setFromStore(true)
            setValue('item_id', null as unknown as string)
            setValue('store_item_id', null as unknown as string)
            setValue('store_id', null as unknown as string)
          }}
        >
          {i18next.t('select.sku.from-store')}
        </Tab>
      </Tabs>

      {fromStore && (
        <Controller
          name="store_id"
          control={control}
          rules={{ required: i18next.t('validation.required') }}
          render={({ field }) => (
            <FieldGroup label={i18next.t('store.store')}>
              <Select
                value={field.value}
                onChange={v => field.onChange(v as string)}
                options={stores.map(s => ({
                  value: s.id,
                  children: s.name,
                }))}
              />
              <ErrorMessage
                name="store_id"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </FieldGroup>
          )}
        />
      )}

      <Controller
        name="item_id"
        control={control}
        rules={{ required: i18next.t('validation.required') }}
        render={({ field }) => (
          <>
            <SelectSkuItem
              customItemsList={storeId ? storeItems : undefined}
              value={field.value}
              onChange={v => {
                field.onChange(v)
                if (fromStore) {
                  setValue('store_item_id', storeItems.find(it => it.item.id === v)?.id)
                }
              }}
            />
            <ErrorMessage
              name="item_id"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </>
        )}
      />
    </>
  )
}

export default SelectSkuPanel
