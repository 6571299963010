import { CreateVirtualSKUNode, StoreItemRead } from '@/api/dashboard'
import { KeyValue } from '@/types'

let FIELDS_FOR_CLEAN = [
  'max_purchases',
  'bonus_percent',
  'discount_percent',
  'reward_points_percent',
  'rotation_weight',
]

export const clearStoreItemEmptyFields = (data: CreateVirtualSKUNode | StoreItemRead, isStackable: boolean) => {
  let obj = data as unknown as KeyValue

  for (let field of FIELDS_FOR_CLEAN) {
    if (!obj[field]) {
      obj[field] = null
    }
  }

  if (!isStackable) {
    data.bonus_percent = null as unknown as undefined
  }
}
