import i18next from 'i18next'
import { useParams } from 'react-router-dom'
import { LeaderboardRead } from '../../api/dashboard'
import { useLeaderboardUpdateMutate } from './api/useLeaderboardUpdateMutate'
import { useLeaderboardCreateMutate } from './api/useLeaderboardCreateMutate'
import { useState } from 'react'
import { Button, FieldGroup, FieldValidationMessage, Input } from '../../components/ui'
import { useForm } from 'react-hook-form'
import DialogHeader from '../../components/ui/Dialog/DialogHeader'
import { ResponseError, getErrorText } from '../../api'

import { ErrorMessage } from '@hookform/error-message'
import Modal from '../../components/ui/Dialog/Modal'
import { InputCopyButton } from '@/components/ui/InputCopyButton'

const LeaderBoardDialog = (props: { onClose: () => void; item: LeaderboardRead }) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const [helperText, setHelperText] = useState('')
  const { mutateAsync: updateMutateAsync } = useLeaderboardUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useLeaderboardCreateMutate(companyId, gameId)

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LeaderboardRead>({
    defaultValues: {
      ...props.item,
    },
  })

  const saveClick = async (data: LeaderboardRead) => {
    setHelperText('')
    try {
      if (props.item?.id) {
        await updateMutateAsync({
          id: data.id,
          update: data,
        })
        props.onClose()
      } else {
        await createMutateAsync({
          create: data,
        })
      }
      props.onClose()
    } catch (e) {
      setHelperText(getErrorText(e as ResponseError))
    }
  }

  return (
    <Modal onClose={props.onClose}>
      <DialogHeader
        title={i18next.t(props.item.id ? 'edit-leaderboard-dialog.title.edit' : 'leaderboard.add-button')}
      />

      <div className="mt-10">
        {props.item.id && (
          <FieldGroup label={i18next.t('leaderboard.id')}>
            <InputCopyButton
              value={props.item.id}
              onChange={e => {
                e.target.value = props.item.id
              }}
            />
          </FieldGroup>
        )}

        <FieldGroup label={i18next.t('leaderboard.name')}>
          <Input autoFocus {...register('name', { required: i18next.t('validation.required') })} />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('leaderboard.description')}>
          <Input {...register('description', { required: i18next.t('validation.required') })} />
          <ErrorMessage
            name="description"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        {helperText && <FieldValidationMessage>{helperText}</FieldValidationMessage>}
      </div>

      <div className="mt-10 flex justify-end gap-4">
        <Button size="lg" onClick={() => props.onClose()}>
          {i18next.t('Cancel')}
        </Button>
        <Button size="lg" variant="primary" onClick={handleSubmit(saveClick)}>
          {i18next.t(props.item.id ? 'Save2' : 'Add')}
        </Button>
      </div>
    </Modal>
  )
}

export default LeaderBoardDialog
