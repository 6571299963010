import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

/**
 *
 * @param companyId
 * @param query
 */
export const useInviteQuery = (companyId: string) => {
  return useQuery({
    queryKey: ['invite', companyId],
    queryFn: () => dashboardClient.v1.getCompanyInvites(companyId).then(r => r.data),
  })
}
