import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../../icons'

export const SkuIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5134 2.40395L19.9469 5.86101C19.957 5.86643 19.967 5.87203 19.9769 5.8778C20.9221 6.42841 21.5 7.44271 21.5 8.5356V15.4644C21.5 16.5573 20.9221 17.5716 19.9769 18.1222C19.967 18.128 19.957 18.1336 19.9469 18.139L13.5134 21.5961C13.0445 21.8654 12.5222 22 12 22C11.4778 22 10.9555 21.8654 10.4866 21.5961L4.05306 18.139C4.04296 18.1336 4.03296 18.128 4.02305 18.1222C3.07785 17.5716 2.5 16.5573 2.5 15.4644V8.5356C2.5 7.44271 3.07785 6.42841 4.02305 5.8778C4.03296 5.87203 4.04296 5.86643 4.05306 5.86101L10.4866 2.40395C11.4245 1.86535 12.5756 1.86535 13.5134 2.40395ZM11.7693 10.9129C11.9139 10.9881 12.0861 10.9881 12.2307 10.9129L17.9436 7.94222C18.2999 7.75692 18.3034 7.2483 17.9496 7.05818L12.5531 4.15837C12.543 4.15294 12.533 4.14734 12.5231 4.14157C12.199 3.95281 11.801 3.95281 11.4769 4.14157C11.467 4.14734 11.457 4.15294 11.4469 4.15837L5.0174 7.61328C4.69949 7.80433 4.5 8.15348 4.5 8.5356V15.4644C4.5 15.8465 4.6995 16.1957 5.01741 16.3867L10.2633 19.2056C10.5964 19.3846 11 19.1433 11 18.7652V13.0707C11 12.8841 10.8962 12.7131 10.7307 12.6271L6.53865 10.4472C6.04865 10.1924 5.85799 9.58864 6.11278 9.09865C6.36758 8.60865 6.97136 8.41798 7.46135 8.67278L11.7693 10.9129Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
