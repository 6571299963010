import { useParams } from 'react-router-dom'

import styled from '@emotion/styled'
import i18next from 'i18next'
import { useSegmentsQuery } from '../../segment/api/useSegmentsQuery'
import {
  FieldGroup,
  Select,
  SelectMultiple,
  SelectMultipleOption,
  SelectMultipleProps,
  SelectOption,
  SelectProps,
} from '../../../components/ui'
import { RefreshIcon } from '../../../components/icons'
import SegmentBadge from '@/layouts/segment/components/SegmentBadge'

// @ts-ignore
interface SelectSkuProps extends SelectProps {
  options?: SelectOption[]
  label?: string
  error?: string
  multiselect?: boolean
  value: string | string[] | undefined | null
  segmentGroupId?: string
  mode?: 'slug' | 'id'
  onChange: (value: SelectOption['value'] | SelectMultipleOption['value'][]) => void
}

const RefreshButton = styled.div`
  height: 16px;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const SelectSegment = (props: SelectSkuProps) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const {
    data: items = [],
    refetch,
    isFetching,
  } = useSegmentsQuery(companyId, gameId, {
    limit: 100,
    segment_group_id: props.segmentGroupId,
  })

  let visibleItems = items

  if (props.multiselect) {
    let selectedIds = props.value as string[]

    let addedGroups: string[] = []
    selectedIds.forEach(it => {
      let item = items.find(i => i.id == it)
      if (item && item.segment_group_id) {
        addedGroups.push(item.segment_group_id)
      }
    })

    visibleItems = items.filter(it => {
      if (it.segment_group_id && !selectedIds.includes(it.id)) {
        let hasSameGroup = addedGroups.includes(it.segment_group_id)

        if (hasSameGroup) {
          return false
        }
      }

      return true
    })
  }

  const updateClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    refetch()
    return false
  }

  let options = visibleItems.map(it => ({
    value: (props.mode == 'slug' ? it.slug : it.id) as string,
    children: <SegmentBadge segment={it} />,
  })) as SelectMultipleOption[]

  let action = (
    <RefreshButton
      onClick={updateClick}
      style={{
        animation: `${isFetching ? 'spin 2s linear infinite' : ''}`,
      }}
    >
      <RefreshIcon />
    </RefreshButton>
  )

  let content = props.multiselect ? (
    <SelectMultiple
      {...(props as SelectMultipleProps)}
      options={options}
      action={action}
      filter={(opt, search) => {
        let id = opt.value
        let item = visibleItems.find(it => (props.mode == 'slug' ? it.slug == id : it.id == id))
        return !!item?.name.toLowerCase().includes(search)
      }}
      placeholder={i18next.t('users.segments.select')}
    />
  ) : (
    <Select
      {...(props as SelectProps)}
      options={options}
      action={action}
      placeholder={i18next.t('users.segment.select')}
    />
  )

  if (!props.label) {
    return content
  }

  return (
    <FieldGroup label={props.label} error={props.error}>
      {content}
    </FieldGroup>
  )
}
