import { AghanimIcon } from '@/icons'
import { ReactNode, createElement } from 'react'

interface TranslationRowTypeProps {
  children: ReactNode
  icon: AghanimIcon | undefined
  label: ReactNode
}

export const TranslationRowType = ({ children, icon, label }: TranslationRowTypeProps) => {
  return (
    <div className="border-b border-border-secondary last:border-none">
      <div className="flex h-11 items-center gap-3 border-b border-border-secondary bg-fg-primary px-4">
        <div className="flex size-7 shrink-0 items-center justify-center rounded-md bg-fg-gray-primary text-text-tertiary">
          {icon && createElement(icon, { size: 18 })}
        </div>
        <div className="text-paragraph-sm font-semibold text-text-secondary">{label}</div>
      </div>
      {children}
    </div>
  )
}
