import { ReactNode, useState } from 'react'
import i18next from 'i18next'
import { Button, ButtonIcon, ButtonVariant, Modal, ModalFooter, ModalProps, ModalTitle } from '../index'
import { XClose } from '../../icons'

interface ConfirmDialogProps extends ModalProps {
  message?: ReactNode
  confirmButtonText: ReactNode
  cancelButtonText?: ReactNode
  subMessage?: ReactNode
  onCancel?: () => void
  onConfirm: () => void
  children?: ReactNode
  confirmEnabled?: boolean
  color?: ButtonVariant
  showClose?: boolean
}

export function ModalConfirm(props: ConfirmDialogProps) {
  const { ...rest } = props
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    setIsLoading(true)
    await props.onConfirm()
    rest.onClose?.()
  }

  const onCancel = async () => {
    props.onCancel?.()
    rest.onClose?.()
  }

  return (
    <Modal {...rest}>
      {props.showClose && (
        <ButtonIcon
          variant="secondary-gray"
          size="sm"
          onClick={rest.onClose}
          className="absolute right-[18px] top-[18px]"
        >
          <XClose size={14} />
        </ButtonIcon>
      )}
      <ModalTitle>{props.message || i18next.t('confirm.title')}</ModalTitle>
      {props.subMessage && <div className="text-paragraph-md text-text-secondary">{props.subMessage}</div>}
      {props.children}

      <ModalFooter>
        <Button size="lg" onClick={onCancel}>
          {props.cancelButtonText || i18next.t('Cancel')}
        </Button>
        <Button size="lg" variant={props.color} onClick={onSubmit} isLoading={isLoading}>
          {props.confirmButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
