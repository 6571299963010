import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import styled from '@emotion/styled'
import { CampaignSettings, CommunicationFrequency, PeriodType } from '../../../../api/dashboard'
import { FieldValidationMessage, Input, Select, Toggle } from '@/ui'
import { ErrorMessage } from '@hookform/error-message'
import i18next from 'i18next'

interface FrequencyCardProps {
  label: ReactNode
  desc: ReactNode
  field: string
}

const StyledFrequencyCard = styled.div`
  background-color: ${p => p.theme.palette.background.fgPrimary};
  color: ${p => p.theme.palette.text.primary};
  padding: ${p => p.theme.spacing(2.5)};
  border: 1px solid ${p => p.theme.palette.grey.borderPrimary};
  border-radius: 12px;
  transition:
    border-color ease-in-out 160ms,
    color ease-in-out 160ms;
`

const Header = styled.div`
  border-bottom: 1px solid ${p => p.theme.palette.grey.borderSecondary};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${p => p.theme.spacing(2.5)};
  margin-bottom: ${p => p.theme.spacing(2.5)};
  padding-bottom: ${p => p.theme.spacing(2.5)};
`

const Label = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.09px;
`

const Entry = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  gap: ${p => p.theme.spacing(2.5)};
  overflow: hidden;
`

const Desc = styled.div`
  color: ${p => p.theme.palette.text.secondary};
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.07px;
  max-width: 320px;
`

const FrequencyForm = styled.div`
  background-color: ${p => p.theme.palette.background.fgSecondary};
  padding: ${p => p.theme.spacing(2.5)};
  border-radius: 6px;
`

const FrequencyLabel = styled.div`
  color: ${p => p.theme.palette.text.secondary};
  margin-bottom: ${p => p.theme.spacing(2.5)};

  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.08px;
`

const FieldRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(2.25)};
`

const Divider = styled.div`
  color: ${p => p.theme.palette.text.tertiary};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.005em;
`

const defaultFrequencyValue: CommunicationFrequency = {
  period_type: PeriodType.Day,
  limit: 3,
}

export const FrequencyCard = ({ label, desc, field }: FrequencyCardProps) => {
  const { t } = useTranslation()

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<CampaignSettings>()

  const typesOptions = Object.values(PeriodType).map(value => ({
    children: t(`intl.${value}`, { count: 1 }),
    value: value,
  }))

  const frequency = watch(field as 'email_frequency')

  const onClickToggle = (v: boolean) => {
    setValue(
      field as 'email_frequency',
      v
        ? {
            ...defaultFrequencyValue,
          }
        : (null as unknown as undefined),
      { shouldDirty: true },
    )
  }

  const limitField = `${field}.limit` as 'email_frequency.limit'
  const selected = !!frequency?.period_type
  return (
    <StyledFrequencyCard>
      <Header>
        <Label>{label}</Label>
        <Toggle checked={selected} onChange={e => onClickToggle(e.target.checked)} />
      </Header>
      <Entry>
        <Desc>{desc}</Desc>
        <FrequencyForm>
          <FrequencyLabel>{t('campaign_settings.frequency.form.label')}</FrequencyLabel>
          <FieldRow>
            <Input
              disabled={!selected}
              {...register(limitField, {
                required: selected,
                min: { value: 1, message: i18next.t('campaign_settings.frequency.time-limit') },
                max: { value: 1000, message: i18next.t('campaign_settings.frequency.time-limit') },
              })}
              color={errors[field as 'email_frequency'] && 'error'}
              type="number"
              min={1}
            />
            <Divider>{t('campaign_settings.frequency.form.perDivider')}</Divider>
            <Controller
              control={control}
              name={`${field}.period_type` as 'email_frequency.period_type'}
              rules={{ required: selected }}
              render={({ field }) => <Select disabled={!selected} {...field} options={typesOptions} />}
            />
          </FieldRow>
          <ErrorMessage
            name={limitField}
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FrequencyForm>
      </Entry>
    </StyledFrequencyCard>
  )
}
