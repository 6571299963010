import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { dashboardClient } from '@/api'
import { Button, Modal, ModalFooter, ModalProps, ModalTitle } from '@/ui'
import { HOME_PATH, useGenerateCompanyPath } from '@/libs'
import { accountDetailsQuery } from '@/layouts/company'

interface VerifySubmittingModalProps extends ModalProps {}

export const VerifySubmittingModal = ({ ...rest }: VerifySubmittingModalProps) => {
  const ql = useQueryClient()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { companyId, generatePath } = useGenerateCompanyPath()
  const [isSubmitting, setIsSubmitting] = useState(true)

  useEffect(() => {
    setIsSubmitting(true)
    dashboardClient.v1.submitAccountDetailsForReview(companyId).then(async () => {
      await ql.refetchQueries({ queryKey: accountDetailsQuery(companyId).queryKey })
      setIsSubmitting(false)
    })
  }, [setIsSubmitting])

  const onClickGo = () => {
    rest.onClose?.()
    navigate(generatePath(HOME_PATH))
  }

  return (
    <Modal className="max-w-lg" isLoading={isSubmitting} {...rest}>
      <ModalTitle>{t('get-started.verify.submitting-modal.title')}</ModalTitle>
      <p className="text-paragraph-md text-text-secondary">{t('get-started.verify.submitting-modal.text')}</p>
      <ModalFooter>
        <Button variant="primary" size="lg" onClick={onClickGo}>
          {t('get-started.verify.submitting-modal.button')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
