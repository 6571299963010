import i18next from 'i18next'
import { ConfirmDialog } from '../../../components/ui'
import pluralize, { PluralForms } from '../../../libs/pluralize'

export default function ConfirmPublishDialog(props: {
  confirmPublish: boolean
  count: number
  onCancel: () => void
  onConfirm: () => void
  forms: PluralForms
}) {
  let entity = pluralize(props.count, props.forms, i18next.language)

  return (
    <ConfirmDialog
      color={!props.confirmPublish ? 'error' : 'primary'}
      message={i18next.t(!props.confirmPublish ? 'confirm.unpublish-n' : 'confirm.publish-n', {
        N: props.count,
        entity: entity,
      })}
      confirmButtonText={i18next.t(!props.confirmPublish ? 'unpublish' : 'publish', {
        N: props.count,
      })}
      subMessage={i18next.t(!props.confirmPublish ? 'confirm.unpublish-n.message' : 'confirm.publish-n.message', {
        entity: entity,
      })}
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
    />
  )
}
