import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { PageUpdate } from '../../../api/dashboard'

interface PageUpdateMutate {
  pageId: string
  update: PageUpdate
}

export const usePageUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ pageId, update }: PageUpdateMutate) =>
      dashboardClient.v1.updatePage(companyId, gameId, pageId, update),
    onSuccess: data => {
      queryClient.setQueryData(['pages', gameId], data.data)
      queryClient.invalidateQueries({ queryKey: ['pages', gameId] })
    },
  })
}
