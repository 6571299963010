import styled from '@emotion/styled'

interface PlayerAvatarProps {
  src: string | undefined
}

const StyledPlayerAvatar = styled.div`
  background-color: ${p => p.theme.palette.background.primary};
  width: ${p => p.theme.spacing(4.75)};
  height: ${p => p.theme.spacing(4.75)};
  display: inline-flex;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  background-color: transparent;
  display: block;
  width: 100%;
  height: 100%;
`

export const PlayerAvatar = ({ src }: PlayerAvatarProps) => {
  return (
    <StyledPlayerAvatar>
      <Image src={src || '/icons/default-avatar.svg'} />
    </StyledPlayerAvatar>
  )
}
