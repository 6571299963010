import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const Search: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21C13.7631 21 15.8415 20.2086 17.4733 18.8875L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L18.8875 17.4733C20.2086 15.8415 21 13.7631 21 11.5C21 6.25329 16.7467 2 11.5 2ZM4 11.5C4 7.35786 7.35786 4 11.5 4C15.6421 4 19 7.35786 19 11.5C19 15.6421 15.6421 19 11.5 19C7.35786 19 4 15.6421 4 11.5Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
