import './index.css'
import { useContext, useEffect, useRef, useState } from 'react'
import i18next from 'i18next'
import SaveButton from '../../components/SaveButton'
import { GameStoreType } from '@/types'
import { useTheme } from '@mui/material/styles'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import confetti from 'canvas-confetti'
import { AuthContext, IAuthContext } from '../../Context'
import Logo from '../../components/Logo'
import { SOCIAL_ICONS_MAP } from '../../icons/Icons'
import algoliasearch from 'algoliasearch'
import { Autocomplete } from '@mui/lab'
import { TextField } from '@mui/material'
import { StyledInputLabel } from '../../components/StyledTextField'
import { SearchIndex } from 'algoliasearch/dist/algoliasearch'
import { setCompanyGameId, setLastGameId } from '../../Settings'
import { addCompanyToCache, useCompaniesQuery } from '../../api/useCompaniesQuery'
import { useQueryClient } from '@tanstack/react-query'
import { ResponseError, dashboardClient, getErrorText } from '../../api'
import { AghanimGameSchemasGame, Company, Role } from '../../api/dashboard'
import { Button, ButtonIcon, Page } from '../../components/ui'
import { GET_STARTED_PATH } from '../../libs/routerPaths'
import { AlertCircleSolid, XClose } from '../../components/icons'
import { hasOnlyDemoCompany } from '@/util'
import { Trans } from 'react-i18next'

interface SearchResult {
  game: string
  url: string
}

let algoliaIndex: SearchIndex | null = null

function getSearchIndex() {
  if (!algoliaIndex) {
    if (
      import.meta.env.VITE_ALGOLIA_APP_ID &&
      import.meta.env.VITE_ALGOLIA_API_KEY &&
      import.meta.env.VITE_ALGOLIA_INDEX_NAME
    ) {
      const client = algoliasearch(
        import.meta.env.VITE_ALGOLIA_APP_ID as string,
        import.meta.env.VITE_ALGOLIA_API_KEY as string,
      )
      algoliaIndex = client.initIndex(import.meta.env.VITE_ALGOLIA_INDEX_NAME as string)
    } else {
      console.log('Algolia not configured')
    }
  }

  return algoliaIndex
}

function showConfetti(): void {
  let duration = 2 * 1000
  let animationEnd = Date.now() + duration
  let skew = 0.5
  let colors = ['#7DB2D3', '#4A4C59', '#FDDE6D', '#7FD6AE', '#F29265']

  function randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min
  }

  ;(function frame() {
    let timeLeft = animationEnd - Date.now()
    let ticks = Math.max(200, 500 * (timeLeft / duration))
    skew = Math.max(0.8, skew - 0.001)

    for (let i = 0; i < 5; i++) {
      confetti({
        particleCount: 1,
        startVelocity: 0,
        ticks: ticks,
        colors: [colors[i]],
        shapes: ['square'],
        origin: {
          x: Math.random(),
          // since particles fall down, skew start toward the top
          y: Math.random() * skew - 0.2,
        },
        gravity: randomInRange(1, 3),
        scalar: randomInRange(0.4, 2),
        drift: randomInRange(-0.4, 1.5),
      })
    }

    if (timeLeft > 0) {
      requestAnimationFrame(frame)
    }
  })()
}

const OnBoardingPage = () => {
  const queryClient = useQueryClient()
  const location = useLocation()
  const { state } = location
  const [helperText, setHelperText] = useState<{ title: string; text: string; status?: number } | null>(null)
  const { data: dataCompanies } = useCompaniesQuery()
  const [step, setStep] = useState<number>(0)
  const [game, setGame] = useState<AghanimGameSchemasGame | null>(null)
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [searchOptions, setSearchOptions] = useState<SearchResult[]>([])
  const [selectedSearchOption, setSelectedSearchOption] = useState<SearchResult | null>(null)
  const [, setTimeId] = useState<NodeJS.Timeout | null>(null)
  const [mainCompany, setMainCompany] = useState<Company | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isAutoCompleteOpened, setIsAutoCompleteOpened] = useState<boolean>(false)
  const navigate = useNavigate()
  const theme = useTheme()

  const context = useContext(AuthContext) as IAuthContext

  const searchGame = () => {
    let idx = getSearchIndex()
    if (idx) {
      idx.search(searchText).then(({ hits }) => {
        setSearchLoading(false)
        setSearchOptions(hits as unknown as SearchResult[])
        setIsAutoCompleteOpened(true)
      })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 100)
  }, [])

  useEffect(() => {
    if (searchText.length > 2) {
      if (searchText.startsWith('http')) {
        let arr = [{ game: searchText, url: searchText }] as SearchResult[]
        setSearchOptions([])
        setSelectedSearchOption(arr[0])
        setIsAutoCompleteOpened(false)
        return
      }
      if (!selectedSearchOption) {
        setIsAutoCompleteOpened(true)
        setSearchLoading(true)
        setTimeId(prev => {
          if (prev) {
            clearTimeout(prev)
          }
          return setTimeout(() => searchGame(), 500)
        })
      }
    } else {
      setSearchOptions([])
    }
  }, [searchText])

  const saveClick = async () => {
    let url = selectedSearchOption?.url || searchText

    if (!url || !url.startsWith('http')) {
      setHelperText({
        title: 'The link is incorrect',
        text: 'Please make sure the link starts with https://apps.apple.com/ for the App Store or https://play.google.com/store/apps/ for Google Play, and try again.',
      })
      return
    }

    doAddGame(url)
  }

  const doAddGame = async (url: string) => {
    setStep(1)
    setHelperText(null)
    let companyID = state?.company_id

    try {
      let {
        data: { company, game },
      } = await dashboardClient.v1.onboardGame({
        url,
        company_id: companyID as string,
      })

      setGame(game)
      setMainCompany(company)
      showConfetti()
      setStep(2)

      if (context) {
        setLastGameId(game.id)
        setCompanyGameId(game.company_id)
      }
    } catch (e) {
      setStep(0)
      let err = e as ResponseError
      switch (err.status) {
        case 404:
          setHelperText({
            status: err.status,
            title: '',
            text: i18next.t('onboarding.404.text'),
          })
          break
        case 429:
          setHelperText({
            status: err.status,
            title: '',
            text: '',
          })
          break
        default:
          setHelperText({ title: 'Error', text: getErrorText(e as ResponseError) })
          break
      }
    }
  }

  const doneClick = () => {
    if (mainCompany && game) {
      addCompanyToCache(queryClient, {
        ...mainCompany,
        role: Role.Admin,
        games: [game],
      })

      navigate(generatePath(GET_STARTED_PATH, { companyId: mainCompany.id, gameId: game.id }))
    }
  }

  const getStep0 = () => {
    return (
      <Box
        sx={{
          width: '100%',
          padding: '0 104px',
          height: '100%',
          background: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!hasOnlyDemoCompany(dataCompanies?.data) && dataCompanies?.data && dataCompanies.data.length > 0 && (
          <ButtonIcon className="absolute right-5 top-5" variant="secondary-gray" onClick={() => navigate(-1)}>
            <XClose />
          </ButtonIcon>
        )}

        <Logo />

        <Box sx={{ textAlign: 'center', marginTop: theme.spacing(5) }}>
          <Typography variant="h1" fontWeight="500">
            {i18next.t('onboarding.add-your-game')}
          </Typography>

          <Typography variant="subtitle2" sx={{ marginTop: theme.spacing(0.75) }}>
            {i18next.t('onboarding.title')}
          </Typography>
        </Box>

        <Box sx={{ marginTop: theme.spacing(5), width: '100%' }}>
          <Autocomplete
            loading={searchLoading}
            loadingText={i18next.t('onboarding.searching')}
            ListboxProps={{
              style: {
                maxHeight: '150px',
              },
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                saveClick()
              }
            }}
            value={selectedSearchOption}
            onChange={(_, newValue: SearchResult | null) => {
              setSelectedSearchOption(newValue)
              setIsAutoCompleteOpened(false)
              setHelperText(null)
            }}
            getOptionLabel={option => option.game}
            inputValue={searchText}
            onInputChange={(event, newInputValue, reason) => {
              if (event?.type == 'blur' || (reason as string) == 'reset') {
                setIsAutoCompleteOpened(false)
                if (!newInputValue) {
                  return
                }
              }
              if (!newInputValue.startsWith('http')) {
                setSelectedSearchOption(null)
              }
              setSearchText(newInputValue)
              if (!newInputValue) {
                setIsAutoCompleteOpened(false)
                setSearchOptions([])
              }
            }}
            options={searchOptions}
            fullWidth
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderInput={(params: any) => (
              <>
                <StyledInputLabel label={i18next.t('onboarding.link-your-game')} />
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  placeholder={'Google Play / Apple store'}
                  inputRef={inputRef}
                  fullWidth
                />
                {helperText && (
                  <>
                    <div className="mt-1.5 grid grid-cols-[auto_1fr_auto] items-center gap-3 rounded-md border border-[#0EA5E91A] bg-fg-brand-tertiary p-3 text-text-brand-solid">
                      <AlertCircleSolid size={16} />
                      <div>
                        <div className="mb-1 text-caption-sm">{helperText.title}</div>
                        {helperText.status == 429 ? (
                          <Trans i18nKey="onboarding.429.text" components={{ a: <a /> }} />
                        ) : (
                          <div className="text-paragraph-xs">{helperText.text}</div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            open={!!searchText && isAutoCompleteOpened}
          />
        </Box>

        <Button
          onClick={saveClick}
          variant="primary"
          style={{ marginTop: theme.spacing(5), width: '100%', height: '48px' }}
        >
          {i18next.t('AddGame')}
        </Button>
      </Box>
    )
  }

  const getStep1 = () => {
    return (
      <Box
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src="/icons/waiter.svg" style={{ width: '72px', height: '72px' }} alt="loader" />
      </Box>
    )
  }

  const getStep2 = () => {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <img
            src={game?.logo_url || `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/placeholder.png`}
            data-categories="essential"
            alt="game logo"
            style={{
              objectFit: 'cover',
              display: 'inline-block',
              width: '124px',
              height: '124px',
              borderRadius: theme.spacing(3),
              marginBottom: theme.spacing(3),
            }}
          />
          <Typography
            style={{
              marginTop: theme.spacing(5),
              color: theme.palette.text.primary,
            }}
            variant="h1"
          >
            {game?.name || 'Game'}
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginTop: theme.spacing(1),
              color: theme.palette.text.table,
            }}
          >
            {mainCompany?.name || 'Company'}
          </Typography>
        </Box>

        <SaveButton onClick={doneClick} style={{ height: '43px', marginTop: theme.spacing(5) }}>
          {i18next.t('onboarding.done-button')}
        </SaveButton>
      </Box>
    )
  }

  const getBackground = () => {
    return (
      <div className="pointer-events-none absolute left-0 top-0 flex size-full items-center justify-center overflow-hidden">
        <svg width="1074" height="1047" viewBox="0 0 1074 1047" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="537.342" cy="536.658" r="334.712" stroke="#0097E4" strokeWidth="1.39755" />
          <circle
            cx="537.342"
            cy="536.658"
            r="401.794"
            stroke="#0097E4"
            strokeWidth="1.39755"
            style={{ opacity: 0.6 }}
          />
          <circle
            cx="537.342"
            cy="536.658"
            r="468.877"
            stroke="#0097E4"
            strokeWidth="1.39755"
            style={{ opacity: 0.4 }}
          />
          <circle
            cx="537.342"
            cy="536.658"
            r="535.959"
            stroke="#0097E4"
            strokeWidth="1.39755"
            style={{ opacity: 0.2 }}
          />
        </svg>
        <img
          src={SOCIAL_ICONS_MAP[GameStoreType.app_store]}
          alt={'app store'}
          className="onboarding-image--app-store"
        />
        <img
          src={SOCIAL_ICONS_MAP[GameStoreType.google_play]}
          alt={'google_play'}
          className="onboarding-image--youtube"
        />
        <img
          src={SOCIAL_ICONS_MAP[GameStoreType.app_store]}
          alt={'app_store'}
          className="onboarding-image--google-play"
        />
        <img src={SOCIAL_ICONS_MAP[GameStoreType.app_store]} alt={'app_store'} className="onboarding-image--discord" />
        <img
          src={SOCIAL_ICONS_MAP[GameStoreType.google_play]}
          alt={'google_play'}
          className="onboarding-image--amazon"
        />
      </div>
    )
  }

  return (
    <Page className="items-center justify-center">
      {getBackground()}

      <div className="relative w-full max-w-[690px] rounded-[36px] border border-border-primary bg-fg-primary px-6 py-24 shadow-2xl">
        {step === 0 && getStep0()}
        {step === 1 && getStep1()}
        {step === 2 && getStep2()}
      </div>
    </Page>
  )
}

export default OnBoardingPage
