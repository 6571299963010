import { FC, useState } from 'react'
import { EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from 'reactflow'
import { ADD_NEXT_FALSE_PREFIX_ID, ADD_NEXT_PREFIX_ID, ADD_NEXT_TRUE_PREFIX_ID } from '../Settings'
import PlusButton from './PlusButton'
import { useTheme } from '@mui/material/styles'
import { getLineColor } from './common'
import EdgeLabel from './EdgeLabel'
import { EventActionNodeType } from '../types'

export const DefaultEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
}) => {
  const theme = useTheme()
  const [hovered, setHovered] = useState(false)

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <>
      <path
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        id={id}
        style={{
          stroke: getLineColor(theme),
          strokeWidth: data.style.strokeWidth ? data.style.strokeWidth : data.selected ? '3' : '2',
          strokeOpacity: data.style.strokeOpacity,
        }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        fillRule="evenodd"
      />

      <EdgeLabelRenderer>
        <PlusButton
          visible={hovered}
          id={ADD_NEXT_PREFIX_ID + data.sourceId}
          onClick={(target: HTMLDivElement) => data.addClick(target)}
          transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
        />
      </EdgeLabelRenderer>
    </>
  )
}

export const RightEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
}) => {
  const [hovered, setHovered] = useState(false)
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 16,
  })
  const theme = useTheme()
  let text = 'TRUE'
  switch (data.sourceNode.model_type) {
    case EventActionNodeType.SplitNode:
      text = 100 - data.sourceNode.distribution[0].value + '%'
      break
  }

  let plusButtonX = labelX + 70
  let plusButtonY = labelY

  if (sourceY - targetY > 10) {
    plusButtonX = labelX
    plusButtonY = labelY + 25
  } else if (Math.abs(sourceX - targetX) < 25) {
    plusButtonX = labelX
    plusButtonY = labelY + 25
  }

  return (
    <>
      <path
        id={id}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        style={{
          stroke: getLineColor(theme),
          strokeWidth: data.style.strokeWidth ? data.style.strokeWidth : data.selected ? '3' : '2',
          strokeOpacity: data.style.strokeOpacity,
        }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        fillRule="evenodd"
      />

      <EdgeLabelRenderer>
        <EdgeLabel labelX={labelX} labelY={labelY} value={text} />
        <PlusButton
          visible={hovered}
          id={ADD_NEXT_PREFIX_ID + ADD_NEXT_TRUE_PREFIX_ID + data.sourceId}
          onClick={target => data.addClick(target, true)}
          transform={`translate(-50%, -50%) translate(${plusButtonX}px,${plusButtonY}px)`}
        />
      </EdgeLabelRenderer>
    </>
  )
}

export const LeftEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
}) => {
  const [hovered, setHovered] = useState(false)
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 16,
  })
  const theme = useTheme()

  let text = 'FALSE'

  switch (data.sourceNode.model_type) {
    case EventActionNodeType.SplitNode:
      text = data.sourceNode.distribution[0].value + '%'
      break
  }

  let plusButtonX = labelX - 70
  let plusButtonY = labelY

  if (sourceY - targetY > 55) {
    plusButtonX = labelX
    plusButtonY = labelY + 70
  } else if (Math.abs(sourceX - targetX) < 55) {
    plusButtonX = labelX
    plusButtonY = labelY + 70
  }

  return (
    <>
      <path
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        id={id}
        style={{
          stroke: getLineColor(theme),
          strokeWidth: data.style.strokeWidth ? data.style.strokeWidth : data.selected ? '3' : '2',
          strokeOpacity: data.style.strokeOpacity,
        }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        fillRule="evenodd"
      />

      <EdgeLabelRenderer>
        <EdgeLabel labelX={labelX} labelY={labelY} value={text} />
        <PlusButton
          visible={hovered}
          id={ADD_NEXT_PREFIX_ID + ADD_NEXT_FALSE_PREFIX_ID + data.sourceId}
          onClick={target => data.addClick(target, false)}
          transform={`translate(-50%, -50%) translate(${plusButtonX}px,${plusButtonY}px)`}
        />
      </EdgeLabelRenderer>
    </>
  )
}
