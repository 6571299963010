export const COUNTRY_BY_REGIONS = {
  SouthAmerica: new Set(['AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'GY', 'PE', 'PY', 'SR', 'UY', 'VE']),
  Europe: new Set([
    'AL',
    'AD',
    'AT',
    'BY',
    'BE',
    'BA',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IS',
    'IE',
    'IT',
    'LV',
    'LI',
    'LT',
    'LU',
    'MT',
    'MD',
    'MC',
    'ME',
    'NL',
    'MK',
    'NO',
    'PL',
    'PT',
    'RO',
    'RU',
    'SM',
    'RS',
    'SK',
    'SI',
    'ES',
    'SE',
    'CH',
    'UA',
    'GB',
    'VA',
  ]),
  Asia: new Set([
    'AF',
    'AM',
    'AZ',
    'BH',
    'BD',
    'BT',
    'BN',
    'KH',
    'CN',
    'GE',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IL',
    'JP',
    'JO',
    'KZ',
    'KW',
    'KG',
    'LA',
    'LB',
    'MY',
    'MV',
    'MN',
    'MM',
    'NP',
    'KP',
    'OM',
    'PK',
    'PH',
    'QA',
    'SA',
    'SG',
    'KR',
    'LK',
    'SY',
    'TW',
    'TJ',
    'TH',
    'TR',
    'TM',
    'AE',
    'UZ',
    'VN',
    'YE',
  ]),
  Africa: new Set([
    'DZ',
    'AO',
    'BJ',
    'BW',
    'BF',
    'BI',
    'CM',
    'CV',
    'CF',
    'TD',
    'KM',
    'CD',
    'DJ',
    'EG',
    'GQ',
    'ER',
    'SZ',
    'ET',
    'GA',
    'GM',
    'GH',
    'GN',
    'GW',
    'CI',
    'KE',
    'LS',
    'LR',
    'LY',
    'MG',
    'MW',
    'ML',
    'MR',
    'MU',
    'MA',
    'MZ',
    'NA',
    'NE',
    'NG',
    'RW',
    'ST',
    'SN',
    'SC',
    'SL',
    'SO',
    'ZA',
    'SS',
    'SD',
    'SZ',
    'TZ',
    'TG',
    'TN',
    'UG',
    'ZM',
    'ZW',
  ]),
  Oceania: new Set(['AU', 'FJ', 'KI', 'MH', 'FM', 'NR', 'NZ', 'PW', 'PG', 'WS', 'SB', 'TO', 'TV', 'VU']),
  NorthAmerica: new Set([
    'AG',
    'BS',
    'BB',
    'BZ',
    'CA',
    'CR',
    'CU',
    'DM',
    'DO',
    'SV',
    'GD',
    'GT',
    'HT',
    'HN',
    'JM',
    'MX',
    'NI',
    'PA',
    'KN',
    'LC',
    'VC',
    'TT',
    'US',
  ]),
}
