export default function AlertCircle() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2948_1427)">
        <path
          d="M2 13C2 6.37258 7.37258 1 14 1H34C40.6274 1 46 6.37258 46 13V33C46 39.6274 40.6274 45 34 45H14C7.37258 45 2 39.6274 2 33V13Z"
          fill="white"
        />
        <path
          d="M2.5 13C2.5 6.64873 7.64873 1.5 14 1.5H34C40.3513 1.5 45.5 6.64873 45.5 13V33C45.5 39.3513 40.3513 44.5 34 44.5H14C7.64873 44.5 2.5 39.3513 2.5 33V13Z"
          stroke="#E2E8F0"
        />
        <g clipPath="url(#clip0_2948_1427)">
          <path
            d="M24.0003 19.6665V22.9998M24.0003 26.3332H24.0087M32.3337 22.9998C32.3337 27.6022 28.6027 31.3332 24.0003 31.3332C19.398 31.3332 15.667 27.6022 15.667 22.9998C15.667 18.3975 19.398 14.6665 24.0003 14.6665C28.6027 14.6665 32.3337 18.3975 32.3337 22.9998Z"
            stroke="#64748B"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_2948_1427"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2948_1427" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2948_1427" result="shape" />
        </filter>
        <clipPath id="clip0_2948_1427">
          <rect width="20" height="20" fill="white" transform="translate(14 13)" />
        </clipPath>
      </defs>
    </svg>
  )
}
