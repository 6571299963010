import { MouseEventHandler } from 'react'
import { ListItemIcon, ListItemText } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import { Link } from 'react-router-dom'
import { Theme, useTheme } from '@mui/material/styles'
import { SxProps } from '@mui/system/styleFunctionSx'

export interface NavItemProps {
  id?: string
  navlabel?: boolean
  subheader?: string
  title?: string
  href?: string
  icon?: React.ReactNode
  isBigIcon?: boolean
  rightIcon?: React.ReactNode
  children?: NavItemProps[]
  hasDrill?: boolean
  otherUrls?: string[]
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  selected?: boolean
  showText?: boolean
  parentColor?: string
}

const ListItemButtonStyled = (props: {
  to: string
  selected: boolean
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  children: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any
  sx: SxProps<Theme>
}) => {
  const theme = useTheme()
  const sx = {
    '&.Mui-selected': {
      borderRadius: theme.spacing(0.5),
      color: theme.palette.primary.main,
      backgroundColor: 'red',
      '&, & .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
    },
    '&.MuiListItemButton-root:hover:not(.Mui-selected)': {
      backgroundColor: theme.palette.grey['100'],
    },
    '.MuiTouchRipple-child': {
      backgroundColor: theme.palette.grey['200'],
    },
    ...props.sx,
    color: theme.palette.text.sideBar,
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(4),
    padding: theme.spacing(0.5),
  }

  return (
    <ListItemButton
      sx={sx}
      selected={props.selected}
      onClick={props.onClick}
      component={props.component}
      to={props.to || ''}
    >
      {props.children}
    </ListItemButton>
  )
}

const NavItem = ({ href, selected, onClick, parentColor, showText, icon, rightIcon, title }: NavItemProps) => {
  const theme = useTheme()

  return (
    <ListItemButtonStyled
      component={Link}
      to={href || ''}
      sx={
        showText === false
          ? {
              width: theme.spacing(6),
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              color: parentColor,
            }
          : {}
      }
      onClick={e => onClick && onClick(e)}
      selected={!!selected}
    >
      {icon && (
        <ListItemIcon
          sx={{
            minWidth: theme.spacing(2.25),
          }}
        >
          {icon}
        </ListItemIcon>
      )}

      {showText !== false && (
        <>
          <ListItemText
            sx={{
              marginLeft: theme.spacing(1),
              marginTop: 0,
              marginBottom: 0,
            }}
            primaryTypographyProps={{
              fontSize: '0.875rem',
              fontWeight: '500',
              whiteSpace: 'nowrap',
              lineHeight: '1.334rem',
              color: parentColor,
            }}
          >
            {title}
          </ListItemText>
          {rightIcon}
        </>
      )}
    </ListItemButtonStyled>
  )
}

export default NavItem
