import i18next from 'i18next'
import SelectSkuPanel from './SelectSkuPanel'
import SelectVideoPanel from './SelectVideoPanel'
import SelectNewsPanel from './SelectNewsPanel'
import SelectLeaderBoardPanel from './SelectLeaderBoardPanel'
import TextBlockEditor from './TextBlockEditor'
import { Side } from '../editor-types'
import { Button, FieldGroup, Input, Modal, ModalFooter, ModalProps, ModalTitle, Select } from '../../components/ui'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Block } from '../../api/dashboard'
import SelectPagePanel from './SelectPagePanel'

interface AddBlockDialogProps extends ModalProps {
  onSave: (block: Block) => void
  block?: Block | undefined
  side: Side
}

const EditBlockModal = (props: AddBlockDialogProps) => {
  const { ...rest } = props

  const methods = useForm<Block>({
    values: {
      block: 'FeaturedItemBlock',
      id: '',
      ...props.block,
    },
  })

  const { handleSubmit, control, watch, register } = methods

  const editors = {
    FeaturedItemBlock: <SelectSkuPanel />,
    YoutubeBlock: <SelectVideoPanel />,
    FeaturedNewsBlock: <SelectNewsPanel />,
    FeaturedPageBlock: <SelectPagePanel />,
    LeaderboardCardBlock: <SelectLeaderBoardPanel />,
    LeaderboardFullBlock: <SelectLeaderBoardPanel />,
    TextBlock: <TextBlockEditor />,
  }

  let items = [
    { label: 'Video', value: 'YoutubeBlock' },
    { label: 'Featured Item', value: 'FeaturedItemBlock' },
    { label: 'Featured News', value: 'FeaturedNewsBlock' },
    { label: 'Featured Page', value: 'FeaturedPageBlock' },
    { label: 'Redeem Coupon', value: 'RedeemCouponBlock' },
    { label: 'Card Leaderboard list', value: 'LeaderboardCardBlock' },
    { label: 'Text', value: 'TextBlock' },
  ]

  if (props.side === 'up' || props.side == 'down') {
    items.push(
      { label: 'Achievement List', value: 'AchievementListBlock' },
      { label: 'Store', value: 'StoreBlock' },
      { label: 'Full Leaderboard list', value: 'LeaderboardFullBlock' },
      { label: 'Latest News', value: 'LatestNewsBlock' },
    )
  }

  items = items.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))

  const saveClick = (data: Block) => {
    props.onSave(data)
    props.onClose?.()
  }

  const blockType = watch('block')

  return (
    <Modal {...rest}>
      <ModalTitle>{i18next.t(props.block ? 'hub-editor.edit-block' : 'hub-editor.add-block')}</ModalTitle>

      <div className="mt-10">
        <FieldGroup label={i18next.t('hub-editor.block-type')}>
          <Controller
            name="block"
            control={control}
            render={({ field }) => (
              <Select
                disabled={!!props.block}
                dropDownWidth="280px"
                {...field}
                options={items.map(it => ({
                  children: it.label,
                  value: it.value,
                }))}
              />
            )}
          />
        </FieldGroup>

        {blockType == 'FeaturedItemBlock' && (
          <FieldGroup label={i18next.t('hub-editor.block-name')}>
            <Input autoFocus {...register('name')} />
          </FieldGroup>
        )}

        <FormProvider {...methods}>
          {
            editors[
              blockType as
                | 'FeaturedItemBlock'
                | 'YoutubeBlock'
                | 'FeaturedNewsBlock'
                | 'FeaturedPageBlock'
                | 'LeaderboardCardBlock'
                | 'LeaderboardFullBlock'
                | 'TextBlock'
            ]
          }
        </FormProvider>
      </div>

      <ModalFooter>
        <Button size="lg" onClick={() => props.onClose?.()}>
          {i18next.t('Cancel')}
        </Button>
        <Button size="lg" variant="primary" onClick={handleSubmit(saveClick)}>
          {i18next.t(props.block ? 'apply' : 'Add')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditBlockModal
