import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const DeleteOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 22 22" ref={ref}>
      <path
        id="Rectangle 5193"
        d="M3.66675 6.41699H18.3334M8.25008 3.66699H13.7501M5.95841 6.41699H16.0417L15.3979 16.4616C15.3304 17.5143 14.4569 18.3337 13.402 18.3337H8.5982C7.54331 18.3337 6.66978 17.5143 6.6023 16.4616L5.95841 6.41699Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  )
})
