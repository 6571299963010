import i18next from 'i18next'
import { Button, FieldGroup, Input, Modal, ModalFooter, ModalProps, ModalTitle, useModal } from '../../../components/ui'
import { Edit03, Plus, Trash } from '../../../components/icons'
import { useState } from 'react'
import { cn } from '../../../libs/cn'

interface ListEditorModalProps extends ModalProps {
  items: string[]
  onItemsChange: (value: string[]) => void
}

export const ListEditorModal = (props: ListEditorModalProps) => {
  const { ...rest } = props

  const [list, setList] = useState(props.items)
  const [newItem, setNewItem] = useState('')

  const onAdd = () => {
    if (!newItem) {
      return
    }
    if (list.indexOf(newItem) == -1) {
      setList([...list, newItem])
    }
    setNewItem('')
  }

  return (
    <Modal {...rest}>
      <ModalTitle>{i18next.t('campaign.condition.list_value')}</ModalTitle>
      <div className="mb-6">
        {list.length > 0 && (
          <div className="mb-2 flex flex-col rounded-md border border-border-secondary">
            {list.map((it, idx) => (
              <div
                key={it}
                className={cn(
                  'flex h-[54px] w-full items-center px-3 ',
                  idx > 0 ? 'border-t border-border-secondary' : '',
                )}
              >
                {it}
                <Trash
                  className="ml-auto mr-2 cursor-pointer text-text-error-primary"
                  onClick={() => setList(list.filter(d => d != it))}
                />
              </div>
            ))}
          </div>
        )}

        <FieldGroup label={i18next.t('campaign.condition.new_value')}>
          <div className="flex gap-3">
            <Input
              autoFocus
              value={newItem}
              onChange={e => setNewItem(e.target.value)}
              onKeyUp={e => {
                if (e.keyCode == 13) {
                  onAdd()
                }
              }}
            />
            <Button onClick={onAdd} size="xl" disabled={!newItem}>
              <Plus />
            </Button>
          </div>
        </FieldGroup>
      </div>

      <ModalFooter>
        <Button
          onClick={() => {
            rest.onClose?.()
          }}
        >
          {i18next.t('Cancel')}
        </Button>
        <Button
          onClick={() => {
            props.onItemsChange(list)
            rest.onClose?.()
          }}
          variant="primary"
        >
          {i18next.t('Apply')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const InputList = (props: { value: string[]; onChange: (value: string[]) => void }) => {
  const openEditModal = useModal<{ items: string[]; onItemsChange: (value: string[]) => void }>(props => (
    <ListEditorModal {...props} />
  ))

  return (
    <Input
      value={props.value.join(',')}
      onChange={() => {
        //do nothing
      }}
      extraRight={
        <div
          className="flex h-full cursor-pointer items-center justify-center"
          style={{ zIndex: 1 }}
          onClick={() => openEditModal({ items: props.value, onItemsChange: props.onChange })}
        >
          <Edit03 />
        </div>
      }
    />
  )
}
