export type PluralForms = {
  one: string
  few: string
  many: string
  other: string
}

export default function pluralize(count: number, forms: PluralForms, lang: string = 'en'): string {
  switch (lang) {
    case 'en':
      return count === 1 ? forms.one : forms.other
    case 'es':
      return count === 1 ? forms.one : forms.other
    case 'de':
      return count === 1 ? forms.one : forms.other
    case 'zh':
      return forms.one
    case 'ru':
      if (count % 10 === 1 && count % 100 !== 11) {
        return `${forms.one}`
      } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
        return forms.few
      } else {
        return forms.many
      }
    default:
      return forms.other
  }
}
