import { useTranslation } from 'react-i18next'
import { LocaleRead, LocaleStatus } from '@/api/dashboard'
import { FloatingMenuProps, MenuItem, MenuItemDivider, Menu_v2, useModal } from '@/ui'
import { L10nLocaleModalConfirmTranslateAll, L10nLocaleModalDelete } from '../widgets'

interface LocaleMenuProps extends FloatingMenuProps {
  locale: LocaleRead
}

export const LocaleMenu = ({ locale, onClose }: LocaleMenuProps) => {
  const { t } = useTranslation()

  const openModalConfirmTranslateAll = useModal<{ locale: LocaleRead }>(props => (
    <L10nLocaleModalConfirmTranslateAll {...props} />
  ))

  const openLocaleModalDelete = useModal<{ locale: LocaleRead }>(props => <L10nLocaleModalDelete {...props} />)

  const onClickTranslateAll = () => {
    openModalConfirmTranslateAll({ locale })
    onClose?.()
  }

  const onClickDelete = () => {
    openLocaleModalDelete({ locale })
    onClose?.()
  }

  return (
    <Menu_v2>
      <MenuItem
        disabled={locale.status === LocaleStatus.Synchronization || locale.status === LocaleStatus.Translating}
        onClick={onClickTranslateAll}
      >
        {t('localization.locale.menu.translate-all')}
      </MenuItem>
      <MenuItemDivider />
      <MenuItem variant="danger" onClick={onClickDelete}>
        {t('localization.locale.menu.delete')}
      </MenuItem>
    </Menu_v2>
  )
}
