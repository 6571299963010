import { CreateCouponNode, Node } from '../../../api/dashboard'
import CouponSettings from '../../coupon/components/CouponSettings'
import { BlockError } from '../types'

export default function CreateCouponEditor(props: {
  node: CreateCouponNode
  error?: BlockError | null
  onClose: () => void
  setNode: (node: Node) => void
}) {
  return (
    <CouponSettings
      error={props.error}
      node={props.node}
      onClose={props.onClose}
      onSave={(node: Node) => {
        props.setNode(node)
        props.onClose()
      }}
    />
  )
}
