import { Tabs, styled } from '@mui/material'
import Tab from '@mui/material/Tab'
import { MAIN_MARGIN } from '../Settings'

export const TabStyle = {
  textTransform: 'capitalize',
  fontWeight: '500',
}

export const StyledTab = styled(Tab)(() => ({
  textTransform: 'capitalize',
  fontWeight: '500',
}))

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginLeft: MAIN_MARGIN,
  borderBottom: `1px solid ${theme.palette.divider}`,
  overflow: 'visible',
  '& .MuiTabs-scroller': {
    overflow: 'visible !important',
  },
  '& .MuiTabs-indicator': {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    zIndex: 1,
    bottom: '-1px',
  },
  '& .MuiButtonBase-root': {
    lineHeight: '16.94px',
    padding: '0 9px',
  },

  '& .Mui-selected': {
    color: theme.palette.grey['1000'],
  },
}))

//
// export function getTabsHeight(theme: Theme)
// {
//     return theme.spacing(6.5)
// }
