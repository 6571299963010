import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { SegmentUpdate } from '../../../api/dashboard'

interface SegmentUpdateMutate {
  id: string
  update: SegmentUpdate
}

export const useSegmentUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, update }: SegmentUpdateMutate) =>
      dashboardClient.v1.updateSegment(companyId, gameId, id, update),
    onSuccess: data => {
      queryClient.setQueryData(['segments', gameId], data.data)
      queryClient.invalidateQueries({ queryKey: ['segments', gameId] })
    },
  })
}
