import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../../api'

type Query = {
  /** Campaign Id */
  campaign_id?: string

  /** User Id */
  user_id?: string
}

/**
 *
 * @param companyId
 * @param gameId
 * @param query
 */
export const useOfferReportQuery = (companyId: string, gameId: string, query: Query) => {
  return useQuery({
    queryKey: ['offer_report', gameId],
    queryFn: () => dashboardClient.v1.getOfferStat(companyId, gameId, query),
    select: response => response.data,
  })
}
