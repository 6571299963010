import { useRef, useState } from 'react'
import i18next from 'i18next'
import LoadingButton from '@mui/lab/LoadingButton'
import { ButtonOwnProps } from '@mui/material/Button/Button'

interface IProps {
  onClick: () => boolean | undefined | void | Promise<unknown>
  style?: object
  classes?: string
  disabled?: boolean
  color?: string
  variant?: string
  children?: React.ReactNode
  size?: 'small' | 'medium' | 'large'
}

const SaveButton = (props: IProps) => {
  const btnNode = useRef<HTMLButtonElement>(null)
  const [isLoading, setIsLoading] = useState(false)

  const onClick = async () => {
    setIsLoading(true)
    try {
      await props.onClick()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <LoadingButton
      variant={(props.variant as ButtonOwnProps['variant']) || 'contained'}
      color={(props.color as ButtonOwnProps['color']) || 'primary'}
      loading={isLoading}
      disableRipple
      size={props.size || 'large'}
      disabled={props.disabled}
      ref={btnNode}
      style={{ ...props.style }}
      onClick={() => onClick()}
    >
      {props.children || i18next.t('Save')}
    </LoadingButton>
  )
}

export default SaveButton
