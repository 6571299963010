import { HTMLAttributes, ReactNode } from 'react'
import { cn } from '../../../libs/cn'
import { AnimatePresence, motion } from 'framer-motion'
import { getMotionProps } from '../../../libs'
import { Link } from 'react-router-dom'

export interface TabProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode
  isActive: boolean
  count?: number
  to?: string
  onClick?: () => void
  className?: string
}

export const countWrapperMotionProps = getMotionProps({
  initial: { width: 0, marginLeft: 0, opacity: 0 },
  animate: { width: 'auto', marginLeft: 6, opacity: 1 },
  exit: { width: 0, marginLeft: 0, opacity: 0 },
})

export const countValueMotionProps = getMotionProps(
  {
    initial: { x: -2, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: 2, opacity: 0 },
  },
  { delay: 0.15 },
)

export const Tab = ({ children, isActive, count, onClick, to, ...rest }: TabProps) => {
  const clsName = // eslint-disable-next-line tailwindcss/no-custom-classname
    cn(
      'group flex h-9 w-full cursor-pointer items-center justify-center rounded-md border border-border-primary px-4 text-center text-text-tertiary transition-all hover:border-border-primary-hover hover:text-text-primary hover:shadow-md [&.active]:border-border-brand [&.active]:font-semibold [&.active]:text-text-primary [&.active]:shadow-md [&.active]:ring-1 [&.active]:ring-border-brand',
      isActive && 'active',
      rest.className,
    )

  const content = (
    <>
      <span className="text-caption-md">{children}</span>
      <AnimatePresence initial={false}>
        {count !== undefined && (
          <motion.div {...countWrapperMotionProps} className="overflow-hidden rounded-md">
            <motion.span
              {...countValueMotionProps}
              className="flex h-5 items-center justify-center px-1 text-center text-caption-sm text-text-quarterary-hover transition-colors group-[&.active]:bg-fg-brand-tertiary group-[&.active]:font-semibold group-[&.active]:text-text-brand-primary"
            >
              {count}
            </motion.span>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )

  if (to) {
    return (
      <Link {...rest} to={to} className={clsName}>
        {content}
      </Link>
    )
  }

  return (
    <div {...rest} onClick={onClick} className={clsName}>
      {content}
    </div>
  )
}
