import { Skeleton } from '@mui/material'
import { TableCell, TableRow } from '../../../components/ui'

export const PagesRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton variant="rounded" width="100%" height={24} style={{ margin: '7px 0' }} />
      </TableCell>
      <TableCell>
        <Skeleton variant="rounded" width="100%" height={24} />
      </TableCell>
      <TableCell>
        <Skeleton variant="rounded" width="100%" height={24} />
      </TableCell>
    </TableRow>
  )
}
