import { useState } from 'react'
import i18next from 'i18next'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { FieldInfo, KeyValue, S3Bucket } from '../../types'
import { useTheme } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import StyledTextField from '../../components/StyledTextField'
import { DIALOG_PADDING, DialogFooter, DialogHeader } from '../../components/DialogSavePanel'
import { LeaderboardMemberRead } from '../../api/dashboard'
import { dashboardClient } from '../../api'
import ImageUpload from '../../components/ImageUpload'

export default function EditMemberDialog(props: { onClose: (saved: boolean) => void; item?: LeaderboardMemberRead }) {
  const { companyId, gameId, leaderboardId } = useParams() as {
    companyId: string
    gameId: string
    leaderboardId: string
  }

  const [, setHelperText] = useState('')

  const [item, setItem] = useState<LeaderboardMemberRead>({
    member_id: '',
    member_data: '',
    image_url: '',
    score: 0,
    ...props.item,
  } as LeaderboardMemberRead)

  const isEnableSave = () => {
    return true
  }

  const saveClick = async () => {
    setHelperText('')
    try {
      await dashboardClient.v1.updateLeaderboardMember(item.id, companyId, gameId, leaderboardId, item)

      props.onClose(true)
      return true
    } catch (e) {
      const err = e as { detail: string }
      setHelperText(err.detail)
    }
  }

  const theme = useTheme()

  const renderFields = (fields: FieldInfo[]) => {
    return fields.map((it, idx) => (
      <StyledTextField
        label={i18next.t(it.i18)}
        type={it.type}
        key={it.property}
        value={(item as unknown as KeyValue)[it.property] as string}
        onChange={e =>
          setItem({
            ...item,
            [it.property]: e.target.value,
          })
        }
        fullWidth={it.fullWidth !== false}
        margin={idx > 0}
      />
    ))
  }

  const fields = [
    {
      i18: 'leaderboard.members.member_id',
      property: 'member_id',
      type: 'text',
    },
    {
      i18: 'leaderboard.members.score',
      property: 'score',
      type: 'number',
    },
  ] as FieldInfo[]

  return (
    <Dialog onClose={() => props.onClose(false)} open={true} sx={{ '& .MuiDialog-paper': { width: '80%' } }}>
      <DialogHeader text={i18next.t('leaderboard.members.edit')} onCloseClick={() => props.onClose(false)} />

      <DialogContent dividers sx={{ padding: theme.spacing(DIALOG_PADDING) }}>
        {renderFields(fields)}

        <ImageUpload
          value={item.image_url}
          bucket={S3Bucket.hub}
          margin
          fullWidth
          onChange={url =>
            setItem({
              ...item,
              image_url: url,
            })
          }
        />
      </DialogContent>

      <DialogFooter onSaveClick={() => saveClick()} saveDisabled={!isEnableSave()} />
    </Dialog>
  )
}
