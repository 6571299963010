import i18next from 'i18next'

export function ByAghanim() {
  return (
    <div className="mt-auto flex items-center gap-2.5 text-caption-sm text-text-secondary">
      <img className="size-[18px]" src="/icons/logo.svg" alt="logo" />
      <span>{i18next.t('campaign.by-aghanim')}</span>
    </div>
  )
}
