;<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" />

import { AghanimIcon, IconBase } from '../../../components/icons/IconBase'
import { forwardRef } from 'react'

export const LinkBrokenIcon: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M9 4V2M15 20V22M4 9H2M20 15H22M4.91421 4.91421L3.5 3.5M19.0858 19.0858L20.5 20.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17.6569L9.87869 19.7782C8.31659 21.3403 5.78393 21.3403 4.22184 19.7782C2.65974 18.2161 2.65974 15.6835 4.22184 14.1214L6.34316 12M17.6569 12L19.7782 9.87872C21.3403 8.31662 21.3403 5.78396 19.7782 4.22187C18.2161 2.65977 15.6834 2.65977 14.1213 4.22187L12 6.34319"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  )
})
