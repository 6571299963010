import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const H2: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path d="M3 19V5H5.63121V10.9268H12.3617V5H15V19H12.3617V13.0527H5.63121V19H3Z" fill="currentColor" />
      <path
        d="M16.0424 19V18.0963L18.553 15.7461C18.7931 15.5146 18.9932 15.3089 19.1532 15.1291C19.3133 14.9493 19.4334 14.775 19.5134 14.6065C19.5935 14.4379 19.6335 14.258 19.6335 14.067C19.6335 13.8489 19.5817 13.6623 19.4781 13.5072C19.3745 13.3499 19.2321 13.2285 19.0508 13.1431C18.8696 13.0576 18.6636 13.0149 18.4329 13.0149C18.1952 13.0149 17.9868 13.0621 17.8079 13.1566C17.629 13.2487 17.4901 13.3802 17.3912 13.5511C17.2947 13.7219 17.2465 13.9253 17.2465 14.1614H16C16 13.723 16.1048 13.342 16.3143 13.0183C16.5238 12.6946 16.8121 12.444 17.1794 12.2664C17.549 12.0888 17.9727 12 18.4506 12C18.9355 12 19.3616 12.0865 19.7288 12.2596C20.096 12.4327 20.3809 12.6699 20.5833 12.9711C20.7881 13.2723 20.8905 13.6162 20.8905 14.0029C20.8905 14.2614 20.8387 14.5154 20.7352 14.7649C20.6316 15.0145 20.4492 15.2909 20.1879 15.5944C19.9289 15.8979 19.5652 16.2654 19.0968 16.697L17.8503 17.9075V17.9547H21V19H16.0424Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
