import { forwardRef } from 'react'
import { cn } from '../../../libs/cn'
import { ChangeHandler } from 'react-hook-form'

export const COLORS: string[] = [
  '#BAE6FD',
  '#E2E8F0',
  '#FED7AA',
  '#FDE68A',
  '#FEF08A',
  '#D9F99D',
  '#BBF7D0',
  '#A7F3D0',
  '#99F6E4',
]

interface ColorProps {
  colors?: string[]
  value?: string
  onChange?: ChangeHandler
  name?: string
}

export const ColorSelector = forwardRef<HTMLInputElement, ColorProps>(function Input(
  { colors = COLORS, name, ...rest },
  ref,
) {
  return (
    <div ref={ref} className="flex gap-3" {...rest}>
      {colors.map(color => (
        <div
          key={color}
          className={cn(
            'flex cursor-pointer items-center justify-center rounded-full border border-border-primary',
            rest.value == color && 'bg-fg-tertiary',
          )}
          onClick={() => rest.onChange && rest.onChange({ target: { value: color, name: name } })}
          style={{
            width: '32px',
            height: '32px',
          }}
        >
          <div
            className="rounded-full"
            style={{
              backgroundColor: color,
              width: '26px',
              height: '26px',
            }}
          />
        </div>
      ))}
    </div>
  )
})
