import { Outlet, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { webhookDetailQuery, webhookLogQuery } from '../api'
import { WebhookTitleBar, WebhookTitleBarSkeleton } from '../components'
import {
  WebhookBar,
  WebhookBarSkeleton,
  WebhookDiscoverCallout,
  WebhookLogsInspector,
  WebhookLogsInspectorSkeleton,
  WebhookLogsZeroState,
} from '../widgets'
import i18next from 'i18next'
import { useState } from 'react'
import { WebhookEventType, WebhookLogStatus } from '@/api/dashboard'
import { TableSearch } from '@/components/ui/TableSearch'
import { getUrlParamsObject } from '@/layouts/dashboard/util'
import { ToolbarFilter } from '@/ui'
import { useTranslation } from 'react-i18next'
import { DateFilterButton } from '@/layouts/dashboard/components/Filter/DateFilterButton'
import { cn } from '@/libs'

export const WebhookDetailPage = () => {
  const { companyId, gameId, webhookId } = useParams() as {
    companyId: string
    gameId: string
    webhookId: string
  }
  const [timeoutId, setTimeoutId] = useState<number | null>(null)
  const { t } = useTranslation()

  const [search, setSearch] = useState('')

  const [filter, setFilter] = useState({
    search: '',
    status: undefined as WebhookLogStatus | undefined,
    dateFilter: null as string | null,
    event_type: undefined as WebhookEventType | undefined,
  })

  const { data: webhook, isLoading } = useQuery(webhookDetailQuery(companyId, gameId, webhookId))
  const {
    data: logs = [],
    isLoading: isLoadingLogs,
    refetch,
  } = useQuery(
    webhookLogQuery(companyId, gameId, webhookId, {
      limit: 100,
      offset: 0,
      search_string: filter.search,
      status: filter.status ? filter.status : undefined,
      event_type: filter.event_type ? filter.event_type : undefined,
      ...getUrlParamsObject(filter.dateFilter),
    }),
  )

  if (isLoading || !webhook) {
    return (
      <>
        <WebhookTitleBarSkeleton />
        <WebhookBarSkeleton />
        <div className="mt-2.5">
          <WebhookLogsInspectorSkeleton />
        </div>
      </>
    )
  }

  return (
    <div className="flex h-full flex-col">
      <Outlet />
      <WebhookTitleBar webhook={webhook} />
      <WebhookDiscoverCallout />
      <WebhookBar webhook={webhook} />
      <div className="mt-2.5 h-full">
        <div
          className={cn(
            'flex w-full items-center gap-2 rounded-xl border border-border-primary bg-bg-secondary p-3 shadow-xs',
          )}
        >
          <TableSearch
            fullWidth
            placeholder={i18next.t('search')}
            value={search}
            onChange={e => {
              const v = e.target.value
              setSearch(v)

              if (!v) {
                setFilter({ ...filter, search: v })
                return
              }

              if (timeoutId) {
                clearTimeout(timeoutId)
              }

              setTimeoutId(
                setTimeout(() => {
                  setFilter({ ...filter, search: v })
                }, 500) as unknown as number,
              )
            }}
          />
          <div className="flex gap-2">
            {webhook.events.length > 1 && (
              <ToolbarFilter
                label={t('webhook.filters.event-type')}
                items={webhook.events.sort().map(it => ({
                  label: it,
                  value: it,
                }))}
                value={filter.event_type as string}
                onChange={value => setFilter({ ...filter, event_type: value as WebhookEventType })}
              />
            )}
            <ToolbarFilter
              label={t('webhook.filters.status')}
              items={[
                {
                  label: t('webhook.filters.success'),
                  value: WebhookLogStatus.Success,
                },
                {
                  label: t('webhook.filters.failed'),
                  value: WebhookLogStatus.Error,
                },
              ]}
              value={filter.status as string}
              onChange={value => setFilter({ ...filter, status: value as WebhookLogStatus })}
            />
            <DateFilterButton
              value={filter.dateFilter}
              onChange={v => {
                setFilter({ ...filter, dateFilter: v })
              }}
            />
          </div>
        </div>

        {isLoadingLogs ? (
          <WebhookLogsInspectorSkeleton />
        ) : logs.length ? (
          <WebhookLogsInspector items={logs} onReloadRequest={refetch} />
        ) : (
          <WebhookLogsZeroState
            webhook={webhook}
            onClearFilters={() => {
              setSearch('')
              setFilter({
                search: '',
                status: undefined,
                dateFilter: null,
                event_type: undefined,
              })
            }}
            isSearching={Object.values(filter).some(value => !!value)}
          />
        )}
      </div>
    </div>
  )
}
