import { GenerationProgressState } from '@/api/dashboard'
import { getPercentFrom } from '@/libs'

/**
 *
 * @param progressState
 */
export const calcGenerationProgress = (progressState: GenerationProgressState | undefined) => {
  const totalCurrent =
    (progressState?.colors?.current || 0) +
    (progressState?.background_image?.current || 0) +
    (progressState?.screenshots?.current || 0) +
    (progressState?.news?.current || 0) +
    (progressState?.sku_items?.current || 0) +
    (progressState?.best_youtube?.current || 0)

  const totalTotal =
    (progressState?.colors?.total || 0) +
    (progressState?.background_image?.total || 0) +
    (progressState?.screenshots?.total || 0) +
    (progressState?.news?.total || 0) +
    (progressState?.sku_items?.total || 0) +
    (progressState?.best_youtube?.total || 0)

  return getPercentFrom(totalCurrent, totalTotal)
}
