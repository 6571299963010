import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

/**
 *
 * @param companyId
 * @param gameId
 * @param userId
 */
export const usePlayerSegmentQuery = (companyId: string, gameId: string, userId: string) => {
  return useQuery({
    queryKey: ['user-segment', userId],
    queryFn: () => dashboardClient.v1.getUserSegments(companyId, gameId, userId).then(r => r.data),
  })
}
