import { HTMLAttributes, memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { EditorState, LexicalEditor } from 'lexical'
import { $generateHtmlFromNodes } from '@lexical/html'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin'
import { CodeNode } from '@lexical/code'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { cn, getMotionProps } from '@/libs'
import { DraggableBlockPlugin } from './plugins/DraggableBlockPlugin'
import { ComponentPickerMenuPlugin } from './plugins/ComponentPickerPlugin'
import { InlineImageNode } from './plugins/ImagePlugin/ImageNode'
import { FloatingToolbarPlugin } from './plugins/FloatingToolbarPlugin'
import InlineImagePlugin from './plugins/ImagePlugin/ImagePlugin'
import { ToolbarPlugin } from './plugins/ToolbarPlugin'
import { HtmlValuePlugin } from './plugins/HtmlValuePlugin'
import { YouTubeNode, YouTubePlugin } from './plugins/YouTubePlugin'
import { urlMatcher } from './libs'
import './lexical.css'

export interface LexicalProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  autofocus?: boolean
  readonly?: boolean
  value: string
  onChange: (value: string) => void
}

const nodes = [
  HeadingNode,
  ListNode,
  ListItemNode,
  LinkNode,
  AutoLinkNode,
  HorizontalRuleNode,
  CodeNode,
  QuoteNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  InlineImageNode,
  YouTubeNode,
]

const onError = (error: Error) => {
  console.error(error)
}

export const editorMotionProps = getMotionProps({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})

export const Lexical = memo(({ value, onChange, autofocus = false, readonly = false, ...rest }: LexicalProps) => {
  const { t } = useTranslation()
  const [mounted, setMounted] = useState(false)
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null)

  const initialConfig: InitialConfigType = {
    namespace: 'MyEditor',
    editable: !readonly,
    nodes,
    onError,
  }

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 50)
  }, [setMounted])

  const onRef = useCallback(
    (_floatingAnchorElem: HTMLDivElement) => {
      if (_floatingAnchorElem !== null) {
        setFloatingAnchorElem(_floatingAnchorElem)
      }
    },
    [setFloatingAnchorElem],
  )

  const onChangeContent = useCallback(
    (_: EditorState, editor: LexicalEditor) => {
      editor.update(() => {
        onChange($generateHtmlFromNodes(editor))
      })
    },
    [onChange],
  )

  return (
    <div
      {...rest}
      className={cn(
        'relative w-full rounded-md border border-border-primary shadow-xs',
        readonly && 'pointer-events-none',
        rest.className,
      )}
    >
      <LexicalComposer initialConfig={initialConfig}>
        {floatingAnchorElem && (
          <>
            <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
            <FloatingToolbarPlugin anchorElem={floatingAnchorElem} />
          </>
        )}

        <ToolbarPlugin />

        {mounted && (
          <motion.div className="relative flex h-full flex-col" {...editorMotionProps}>
            <RichTextPlugin
              contentEditable={
                <div ref={onRef} className="flex h-full">
                  <ContentEditable className="prose size-full max-w-none px-8 py-4 outline-none" />
                </div>
              }
              placeholder={
                !readonly ? (
                  <div className="prose pointer-events-none absolute left-0 top-0 px-8 py-4 text-text-quarterary">
                    {t('lexical.placeholder')}
                  </div>
                ) : null
              }
              ErrorBoundary={err => <div>{err.children}</div>}
            />
          </motion.div>
        )}

        <HtmlValuePlugin value={value} />
        <OnChangePlugin onChange={onChangeContent} />
        <ComponentPickerMenuPlugin />
        <LinkPlugin />
        <ListPlugin />
        <InlineImagePlugin />
        <AutoLinkPlugin matchers={urlMatcher} />
        <YouTubePlugin />
        <MarkdownShortcutPlugin />
        <HistoryPlugin />
        {autofocus && <AutoFocusPlugin />}
      </LexicalComposer>
    </div>
  )
})
