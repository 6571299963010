import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ItemRead } from '../../../api/dashboard'
import { Button, Modal, ModalFooter, ModalProps, ModalTitle } from '../../../components/ui'
import { useItemBulkUpdateMutate } from '../api'

interface BulkUnarchiveModalProps extends ModalProps {
  items: ItemRead[]
  onReset?: (items: string[]) => void
}

export const BulkUnarchiveModal = ({ items, onReset, ...rest }: BulkUnarchiveModalProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { mutateAsync, isPending } = useItemBulkUpdateMutate(companyId, gameId)

  const onSubmit = async () => {
    await mutateAsync({ data: items.map(i => ({ id: i.id, archived_at: null as unknown as undefined })) })
    rest.onClose?.()
    onReset?.([])
  }

  return (
    <Modal className="max-w-[600px]" {...rest}>
      <ModalTitle>
        {items.length > 1
          ? t('sku.bulk-unarchive.modal.title-multiple', { count: items.length })
          : t('sku.bulk-unarchive.modal.title-single', { itemName: items.at(0)?.name })}
      </ModalTitle>
      <div>
        <p className="text-paragraph-md text-text-secondary">{t('sku.bulk-unarchive.modal.desc')}</p>
      </div>
      <ModalFooter>
        <Button type="button" size="lg" onClick={rest.onClose}>
          {t('sku.bulk-unarchive.modal.cancel')}
        </Button>
        <Button type="submit" variant="primary" size="lg" isLoading={isPending} onClick={onSubmit}>
          {t('sku.bulk-unarchive.modal.submit')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
