import { useEffect } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom'
import { getLastCompanyId, getLastGameId } from '../Settings'
import { HUB_API } from '../HubAPI'
import { useAuth0 } from '@auth0/auth0-react'

export default function GoDoc() {
  const navigate = useNavigate()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const loadToken = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: import.meta.env.VITE_AUTH0_API_AUDIENCE,
        scope: 'read:current_user',
      },
    })
    HUB_API.setToken(accessToken)
  }

  const load = async () => {
    await loadToken()
    let lastGameId = getLastGameId()
    let lastCompanyId = getLastCompanyId()
    if (!lastGameId || !lastCompanyId) {
      let companies = await HUB_API.getCompanies()
      let companyWithGame = companies.find(c => c.games.length > 0)
      if (!companyWithGame) {
        navigate('/onboarding')
        return
      }
      lastCompanyId = companyWithGame.id
      lastGameId = companyWithGame.games[0].id
    }

    let goto = window.location.pathname.replace('go', `company/${lastCompanyId}/${lastGameId}`)
    navigate(goto)
  }

  useEffect(() => {
    if (isAuthenticated) {
      load()
    }
  }, [isAuthenticated])

  return (
    <Box
      sx={{
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </Box>
  )
}
