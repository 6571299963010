import { useEffect, useRef } from 'react'

import styled from '@emotion/styled'
import AnimatedDots from '../../../../components/animated-dots/AnimatedDots'
import { KeyValue } from '../../../../types'
import i18next from 'i18next'
import { Logo } from '../../../../components/icons/Logo'
import { useParams } from 'react-router-dom'
import { formatMoney } from '../../../../util'
import { useBalanceQuery } from '@/layouts/dashboard/api/useBalanceQuery'
import { useCurrentGame } from '@/api/useCompaniesQuery'

const Root = styled.div`
  display: flex;
  width: 460px;
  min-width: 460px;
  height: 289px;
  flex-direction: column;
  justify-content: space-between;
  padding: 36px;
  background-size: cover;
  background-image: url(/icons/balance-card.png);
  background-origin: border-box;
  border-radius: 18px;
  box-shadow: 0 8px 16px 0 #1018281f;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.01, 1.01);
  }
  border: 2px solid rgba(0, 0, 0, 0.05);
`

const ValueText = styled.span`
  position: relative;
  z-index: 0;
  font-size: 32px;
  line-height: 51px;
  font-family: InterAg;
  font-weight: 600;
  color: ${p => p.theme.palette.text.secondary};
  text-shadow:
    0 2px 2px rgba(71, 85, 105, 0.35),
    0 1px 1px rgba(255, 255, 255, 0.5);
  margin-bottom: 12px;
`

const BalanceSingleValue = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { game } = useCurrentGame()

  const { data: balance, isError } = useBalanceQuery(companyId, gameId, {
    is_sand_box: !!game?.sandbox,
  })

  const handleMove = (e: MouseEvent) => {
    const el = ref.current
    if (!el) {
      return
    }

    const xVal = (e as unknown as KeyValue).layerX as number
    const yVal = (e as unknown as KeyValue).layerY as number
    const width = el.offsetWidth
    const height = el.offsetHeight
    const yRotation = 5 * ((xVal - width / 2) / width)

    const xRotation = -5 * ((yVal - height / 2) / height)

    el.style.transform = 'perspective(700px) scale(1.01) rotateX(' + xRotation + 'deg) rotateY(' + yRotation + 'deg)'
  }

  const onMouseOut = () => {
    const el = ref.current
    if (el) {
      el.style.transform = 'perspective(700px) scale(1) rotateX(0) rotateY(0)'
    }
  }

  useEffect(() => {
    const el = ref.current
    if (!el) {
      return
    }
    el.addEventListener('mousemove', handleMove)

    /* Define function a */

    el.addEventListener('mouseout', onMouseOut)

    return () => {
      el.removeEventListener('mousemove', handleMove)
      el.removeEventListener('mouseout', onMouseOut)
    }
  }, [])

  const strValue = balance ? (formatMoney(balance.value + balance.prev_value!) as string) : ''

  return (
    <Root ref={ref}>
      <div>
        <Logo />
      </div>
      <div className="flex flex-col">
        <span className="text-[12px] font-semibold uppercase text-text-tertiary">{i18next.t('dashboard.balance')}</span>
        {isError ? (
          <span className="text-text-tertiary">{i18next.t('service-unavailable')}</span>
        ) : (
          <ValueText title={strValue} className="ph-no-capture">
            {balance ? <span data-testid={'balance'}>{strValue}</span> : <AnimatedDots />}
          </ValueText>
        )}
        <span className="text-[14px] text-text-primary" style={{ opacity: 0.5 }}>
          {i18next.t('dashboard.balance.desc')}
        </span>
      </div>
    </Root>
  )
}

export default BalanceSingleValue
