import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { StoreUpdate } from '../../../api/dashboard'

interface StoreUpdateMutate {
  id: string
  update: StoreUpdate
}

export const useStoreUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, update }: StoreUpdateMutate) => dashboardClient.v1.updateStore(companyId, gameId, id, update),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['stores', gameId] })
      queryClient.refetchQueries({ queryKey: ['store', variables.id] })
    },
  })
}
