import { useMutation, useQueryClient } from '@tanstack/react-query'
import { LocaleUpdate } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { useGenerateCompanyPath } from '@/libs'
import { localesQueryOptions } from './localesQueryOptions'
import { localeQueryOptions } from './localeQueryOptions'

interface LocaleUpdateMutate {
  localeId: string
  data: LocaleUpdate
}

export const localeUpdateMutation = () => {
  const ql = useQueryClient()
  const { companyId, gameId } = useGenerateCompanyPath()

  return useMutation({
    mutationFn: async ({ localeId, data }: LocaleUpdateMutate) => {
      const r = await dashboardClient.v1.updateLocale(companyId, gameId, localeId, data)
      return r.data
    },
    onMutate: ({ localeId, data }) => {
      ql.setQueryData(localesQueryOptions(companyId, gameId).queryKey, (old = []) => {
        return old.map(locale => {
          if (locale.id === localeId) {
            return { ...locale, ...data }
          }
          return locale
        })
      })

      ql.setQueryData(localeQueryOptions(companyId, gameId, localeId).queryKey, old => {
        return old ? { ...old, ...data } : old
      })
    },
    onSuccess: data => {
      ql.setQueryData(localesQueryOptions(companyId, gameId).queryKey, (old = []) => {
        return old.map(locale => {
          if (locale.id === data.id) {
            return { ...locale, ...data }
          }
          return locale
        })
      })

      ql.setQueryData(localeQueryOptions(companyId, gameId, data.id).queryKey, old => {
        return old ? { ...old, ...data } : old
      })
    },
  })
}
