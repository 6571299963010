import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const UserOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 16 16" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.8412 4.50795C9.8412 5.52485 9.01684 6.34922 7.99993 6.34922C6.98303 6.34922 6.15866 5.52485 6.15866 4.50795C6.15866 3.49104 6.98303 2.66668 7.99993 2.66668C9.01684 2.66668 9.8412 3.49104 9.8412 4.50795ZM11.1745 4.50795C11.1745 6.26123 9.75322 7.68255 7.99993 7.68255C6.24665 7.68255 4.82533 6.26123 4.82533 4.50795C4.82533 2.75466 6.24665 1.33334 7.99993 1.33334C9.75322 1.33334 11.1745 2.75466 11.1745 4.50795ZM12.3809 11.4921C12.3809 11.6646 12.2393 12.1012 11.393 12.5713C10.5919 13.0164 9.39492 13.3333 7.99993 13.3333C6.60494 13.3333 5.40792 13.0164 4.60684 12.5713C3.7606 12.1012 3.61898 11.6646 3.61898 11.4921C3.61898 11.3195 3.7606 10.883 4.60684 10.4128C5.40792 9.96779 6.60494 9.65081 7.99993 9.65081C9.39492 9.65081 10.5919 9.96779 11.393 10.4128C12.2393 10.883 12.3809 11.3195 12.3809 11.4921ZM13.7142 11.4921C13.7142 13.2454 11.1558 14.6667 7.99993 14.6667C4.84402 14.6667 2.28564 13.2454 2.28564 11.4921C2.28564 9.73879 4.84402 8.31747 7.99993 8.31747C11.1558 8.31747 13.7142 9.73879 13.7142 11.4921Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
