import { ReactNode } from 'react'
import AppHeader from '../../header/AppHeader'

export default function FullScreenActionBackground(props: { children: ReactNode; header?: ReactNode }) {
  return (
    <div className="absolute left-0 top-0 z-50 size-full bg-white">
      <div
        className="relative flex size-full flex-col items-center overflow-hidden"
        style={{ width: '100%', height: '100%' }}
      >
        {props.header || <AppHeader />}
        <div
          className="absolute"
          style={{
            top: '-1000px',
            right: '-500px',
            pointerEvents: 'none',
            background: 'radial-gradient(50% 50% at 50% 50%, rgba(236, 72, 153, 0.5) 0%, rgba(236, 72, 153, 0) 100%)',
            opacity: 0.5,
            width: '2000px',
            height: '2000px',
          }}
        />
        <div
          className="absolute"
          style={{
            top: '-1000px',
            left: '-500px',
            pointerEvents: 'none',
            background: 'radial-gradient(50% 50% at 50% 50%, rgba(14, 165, 233, 0.5) 0%, rgba(14, 165, 233, 0) 100%)',
            opacity: 0.5,
            width: '2000px',
            height: '2000px',
          }}
        />

        <div className="flex h-full items-center justify-center">{props.children}</div>
      </div>
    </div>
  )
}
