import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from './index'
import { ItemRead, ResourceState } from './dashboard'

export const DEFAULT_LOAD_LIMIT = 200

type Query = {
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 100
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number
  /** Filter by item state */
  state?: ResourceState

  ids?: string
}

export const useGameItems = (companyId: string, gameId: string, query: Query = {}) =>
  useQuery({
    queryKey: ['items', gameId, { query }],
    queryFn: () =>
      dashboardClient.v1
        .getItems(companyId, gameId, {
          ...query,
          ids: query.ids || '',
        })
        .then(r => r.data),
    staleTime: 60000,
  })

//TODO try to find cache items

export const useGameItem = (companyId: string, gameId: string, id: string) =>
  useQuery({
    queryKey: ['item', gameId, id],
    queryFn: (): Promise<ItemRead | null> => {
      if (!id) return new Promise(resolve => resolve(null))
      return dashboardClient.v1.getItem(companyId, gameId, id).then(r => r.data)
    },
  })
