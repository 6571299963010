import { useTranslation } from 'react-i18next'
import { ItemRead } from '@/api/dashboard'

interface PriceInputProps {
  item: ItemRead
  onClick: () => void
}

export const PriceInput = ({ item, onClick }: PriceInputProps) => {
  const { t } = useTranslation()

  return (
    <div className="inline-flex gap-1.5 rounded-md border border-border-primary p-1.5 shadow-xs">
      <div className="flex h-9 items-center gap-2 rounded-md px-3 text-caption-lg text-text-tertiary">
        <span className="font-semibold">{item.currency}</span>
        <span>{t('intl.Number', { val: item.price / 100 })}</span>
      </div>
      <button
        className="h-9 rounded-md border border-border-brand px-5 text-caption-lg text-text-primary outline-0 transition-colors hover:border-transparent hover:bg-fg-brand-primary-hover hover:text-text-inverse active:border-transparent active:bg-fg-brand-primary-pressed"
        type="button"
        onClick={onClick}
      >
        {t('sku.item.edit-price')}
      </button>
    </div>
  )
}
