import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { DataGranularity, FilterType } from '../../../api/dashboard'

type Query = {
  /** Search String */
  search_string?: string
  /** Status */
  status?: string
  /** User Id */
  user_id?: string

  last_count?: number

  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 100
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number

  /** @default "day" */
  granularity?: DataGranularity

  /** @default "period" */
  type?: FilterType
}

/**
 *
 * @param companyId
 * @param gameId
 * @param query
 */
export const usePaymentsQuery = (companyId: string, gameId: string, query: Query = {}) => {
  return useQuery({
    queryKey: ['payments', gameId, query],
    queryFn: () => dashboardClient.v1.searchPayments(companyId, gameId, query).then(r => r.data),
  })
}
