import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { ErrorMessage } from '@hookform/error-message'
import { dashboardClient } from '@/api'
import { CheckWebhookRequest, WebhookEventType, WebhookRedacted } from '@/api/dashboard'
import { Button, FieldGroup, FieldValidationMessage, Modal, ModalFooter, ModalProps, ModalTitle, Select } from '@/ui'
import { webhookLogQuery } from '../api'
import { webhookSortedKeys } from '../libs'

interface WebhookTestModalProps extends ModalProps {
  webhook: WebhookRedacted
}

type WebhookTestData = CheckWebhookRequest

export const WebhookTestModal = ({ webhook, ...rest }: WebhookTestModalProps) => {
  const ql = useQueryClient()
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    control,
  } = useForm<WebhookTestData>({
    defaultValues: {},
  })

  const onSubmit = handleSubmit(async data => {
    await dashboardClient.v1.checkWebhook(companyId, gameId, webhook.id, data)
    await new Promise(r => setTimeout(r, 4000))
    await ql.refetchQueries({ queryKey: webhookLogQuery(companyId, gameId, webhook.id).queryKey, exact: false })
    rest.onClose?.()
  })

  return (
    <Modal {...rest}>
      <form onSubmit={onSubmit}>
        <ModalTitle>{t('webhook.test-modal.title')}</ModalTitle>

        <FieldGroup label={t('webhook.test-modal.label')}>
          <Controller
            control={control}
            name="event_type"
            rules={{ required: t('validation.required') }}
            render={({ field }) => (
              <Select
                {...field}
                options={Object.values(WebhookEventType)
                  .sort((a, b) => webhookSortedKeys.indexOf(a) - webhookSortedKeys.indexOf(b))
                  .filter(event => event !== 'campaign.custom')
                  .filter(event => webhook.events.includes(event))
                  .map(event => ({
                    children: t(`webhook.event.${event}.label`),
                    value: event,
                  }))}
              />
            )}
          />
          <ErrorMessage
            name="event_type"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <ModalFooter>
          <Button type="button" size="lg" onClick={rest.onClose}>
            {t('webhook.test-modal.cancel-button')}
          </Button>
          <Button variant="primary" size="lg" type="submit" isLoading={isSubmitting}>
            {t('webhook.test-modal.send-button')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
