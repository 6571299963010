import styled from '@emotion/styled'
import { ButtonIcon } from '../ButtonIcon'
import { ChevronLeft, ChevronRight } from '../../icons'
import i18next from 'i18next'
import { CSSProperties } from 'react'

interface PaginationProps {
  hasNext: boolean
  page: number
  onPageChange: (page: number) => void
  pageItems?: number
  style?: CSSProperties
}

const ResultLabel = styled.span`
  color: ${p => p.theme.palette.text.tertiary};
  font-size: 14px;
  font-weight: 400;
  margin-right: auto;
`

const StyledPagination = styled.div`
  background-color: ${p => p.theme.palette.background.content};
  border-top: 1px solid ${p => p.theme.palette.grey.borderSecondary};
  position: sticky;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${p => p.theme.spacing(2.25)} ${p => p.theme.spacing(2.25)};
`

const ButtonContainer = styled.div`
  display: flex;
  gap: ${p => p.theme.spacing(1.25)};
`

export const Pagination = ({ hasNext, page, onPageChange, pageItems, style }: PaginationProps) => {
  const hasPrev = page > 1

  const onClickPrev = () => {
    onPageChange(page - 1)
  }

  const onClickNext = () => {
    onPageChange(page + 1)
  }

  return (
    <StyledPagination style={style}>
      {pageItems !== undefined && (
        <ResultLabel>
          {pageItems} {i18next.t('pagination.results')}
        </ResultLabel>
      )}
      <ButtonContainer>
        <ButtonIcon variant="primary-gray" size="sm" disabled={!hasPrev} onClick={onClickPrev}>
          <ChevronLeft />
        </ButtonIcon>
        <ButtonIcon variant="primary-gray" size="sm" disabled={!hasNext} onClick={onClickNext}>
          <ChevronRight />
        </ButtonIcon>
      </ButtonContainer>
    </StyledPagination>
  )
}
