import { LexicalEditor } from 'lexical'
import { ComponentPickerMenuOption } from '../../components'
import { Menu } from '../../../ui'
import { ComponentPickerOption, getBaseOptions } from '../ComponentPickerPlugin'
import { useMemo } from 'react'

interface ToolbarFormattingMenuProps {
  editor: LexicalEditor
  onClose?: () => void
}

export const ToolbarFormattingMenu = ({ editor, onClose = () => void 0 }: ToolbarFormattingMenuProps) => {
  const options = useMemo(
    () =>
      getBaseOptions(
        editor,
        () => void 0,
        () => void 0,
      ).filter(option => !option.group.includes('media')),
    [editor],
  )

  const onClickOption = (option: ComponentPickerOption) => {
    option.onSelect()
    onClose()
  }

  return (
    <Menu>
      {options.map(option => (
        <ComponentPickerMenuOption option={option} onClick={() => onClickOption(option)} />
      ))}
    </Menu>
  )
}
