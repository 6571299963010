import { Link, useNavigate } from 'react-router-dom'
import Highlight from 'react-highlight'
import { useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { ButtonIcon } from '@/ui'
import { XClose } from '@/icons'
import { GAME_WEBHOOKS_DETAIL_PATH, GAME_WEBHOOKS_PATH, cn, useGenerateCompanyPath, useNavigateBack } from '@/libs'
import { FullscreenLayout } from '@/components/layouts'
import { webhooksQuery } from '@/layouts/webhooks'
import { WebhookForm, WebhookFormData } from '../widgets'
import { CODE_EXAMPLES } from '../code-examples'
import { useBanner } from '@/libs/hooks/useBanner'
import { useTranslation } from 'react-i18next'

export const WebhookNewPage = () => {
  const ql = useQueryClient()
  const { t } = useTranslation()
  const { generatePath, companyId, gameId } = useGenerateCompanyPath()
  const navigate = useNavigate()
  const back = useNavigateBack({ fallback: GAME_WEBHOOKS_PATH })
  const { getStickyTop } = useBanner()
  const onSubmit = async (data: WebhookFormData) => {
    const { data: webhook } = await dashboardClient.v1.createWebhook(companyId, gameId, data)
    navigate(generatePath(GAME_WEBHOOKS_DETAIL_PATH, { webhookId: webhook.id }), { replace: true })
    await ql.invalidateQueries({ queryKey: webhooksQuery(companyId, gameId).queryKey })
  }

  return (
    <FullscreenLayout>
      <div className="grid h-screen grid-rows-[auto_1fr]">
        <div className={cn('sticky left-0 z-10 grid grid-cols-2 border-b border-b-border-primary', getStickyTop())}>
          <div className="flex items-center gap-3 border-r border-r-border-primary bg-fg-primary px-5 py-2.5">
            <Link {...back}>
              <ButtonIcon variant="secondary-gray" size="sm">
                <XClose />
              </ButtonIcon>
            </Link>
            <div className="text-t6"> {t('webhook.create')}</div>
          </div>
          <div className="flex items-center bg-fg-secondary px-5 py-2.5 text-caption-md text-text-quarterary-hover">
            {t('webhook.example-code')}
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div className="h-full border-r border-r-border-primary bg-fg-primary px-5 py-12">
            <div className="mx-auto flex h-full max-w-xl">
              <WebhookForm onSubmit={onSubmit} />
            </div>
          </div>
          <div className="max-h-[calc(100vh-54px)] overflow-y-auto bg-fg-secondary p-5">
            <Highlight>{CODE_EXAMPLES.python}</Highlight>
          </div>
        </div>
      </div>
    </FullscreenLayout>
  )
}
