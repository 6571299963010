import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

interface LeaderboardDeleteMutate {
  id: string
}

export const useLeaderboardDeleteMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id }: LeaderboardDeleteMutate) => dashboardClient.v1.deleteLeaderboard(companyId, gameId, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['leaderboards', gameId] })
    },
  })
}
