import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import i18next from 'i18next'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import TableBar from '../../components/TableBar'
import { PAGE_SIZE } from '../../Settings'
import { useTheme } from '@mui/material/styles'
import Paginator from '../../components/Paginator'
import { LeaderboardMemberRead, LeaderboardRead } from '../../api/dashboard'
import EditMemberDialog from './EditMemberDialog'
import { dashboardClient } from '../../api'
import GridRowEditButton from '../../components/GridRowEditButton'
import { AuthContext } from '../../Context'
import { isAdmin } from '../../security'
import { DEFAULT_SX_TABLE_12 } from '../../theme/Components'
import ImageCellNoText from '../../components/ImageCellNoText'

export default function LeaderBoardMembersTable() {
  const theme = useTheme()
  const context = useContext(AuthContext)
  const canUserEdit = isAdmin(context?.customer)
  const [items, setItems] = useState<LeaderboardMemberRead[]>([])
  const [, setLoading] = useState(true)
  const [page, setPage] = useState<number>(0)
  const [leaderboard, setLeaderboard] = useState<LeaderboardRead | null>(null)
  const { companyId, gameId, leaderboardId } = useParams() as {
    companyId: string
    gameId: string
    leaderboardId: string
  }
  const [editItem, setEditItem] = useState<LeaderboardMemberRead | null>(null)

  const loadItems = async () => {
    setLoading(true)
    try {
      dashboardClient.v1.getLeaderboard(companyId, gameId, leaderboardId).then(res => setLeaderboard(res.data))

      let { data } = await dashboardClient.v1.getLeaderboardMembers(companyId, gameId, leaderboardId, {
        offset: PAGE_SIZE * page,
        limit: PAGE_SIZE,
      })

      setItems(data)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadItems()
  }, [gameId, leaderboardId, page])

  return (
    <Box>
      <TableBar title={leaderboard?.name || ''} sticky />

      <Table sx={{ ...DEFAULT_SX_TABLE_12 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" fontWeight={600}>
                {i18next.t('leaderboard.members.rank')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" fontWeight={600}>
                {i18next.t('leaderboard.members.name')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" fontWeight={600}>
                {i18next.t('leaderboard.members.score')}
              </Typography>
            </TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((it, idx) => (
            <TableRow key={idx}>
              <TableCell>
                <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                  {it.rank}
                </Typography>
              </TableCell>
              <TableCell>
                <Box display="flex" gap={1} alignItems="center">
                  <ImageCellNoText image_url={it.image_url} image_size={theme.spacing(4)} />
                  <Typography color={theme.palette.text.primary} variant="subtitle2">
                    {it.member_data}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography color={theme.palette.text.secondary} variant="subtitle1">
                  {it.score}
                </Typography>
              </TableCell>
              <TableCell align="right">
                {canUserEdit && <GridRowEditButton onEditClick={() => setEditItem(it)} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Paginator
        enablePrev={page > 0}
        enableNext={items.length == PAGE_SIZE}
        prevClick={() => setPage(page - 1)}
        nextClick={() => setPage(page + 1)}
      />

      {editItem && (
        <EditMemberDialog
          item={editItem}
          onClose={(saved: boolean) => {
            setEditItem(null)
            if (saved) {
              loadItems()
            }
          }}
        />
      )}
    </Box>
  )
}
