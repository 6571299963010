import { HTMLAttributes, ReactElement, cloneElement, forwardRef, useMemo, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import {
  FloatingArrow,
  FloatingPortal,
  Placement,
  arrow,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useMergeRefs,
} from '@floating-ui/react'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'

interface TooltipProps extends HTMLAttributes<HTMLElement> {
  message: string
  desc: string
  children: ReactElement
}

const Floating = styled.div`
  z-index: 2900;
`

const WarningMessage = styled(motion.div)`
  color: ${p => p.theme.palette.text.table};
  font-size: 16px;
  font-weight: 600;
`

const WarningDesc = styled.span`
  color: ${p => p.theme.palette.text.tertiary};
  font-size: 16px;
  font-weight: 600;
`

const RootStyle = styled.div`
  border: 2px solid ${p => p.theme.palette.warning.light3};
  background-color: ${p => p.theme.palette.background.content};
  width: 298px;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
`

export const WarningTooltip = forwardRef<HTMLElement, TooltipProps>(function Tooltip(
  { children, message, desc, ...rest },
  ref,
) {
  const theme = useTheme()
  const arrowRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const options = {
    distance: 12,
    delay: { open: 250, close: 0 },
    disabled: false,
    supportingText: false,
    closeOnPress: false,
    placement: 'right' as Placement,
  }

  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    placement: options.placement,
    middleware: [arrow({ element: arrowRef }), shift({ padding: 8 }), offset(options.distance), flip()],
    onOpenChange: setIsOpen,
  })

  const { arrow: arrowData, shift: shiftData } = context.middlewareData

  const arrowStaticPosition = useMemo(
    () => ({
      left: (shiftData?.x ?? 0) > 0 ? (arrowData?.x ?? 0) - (shiftData?.x ?? 0) : (arrowData?.x ?? undefined),
      top: (shiftData?.y ?? 0) > 0 ? (arrowData?.y ?? 0) - (shiftData?.y ?? 0) : (arrowData?.y ?? undefined),
    }),
    [shiftData, arrowData],
  )

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      delay: options.delay,
      enabled: !options.disabled,
      handleClose: safePolygon({ requireIntent: true }),
    }),
    useDismiss(context, {
      referencePress: options.closeOnPress,
    }),
  ])

  const referenceMergedRef = useMergeRefs([refs.setReference, ref])

  return (
    <>
      {cloneElement(children, {
        ...rest,
        ...getReferenceProps(),
        ref: referenceMergedRef,
      })}

      <AnimatePresence>
        {!options.disabled && isOpen && (
          <FloatingPortal>
            <Floating ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
              <FloatingArrow
                ref={arrowRef}
                context={context}
                tipRadius={4}
                width={12}
                height={8}
                staticOffset={arrowStaticPosition.left || arrowStaticPosition.top || undefined}
                fill={theme.palette.warning.light3}
              />
              <RootStyle>
                <WarningMessage>{message}</WarningMessage>
                <WarningDesc>{desc}</WarningDesc>
              </RootStyle>
            </Floating>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </>
  )
})
