import { HTMLAttributes, forwardRef } from 'react'
import { cn } from '@/libs'

interface ImageLazyProps extends HTMLAttributes<HTMLImageElement> {
  src: string
  alt: string
}

export const ImageLazy = forwardRef<HTMLImageElement, ImageLazyProps>(function ImageLazy({ src, alt, ...rest }, ref) {
  return (
    <img
      {...rest}
      ref={ref}
      className={cn('m-0 block', rest.className)}
      src={src}
      alt={alt}
      draggable="false"
      data-categories="essential"
    />
  )
})
