import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import { Link, generatePath, useParams } from 'react-router-dom'
import { HUB_EDITOR_LEFT_SIDE_WIDTH } from '../Settings'
import i18next from 'i18next'
import ButtonIconGroup from '../components/ButtonIconGroup'
import { EditorMode } from './editor-types'
import { DesktopIcon, IPadIcon, IPhoneIcon, ResponsiveIcon } from '../icons/Icons'
import SaveButton from '../components/SaveButton'
import { SxProps } from '@mui/system/styleFunctionSx'
import { HUB_THEMES_PATH } from '../libs/routerPaths'
import { Badge, ButtonIcon, Select } from '../components/ui'
import { Logout } from '../components/icons'
import { PageRead, WebsiteRead } from '../api/dashboard'

const HubEditorHeader = (props: {
  webSite: WebsiteRead
  selectedPage: PageRead | null
  setSelectedPage: (page: PageRead | null) => void
  editorMode: EditorMode
  saveClick: () => void
  previewClick: () => void
  newPageClick: () => void
  setEditorMode: (value: EditorMode) => void
  previewMode: boolean
  pages: PageRead[]
}) => {
  const theme = useTheme()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const sortedPages = props.pages.sort(a => (a.slug === 'home' ? -1 : 1))

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg')) //big screen

  const leftControlStyles = lgUp
    ? {
        position: 'absolute',
        right: theme.spacing(1.75),
      }
    : ({
        marginLeft: 'auto',
        marginRight: theme.spacing(1.75),
      } as SxProps<Theme>)

  return (
    <div className="flex h-[56px] w-full items-center border-b border-border-secondary bg-fg-primary">
      <Box
        sx={{
          width: HUB_EDITOR_LEFT_SIDE_WIDTH,
          display: 'flex',
          height: '100%',
        }}
      >
        {!props.previewMode && (
          <>
            <div className="flex items-center pl-3">
              <Link to={generatePath(HUB_THEMES_PATH, { companyId, gameId })}>
                <ButtonIcon variant="tertiary-gray">
                  <Logout />
                </ButtonIcon>
              </Link>
            </div>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              height="100%"
              sx={{
                gap: theme.spacing(1.75),
                borderRadius: 0,
              }}
              flexGrow={1}
            >
              <Typography
                variant="subtitle1"
                fontWeight="500"
                sx={{
                  marginLeft: theme.spacing(1.75),
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: theme.spacing(12),
                }}
              >
                {props.webSite.title}
              </Typography>

              <Badge variant="brand-blue-tertiary">Alpha</Badge>
            </Box>
          </>
        )}
      </Box>

      <Box
        flexGrow={1}
        display="flex"
        justifyContent={lgUp ? 'center' : 'flex-start'}
        alignItems={'center'}
        sx={{ position: 'relative' }}
      >
        {!props.previewMode && (
          <div className={'w-[272px]'}>
            <Select
              name="page"
              value={props.selectedPage?.id || null}
              onChange={v => {
                if (v == 'new') {
                  props.newPageClick()
                } else {
                  props.setSelectedPage(props.pages.find(it => it.id == v) || null)
                }
              }}
              options={[
                ...sortedPages.map(it => ({
                  children: it.title,
                  value: it.id,
                })),
                {
                  children: i18next.t('hub-editor.new-page') + '...',
                  value: 'new',
                },
              ]}
            />
          </div>
        )}

        <Stack spacing={1} direction="row" sx={leftControlStyles}>
          {!props.previewMode && (
            <ButtonIconGroup
              items={[
                { icon: <IPhoneIcon />, value: EditorMode.Mobile },
                { icon: <IPadIcon />, value: EditorMode.Tablet },
                { icon: <DesktopIcon />, value: EditorMode.Desktop },
                { icon: <ResponsiveIcon />, value: EditorMode.Responsive },
              ]}
              value={props.editorMode}
              onSelected={value => props.setEditorMode(value as unknown as EditorMode)}
            />
          )}
          <Button variant={'outlined'} size="small" color="primary" onClick={props.previewClick}>
            {i18next.t(props.previewMode ? 'hub-editor.back-to-edit' : 'hub-editor.preview')}
          </Button>

          {!props.previewMode && (
            <SaveButton variant={'contained'} size="small" color="primary" onClick={props.saveClick}>
              {i18next.t('Save')}
            </SaveButton>
          )}
        </Stack>
      </Box>
    </div>
  )
}

export default HubEditorHeader
