import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <path
      d="M17.24,8.85l-3.56-3.59c-.6-.62-1.11-.76-2.02-.76h-3.37c-1.49,0-2.3.8-2.3,2.31v10.38c0,1.51.8,2.31,2.3,2.31h7.41c1.5,0,2.3-.8,2.3-2.31v-6.26c0-.98-.14-1.44-.76-2.08ZM16.5,17.36c0,.35-.28.64-.64.64h-7.73c-.35,0-.64-.28-.64-.64V6.64c0-.35.28-.64.64-.64h2.3c.35,0,.64.28.64.64v2.78c0,.7.57,1.27,1.28,1.27h3.51c.35,0,.64.28.64.64v6.03h0Z"
      fill="currentColor"
    />,
  ],
  [
    'fill',
    <>
      <path
        d="M12.35,10.69h4.75c.49,0,.9.4.9.9v5.6c0,1.51-.8,2.31-2.3,2.31h-7.41c-1.5,0-2.3-.8-2.3-2.31V6.81c0-1.51.8-2.31,2.3-2.31h1.88c.49,0,.9.41.9.9v4.01c0,.7.57,1.27,1.27,1.27Z"
        fill="currentColor"
      />
      <path
        d="M17.54,9.19c.07.09,0,.22-.12.22h-4.54c-.29,0-.52-.23-.52-.53v-4.17c0-.09.08-.16.17-.14.43.09.77.3,1.16.68l3.56,3.59c.11.12.21.23.29.34Z"
        fill="currentColor"
      />
    </>,
  ],
  [
    'duotone',
    <>
      <rect x="0" y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12C0,21.31,2.69,24,6,24h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <path
        d="M12.35,10.69h4.75c.49,0,.9.4.9.9v5.6c0,1.51-.8,2.31-2.3,2.31h-7.41c-1.5,0-2.3-.8-2.3-2.31V6.81c0-1.51.8-2.31,2.3-2.31h1.88c.49,0,.9.41.9.9v4.01c0,.7.57,1.27,1.27,1.27Z"
        fill="currentColor"
      />
      <path
        d="M17.54,9.19c.07.09,0,.22-.12.22h-4.54c-.29,0-.52-.23-.52-.53v-4.17c0-.09.08-.16.17-.14.43.09.77.3,1.16.68l3.56,3.59c.11.12.21.23.29.34Z"
        fill="currentColor"
      />
    </>,
  ],
])

export const Documents = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
