import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LexicalEditor } from 'lexical'
import { S3Bucket } from '../../../../types'
import { ImageIndentFull, ImageIndentLeft, ImageIndentRight } from '../../../icons'
import {
  Button,
  FieldGroup,
  FieldValidationMessage,
  ImageUploader,
  Modal,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
} from '../../../ui'
import { InlineImagePayload } from './ImageNode'
import { INSERT_INLINE_IMAGE_COMMAND } from './ImagePlugin'
import { ErrorMessage } from '@hookform/error-message'

interface ImagePluginInsertModalProps extends ModalProps {
  editor: LexicalEditor
}

export const ImagePluginInsertModal = ({ editor, onClose = () => void 0 }: ImagePluginInsertModalProps) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<InlineImagePayload>({
    defaultValues: {
      position: 'full',
      src: '',
    },
  })

  const onSubmit = (data: InlineImagePayload) => {
    editor.dispatchCommand(INSERT_INLINE_IMAGE_COMMAND, { ...data, alt: '' })
    onClose()
  }

  return (
    <Modal onClose={onClose}>
      <ModalTitle>{t('lexical.insert-image-modal.title')}</ModalTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldGroup label={t('lexical.insert-image-modal.align.label')} size="sm">
          <Controller
            name="position"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={[
                  {
                    children: t('lexical.insert-image-modal.align.left'),
                    value: 'left',
                    icon: <ImageIndentLeft className="text-text-brand-primary" size={20} />,
                  },
                  {
                    children: t('lexical.insert-image-modal.align.full'),
                    value: 'full',
                    icon: <ImageIndentFull className="text-text-brand-primary" size={20} />,
                  },
                  {
                    children: t('lexical.insert-image-modal.align.right'),
                    value: 'right',
                    icon: <ImageIndentRight className="text-text-brand-primary" size={20} />,
                  },
                ]}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup>
          <Controller
            name="src"
            control={control}
            rules={{ required: t('validation.required') }}
            render={({ field }) => (
              <ImageUploader
                {...field}
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                  'image/gif': [],
                }}
                bucket={S3Bucket.news}
              />
            )}
          />
          <ErrorMessage
            name="src"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <ModalFooter>
          <Button variant="outline" size="lg" type="button" onClick={onClose}>
            {t('lexical.insert-image-modal.cancel')}
          </Button>
          <Button variant="primary" size="lg" type="submit" disabled={isSubmitting}>
            {t('lexical.insert-image-modal.save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
