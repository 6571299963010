import { Placement } from '@floating-ui/react'
import { getMotionProps } from '../../../libs'

/**
 * The function will return props for animation depending on the position of the popup.
 * @param placement
 */
export const getFloatingMotionProps = (placement: Placement | undefined) => {
  const y = placement?.includes('top') ? 4 : -4
  return getMotionProps(
    {
      initial: { y, opacity: 0 },
      animate: { y: 0, opacity: 1 },
      exit: { y, opacity: 0 },
    },
    { duration: 0.1 },
  )
}
