import { useContext, useState } from 'react'
import i18next from 'i18next'
import Dialog from '@mui/material/Dialog'
import { useParams } from 'react-router-dom'
import DialogHeader from '../../components/ui/Dialog/DialogHeader'
import { Button, FieldGroup, FieldValidationMessage, Select } from '../../components/ui'
import { Controller, useForm } from 'react-hook-form'
import { CustomerCompanyRead, Role } from '../../api/dashboard'
import { ResponseError, dashboardClient, getErrorText } from '../../api'
import UserAvName from './components/UserAvName'
import { isSuperAdmin } from '../../util'
import { AuthContext, IAuthContext } from '../../Context'

const EditMemberDialog = (props: { onClose: () => void; item: CustomerCompanyRead }) => {
  const { companyId } = useParams() as { companyId: string }
  const context = useContext(AuthContext) as IAuthContext
  const [helperText, setHelperText] = useState('')

  const { control, handleSubmit } = useForm<CustomerCompanyRead>({
    defaultValues: {
      ...props.item,
    },
  })

  const saveClick = async (data: CustomerCompanyRead) => {
    setHelperText('')
    try {
      await dashboardClient.v1.updateMember(data.customer.id, companyId, {
        role: data.role,
      })

      props.onClose()
      return true
    } catch (e) {
      setHelperText(getErrorText(e as ResponseError))
    }
  }

  return (
    <Dialog
      onClose={() => props.onClose()}
      open={true}
      sx={{ '& .MuiDialog-paper': { width: '572px', padding: '40px', borderRadius: '24px', margin: 0 } }}
    >
      <DialogHeader title={i18next.t('users.role.edit')} />

      <div className="mt-10 flex gap-3">
        <div style={{ width: '304px' }}>
          <FieldGroup label={i18next.t('users.member')}>
            <div
              className="flex w-full items-center rounded-md border border-border-primary bg-fg-disabled px-1"
              style={{ height: '48px' }}
            >
              <UserAvName name={props.item.customer.name || ''} email={props.item.customer.email} />
            </div>
          </FieldGroup>
        </div>
        <div className="grow">
          <FieldGroup label={i18next.t('users.role')}>
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <Select
                  dropDownWidth="280px"
                  {...field}
                  options={[
                    isSuperAdmin(context.customer)
                      ? {
                          value: Role.Viewer,
                          children: i18next.t('users.role.viewer'),
                          desc: i18next.t('users.role.viewer.desc'),
                        }
                      : undefined,
                    {
                      value: Role.User,
                      children: i18next.t('users.role.user'),
                      desc: i18next.t('users.role.user.desc'),
                    },
                    {
                      value: Role.Editor,
                      children: i18next.t('users.role.editor'),
                      desc: i18next.t('users.role.editor.desc'),
                    },
                    {
                      value: Role.Admin,
                      children: i18next.t('users.role.admin'),
                      desc: i18next.t('users.role.admin.desc'),
                    },
                  ]}
                />
              )}
            />
          </FieldGroup>
        </div>
      </div>

      {helperText && <FieldValidationMessage>{helperText}</FieldValidationMessage>}

      <div className="mt-10 flex justify-end gap-4">
        <Button size="lg" onClick={() => props.onClose()}>
          {i18next.t('Cancel')}
        </Button>
        <Button size="lg" variant="primary" onClick={handleSubmit(saveClick)}>
          {i18next.t('Save2')}
        </Button>
      </div>
    </Dialog>
  )
}

export default EditMemberDialog
