import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

interface AchievementDeleteMutate {
  id: string
}

export const useAchievementDeleteMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id }: AchievementDeleteMutate) => dashboardClient.v1.deleteAchievement(companyId, gameId, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['achievements', gameId] })
    },
  })
}
