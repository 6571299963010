import { ChangeEvent, ReactNode, forwardRef, useRef } from 'react'
import styled from '@emotion/styled'
import { Button, Floating, Input, InputProps, Label, Menu, MenuOption } from '@/ui'
import i18next from 'i18next'
import { ClipboardDownload } from '../../../../components/icons'

interface InputVarsProps extends InputProps {
  label: ReactNode
  items: { value: string; name: string }[]
}

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

interface VariableSelectMenuProps {
  onClose?: () => void
}

export const InputVars = forwardRef<HTMLInputElement, InputVarsProps>(function InputVars({
  items,
  label,
  value,
  onChange,
  name,
}) {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onItemSelected = (v: string) => {
    if (!inputRef?.current) {
      return
    }

    if (!value) {
      return onChange?.({ target: { value: v, name: name } } as unknown as ChangeEvent<HTMLInputElement>)
    }

    let startPos = inputRef.current.selectionStart || 0

    let oldValue = value as string
    let newValue = oldValue.substring(0, startPos) + v + oldValue.substring(startPos)

    onChange?.({ target: { value: newValue, name: name } } as unknown as ChangeEvent<HTMLInputElement>)
  }

  const VariableSelectMenu = ({ onClose = () => void 0 }: VariableSelectMenuProps) => {
    const onClickVariable = (variable: string) => {
      onClose()
      onItemSelected(variable)
    }

    return (
      <Menu>
        {items.map(it => (
          <MenuOption onClick={() => onClickVariable(it.value)} key={it.value}>
            {it.name}
          </MenuOption>
        ))}
      </Menu>
    )
  }

  return (
    <StyledRoot>
      <StyledHeader>
        <Label $size="md">{label}</Label>

        <Floating menu={<VariableSelectMenu />} referenceWidth={true}>
          <Button>
            <ClipboardDownload />
            {i18next.t('campaign.insert-var')}
          </Button>
        </Floating>
      </StyledHeader>

      <Input value={value} ref={inputRef} onChange={onChange} />
    </StyledRoot>
  )
})
