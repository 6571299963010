import { Link } from 'react-router-dom'
import { ButtonIcon, Floating, Skeleton } from '../../../components/ui'
import { DotsHorizontal, FlipBackward } from '../../../components/icons'
import { useNavigateBack } from '../../../libs/hooks'
import { GAME_WEBHOOKS_PATH } from '../../../libs/routerPaths'
import { WebhookRedacted } from '../../../api/dashboard'
import { WebhookMoreMenu } from './WebhookMoreMenu'
import { useContext } from 'react'
import { AuthContext, IAuthContext } from '@/Context'
import { isAdmin } from '@/security'
import { useTranslation } from 'react-i18next'

interface WebhookTitleBarProps {
  webhook: WebhookRedacted
}

export const WebhookTitleBar = ({ webhook }: WebhookTitleBarProps) => {
  const back = useNavigateBack({ fallback: GAME_WEBHOOKS_PATH })
  const context = useContext(AuthContext) as IAuthContext
  const { t } = useTranslation()
  return (
    <div className="mb-8 mt-3 flex gap-3">
      <Link {...back}>
        <ButtonIcon variant="secondary-gray" size="sm">
          <FlipBackward />
        </ButtonIcon>
      </Link>
      <div className="overflow-hidden">
        <div className="mb-0.5 text-caption-xs leading-none text-text-tertiary">{t('webhook.back')}</div>
        <div className="w-full truncate text-t5">{webhook.url}</div>
      </div>
      {isAdmin(context.customer) && (
        <div className="ml-auto">
          <Floating menu={<WebhookMoreMenu webhook={webhook} />} placement="bottom-end">
            <ButtonIcon variant="secondary-gray" size="sm">
              <DotsHorizontal />
            </ButtonIcon>
          </Floating>
        </div>
      )}
    </div>
  )
}

export const WebhookTitleBarSkeleton = () => (
  <div className="mb-8 mt-3 flex gap-3">
    <Skeleton className="size-8" />
    <div>
      <Skeleton className="mb-0.5 h-2.5 w-24" />
      <Skeleton className="h-[22px] w-64" />
    </div>
  </div>
)
