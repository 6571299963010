import { ReactNode, createElement } from 'react'
import { AghanimIcon, CloseOutline } from '@/icons'
import { cn } from '@/libs'
import { cva } from 'class-variance-authority'

interface CalloutProps {
  icon: AghanimIcon
  variant?: 'default' | 'error'
  children?: ReactNode
  label?: ReactNode
  action?: ReactNode
  onClose?: () => void
}

const variants = cva('rounded-xl border p-3 shadow-xs', {
  variants: {
    variant: {
      default: [
        'border-border-primary',
        'bg-fg-primary',
        '[--label-color:theme(colors.text-gray-primary)]',
        '[--text-color:theme(colors.text-secondary)]',
      ],
      error: [
        'border-border-error',
        'bg-fg-primary',
        '[--label-color:theme(colors.text-error-solid)]',
        '[--text-color:theme(colors.text-error-solid)]',
      ],
    },
    defaultVariants: {
      variant: 'default',
    },
  },
})

export const Callout = ({ icon, variant = 'default', children, label, action, onClose }: CalloutProps) => (
  <div className={cn(variants({ variant }))}>
    <div className="flex items-start gap-3">
      <div className="flex h-5 shrink-0 items-center text-[--label-color]">{createElement(icon, { size: 16 })}</div>
      <div className="w-full">
        {label && <div className="text-caption-lg text-[--label-color]">{label}</div>}
        {children && <div className={cn('text-paragraph-sm text-[--text-color]', label && 'mt-1.5')}>{children}</div>}
      </div>
      <div className="flex shrink-0 gap-3">
        {action && (
          <>
            <div className="text-caption-md text-text-secondary hover:text-text-primary">{action}</div>
            <div className="h-[18px] w-px bg-border-secondary" />
          </>
        )}
        {onClose && (
          <button
            className="flex h-[18px] shrink-0 items-center text-text-quarterary-hover hover:text-text-primary"
            type="button"
            onClick={onClose}
          >
            <CloseOutline size={16} />
          </button>
        )}
      </div>
    </div>
  </div>
)
