import { InputHTMLAttributes, forwardRef } from 'react'
import { Search } from '@/icons'
import { cn } from '@/libs'

interface ToolbarSearchProps extends InputHTMLAttributes<HTMLInputElement> {}

export const ToolbarSearch = forwardRef<HTMLInputElement, ToolbarSearchProps>(function ({ ...rest }, ref) {
  return (
    <label
      className={cn(
        'group/input-search flex w-full cursor-text items-center gap-3 rounded-md px-3 py-1.5 text-text-tertiary transition-colors',
        'has-[:focus]:bg-fg-primary-hover has-[:hover]:bg-fg-primary-hover has-[:hover]:text-text-secondary',
      )}
    >
      <Search size={14} />
      <input
        {...rest}
        ref={ref}
        className={cn(
          'flex bg-transparent text-caption-md font-normal text-text-secondary outline-none placeholder:text-text-tertiary',
          rest.className,
        )}
        type="text"
      />
    </label>
  )
})
