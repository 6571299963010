import { HTMLAttributes, ReactNode } from 'react'
import { cn } from '@/libs'

interface SidebarGroupProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  label?: ReactNode
}

export const SidebarGroup = ({ children, label, ...rest }: SidebarGroupProps) => (
  <div {...rest} className={cn('mb-4 last:mb-0', rest.className)}>
    {label && <div className="my-3 text-caption-xs uppercase text-text-quarterary-hover">{label}</div>}
    <div>{children}</div>
  </div>
)
