import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

type Query = {
  is_sand_box: boolean
}

/**
 * @param companyId
 * @param gameId
 * @param query
 */
export const useBalanceQuery = (companyId: string, gameId: string, query: Query = { is_sand_box: false }) => {
  return useQuery({
    queryKey: ['balance', companyId, gameId, query],
    queryFn: () => dashboardClient.v1.getBalance(companyId, gameId, query),
    select: response => response.data,
  })
}
