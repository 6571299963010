import { WSEventType } from './types'
import { IAuthContext } from './Context'
import { GenerationProgressState } from './api/dashboard'

export const EVENTS_API_URL = import.meta.env.VITE_EVENTS_API_URL

let ws: WebSocket | null = null
let connectedCompanyId: string

export const closeWS = () => {
  if (ws) {
    console.log('WebSocket connection closed manually')
    ws.onopen = null
    ws.onmessage = null
    ws.onclose = null
    ws.close()
    ws = null
  }
}

/**
 * @deprecated please use `useCompanyEventsSocket`
 * @param context
 */
export const connectToWS = (context: IAuthContext | null) => {
  if (connectedCompanyId === context?.currentCompanyId || !context?.currentCompanyId) {
    return
  }

  if (!EVENTS_API_URL) {
    console.log('REACT_APP_EVENTS_API_URL is not defined. Messages from the server cannot be received')
    return
  }

  // close before open new
  if (ws && connectedCompanyId !== context?.currentCompanyId) {
    closeWS()
  }

  connectedCompanyId = context.currentCompanyId as string

  return new Promise<void>((resolve, reject) => {
    ws = new WebSocket(`${EVENTS_API_URL}/company/events/${connectedCompanyId}`)
    ws.onopen = () => {
      console.log('WebSocket connection opened: ' + connectedCompanyId)
      resolve()
    }

    ws.onmessage = event => {
      try {
        const data = JSON.parse(event.data) as {
          type: WSEventType
          payload: {
            state: GenerationProgressState
          }
        }
        // console.log(data)

        if (context) {
          context.setState({
            ...context.state,
            wsMessage: data,
          })
        }
      } catch (e) {
        console.log(e)
      }
    }

    ws.onclose = event => {
      console.log('WebSocket connection closed: ', event.code)
      ws = null
      connectedCompanyId = ''
      setTimeout(function () {
        connectToWS(context)
      }, 1000)
    }

    ws.onerror = event => {
      console.error('WebSocket error: ', event)
      reject()
      ws = null
    }
  })
}
