import { Currency, CustomerCompanyRead, ItemRead, OrderRead, OrderStatus, PaymentRead } from '../../../api/dashboard'
import i18next from 'i18next'
import { Link, generatePath, useParams } from 'react-router-dom'
import { SKU_ITEMS_DETAILS_PATH } from '../../../libs/routerPaths'
import { getItemName } from '../../../api/getItemName'
import { formatMoney, isSuperAdmin } from '../../../util'
import PayCardView from '../PayCardView'
import { Badge } from '../../../components/ui'
import UserCountry from '../../player/components/UserCountry'
import { PaymentStatus } from '../../../pay-types'
import { useContext } from 'react'
import { AuthContext, IAuthContext } from '../../../Context'
import { PropTable, Property } from '../components/PropTable'
import { KeyValue } from '../../../types'
import { RewardPointsPrice } from '../../game-items/components/RewardPointsPrice'
import { useGameSettingsQuery } from '../../../api/useGameSettingsQuery'
import ToolTipBox from '@/layouts/dashboard/components/ToolTip/ToolTipBox'
import { getDateTimeFormatParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'

interface Tax {
  amount: number
  display_amount: string
  included: boolean
  name: string
  prc: number
  visible: boolean
}

export const GeneralTransactionDetails = (props: {
  payments: PaymentRead[]
  sourcePayments: PaymentRead[]
  order: OrderRead | null
  item: ItemRead | null
  refundCostumer: CustomerCompanyRead | null
}) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
    paymentNumber: string
  }
  const { t } = useTranslation()
  const { data: settings } = useGameSettingsQuery(companyId, gameId)

  const DEFAULT_EMPTY_VALUE = '–/–'

  const context = useContext(AuthContext) as IAuthContext

  const order = props.order
  const p = props.payments[0]

  const getRefundCustomerName = () => {
    return props.refundCostumer?.customer?.email || i18next.t('transactions-table.refund.staff')
  }

  const getCountryBillingAddress = () => {
    for (let p of props.sourcePayments) {
      if (p.user_billing_address) {
        try {
          let address = JSON.parse(p.user_billing_address)
          for (let key in address) {
            if (key == 'Country' && address[key]) {
              return address[key]
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  }

  const getBonusValue = () => {
    if (!order) {
      return DEFAULT_EMPTY_VALUE
    }

    let offer = order?.offers[0]
    if ((offer?.data as unknown as KeyValue)?.bonus_percent) {
      return (offer.data as unknown as KeyValue).bonus_percent + '%'
    }

    if (order.global_bonus) {
      return order.global_bonus.bonus_percent + '%'
    }

    return DEFAULT_EMPTY_VALUE
  }

  const getDiscountValue = () => {
    if (p.currency == Currency.RP) {
      return DEFAULT_EMPTY_VALUE
    }

    let discount = DEFAULT_EMPTY_VALUE

    let transactionTotal = p.total_order_currency_billing
    if (transactionTotal == undefined) {
      transactionTotal = p.total
    }

    if (order && p.user_local_price && p.user_local_price != transactionTotal) {
      let d = p.user_local_price - transactionTotal!
      if (d > 0) {
        discount = '-' + formatMoney(d, order.currency, order.price_minor_unit)
      }
    }

    return discount
  }

  const getUserBillingAddress = () => {
    let user_billing_addressJson: KeyValue = {}

    props.sourcePayments.forEach(it => {
      if (it.user_billing_address) {
        try {
          let address = JSON.parse(it.user_billing_address)
          for (let key in address) {
            if (key == 'Country') {
              continue
            }
            if (address[key]) {
              user_billing_addressJson[key] = address[key]
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
    })

    let arr = [] as Property[]
    for (let key in user_billing_addressJson) {
      if (user_billing_addressJson[key]) {
        arr.push({
          label: key + ':',
          value: user_billing_addressJson[key] as string,
        })
      }
    }
    return arr
  }

  let billing_email = ''
  let p_billing_email = props.payments.find(it => it.billing_email)
  if (p_billing_email?.billing_email) {
    billing_email = p_billing_email.billing_email
  }

  let couponInfo = DEFAULT_EMPTY_VALUE
  if (order?.discounts.length) {
    let discountCoupons = order.discounts.filter(d => !!d.coupon)
    if (discountCoupons.length) {
      couponInfo = discountCoupons.map(it => `${it.coupon?.code} -${it.coupon.discount_percent}% Off`).join(',')
    }
  }

  const orderStatus =
    order?.status == OrderStatus.Paid ? (
      <Badge variant="green-tertiary" className="ml-3 capitalize">
        {OrderStatus.Paid}
      </Badge>
    ) : (
      <Badge variant="gray-secondary" className="ml-3 capitalize">
        {order?.status}
      </Badge>
    )

  const getRewardPoints = () => {
    return order?.eligible_for_reward_points ? (
      <RewardPointsPrice price={order?.eligible_for_reward_points} />
    ) : (
      DEFAULT_EMPTY_VALUE
    )
  }

  let tableProps = [
    {
      label: i18next.t('transaction-details.item'),
      value: props.item && (
        <Link to={generatePath(SKU_ITEMS_DETAILS_PATH, { companyId, gameId, itemId: props.item.id })}>
          {getItemName(props.item)}
        </Link>
      ),
    },
    {
      label: i18next.t('transaction-details.receipt-number'),
      value: p.payment_number,
    },
    isSuperAdmin(context.customer) && {
      label: i18next.t('transaction-details.payment_id'),
      value: p.payment_id,
      items: [
        {
          label: i18next.t('transaction-details.pay_id'),
          value: p.ps_tx_id,
        },
      ],
    },
    {
      label: i18next.t('transaction-details.order_id'),
      value:
        isSuperAdmin(context.customer) && order?.status ? (
          <>
            {p.order_id} {orderStatus}
          </>
        ) : (
          p.order_id
        ),
    },
    {
      label: i18next.t('transaction-details.date'),
      value: t('intl.DateTime', getDateTimeFormatParams(p.created_at! / 1_000_000)),
    },
    {
      label: i18next.t('transaction-details.payment-method'),
      value: (
        <div className="inline-block">
          <PayCardView payment={p} />
        </div>
      ),
    },
    { label: i18next.t('transaction-details.coupon-code'), value: couponInfo },
    { label: i18next.t('transaction-details.discount'), value: getDiscountValue() },
    { label: i18next.t('transaction-details.bonus'), value: getBonusValue() },
    p.currency != Currency.RP &&
      settings?.enable_reward_points && {
        label: i18next.t('transaction-details.eligible_for_reward_points'),
        value: getRewardPoints(),
      },
    {
      label: i18next.t('transaction-details.tax.billing-email'),
      value: billing_email,
    },
    {
      label: (
        <div className="flex items-center gap-1">
          {i18next.t('transaction-details.order-country')}
          <ToolTipBox tooltip={i18next.t('transaction-details.order-country.tooltip')} />
        </div>
      ),
      value: <UserCountry country={order?.country} />,
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <span>{i18next.t('transaction-details.payment-country')}</span>
          <ToolTipBox tooltip={i18next.t('transaction-details.payment-country.tooltip')} />
        </div>
      ),
      value: <UserCountry country={p?.country} />,
    },
  ] as Property[]

  if (p.status === PaymentStatus.refunded) {
    tableProps.push(
      {
        label: i18next.t('transaction-details.refund-customer'),
        value: getRefundCustomerName(),
      },
      {
        label: i18next.t('transaction-details.refund-reason'),
        value: p.refund_reason,
      },
    )
  }

  if (p.status === PaymentStatus.failed && p.fail_reason_code) {
    tableProps.push({
      label: i18next.t('transaction-details.failed-reason'),
      value: i18next.t(`fail.code.${p.fail_reason_code}`, { ns: 'transaction-error-codes' }),
    })
    tableProps.push({
      label: i18next.t('transaction-details.failed-code'),
      value: p.fail_reason_code,
    })
  }

  let ip_address = DEFAULT_EMPTY_VALUE
  if (order?.ip_address) {
    ip_address = order.ip_address
  }

  let taxes = p.taxes ? (JSON.parse(p.taxes) as Tax[]) : []

  let taxProps = [
    {
      label: (
        <div className="flex items-center gap-1">
          <span>{i18next.t('transaction-details.tax.location')}</span>
          <ToolTipBox tooltip={i18next.t('transaction-details.tax.location.tooltip')} />
        </div>
      ),
      value: <UserCountry country={getCountryBillingAddress()} />,
    },
    taxes.length && {
      label: `${i18next.t('transaction-details.tax.amount')}${
        taxes[0].included ? ` ${i18next.t('transaction-details.tax.included')}` : ''
      }`,
      value: taxes[0] ? formatMoney(taxes[0].amount, p.currency, p.currency_minor_unit) : '',
    },
    {
      label: <div className="flex h-full items-start"> {i18next.t('transaction-details.tax.billing-address')} </div>,
      value: '',
      items: getUserBillingAddress(),
    },
    {
      label: i18next.t('transaction-details.tax.ip-address'),
      value: ip_address,
    },
  ] as Property[]

  return (
    <div className="ph-no-capture">
      <div className="h-full rounded-xl border-2 border-border-secondary">
        <PropTable rows={tableProps} />
        <div
          className="bg-fg-secondary p-3 font-medium uppercase text-text-quarterary-hover"
          style={{
            fontSize: '10px',
            lineHeight: '10px',
          }}
        >
          {i18next.t('transaction-details.tax-details')}
        </div>
        <PropTable rows={taxProps} />
      </div>
    </div>
  )
}
