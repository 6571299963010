import { MouseEvent, ReactNode, forwardRef, useId, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Checkbox } from '../../../components/ui'
import { cn } from '../../../libs/cn'
import { getMotionProps } from '../../../libs'
import { cva } from 'class-variance-authority'

interface PropertyCheckboxControlProps {
  children?: ReactNode
  label: ReactNode
  desc: ReactNode
  value: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
  size?: 'lg' | 'md'
  type?: 'checkbox' | 'radio'
  className?: string
}

export const childrenMotionProps = getMotionProps({
  initial: { y: -2, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: -2, opacity: 0 },
})

const variants = cva('', {
  variants: {
    size: {
      lg: 'gap-18 p-6',
      md: 'gap-3 p-3',
    },
    fontSize: {
      lg: 'text-t5',
      md: 'text-paragraph-sm font-semibold',
    },
  },
})

export const PropertyCheckboxControl = forwardRef<HTMLButtonElement, PropertyCheckboxControlProps>(
  function PropertyCheckboxControl(
    { children, label, desc, value, type, onChange, size = 'lg', className, disabled = false },
    ref,
  ) {
    const id = useId()
    const childrenRef = useRef<HTMLDivElement>(null)
    const [state, setState] = useState<null | 'hover'>(null)

    const onClickCard = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      if (childrenRef.current?.contains(e.target as HTMLButtonElement)) {
        return
      }
      onChange(!value)
    }

    const onMouseMove = (e: MouseEvent<HTMLButtonElement>) => {
      setState(childrenRef.current?.contains(e.target as HTMLButtonElement) ? null : 'hover')
    }

    const onMouseLeave = () => {
      setState(null)
    }

    return (
      <button
        ref={ref}
        className={cn(
          'flex select-none items-start overflow-hidden rounded-2xl border border-border-primary text-left shadow-xs outline-none transition-colors focus-visible:border-border-brand focus-visible:ring-2 focus-visible:ring-brand',
          state === 'hover' && 'cursor-pointer bg-fg-primary-hover',
          value && 'border-border-brand bg-fg-primary ring-1 ring-border-brand',
          disabled && 'pointer-events-none border-border-primary text-text-disabled ring-1 ring-border-primary',
          className,
        )}
        onClick={onClickCard}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        <div className={cn('grid grid-cols-[20px_auto] overflow-hidden', variants({ size: size }))}>
          <div className="pointer-events-none shrink-0">
            <Checkbox key={`checkbox-${id}-${value}`} defaultChecked={value} tabIndex={-1} type={type} />
          </div>
          <div className="-m-1 overflow-hidden p-1">
            <div className={children ? 'mb-6' : ''}>
              <div className={cn('mb-1.5', variants({ fontSize: size }))}>{label}</div>
              <div className={cn('text-sm text-text-secondary transition-colors', disabled && 'text-text-disabled')}>
                {desc}
              </div>
            </div>
            {children && (
              <div className={cn('min-h-[71px]')}>
                <AnimatePresence>
                  {value && !disabled && (
                    <motion.div ref={childrenRef} className="cursor-default" {...childrenMotionProps}>
                      {children}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            )}
          </div>
        </div>
      </button>
    )
  },
)
