import { ItemImageCreate, ItemImageRead, ItemRead } from '../../../api/dashboard'

interface PreviewReceiptProps {
  sku: ItemRead
  image: ItemImageRead | ItemImageCreate
}

export const PreviewReceipt = ({ sku, image }: PreviewReceiptProps) => (
  <div className="-mb-6 grid w-[580px] grid-cols-2 overflow-hidden rounded-t-[12px] border border-[#27272A]">
    <div className="size-full bg-[#18181B] p-6">
      <div className="mb-3 flex items-center justify-between">
        <div className="h-[12px] w-[48px] rounded-[20px] bg-[#3F3F46]" />
        <div className="h-[8px] w-[28px] rounded-[20px] bg-[#27272A]" />
      </div>
      <div className="mb-3 flex justify-center">
        {image.cropped_url || image.original_url ? (
          <img
            className="flex size-[100px] rounded-[6px] object-cover"
            src={image.cropped_url || image.original_url}
            alt=""
          />
        ) : (
          <div className="flex size-[100px]" />
        )}
      </div>
      <div className="mb-3">
        <div className="mb-0.5 flex justify-between">
          <div className="text-xs font-semibold text-white">{sku.name}</div>
          <div className="h-[12px] w-[24px] rounded-[20px] bg-[#3F3F46]" />
        </div>
        <div className="max-w-[60%] text-[8px] leading-normal text-white">{sku.description}</div>
      </div>
      <div className="grid h-28 grid-cols-2 content-center gap-6">
        <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
        <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
      </div>
      <div className="grid h-28 grid-cols-2 content-center gap-6">
        <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
        <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
      </div>
      <div className="grid h-28 grid-cols-2 content-center gap-6">
        <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
        <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
      </div>
    </div>
    <div className="size-full bg-[#27272A] p-6">
      <div className="mb-6">
        <div className="mb-1.5 flex h-[32px] items-center justify-center rounded-[6px] bg-[#52525B]">
          <div className="h-[8px] w-[22px] rounded-[20px] bg-[#71717A]" />
        </div>
        <div className="flex h-[32px] items-center justify-center rounded-[6px] bg-[#52525B]">
          <div className="h-[8px] w-[22px] rounded-[20px] bg-[#71717A]" />
        </div>
      </div>
      <div>
        <div className="grid h-28 grid-cols-2 content-center gap-6">
          <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
          <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
        </div>
        <div className="grid h-28 grid-cols-2 content-center gap-6">
          <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
          <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
        </div>
        <div className="grid h-28 grid-cols-2 content-center gap-6">
          <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
          <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
        </div>
        <div className="grid h-28 grid-cols-2 content-center gap-6">
          <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
          <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
        </div>
        <div className="grid h-28 grid-cols-2 content-center gap-6">
          <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
          <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
        </div>
        <div className="grid h-28 grid-cols-2 content-center gap-6">
          <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
          <div className="h-[4px] rounded-[20px] bg-[#3F3F46]" />
        </div>
      </div>
    </div>
  </div>
)
