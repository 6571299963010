import { Button, FieldGroup, Input } from '../../components/ui'
import i18next from 'i18next'
import { useState } from 'react'
import { Plus, Trash } from '../../components/icons'
import { cn } from '../../libs/cn'

export const DomainListEditor = (props: {
  domains: string[]
  onChange: (value: string[]) => void
  canUserEdit: boolean
}) => {
  const [newDomain, setNewDomain] = useState('')
  const { domains, onChange, canUserEdit } = props

  const onAdd = () => {
    let v = newDomain.trim()
    // eslint-disable-next-line no-useless-escape,
    if (/^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/.test(v)) {
      if (domains.indexOf(v) == -1) {
        onChange([...domains, newDomain])
      }
      setNewDomain('')
    }
  }

  return (
    <div>
      {canUserEdit && (
        <div className="mb-6">
          <FieldGroup label={i18next.t('company-settings-page.add-domain')}>
            <div className="flex gap-3">
              <Input
                value={newDomain}
                onChange={e => setNewDomain(e.target.value)}
                onKeyUp={e => {
                  if (e.keyCode == 13) {
                    onAdd()
                  }
                }}
                placeholder="example.com"
              />
              <Button onClick={onAdd} size="xl">
                <Plus />
              </Button>
            </div>
          </FieldGroup>
        </div>
      )}

      {domains.length > 0 && (
        <div className="flex flex-col rounded-md border border-border-secondary">
          {domains.map((it, idx) => (
            <div
              key={it}
              className={cn(
                'flex h-[54px] w-full items-center px-3 ',
                idx > 0 ? 'border-t border-border-secondary' : '',
              )}
            >
              {it}
              {canUserEdit && (
                <Trash
                  className="ml-auto mr-2 cursor-pointer text-text-error-primary"
                  onClick={() => onChange(domains.filter(d => d != it))}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
