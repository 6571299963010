import { queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { ResourceState } from '../../../api/dashboard'

type Query = {
  /** Filter by item state */
  state?: ResourceState
}

/**
 *
 * @param companyId
 * @param gameId
 * @param query
 */
export const skuItemsCountQuery = (companyId: string, gameId: string, query?: Query) =>
  queryOptions({
    queryKey: ['items', gameId, 'count', { query }],
    queryFn: () => dashboardClient.v1.getItemsCount(companyId, gameId, query).then(r => r.data),
  })
