import { ApiConfig, DashboardClient, FullRequestParams, HttpResponse, HTTPValidationError } from './dashboard'
import { HUB_API } from '../HubAPI'

class DashboardClientV2<SecurityDataType extends unknown> extends DashboardClient<SecurityDataType> {
  private oldRequest

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    super(apiConfig)
    this.oldRequest = this.request
    this.request = this.requestV2
  }

  async requestV2<T, E>(params: FullRequestParams): Promise<HttpResponse<T, E>> {
    try {
      let result = await this.oldRequest<T, E>(params)
      return result
    } catch (e) {
      let error = e as ResponseError

      if (error?.status == 401) {
        window.location.reload()
        return new Promise(() => {})
      }

      if (error?.status == 403 && params.method == 'GET') {
        window.location.href = '/403'
        return new Promise(() => {})
      }

      throw e
    }
  }
}

export const dashboardClient = new DashboardClientV2({
  baseUrl: import.meta.env.VITE_HUB_API_URL + '/dashboard',
  securityWorker: async () => {
    return {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${HUB_API.getToken()}`,
      },
    }
  },
})

export interface ResponseError {
  error: HTTPValidationError
  status: number
}

export function getErrorText(responseError: ResponseError | unknown) {
  if (!responseError) return ''

  let err = responseError as ResponseError

  if (!err.error) return ''

  let error = err.error

  if (typeof error.detail == 'string') return error.detail

  if (error.detail && error.detail[0]) return error.detail[0].msg

  return ''
}
