import { InputHTMLAttributes, ReactNode, RefObject, forwardRef, useId } from 'react'
import styled from '@emotion/styled'
import { Check } from '../../icons'
import { css } from '@emotion/react'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  caption?: ReactNode
  type?: 'checkbox' | 'radio'
  wrapperRef?: RefObject<HTMLDivElement>
}

const StyledCheckbox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    appearance: none;
  }

  input:hover + [data-id='track'] {
    background-color: #f8fafc;
    border-color: #cbd5e1;
  }

  input:focus + [data-id='track'] {
    box-shadow: 0 0 0 2px rgba(0, 151, 228, 0.14);
  }

  input:checked + [data-id='track'] {
    background-color: #0ea5e9;
    border-color: #0284c7;
    color: #fff;
  }

  input:disabled + [data-id='track'] {
    background-color: #f1f5f9;
    border-color: #e2e8f0;
    color: transparent;
  }

  input:checked:disabled + [data-id='track'] {
    color: #94a3b8;
  }
`

const Track = styled.label<{ $type: CheckboxProps['type'] }>`
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  width: 20px;
  height: 20px;
  outline: none;
  cursor: pointer;
  transition:
    background-color ease-in-out 160ms,
    border-color ease-in-out 160ms,
    color ease-in-out 160ms;

  ${p =>
    p.$type === 'checkbox' &&
    css`
      border-radius: 4px;
    `}

  ${p =>
    p.$type === 'radio' &&
    css`
      border-radius: 50%;
    `}
`

const Dot = styled.div`
  background-color: #fff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
`

const Entry = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-top: 2px;
  gap: 6px;
`

const Label = styled.label`
  display: inline-flex;
  color: #0f172a;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.07px;
  cursor: pointer;
`

const Caption = styled.label`
  color: #475569;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.07px;
`

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { children, caption, type = 'checkbox', wrapperRef, ...rest },
  ref,
) {
  const id = rest?.id || useId()
  return (
    <StyledCheckbox ref={wrapperRef}>
      <input {...rest} ref={ref} id={id} type={type} />
      <Track $type={type} htmlFor={id} data-id="track">
        {type === 'checkbox' && <Check size={12} strokeWidth={4} />}
        {type === 'radio' && <Dot />}
      </Track>
      {(children || caption) && (
        <Entry>
          <Label htmlFor={id}>{children}</Label>
          {caption && <Caption>{caption}</Caption>}
        </Entry>
      )}
    </StyledCheckbox>
  )
})
