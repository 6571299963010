import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { FormProvider, useForm } from 'react-hook-form'
import { WebhookRedacted } from '@/api/dashboard'
import { GeneralStage } from './GeneralStage'
import { SelectEventsStage } from './SelectEventsStage'
import { WebhookFormData, WebhookFormStage } from '../WebhookForm'

interface WebhookEditFormProps {
  webhook: WebhookRedacted
  onSubmit: (data: WebhookFormData) => Promise<void>
}

export const WebhookEditForm = ({ webhook, onSubmit }: WebhookEditFormProps) => {
  const [stage, setStage] = useState<WebhookFormStage>('general')

  const formMethods = useForm<WebhookFormData>({
    defaultValues: {
      ...webhook,
    },
  })

  return (
    <FormProvider {...formMethods}>
      <form className="flex size-full flex-col" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <AnimatePresence initial={false}>
          {stage === 'general' && <GeneralStage setStage={setStage} />}
          {stage === 'select-events' && <SelectEventsStage setStage={setStage} />}
        </AnimatePresence>
      </form>
    </FormProvider>
  )
}
