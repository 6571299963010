import { WebhookRedacted } from '@/api/dashboard'
import { Skeleton, TableCell, TableRow } from '@/ui'
import { GAME_WEBHOOKS_DETAIL_PATH, useGenerateCompanyPath } from '@/libs'
import { WebhookStatusBadge } from './WebhookStatusBadge'

interface WebhookRowProps {
  webhook: WebhookRedacted
  canEdit: boolean
}

export const WebhookRow = ({ webhook, canEdit }: WebhookRowProps) => {
  const { generatePath } = useGenerateCompanyPath()
  return (
    <TableRow to={canEdit ? generatePath(GAME_WEBHOOKS_DETAIL_PATH, { webhookId: webhook.id }) : undefined}>
      <TableCell>
        <div className="truncate text-caption-md text-text-brand-primary">{webhook.url}</div>
        <div className="mt-1 line-clamp-1 text-caption-md font-normal text-text-tertiary">
          {webhook.description || '–'}
        </div>
      </TableCell>
      <TableCell className="max-w-36">{webhook.events.length}</TableCell>
      <TableCell className="max-w-36">{webhook.error_rate.toFixed(2)}%</TableCell>
      <TableCell className="max-w-36">
        <WebhookStatusBadge />
      </TableCell>
    </TableRow>
  )
}

export const WebhookRowSkeleton = () => (
  <TableRow>
    <TableCell>
      <Skeleton className="h-[18px] w-2/3" />
      <Skeleton className="mt-1 h-[18px] w-1/4" />
    </TableCell>
    <TableCell className="max-w-36">
      <Skeleton className="h-[18px] w-1/2" />
    </TableCell>
    <TableCell className="max-w-36">
      <Skeleton className="h-[18px] w-1/2" />
    </TableCell>
    <TableCell className="flex max-w-36 justify-end">
      <Skeleton className="h-6 w-1/2" />
    </TableCell>
  </TableRow>
)
