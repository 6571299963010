import i18next from 'i18next'
import { useContext, useEffect, useState } from 'react'
import { dashboardClient } from '../../../api'
import { useParams } from 'react-router-dom'
import { APIKeyRedacted, APIKeyRevealed, APIKeyType } from '../../../api/dashboard'
import { Visibility } from '@mui/icons-material'
import DescBlock from '../components/DescBlock'
import Block from '../components/Block'
import { Button, FieldGroup, Input } from '../../../components/ui'
import { Trans } from 'react-i18next'
import { AuthContext, IAuthContext } from '@/Context'
import { isAdmin } from '@/security'

export default function ApiKeySettings() {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const [apiKeys, setApiKeys] = useState<APIKeyRedacted[]>([])
  const [apiKeysRevealed, setApiKeysRevealed] = useState<APIKeyRevealed[]>([])
  const context = useContext(AuthContext) as IAuthContext
  const isAdm = isAdmin(context.customer)

  const loadKeys = async () => {
    let { data } = await dashboardClient.v1.getApiKeys(companyId, gameId)
    setApiKeys(data)
  }

  const reveal = async (apiKey: APIKeyRedacted) => {
    let { data } = await dashboardClient.v1.revealApiKey(companyId, gameId, apiKey.id)
    setApiKeysRevealed([...apiKeysRevealed, data])
  }

  useEffect(() => {
    loadKeys()
  }, [gameId])

  const createKey = async (type: APIKeyType) => {
    await dashboardClient.v1.createApiKey(companyId, gameId, {
      type: type,
    })
    loadKeys()
  }

  const hasPublicKey = apiKeys.find(it => it.type == APIKeyType.Publishable)
  const hasSecretKey = apiKeys.find(it => it.type == APIKeyType.Secret)

  return (
    <DescBlock
      title={i18next.t('game-settings.api-keys.title')}
      description={<Trans i18nKey="game-settings.api-keys.description" components={{ a: <a /> }} />}
    >
      <Block>
        {apiKeys
          .filter(it => it.type != APIKeyType.Publishable)
          .map(apikey => {
            let keyRevealed = apiKeysRevealed.find(it => it.id == apikey.id)
            let label = keyRevealed ? 'api_key.secret' : 'api_key.secret.redacted'
            if (apikey.type == APIKeyType.Publishable) {
              label = keyRevealed ? 'api_key.publishable' : 'api_key.publishable.redacted'
            }

            return (
              <FieldGroup label={i18next.t(label)} key={apikey.id}>
                <Input
                  disabled={!isAdm}
                  key={apikey.id}
                  value={keyRevealed?.secret || apikey.secret_redacted}
                  extraRight={
                    !keyRevealed && (
                      <div onClick={() => reveal(apikey)} className="z-[1] cursor-pointer">
                        <Visibility />
                      </div>
                    )
                  }
                />
              </FieldGroup>
            )
          })}

        {(!hasSecretKey || !hasPublicKey) && isAdm && (
          <div className="flex flex-col gap-2">
            {/*<div>*/}
            {/*  {!hasPublicKey && (*/}
            {/*    <Button onClick={() => createKey(APIKeyType.Publishable)} variant="primary">*/}
            {/*      {i18next.t('api_key.publishable.generate')}*/}
            {/*    </Button>*/}
            {/*  )}*/}
            {/*</div>*/}
            <div>
              {!hasSecretKey && (
                <Button onClick={() => createKey(APIKeyType.Secret)} color="primary" variant="primary">
                  {i18next.t('api_key.secret.generate')}
                </Button>
              )}
            </div>
          </div>
        )}
      </Block>
    </DescBlock>
  )
}
