import { ItemCategoryRead, StoreItemRead } from '../../../api/dashboard'
import { PreviewProductCard } from '../../../components/ui/PreviewProductCard'

export const StoreView = (props: { categories: ItemCategoryRead[] | null; items: StoreItemRead[] }) => {
  const renderItems = (items: StoreItemRead[]) => {
    return items.map(it => (
      <PreviewProductCard
        key={it.item_id}
        item={it.item}
        style={{
          zoom: 0.605,
        }}
      />
    ))
  }

  const renderCatItems = (name: string, items: StoreItemRead[]) => {
    return (
      <div className="mt-2">
        <div className="text-[16px] font-semibold uppercase text-text-tertiary">{name}</div>
        <div className="mt-2 flex flex-wrap justify-start gap-3">{renderItems(items)}</div>
      </div>
    )
  }

  const categories = props.categories
  if (!props?.items.length) {
    return null
  }

  if (!categories || !categories.length) {
    return <div className="mt-2 flex flex-wrap justify-start gap-3"> {renderItems(props.items)} </div>
  }

  const catItems = categories.map(c => {
    const items = props.items.filter(it => it.item.categories?.includes(c.id))
    if (!items.length) {
      return null
    }
    return renderCatItems(c.name, items)
  })

  const notCatItems = props.items.filter(it => !it.item.categories || !it.item.categories.length)
  return (
    <>
      {catItems}
      {renderCatItems('Other', notCatItems)}
    </>
  )
}
