import { Campaign, CampaignEventType } from '../../../api/dashboard'
import i18next from 'i18next'

export const CONTEXT_ITEM = 'context_item'

export function getContextItemText(campaign: Campaign) {
  switch (campaign.type) {
    case CampaignEventType.AbandonedCart:
      return i18next.t('campaign.abandoned-card-item')
    case CampaignEventType.PurchasedInWeb:
      return i18next.t('campaign.purchased-item')
    default:
      return null
  }
}
