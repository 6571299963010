import { GraphRoot } from '@/api/dashboard'
import { EventActionNodeType } from '@/layouts/campaigns/types'
import { CONTEXT_ITEM } from '@/layouts/campaigns/editors/common'

export function clearEntityIdFields(graph: GraphRoot) {
  for (let id in graph.nodes) {
    let node = graph.nodes[id]

    switch (node.model_type) {
      case EventActionNodeType.ItemBonusOfferActionNode:
      case EventActionNodeType.ItemDiscountOfferActionNode:
      case EventActionNodeType.ItemOfferActionNode:
      case EventActionNodeType.CreateVirtualSKUNode:
      case EventActionNodeType.CreateCouponNode:
        if (node.item_id != CONTEXT_ITEM) {
          node.item_id = null as unknown as string
        }
        break
      case EventActionNodeType.CreateItemBannerActionNode:
        if (node.config.featured_item_id != CONTEXT_ITEM) {
          node.config.featured_item_id = null as unknown as string
        }
        node.page_id = null as unknown as string
        break
    }
  }
}
