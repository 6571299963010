import { useRef } from 'react'

/**
 * This hook will return focus to the last selected item before opening the modal window.
 * @param onClose
 */
export const useModalCloseWithFocus = (onClose: () => void | undefined) => {
  const lastActiveElement = useRef<Element | null>(document.activeElement)

  return () => {
    ;(lastActiveElement.current as HTMLElement | null)?.focus()
    onClose?.()
  }
}
