import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const ArrowDown: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path opacity="0.4" d="M12 5V19" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 12L12 19L5 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </IconBase>
  )
})
