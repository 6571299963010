import { ReactNode, useCallback, useLayoutEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { FlipBackward } from '@/icons'
import { ButtonIcon } from '@/ui'

interface FloatingHeaderProps {
  children: ReactNode
  extra: ReactNode
  backTo: string
}

const StyleFloatingHeader = styled.div`
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.75) 100%);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid transparent;
  position: sticky;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  min-height: 72px;
  z-index: 9999;
  transition:
    border-color ease-in-out 80ms,
    box-shadow ease-in-out 80ms;

  &.isSticky {
    border-color: ${p => p.theme.palette.grey.borderPrimary};
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }
`

const Title = styled.div`
  display: flex;
  gap: ${p => p.theme.spacing()};
  font-family: 'Nohemi';
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.48px;
  text-transform: uppercase;

  span {
    color: ${p => p.theme.palette.text.tertiary};
  }
`

const Entry = styled.div`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(2.5)};
`

const Extra = styled.div`
  margin-left: auto;
`

export const FloatingHeader = ({ children, extra, backTo }: FloatingHeaderProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const onScroll = useCallback(
    (e: Event) => {
      ref.current?.classList.toggle('isSticky', (e.currentTarget as Window).scrollY > 24)
    },
    [ref.current],
  )

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll])

  return (
    <StyleFloatingHeader ref={ref}>
      <div className="container">
        <Entry>
          <ButtonIcon variant="secondary-gray" onClick={() => navigate(backTo)}>
            <FlipBackward />
          </ButtonIcon>
          <Title>{children}</Title>
          <Extra>{extra}</Extra>
        </Entry>
      </div>
    </StyleFloatingHeader>
  )
}
