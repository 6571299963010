import { GraphRoot, Node, TokenType } from '../../api/dashboard'
import { EventActionNodeType } from './types'

interface FindState {
  matches: Node[]
  idx: number
  id: string
}

/**
 * Return list of using Attributes in store rules
 * @param graph
 */
export function getUsingAttr(graph: GraphRoot): string[] {
  if (!graph.nodes) {
    return []
  }

  let currentNodeId = graph.next_node_id

  let count = 0
  let stackMatches: FindState[] = []
  let res: string[] = []

  while (currentNodeId && count < 1000) {
    let node = graph.nodes[currentNodeId]
    let nextNodes: Node[] = []
    if (node.model_type == EventActionNodeType.ConditionNode && node.expr.tokens) {
      let attr = node.expr.tokens.filter(it => it.type == TokenType.Attribute).map(it => it.value)
      res.push(...attr)

      if (node.true_node_id) {
        nextNodes.push(graph.nodes[node.true_node_id])
      }

      if (node.false_node_id) {
        nextNodes.push(graph.nodes[node.false_node_id])
      }
    } else if (node.next_node_id) {
      nextNodes = [graph.nodes[node.next_node_id]]
    }

    if (nextNodes.length > 0) {
      stackMatches.push({ id: currentNodeId, matches: nextNodes, idx: 0 })

      currentNodeId = nextNodes[0].id
      count++

      continue
    }

    if (stackMatches.length > 0) {
      let prev = stackMatches.pop() as FindState
      if (prev.idx < prev.matches.length - 1) {
        currentNodeId = prev.matches[++prev.idx].id
        count++
        continue
      }
    }

    break
  }
  return [...new Set(res)]
}
