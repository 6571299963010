import { useTranslation } from 'react-i18next'
import { WebhookRedacted } from '@/api/dashboard'
import { Button, TableZeroState, useModal } from '@/ui'
import { CloseOutline, TerminalSquare } from '@/icons'
import { WebhookTestModal } from '../components'

interface WebhookLogsZeroStateProps {
  webhook: WebhookRedacted
  isSearching?: boolean
  onClearFilters?: () => void
}

export const WebhookLogsZeroState = ({ webhook, isSearching, onClearFilters }: WebhookLogsZeroStateProps) => {
  const { t } = useTranslation()

  const openTestModal = useModal<{ webhook: WebhookRedacted }>(props => <WebhookTestModal {...props} />)

  const onClickSendTest = () => {
    openTestModal({ webhook })
  }

  return (
    <TableZeroState
      title={t(isSearching ? 'webhook.search.not-found' : 'webhook.logs.zero-state.title')}
      message={t(isSearching ? 'webhook.search.not-found.desc' : 'webhook.logs.zero-state.message')}
      buttons={
        <>
          {isSearching ? (
            <Button variant="primary" onClick={onClearFilters}>
              <CloseOutline size={14} />
              <span>{t('webhook.clear-filters')}</span>
            </Button>
          ) : (
            <Button variant="primary" onClick={onClickSendTest}>
              <TerminalSquare size={14} />
              <span>{t('webhook.logs.zero-state.send-button')}</span>
            </Button>
          )}
          <a href="https://docs.aghanim.com/webhooks/overview" target="_blank">
            <Button variant="tertiary-gray">{t('webhook.logs.zero-state.learn-more-button')}</Button>
          </a>
        </>
      }
    />
  )
}
