import { BrowserPopupNotificationActionNode, EmailActionNode } from '../../../../api/dashboard'
import { getMergedPreviewProps } from '../../util'
import i18next from 'i18next'
import { DescContext } from './types'
import { CONTEXT_ITEM } from '@/layouts/campaigns/editors/common'
import { getContextItemName } from '@/layouts/campaigns/blocks/descriptions/getContextItemName'

export function getDescCommunicationNode(
  node: BrowserPopupNotificationActionNode | EmailActionNode,
  context: DescContext,
) {
  let desc = node.body
  if (node.item_id || node.link_created_offer) {
    let { item } = getMergedPreviewProps(node, context)
    if (item) {
      desc = item.name
    }
  }

  if (node.item_id == CONTEXT_ITEM) {
    desc = getContextItemName(context.type)
  }

  return [node.title || i18next.t(`campaign.node-type.${node.model_type}`), desc]
}
