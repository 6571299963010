import { useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export interface CompanyEventsSocketMessage<Type, Payload> {
  type: Type
  payload: Payload
}

interface CompanyEventsSocketParams<T> {
  onMessage: (message: T) => void
}

export const useCompanyEventsSocket = <T>({ onMessage }: CompanyEventsSocketParams<T>) => {
  const { companyId } = useParams() as { companyId: string }
  const [ws] = useState<WebSocket>(
    () => new WebSocket(`${import.meta.env.VITE_EVENTS_API_URL}/company/events/${companyId}`),
  )

  useLayoutEffect(() => {
    ws.onmessage = ({ data }: { data: string }) => {
      onMessage(JSON.parse(data))
    }

    return () => {
      ws?.close()
    }
  }, [ws, onMessage])
}
