import { ItemRead } from '../../../api/dashboard'
import { formatMoney } from '../../../util'

import { RewardPointsPrice } from './RewardPointsPrice'

export const PriceView = ({ item }: { item: ItemRead }) => {
  if (!item.reward_points_price) {
    if (!item.price) {
      return 'N/A'
    }

    return formatMoney(item.price, item.currency)
  }

  return <RewardPointsPrice price={item.reward_points_price} />
}
