import { queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

export const webhooksQuery = (
  companyId: string,
  gameId: string,
  query?: {
    limit?: number
    offset?: number
  },
) => {
  return queryOptions({
    queryKey: [gameId, 'webhooks', { query }],
    queryFn: () => dashboardClient.v1.getWebhooks(companyId, gameId, query).then(r => r.data),
  })
}
