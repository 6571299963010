import ReactECharts from 'echarts-for-react'
import * as echarts from 'echarts'

import { DataGranularity, PointValue } from '../../../../api/dashboard'
import { formatMoney } from '../../../../util'
import { SingleValueType } from './SVSparkLine'
import { getDateCategoryParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'

const SparkLine = (props: {
  data: PointValue[]
  isRed?: boolean
  type?: SingleValueType
  granularity?: DataGranularity
}) => {
  const { t } = useTranslation()

  let lineColor = props.isRed ? '#F04438' : '#17B26A'
  let gradiendColor1 = props.isRed ? 'rgba(217, 45, 32, 0.5)' : 'rgba(74, 222, 128, 0.5)'
  let gradiendColor2 = props.isRed ? 'rgba(217, 45, 32, 0)' : 'rgba(74, 222, 128, 0)'

  const options = {
    title: {
      show: false,
    },
    tooltip: {
      backgroundColor: '#000000CC',
      borderColor: 'transparent',
      trigger: 'axis',
      formatter: function (params: { axisValue: string; value: number; dataIndex: number }[]) {
        let point = props.data[params[0].dataIndex]

        let category = t(
          'intl.DateTime',
          getDateCategoryParams(point.category, props.granularity || DataGranularity.Day),
        )

        let value: number | string | undefined = point.value
        if (props.type == SingleValueType.money) {
          value = formatMoney(value)
        }

        return `<div class="chart-tooltip" style="height: auto">
                  <div class="chart-tooltip--subtitle">
                      <span>${category}:</span>
                      <span>${value}</span>
                  </div>
              </div>`
      },
    },
    legend: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    grid: {
      left: '5%',
      right: '5%',
      top: '0%',
      bottom: '10%',
      containLabel: false,
    },
    xAxis: [
      {
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        type: 'category',
        boundaryGap: false,
        data: props.data.map(it =>
          t('intl.DateTime', getDateCategoryParams(it.category, props.granularity || DataGranularity.Day)),
        ),
      },
    ],
    yAxis: [
      {
        show: false,
      },
    ],
    axisPointer: {
      lineStyle: {
        color: '#000',
        width: 1,
        type: 'solid',
      },
    },
    series: [
      {
        name: 's1',
        type: 'line',
        showSymbol: false,
        color: lineColor,
        lineStyle: {
          color: lineColor,
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: gradiendColor1,
            },
            {
              offset: 1,
              color: gradiendColor2,
            },
          ]),
        },
        data: props.data.map(it => it.value),
        emphasis: {
          itemStyle: {
            color: lineColor,
            borderColor: lineColor,
          },
        },
      },
    ],
  }

  return (
    <ReactECharts
      option={options}
      style={{
        height: '64px',
        width: '128px',
      }}
    />
  )
}

export default SparkLine
