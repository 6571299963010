import { ReactNode, useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { $createParagraphNode, $getSelection, $isRangeSelection, LexicalEditor, TextNode } from 'lexical'
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin'
import { $createHeadingNode } from '@lexical/rich-text'
import { $setBlocksType } from '@lexical/selection'
import { useModal } from '../../../ui'
import { AghanimIcon, BulletList, H1, H2, H3, ImageOutline, NumberedList, Paragraph, YouTube } from '../../../icons'
import { groupBy } from '../../../../libs'
import { ComponentPickerMenu, ComponentPickerMenuGroup, ComponentPickerMenuOption } from '../../components'
import { ImagePluginInsertModal } from '../ImagePlugin/ImagePluginInsertModal'
import { YouTubeInsertModal } from '../YouTubePlugin'

export interface ComponentPickerOption {
  label: ReactNode
  desc: ReactNode
  icon: AghanimIcon
  group: 'headings' | 'basic-blocks' | 'media'
  keywords: string[]
  keyboardShortcut?: string
  index?: number
  onSelect: (queryString?: string) => void
}

function getDynamicOptions(queryString: string) {
  const options: Array<ComponentPickerOption> = []

  if (queryString == null) {
    return options
  }

  return options
}

const headingIconMap = new Map<1 | 2 | 3, AghanimIcon>([
  [1, H1],
  [2, H2],
  [3, H3],
])

export const getBaseOptions = (
  editor: LexicalEditor,
  openImageInsertModal: () => void,
  openYoutubeInsertModal: () => void,
): ComponentPickerOption[] => [
  ...([1, 2, 3] as const).map(
    (level): ComponentPickerOption => ({
      label: i18next.t(`lexical.menu.h${level}.label`),
      desc: i18next.t(`lexical.menu.h${level}.desc`),
      icon: headingIconMap.get(level) as AghanimIcon,
      group: 'headings',
      keywords: ['heading', 'header', `h${level}`],
      onSelect: () =>
        editor.update(() => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode(`h${level}`))
          }
        }),
    }),
  ),
  {
    label: i18next.t('lexical.menu.numbered-list.label'),
    desc: i18next.t('lexical.menu.numbered-list.desc'),
    icon: NumberedList,
    group: 'basic-blocks',
    keywords: ['numbered list', 'ordered list', 'ol'],
    onSelect: () => editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined),
  },
  {
    label: i18next.t('lexical.menu.bullet-list.label'),
    desc: i18next.t('lexical.menu.bullet-list.desc'),
    icon: BulletList,
    group: 'basic-blocks',
    keywords: ['bulleted list', 'unordered list', 'ul'],
    onSelect: () => editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined),
  },
  {
    label: i18next.t('lexical.menu.paragraph.label'),
    desc: i18next.t('lexical.menu.paragraph.desc'),
    icon: Paragraph,
    group: 'basic-blocks',
    keywords: ['paragraph', 'p', 'text'],
    onSelect: () =>
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createParagraphNode())
        }
      }),
  },
  {
    label: i18next.t('lexical.menu.image.label'),
    desc: i18next.t('lexical.menu.image.desc'),
    icon: ImageOutline,
    group: 'media',
    keywords: ['image', 'photo', 'picture', 'file'],
    onSelect: openImageInsertModal,
  },
  {
    label: i18next.t('lexical.menu.youtube.label'),
    desc: i18next.t('lexical.menu.youtube.desc'),
    icon: YouTube,
    group: 'media',
    keywords: ['youtube', 'video', 'file'],
    onSelect: openYoutubeInsertModal,
  },
]

export const ComponentPickerMenuPlugin = () => {
  const { t } = useTranslation()
  const [editor] = useLexicalComposerContext()
  const [queryString, setQueryString] = useState<string | null>(null)

  const openImageInsertModal = useModal(props => <ImagePluginInsertModal {...props} editor={editor} />)
  const openYoutubeInsertModal = useModal(props => <YouTubeInsertModal {...props} editor={editor} />)

  const checkForTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  })

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(editor, openImageInsertModal, openYoutubeInsertModal)

    if (!queryString) {
      return baseOptions
    }

    const regex = new RegExp(queryString, 'i')

    return [
      ...getDynamicOptions(queryString),
      ...baseOptions.filter(
        option => regex.test(option.label?.toString() || '') || option.keywords.some(keyword => regex.test(keyword)),
      ),
    ]
  }, [editor, queryString])

  const groupedOptions = useMemo(
    () =>
      Object.entries(
        groupBy<ComponentPickerOption>(
          options.map((option, index) => ({ ...option, index })),
          option => option.group,
        ),
      ),
    [options],
  )

  const onSelectOption = useCallback(
    (_option: MenuOption, nodeToRemove: TextNode | null, closeMenu: () => void, matchingString: string) => {
      const option = _option as unknown as ComponentPickerOption
      editor.update(() => {
        nodeToRemove?.remove()
        option.onSelect(matchingString)
        closeMenu()
      })
    },
    [editor],
  )

  return (
    <LexicalTypeaheadMenuPlugin<MenuOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForTriggerMatch}
      anchorClassName="z-[100] absolute"
      options={options as unknown as MenuOption[]}
      menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) => {
        return anchorElementRef.current && options.length
          ? createPortal(
              <ComponentPickerMenu>
                {groupedOptions.map(([key, options]) => (
                  <ComponentPickerMenuGroup key={`menu-group-${key}`} label={t(`lexical.menu.groups.${key}`)}>
                    {options.map(option => (
                      <ComponentPickerMenuOption
                        key={`component-picker-menu-option-${option.keywords.join('-')}`}
                        option={option}
                        isSelected={selectedIndex === option.index}
                        onClick={() => {
                          setHighlightedIndex(option.index || -1)
                          selectOptionAndCleanUp(option as unknown as MenuOption)
                        }}
                        onMouseEnter={() => {
                          setHighlightedIndex(option.index || -1)
                        }}
                      />
                    ))}
                  </ComponentPickerMenuGroup>
                ))}
              </ComponentPickerMenu>,
              anchorElementRef.current,
            )
          : null
      }}
    />
  )
}
