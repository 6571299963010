import { HTMLAttributes, ReactNode } from 'react'
import { cn } from '@/libs'

interface PageProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

export const Page = ({ children, ...rest }: PageProps) => (
  <div {...rest} className={cn('flex size-full flex-col', rest.className)}>
    {children}
  </div>
)
