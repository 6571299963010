import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const CloseOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path id="Vector 83" d="M4 4L20 20M20 4L4 20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </IconBase>
  )
})
