import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const Coins: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 16.5C4.89522 16.5 1.5 13.1031 1.5 8.99618C1.5 4.88931 4.88759 1.5 8.99237 1.5C13.0972 1.5 16.5 4.88931 16.5 8.99618C16.5 13.1031 13.1048 16.5 9 16.5ZM9 14.9198C12.2808 14.9198 14.9207 12.2863 14.9207 8.99618C14.9207 5.71374 12.2808 3.07252 8.99237 3.07252C5.7116 3.07252 3.08698 5.71374 3.08698 8.99618C3.08698 12.2863 5.71923 14.9198 9 14.9198ZM8.99237 13.0267C8.80163 13.0267 8.66429 12.8969 8.66429 12.6985V12.2176C7.61902 12.126 6.86368 11.584 6.65005 10.8359C6.62716 10.7443 6.60427 10.6603 6.60427 10.5763C6.60427 10.2786 6.81027 10.0725 7.13072 10.0725C7.39013 10.0725 7.55799 10.2023 7.6648 10.4695C7.77925 10.874 8.10732 11.1718 8.66429 11.2557V9.42366L8.52696 9.39313C7.32909 9.11832 6.69583 8.5687 6.69583 7.62214C6.69583 6.60687 7.51221 5.91221 8.66429 5.79771V5.32443C8.66429 5.12595 8.80163 4.98855 8.99237 4.98855C9.17548 4.98855 9.32045 5.12595 9.32045 5.32443V5.79771C10.2971 5.90458 11.0219 6.4313 11.2202 7.17176C11.2355 7.25573 11.2584 7.33969 11.2584 7.42366C11.2584 7.71374 11.0524 7.91221 10.7472 7.91221C10.4802 7.91221 10.3123 7.77481 10.2055 7.53053C10.0682 7.11832 9.75534 6.85878 9.32045 6.77481V8.50763L9.49593 8.5458C10.7548 8.78244 11.3805 9.35496 11.3805 10.3397C11.3805 11.4389 10.5031 12.1183 9.32045 12.2176V12.6985C9.32045 12.8969 9.17548 13.0267 8.99237 13.0267ZM8.66429 8.34733V6.77481C8.13021 6.85878 7.80214 7.16412 7.80214 7.55344C7.80214 7.92748 8.06155 8.17176 8.61852 8.33206L8.66429 8.34733ZM9.32045 9.58397V11.2557C9.95371 11.1794 10.2742 10.8664 10.2742 10.4237C10.2742 10.0267 10.0529 9.78244 9.45015 9.6145L9.32045 9.58397Z"
            fill="#475569"
          />
        </svg>
      </svg>
    </IconBase>
  )
})
