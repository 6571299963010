import { Button } from './ui'
import i18next from 'i18next'
import { useNavigate } from 'react-router-dom'

export default function ErrorView(props: { msg: string }) {
  const navigate = useNavigate()

  return (
    <div style={{ height: '100vh' }} className="flex size-full items-center justify-center bg-border-secondary py-5">
      <div
        className="flex size-full flex-col items-center justify-center rounded-xl border border-border-primary bg-fg-primary"
        style={{ maxWidth: '1164px', boxShadow: '0px 1px 2px 0px #1018280D' }}
      >
        <img src="/icons/logo.svg" alt="logo" />
        <h2 className="mt-6 text-[32px] font-semibold">{props.msg}</h2>
        <Button variant="primary" onClick={() => navigate('/logout')} className="mt-6">
          {i18next.t('logout')}
        </Button>
      </div>
    </div>
  )
}
