import styled from '@emotion/styled'
import { UserRead } from '../../../../api/dashboard'
import { TableCell, TableRow } from '../../../../components/ui'
import { PlayerAvatar } from '../PlayerAvatar'
import { PlayerStatus } from '../PlayerStatus'
import i18next from 'i18next'
import { DateTimeValue } from '@/components/ui/DateTimeValue'
import UserCountry from '@/layouts/player/components/UserCountry'

interface PlayerTableRowProps {
  user: UserRead
  to: string
}

const UserRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(1.5)};
`

export const PlayerTableRow = ({ user, to }: PlayerTableRowProps) => {
  return (
    <TableRow to={to}>
      <TableCell width="200%">
        <UserRow>
          <PlayerAvatar src={user.avatar_url} />
          <div>{user.email || user.name || i18next.t('players.incognito')}</div>
        </UserRow>
      </TableCell>
      <TableCell width="100%">
        <UserCountry country={user.country} />
      </TableCell>
      <TableCell width="50%">{user.player_id}</TableCell>
      <TableCell width="50%">
        <DateTimeValue value={user.created_at} />
      </TableCell>
      <TableCell width="30%">
        <PlayerStatus isBanned={user.banned || false} />
      </TableCell>
    </TableRow>
  )
}
