import { Trans, useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Role } from '@/api/dashboard'
import { Button, Checkbox, Modal, ModalFooter, ModalProps, ModalTitle, Select } from '@/ui'
import { useCompaniesQuery } from '@/api/useCompaniesQuery'
import { ImageCompany } from '@/components/ui/AppSidebar/ImageCompany'

interface ConfirmDialogProps extends ModalProps {}

export function CreateGameModalConfirm({ ...rest }: ConfirmDialogProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: dataCompanies } = useCompaniesQuery()
  let companies = dataCompanies?.data || []
  const { companyId } = useParams() as {
    companyId: string
  }

  companies = companies.filter(it => it.role == Role.Admin && !it.is_demo)

  let cId = null

  if (companies.length) {
    cId = companies.find(c => c.id == companyId)?.id || companies[0].id
  }

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      company_id: cId,
    },
  })

  const company_id = watch('company_id')

  const onSubmit = handleSubmit(data => {
    navigate('/onboarding', { state: { company_id: data.company_id == 'new' ? null : data.company_id } })
    rest.onClose?.()
  })

  return (
    <Modal {...rest}>
      <ModalTitle>{t('onboarding.confirm.title')}</ModalTitle>

      {t('onboarding.confirm.message')}

      <form onSubmit={onSubmit}>
        <div className="mt-6 flex flex-col gap-4">
          <Checkbox type="radio" checked={company_id == 'new'} onClick={() => setValue('company_id', 'new')}>
            {t('onboarding.confirm.create-new-company')}
          </Checkbox>
          <Checkbox type="radio" checked={company_id != 'new'} onClick={() => setValue('company_id', companies[0].id)}>
            {companies.length > 1 ? (
              t('onboarding.confirm.existing-company')
            ) : (
              <Trans
                i18nKey="onboarding.confirm.add-to"
                components={{ b: <b className="ml-1" /> }}
                values={{ value: companies[0].name }}
              />
            )}
          </Checkbox>

          {companies.length > 1 && (
            <Controller
              control={control}
              name="company_id"
              rules={{ required: t('validation.required') }}
              render={({ field }) => (
                <Select
                  disabled={company_id == 'new'}
                  {...field}
                  options={companies
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(company => ({
                      icon: <ImageCompany company={company} />,
                      children: company.name,
                      value: company.id,
                    }))}
                />
              )}
            />
          )}
        </div>

        <ModalFooter>
          <Button type="button" size="lg" onClick={rest.onClose}>
            {t('Cancel')}
          </Button>
          <Button type="submit" size="lg" variant={'primary'}>
            {t('Create')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
