import i18next from 'i18next'
import AlertCircle from '../../icons/AlertCircle'
import { HTMLAttributes, forwardRef } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {}

const ZeroState = forwardRef<HTMLButtonElement, Props>(function ButtonClose({ ...rest }: Props) {
  return (
    <div className="size-full rounded-md border-2 border-dashed border-fg-secondary p-1.5" {...rest}>
      <div className="flex size-full flex-col items-center justify-center gap-3 rounded-md bg-fg-secondary text-sm text-text-tertiary">
        <AlertCircle />
        {i18next.t('dashboard.no-data')}
      </div>
    </div>
  )
})

export default ZeroState
