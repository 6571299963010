import { VerifyDataCard } from '@/layouts/get-started/components'
import { BusinessOwnerDetails } from '@/api/dashboard'

interface VerifyBusinessOwnerCardProps {
  data: BusinessOwnerDetails
  onClickDelete?: () => void
  onClickEdit?: () => void
}

export const VerifyBusinessOwnerCard = ({ data, onClickDelete, onClickEdit }: VerifyBusinessOwnerCardProps) => (
  <VerifyDataCard title={data.entity_legal_name} onClickDelete={onClickDelete} onClickEdit={onClickEdit}>
    <p>{data.country}</p>
  </VerifyDataCard>
)
