import { FORMAT_ELEMENT_COMMAND, LexicalEditor } from 'lexical'
import { Menu } from '../../../ui'
import { AlignCenter, AlignLeft, AlignRight } from '../../../icons'
import { ToolbarAlignOption } from './ToolbarAlignOption'
import { useTranslation } from 'react-i18next'

interface ToolbarAlignMenuProps {
  editor: LexicalEditor
  onClose?: () => void
}

export const ToolbarAlignMenu = ({ editor, onClose = () => void 0 }: ToolbarAlignMenuProps) => {
  const { t } = useTranslation()

  const onClickOption = (direction: 'left' | 'center' | 'right') => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, direction)
    onClose()
  }

  return (
    <Menu>
      <ToolbarAlignOption icon={AlignLeft} onClick={() => onClickOption('left')}>
        {t('lexical.align-menu.left')}
      </ToolbarAlignOption>
      <ToolbarAlignOption icon={AlignCenter} onClick={() => onClickOption('center')}>
        {t('lexical.align-menu.center')}
      </ToolbarAlignOption>
      <ToolbarAlignOption icon={AlignRight} onClick={() => onClickOption('right')}>
        {t('lexical.align-menu.right')}
      </ToolbarAlignOption>
    </Menu>
  )
}
