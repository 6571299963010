/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** ABTestPoint */
export interface ABTestPoint {
  /** Event Node Id */
  event_node_id?: string
  /** A Revenue */
  A_revenue: number
  /** B Revenue */
  B_revenue: number
  /** A Conversion */
  A_conversion: number
  /** B Conversion */
  B_conversion: number
  /** A Count */
  A_count: number
  /** B Count */
  B_count: number
}

/** APIKeyCreate */
export interface APIKeyCreate {
  /** An enumeration. */
  type: APIKeyType
  /** Expires At */
  expires_at?: number
}

/** APIKeyRedacted */
export interface APIKeyRedacted {
  /** An enumeration. */
  type: APIKeyType
  /** Expires At */
  expires_at?: number
  /** Secret Redacted */
  secret_redacted: string
  /** Id */
  id: string
}

/** APIKeyRevealed */
export interface APIKeyRevealed {
  /** An enumeration. */
  type: APIKeyType
  /** Expires At */
  expires_at?: number
  /** Id */
  id: string
  /** Secret */
  secret: string
}

/**
 * APIKeyType
 * An enumeration.
 */
export enum APIKeyType {
  Publishable = 'publishable',
  Secret = 'secret',
}

/** AccountDetails */
export interface AccountDetails {
  company_legal?: CompanyLegalDetails
  /** Business Owners */
  business_owners?: BusinessOwnerDetails[]
  /** Have No Ubo */
  have_no_ubo?: boolean
  authorized_signatory?: AuthorizedSignatoryDetails
}

/** AccountRead */
export interface AccountRead {
  details: AccountDetails
  /** An enumeration. */
  verify_state: VerifyState
}

/** AchievementBulkUpdate */
export interface AchievementBulkUpdate {
  /**
   * Name
   * The name of the achievement
   * @maxLength 255
   */
  name?: string
  /**
   * Description
   * The description of the achievement
   * @maxLength 512
   */
  description?: string
  /**
   * Image Url
   * The URL of the image for the achievement
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
  /** The type of the achievement */
  type?: AchievementType
  /**
   * Position
   * The position of the achievement in the list
   */
  position?: number
  /**
   * Id
   * The unique identifier of the achievement
   */
  id: string
}

/** AchievementCreate */
export interface AchievementCreate {
  /**
   * Name
   * The name of the achievement
   * @maxLength 255
   */
  name: string
  /**
   * Description
   * The description of the achievement
   * @maxLength 512
   */
  description: string
  /**
   * Image Url
   * The URL of the image for the achievement
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url: string
  /** The type of the achievement */
  type: AchievementType
  /**
   * Position
   * The position of the achievement in the list
   */
  position: number
}

/** AchievementListBlock */
export interface AchievementListBlock {
  /**
   * Block
   * @default "AchievementListBlock"
   */
  block?: 'AchievementListBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
}

/** AchievementRead */
export interface AchievementRead {
  /**
   * Name
   * The name of the achievement
   * @maxLength 255
   */
  name: string
  /**
   * Description
   * The description of the achievement
   * @maxLength 512
   */
  description: string
  /**
   * Image Url
   * The URL of the image for the achievement
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url: string
  /** The type of the achievement */
  type: AchievementType
  /**
   * Position
   * The position of the achievement in the list
   */
  position: number
  /**
   * Id
   * The unique identifier of the achievement
   */
  id: string
}

/** AchievementTranslate */
export interface AchievementTranslate {
  /**
   * Name
   * The name of the achievement
   * @maxLength 255
   */
  name?: string
  /**
   * Description
   * The description of the achievement
   * @maxLength 512
   */
  description?: string
}

/**
 * AchievementType
 * An enumeration.
 */
export enum AchievementType {
  FirstVisit = 'first_visit',
  FirstPurchase = 'first_purchase',
  RegularVisit = 'regular_visit',
}

/** AchievementUpdate */
export interface AchievementUpdate {
  /**
   * Name
   * The name of the achievement
   * @maxLength 255
   */
  name?: string
  /**
   * Description
   * The description of the achievement
   * @maxLength 512
   */
  description?: string
  /**
   * Image Url
   * The URL of the image for the achievement
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
  /** The type of the achievement */
  type?: AchievementType
  /**
   * Position
   * The position of the achievement in the list
   */
  position?: number
}

/** AddStoreItemsNode */
export interface AddStoreItemsNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "AddStoreItemsNode"
   */
  model_type?: 'AddStoreItemsNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  item: StoreItemCreate
}

/** AddStoreNode */
export interface AddStoreNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "AddStoreNode"
   */
  model_type?: 'AddStoreNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Store Id */
  store_id: string
}

/** AddToSegmentNode */
export interface AddToSegmentNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "AddToSegmentNode"
   */
  model_type?: 'AddToSegmentNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Segment Id */
  segment_id?: string
  /** Segment Slug */
  segment_slug?: string
}

/** AgeSettings */
export interface AgeSettings {
  /** Min Age */
  min_age?: number
  /** Icon Url */
  icon_url?: string
}

/**
 * AttrType
 * An enumeration.
 */
export enum AttrType {
  Number = 'number',
  Date = 'date',
  String = 'string',
  Boolean = 'boolean',
  List = 'list',
}

/** AuthorizedSignatoryDetails */
export interface AuthorizedSignatoryDetails {
  /**
   * First Name
   * @minLength 3
   */
  first_name: string
  /**
   * Last Name
   * @minLength 3
   */
  last_name: string
  /**
   * Country
   * @pattern ^[A-Z]{2}$
   */
  country: string
  /**
   * Job Title
   * @minLength 3
   */
  job_title: string
  /**
   * Email
   * @format email
   */
  email: string
}

/** BannerBlock */
export interface BannerBlock {
  /**
   * Block
   * @default "BannerBlock"
   */
  block?: 'BannerBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
  /** Description */
  description?: string
  /** Image Url */
  image_url?: string
  /** Title */
  title?: string
  /** Background Image Size */
  background_image_size?: string
}

/** Benefit */
export interface Benefit {
  /** An enumeration. */
  type: BenefitType
  source: BenefitSource
  /** Data */
  data: BenefitDiscountData | BenefitBonusQuantityData | BenefitBonusItemData
}

/** BenefitBonusItemData */
export interface BenefitBonusItemData {
  item: BenefitItem
}

/** BenefitBonusQuantityData */
export interface BenefitBonusQuantityData {
  /** Bonus Percent */
  bonus_percent: number
  /** Bonus Value */
  bonus_value: number
}

/** BenefitCouponDetails */
export interface BenefitCouponDetails {
  /** Name */
  name: string
  /** Code */
  code: string
}

/** BenefitDiscountData */
export interface BenefitDiscountData {
  /** Discount Percent */
  discount_percent: number
  /** Discount Amount */
  discount_amount: number
}

/** BenefitItem */
export interface BenefitItem {
  /** Id */
  id: string
  /** Sku */
  sku: string
  /** Name */
  name: string
  /** Description */
  description: string
  /** Image Url */
  image_url: string | ''
  /** An enumeration. */
  type: ItemType
  /** Quantity */
  quantity: number
}

/** BenefitOfferDetails */
export interface BenefitOfferDetails {
  /** Description */
  description: string
}

/** BenefitSource */
export interface BenefitSource {
  /** An enumeration. */
  type: BenefitSourceType
  /** Details */
  details?: BenefitOfferDetails | BenefitCouponDetails
}

/**
 * BenefitSourceType
 * An enumeration.
 */
export enum BenefitSourceType {
  Global = 'global',
  Offer = 'offer',
  Coupon = 'coupon',
}

/**
 * BenefitType
 * An enumeration.
 */
export enum BenefitType {
  Discount = 'discount',
  BonusQuantity = 'bonus_quantity',
  BonusItem = 'bonus_item',
}

/** BestTriggersData */
export interface BestTriggersData {
  /** Data */
  data: SeriesValue[]
  /** Prev Data */
  prev_data: SeriesValue[]
  /** Total */
  total: PointValue[]
  /** Prev Total */
  prev_total: PointValue[]
}

/** BillingAddress */
export interface BillingAddress {
  /** Iso Code */
  iso_code?: string
  /** Address1 */
  address1?: string
  /** City */
  city?: string
  /** Postal */
  postal?: string
  /** Region */
  region?: string
}

/** Block */
export type Block =
  | ({
      block: 'FullNewsBlock'
    } & FullNewsBlock)
  | ({
      block: 'FeaturedNewsBlock'
    } & FeaturedNewsBlock)
  | ({
      block: 'TwitchBlock'
    } & TwitchBlock)
  | ({
      block: 'YoutubeBlock'
    } & YoutubeBlock)
  | ({
      block: 'TextBlock'
    } & TextBlock)
  | ({
      block: 'BannerBlock'
    } & BannerBlock)
  | ({
      block: 'FeaturedItemBlock'
    } & FeaturedItemBlock)
  | ({
      block: 'RedeemCouponBlock'
    } & RedeemCouponBlock)
  | ({
      block: 'LatestNewsBlock'
    } & LatestNewsBlock)
  | ({
      block: 'LeaderboardCardBlock'
    } & LeaderboardCardBlock)
  | ({
      block: 'LeaderboardFullBlock'
    } & LeaderboardFullBlock)
  | ({
      block: 'StoreBlock'
    } & StoreBlock)
  | ({
      block: 'AchievementListBlock'
    } & AchievementListBlock)
  | ({
      block: 'FeaturedPageBlock'
    } & FeaturedPageBlock)

/** Bonus */
export interface Bonus {
  /** Bonus Value */
  bonus_value: number
  /** Bonus Percent */
  bonus_percent: number
  coupon: CouponBonus
}

/** BrowserPopupNotificationActionNode */
export interface BrowserPopupNotificationActionNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "BrowserPopupNotificationActionNode"
   */
  model_type?: 'BrowserPopupNotificationActionNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Body */
  body?: string
  /** Item Id */
  item_id?: string
  /** Link Created Offer */
  link_created_offer?: boolean
  /** Store Item Id */
  store_item_id?: string
  /** Store Id */
  store_id?: string
  /** Image Url */
  image_url?: string
}

/** BrowserPushNotificationActionNode */
export interface BrowserPushNotificationActionNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "BrowserPushNotificationActionNode"
   */
  model_type?: 'BrowserPushNotificationActionNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Body */
  body?: string
  /** Item Id */
  item_id?: string
  /** Link Created Offer */
  link_created_offer?: boolean
  /** Store Item Id */
  store_item_id?: string
  /** Store Id */
  store_id?: string
  /** Image Url */
  image_url?: string
}

/** BusinessOwnerDetails */
export interface BusinessOwnerDetails {
  /**
   * Entity Legal Name
   * @minLength 3
   */
  entity_legal_name: string
  /**
   * Country
   * @pattern ^[A-Z]{2}$
   */
  country: string
}

/** CalcPlayerAttributes */
export interface CalcPlayerAttributes {
  /** Ltv */
  ltv?: number
  /** Purchases Count */
  purchases_count?: number
  /** Active Offers Count */
  active_offers_count?: number
  /** Last Purchase Date */
  last_purchase_date?: number
  /** Last Purchase Item Id */
  last_purchase_item_id?: string
  /** Last Purchase Sku Id */
  last_purchase_sku_id?: string
  /** Last Purchase Amount */
  last_purchase_amount?: number
  /** Last Email Date */
  last_email_date?: number
  /** Last App Popup Date */
  last_app_popup_date?: number
  /** Last App Push Date */
  last_app_push_date?: number
  /** Last Hub Push Date */
  last_hub_push_date?: number
  /** Last Hub Popup Date */
  last_hub_popup_date?: number
  /** Last App Popup Coupon Sent */
  last_app_popup_coupon_sent?: number
  /** Last Communication Date */
  last_communication_date?: number
  /** Average Check */
  average_check?: number
  /** Email */
  email?: string
  /** Last Hub Visit */
  last_hub_visit?: number
  /** User Segments */
  user_segments?: string[]
  /** Communication Count */
  communication_count?: number
  /** Game Hub Login Count */
  game_hub_login_count?: number
  /** Purchased Item Ids */
  purchased_item_ids?: string[]
}

/** Campaign */
export interface Campaign {
  /** Name */
  name: string
  /** Enabled */
  enabled: boolean
  /** Type */
  type: CampaignEventType | string
  /**
   * Time Limit
   * @default false
   */
  time_limit?: boolean
  /** Start At */
  start_at?: number
  /** End At */
  end_at?: number
  attributes?: PersistentCampaignAttributes
  /** Id */
  id: string
}

/** CampaignAttributes */
export interface CampaignAttributes {
  /** Datetime */
  datetime?: number
  /** Abandoned Sku */
  abandoned_sku?: string
  /** Abandoned Price */
  abandoned_price?: number
  /** Abandoned Is Stackable */
  abandoned_is_stackable?: boolean
  /** Purchase Sku */
  purchase_sku?: string
  /** Purchase Price */
  purchase_price?: number
  /** Purchase Is Stackable */
  purchase_is_stackable?: boolean
  /** Coupon Redeemed Item Sku */
  coupon_redeemed_item_sku?: string
  /** Coupon Redeemed Code */
  coupon_redeemed_code?: string
  /** Is First Login */
  is_first_login?: boolean
  /** Items */
  items?: StoreItemBase[]
  /**
   * Visit Country
   * @pattern ^[A-Z]{2}$
   */
  visit_country?: string
}

/** CampaignCreate */
export interface CampaignCreate {
  /** Name */
  name: string
  /** Enabled */
  enabled: boolean
  /** Type */
  type: CampaignEventType | string
  /**
   * Time Limit
   * @default false
   */
  time_limit?: boolean
  /** Start At */
  start_at?: number
  /** End At */
  end_at?: number
  attributes?: PersistentCampaignAttributes
  graph?: GraphRoot
}

/**
 * CampaignEventType
 * An enumeration.
 */
export enum CampaignEventType {
  GameHubVisit = 'game_hub_visit',
  GameHubVisitStore = 'game_hub_visit_store',
  GameHubFirstLogin = 'game_hub_first_login',
  GameHubLogin = 'game_hub_login',
  AddSegment = 'add_segment',
  CouponRedeemed = 'coupon_redeemed',
  RegisteredInGame = 'registered_in_game',
  GameLogin = 'game_login',
  PurchasedInGame = 'purchased_in_game',
  PurchasedInWeb = 'purchased_in_web',
  AbandonedCart = 'abandoned_cart',
  LevelUp = 'level_up',
  CustomEvent = 'custom_event',
}

/** CampaignSettings */
export interface CampaignSettings {
  email_frequency?: CommunicationFrequency
  mobile_popup_frequency?: CommunicationFrequency
  mobile_push_frequency?: CommunicationFrequency
  hub_popup_frequency?: CommunicationFrequency
  hub_push_frequency?: CommunicationFrequency
  all_frequency?: CommunicationFrequency
}

/** CampaignTemplateCreate */
export interface CampaignTemplateCreate {
  /** An enumeration. */
  type: CampaignEventType
  /** Graph */
  graph?: any
  /** Source Campaign Id */
  source_campaign_id: string
}

/** CampaignTemplateRead */
export interface CampaignTemplateRead {
  /** An enumeration. */
  type: CampaignEventType
  /** Graph */
  graph?: any
  /** Source Campaign Id */
  source_campaign_id: string
  /** Id */
  id: string
}

/** CampaignUpdate */
export interface CampaignUpdate {
  /** Name */
  name?: string
  /** Enabled */
  enabled?: boolean
  /** Type */
  type?: CampaignEventType | string
  graph?: GraphRoot
  /** Start At */
  start_at?: number
  /** End At */
  end_at?: number
  /** Time Limit */
  time_limit?: boolean
  attributes?: PersistentCampaignAttributes
}

/** CampaignUserHistoryItem */
export interface CampaignUserHistoryItem {
  /** Id */
  id: string
  /** Created At */
  created_at: number
  /** Finish At */
  finish_at?: number
  /** User Id */
  user_id: string
  /** Player Id */
  player_id: string
  /** An enumeration. */
  status: CampaignUserStatus
  /** Error Text */
  error_text?: string
  event_action_body?: EventActionBody
}

/**
 * CampaignUserStatus
 * An enumeration.
 */
export enum CampaignUserStatus {
  Success = 'success',
  Failure = 'failure',
  InProgress = 'in_progress',
  Wait = 'wait',
}

/** CheckDocsAccessRequest */
export interface CheckDocsAccessRequest {
  /**
   * Email
   * @format email
   */
  email: string
}

/** CheckWebhookRequest */
export interface CheckWebhookRequest {
  /** An enumeration. */
  event_type: WebhookEventType
}

/** CheckWebhookResponse */
export interface CheckWebhookResponse {
  /** Event Id */
  event_id: string
  /** Detail */
  detail: string
  /** Status Code */
  status_code?: number
  /** Response Text */
  response_text?: string
}

/** CommunicationFrequency */
export interface CommunicationFrequency {
  /** An enumeration. */
  period_type: PeriodType
  /** Limit */
  limit: number
}

/**
 * CommunicationType
 * An enumeration.
 */
export enum CommunicationType {
  Email = 'email',
  AppPush = 'app_push',
  AppPopup = 'app_popup',
  HubPush = 'hub_push',
  HubPopup = 'hub_popup',
}

/** Company */
export interface Company {
  /**
   * Name
   * Company name
   * @maxLength 254
   */
  name: string
  /**
   * Size
   * Number of employees
   * @min 1
   */
  size: number
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string
  /** Domains */
  domains?: string[]
  /** Viewer Domains */
  viewer_domains?: string[]
  /**
   * Logo Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  logo_url?: string
  /** Id */
  id: string
}

/** CompanyCreate */
export interface CompanyCreate {
  /**
   * Name
   * Company name
   * @maxLength 254
   */
  name: string
  /**
   * Size
   * Number of employees
   * @min 1
   */
  size: number
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string
  /** Domains */
  domains?: string[]
  /** Viewer Domains */
  viewer_domains?: string[]
  /**
   * Logo Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  logo_url?: string
}

/** CompanyGame */
export interface CompanyGame {
  /**
   * Name
   * Company name
   * @maxLength 254
   */
  name: string
  /**
   * Size
   * Number of employees
   * @min 1
   */
  size: number
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string
  /** Domains */
  domains?: string[]
  /** Viewer Domains */
  viewer_domains?: string[]
  /**
   * Logo Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  logo_url?: string
  /** Id */
  id: string
  /** Games */
  games?: AghanimGameSchemasGame[]
  /**
   * Is Demo
   * @default false
   */
  is_demo?: boolean
  /** An enumeration. */
  role: Role
}

/** CompanyLegalDetails */
export interface CompanyLegalDetails {
  /**
   * Business Name
   * @minLength 3
   */
  business_name: string
  /**
   * Tin
   * @minLength 3
   */
  tin: string
  /**
   * Dba
   * @minLength 3
   */
  dba: string
  /**
   * Country
   * @pattern ^[A-Z]{2}$
   */
  country: string
  /**
   * Address1
   * @minLength 3
   */
  address1: string
  /** Address2 */
  address2: string
  /**
   * City
   * @minLength 3
   */
  city: string
  /**
   * State
   * @minLength 2
   */
  state: string
  /**
   * Postal
   * @minLength 3
   */
  postal: string
  /**
   * Phone
   * @minLength 3
   */
  phone: string
}

/** CompanyUpdate */
export interface CompanyUpdate {
  /** Name */
  name?: string
  /** Size */
  size?: number
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url?: string
  /** Domains */
  domains?: string[]
  /** Viewer Domains */
  viewer_domains?: string[]
  /**
   * Logo Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  logo_url?: string
}

/** ConditionNode */
export interface ConditionNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "ConditionNode"
   */
  model_type?: 'ConditionNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Expression */
  expression?: string
  expr: Expression
  /** True Node Id */
  true_node_id?: string
  /** False Node Id */
  false_node_id?: string
}

/** ConversionFirstPurchase */
export interface ConversionFirstPurchase {
  /** Data */
  data: ConversionHubValue[]
  /** Prev Data */
  prev_data: ConversionHubValue[]
}

/** ConversionHubValue */
export interface ConversionHubValue {
  /** Date */
  date: string
  /** Users Count */
  users_count: number
  /** Payment Users Count */
  payment_users_count: number
}

/**
 * Country
 * An enumeration.
 */
export enum Country {
  VA = 'VA',
  MM = 'MM',
  PE = 'PE',
  DK = 'DK',
  PY = 'PY',
  SV = 'SV',
  US = 'US',
  SI = 'SI',
  SG = 'SG',
  CZ = 'CZ',
  KE = 'KE',
  JP = 'JP',
  KR = 'KR',
  NZ = 'NZ',
  KZ = 'KZ',
  AE = 'AE',
  IQ = 'IQ',
  AU = 'AU',
  CL = 'CL',
  QA = 'QA',
  NL = 'NL',
  IN = 'IN',
  GE = 'GE',
  EE = 'EE',
  BM = 'BM',
  AT = 'AT',
  VN = 'VN',
  LI = 'LI',
  KW = 'KW',
  TC = 'TC',
  BR = 'BR',
  PL = 'PL',
  MY = 'MY',
  HK = 'HK',
  MC = 'MC',
  VG = 'VG',
  GI = 'GI',
  SE = 'SE',
  EG = 'EG',
  GH = 'GH',
  CH = 'CH',
  LK = 'LK',
  SA = 'SA',
  SM = 'SM',
  PK = 'PK',
  DE = 'DE',
  TW = 'TW',
  LU = 'LU',
  BO = 'BO',
  FM = 'FM',
  CA = 'CA',
  SK = 'SK',
  LT = 'LT',
  FR = 'FR',
  KY = 'KY',
  LV = 'LV',
  CY = 'CY',
  MX = 'MX',
  RO = 'RO',
  TZ = 'TZ',
  HU = 'HU',
  PH = 'PH',
  MO = 'MO',
  FI = 'FI',
  RS = 'RS',
  EC = 'EC',
  IE = 'IE',
  ID = 'ID',
  OM = 'OM',
  BD = 'BD',
  PA = 'PA',
  UA = 'UA',
  ZA = 'ZA',
  ES = 'ES',
  PT = 'PT',
  NO = 'NO',
  NG = 'NG',
  TH = 'TH',
  IL = 'IL',
  GB = 'GB',
  JO = 'JO',
  IT = 'IT',
  BG = 'BG',
  TR = 'TR',
  BH = 'BH',
  CR = 'CR',
  MA = 'MA',
  HR = 'HR',
  GR = 'GR',
  KH = 'KH',
  CO = 'CO',
  BE = 'BE',
  DZ = 'DZ',
  CN = 'CN',
  AD = 'AD',
  AL = 'AL',
  BA = 'BA',
  IS = 'IS',
  MD = 'MD',
  ME = 'ME',
  MK = 'MK',
  MT = 'MT',
}

/** CouponBonus */
export interface CouponBonus {
  /** Id */
  id: string
  /** Name */
  name: string
  /** Code */
  code: string
  /** Bonus Percent */
  bonus_percent: number
}

/** CouponCreate */
export interface CouponCreate {
  /**
   * Name
   * The name of the coupon
   * @maxLength 255
   */
  name: string
  /**
   * Code
   * The code of the coupon
   * @maxLength 20
   */
  code: string
  /** The type of the coupon */
  type: CouponType
  /**
   * Item Id
   * The item ID for the coupon
   */
  item_id?: string
  /**
   * Discount Percent
   * The discount percent for the coupon
   * @min 0
   * @max 100
   */
  discount_percent?: number
  /**
   * Bonus Percent
   * The bonus percent for the coupon
   * @min 0
   * @max 1000
   */
  bonus_percent?: number
  /**
   * Requirements Expression
   * The requirement expression for the coupon
   */
  requirements_expression?: Expression
  /** Expires At */
  expires_at?: number
  /** Applicable Item Ids */
  applicable_item_ids?: string[]
  /** Min Amount */
  min_amount?: number
  /** Max Redemptions */
  max_redemptions?: number
  /** Is Stackable With Bonus Coupons */
  is_stackable_with_bonus_coupons?: boolean
  /** Stackable Bonus Limit Percent */
  stackable_bonus_limit_percent?: number
  /** Is Stackable With Discount Coupons */
  is_stackable_with_discount_coupons?: boolean
  /** Stackable Discount Limit Percent */
  stackable_discount_limit_percent?: number
}

/** CouponDiscount */
export interface CouponDiscount {
  /** Id */
  id: string
  /** Name */
  name: string
  /** Code */
  code: string
  /** Discount Percent */
  discount_percent: number
}

/** CouponRead */
export interface CouponRead {
  /**
   * Name
   * The name of the coupon
   * @maxLength 255
   */
  name: string
  /**
   * Code
   * The code of the coupon
   * @maxLength 20
   */
  code: string
  /** The type of the coupon */
  type: CouponType
  /**
   * Item Id
   * The item ID for the coupon
   */
  item_id?: string
  /**
   * Discount Percent
   * The discount percent for the coupon
   * @min 0
   * @max 100
   */
  discount_percent?: number
  /**
   * Bonus Percent
   * The bonus percent for the coupon
   * @min 0
   * @max 1000
   */
  bonus_percent?: number
  /**
   * Requirements Expression
   * The requirement expression for the coupon
   */
  requirements_expression?: Expression
  /**
   * Id
   * The unique identifier of the coupon
   */
  id: string
  /**
   * Item
   * The item for the coupon
   */
  item?: Item
  /**
   * Expires At
   * The expiration date of the coupon
   */
  expires_at?: number
  /**
   * Applicable Item Ids
   * The applicable item IDs
   */
  applicable_item_ids?: string[]
  /** Min Amount */
  min_amount?: number
  /** Max Redemptions */
  max_redemptions?: number
  /** Is Stackable With Bonus Coupons */
  is_stackable_with_bonus_coupons?: boolean
  /** Stackable Bonus Limit Percent */
  stackable_bonus_limit_percent?: number
  /** Is Stackable With Discount Coupons */
  is_stackable_with_discount_coupons?: boolean
  /** Stackable Discount Limit Percent */
  stackable_discount_limit_percent?: number
}

/** CouponTranslate */
export interface CouponTranslate {
  /**
   * Name
   * The name of the coupon
   * @maxLength 255
   */
  name?: string
}

/**
 * CouponType
 * An enumeration.
 */
export enum CouponType {
  Bonus = 'bonus',
  Discount = 'discount',
  FreeItem = 'free_item',
}

/** CouponUpdate */
export interface CouponUpdate {
  /**
   * Name
   * The name of the coupon
   * @maxLength 255
   */
  name?: string
  /** Expires At */
  expires_at?: number
  /** Applicable Item Ids */
  applicable_item_ids?: string[]
  /** Min Amount */
  min_amount?: number
  /** Max Redemptions */
  max_redemptions?: number
  /** Is Stackable With Bonus Coupons */
  is_stackable_with_bonus_coupons?: boolean
  /** Stackable Bonus Limit Percent */
  stackable_bonus_limit_percent?: number
  /** Is Stackable With Discount Coupons */
  is_stackable_with_discount_coupons?: boolean
  /** Stackable Discount Limit Percent */
  stackable_discount_limit_percent?: number
  requirements_expression?: Expression
  /** Item Id */
  item_id?: string
}

/** CreateCouponNode */
export interface CreateCouponNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "CreateCouponNode"
   */
  model_type?: 'CreateCouponNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Duration */
  duration?: number
  /** An enumeration. */
  type: CouponType
  /** Item Id */
  item_id?: string
  /**
   * Use Event Item
   * @default false
   */
  use_event_item?: boolean
  /** Discount Percent */
  discount_percent?: number
  /** Bonus Percent */
  bonus_percent?: number
  /** Min Amount */
  min_amount?: number
  /** Stackable Bonus Limit */
  stackable_bonus_limit?: number
  /** Stackable Discount Limit */
  stackable_discount_limit?: number
  /** Code Mask */
  code_mask?: string
  /** Player Bond */
  player_bond?: boolean
  /** Applicable Item Ids */
  applicable_item_ids?: string[]
  /** Payment Method Ids */
  payment_method_ids?: string[]
  /** Max Redemptions */
  max_redemptions?: number
  /** Is Stackable With Bonus Coupons */
  is_stackable_with_bonus_coupons?: boolean
  /** Stackable Bonus Limit Percent */
  stackable_bonus_limit_percent?: number
  /** Is Stackable With Discount Coupons */
  is_stackable_with_discount_coupons?: boolean
  /** Stackable Discount Limit Percent */
  stackable_discount_limit_percent?: number
}

/** CreateImageRequest */
export interface CreateImageRequest {
  /** Prompt */
  prompt: string
  /** Size */
  size: '256x256' | '512x512' | '1024x1024' | '1792x1024' | '1024x1792'
}

/** CreateImageResponse */
export interface CreateImageResponse {
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  url: string
}

/** CreateItemBannerActionNode */
export interface CreateItemBannerActionNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "CreateItemBannerActionNode"
   */
  model_type?: 'CreateItemBannerActionNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Page Id */
  page_id?: string
  /** Page Slug */
  page_slug?: string
  config: FeaturedBannerConfig
  /** Duration */
  duration?: number
  /** Link Created Offer */
  link_created_offer?: boolean
  /**
   * Use Event Item
   * @default false
   */
  use_event_item?: boolean
}

/** CreateUploadLinkRequest */
export interface CreateUploadLinkRequest {
  /** Bucket */
  bucket: string
  /** Image Format */
  image_format: string
}

/** CreateUploadLinkResponse */
export interface CreateUploadLinkResponse {
  /** Upload Url */
  upload_url: string
  /** Get Url */
  get_url: string
}

/** CreateVirtualSKUNode */
export interface CreateVirtualSKUNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "CreateVirtualSKUNode"
   */
  model_type?: 'CreateVirtualSKUNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Item Id */
  item_id?: string
  /**
   * Use Event Item
   * @default false
   */
  use_event_item?: boolean
  /** Start At */
  start_at?: number
  /** End At */
  end_at?: number
  /** Duration */
  duration?: number
  /** Max Purchases */
  max_purchases?: number
  /** Min Amount */
  min_amount?: number
  /** Max Reward Points Percent */
  max_reward_points_percent?: number
  /** Reward Points Percent */
  reward_points_percent?: number
  /** Payment Method Ids */
  payment_method_ids?: string[]
  /** Discount Percent */
  discount_percent?: number
  /** Bonus Percent */
  bonus_percent?: number
  /** Is Free Item */
  is_free_item?: boolean
  /** An enumeration. */
  max_purchases_period_type?: PeriodType
  /** Max Purchases Period Value */
  max_purchases_period_value?: number
  /** Custom Badge */
  custom_badge?: string
}

/**
 * Currency
 * An enumeration.
 */
export enum Currency {
  THB = 'THB',
  HKD = 'HKD',
  IDR = 'IDR',
  TRY = 'TRY',
  RON = 'RON',
  ILS = 'ILS',
  MOP = 'MOP',
  JOD = 'JOD',
  UAH = 'UAH',
  CHF = 'CHF',
  HUF = 'HUF',
  MXN = 'MXN',
  RUB = 'RUB',
  CAD = 'CAD',
  GEL = 'GEL',
  LKR = 'LKR',
  BOB = 'BOB',
  ZAR = 'ZAR',
  NZD = 'NZD',
  SAR = 'SAR',
  MMK = 'MMK',
  VND = 'VND',
  MYR = 'MYR',
  PYG = 'PYG',
  SEK = 'SEK',
  DZD = 'DZD',
  QAR = 'QAR',
  PEN = 'PEN',
  AED = 'AED',
  BRL = 'BRL',
  CLP = 'CLP',
  EGP = 'EGP',
  USD = 'USD',
  RSD = 'RSD',
  PHP = 'PHP',
  KES = 'KES',
  NOK = 'NOK',
  AUD = 'AUD',
  BDT = 'BDT',
  INR = 'INR',
  MAD = 'MAD',
  KRW = 'KRW',
  NGN = 'NGN',
  BGN = 'BGN',
  TWD = 'TWD',
  EUR = 'EUR',
  PLN = 'PLN',
  COP = 'COP',
  TZS = 'TZS',
  KZT = 'KZT',
  CRC = 'CRC',
  PKR = 'PKR',
  SGD = 'SGD',
  JPY = 'JPY',
  IQD = 'IQD',
  GBP = 'GBP',
  GHS = 'GHS',
  CZK = 'CZK',
  DKK = 'DKK',
  CNY = 'CNY',
  RP = 'RP',
}

/** CustomAttribute */
export interface CustomAttribute {
  /** Name */
  name: string
  /** Description */
  description?: string
  /** An enumeration. */
  type: AttrType
  /** Min */
  min?: number
  /** Max */
  max?: number
  /** Custom Values */
  custom_values?: string[]
}

/** Customer */
export interface Customer {
  /** Id */
  id: string
  /** Name */
  name?: string
  /** Email */
  email: string
  /** Created At */
  created_at: number
  /**
   * Avatar Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  avatar_url?: string
  /** Last Login At */
  last_login_at?: number
  /** Last Active At */
  last_active_at?: number
  /** An enumeration. */
  locale?: DashboardLocale
}

/** CustomerCompany */
export interface CustomerCompany {
  /** Id */
  id: string
  /** Name */
  name?: string
  /** Email */
  email: string
  /** Created At */
  created_at: number
  /**
   * Avatar Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  avatar_url?: string
  /** Last Login At */
  last_login_at?: number
  /** Last Active At */
  last_active_at?: number
  /** An enumeration. */
  locale?: DashboardLocale
  /** Role */
  role: Role | 'super_admin'
}

/** CustomerCompanyRead */
export interface CustomerCompanyRead {
  /** An enumeration. */
  role: Role
  customer: Customer
}

/** CustomerCompanyUpdate */
export interface CustomerCompanyUpdate {
  /** An enumeration. */
  role: Role
}

/**
 * DashboardLocale
 * An enumeration.
 */
export enum DashboardLocale {
  Zh = 'zh',
  En = 'en',
}

/**
 * DataGranularity
 * An enumeration.
 */
export enum DataGranularity {
  Day = 'day',
  Hour = 'hour',
}

/** Discount */
export interface Discount {
  /** Amount */
  amount: number
  /** An enumeration. */
  currency: Currency
  coupon: CouponDiscount
}

/** EmailActionNode */
export interface EmailActionNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "EmailActionNode"
   */
  model_type?: 'EmailActionNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Body */
  body?: string
  /** Item Id */
  item_id?: string
  /** Link Created Offer */
  link_created_offer?: boolean
  /** Store Item Id */
  store_item_id?: string
  /** Store Id */
  store_id?: string
}

/** EmailResponse */
export interface EmailResponse {
  /** Html */
  html: string
}

/** EmailTemplate */
export interface EmailTemplate {
  /** Html */
  html: string
  /** Name */
  name: string
}

/** EventActionBody */
export interface EventActionBody {
  /**
   * Calc Attributes
   * @default {}
   */
  calc_attributes?: CalcPlayerAttributes
  /**
   * Persistent Attributes
   * @default {"level":0,"saved_ach":false,"saved_card":false,"soft_currency_amount":0,"hard_currency_amount":0}
   */
  persistent_attributes?: PersistentPlayerAttributes
  /** Custom Attributes */
  custom_attributes?: object
  /**
   * Campaign Attributes
   * @default {"datetime":1726412527}
   */
  campaign_attributes?: CampaignAttributes
  /** Custom Campaign Attributes Values */
  custom_campaign_attributes_values?: object
}

/** Expression */
export interface Expression {
  /** Tokens */
  tokens?: Token[]
}

/** FAQ */
export interface FAQ {
  /** Title */
  title: string
  /** Description */
  description: string
  /** Order Id */
  order_id: number
  /** Id */
  id: string
}

/** FAQCreate */
export interface FAQCreate {
  /** Title */
  title: string
  /** Description */
  description: string
  /** Order Id */
  order_id: number
}

/** FAQUpdate */
export interface FAQUpdate {
  /** Title */
  title?: string
  /** Description */
  description?: string
  /** Order Id */
  order_id?: number
}

/** FeatureFlags */
export interface FeatureFlags {
  /** Account Settings Enabled */
  account_settings_enabled?: boolean
  /** Daily Rewards Enabled */
  daily_rewards_enabled?: boolean
  /** Loyalty Program Enabled */
  loyalty_program_enabled?: boolean
  /** Sandbox Enabled */
  sandbox_enabled?: boolean
}

/** FeaturedBannerConfig */
export interface FeaturedBannerConfig {
  /** Title */
  title?: string
  /** Description */
  description?: string
  /** Image Url */
  image_url?: string
  /** Background Image Size */
  background_image_size?: string
  /** Featured Item Id */
  featured_item_id?: string
  /** Store Item Id */
  store_item_id?: string
  /** Store Id */
  store_id?: string
  /** Replace Item Block */
  replace_item_block?: boolean
  /** Replace Item Block Name */
  replace_item_block_name?: string
}

/** FeaturedItemBlock */
export interface FeaturedItemBlock {
  /**
   * Block
   * @default "FeaturedItemBlock"
   */
  block?: 'FeaturedItemBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
  /** Item Id */
  item_id?: string
  /** Store Item Id */
  store_item_id?: string
  /** Store Id */
  store_id?: string
  /** Background Image Size */
  background_image_size?: string
}

/** FeaturedNewsBlock */
export interface FeaturedNewsBlock {
  /**
   * Block
   * @default "FeaturedNewsBlock"
   */
  block?: 'FeaturedNewsBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
  /** News Id */
  news_id?: string
}

/** FeaturedPageBlock */
export interface FeaturedPageBlock {
  /**
   * Block
   * @default "FeaturedPageBlock"
   */
  block?: 'FeaturedPageBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
  /** Page Id */
  page_id: string
}

/**
 * FilterType
 * An enumeration.
 */
export enum FilterType {
  Period = 'period',
  Today = 'today',
  Yesterday = 'yesterday',
}

/** FullNewsBlock */
export interface FullNewsBlock {
  /**
   * Block
   * @default "FullNewsBlock"
   */
  block?: 'FullNewsBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
  /** News Id */
  news_id?: string
}

/** GameCreate */
export interface GameCreate {
  /**
   * Name
   * @minLength 2
   * @maxLength 200
   */
  name: string
  /**
   * Description
   * @minLength 2
   * @maxLength 1000
   */
  description: string
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string
  /**
   * Logo Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  logo_url: string
  /** Social Medias */
  social_medias: SocialMedia[]
  /** Game Stores */
  game_stores: GameStore[]
  /** An enumeration. */
  login_type: LoginType
  login_settings?: LoginSettings
  /** Language codes according to ISO 639-1. */
  locale_default: Locale
}

/** GameSettings */
export interface GameSettings {
  /** Player Attributes */
  player_attributes?: CustomAttribute[]
  campaign_settings?: CampaignSettings
  /** Purchase Successful Text */
  purchase_successful_text?: string
  /** Receipt Appreciation Text */
  receipt_appreciation_text?: string
  /**
   * Back To Game Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  back_to_game_url?: string
  feature_flags?: FeatureFlags
  onboarding_progress?: OnboardingProgress
  /** Enable Reward Points */
  enable_reward_points?: boolean
  reward_points_settings?: RewardPointsSettings
  /** Use Only Game Player Country */
  use_only_game_player_country?: boolean
  /** Id */
  id: string
}

/** GameSettingsCreate */
export interface GameSettingsCreate {
  /** Player Attributes */
  player_attributes?: CustomAttribute[]
  campaign_settings?: CampaignSettings
  /**
   * Purchase Successful Text
   * @maxLength 255
   */
  purchase_successful_text?: string
  /**
   * Receipt Appreciation Text
   * @maxLength 255
   */
  receipt_appreciation_text?: string
  /**
   * Back To Game Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  back_to_game_url?: string
  feature_flags?: FeatureFlags
  onboarding_progress?: OnboardingProgress
  /** Enable Reward Points */
  enable_reward_points?: boolean
  reward_points_settings?: RewardPointsSettings
  /** Use Only Game Player Country */
  use_only_game_player_country?: boolean
}

/** GameSettingsUpdate */
export interface GameSettingsUpdate {
  /** Player Attributes */
  player_attributes?: CustomAttribute[]
  campaign_settings?: CampaignSettings
  /**
   * Purchase Successful Text
   * @maxLength 255
   */
  purchase_successful_text?: string
  /**
   * Receipt Appreciation Text
   * @maxLength 255
   */
  receipt_appreciation_text?: string
  /**
   * Back To Game Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  back_to_game_url?: string
  onboarding_progress?: OnboardingProgress
  /** Enable Reward Points */
  enable_reward_points?: boolean
  reward_points_settings?: RewardPointsSettings
  /** Use Only Game Player Country */
  use_only_game_player_country?: boolean
}

/** GameSettingsUpdateFeatures */
export interface GameSettingsUpdateFeatures {
  /** Player Attributes */
  player_attributes?: CustomAttribute[]
  campaign_settings?: CampaignSettings
  /**
   * Purchase Successful Text
   * @maxLength 255
   */
  purchase_successful_text?: string
  /**
   * Receipt Appreciation Text
   * @maxLength 255
   */
  receipt_appreciation_text?: string
  /**
   * Back To Game Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  back_to_game_url?: string
  onboarding_progress?: OnboardingProgress
  /** Enable Reward Points */
  enable_reward_points?: boolean
  reward_points_settings?: RewardPointsSettings
  /** Use Only Game Player Country */
  use_only_game_player_country?: boolean
  feature_flags?: FeatureFlags
}

/** GameStore */
export interface GameStore {
  /** An enumeration. */
  name: GameStoreName
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string
}

/**
 * GameStoreName
 * An enumeration.
 */
export enum GameStoreName {
  AppStore = 'app_store',
  GooglePlay = 'google_play',
}

/** GameTaskRead */
export interface GameTaskRead {
  /** An enumeration. */
  type: GameTaskType
  /** An enumeration. */
  status: TaskStatus
  /** Id */
  id: string
}

/**
 * GameTaskType
 * An enumeration.
 */
export enum GameTaskType {
  ConfigureApiKeys = 'configure_api_keys',
  ConfigureWebhooks = 'configure_webhooks',
  IntegrateGame = 'integrate_game',
}

/** GameUpdate */
export interface GameUpdate {
  /**
   * Name
   * @minLength 2
   * @maxLength 200
   */
  name?: string
  /**
   * Description
   * @minLength 2
   * @maxLength 1000
   */
  description?: string
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url?: string
  /**
   * Logo Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  logo_url?: string
  /** Social Medias */
  social_medias?: SocialMedia[]
  /** Game Stores */
  game_stores?: GameStore[]
  /** An enumeration. */
  login_type?: LoginType
  login_settings?: LoginSettings
  /** Language codes according to ISO 639-1. */
  locale_default?: Locale
}

/** GenerationProgress */
export interface GenerationProgress {
  /** Total */
  total: number
  /** Current */
  current: number
}

/** GenerationProgressState */
export interface GenerationProgressState {
  /** Start At */
  start_at?: number
  colors?: GenerationProgress
  background_image?: GenerationProgress
  screenshots?: GenerationProgress
  news?: GenerationProgress
  sku_items?: GenerationProgress
  best_youtube?: GenerationProgress
}

/** GetCompletionRequest */
export interface GetCompletionRequest {
  /** Prompt */
  prompt: string
  /** Context */
  context: string
}

/** GlobalBonus */
export interface GlobalBonus {
  /** Bonus Value */
  bonus_value: number
  /** Bonus Percent */
  bonus_percent: number
}

/** GlobalDiscount */
export interface GlobalDiscount {
  /** Amount */
  amount: number
  /** An enumeration. */
  currency: Currency
  /** Discount Percent */
  discount_percent: number
}

/** GraphRoot */
export interface GraphRoot {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "GraphRoot"
   */
  model_type?: string
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Nodes */
  nodes?: Record<string, Node>
  view_port?: ViewPort
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** ImageCrop */
export interface ImageCrop {
  image_size: ImageSize
  /** Aspect Ratio */
  aspect_ratio: '1:1' | '4:3'
  transforms?: ImageTransforms
  visible_area?: Rect
  coordinates?: Rect
}

/** ImageFlip */
export interface ImageFlip {
  /** Horizontal */
  horizontal: boolean
  /** Vertical */
  vertical: boolean
}

/** ImageSize */
export interface ImageSize {
  /** Width */
  width: number
  /** Height */
  height: number
}

/** ImageTransforms */
export interface ImageTransforms {
  /** Rotate */
  rotate: number
  flip: ImageFlip
}

/**
 * ImageUsageContext
 * An enumeration.
 */
export enum ImageUsageContext {
  Store = 'store',
  FeaturedBannerMain = 'featured_banner_main',
  FeaturedBannerStore = 'featured_banner_store',
  PaymentReceipt = 'payment_receipt',
}

/** InfinitePaginatedResponse[TranslationHtmlStrippedRead] */
export interface InfinitePaginatedResponseTranslationHtmlStrippedRead {
  /** Data */
  data: TranslationHtmlStrippedRead[]
  /** Has More */
  has_more: boolean
}

/** InviteAcceptResponse */
export interface InviteAcceptResponse {
  /** Company Id */
  company_id: string
  /** Game Id */
  game_id?: string
}

/** InviteCreate */
export interface InviteCreate {
  /** Email */
  email: string
  /** Name */
  name?: string
  /** An enumeration. */
  role: Role
}

/** InvitePreview */
export interface InvitePreview {
  /** Invited By User */
  invited_by_user: string
  /** Company Name */
  company_name: string
  /** An enumeration. */
  role: Role
}

/** InviteRead */
export interface InviteRead {
  /** Email */
  email: string
  /** Name */
  name?: string
  /** An enumeration. */
  role: Role
  /** Id */
  id: string
  created_by_user: Customer
  /** Created At */
  created_at: number
}

/** Item */
export interface Item {
  /**
   * Name
   * The name of the item
   * @maxLength 255
   */
  name: string
  /**
   * Description
   * The description of the item
   * @maxLength 512
   */
  description: string
  /**
   * Price
   * The price of the item
   */
  price: number
  /**
   * Price Point
   * The price point for local prices
   * @exclusiveMin 0
   * @max 5000000
   */
  price_point?: number
  /**
   * Reward Points Price
   * The reward points price for local prices
   */
  reward_points_price?: number
  /**
   * Discount Percent
   * The discount percent for the item
   * @min 0
   * @max 100
   */
  discount_percent?: number
  /**
   * Bonus Percent
   * The bonus percent for the item
   * @min 0
   * @max 1000
   */
  bonus_percent?: number
  /** The currency of the item */
  currency: Currency
  /**
   * Sku
   * The SKU of the item
   * @maxLength 100
   */
  sku: string
  /**
   * Image Url
   * The URL of the image for the item
   */
  image_url: string | ''
  /**
   * Image Url Featured
   * The URL of the image for the item in the featured card
   */
  image_url_featured?: string | ''
  /** The type of the item */
  type: ItemType
  /**
   * Quantity
   * The quantity of the item
   * @min 1
   * @default 1
   */
  quantity?: number
  /**
   * Is Stackable
   * Whether the item is stackable
   */
  is_stackable: boolean
  /**
   * Is Currency
   * Whether the item is a currency.
   */
  is_currency: boolean
  /**
   * Position
   * Position of the item in list
   */
  position?: number
  /**
   * Categories
   * The categories list
   */
  categories?: string[]
  /**
   * Id
   * The unique identifier of the item
   */
  id: string
  /**
   * Properties
   * The item properties
   */
  properties: ItemPropertyRelationRead[]
}

/** ItemBonusOffer */
export interface ItemBonusOffer {
  /** Bonus Percent */
  bonus_percent: number
  /** Bonus Value */
  bonus_value: number
}

/** ItemBonusOfferActionNode */
export interface ItemBonusOfferActionNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "ItemBonusOfferActionNode"
   */
  model_type?: 'ItemBonusOfferActionNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Item Id */
  item_id?: string
  /** Discount Percent */
  discount_percent?: number
  /** Bonus Percent */
  bonus_percent?: number
  /** Duration */
  duration?: number
  /**
   * Use Event Item
   * @default false
   */
  use_event_item?: boolean
  /**
   * Item Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  item_image_url?: string
  /** Item Name */
  item_name?: string
  /** Item Description */
  item_description?: string
}

/** ItemBulkDelete */
export interface ItemBulkDelete {
  /** Id */
  id: string
}

/** ItemBulkUpdate */
export interface ItemBulkUpdate {
  /**
   * Name
   * The name of the item
   * @maxLength 255
   */
  name?: string
  /**
   * Description
   * The description of the item
   * @maxLength 512
   */
  description?: string
  /**
   * Price
   * The price of the item
   */
  price?: number
  /**
   * Price Point
   * The price point for local prices
   * @exclusiveMin 0
   * @max 5000000
   */
  price_point?: number
  /**
   * Reward Points Price
   * The reward points price for local prices
   */
  reward_points_price?: number
  /**
   * Discount Percent
   * The discount percent for the item
   * @exclusiveMin 0
   * @max 100
   */
  discount_percent?: number
  /**
   * Bonus Percent
   * The bonus percent for the item
   * @exclusiveMin 0
   * @max 1000
   */
  bonus_percent?: number
  /** The currency of the item */
  currency?: Currency
  /**
   * Sku
   * The SKU of the item
   * @maxLength 100
   */
  sku?: string
  /**
   * Image Url
   * The URL of the image for the item
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
  /**
   * Image Url Featured
   * The URL of the image for the item in the featured card
   */
  image_url_featured?: string | ''
  /** The type of the item */
  type?: ItemType
  /**
   * Quantity
   * The quantity of the item
   * @min 1
   */
  quantity?: number
  /**
   * Is Stackable
   * Whether the item is stackable
   */
  is_stackable?: boolean
  /**
   * Is Currency
   * Whether the item is a currency.
   */
  is_currency?: boolean
  /**
   * Archived At
   * The timestamp of when the item was archived
   */
  archived_at?: number
  /**
   * Position
   * Position of the item in list
   */
  position?: number
  /**
   * Categories
   * The categories list
   */
  categories?: string[]
  /**
   * Id
   * The unique identifier of the item
   */
  id: string
}

/** ItemCategoryBulkDelete */
export interface ItemCategoryBulkDelete {
  /** Id */
  id: string
}

/** ItemCategoryBulkUpdate */
export interface ItemCategoryBulkUpdate {
  /** Name */
  name?: string
  /** Description */
  description?: string
  /** Position */
  position?: number
  /** Slug */
  slug?: string
  /** Id */
  id: string
}

/** ItemCategoryCreate */
export interface ItemCategoryCreate {
  /** Name */
  name: string
  /** Description */
  description: string
  /** Position */
  position: number
  /** Slug */
  slug?: string
}

/** ItemCategoryRead */
export interface ItemCategoryRead {
  /** Name */
  name: string
  /** Description */
  description: string
  /** Position */
  position: number
  /** Slug */
  slug?: string
  /** Id */
  id: string
}

/** ItemCategoryUpdate */
export interface ItemCategoryUpdate {
  /** Name */
  name?: string
  /** Description */
  description?: string
  /** Position */
  position?: number
  /** Slug */
  slug?: string
}

/**
 * ItemClaimedType
 * An enumeration.
 */
export enum ItemClaimedType {
  StoreItem = 'store_item',
  DailyReward = 'daily_reward',
  LoyaltyProgram = 'loyalty_program',
}

/** ItemCreate */
export interface ItemCreate {
  /**
   * Name
   * The name of the item
   * @maxLength 255
   */
  name: string
  /**
   * Description
   * The description of the item
   * @maxLength 512
   */
  description: string
  /**
   * Price
   * The price of the item
   */
  price: number
  /**
   * Price Point
   * The price point for local prices
   * @exclusiveMin 0
   * @max 5000000
   */
  price_point?: number
  /**
   * Reward Points Price
   * The reward points price for local prices
   */
  reward_points_price?: number
  /**
   * Discount Percent
   * The discount percent for the item
   * @min 0
   * @max 100
   */
  discount_percent?: number
  /**
   * Bonus Percent
   * The bonus percent for the item
   * @min 0
   * @max 1000
   */
  bonus_percent?: number
  /** The currency of the item */
  currency: Currency
  /**
   * Sku
   * The SKU of the item
   * @maxLength 100
   */
  sku: string
  /**
   * Image Url
   * The URL of the image for the item
   */
  image_url: string | ''
  /**
   * Image Url Featured
   * The URL of the image for the item in the featured card
   */
  image_url_featured?: string | ''
  /** The type of the item */
  type: ItemType
  /**
   * Quantity
   * The quantity of the item
   * @min 1
   * @default 1
   */
  quantity?: number
  /**
   * Is Stackable
   * Whether the item is stackable
   */
  is_stackable: boolean
  /**
   * Is Currency
   * Whether the item is a currency.
   */
  is_currency: boolean
  /**
   * Position
   * Position of the item in list
   */
  position?: number
  /**
   * Categories
   * The categories list
   */
  categories?: string[]
}

/** ItemDiscountOffer */
export interface ItemDiscountOffer {
  /** Amount */
  amount: number
  /** An enumeration. */
  currency: Currency
  /** Discount Percent */
  discount_percent: number
}

/** ItemDiscountOfferActionNode */
export interface ItemDiscountOfferActionNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "ItemDiscountOfferActionNode"
   */
  model_type?: 'ItemDiscountOfferActionNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Item Id */
  item_id?: string
  /** Discount Percent */
  discount_percent?: number
  /** Bonus Percent */
  bonus_percent?: number
  /** Duration */
  duration?: number
  /**
   * Use Event Item
   * @default false
   */
  use_event_item?: boolean
  /**
   * Item Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  item_image_url?: string
  /** Item Name */
  item_name?: string
  /** Item Description */
  item_description?: string
}

/** ItemGeneralOffer */
export interface ItemGeneralOffer {
  /** Item Id */
  item_id: string
  /**
   * Item Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  item_image_url?: string
  /** Item Name */
  item_name?: string
  /** Item Description */
  item_description?: string
  /** Bonus Percent */
  bonus_percent?: number
  /** Discount Percent */
  discount_percent?: number
}

/** ItemImageCreate */
export interface ItemImageCreate {
  /** An enumeration. */
  usage_context?: ImageUsageContext
  /**
   * Original Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  original_url: string
  /**
   * Cropped Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  cropped_url?: string
  crop?: ImageCrop
  /** Item Id */
  item_id: string
}

/** ItemImageRead */
export interface ItemImageRead {
  /** An enumeration. */
  usage_context?: ImageUsageContext
  /**
   * Original Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  original_url: string
  /**
   * Cropped Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  cropped_url?: string
  crop?: ImageCrop
  /** Id */
  id: string
  /** Created At */
  created_at: number
  /** Modified At */
  modified_at?: number
}

/** ItemImageUpdate */
export interface ItemImageUpdate {
  /**
   * Cropped Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  cropped_url?: string
  crop?: ImageCrop
}

/** ItemOfferActionNode */
export interface ItemOfferActionNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "ItemOfferActionNode"
   */
  model_type?: 'ItemOfferActionNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Item Id */
  item_id?: string
  /** Discount Percent */
  discount_percent?: number
  /** Bonus Percent */
  bonus_percent?: number
  /** Duration */
  duration?: number
  /**
   * Use Event Item
   * @default false
   */
  use_event_item?: boolean
  /**
   * Item Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  item_image_url?: string
  /** Item Name */
  item_name?: string
  /** Item Description */
  item_description?: string
}

/** ItemPropertyConfigNumber */
export interface ItemPropertyConfigNumber {
  /**
   * Property Type
   * @default "number"
   */
  property_type?: 'number'
}

/** ItemPropertyConfigRange */
export interface ItemPropertyConfigRange {
  /**
   * Property Type
   * @default "range"
   */
  property_type?: 'range'
  /** Max */
  max: number
  /**
   * Min
   * @default 0
   */
  min?: number
}

/** ItemPropertyConfigString */
export interface ItemPropertyConfigString {
  /**
   * Property Type
   * @default "string"
   */
  property_type?: 'string'
}

/** ItemPropertyCreate */
export interface ItemPropertyCreate {
  /**
   * Name
   * The name of the property
   * @maxLength 255
   */
  name: string
  /**
   * Description
   * The description of the property
   * @maxLength 512
   */
  description?: string
  /**
   * Icon Url
   * The URL of the icon for the property
   */
  icon_url?: string | ''
  /**
   * Config
   * The config of the property
   */
  config:
    | ({
        property_type: 'range'
      } & ItemPropertyConfigRange)
    | ({
        property_type: 'number'
      } & ItemPropertyConfigNumber)
    | ({
        property_type: 'string'
      } & ItemPropertyConfigString)
}

/** ItemPropertyRead */
export interface ItemPropertyRead {
  /**
   * Name
   * The name of the property
   * @maxLength 255
   */
  name: string
  /**
   * Description
   * The description of the property
   * @maxLength 512
   */
  description?: string
  /**
   * Icon Url
   * The URL of the icon for the property
   */
  icon_url?: string | ''
  /**
   * Config
   * The config of the property
   */
  config:
    | ({
        property_type: 'range'
      } & ItemPropertyConfigRange)
    | ({
        property_type: 'number'
      } & ItemPropertyConfigNumber)
    | ({
        property_type: 'string'
      } & ItemPropertyConfigString)
  /**
   * Id
   * The unique identifier of the property
   */
  id: string
}

/** ItemPropertyRelationCreate */
export interface ItemPropertyRelationCreate {
  /** Property Id */
  property_id: string
  data?: ItemPropertyRelationData
  /** Position */
  position: number
}

/** ItemPropertyRelationData */
export interface ItemPropertyRelationData {
  /** Value */
  value?: number | string
}

/** ItemPropertyRelationRead */
export interface ItemPropertyRelationRead {
  /** Property Id */
  property_id: string
  data?: ItemPropertyRelationData
  /** Position */
  position: number
  /** Id */
  id: string
  /** Item Id */
  item_id: string
  item_property: ItemPropertyRead
}

/** ItemPropertyRelationUpdate */
export interface ItemPropertyRelationUpdate {
  data?: ItemPropertyRelationData
  /** Position */
  position?: number
}

/** ItemPropertyUpdate */
export interface ItemPropertyUpdate {
  /**
   * Name
   * The name of the property
   * @maxLength 255
   */
  name?: string
  /**
   * Description
   * The description of the property
   * @maxLength 512
   */
  description?: string
  /**
   * Icon Url
   * The URL of the icon for the property
   */
  icon_url?: string | ''
  /** Config */
  config?: ItemPropertyConfigRange | ItemPropertyConfigNumber | ItemPropertyConfigString
}

/** ItemRead */
export interface ItemRead {
  /**
   * Name
   * The name of the item
   * @maxLength 255
   */
  name: string
  /**
   * Description
   * The description of the item
   * @maxLength 512
   */
  description: string
  /**
   * Price
   * The price of the item
   */
  price: number
  /**
   * Price Point
   * The price point for local prices
   * @exclusiveMin 0
   * @max 5000000
   */
  price_point?: number
  /**
   * Reward Points Price
   * The reward points price for local prices
   */
  reward_points_price?: number
  /**
   * Discount Percent
   * The discount percent for the item
   * @min 0
   * @max 100
   */
  discount_percent?: number
  /**
   * Bonus Percent
   * The bonus percent for the item
   * @min 0
   * @max 1000
   */
  bonus_percent?: number
  /** The currency of the item */
  currency: Currency
  /**
   * Sku
   * The SKU of the item
   * @maxLength 100
   */
  sku: string
  /**
   * Image Url
   * The URL of the image for the item
   */
  image_url: string | ''
  /**
   * Image Url Featured
   * The URL of the image for the item in the featured card
   */
  image_url_featured?: string | ''
  /** The type of the item */
  type: ItemType
  /**
   * Quantity
   * The quantity of the item
   * @min 1
   * @default 1
   */
  quantity?: number
  /**
   * Is Stackable
   * Whether the item is stackable
   */
  is_stackable: boolean
  /**
   * Is Currency
   * Whether the item is a currency.
   */
  is_currency: boolean
  /**
   * Position
   * Position of the item in list
   */
  position?: number
  /**
   * Categories
   * The categories list
   */
  categories?: string[]
  /**
   * Id
   * The unique identifier of the item
   */
  id: string
  /**
   * Properties
   * The item properties
   */
  properties: ItemPropertyRelationRead[]
  /**
   * Created At
   * The timestamp of when the item was created
   */
  created_at: number
  /**
   * Modified At
   * The timestamp of when the item was modified
   */
  modified_at?: number
  /**
   * Archived At
   * The timestamp of when the item was archived
   */
  archived_at?: number
  /** Price Minor Unit */
  price_minor_unit: number
}

/** ItemTranslate */
export interface ItemTranslate {
  /**
   * Name
   * The name of the item
   * @maxLength 255
   */
  name?: string
  /**
   * Description
   * The description of the item
   * @maxLength 512
   */
  description?: string
}

/**
 * ItemType
 * An enumeration.
 */
export enum ItemType {
  Item = 'item',
  Currency = 'currency',
}

/** ItemUpdate */
export interface ItemUpdate {
  /**
   * Name
   * The name of the item
   * @maxLength 255
   */
  name?: string
  /**
   * Description
   * The description of the item
   * @maxLength 512
   */
  description?: string
  /**
   * Price
   * The price of the item
   */
  price?: number
  /**
   * Price Point
   * The price point for local prices
   * @exclusiveMin 0
   * @max 5000000
   */
  price_point?: number
  /**
   * Reward Points Price
   * The reward points price for local prices
   */
  reward_points_price?: number
  /**
   * Discount Percent
   * The discount percent for the item
   * @exclusiveMin 0
   * @max 100
   */
  discount_percent?: number
  /**
   * Bonus Percent
   * The bonus percent for the item
   * @exclusiveMin 0
   * @max 1000
   */
  bonus_percent?: number
  /** The currency of the item */
  currency?: Currency
  /**
   * Sku
   * The SKU of the item
   * @maxLength 100
   */
  sku?: string
  /**
   * Image Url
   * The URL of the image for the item
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
  /**
   * Image Url Featured
   * The URL of the image for the item in the featured card
   */
  image_url_featured?: string | ''
  /** The type of the item */
  type?: ItemType
  /**
   * Quantity
   * The quantity of the item
   * @min 1
   */
  quantity?: number
  /**
   * Is Stackable
   * Whether the item is stackable
   */
  is_stackable?: boolean
  /**
   * Is Currency
   * Whether the item is a currency.
   */
  is_currency?: boolean
  /**
   * Archived At
   * The timestamp of when the item was archived
   */
  archived_at?: number
  /**
   * Position
   * Position of the item in list
   */
  position?: number
  /**
   * Categories
   * The categories list
   */
  categories?: string[]
}

/** LatestNewsBlock */
export interface LatestNewsBlock {
  /**
   * Block
   * @default "LatestNewsBlock"
   */
  block?: 'LatestNewsBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
}

/** LeadCreateOrUpdate */
export interface LeadCreateOrUpdate {
  /** Full Name */
  full_name?: string
  /** Know About Us */
  know_about_us?: string
  /** Goal */
  goal?: string
  /** Goal Question */
  goal_question?: string
  /** Website Url */
  website_url?: string
  /** Cell Number */
  cell_number?: string
}

/** LeadRead */
export interface LeadRead {
  /** Full Name */
  full_name?: string
  /** Know About Us */
  know_about_us?: string
  /** Goal */
  goal?: string
  /** Goal Question */
  goal_question?: string
  /** Website Url */
  website_url?: string
  /** Cell Number */
  cell_number?: string
  /** Customer Id */
  customer_id: string
  /** Completed */
  completed: boolean
}

/** LeaderboardCardBlock */
export interface LeaderboardCardBlock {
  /**
   * Block
   * @default "LeaderboardCardBlock"
   */
  block?: 'LeaderboardCardBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
  /** Leaderboard Id */
  leaderboard_id?: string
}

/** LeaderboardCreate */
export interface LeaderboardCreate {
  /**
   * Name
   * The name of the leaderboard.
   * @maxLength 255
   */
  name: string
  /**
   * Description
   * The description of the leaderboard.
   * @maxLength 512
   */
  description: string
}

/** LeaderboardFullBlock */
export interface LeaderboardFullBlock {
  /**
   * Block
   * @default "LeaderboardFullBlock"
   */
  block?: 'LeaderboardFullBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
  /** Leaderboard Id */
  leaderboard_id?: string
}

/** LeaderboardMemberRead */
export interface LeaderboardMemberRead {
  /**
   * Member Id
   * The unique identifier of the member.
   */
  member_id: string
  /**
   * Member Data
   * The data of the member. This might be a members's name.
   */
  member_data: string
  /**
   * Member Subdata
   * The additional subdata related to the member, such as a clan, team or guild they belong to.
   */
  member_subdata?: string
  /**
   * Image Url
   * The image URL of the member.
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url: string
  /**
   * Rank
   * The rank of the member.
   */
  rank: number
  /**
   * Score
   * The score of the member.
   */
  score: number
  /**
   * Id
   * The unique identifier of the leaderboard member.
   */
  id: string
  /**
   * Created At
   * The unix timestamp of when the leaderboard member was created.
   */
  created_at: number
  /**
   * Modified At
   * The unix timestamp of when the leaderboard member was last modified.
   */
  modified_at?: number
}

/** LeaderboardMemberUpdate */
export interface LeaderboardMemberUpdate {
  /**
   * Member Id
   * The unique identifier of the member.
   */
  member_id?: string
  /**
   * Member Data
   * The data of the member.
   */
  member_data?: string
  /**
   * Image Url
   * The image URL of the member.
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
  /**
   * Score
   * The score of the member.
   */
  score?: number
}

/** LeaderboardRead */
export interface LeaderboardRead {
  /**
   * Name
   * The name of the leaderboard.
   * @maxLength 255
   */
  name: string
  /**
   * Description
   * The description of the leaderboard.
   * @maxLength 512
   */
  description: string
  /**
   * Id
   * The unique identifier of the leaderboard.
   */
  id: string
  /**
   * Ranked At
   * The unix timestamp of when the leaderboard was last ranked.
   */
  ranked_at?: number
}

/** LeaderboardTranslate */
export interface LeaderboardTranslate {
  /**
   * Name
   * The name of the leaderboard.
   * @maxLength 255
   */
  name?: string
  /**
   * Description
   * The description of the leaderboard.
   * @maxLength 512
   */
  description?: string
}

/** LeaderboardUpdate */
export interface LeaderboardUpdate {
  /**
   * Name
   * The name of the leaderboard.
   * @maxLength 255
   */
  name?: string
  /**
   * Description
   * The description of the leaderboard.
   * @maxLength 512
   */
  description?: string
}

/**
 * Locale
 * Language codes according to ISO 639-1.
 */
export enum Locale {
  En = 'en',
  Fr = 'fr',
  It = 'it',
  De = 'de',
  Es = 'es',
  Pt = 'pt',
  Zh = 'zh',
  Ja = 'ja',
  Ko = 'ko',
  Cs = 'cs',
  Hi = 'hi',
  Id = 'id',
  Pl = 'pl',
  Ru = 'ru',
  Tr = 'tr',
  Uk = 'uk',
  Vi = 'vi',
  Th = 'th',
}

/** LocaleCreate */
export interface LocaleCreate {
  /** Language codes according to ISO 639-1. */
  locale: Locale
  /** Published At */
  published_at?: number
}

/** LocaleRead */
export interface LocaleRead {
  /** Language codes according to ISO 639-1. */
  locale: Locale
  /** Published At */
  published_at?: number
  /** Id */
  id: string
  /**
   * Represents an object in a nested context whose parent can be tracked.
   *
   * The top object in the parent link should be an instance of `Mutable`.
   */
  stats: LocaleStats
  /** An enumeration. */
  status: LocaleStatus
  /** Modified At */
  modified_at?: number
}

/**
 * LocaleStats
 * Represents an object in a nested context whose parent can be tracked.
 *
 * The top object in the parent link should be an instance of `Mutable`.
 */
export interface LocaleStats {
  /**
   * Total
   * @default 0
   */
  total?: number
  /**
   * Done
   * @default 0
   */
  done?: number
}

/**
 * LocaleStatus
 * An enumeration.
 */
export enum LocaleStatus {
  Clean = 'clean',
  Translating = 'translating',
  Synchronization = 'synchronization',
  Fuzzy = 'fuzzy',
}

/** LocaleUpdate */
export type LocaleUpdate = object

/** LoginSettings */
export interface LoginSettings {
  /** User Id Instruction Text */
  user_id_instruction_text?: string
  /** User Id Instruction Image */
  user_id_instruction_image?: string
  /** Background Image */
  background_image?: string
  /** Welcome Image */
  welcome_image?: string
  /** Age Of Consent */
  age_of_consent?: number
  /** @default "lower" */
  user_id_normalization_method?: NormalizationMethod
}

/**
 * LoginType
 * An enumeration.
 */
export enum LoginType {
  Auth0 = 'auth0',
  UserId = 'user_id',
}

/** MeasureValue */
export interface MeasureValue {
  /** Value */
  value: number
  /** Prev Value */
  prev_value?: number
}

/** MobilePopupActionNode */
export interface MobilePopupActionNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "MobilePopupActionNode"
   */
  model_type?: 'MobilePopupActionNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Body */
  body?: string
  /** Item Id */
  item_id?: string
  /** Link Created Offer */
  link_created_offer?: boolean
  /** Store Item Id */
  store_item_id?: string
  /** Store Id */
  store_id?: string
  /** Url */
  url?: string
  /** Response */
  response?: string
  /** Status Code */
  status_code?: number
  /** Image Url */
  image_url?: string
}

/** MobilePushActionNode */
export interface MobilePushActionNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "MobilePushActionNode"
   */
  model_type?: 'MobilePushActionNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Body */
  body?: string
  /** Item Id */
  item_id?: string
  /** Link Created Offer */
  link_created_offer?: boolean
  /** Store Item Id */
  store_item_id?: string
  /** Store Id */
  store_id?: string
  /** Url */
  url?: string
  /** Response */
  response?: string
  /** Status Code */
  status_code?: number
  /** Image Url */
  image_url?: string
}

/**
 * Mode
 * An enumeration.
 */
export enum Mode {
  Dark = 'dark',
  Light = 'light',
  Auto = 'auto',
}

/** NewsBannerConfig */
export interface NewsBannerConfig {
  /** Title */
  title?: string
  /** Description */
  description?: string
  /** Image Url */
  image_url?: string
  /** Background Image Size */
  background_image_size?: string
  /** Featured News Id */
  featured_news_id?: string
  /** Featured Item Id */
  featured_item_id?: string
}

/** NewsBulkDelete */
export interface NewsBulkDelete {
  /** Id */
  id: string
}

/** NewsBulkUpdate */
export interface NewsBulkUpdate {
  /**
   * Slug
   * The slug of the news
   * @maxLength 100
   */
  slug?: string
  /**
   * Title
   * The title of the news
   * @maxLength 255
   */
  title?: string
  /**
   * Description
   * The description of the news
   * @maxLength 512
   */
  description?: string
  /**
   * Text
   * The text of the news
   * @maxLength 10000
   */
  text?: string
  /**
   * Html
   * The html content of the news
   * @maxLength 100000
   */
  html?: string
  /**
   * Date
   * The date of the news
   * @format date
   */
  date?: string
  /**
   * Image Url
   * The URL of the image for the news
   */
  image_url?: string | ''
  /**
   * Tags
   * The tags of the news, separated by commas
   */
  tags?: string
  /**
   * Order Id
   * The order ID of the news
   * @default 0
   */
  order_id?: number
  /** The category of the news */
  category?: NewsCategory
  /**
   * Published At
   * The published date of the news
   */
  published_at?: number
  /**
   * Id
   * The unique identifier of the news
   */
  id: string
}

/**
 * NewsCategory
 * An enumeration.
 */
export enum NewsCategory {
  Bugfix = 'bugfix',
  CompanyNews = 'company_news',
  Event = 'event',
  Maintenance = 'maintenance',
  Update = 'update',
}

/** NewsCreate */
export interface NewsCreate {
  /**
   * Slug
   * The slug of the news
   * @maxLength 100
   */
  slug: string
  /**
   * Title
   * The title of the news
   * @maxLength 255
   */
  title: string
  /**
   * Description
   * The description of the news
   * @maxLength 512
   */
  description: string
  /**
   * Text
   * The text of the news
   * @maxLength 10000
   */
  text: string
  /**
   * Html
   * The html content of the news
   * @maxLength 100000
   */
  html?: string
  /**
   * Date
   * The date of the news
   * @format date
   */
  date: string
  /**
   * Image Url
   * The URL of the image for the news
   */
  image_url: string | ''
  /**
   * Tags
   * The tags of the news, separated by commas
   */
  tags: string
  /**
   * Order Id
   * The order ID of the news
   * @default 0
   */
  order_id?: number
  /** The category of the news */
  category: NewsCategory
  /**
   * Published At
   * The published date of the news
   */
  published_at?: number
}

/**
 * NewsGroup
 * An enumeration.
 */
export enum NewsGroup {
  Event = 'event',
  Update = 'update',
  Other = 'other',
}

/** NewsRead */
export interface NewsRead {
  /**
   * Slug
   * The slug of the news
   * @maxLength 100
   */
  slug: string
  /**
   * Title
   * The title of the news
   * @maxLength 255
   */
  title: string
  /**
   * Description
   * The description of the news
   * @maxLength 512
   */
  description: string
  /**
   * Text
   * The text of the news
   * @maxLength 10000
   */
  text: string
  /**
   * Html
   * The html content of the news
   * @maxLength 100000
   */
  html?: string
  /**
   * Date
   * The date of the news
   * @format date
   */
  date: string
  /**
   * Image Url
   * The URL of the image for the news
   */
  image_url: string | ''
  /**
   * Tags
   * The tags of the news, separated by commas
   */
  tags: string
  /**
   * Order Id
   * The order ID of the news
   * @default 0
   */
  order_id?: number
  /** The category of the news */
  category: NewsCategory
  /**
   * Published At
   * The published date of the news
   */
  published_at?: number
  /**
   * Id
   * The unique identifier of the news
   */
  id: string
}

/** NewsSettings */
export interface NewsSettings {
  /** Default Image Urls */
  default_image_urls?: Record<string, string>
  /** Image Ratio */
  image_ratio?: number
}

/**
 * NewsSortField
 * An enumeration.
 */
export enum NewsSortField {
  Slug = 'slug',
  Title = 'title',
  Description = 'description',
  Date = 'date',
  Tags = 'tags',
  Category = 'category',
}

/** NewsTranslate */
export interface NewsTranslate {
  /**
   * Title
   * The title of the news
   * @maxLength 255
   */
  title?: string
  /**
   * Description
   * The description of the news
   * @maxLength 512
   */
  description?: string
  /**
   * Text
   * The text of the news
   * @maxLength 10000
   */
  text?: string
}

/** NewsUpdate */
export interface NewsUpdate {
  /**
   * Slug
   * The slug of the news
   * @maxLength 100
   */
  slug?: string
  /**
   * Title
   * The title of the news
   * @maxLength 255
   */
  title?: string
  /**
   * Description
   * The description of the news
   * @maxLength 512
   */
  description?: string
  /**
   * Text
   * The text of the news
   * @maxLength 10000
   */
  text?: string
  /**
   * Html
   * The html content of the news
   * @maxLength 100000
   */
  html?: string
  /**
   * Date
   * The date of the news
   * @format date
   */
  date?: string
  /**
   * Image Url
   * The URL of the image for the news
   */
  image_url?: string | ''
  /**
   * Tags
   * The tags of the news, separated by commas
   */
  tags?: string
  /**
   * Order Id
   * The order ID of the news
   * @default 0
   */
  order_id?: number
  /** The category of the news */
  category?: NewsCategory
  /**
   * Published At
   * The published date of the news
   */
  published_at?: number
}

/** Node */
export type Node =
  | ({
      model_type: 'EmailActionNode'
    } & EmailActionNode)
  | ({
      model_type: 'MobilePushActionNode'
    } & MobilePushActionNode)
  | ({
      model_type: 'MobilePopupActionNode'
    } & MobilePopupActionNode)
  | ({
      model_type: 'TimerNode'
    } & TimerNode)
  | ({
      model_type: 'ConditionNode'
    } & ConditionNode)
  | ({
      model_type: 'BrowserPushNotificationActionNode'
    } & BrowserPushNotificationActionNode)
  | ({
      model_type: 'BrowserPopupNotificationActionNode'
    } & BrowserPopupNotificationActionNode)
  | ({
      model_type: 'WebhookActionNode'
    } & WebhookActionNode)
  | ({
      model_type: 'ItemDiscountOfferActionNode'
    } & ItemDiscountOfferActionNode)
  | ({
      model_type: 'ItemBonusOfferActionNode'
    } & ItemBonusOfferActionNode)
  | ({
      model_type: 'SplitNode'
    } & SplitNode)
  | ({
      model_type: 'CreateItemBannerActionNode'
    } & CreateItemBannerActionNode)
  | ({
      model_type: 'AddToSegmentNode'
    } & AddToSegmentNode)
  | ({
      model_type: 'CreateCouponNode'
    } & CreateCouponNode)
  | ({
      model_type: 'AddStoreNode'
    } & AddStoreNode)
  | ({
      model_type: 'RemoveItemsStoreNode'
    } & RemoveItemsStoreNode)
  | ({
      model_type: 'AddStoreItemsNode'
    } & AddStoreItemsNode)
  | ({
      model_type: 'ItemOfferActionNode'
    } & ItemOfferActionNode)
  | ({
      model_type: 'SortStoreItemsNode'
    } & SortStoreItemsNode)
  | ({
      model_type: 'CreateVirtualSKUNode'
    } & CreateVirtualSKUNode)

/**
 * NormalizationMethod
 * An enumeration.
 */
export enum NormalizationMethod {
  Lower = 'lower',
  Upper = 'upper',
}

/**
 * ObjectType
 * An enumeration.
 */
export enum ObjectType {
  Achievement = 'Achievement',
  News = 'News',
  Item = 'Item',
  Leaderboard = 'Leaderboard',
  Page = 'Page',
  Store = 'Store',
  ItemCategory = 'ItemCategory',
  GameSettings = 'GameSettings',
  ItemProperty = 'ItemProperty',
  StoreItem = 'StoreItem',
}

/** Offer */
export interface Offer {
  /** Description */
  description: string
  /** An enumeration. */
  type: OfferType
  /** Data */
  data: ItemDiscountOffer | ItemBonusOffer
}

/** OfferBulkDelete */
export interface OfferBulkDelete {
  /** Id */
  id: string
}

/** OfferRead */
export interface OfferRead {
  /** Id */
  id: string
  /** Description */
  description: string
  /** An enumeration. */
  type: OfferType
  data: ItemGeneralOffer
  /** Expires At */
  expires_at?: number
  /** Created At */
  created_at?: number
}

/** OfferStat */
export interface OfferStat {
  /** All Count */
  all_count: number
  /** Active Count */
  active_count: number
  /** Purchase Count */
  purchase_count: number
}

/**
 * OfferType
 * An enumeration.
 */
export enum OfferType {
  ItemDiscount = 'item_discount',
  ItemBonus = 'item_bonus',
  General = 'general',
}

/** OfferUserRead */
export interface OfferUserRead {
  /** Id */
  id: string
  /** Player Id */
  player_id: string
  /** Player Name */
  player_name?: string
  /** User Id */
  user_id: string
  /** Item Id */
  item_id: string
  /** Item Sku */
  item_sku: string
  /** Item Name */
  item_name?: any
  /** Item Image Url */
  item_image_url: string
  /** Item Quantity */
  item_quantity?: number
  /** Item Is Stackable */
  item_is_stackable: boolean
  /** Data */
  data: object
  /** Expires At */
  expires_at?: number
  /** Redeemed At */
  redeemed_at?: number
  /** Created At */
  created_at: number
}

/** OnBoardingRequest */
export interface OnBoardingRequest {
  /** Url */
  url: string
  /** Company Id */
  company_id?: string
}

/** OnBoardingResponse */
export interface OnBoardingResponse {
  company: Company
  game: AghanimGameSchemasGame
  website: Website
}

/** OnboardingProgress */
export interface OnboardingProgress {
  /** Visit Game Hub */
  visit_game_hub: boolean
  /** Customize Your Store */
  customize_your_store: boolean
  /** Setup Item Store */
  setup_item_store: boolean
  /** Test Webhook */
  test_webhook: boolean
  /** Account Verified */
  account_verified: boolean
  /** Liveops Published */
  liveops_published: boolean
  /** Setup Webhook */
  setup_webhook: boolean
}

/** OrderGameSettings */
export interface OrderGameSettings {
  /** Purchase Successful Text */
  purchase_successful_text?: string
  /** Receipt Appreciation Text */
  receipt_appreciation_text?: string
  /**
   * Back To Game Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  back_to_game_url?: string
}

/** OrderRead */
export interface OrderRead {
  /** Id */
  id: string
  game: AghanimOrderSchemasGame
  /** Game Id */
  game_id: string
  /** User Id */
  user_id: string
  /** Player Id */
  player_id: string
  /** Language codes according to ISO 639-1. */
  locale?: Locale
  /**
   * Country
   * @pattern ^[A-Z]{2}$
   */
  country: string
  /** Items */
  items: PersonalItem[]
  global_discount?: GlobalDiscount
  global_bonus?: GlobalBonus
  /** Discounts */
  discounts: Discount[]
  /** Bonuses */
  bonuses: Bonus[]
  /** Offers */
  offers: Offer[]
  /** Benefits */
  benefits: Benefit[]
  /** Amount */
  amount: number
  /** An enumeration. */
  currency: Currency
  /** An enumeration. */
  status: OrderStatus
  /** Subtotal */
  subtotal: number
  /** Total */
  total: number
  /**
   * Return Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  return_url: string
  /** Created At */
  created_at: number
  /** Modified At */
  modified_at?: number
  /** Datahouse User Id */
  datahouse_user_id?: string
  game_settings: OrderGameSettings
  website_config: AghanimOrderSchemasWebsiteConfig
  /** Ip Address */
  ip_address?: string
  /** User Agent */
  user_agent?: string
  /** Store Item Id */
  store_item_id?: string
  /** Price Minor Unit */
  price_minor_unit: number
  /** Paylink User Id */
  paylink_user_id?: string
  /** Eligible For Reward Points */
  eligible_for_reward_points?: number
  /** Sandbox */
  sandbox: boolean
}

/**
 * OrderStatus
 * An enumeration.
 */
export enum OrderStatus {
  Created = 'created',
  Captured = 'captured',
  Paid = 'paid',
  Delivered = 'delivered',
  Canceled = 'canceled',
  Refunded = 'refunded',
  RefundRequested = 'refund_requested',
  Reattempted = 'reattempted',
}

/** OutdatedAgreements */
export interface OutdatedAgreements {
  /** Terms */
  terms: boolean
  /** Privacy */
  privacy: boolean
}

/** PageBase */
export interface PageBase {
  /** Title */
  title: string
  /** Text */
  text: string
  /** Description */
  description: string
  /** Slug */
  slug: string
  /** Layout */
  layout?: any
  /** Html */
  html?: string
  /** An enumeration. */
  type?: PageLayoutType
  /** Parent Id */
  parent_id?: string
  /**
   * Date
   * The date of the page
   * @format date
   */
  date?: string
  /**
   * Image Url
   * The URL of the image for the page
   */
  image_url?: string | ''
  /**
   * Published At
   * The published date of the Page
   */
  published_at?: number
}

/** PageBulkDelete */
export interface PageBulkDelete {
  /** Id */
  id: string
}

/** PageBulkUpdate */
export interface PageBulkUpdate {
  /** Title */
  title?: string
  /** Description */
  description?: string
  /** Text */
  text?: string
  /** Slug */
  slug?: string
  layout?: PageLayout
  /** Html */
  html?: string
  /** An enumeration. */
  type?: PageLayoutType
  /** Parent Id */
  parent_id?: string
  /**
   * Date
   * The date of the page
   * @format date
   */
  date?: string
  /**
   * Image Url
   * The URL of the image for the page
   */
  image_url?: string | ''
  /**
   * Published At
   * The published date of the Page
   */
  published_at?: number
  /**
   * Id
   * The unique identifier of the page
   */
  id: string
}

/** PageCreate */
export interface PageCreate {
  /** Title */
  title: string
  /** Text */
  text: string
  /** Description */
  description: string
  /** Slug */
  slug: string
  /** Layout */
  layout?: any
  /** Html */
  html?: string
  /** An enumeration. */
  type?: PageLayoutType
  /** Parent Id */
  parent_id?: string
  /**
   * Date
   * The date of the page
   * @format date
   */
  date?: string
  /**
   * Image Url
   * The URL of the image for the page
   */
  image_url?: string | ''
  /**
   * Published At
   * The published date of the Page
   */
  published_at?: number
}

/** PageLayout */
export interface PageLayout {
  /** Blocks */
  blocks?: Block[][]
}

/**
 * PageLayoutType
 * An enumeration.
 */
export enum PageLayoutType {
  Content = 'content',
  Article = 'article',
  Collection = 'collection',
  Blocks = 'blocks',
}

/** PageRead */
export interface PageRead {
  /** Title */
  title: string
  /** Text */
  text: string
  /** Description */
  description: string
  /** Slug */
  slug: string
  /** Layout */
  layout?: any
  /** Html */
  html?: string
  /** An enumeration. */
  type?: PageLayoutType
  /** Parent Id */
  parent_id?: string
  /**
   * Date
   * The date of the page
   * @format date
   */
  date?: string
  /**
   * Image Url
   * The URL of the image for the page
   */
  image_url?: string | ''
  /**
   * Published At
   * The published date of the Page
   */
  published_at?: number
  /** Id */
  id: string
  parent?: PageBase
}

/** PageTranslate */
export interface PageTranslate {
  /** Title */
  title?: string
  /** Description */
  description?: string
  /** Text */
  text?: string
}

/** PageUpdate */
export interface PageUpdate {
  /** Title */
  title?: string
  /** Description */
  description?: string
  /** Text */
  text?: string
  /** Slug */
  slug?: string
  layout?: PageLayout
  /** Html */
  html?: string
  /** An enumeration. */
  type?: PageLayoutType
  /** Parent Id */
  parent_id?: string
  /**
   * Date
   * The date of the page
   * @format date
   */
  date?: string
  /**
   * Image Url
   * The URL of the image for the page
   */
  image_url?: string | ''
  /**
   * Published At
   * The published date of the Page
   */
  published_at?: number
}

/** PaymentMethod */
export interface PaymentMethod {
  /** Id */
  id: string
  /** Name */
  name: string
  /** Caption */
  caption?: string
  /**
   * Logo Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  logo_url?: string
  /** Fee */
  fee?: PaymentMethodFee[]
  settings: PaymentMethodSettings
  /** Supported Countries */
  supported_countries: string[]
  /** Dashboard Show */
  dashboard_show?: boolean
}

/** PaymentMethodFee */
export interface PaymentMethodFee {
  /** Country Code */
  country_code: string
  /** Currency */
  currency: string
  /** Fee Fix */
  fee_fix: number
  /** Fee Prc */
  fee_prc: number
}

/** PaymentMethodSettings */
export interface PaymentMethodSettings {
  /** Enabled */
  enabled: boolean
  /** Global Enabled */
  global_enabled?: boolean
}

/** PaymentRead */
export interface PaymentRead {
  /** Company Id */
  company_id?: string
  /** Game Id */
  game_id?: string
  /** User Id */
  user_id?: string
  /** Player Id */
  player_id?: string
  /**
   * Payment Date
   * @format date-time
   */
  payment_date?: string
  /** Player Name */
  player_name?: string
  /** Item Id */
  item_id?: string
  /** Item Name */
  item_name?: string
  /** Datahouse User Id */
  datahouse_user_id?: string
  /** Payment Method Id */
  payment_method_id?: string
  /** Payment Method Name */
  payment_method_name?: string
  /** Status */
  status?: string
  /** Amount */
  amount?: number
  /** Currency */
  currency?: string
  /** Country */
  country?: string
  /** Payment Id */
  payment_id?: string
  /** Ps Tx Id */
  ps_tx_id?: string
  /** Payment Number */
  payment_number?: string
  /** Paylink User Id */
  paylink_user_id?: string
  /** Order Id */
  order_id?: string
  /** Card Last 4 Digits */
  card_last_4_digits?: string
  /** Card Bin */
  card_bin?: string
  /** Card Scheme */
  card_scheme?: string
  /** Total */
  total?: number
  /** Total Order Currency Billing */
  total_order_currency_billing?: number
  /** Total Usd */
  total_usd?: number
  /** Total Usd Revenue */
  total_usd_revenue?: number
  /** User Local Price */
  user_local_price?: number
  /** Currency Minor Unit */
  currency_minor_unit?: number
  /** Fees */
  fees?: string
  /** Taxes */
  taxes?: string
  /** User Name */
  user_name?: string
  /** Refund Customer Id */
  refund_customer_id?: string
  /** Refund Reason */
  refund_reason?: string
  /** Ip */
  ip?: any
  /** Created At */
  created_at?: number
  /** Billing Email */
  billing_email?: string
  /** Fail Reason */
  fail_reason?: string
  /** Fail Reason Code */
  fail_reason_code?: string
  /** Campaign Id */
  campaign_id?: string
  /** User Campaign Id */
  user_campaign_id?: string
  /** User Billing Address */
  user_billing_address?: string
}

/** PaymentSuccessFail */
export interface PaymentSuccessFail {
  /** Date */
  date: string
  /** Success Payment */
  success_payment?: number
  /** Failed Payment */
  failed_payment?: number
}

/** Payout */
export interface Payout {
  /** Agreement Id */
  agreement_id: string
  /** Currency Code */
  currency_code: string
  /** Ending Balance */
  ending_balance: number
  /** Ending Date */
  ending_date: number
  /** Start Date */
  start_date: number
  /** Payout Amount */
  payout_amount: number
  /** Payout Status */
  payout_status: string
  /** Payout Type */
  payout_type: string
}

/** PayoutMonth */
export interface PayoutMonth {
  /** Ending Date */
  ending_date: number
  /** Total */
  total: number
}

/**
 * PeriodType
 * An enumeration.
 */
export enum PeriodType {
  Week = 'week',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second',
}

/** PersistentCampaignAttributes */
export interface PersistentCampaignAttributes {
  /** Custom Attributes */
  custom_attributes?: CustomAttribute[]
  /** Abandoned Cart Duration */
  abandoned_cart_duration?: number
  /** Visit Duration */
  visit_duration?: number
}

/**
 * PersistentPlayerAttributes
 * Represents an object in a nested context whose parent can be tracked.
 *
 * The top object in the parent link should be an instance of `Mutable`.
 */
export interface PersistentPlayerAttributes {
  /**
   * Level
   * @default 0
   */
  level?: number
  /**
   * Saved Ach
   * @default false
   */
  saved_ach?: boolean
  /**
   * Saved Card
   * @default false
   */
  saved_card?: boolean
  /**
   * Soft Currency Amount
   * @default 0
   */
  soft_currency_amount?: number
  /**
   * Hard Currency Amount
   * @default 0
   */
  hard_currency_amount?: number
  /** Appsflyer Id */
  appsflyer_id?: string
  /** Appsflyer App Id */
  appsflyer_app_id?: string
  /** Appsflyer Meta */
  appsflyer_meta?: object
}

/** PersonalCompany */
export interface PersonalCompany {
  /**
   * Name
   * Company name
   * @maxLength 254
   */
  name: string
  /**
   * Size
   * Number of employees
   * @min 1
   */
  size: number
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string
  /** Domains */
  domains?: string[]
  /** Viewer Domains */
  viewer_domains?: string[]
  /**
   * Logo Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  logo_url?: string
  /** Id */
  id: string
  /** An enumeration. */
  role: Role
}

/**
 * PersonalItem
 * An item with additional fields that are specific to the user.
 *
 * This item crafted from database item with some adjustments:
 * 1. price and currency updated based on the user's country and price point
 * 2. price after discount is calculated based on the user's offers and coupons
 */
export interface PersonalItem {
  /**
   * Name
   * The name of the item
   * @maxLength 255
   */
  name: string
  /**
   * Description
   * The description of the item
   * @maxLength 512
   */
  description: string
  /**
   * Price
   * The price of the item
   */
  price: number
  /**
   * Price Point
   * The price point for local prices
   * @exclusiveMin 0
   * @max 5000000
   */
  price_point?: number
  /**
   * Reward Points Price
   * The reward points price for local prices
   */
  reward_points_price?: number
  /**
   * Discount Percent
   * The discount percent for the item
   * @min 0
   * @max 100
   */
  discount_percent?: number
  /**
   * Bonus Percent
   * The bonus percent for the item
   * @min 0
   * @max 1000
   */
  bonus_percent?: number
  /** The currency of the item */
  currency: Currency
  /**
   * Sku
   * The SKU of the item
   * @maxLength 100
   */
  sku: string
  /**
   * Image Url
   * The URL of the image for the item
   */
  image_url: string | ''
  /**
   * Image Url Featured
   * The URL of the image for the item in the featured card
   */
  image_url_featured?: string | ''
  /** The type of the item */
  type: ItemType
  /**
   * Quantity
   * The quantity of the item
   * @min 1
   * @default 1
   */
  quantity?: number
  /**
   * Is Stackable
   * Whether the item is stackable
   */
  is_stackable: boolean
  /**
   * Is Currency
   * Whether the item is a currency.
   */
  is_currency: boolean
  /**
   * Position
   * Position of the item in list
   */
  position?: number
  /**
   * Categories
   * The categories list
   */
  categories?: string[]
  /**
   * Id
   * The unique identifier of the item
   */
  id: string
  /**
   * Properties
   * The item properties
   */
  properties: ItemPropertyRelationRead[]
  /** Price After Discount */
  price_after_discount: number
  /** Quantity After Bonus */
  quantity_after_bonus: number
  /** Quantity Bonus Value */
  quantity_bonus_value?: number
  offer?: OfferRead
  /** Store Item Id */
  store_item_id?: string
  /** Price Minor Unit */
  price_minor_unit: number
  /** Custom Badge */
  custom_badge?: string
  /** Expires At */
  expires_at?: number
  /** Eligible For Reward Points */
  eligible_for_reward_points?: number
  /**
   * Is Free Item
   * @default false
   */
  is_free_item?: boolean
}

/** PointSeriesValue */
export interface PointSeriesValue {
  /** Category */
  category: string
  /** Values */
  values: Record<string, number>
}

/** PointValue */
export interface PointValue {
  /** Category */
  category: string
  /** Value */
  value?: number
}

/** Price */
export interface Price {
  /**
   * Price Point
   * @exclusiveMin 0
   * @max 5000000
   */
  price_point: number
  /** An enumeration. */
  country: Country
  /** An enumeration. */
  currency: Currency
  /**
   * Price
   * @exclusiveMin 0
   * @max 100000000000
   */
  price: number
  /** Item Id */
  item_id?: string
  /** Id */
  id: string
}

/** PriceCreate */
export interface PriceCreate {
  /**
   * Price Point
   * @exclusiveMin 0
   * @max 5000000
   */
  price_point: number
  /** An enumeration. */
  country: Country
  /** An enumeration. */
  currency: Currency
  /**
   * Price
   * @exclusiveMin 0
   * @max 100000000000
   */
  price: number
  /** Item Id */
  item_id: string
}

/** ProfileUpdate */
export interface ProfileUpdate {
  /** Name */
  name?: string
  /**
   * Avatar Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  avatar_url?: string
  /** An enumeration. */
  locale?: DashboardLocale
}

/** Rect */
export interface Rect {
  /** Left */
  left: number
  /** Top */
  top: number
  /** Width */
  width: number
  /** Height */
  height: number
}

/** RedeemCouponBlock */
export interface RedeemCouponBlock {
  /**
   * Block
   * @default "RedeemCouponBlock"
   */
  block?: 'RedeemCouponBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
}

/** RefundRequest */
export interface RefundRequest {
  /** Reason */
  reason: string
  /** Order Id */
  order_id: string
}

/** RemoveItemsStoreNode */
export interface RemoveItemsStoreNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "RemoveItemsStoreNode"
   */
  model_type?: 'RemoveItemsStoreNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Item Id */
  item_id: string
}

/** ResendResult */
export interface ResendResult {
  /** Id */
  id: string
}

/**
 * ResourceState
 * An enumeration.
 */
export enum ResourceState {
  Active = 'active',
  Archived = 'archived',
}

/** RevenueByDate */
export interface RevenueByDate {
  /** Date */
  date: string
  /** Revenue */
  revenue: number
  /** Prev Revenue */
  prev_revenue?: number
}

/** RewardPointsSettings */
export interface RewardPointsSettings {
  /** Reward Points Rate */
  reward_points_rate?: number
}

/**
 * Role
 * An enumeration.
 */
export enum Role {
  Admin = 'admin',
  Editor = 'editor',
  User = 'user',
  Viewer = 'viewer',
}

/** RotationSettings */
export interface RotationSettings {
  /**
   * Count
   * @exclusiveMin 0
   */
  count: number
  /**
   * Period Value
   * @exclusiveMin 0
   */
  period_value: number
  /** An enumeration. */
  period_type: PeriodType
  /** An enumeration. */
  type: RotationType
}

/**
 * RotationType
 * An enumeration.
 */
export enum RotationType {
  Random = 'random',
  RandomByWeight = 'random_by_weight',
  RoundRobin = 'round_robin',
}

/** SankeyLinkData */
export interface SankeyLinkData {
  /** From Name */
  from_name: string
  /** To Name */
  to_name: string
  /** Value */
  value: number
}

/** SankeyTopSkuData */
export interface SankeyTopSkuData {
  /** Event To Message */
  event_to_message: SankeyLinkData[]
  /** Message To Sku */
  message_to_sku: SankeyLinkData[]
}

/** ScheduleJobRequest */
export interface ScheduleJobRequest {
  /** Locale Id */
  locale_id: string
}

/** ScheduleJobResponse */
export interface ScheduleJobResponse {
  /** Job Id */
  job_id: string
}

/** Screenshot */
export interface Screenshot {
  /** Src */
  src?: string
  /** Type */
  type?: string
  /** Sizes */
  sizes?: string
  /** @default "wide" */
  form_factor?: ScreenshotFormFactor
}

/**
 * ScreenshotFormFactor
 * An enumeration.
 */
export enum ScreenshotFormFactor {
  Wide = 'wide',
  Narrow = 'narrow',
}

/**
 * SectionObjectType
 * An enumeration.
 */
export enum SectionObjectType {
  Page = 'page',
}

/**
 * SectionType
 * An enumeration.
 */
export enum SectionType {
  Home = 'home',
  Store = 'store',
  RedeemCode = 'redeem_code',
  News = 'news',
  Updates = 'updates',
  Events = 'events',
  Watch = 'watch',
  Play = 'play',
  Leaderboard = 'leaderboard',
  Achievement = 'achievement',
  Chat = 'chat',
  Forum = 'forum',
  About = 'about',
  Merch = 'merch',
  Page = 'page',
  Campaign = 'campaign',
}

/** SegmentCreate */
export interface SegmentCreate {
  /** Slug */
  slug: string
  /** Name */
  name: string
  /** Description */
  description?: string
  /** Color */
  color?: string
  /**
   * Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
  /** Segment Group Id */
  segment_group_id?: string
}

/** SegmentGroupCreate */
export interface SegmentGroupCreate {
  /** Name */
  name: string
  /** An enumeration. */
  type: SegmentGroupType
  /** Enabled */
  enabled: boolean
  /** Is Template */
  is_template: boolean
}

/** SegmentGroupRead */
export interface SegmentGroupRead {
  /** Name */
  name: string
  /** An enumeration. */
  type: SegmentGroupType
  /** Enabled */
  enabled: boolean
  /** Is Template */
  is_template: boolean
  /** Id */
  id: string
}

/** SegmentGroupReadStat */
export interface SegmentGroupReadStat {
  /** Name */
  name: string
  /** An enumeration. */
  type: SegmentGroupType
  /** Enabled */
  enabled: boolean
  /** Is Template */
  is_template: boolean
  /** Id */
  id: string
  /** Segment Count */
  segment_count: number
  progress?: SegmentationProgress
}

/**
 * SegmentGroupType
 * An enumeration.
 */
export enum SegmentGroupType {
  Static = 'static',
  Dynamic = 'dynamic',
}

/** SegmentGroupUpdate */
export interface SegmentGroupUpdate {
  /** Name */
  name?: string
  graph?: GraphRoot
  /** An enumeration. */
  type?: SegmentGroupType
  /** Enabled */
  enabled?: boolean
  /** Is Template */
  is_template?: boolean
}

/** SegmentRead */
export interface SegmentRead {
  /** Slug */
  slug: string
  /** Name */
  name: string
  /** Description */
  description?: string
  /** Color */
  color?: string
  /**
   * Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
  /** Segment Group Id */
  segment_group_id?: string
  /** Id */
  id: string
  segment_group?: SegmentGroupRead
}

/** SegmentUpdate */
export interface SegmentUpdate {
  /** Name */
  name?: string
  /** Description */
  description?: string
  /** Color */
  color?: string
  /**
   * Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
  /** Segment Group Id */
  segment_group_id?: string
  /** Slug */
  slug?: string
}

/** SegmentationProgress */
export interface SegmentationProgress {
  /** Total */
  total: number
  /**
   * Completed
   * @default 0
   */
  completed?: number
  /** Segment Group Id */
  segment_group_id: string
}

/** SeriesValue */
export interface SeriesValue {
  /** Category */
  category: string
  /** Value */
  value?: number
  /** Series Name */
  series_name?: string
  /** Prev Value */
  prev_value?: number
}

/** SocialMedia */
export interface SocialMedia {
  /** An enumeration. */
  name: SocialMediaName
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string
  /** Custom Name */
  custom_name?: string
  /** Custom Icon Url */
  custom_icon_url?: string
}

/**
 * SocialMediaName
 * An enumeration.
 */
export enum SocialMediaName {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Twitter = 'twitter',
  Discord = 'discord',
  Youtube = 'youtube',
  Tiktok = 'tiktok',
  Custom = 'custom',
  Telegram = 'telegram',
}

/**
 * SortOrder
 * An enumeration.
 */
export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

/** SortStoreItemsNode */
export interface SortStoreItemsNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "SortStoreItemsNode"
   */
  model_type?: 'SortStoreItemsNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** An enumeration. */
  order: SortOrder
  /** An enumeration. */
  sort_by: SortStoreType
}

/**
 * SortStoreType
 * An enumeration.
 */
export enum SortStoreType {
  Price = 'price',
  Name = 'name',
  Discount = 'discount',
  Position = 'position',
}

/** SplitInfo */
export interface SplitInfo {
  /**
   * Title
   * @default ""
   */
  title?: string
  /** Value */
  value: number
  /** Next Node Id */
  next_node_id?: string
}

/** SplitNode */
export interface SplitNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "SplitNode"
   */
  model_type?: 'SplitNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Distribution */
  distribution: SplitInfo[]
  /** Random Value */
  random_value?: number
  /** Value Next Node Id */
  value_next_node_id?: string
}

/**
 * State
 * An enumeration.
 */
export enum State {
  New = 'new',
  Fuzzy = 'fuzzy',
  Done = 'done',
}

/** StoreBlock */
export interface StoreBlock {
  /**
   * Block
   * @default "StoreBlock"
   */
  block?: 'StoreBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
}

/** StoreBulkDelete */
export interface StoreBulkDelete {
  /** Id */
  id: string
}

/**
 * StoreCardVersion
 * An enumeration.
 */
export enum StoreCardVersion {
  V1 = 'v1',
  V2 = 'v2',
}

/** StoreCreate */
export interface StoreCreate {
  /** Name */
  name: string
  /** Description */
  description: string
  /** An enumeration. */
  type: StoreType
  rotation_settings?: RotationSettings
}

/** StoreGraph */
export interface StoreGraph {
  /** Game Id */
  game_id: string
  /** Enabled */
  enabled: boolean
  /** Group By Categories */
  group_by_categories: boolean
}

/** StoreGraphPreview */
export interface StoreGraphPreview {
  graph: GraphRoot
  calc_attributes: CalcPlayerAttributes
  /** An enumeration. */
  country?: Country
  /**
   * Represents an object in a nested context whose parent can be tracked.
   *
   * The top object in the parent link should be an instance of `Mutable`.
   */
  attributes: PersistentPlayerAttributes
  /** User Id */
  user_id?: string
}

/** StoreGraphPreviewResult */
export interface StoreGraphPreviewResult {
  graph: GraphRoot
  /** Items */
  items: StoreItemRead[]
}

/** StoreGraphResponse */
export interface StoreGraphResponse {
  graph?: StoreGraph
}

/** StoreGraphUpdate */
export interface StoreGraphUpdate {
  graph?: GraphRoot
  /** Enabled */
  enabled?: boolean
  /** Group By Categories */
  group_by_categories?: boolean
}

/** StoreItemBase */
export interface StoreItemBase {
  /** Item Id */
  item_id: string
  /** Discount Percent */
  discount_percent?: number
  /** Bonus Percent */
  bonus_percent?: number
  /** Position */
  position: number
  /** Name */
  name: string
  /** Description */
  description: string
  /** Max Purchases */
  max_purchases?: number
  requirements_expression?: Expression
  /**
   * Custom Badge
   * The custom badge of the item
   * @maxLength 100
   */
  custom_badge?: string
  /** Campaign Id */
  campaign_id?: string
  /** User Campaign Id */
  user_campaign_id?: string
  /** Bond User Id */
  bond_user_id?: string
  /** Start At */
  start_at?: number
  /** End At */
  end_at?: number
  /** Min Amount */
  min_amount?: number
  /** Max Reward Points Percent */
  max_reward_points_percent?: number
  /** Reward Points Percent */
  reward_points_percent?: number
  payment_bound?: StoreItemPaymentSettings
  /**
   * Is Free Item
   * @default false
   */
  is_free_item?: boolean
  /**
   * Rotation Weight
   * @min 0
   * @max 1000
   */
  rotation_weight?: number
  /** An enumeration. */
  max_purchases_period_type?: PeriodType
  /**
   * Max Purchases Period Value
   * @min 0
   */
  max_purchases_period_value?: number
}

/** StoreItemBulkDelete */
export interface StoreItemBulkDelete {
  /** Id */
  id: string
}

/** StoreItemBulkUpdate */
export interface StoreItemBulkUpdate {
  /** Discount Percent */
  discount_percent?: number
  /** Bonus Percent */
  bonus_percent?: number
  /** Position */
  position?: number
  /** Name */
  name?: string
  /** Description */
  description?: string
  /** Max Purchases */
  max_purchases?: number
  requirements_expression?: Expression
  /**
   * Custom Badge
   * The custom badge for the item
   * @minLength 1
   * @maxLength 100
   */
  custom_badge?: string
  /** Start At */
  start_at?: number
  /** End At */
  end_at?: number
  /** Min Amount */
  min_amount?: number
  /** Reward Points Percent */
  reward_points_percent?: number
  payment_bound?: StoreItemPaymentSettings
  /** Is Free Item */
  is_free_item?: boolean
  /**
   * Rotation Weight
   * @min 0
   * @max 1000
   */
  rotation_weight?: number
  /** An enumeration. */
  max_purchases_period_type?: PeriodType
  /**
   * Max Purchases Period Value
   * @min 0
   */
  max_purchases_period_value?: number
  /**
   * Id
   * The unique identifier of the item
   */
  id: string
}

/** StoreItemCreate */
export interface StoreItemCreate {
  /** Item Id */
  item_id: string
  /** Discount Percent */
  discount_percent?: number
  /** Bonus Percent */
  bonus_percent?: number
  /** Position */
  position: number
  /** Name */
  name: string
  /** Description */
  description: string
  /** Max Purchases */
  max_purchases?: number
  requirements_expression?: Expression
  /**
   * Custom Badge
   * The custom badge of the item
   * @maxLength 100
   */
  custom_badge?: string
  /** Campaign Id */
  campaign_id?: string
  /** User Campaign Id */
  user_campaign_id?: string
  /** Bond User Id */
  bond_user_id?: string
  /** Start At */
  start_at?: number
  /** End At */
  end_at?: number
  /** Min Amount */
  min_amount?: number
  /** Max Reward Points Percent */
  max_reward_points_percent?: number
  /** Reward Points Percent */
  reward_points_percent?: number
  payment_bound?: StoreItemPaymentSettings
  /**
   * Is Free Item
   * @default false
   */
  is_free_item?: boolean
  /**
   * Rotation Weight
   * @min 0
   * @max 1000
   */
  rotation_weight?: number
  /** An enumeration. */
  max_purchases_period_type?: PeriodType
  /**
   * Max Purchases Period Value
   * @min 0
   */
  max_purchases_period_value?: number
}

/** StoreItemPaymentSettings */
export interface StoreItemPaymentSettings {
  /** Payment Method Ids */
  payment_method_ids?: string[]
}

/** StoreItemRead */
export interface StoreItemRead {
  /** Item Id */
  item_id: string
  /** Discount Percent */
  discount_percent?: number
  /** Bonus Percent */
  bonus_percent?: number
  /** Position */
  position: number
  /** Name */
  name: string
  /** Description */
  description: string
  /** Max Purchases */
  max_purchases?: number
  requirements_expression?: Expression
  /**
   * Custom Badge
   * The custom badge of the item
   * @maxLength 100
   */
  custom_badge?: string
  /** Campaign Id */
  campaign_id?: string
  /** User Campaign Id */
  user_campaign_id?: string
  /** Bond User Id */
  bond_user_id?: string
  /** Start At */
  start_at?: number
  /** End At */
  end_at?: number
  /** Min Amount */
  min_amount?: number
  /** Max Reward Points Percent */
  max_reward_points_percent?: number
  /** Reward Points Percent */
  reward_points_percent?: number
  payment_bound?: StoreItemPaymentSettings
  /**
   * Is Free Item
   * @default false
   */
  is_free_item?: boolean
  /**
   * Rotation Weight
   * @min 0
   * @max 1000
   */
  rotation_weight?: number
  /** An enumeration. */
  max_purchases_period_type?: PeriodType
  /**
   * Max Purchases Period Value
   * @min 0
   */
  max_purchases_period_value?: number
  /** Id */
  id: string
  item: ItemRead
  /** Store Id */
  store_id: string
}

/** StoreItemUpdate */
export interface StoreItemUpdate {
  /** Discount Percent */
  discount_percent?: number
  /** Bonus Percent */
  bonus_percent?: number
  /** Position */
  position?: number
  /** Name */
  name?: string
  /** Description */
  description?: string
  /** Max Purchases */
  max_purchases?: number
  requirements_expression?: Expression
  /**
   * Custom Badge
   * The custom badge for the item
   * @minLength 1
   * @maxLength 100
   */
  custom_badge?: string
  /** Start At */
  start_at?: number
  /** End At */
  end_at?: number
  /** Min Amount */
  min_amount?: number
  /** Reward Points Percent */
  reward_points_percent?: number
  payment_bound?: StoreItemPaymentSettings
  /** Is Free Item */
  is_free_item?: boolean
  /**
   * Rotation Weight
   * @min 0
   * @max 1000
   */
  rotation_weight?: number
  /** An enumeration. */
  max_purchases_period_type?: PeriodType
  /**
   * Max Purchases Period Value
   * @min 0
   */
  max_purchases_period_value?: number
}

/** StoreRead */
export interface StoreRead {
  /** Name */
  name: string
  /** Description */
  description: string
  /** An enumeration. */
  type: StoreType
  rotation_settings?: RotationSettings
  /** Id */
  id: string
}

/** StoreReadStat */
export interface StoreReadStat {
  /** Name */
  name: string
  /** Description */
  description: string
  /** An enumeration. */
  type: StoreType
  rotation_settings?: RotationSettings
  /** Id */
  id: string
  /** Item Count */
  item_count: number
}

/** StoreSettings */
export interface StoreSettings {
  /** Default Image Url */
  default_image_url?: string
  custom_texts?: StoreSettingsCustomTexts
  /** Image Ratio */
  image_ratio?: number
  /** @default "v1" */
  card_version?: StoreCardVersion
  /** Mobile Grid Columns */
  mobile_grid_columns?: number
  /** Tablet Grid Columns */
  tablet_grid_columns?: number
  /** Desktop Grid Columns */
  desktop_grid_columns?: number
  /** Large Desktop Grid Columns */
  large_desktop_grid_columns?: number
}

/** StoreSettingsCustomTexts */
export interface StoreSettingsCustomTexts {
  /** Item Text */
  item_text?: string
  /** Currency Text */
  currency_text?: string
}

/**
 * StoreType
 * An enumeration.
 */
export enum StoreType {
  Default = 'default',
  Special = 'special',
  Rotation = 'rotation',
}

/** StoreUpdate */
export interface StoreUpdate {
  /** Name */
  name?: string
  /** Description */
  description?: string
  /** An enumeration. */
  type?: StoreType
  rotation_settings?: RotationSettings
}

/**
 * TaskStatus
 * An enumeration.
 */
export enum TaskStatus {
  Todo = 'todo',
  Completed = 'completed',
}

/** TextBlock */
export interface TextBlock {
  /**
   * Block
   * @default "TextBlock"
   */
  block?: 'TextBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
  /** Text */
  text?: string
  /** Title */
  title?: string
}

/** TimerNode */
export interface TimerNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "TimerNode"
   */
  model_type?: 'TimerNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Delay */
  delay: number
  /** Start Wait */
  start_wait?: number
  /** End Wait */
  end_wait?: number
}

/** Token */
export interface Token {
  /** An enumeration. */
  type: TokenType
  /** Value */
  value?: any
}

/**
 * TokenType
 * An enumeration.
 */
export enum TokenType {
  Attribute = 'attribute',
  CustomAttribute = 'custom_attribute',
  EventAttribute = 'event_attribute',
  CustomEventAttribute = 'custom_event_attribute',
  Operator = 'operator',
  Number = 'number',
  Date = 'date',
  String = 'string',
  Boolean = 'boolean',
  List = 'list',
}

/** TotalCountResponse */
export interface TotalCountResponse {
  /** Total Count */
  total_count: number
}

/** TranslateTextRequest */
export interface TranslateTextRequest {
  /** Language codes according to ISO 639-1. */
  from_locale: Locale
  /** Language codes according to ISO 639-1. */
  to_locale: Locale
  /** Original Text */
  original_text: string
}

/** TranslationCreate */
export interface TranslationCreate {
  /** Object Id */
  object_id: string
  /** An enumeration. */
  object_type: ObjectType
  /** Object Property */
  object_property: string
  /** Language codes according to ISO 639-1. */
  locale: Locale
  /** Content */
  content?: string
  /** Label */
  label?: string
}

/** TranslationHtmlStrippedRead */
export interface TranslationHtmlStrippedRead {
  /** Object Id */
  object_id: string
  /** An enumeration. */
  object_type: ObjectType
  /** Object Property */
  object_property: string
  /** Language codes according to ISO 639-1. */
  locale: Locale
  /** Content */
  content?: string
  /** Label */
  label?: string
  /** Id */
  id?: string
  /** Original Id */
  original_id: string
  /** Original Content */
  original_content?: string
  /** Is Truncated */
  is_truncated: boolean
  /** An enumeration. */
  state: State
  /** Synced */
  synced: boolean
  /** Cursor Id */
  cursor_id: string
}

/** TranslationRead */
export interface TranslationRead {
  /** Object Id */
  object_id: string
  /** An enumeration. */
  object_type: ObjectType
  /** Object Property */
  object_property: string
  /** Language codes according to ISO 639-1. */
  locale: Locale
  /** Content */
  content?: string
  /** Label */
  label?: string
  /** Id */
  id?: string
  /** Original Id */
  original_id: string
  /** Original Content */
  original_content?: string
  /** Is Truncated */
  is_truncated: boolean
  /** An enumeration. */
  state: State
  /** Synced */
  synced: boolean
  /** Cursor Id */
  cursor_id: string
}

/** TranslationUpdate */
export interface TranslationUpdate {
  /** Content */
  content?: string
}

/** TwitchBlock */
export interface TwitchBlock {
  /**
   * Block
   * @default "TwitchBlock"
   */
  block?: 'TwitchBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
  video: VideoBase
}

/** TwitchData */
export interface TwitchData {
  /**
   * Channel
   * The channel of the Twitch video
   */
  channel: string
}

/** User */
export interface User {
  /** Id */
  id: string
  /** Name */
  name?: string
  /** Email */
  email?: string
  /** Player Id */
  player_id: string
  /** Created At */
  created_at: number
  /** Modified At */
  modified_at?: number
  /**
   * Country
   * @pattern ^[A-Z]{2}$
   */
  country?: string
  /** Avatar Url */
  avatar_url?: string
  /** Banned */
  banned?: boolean
  /**
   * Represents an object in a nested context whose parent can be tracked.
   *
   * The top object in the parent link should be an instance of `Mutable`.
   */
  attributes?: PersistentPlayerAttributes
  calc_attributes?: CalcPlayerAttributes
  /** Custom Attributes */
  custom_attributes?: object
}

/** UserCouponRead */
export interface UserCouponRead {
  /** Name */
  name?: any
  /**
   * Code
   * The code of the coupon
   * @maxLength 20
   */
  code: string
  /** The type of the coupon */
  type: CouponType
  /**
   * Item Id
   * The item ID for the coupon
   */
  item_id?: string
  /**
   * Discount Percent
   * The discount percent for the coupon
   * @min 0
   * @max 100
   */
  discount_percent?: number
  /**
   * Bonus Percent
   * The bonus percent for the coupon
   * @min 0
   * @max 1000
   */
  bonus_percent?: number
  /**
   * Requirements Expression
   * The requirement expression for the coupon
   */
  requirements_expression?: Expression
  /**
   * Id
   * The unique identifier of the coupon
   */
  id: string
  /**
   * Item
   * The item for the coupon
   */
  item?: Item
  /**
   * Expires At
   * The expiration date of the coupon
   */
  expires_at?: number
  /**
   * Applicable Item Ids
   * The applicable item IDs
   */
  applicable_item_ids?: string[]
  /** Min Amount */
  min_amount?: number
  /** Max Redemptions */
  max_redemptions?: number
  /** Is Stackable With Bonus Coupons */
  is_stackable_with_bonus_coupons?: boolean
  /** Stackable Bonus Limit Percent */
  stackable_bonus_limit_percent?: number
  /** Is Stackable With Discount Coupons */
  is_stackable_with_discount_coupons?: boolean
  /** Stackable Discount Limit Percent */
  stackable_discount_limit_percent?: number
  /** Redeemed At */
  redeemed_at?: number
  /** Order Item Id */
  order_item_id?: string
}

/** UserItemClaimed */
export interface UserItemClaimed {
  /** Created At */
  created_at: number
  /** An enumeration. */
  object_type: ItemClaimedType
  /** Item Id */
  item_id: string
  /** Item Sku */
  item_sku: string
  /** Item Name */
  item_name?: any
  /** Item Image Url */
  item_image_url: string
  /** Item Quantity */
  item_quantity?: number
  /** Item Is Stackable */
  item_is_stackable: boolean
}

/** UserPaymentMethod */
export interface UserPaymentMethod {
  /** Id */
  id: string
  /** Payment Method Id */
  payment_method_id: string
  /** Payment Method Name */
  payment_method_name: string
  data: UserPaymentMethodData
  /** Is Default */
  is_default: boolean
  /** Is Verified */
  is_verified: boolean
  billing_address?: BillingAddress
}

/** UserPaymentMethodData */
export interface UserPaymentMethodData {
  /** Bin */
  bin?: string
  /** Last4 */
  last4?: string
  /** Scheme */
  scheme?: string
  /** Email */
  email?: string
  /** Token Id */
  token_id?: string
  /** Customer Id */
  customer_id?: string
}

/** UserRead */
export interface UserRead {
  /** Id */
  id: string
  /** Name */
  name?: string
  /** Email */
  email?: string
  /** Player Id */
  player_id: string
  /** Created At */
  created_at: number
  /** Modified At */
  modified_at?: number
  /**
   * Country
   * @pattern ^[A-Z]{2}$
   */
  country?: string
  /** Avatar Url */
  avatar_url?: string
  /** Banned */
  banned?: boolean
  /**
   * Represents an object in a nested context whose parent can be tracked.
   *
   * The top object in the parent link should be an instance of `Mutable`.
   */
  attributes?: PersistentPlayerAttributes
}

/** UserSegmentRead */
export interface UserSegmentRead {
  /** Segment Id */
  segment_id: string
  /** Reason */
  reason?: string
  /** Added By Campaign Id */
  added_by_campaign_id?: string
  /** Added By Customer Id */
  added_by_customer_id?: string
  segment?: SegmentRead
  /** Created At */
  created_at?: number
}

/** UserSegmentUpdate */
export interface UserSegmentUpdate {
  /** Ids */
  ids: string[]
}

/** UserUpdate */
export interface UserUpdate {
  /** Name */
  name?: string
  /** Email */
  email?: string
  /**
   * Country
   * @pattern ^[A-Z]{2}$
   */
  country?: string
  /** Banned */
  banned?: boolean
  /** Avatar Url */
  avatar_url?: string
  /**
   * Represents an object in a nested context whose parent can be tracked.
   *
   * The top object in the parent link should be an instance of `Mutable`.
   */
  attributes?: PersistentPlayerAttributes
  /** Custom Attributes */
  custom_attributes?: object
  /** Segments */
  segments?: string[]
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

/**
 * VerifyState
 * An enumeration.
 */
export enum VerifyState {
  Unverified = 'unverified',
  UnderReview = 'under_review',
  Verified = 'verified',
}

/** VideoBase */
export interface VideoBase {
  /**
   * Title
   * The title of the video
   * @maxLength 255
   */
  title: string
  /**
   * Description
   * The description of the video
   * @maxLength 512
   */
  description: string
  /**
   * Order Id
   * The order ID of the video
   * @default 0
   */
  order_id?: number
  /**
   * Thumbnail Url
   * The URL of the thumbnail for the video
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  thumbnail_url?: string
  /** The platform of the video */
  platform: VideoPlatform
  /**
   * Data
   * The data of the video
   */
  data: YoutubeData | TwitchData
}

/** VideoCreate */
export interface VideoCreate {
  /**
   * Title
   * The title of the video
   * @maxLength 255
   */
  title: string
  /**
   * Description
   * The description of the video
   * @maxLength 512
   */
  description: string
  /**
   * Order Id
   * The order ID of the video
   * @default 0
   */
  order_id?: number
  /**
   * Thumbnail Url
   * The URL of the thumbnail for the video
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  thumbnail_url?: string
  /** The platform of the video */
  platform: VideoPlatform
  /**
   * Data
   * The data of the video
   */
  data: YoutubeData | TwitchData
}

/** VideoFeaturedAdd */
export interface VideoFeaturedAdd {
  /** Video Id */
  video_id: string
}

/**
 * VideoPlatform
 * An enumeration.
 */
export enum VideoPlatform {
  Youtube = 'youtube',
  Twitch = 'twitch',
}

/** VideoRead */
export interface VideoRead {
  /**
   * Title
   * The title of the video
   * @maxLength 255
   */
  title: string
  /**
   * Description
   * The description of the video
   * @maxLength 512
   */
  description: string
  /**
   * Order Id
   * The order ID of the video
   * @default 0
   */
  order_id?: number
  /**
   * Thumbnail Url
   * The URL of the thumbnail for the video
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  thumbnail_url?: string
  /** The platform of the video */
  platform: VideoPlatform
  /**
   * Data
   * The data of the video
   */
  data: YoutubeData | TwitchData
  /**
   * Id
   * The unique identifier of the video
   */
  id: string
}

/** VideoTranslate */
export interface VideoTranslate {
  /**
   * Title
   * The title of the video
   * @maxLength 255
   */
  title?: string
  /**
   * Description
   * The description of the video
   * @maxLength 512
   */
  description?: string
}

/** VideoUpdate */
export interface VideoUpdate {
  /**
   * Title
   * The title of the video
   * @maxLength 255
   */
  title?: string
  /**
   * Description
   * The description of the video
   * @maxLength 512
   */
  description?: string
  /**
   * Order Id
   * The order ID of the video
   */
  order_id?: number
  /**
   * Thumbnail Url
   * The URL of the thumbnail for the video
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  thumbnail_url?: string
}

/** ViewPort */
export interface ViewPort {
  /** X */
  x: number
  /** Y */
  y: number
  /** Zoom */
  zoom: number
}

/** WebSiteMenuSection */
export interface WebSiteMenuSection {
  /** Left */
  left?: string[]
  /** Right */
  right?: string[]
}

/** WebhookActionNode */
export interface WebhookActionNode {
  /** Id */
  id: string
  /** Next Node Id */
  next_node_id?: string
  /** Title */
  title?: string
  /** Styles */
  styles?: Record<string, string>
  /** X */
  x: number
  /** Y */
  y: number
  /** Result */
  result?: string
  /**
   * Model Type
   * @default "WebhookActionNode"
   */
  model_type?: 'WebhookActionNode'
  /** Break On Error */
  break_on_error?: boolean
  /** Description */
  description?: string
  /** Body */
  body?: string
  /** Item Id */
  item_id?: string
  /** Link Created Offer */
  link_created_offer?: boolean
  /** Store Item Id */
  store_item_id?: string
  /** Store Id */
  store_id?: string
  /** Url */
  url?: string
  /** Response */
  response?: string
  /** Status Code */
  status_code?: number
}

/** WebhookCreate */
export interface WebhookCreate {
  /** The list of events that trigger the webhook. */
  events: WebhookEventType[]
  /**
   * Url
   * The URL where the webhook will be sent.
   */
  url: string
  /**
   * Description
   * The description of a webhook.
   */
  description?: string
  /** The HTTP method used to send the webhook. */
  method: WebhookMethod
}

/**
 * WebhookEventType
 * An enumeration.
 */
export enum WebhookEventType {
  PlayerVerify = 'player.verify',
  CouponRedeemed = 'coupon.redeemed',
  IngamePush = 'ingame.push',
  IngamePopup = 'ingame.popup',
  PlayerIsIdle = 'player.is_idle',
  CampaignCustom = 'campaign.custom',
  OrderPaid = 'order.paid',
  OrderCanceled = 'order.canceled',
  OrderRefunded = 'order.refunded',
  ItemAdd = 'item.add',
  ItemRemove = 'item.remove',
}

/** WebhookLogRead */
export interface WebhookLogRead {
  /** Id */
  id: string
  /** Webhook Id */
  webhook_id: string
  /** An enumeration. */
  event_type: WebhookEventType
  /** Game Id */
  game_id?: string
  /** User Id */
  user_id?: string
  /** Order Id */
  order_id?: string
  /** Request Status */
  request_status?: string
  /** Request Body */
  request_body?: string
  /** Response Body */
  response_body?: string
  /** Duration */
  duration: number
  /** Status Code */
  status_code: number
  /** Created At */
  created_at: number
  /** Is Test */
  is_test?: boolean
}

/**
 * WebhookLogStatus
 * An enumeration.
 */
export enum WebhookLogStatus {
  Success = 'success',
  Error = 'error',
}

/**
 * WebhookMethod
 * An enumeration.
 */
export enum WebhookMethod {
  GET = 'GET',
  POST = 'POST',
}

/** WebhookRedacted */
export interface WebhookRedacted {
  /** The list of events that trigger the webhook. */
  events: WebhookEventType[]
  /**
   * Url
   * The URL where the webhook will be sent.
   */
  url: string
  /**
   * Description
   * The description of a webhook.
   */
  description?: string
  /** The HTTP method used to send the webhook. */
  method: WebhookMethod
  /** Secret Redacted */
  secret_redacted: string
  /**
   * Id
   * The unique identifier of the webhook.
   */
  id: string
  /**
   * Game Id
   * The unique identifier of the game.
   */
  game_id: string
  /**
   * Error Rate
   * The error rate of the webhook.
   */
  error_rate: number
}

/** WebhookRevealed */
export interface WebhookRevealed {
  /** The list of events that trigger the webhook. */
  events: WebhookEventType[]
  /**
   * Url
   * The URL where the webhook will be sent.
   */
  url: string
  /**
   * Description
   * The description of a webhook.
   */
  description?: string
  /** The HTTP method used to send the webhook. */
  method: WebhookMethod
  /**
   * Id
   * The unique identifier of the webhook.
   */
  id: string
  /**
   * Game Id
   * The unique identifier of the game.
   */
  game_id: string
  /**
   * Error Rate
   * The error rate of the webhook.
   */
  error_rate: number
  /**
   * Secret
   * The secret used to sign the webhook.
   */
  secret: string
}

/** WebhookUpdate */
export interface WebhookUpdate {
  /** The list of events that trigger the webhook. */
  events?: WebhookEventType[]
  /**
   * Url
   * The URL where the webhook will be sent.
   */
  url?: string
  /**
   * Description
   * The description of a webhook.
   */
  description?: string
  /** The HTTP method used to send the webhook. */
  method?: WebhookMethod
}

/** Website */
export interface Website {
  /**
   * Title
   * @maxLength 200
   */
  title: string
  /**
   * Description
   * @maxLength 1000
   */
  description: string
  config?: AghanimTypesWebsiteConfig
  /** Template Id */
  template_id?: string
  /**
   * Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
  /** Id */
  id: string
  /** Slug */
  slug: string
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  url: string
  game: AghanimGameSchemasGame
  /** Created At */
  created_at: number
  /** Modified At */
  modified_at?: number
  /** Published At */
  published_at?: number
  /** Share Token */
  share_token: string
}

/** WebsiteCreate */
export interface WebsiteCreate {
  /**
   * Title
   * @maxLength 200
   */
  title: string
  /**
   * Description
   * @maxLength 1000
   */
  description: string
  config?: AghanimTypesWebsiteConfig
  /** Template Id */
  template_id?: string
  /**
   * Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
  /** Share Token */
  share_token: string
}

/** WebsiteRead */
export interface WebsiteRead {
  /**
   * Title
   * @maxLength 200
   */
  title: string
  /**
   * Description
   * @maxLength 1000
   */
  description: string
  config?: AghanimTypesWebsiteConfig
  /** Template Id */
  template_id?: string
  /**
   * Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
  /** Id */
  id: string
  game: AghanimGameSchemasGame
  /** Slug */
  slug: string
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  url: string
  /** Featured Items */
  featured_items: Item[]
  /** Featured Videos */
  featured_videos: VideoRead[]
  /** Created At */
  created_at: number
  /** Modified At */
  modified_at?: number
  /** Published At */
  published_at?: number
  /** Share Token */
  share_token: string
}

/** WebsiteSection */
export interface WebsiteSection {
  /** An enumeration. */
  name: SectionType
  /** An enumeration. */
  object_type?: SectionObjectType
  /** Object Id */
  object_id?: string
  /** Visible */
  visible: boolean
  /** Icon */
  icon?: string
}

/** WebsiteTemplate */
export interface WebsiteTemplate {
  /**
   * Name
   * @maxLength 200
   */
  name?: string
  /**
   * Description
   * @maxLength 1000
   */
  description?: string
  config?: AghanimTypesWebsiteConfig
  /** Published */
  published?: boolean
  /**
   * Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
  /** Id */
  id: string
}

/** WebsiteTemplateCreate */
export interface WebsiteTemplateCreate {
  /**
   * Name
   * @maxLength 200
   */
  name?: string
  /**
   * Description
   * @maxLength 1000
   */
  description?: string
  config?: AghanimTypesWebsiteConfig
  /** Published */
  published?: boolean
  /**
   * Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
}

/** WebsiteTemplateUpdate */
export interface WebsiteTemplateUpdate {
  /**
   * Name
   * @maxLength 200
   */
  name?: string
  /**
   * Description
   * @maxLength 1000
   */
  description?: string
  /** Published */
  published?: boolean
  /**
   * Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
  config?: AghanimTypesWebsiteConfig
}

/** WebsiteTranslate */
export interface WebsiteTranslate {
  /**
   * Title
   * @maxLength 200
   */
  title?: string
  /**
   * Description
   * @maxLength 1000
   */
  description?: string
}

/** WebsiteUpdate */
export interface WebsiteUpdate {
  /**
   * Title
   * @maxLength 200
   */
  title?: string
  /**
   * Description
   * @maxLength 1000
   */
  description?: string
  config?: AghanimTypesWebsiteConfig
  /** Published At */
  published_at?: number
  /**
   * Image Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  image_url?: string
}

/** YoutubeBlock */
export interface YoutubeBlock {
  /**
   * Block
   * @default "YoutubeBlock"
   */
  block?: 'YoutubeBlock'
  /** Id */
  id?: string
  /** Name */
  name?: string
  video: VideoBase
}

/** YoutubeData */
export interface YoutubeData {
  /**
   * Video Id
   * The video ID of the YouTube video
   */
  video_id: string
}

/** Game */
export interface AghanimGameSchemasGame {
  /**
   * Name
   * @minLength 2
   * @maxLength 200
   */
  name: string
  /**
   * Description
   * @minLength 2
   * @maxLength 1000
   */
  description: string
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string
  /**
   * Logo Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  logo_url: string
  /** Social Medias */
  social_medias: SocialMedia[]
  /** Game Stores */
  game_stores: GameStore[]
  /** An enumeration. */
  login_type: LoginType
  login_settings?: LoginSettings
  /** Language codes according to ISO 639-1. */
  locale_default: Locale
  /** Id */
  id: string
  /** Company Id */
  company_id: string
  /** Locales */
  locales: LocaleRead[]
  /** Sandbox */
  sandbox: boolean
  /** Sandbox Game Id */
  sandbox_game_id?: string
  /** Live Game Id */
  live_game_id?: string
}

/** Game */
export interface AghanimOrderSchemasGame {
  /** Name */
  name: string
  /**
   * Logo Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  logo_url: string
}

/** WebsiteConfig */
export interface AghanimOrderSchemasWebsiteConfig {
  /** Google Analytics Id */
  google_analytics_id?: string
  /** Google Tag Manager Id */
  google_tag_manager_id?: string
}

/** WebsiteConfig */
export interface AghanimTypesWebsiteConfig {
  /**
   * Favicon Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  favicon_url?: string
  /** Base Bg Color */
  base_bg_color?: string
  /** Base Bg Image */
  base_bg_image?: string
  /** Accent Color */
  accent_color?: string
  /** Button Color */
  button_color?: string
  /** Text Color */
  text_color?: string
  /** Dark Bg Color */
  dark_bg_color?: string
  /** Featured Bg Color */
  featured_bg_color?: string
  /** Card Bg Color */
  card_bg_color?: string
  /** Card Image Bg Color */
  card_image_bg_color?: string
  /** Ui Elements Color */
  ui_elements_color?: string
  /** Text Muted */
  text_muted?: string
  /** Left Menu Item Color */
  left_menu_item_color?: string
  /** Login Button Bg */
  login_button_bg?: string
  /** Login Button Color */
  login_button_color?: string
  /** Social Link Bg Color */
  social_link_bg_color?: string
  /** Social Link Color */
  social_link_color?: string
  /** Offer Label Bg Color */
  offer_label_bg_color?: string
  /** Offer Label Color */
  offer_label_color?: string
  /** Reward Button Bg */
  reward_button_bg?: string
  /** Reward Button Color */
  reward_button_color?: string
  /** Custom Badge Bg Color */
  custom_badge_bg_color?: string
  /** Custom Badge Color */
  custom_badge_color?: string
  /** Free Item Custom Badge Bg Color */
  free_item_custom_badge_bg_color?: string
  /** Free Item Custom Badge Color */
  free_item_custom_badge_color?: string
  /** Free Item Button Bg */
  free_item_button_bg?: string
  /** Free Item Button Color */
  free_item_button_color?: string
  /** Buy Button Bg Color */
  buy_button_bg_color?: string
  /** Buy Button Color */
  buy_button_color?: string
  /** Border Radius */
  border_radius?: string
  /** Font Size */
  font_size?: string
  /** Twitch Video Url */
  twitch_video_url?: string
  /** Youtube Video Url */
  youtube_video_url?: string
  /** Background Image Size */
  background_image_size?: string
  /** Background Image Opacity */
  background_image_opacity?: string
  /** Left Aside Width */
  left_aside_width?: string
  /** Right Aside Width */
  right_aside_width?: string
  /** Font Weight */
  font_weight?: string
  /** Base Padding */
  base_padding?: string
  /** Chrome App Icon Url */
  chrome_app_icon_url?: string
  /** Screenshots */
  screenshots?: Screenshot[]
  /** Sections */
  sections?: WebsiteSection[]
  age_settings?: AgeSettings
  /** Trademarks */
  trademarks?: string
  banner_config?: NewsBannerConfig
  featured_banner_config?: FeaturedBannerConfig
  /** An enumeration. */
  events_widget_category?: NewsCategory
  /** An enumeration. */
  mode?: Mode
  menu_sections?: WebSiteMenuSection
  store_settings?: StoreSettings
  news_settings?: NewsSettings
  /** Avatars */
  avatars?: string[]
  /**
   * Auto Apply Random Avatar
   * @default true
   */
  auto_apply_random_avatar?: boolean
  /** Leaderboard Icons */
  leaderboard_icons?: string[]
  /**
   * Demo Mode
   * @default false
   */
  demo_mode?: boolean
  /** Google Analytics Id */
  google_analytics_id?: string
  /** Google Tag Manager Id */
  google_tag_manager_id?: string
  /** Termly Id */
  termly_id?: string
  /** Cookie Banner Enabled */
  cookie_banner_enabled?: boolean
  sort_item_type?: ItemType[]
  /** Custom Code Js */
  custom_code_js?: string
  /** Palette Name */
  palette_name?: string
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '/dashboard'
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter(key => 'undefined' !== typeof query[key])
    return keys
      .map(key => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async response => {
      const r = response.clone() as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then(data => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch(e => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title Aghanim Dashboard API
 * @version 0.1.0
 * @baseUrl /dashboard
 *
 * Welcome to the Aghanim API.
 */
export class DashboardClient<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  v1 = {
    /**
     * No description
     *
     * @tags Games
     * @name GetGames
     * @summary Get Games
     * @request GET:/v1/companies/{company_id}/games
     * @secure
     */
    getGames: (
      companyId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<AghanimGameSchemasGame[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Games
     * @name CreateGame
     * @summary Create Game
     * @request POST:/v1/companies/{company_id}/games
     * @secure
     */
    createGame: (companyId: string, data: GameCreate, params: RequestParams = {}) =>
      this.request<AghanimGameSchemasGame, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Games
     * @name GetGame
     * @summary Get Game
     * @request GET:/v1/companies/{company_id}/games/{game_id}
     * @secure
     */
    getGame: (companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<AghanimGameSchemasGame, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Games
     * @name UpdateGame
     * @summary Update Game
     * @request PUT:/v1/companies/{company_id}/games/{game_id}
     * @secure
     */
    updateGame: (companyId: string, gameId: string, data: GameUpdate, params: RequestParams = {}) =>
      this.request<AghanimGameSchemasGame, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Games
     * @name DeleteGame
     * @summary Delete Game
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}
     * @secure
     */
    deleteGame: (companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Games
     * @name CreateSandbox
     * @summary Create Sandbox
     * @request POST:/v1/companies/{company_id}/games/{game_id}/sandbox
     * @secure
     */
    createSandbox: (companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<AghanimGameSchemasGame, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/sandbox`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of achievements.
     *
     * @tags Achievements
     * @name GetAchievements
     * @summary Get Achievements
     * @request GET:/v1/companies/{company_id}/games/{game_id}/achievements
     * @secure
     */
    getAchievements: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<AchievementRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/achievements`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new achievement.
     *
     * @tags Achievements
     * @name CreateAchievement
     * @summary Create Achievement
     * @request POST:/v1/companies/{company_id}/games/{game_id}/achievements
     * @secure
     */
    createAchievement: (companyId: string, gameId: string, data: AchievementCreate, params: RequestParams = {}) =>
      this.request<AchievementRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/achievements`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates multiple store items.
     *
     * @tags Achievements
     * @name BulkUpdateAchievement
     * @summary Bulk Update Achievement
     * @request PATCH:/v1/companies/{company_id}/games/{game_id}/achievements
     * @secure
     */
    bulkUpdateAchievement: (
      companyId: string,
      gameId: string,
      data: AchievementBulkUpdate[],
      params: RequestParams = {},
    ) =>
      this.request<AchievementRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/achievements`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a single achievement.
     *
     * @tags Achievements
     * @name GetAchievement
     * @summary Get Achievement
     * @request GET:/v1/companies/{company_id}/games/{game_id}/achievements/{achievement_id}
     * @secure
     */
    getAchievement: (companyId: string, gameId: string, achievementId: string, params: RequestParams = {}) =>
      this.request<AchievementRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/achievements/${achievementId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates an achievement.
     *
     * @tags Achievements
     * @name UpdateAchievement
     * @summary Update Achievement
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/achievements/{achievement_id}
     * @secure
     */
    updateAchievement: (
      companyId: string,
      gameId: string,
      achievementId: string,
      data: AchievementUpdate,
      params: RequestParams = {},
    ) =>
      this.request<AchievementRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/achievements/${achievementId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes an achievement.
     *
     * @tags Achievements
     * @name DeleteAchievement
     * @summary Delete Achievement
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/achievements/{achievement_id}
     * @secure
     */
    deleteAchievement: (companyId: string, gameId: string, achievementId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/achievements/${achievementId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Translates an achievement.
     *
     * @tags Achievements
     * @name TranslateAchievement
     * @summary Translate Achievement
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/achievements/{achievement_id}/translations/{locale}
     * @secure
     */
    translateAchievement: (
      locale: Locale,
      companyId: string,
      gameId: string,
      achievementId: string,
      data: AchievementTranslate,
      params: RequestParams = {},
    ) =>
      this.request<AchievementRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/achievements/${achievementId}/translations/${locale}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags API Keys
     * @name GetApiKeys
     * @summary Get Api Keys
     * @request GET:/v1/companies/{company_id}/games/{game_id}/api_keys
     * @secure
     */
    getApiKeys: (companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<APIKeyRedacted[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/api_keys`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags API Keys
     * @name CreateApiKey
     * @summary Create Api Key
     * @request POST:/v1/companies/{company_id}/games/{game_id}/api_keys
     * @secure
     */
    createApiKey: (companyId: string, gameId: string, data: APIKeyCreate, params: RequestParams = {}) =>
      this.request<APIKeyRevealed, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/api_keys`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags API Keys
     * @name GetApiKey
     * @summary Get Api Key
     * @request GET:/v1/companies/{company_id}/games/{game_id}/api_keys/{api_key_id}
     * @secure
     */
    getApiKey: (companyId: string, gameId: string, apiKeyId: string, params: RequestParams = {}) =>
      this.request<APIKeyRedacted, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/api_keys/${apiKeyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags API Keys
     * @name DeleteApiKey
     * @summary Delete Api Key
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/api_keys/{api_key_id}
     * @secure
     */
    deleteApiKey: (companyId: string, gameId: string, apiKeyId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/api_keys/${apiKeyId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags API Keys
     * @name RevealApiKey
     * @summary Reveal Api Key
     * @request POST:/v1/companies/{company_id}/games/{game_id}/api_keys/{api_key_id}/reveal
     * @secure
     */
    revealApiKey: (companyId: string, gameId: string, apiKeyId: string, params: RequestParams = {}) =>
      this.request<APIKeyRevealed, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/api_keys/${apiKeyId}/reveal`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of coupons.
     *
     * @tags Coupons
     * @name GetCoupons
     * @summary Get Coupons
     * @request GET:/v1/companies/{company_id}/games/{game_id}/coupons
     * @secure
     */
    getCoupons: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<CouponRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/coupons`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new coupon.
     *
     * @tags Coupons
     * @name CreateCoupon
     * @summary Create Coupon
     * @request POST:/v1/companies/{company_id}/games/{game_id}/coupons
     * @secure
     */
    createCoupon: (companyId: string, gameId: string, data: CouponCreate, params: RequestParams = {}) =>
      this.request<CouponRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/coupons`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a single coupon.
     *
     * @tags Coupons
     * @name GetCoupon
     * @summary Get Coupon
     * @request GET:/v1/companies/{company_id}/games/{game_id}/coupons/{coupon_id}
     * @secure
     */
    getCoupon: (companyId: string, gameId: string, couponId: string, params: RequestParams = {}) =>
      this.request<CouponRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/coupons/${couponId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates a coupon.
     *
     * @tags Coupons
     * @name UpdateCoupon
     * @summary Update Coupon
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/coupons/{coupon_id}
     * @secure
     */
    updateCoupon: (
      companyId: string,
      gameId: string,
      couponId: string,
      data: CouponUpdate,
      params: RequestParams = {},
    ) =>
      this.request<CouponRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/coupons/${couponId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes a coupon.
     *
     * @tags Coupons
     * @name DeleteCoupon
     * @summary Delete Coupon
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/coupons/{coupon_id}
     * @secure
     */
    deleteCoupon: (companyId: string, gameId: string, couponId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/coupons/${couponId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Translates a coupon.
     *
     * @tags Coupons
     * @name TranslateCoupon
     * @summary Translate Coupon
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/coupons/{coupon_id}/translations/{locale}
     * @secure
     */
    translateCoupon: (
      locale: Locale,
      companyId: string,
      gameId: string,
      couponId: string,
      data: CouponTranslate,
      params: RequestParams = {},
    ) =>
      this.request<CouponRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/coupons/${couponId}/translations/${locale}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags FAQs
     * @name GetFaqs
     * @summary Get Faqs
     * @request GET:/v1/companies/{company_id}/games/{game_id}/faqs
     * @secure
     */
    getFaqs: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<FAQ[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/faqs`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags FAQs
     * @name CreateFaq
     * @summary Create Faq
     * @request POST:/v1/companies/{company_id}/games/{game_id}/faqs
     * @secure
     */
    createFaq: (companyId: string, gameId: string, data: FAQCreate, params: RequestParams = {}) =>
      this.request<FAQ, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/faqs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags FAQs
     * @name GetFaq
     * @summary Get Faq
     * @request GET:/v1/companies/{company_id}/games/{game_id}/faqs/{faq_id}
     * @secure
     */
    getFaq: (companyId: string, gameId: string, faqId: string, params: RequestParams = {}) =>
      this.request<FAQ, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/faqs/${faqId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags FAQs
     * @name UpdateFaq
     * @summary Update Faq
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/faqs/{faq_id}
     * @secure
     */
    updateFaq: (companyId: string, gameId: string, faqId: string, data: FAQUpdate, params: RequestParams = {}) =>
      this.request<FAQ, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/faqs/${faqId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags FAQs
     * @name DeleteFaq
     * @summary Delete Faq
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/faqs/{faq_id}
     * @secure
     */
    deleteFaq: (companyId: string, gameId: string, faqId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/faqs/${faqId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Return list of game tasks to complete by a company admin.
     *
     * @tags Tasks
     * @name GetGameTasks
     * @summary Get Game Tasks
     * @request GET:/v1/companies/{company_id}/games/{game_id}/tasks
     * @secure
     */
    getGameTasks: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * The limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 200
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<GameTaskRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/tasks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Items
     * @name GetItems
     * @summary Get Items
     * @request GET:/v1/companies/{company_id}/games/{game_id}/items
     * @secure
     */
    getItems: (
      companyId: string,
      gameId: string,
      query?: {
        /** Filter by item type */
        type?: ItemType
        /** Filter by item state */
        state?: ResourceState
        /**
         * Ids
         * Filter by ids
         */
        ids?: string
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<ItemRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/items`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new item.
     *
     * @tags Items
     * @name CreateItem
     * @summary Create Item
     * @request POST:/v1/companies/{company_id}/games/{game_id}/items
     * @secure
     */
    createItem: (companyId: string, gameId: string, data: ItemCreate, params: RequestParams = {}) =>
      this.request<Item, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/items`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes multiple items.
     *
     * @tags Items
     * @name BulkDeleteItems
     * @summary Bulk Delete Items
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/items
     * @secure
     */
    bulkDeleteItems: (companyId: string, gameId: string, data: ItemBulkDelete[], params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/items`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Updates multiple items.
     *
     * @tags Items
     * @name BulkUpdateItems
     * @summary Bulk Update Items
     * @request PATCH:/v1/companies/{company_id}/games/{game_id}/items
     * @secure
     */
    bulkUpdateItems: (companyId: string, gameId: string, data: ItemBulkUpdate[], params: RequestParams = {}) =>
      this.request<ItemRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/items`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the count of items.
     *
     * @tags Items
     * @name GetItemsCount
     * @summary Get Items Count
     * @request GET:/v1/companies/{company_id}/games/{game_id}/items/count
     * @secure
     */
    getItemsCount: (
      companyId: string,
      gameId: string,
      query?: {
        /** Filter by item state */
        state?: ResourceState
      },
      params: RequestParams = {},
    ) =>
      this.request<TotalCountResponse, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/items/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a single item.
     *
     * @tags Items
     * @name GetItem
     * @summary Get Item
     * @request GET:/v1/companies/{company_id}/games/{game_id}/items/{item_id}
     * @secure
     */
    getItem: (companyId: string, gameId: string, itemId: string, params: RequestParams = {}) =>
      this.request<ItemRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/items/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates an item.
     *
     * @tags Items
     * @name UpdateItem
     * @summary Update Item
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/items/{item_id}
     * @secure
     */
    updateItem: (companyId: string, gameId: string, itemId: string, data: ItemUpdate, params: RequestParams = {}) =>
      this.request<ItemRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/items/${itemId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes an item.
     *
     * @tags Items
     * @name DeleteItem
     * @summary Delete Item
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/items/{item_id}
     * @secure
     */
    deleteItem: (companyId: string, gameId: string, itemId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/items/${itemId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Translates an item.
     *
     * @tags Items
     * @name TranslateItem
     * @summary Translate Item
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/items/{item_id}/translations/{locale}
     * @secure
     */
    translateItem: (
      locale: Locale,
      companyId: string,
      gameId: string,
      itemId: string,
      data: ItemTranslate,
      params: RequestParams = {},
    ) =>
      this.request<ItemRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/items/${itemId}/translations/${locale}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Item Images
     * @name GetItemImages
     * @summary Get Item Images
     * @request GET:/v1/companies/{company_id}/games/{game_id}/item_images
     * @secure
     */
    getItemImages: (
      companyId: string,
      gameId: string,
      query: {
        /**
         * Item Id
         * Filter by Item ID
         */
        item_id: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ItemImageRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_images`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Item Images
     * @name CreateItemImage
     * @summary Create Item Image
     * @request POST:/v1/companies/{company_id}/games/{game_id}/item_images
     * @secure
     */
    createItemImage: (companyId: string, gameId: string, data: ItemImageCreate, params: RequestParams = {}) =>
      this.request<ItemImageRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_images`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Item Images
     * @name GetItemImage
     * @summary Get Item Image
     * @request GET:/v1/companies/{company_id}/games/{game_id}/item_images/{item_image_id}
     * @secure
     */
    getItemImage: (companyId: string, gameId: string, itemImageId: string, params: RequestParams = {}) =>
      this.request<ItemImageRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_images/${itemImageId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Item Images
     * @name UpdateItemImage
     * @summary Update Item Image
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/item_images/{item_image_id}
     * @secure
     */
    updateItemImage: (
      companyId: string,
      gameId: string,
      itemImageId: string,
      data: ItemImageUpdate,
      params: RequestParams = {},
    ) =>
      this.request<ItemImageRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_images/${itemImageId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Item Images
     * @name DeleteItemImage
     * @summary Delete Item Image
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/item_images/{item_image_id}
     * @secure
     */
    deleteItemImage: (companyId: string, gameId: string, itemImageId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_images/${itemImageId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Returns a list of leaderboards.
     *
     * @tags Leaderboards
     * @name GetLeaderboards
     * @summary Get Leaderboards
     * @request GET:/v1/companies/{company_id}/games/{game_id}/leaderboards
     * @secure
     */
    getLeaderboards: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<LeaderboardRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/leaderboards`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new leaderboard.
     *
     * @tags Leaderboards
     * @name CreateLeaderboard
     * @summary Create Leaderboard
     * @request POST:/v1/companies/{company_id}/games/{game_id}/leaderboards
     * @secure
     */
    createLeaderboard: (companyId: string, gameId: string, data: LeaderboardCreate, params: RequestParams = {}) =>
      this.request<LeaderboardRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/leaderboards`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a single leaderboard.
     *
     * @tags Leaderboards
     * @name GetLeaderboard
     * @summary Get Leaderboard
     * @request GET:/v1/companies/{company_id}/games/{game_id}/leaderboards/{leaderboard_id}
     * @secure
     */
    getLeaderboard: (companyId: string, gameId: string, leaderboardId: string, params: RequestParams = {}) =>
      this.request<LeaderboardRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/leaderboards/${leaderboardId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates a leaderboard.
     *
     * @tags Leaderboards
     * @name UpdateLeaderboard
     * @summary Update Leaderboard
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/leaderboards/{leaderboard_id}
     * @secure
     */
    updateLeaderboard: (
      companyId: string,
      gameId: string,
      leaderboardId: string,
      data: LeaderboardUpdate,
      params: RequestParams = {},
    ) =>
      this.request<LeaderboardRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/leaderboards/${leaderboardId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes a leaderboard.
     *
     * @tags Leaderboards
     * @name DeleteLeaderboard
     * @summary Delete Leaderboard
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/leaderboards/{leaderboard_id}
     * @secure
     */
    deleteLeaderboard: (companyId: string, gameId: string, leaderboardId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/leaderboards/${leaderboardId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Returns a list of leaderboard members.
     *
     * @tags Leaderboards
     * @name GetLeaderboardMembers
     * @summary Get Leaderboard Members
     * @request GET:/v1/companies/{company_id}/games/{game_id}/leaderboards/{leaderboard_id}/members
     * @secure
     */
    getLeaderboardMembers: (
      companyId: string,
      gameId: string,
      leaderboardId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<LeaderboardMemberRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/leaderboards/${leaderboardId}/members`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leaderboards
     * @name UpdateLeaderboardMember
     * @summary Update Leaderboard Member
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/leaderboards/{leaderboard_id}/members/{member_id}
     * @secure
     */
    updateLeaderboardMember: (
      memberId: string,
      companyId: string,
      gameId: string,
      leaderboardId: string,
      data: LeaderboardMemberUpdate,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/leaderboards/${leaderboardId}/members/${memberId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Translates a leaderboard.
     *
     * @tags Leaderboards
     * @name TranslateLeaderboard
     * @summary Translate Leaderboard
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/leaderboards/{leaderboard_id}/translations/{locale}
     * @secure
     */
    translateLeaderboard: (
      locale: Locale,
      companyId: string,
      gameId: string,
      leaderboardId: string,
      data: LeaderboardTranslate,
      params: RequestParams = {},
    ) =>
      this.request<LeaderboardRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/leaderboards/${leaderboardId}/translations/${locale}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of news posts.
     *
     * @tags News
     * @name GetNewsList
     * @summary Get News List
     * @request GET:/v1/companies/{company_id}/games/{game_id}/news
     * @secure
     */
    getNewsList: (
      companyId: string,
      gameId: string,
      query?: {
        /** An enumeration. */
        category?: NewsCategory
        /** An enumeration. */
        group?: NewsGroup
        /** An enumeration. */
        sort_field?: NewsSortField
        /** @default "asc" */
        sort_order?: SortOrder
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<NewsRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/news`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new news post.
     *
     * @tags News
     * @name CreateNews
     * @summary Create News
     * @request POST:/v1/companies/{company_id}/games/{game_id}/news
     * @secure
     */
    createNews: (companyId: string, gameId: string, data: NewsCreate, params: RequestParams = {}) =>
      this.request<NewsRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/news`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes multiple news.
     *
     * @tags News
     * @name BulkDeleteNews
     * @summary Bulk Delete News
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/news
     * @secure
     */
    bulkDeleteNews: (companyId: string, gameId: string, data: NewsBulkDelete[], params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/news`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Updates multiple news.
     *
     * @tags News
     * @name BulkUpdateNews
     * @summary Bulk Update News
     * @request PATCH:/v1/companies/{company_id}/games/{game_id}/news
     * @secure
     */
    bulkUpdateNews: (companyId: string, gameId: string, data: NewsBulkUpdate[], params: RequestParams = {}) =>
      this.request<NewsRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/news`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a single news post.
     *
     * @tags News
     * @name GetNewsPost
     * @summary Get News Post
     * @request GET:/v1/companies/{company_id}/games/{game_id}/news/{news_id}
     * @secure
     */
    getNewsPost: (companyId: string, gameId: string, newsId: string, params: RequestParams = {}) =>
      this.request<NewsRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/news/${newsId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates a news post.
     *
     * @tags News
     * @name UpdateNews
     * @summary Update News
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/news/{news_id}
     * @secure
     */
    updateNews: (companyId: string, gameId: string, newsId: string, data: NewsUpdate, params: RequestParams = {}) =>
      this.request<NewsRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/news/${newsId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes a news post.
     *
     * @tags News
     * @name DeleteNews
     * @summary Delete News
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/news/{news_id}
     * @secure
     */
    deleteNews: (companyId: string, gameId: string, newsId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/news/${newsId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Translates a news post.
     *
     * @tags News
     * @name TranslateNews
     * @summary Translate News
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/news/{news_id}/translations/{locale}
     * @secure
     */
    translateNews: (
      locale: Locale,
      companyId: string,
      gameId: string,
      newsId: string,
      data: NewsTranslate,
      params: RequestParams = {},
    ) =>
      this.request<NewsRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/news/${newsId}/translations/${locale}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name GetOrder
     * @summary Get Order
     * @request GET:/v1/companies/{company_id}/games/{game_id}/orders/{order_id}
     * @secure
     */
    getOrder: (orderId: string, companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<OrderRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/orders/${orderId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name GetPages
     * @summary Get Pages
     * @request GET:/v1/companies/{company_id}/games/{game_id}/pages
     * @secure
     */
    getPages: (
      companyId: string,
      gameId: string,
      query?: {
        /** Parent Id */
        parent_id?: string
        /**
         * Ignore Parent Id
         * @default false
         */
        ignore_parent_id?: boolean
        types?: PageLayoutType[]
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<PageRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/pages`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name CreatePage
     * @summary Create Page
     * @request POST:/v1/companies/{company_id}/games/{game_id}/pages
     * @secure
     */
    createPage: (companyId: string, gameId: string, data: PageCreate, params: RequestParams = {}) =>
      this.request<PageRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/pages`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes multiple pages.
     *
     * @tags Pages
     * @name BulkDeletePage
     * @summary Bulk Delete Page
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/pages
     * @secure
     */
    bulkDeletePage: (companyId: string, gameId: string, data: PageBulkDelete[], params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/pages`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Updates multiple pages.
     *
     * @tags Pages
     * @name BulkUpdatePage
     * @summary Bulk Update Page
     * @request PATCH:/v1/companies/{company_id}/games/{game_id}/pages
     * @secure
     */
    bulkUpdatePage: (companyId: string, gameId: string, data: PageBulkUpdate[], params: RequestParams = {}) =>
      this.request<PageRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/pages`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name GetPage
     * @summary Get Page
     * @request GET:/v1/companies/{company_id}/games/{game_id}/pages/{page_id}
     * @secure
     */
    getPage: (companyId: string, gameId: string, pageId: string, params: RequestParams = {}) =>
      this.request<PageRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/pages/${pageId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name UpdatePage
     * @summary Update Page
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/pages/{page_id}
     * @secure
     */
    updatePage: (companyId: string, gameId: string, pageId: string, data: PageUpdate, params: RequestParams = {}) =>
      this.request<PageRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/pages/${pageId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name DeletePage
     * @summary Delete Page
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/pages/{page_id}
     * @secure
     */
    deletePage: (companyId: string, gameId: string, pageId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/pages/${pageId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name TranslatePage
     * @summary Translate Page
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/pages/{page_id}/translations/{locale}
     * @secure
     */
    translatePage: (
      locale: Locale,
      companyId: string,
      gameId: string,
      pageId: string,
      data: PageTranslate,
      params: RequestParams = {},
    ) =>
      this.request<PageRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/pages/${pageId}/translations/${locale}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of videos.
     *
     * @tags Videos
     * @name GetVideos
     * @summary Get Videos
     * @request GET:/v1/companies/{company_id}/games/{game_id}/videos
     * @secure
     */
    getVideos: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<VideoRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/videos`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new video.
     *
     * @tags Videos
     * @name CreateVideo
     * @summary Create Video
     * @request POST:/v1/companies/{company_id}/games/{game_id}/videos
     * @secure
     */
    createVideo: (companyId: string, gameId: string, data: VideoCreate, params: RequestParams = {}) =>
      this.request<VideoRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/videos`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a single video.
     *
     * @tags Videos
     * @name GetVideo
     * @summary Get Video
     * @request GET:/v1/companies/{company_id}/games/{game_id}/videos/{video_id}
     * @secure
     */
    getVideo: (companyId: string, gameId: string, videoId: string, params: RequestParams = {}) =>
      this.request<VideoRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/videos/${videoId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates a video.
     *
     * @tags Videos
     * @name UpdateVideo
     * @summary Update Video
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/videos/{video_id}
     * @secure
     */
    updateVideo: (companyId: string, gameId: string, videoId: string, data: VideoUpdate, params: RequestParams = {}) =>
      this.request<VideoRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/videos/${videoId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes a video.
     *
     * @tags Videos
     * @name DeleteVideo
     * @summary Delete Video
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/videos/{video_id}
     * @secure
     */
    deleteVideo: (companyId: string, gameId: string, videoId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/videos/${videoId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Translates a video.
     *
     * @tags Videos
     * @name TranslateVideo
     * @summary Translate Video
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/videos/{video_id}/translations/{locale}
     * @secure
     */
    translateVideo: (
      locale: Locale,
      companyId: string,
      gameId: string,
      videoId: string,
      data: VideoTranslate,
      params: RequestParams = {},
    ) =>
      this.request<VideoRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/videos/${videoId}/translations/${locale}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name GetCampaigns
     * @summary Get Campaigns
     * @request GET:/v1/companies/{company_id}/games/{game_id}/campaigns
     * @secure
     */
    getCampaigns: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<Campaign[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/campaigns`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name CreateCampaign
     * @summary Create Campaign
     * @request POST:/v1/companies/{company_id}/games/{game_id}/campaigns
     * @secure
     */
    createCampaign: (companyId: string, gameId: string, data: CampaignCreate, params: RequestParams = {}) =>
      this.request<Campaign, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/campaigns`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name GetCampaign
     * @summary Get Campaign
     * @request GET:/v1/companies/{company_id}/games/{game_id}/campaigns/{campaign_id}
     * @secure
     */
    getCampaign: (companyId: string, gameId: string, campaignId: string, params: RequestParams = {}) =>
      this.request<Campaign, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/campaigns/${campaignId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name UpdateCampaign
     * @summary Update Campaign
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/campaigns/{campaign_id}
     * @secure
     */
    updateCampaign: (
      companyId: string,
      gameId: string,
      campaignId: string,
      data: CampaignUpdate,
      params: RequestParams = {},
    ) =>
      this.request<Campaign, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/campaigns/${campaignId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name DeleteCampaign
     * @summary Delete Campaign
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/campaigns/{campaign_id}
     * @secure
     */
    deleteCampaign: (companyId: string, gameId: string, campaignId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/campaigns/${campaignId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name GetCampaignGraph
     * @summary Get Campaign Graph
     * @request GET:/v1/companies/{company_id}/games/{game_id}/campaigns/{campaign_id}/graph
     * @secure
     */
    getCampaignGraph: (companyId: string, gameId: string, campaignId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/campaigns/${campaignId}/graph`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name UpdateCampaignGraph
     * @summary Update Campaign Graph
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/campaigns/{campaign_id}/graph
     * @secure
     */
    updateCampaignGraph: (
      companyId: string,
      gameId: string,
      campaignId: string,
      data: GraphRoot,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/campaigns/${campaignId}/graph`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of webhooks.
     *
     * @tags Webhooks
     * @name GetWebhooks
     * @summary Get Webhooks
     * @request GET:/v1/companies/{company_id}/games/{game_id}/webhooks
     * @secure
     */
    getWebhooks: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * The limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 200
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<WebhookRedacted[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/webhooks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new webhook.
     *
     * @tags Webhooks
     * @name CreateWebhook
     * @summary Create Webhook
     * @request POST:/v1/companies/{company_id}/games/{game_id}/webhooks
     * @secure
     */
    createWebhook: (companyId: string, gameId: string, data: WebhookCreate, params: RequestParams = {}) =>
      this.request<WebhookRevealed, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/webhooks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a single webhook.
     *
     * @tags Webhooks
     * @name GetWebhook
     * @summary Get Webhook
     * @request GET:/v1/companies/{company_id}/games/{game_id}/webhooks/{webhook_id}
     * @secure
     */
    getWebhook: (companyId: string, gameId: string, webhookId: string, params: RequestParams = {}) =>
      this.request<WebhookRedacted, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/webhooks/${webhookId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates a webhook.
     *
     * @tags Webhooks
     * @name UpdateWebhook
     * @summary Update Webhook
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/webhooks/{webhook_id}
     * @secure
     */
    updateWebhook: (
      companyId: string,
      gameId: string,
      webhookId: string,
      data: WebhookUpdate,
      params: RequestParams = {},
    ) =>
      this.request<WebhookRedacted, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/webhooks/${webhookId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes a webhook.
     *
     * @tags Webhooks
     * @name DeleteWebhook
     * @summary Delete Webhook
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/webhooks/{webhook_id}
     * @secure
     */
    deleteWebhook: (companyId: string, gameId: string, webhookId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/webhooks/${webhookId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Expires the secret of a webhook.
     *
     * @tags Webhooks
     * @name ExpireWebhookSecret
     * @summary Expire Webhook Secret
     * @request POST:/v1/companies/{company_id}/games/{game_id}/webhooks/{webhook_id}/expire_secret
     * @secure
     */
    expireWebhookSecret: (companyId: string, gameId: string, webhookId: string, params: RequestParams = {}) =>
      this.request<WebhookRevealed, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/webhooks/${webhookId}/expire_secret`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Reveals the secret of a webhook.
     *
     * @tags Webhooks
     * @name RevealWebhookSecret
     * @summary Reveal Webhook Secret
     * @request POST:/v1/companies/{company_id}/games/{game_id}/webhooks/{webhook_id}/reveal
     * @secure
     */
    revealWebhookSecret: (companyId: string, gameId: string, webhookId: string, params: RequestParams = {}) =>
      this.request<WebhookRevealed, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/webhooks/${webhookId}/reveal`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Websites
     * @name GetWebsites
     * @summary Get Websites
     * @request GET:/v1/companies/{company_id}/games/{game_id}/websites
     * @secure
     */
    getWebsites: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<Website[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/websites`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Websites
     * @name CreateWebsite
     * @summary Create Website
     * @request POST:/v1/companies/{company_id}/games/{game_id}/websites
     * @secure
     */
    createWebsite: (companyId: string, gameId: string, data: WebsiteCreate, params: RequestParams = {}) =>
      this.request<Website, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/websites`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Websites
     * @name GetWebsite
     * @summary Get Website
     * @request GET:/v1/companies/{company_id}/games/{game_id}/websites/{website_id}
     * @secure
     */
    getWebsite: (companyId: string, gameId: string, websiteId: string, params: RequestParams = {}) =>
      this.request<WebsiteRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/websites/${websiteId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Websites
     * @name UpdateWebsite
     * @summary Update Website
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/websites/{website_id}
     * @secure
     */
    updateWebsite: (
      companyId: string,
      gameId: string,
      websiteId: string,
      data: WebsiteUpdate,
      params: RequestParams = {},
    ) =>
      this.request<Website, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/websites/${websiteId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Websites
     * @name DeleteWebsite
     * @summary Delete Website
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/websites/{website_id}
     * @secure
     */
    deleteWebsite: (companyId: string, gameId: string, websiteId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/websites/${websiteId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Websites
     * @name AddFeaturedVideo
     * @summary Add Featured Video
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/websites/{website_id}/featured_videos
     * @secure
     */
    addFeaturedVideo: (
      companyId: string,
      gameId: string,
      websiteId: string,
      data: VideoFeaturedAdd,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/websites/${websiteId}/featured_videos`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Websites
     * @name DeleteFeaturedVideo
     * @summary Delete Featured Video
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/websites/{website_id}/featured_videos/{video_id}
     * @secure
     */
    deleteFeaturedVideo: (
      companyId: string,
      gameId: string,
      websiteId: string,
      videoId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/websites/${websiteId}/featured_videos/${videoId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Websites
     * @name TranslateWebsite
     * @summary Translate Website
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/websites/{website_id}/translations/{locale}
     * @secure
     */
    translateWebsite: (
      locale: Locale,
      companyId: string,
      gameId: string,
      websiteId: string,
      data: WebsiteTranslate,
      params: RequestParams = {},
    ) =>
      this.request<WebsiteRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/websites/${websiteId}/translations/${locale}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name RefundOrder
     * @summary Refund Order
     * @request POST:/v1/companies/{company_id}/games/{game_id}/billing/{payment_id}/refund
     * @secure
     */
    refundOrder: (
      paymentId: string,
      companyId: string,
      gameId: string,
      data: RefundRequest,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/billing/${paymentId}/refund`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name RenotifyPayment
     * @summary Renotify Payment
     * @request POST:/v1/companies/{company_id}/games/{game_id}/billing/{payment_id}/renotify
     * @secure
     */
    renotifyPayment: (
      paymentId: string,
      companyId: string,
      gameId: string,
      data: RefundRequest,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/billing/${paymentId}/renotify`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name GetPaymentMethods
     * @summary Get Payment Methods
     * @request GET:/v1/companies/{company_id}/games/{game_id}/billing/payment_methods
     * @secure
     */
    getPaymentMethods: (companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<PaymentMethod[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/billing/payment_methods`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name UpdatePaymentMethods
     * @summary Update Payment Methods
     * @request POST:/v1/companies/{company_id}/games/{game_id}/billing/payment_methods/{id}
     * @secure
     */
    updatePaymentMethods: (
      id: string,
      companyId: string,
      gameId: string,
      data: PaymentMethodSettings,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/billing/payment_methods/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name GetUserPaymentMethods
     * @summary Get User Payment Methods
     * @request GET:/v1/companies/{company_id}/games/{game_id}/billing/payment_methods/{paylink_user_id}
     * @secure
     */
    getUserPaymentMethods: (paylinkUserId: string, companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<UserPaymentMethod[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/billing/payment_methods/${paylinkUserId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name ResendReceiptEmail
     * @summary Resend Receipt Email
     * @request POST:/v1/companies/{company_id}/games/{game_id}/billing/{order_id}/resend_email
     * @secure
     */
    resendReceiptEmail: (companyId: string, gameId: string, orderId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/billing/${orderId}/resend_email`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name GetPayouts
     * @summary Get Payouts
     * @request GET:/v1/companies/{company_id}/games/{game_id}/billing/payouts
     * @secure
     */
    getPayouts: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<Payout[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/billing/payouts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name GetPayoutsByMonth
     * @summary Get Payouts By Month
     * @request GET:/v1/companies/{company_id}/games/{game_id}/billing/payouts/monthly_aggregated
     * @secure
     */
    getPayoutsByMonth: (companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<PayoutMonth[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/billing/payouts/monthly_aggregated`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name GetBalance
     * @summary Get Balance
     * @request GET:/v1/companies/{company_id}/games/{game_id}/billing/balance
     * @secure
     */
    getBalance: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Is Sand Box
         * @default false
         */
        is_sand_box?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<MeasureValue, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/billing/balance`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name MakeReceiptEmailFromPayment
     * @summary Make Receipt Email From Payment
     * @request POST:/v1/companies/{company_id}/games/{game_id}/billing/email_from_payment
     * @secure
     */
    makeReceiptEmailFromPayment: (
      companyId: string,
      gameId: string,
      query: {
        /** Payment Method Caption */
        payment_method_caption: string
      },
      data: PaymentRead,
      params: RequestParams = {},
    ) =>
      this.request<EmailResponse, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/billing/email_from_payment`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name GetReceiptEmail
     * @summary Get Receipt Email
     * @request GET:/v1/companies/{company_id}/games/{game_id}/billing/{order_id}/email
     * @secure
     */
    getReceiptEmail: (companyId: string, gameId: string, orderId: string, params: RequestParams = {}) =>
      this.request<EmailResponse, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/billing/${orderId}/email`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetAllDashboardData
     * @summary Get All Dashboard Data
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/all_data
     * @secure
     */
    getAllDashboardData: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/all_data`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetPayment
     * @summary Get Payment
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/payments/{payment_number}
     * @secure
     */
    getPayment: (paymentNumber: string, companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<PaymentRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/payments/${paymentNumber}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name SearchPayments
     * @summary Search Payments
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/payments
     * @secure
     */
    searchPayments: (
      companyId: string,
      gameId: string,
      query?: {
        /** Search String */
        search_string?: string
        /** Status */
        status?: string
        /** User Id */
        user_id?: string
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<PaymentRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/payments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetRevenue
     * @summary Get Revenue
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/revenue
     * @secure
     */
    getRevenue: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, MeasureValue>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/revenue`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetRevenueByDates
     * @summary Get Revenue By Dates
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/revenue_by_dates
     * @secure
     */
    getRevenueByDates: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, RevenueByDate[]>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/revenue_by_dates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetArpdau
     * @summary Get Arpdau
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/arpdau
     * @secure
     */
    getArpdau: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, MeasureValue>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/arpdau`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetArppu
     * @summary Get Arppu
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/arppu
     * @secure
     */
    getArppu: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, MeasureValue>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/arppu`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetDau
     * @summary Get Dau
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/dau
     * @secure
     */
    getDau: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, MeasureValue>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/dau`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetMau
     * @summary Get Mau
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/mau
     * @secure
     */
    getMau: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, MeasureValue>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/mau`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetConversion
     * @summary Get Conversion
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/conversion
     * @secure
     */
    getConversion: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, MeasureValue>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/conversion`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetPaymentsSuccessFailByDates
     * @summary Get Payments Success Fail By Dates
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/payments_success_fail_by_dates
     * @secure
     */
    getPaymentsSuccessFailByDates: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, PaymentSuccessFail[]>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/payments_success_fail_by_dates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetPaymentsSuccessPercent
     * @summary Get Payments Success Percent
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/payments_success_percent
     * @secure
     */
    getPaymentsSuccessPercent: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, MeasureValue>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/payments_success_percent`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetRevenueByDateDevice
     * @summary Get Revenue By Date Device
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/revenue_by_date_device
     * @secure
     */
    getRevenueByDateDevice: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, SeriesValue[]>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/revenue_by_date_device`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetConversionGameHubFirstPurchaseByDate
     * @summary Get Conversion Game Hub First Purchase By Date
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/conversion_game_hub_first_purchase_by_date
     * @secure
     */
    getConversionGameHubFirstPurchaseByDate: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, ConversionFirstPurchase>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/conversion_game_hub_first_purchase_by_date`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetRevenueByCountry
     * @summary Get Revenue By Country
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/revenue_by_country
     * @secure
     */
    getRevenueByCountry: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, PointValue[]>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/revenue_by_country`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetSalesByDate
     * @summary Get Sales By Date
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/sales_by_date
     * @secure
     */
    getSalesByDate: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, PointSeriesValue[]>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/sales_by_date`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetTopItemSum
     * @summary Get Top Item Sum
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/top_items_sum
     * @secure
     */
    getTopItemSum: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, MeasureValue>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/top_items_sum`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetTopItems
     * @summary Get Top Items
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/top_items
     * @secure
     */
    getTopItems: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, PointSeriesValue[]>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/top_items`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetBestTriggersByDate
     * @summary Get Best Triggers By Date
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/best_triggers_by_date
     * @secure
     */
    getBestTriggersByDate: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, BestTriggersData>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/best_triggers_by_date`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetSankeyTopSku
     * @summary Get Sankey Top Sku
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/sankey_top_sku
     * @secure
     */
    getSankeyTopSku: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, SankeyTopSkuData>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/sankey_top_sku`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name ExportTransactions
     * @summary Export Transactions
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/export_transactions
     * @secure
     */
    exportTransactions: (
      companyId: string,
      gameId: string,
      query: {
        /** Start */
        start: string
        /** End */
        end: string
      },
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/export_transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetTopClaimedItems
     * @summary Get Top Claimed Items
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/top_claimed_items
     * @secure
     */
    getTopClaimedItems: (
      companyId: string,
      gameId: string,
      query?: {
        /** An enumeration. */
        item_type?: ItemClaimedType
        /**
         * Limit
         * @default 5
         */
        limit?: number
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<PointValue[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/top_claimed_items`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetCampaignPlayersCount
     * @summary Get Campaign Players Count
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/campaign_players_count/{campaign_id}
     * @secure
     */
    getCampaignPlayersCount: (
      campaignId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, MeasureValue>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/campaign_players_count/${campaignId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetCampaignPlayersCountByDate
     * @summary Get Campaign Players Count By Date
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/campaign_players_count_by_date/{campaign_id}
     * @secure
     */
    getCampaignPlayersCountByDate: (
      campaignId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, PointValue[]>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/campaign_players_count_by_date/${campaignId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetCampaignTotalRevenueByDate
     * @summary Get Campaign Total Revenue By Date
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/campaign_total_revenue_by_date/{campaign_id}
     * @secure
     */
    getCampaignTotalRevenueByDate: (
      campaignId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, PointValue[]>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/campaign_total_revenue_by_date/${campaignId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetCampaignTotalRevenue
     * @summary Get Campaign Total Revenue
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/campaign_total_revenue/{campaign_id}
     * @secure
     */
    getCampaignTotalRevenue: (
      campaignId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, MeasureValue>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/campaign_total_revenue/${campaignId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetCampaignAvgCheck
     * @summary Get Campaign Avg Check
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/campaign_avg_check/{campaign_id}
     * @secure
     */
    getCampaignAvgCheck: (
      campaignId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, MeasureValue>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/campaign_avg_check/${campaignId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetCampaignAvgCheckByDate
     * @summary Get Campaign Avg Check By Date
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/campaign_avg_check_by_date/{campaign_id}
     * @secure
     */
    getCampaignAvgCheckByDate: (
      campaignId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, PointValue[]>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/campaign_avg_check_by_date/${campaignId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetCampaignPayingUsersCount
     * @summary Get Campaign Paying Users Count
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/campaign_paying_users_count/{campaign_id}
     * @secure
     */
    getCampaignPayingUsersCount: (
      campaignId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, MeasureValue>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/campaign_paying_users_count/${campaignId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetAbTestData
     * @summary Get Ab Test Data
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/ab_test_data/{campaign_id}
     * @secure
     */
    getAbTestData: (
      campaignId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, ABTestPoint[]>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/ab_test_data/${campaignId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetCampaignPayingUsersCountByDate
     * @summary Get Campaign Paying Users Count By Date
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/campaign_paying_users_count_by_date/{campaign_id}
     * @secure
     */
    getCampaignPayingUsersCountByDate: (
      campaignId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, PointValue[]>, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/campaign_paying_users_count_by_date/${campaignId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetAllCampaignData
     * @summary Get All Campaign Data
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/all_campaign_data/{campaign_id}
     * @secure
     */
    getAllCampaignData: (
      campaignId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/all_campaign_data/${campaignId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetCampaignCommunicationStat
     * @summary Get Campaign Communication Stat
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/campaign_communication_stat/{campaign_id}
     * @secure
     */
    getCampaignCommunicationStat: (
      campaignId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count Days
         * @max 30
         * @default 30
         */
        last_count_days?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** An enumeration. */
        type?: CommunicationType
        /** Is Read */
        is_read?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<MeasureValue, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/campaign_communication_stat/${campaignId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name GetCampaignCommunicationStatByDate
     * @summary Get Campaign Communication Stat By Date
     * @request GET:/v1/companies/{company_id}/games/{game_id}/reports/campaign_communication_stat_by_date/{campaign_id}
     * @secure
     */
    getCampaignCommunicationStatByDate: (
      campaignId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Last Count Days
         * @max 30
         * @default 30
         */
        last_count_days?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** An enumeration. */
        type?: CommunicationType
        /** Is Read */
        is_read?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<PointValue[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/reports/campaign_communication_stat_by_date/${campaignId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game settings
     * @name GetGameSettings
     * @summary Get Game Settings
     * @request GET:/v1/companies/{company_id}/games/{game_id}/game_settings
     * @secure
     */
    getGameSettings: (companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<GameSettings, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/game_settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game settings
     * @name CreateGameSettings
     * @summary Create Game Settings
     * @request POST:/v1/companies/{company_id}/games/{game_id}/game_settings
     * @secure
     */
    createGameSettings: (companyId: string, gameId: string, data: GameSettingsCreate, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/game_settings`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game settings
     * @name UpdateGameSettings
     * @summary Update Game Settings
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/game_settings/{settings_id}
     * @secure
     */
    updateGameSettings: (
      settingsId: string,
      companyId: string,
      gameId: string,
      data: GameSettingsUpdate,
      params: RequestParams = {},
    ) =>
      this.request<GameSettings, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/game_settings/${settingsId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game settings
     * @name DeleteGameSettings
     * @summary Delete Game Settings
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/game_settings/{settings_id}
     * @secure
     */
    deleteGameSettings: (settingsId: string, companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/game_settings/${settingsId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game settings
     * @name UpdateGameSettingsFeatureFlags
     * @summary Update Game Settings Feature Flags
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/game_settings/{settings_id}/feature_flags
     * @secure
     */
    updateGameSettingsFeatureFlags: (
      settingsId: string,
      companyId: string,
      gameId: string,
      data: GameSettingsUpdateFeatures,
      params: RequestParams = {},
    ) =>
      this.request<GameSettings, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/game_settings/${settingsId}/feature_flags`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUser
     * @summary Get User
     * @request GET:/v1/companies/{company_id}/games/{game_id}/user/{user_id}
     * @secure
     */
    getUser: (companyId: string, gameId: string, userId: string, params: RequestParams = {}) =>
      this.request<UserRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/user/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UpdateUser
     * @summary Update User
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/user/{user_id}
     * @secure
     */
    updateUser: (companyId: string, gameId: string, userId: string, data: UserUpdate, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/user/${userId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetFullUser
     * @summary Get Full User
     * @request GET:/v1/companies/{company_id}/games/{game_id}/user/{user_id}/full
     * @secure
     */
    getFullUser: (companyId: string, gameId: string, userId: string, params: RequestParams = {}) =>
      this.request<User, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/user/${userId}/full`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUsers
     * @summary Get Users
     * @request GET:/v1/companies/{company_id}/games/{game_id}/user
     * @secure
     */
    getUsers: (
      companyId: string,
      gameId: string,
      query?: {
        /** Search String */
        search_string?: string
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<UserRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/user`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of the nearest local prices by price match.
     *
     * @tags Item Prices
     * @name SearchPrices
     * @summary Search Prices
     * @request GET:/v1/companies/{company_id}/games/{game_id}/item_prices/{item_id}/search
     * @secure
     */
    searchPrices: (
      companyId: string,
      gameId: string,
      itemId: string,
      query: {
        /**
         * Price
         * @exclusiveMin 0
         * @max 5000000
         */
        price: number
      },
      params: RequestParams = {},
    ) =>
      this.request<Price[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_prices/${itemId}/search`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of local prices by price point.
     *
     * @tags Item Prices
     * @name GetPrices
     * @summary Get Prices
     * @request GET:/v1/companies/{company_id}/games/{game_id}/item_prices/{item_id}
     * @secure
     */
    getPrices: (
      companyId: string,
      gameId: string,
      itemId: string,
      query: {
        /**
         * Price Point
         * @exclusiveMin 0
         * @max 5000000
         */
        price_point: number
      },
      params: RequestParams = {},
    ) =>
      this.request<Price[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_prices/${itemId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update price points for specified item.
     *
     * @tags Item Prices
     * @name CreateOrUpdatePrices
     * @summary Create Or Update Prices
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/item_prices/{item_id}
     * @secure
     */
    createOrUpdatePrices: (
      companyId: string,
      gameId: string,
      itemId: string,
      data: PriceCreate[],
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_prices/${itemId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Segments
     * @name GetSegment
     * @summary Get Segment
     * @request GET:/v1/companies/{company_id}/games/{game_id}/segments/{segment_id}
     * @secure
     */
    getSegment: (companyId: string, gameId: string, segmentId: string, params: RequestParams = {}) =>
      this.request<SegmentRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/segments/${segmentId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Segments
     * @name UpdateSegment
     * @summary Update Segment
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/segments/{segment_id}
     * @secure
     */
    updateSegment: (
      companyId: string,
      gameId: string,
      segmentId: string,
      data: SegmentUpdate,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/segments/${segmentId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Segments
     * @name DeleteSegment
     * @summary Delete Segment
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/segments/{segment_id}
     * @secure
     */
    deleteSegment: (companyId: string, gameId: string, segmentId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/segments/${segmentId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Segments
     * @name GetSegments
     * @summary Get Segments
     * @request GET:/v1/companies/{company_id}/games/{game_id}/segments
     * @secure
     */
    getSegments: (
      companyId: string,
      gameId: string,
      query?: {
        /** Segment Group Id */
        segment_group_id?: string
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<SegmentRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/segments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Segments
     * @name CreateSegment
     * @summary Create Segment
     * @request POST:/v1/companies/{company_id}/games/{game_id}/segments
     * @secure
     */
    createSegment: (companyId: string, gameId: string, data: SegmentCreate, params: RequestParams = {}) =>
      this.request<SegmentRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/segments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Segments
     * @name GetUserSegments
     * @summary Get User Segments
     * @request GET:/v1/companies/{company_id}/games/{game_id}/user_segments/{user_id}
     * @secure
     */
    getUserSegments: (companyId: string, gameId: string, userId: string, params: RequestParams = {}) =>
      this.request<UserSegmentRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/user_segments/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Segments
     * @name UpdateUserSegments
     * @summary Update User Segments
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/user_segments/{user_id}
     * @secure
     */
    updateUserSegments: (
      companyId: string,
      gameId: string,
      userId: string,
      data: UserSegmentUpdate,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/user_segments/${userId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Segment Groups
     * @name GetSegmentGroup
     * @summary Get Segment Group
     * @request GET:/v1/companies/{company_id}/games/{game_id}/segment_groups/{segment_group_id}
     * @secure
     */
    getSegmentGroup: (companyId: string, gameId: string, segmentGroupId: string, params: RequestParams = {}) =>
      this.request<SegmentGroupRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/segment_groups/${segmentGroupId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Segment Groups
     * @name UpdateSegmentGroup
     * @summary Update Segment Group
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/segment_groups/{segment_group_id}
     * @secure
     */
    updateSegmentGroup: (
      companyId: string,
      gameId: string,
      segmentGroupId: string,
      data: SegmentGroupUpdate,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/segment_groups/${segmentGroupId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Segment Groups
     * @name CalculateStaticSegmentGroup
     * @summary Calculate Static Segment Group
     * @request POST:/v1/companies/{company_id}/games/{game_id}/segment_groups/{segment_group_id}
     * @secure
     */
    calculateStaticSegmentGroup: (
      companyId: string,
      gameId: string,
      segmentGroupId: string,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/segment_groups/${segmentGroupId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Segment Groups
     * @name DeleteSegmentGroup
     * @summary Delete Segment Group
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/segment_groups/{segment_group_id}
     * @secure
     */
    deleteSegmentGroup: (companyId: string, gameId: string, segmentGroupId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/segment_groups/${segmentGroupId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Segment Groups
     * @name GetSegmentGroupGraph
     * @summary Get Segment Group Graph
     * @request GET:/v1/companies/{company_id}/games/{game_id}/segment_groups/{segment_group_id}/graph
     * @secure
     */
    getSegmentGroupGraph: (companyId: string, gameId: string, segmentGroupId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/segment_groups/${segmentGroupId}/graph`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Segment Groups
     * @name GetSegmentGroups
     * @summary Get Segment Groups
     * @request GET:/v1/companies/{company_id}/games/{game_id}/segment_groups
     * @secure
     */
    getSegmentGroups: (companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<SegmentGroupReadStat[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/segment_groups`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Segment Groups
     * @name CreateSegmentGroup
     * @summary Create Segment Group
     * @request POST:/v1/companies/{company_id}/games/{game_id}/segment_groups
     * @secure
     */
    createSegmentGroup: (companyId: string, gameId: string, data: SegmentGroupCreate, params: RequestParams = {}) =>
      this.request<SegmentGroupRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/segment_groups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupons
     * @name GetUserCoupons
     * @summary Get User Coupons
     * @request GET:/v1/companies/{company_id}/games/{game_id}/coupons/user/{user_id}
     * @secure
     */
    getUserCoupons: (
      userId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<UserCouponRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/coupons/user/${userId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Email Templates
     * @name GetEmailTemplates
     * @summary Get Email Templates
     * @request GET:/v1/companies/{company_id}/games/{game_id}/email_templates
     * @secure
     */
    getEmailTemplates: (companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<EmailTemplate[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/email_templates`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Check if webhook is working by sending a test payload.
     *
     * @tags Webhooks
     * @name CheckWebhook
     * @summary Check Webhook
     * @request POST:/v1/companies/{company_id}/games/{game_id}/webhooks/{webhook_id}/check
     * @secure
     */
    checkWebhook: (
      companyId: string,
      gameId: string,
      webhookId: string,
      data: CheckWebhookRequest,
      params: RequestParams = {},
    ) =>
      this.request<CheckWebhookResponse, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/webhooks/${webhookId}/check`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Translate all translations.
     *
     * @tags Translations
     * @name ScheduleTranslateAllJob
     * @summary Schedule Translate All Job
     * @request POST:/v1/companies/{company_id}/games/{game_id}/translation_jobs/translate_all
     * @secure
     */
    scheduleTranslateAllJob: (
      companyId: string,
      gameId: string,
      data: ScheduleJobRequest,
      params: RequestParams = {},
    ) =>
      this.request<ScheduleJobResponse, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/translation_jobs/translate_all`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Sync all translations.
     *
     * @tags Translations
     * @name ScheduleSyncAllJob
     * @summary Schedule Sync All Job
     * @request POST:/v1/companies/{company_id}/games/{game_id}/translation_jobs/sync_all
     * @secure
     */
    scheduleSyncAllJob: (companyId: string, gameId: string, data: ScheduleJobRequest, params: RequestParams = {}) =>
      this.request<ScheduleJobResponse, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/translation_jobs/sync_all`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of translations.
     *
     * @tags Translations
     * @name GetTranslations
     * @summary Get Translations
     * @request GET:/v1/companies/{company_id}/games/{game_id}/translations
     * @secure
     */
    getTranslations: (
      companyId: string,
      gameId: string,
      query: {
        /** Locale Id */
        locale_id: string
        /** Q */
        q?: string
        /** An enumeration. */
        object_type?: ObjectType
        /** An enumeration. */
        state?: State
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Starting After
         * The cursor for pagination
         */
        starting_after?: string
        /**
         * Ending Before
         * The cursor for pagination
         */
        ending_before?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<InfinitePaginatedResponseTranslationHtmlStrippedRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/translations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new translation.
     *
     * @tags Translations
     * @name CreateTranslation
     * @summary Create Translation
     * @request POST:/v1/companies/{company_id}/games/{game_id}/translations
     * @secure
     */
    createTranslation: (companyId: string, gameId: string, data: TranslationCreate, params: RequestParams = {}) =>
      this.request<TranslationRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/translations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a single translation.
     *
     * @tags Translations
     * @name GetTranslation
     * @summary Get Translation
     * @request GET:/v1/companies/{company_id}/games/{game_id}/translations/{translation_id}
     * @secure
     */
    getTranslation: (companyId: string, gameId: string, translationId: string, params: RequestParams = {}) =>
      this.request<TranslationRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/translations/${translationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates a translation.
     *
     * @tags Translations
     * @name UpdateTranslation
     * @summary Update Translation
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/translations/{translation_id}
     * @secure
     */
    updateTranslation: (
      companyId: string,
      gameId: string,
      translationId: string,
      data: TranslationUpdate,
      params: RequestParams = {},
    ) =>
      this.request<TranslationRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/translations/${translationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of locales.
     *
     * @tags Locales
     * @name GetLocales
     * @summary Get Locales
     * @request GET:/v1/companies/{company_id}/games/{game_id}/locales
     * @secure
     */
    getLocales: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<LocaleRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/locales`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new locale.
     *
     * @tags Locales
     * @name CreateLocale
     * @summary Create Locale
     * @request POST:/v1/companies/{company_id}/games/{game_id}/locales
     * @secure
     */
    createLocale: (companyId: string, gameId: string, data: LocaleCreate, params: RequestParams = {}) =>
      this.request<LocaleRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/locales`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a single locale.
     *
     * @tags Locales
     * @name GetLocale
     * @summary Get Locale
     * @request GET:/v1/companies/{company_id}/games/{game_id}/locales/{locale_id}
     * @secure
     */
    getLocale: (companyId: string, gameId: string, localeId: string, params: RequestParams = {}) =>
      this.request<LocaleRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/locales/${localeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates a locale.
     *
     * @tags Locales
     * @name UpdateLocale
     * @summary Update Locale
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/locales/{locale_id}
     * @secure
     */
    updateLocale: (
      companyId: string,
      gameId: string,
      localeId: string,
      data: LocaleUpdate,
      params: RequestParams = {},
    ) =>
      this.request<LocaleRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/locales/${localeId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes a locale.
     *
     * @tags Locales
     * @name DeleteLocale
     * @summary Delete Locale
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/locales/{locale_id}
     * @secure
     */
    deleteLocale: (companyId: string, gameId: string, localeId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/locales/${localeId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Campaigns
     * @name GetCampaignUsers
     * @summary Get Campaign Users
     * @request GET:/v1/companies/{company_id}/games/{game_id}/campaigns_user/{campaign_id}/users
     * @secure
     */
    getCampaignUsers: (
      companyId: string,
      gameId: string,
      campaignId: string,
      query?: {
        /** Search String */
        search_string?: string
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<CampaignUserHistoryItem[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/campaigns_user/${campaignId}/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Campaigns
     * @name GetUserGraph
     * @summary Get User Graph
     * @request GET:/v1/companies/{company_id}/games/{game_id}/campaigns_user/{campaign_id}/{user_campaign_id}
     * @secure
     */
    getUserGraph: (
      userCampaignId: string,
      companyId: string,
      gameId: string,
      campaignId: string,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/campaigns_user/${campaignId}/${userCampaignId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Onboarding Progress
     * @name GetOnboardingProgress
     * @summary Get Onboarding Progress
     * @request GET:/v1/companies/{company_id}/games/{game_id}/onboarding_progress
     * @secure
     */
    getOnboardingProgress: (companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<GenerationProgressState, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/onboarding_progress`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stores
     * @name GetStore
     * @summary Get Store
     * @request GET:/v1/companies/{company_id}/games/{game_id}/stores/{store_id}
     * @secure
     */
    getStore: (companyId: string, gameId: string, storeId: string, params: RequestParams = {}) =>
      this.request<StoreRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/stores/${storeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stores
     * @name UpdateStore
     * @summary Update Store
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/stores/{store_id}
     * @secure
     */
    updateStore: (companyId: string, gameId: string, storeId: string, data: StoreUpdate, params: RequestParams = {}) =>
      this.request<StoreRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/stores/${storeId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stores
     * @name DeleteStore
     * @summary Delete Store
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/stores/{store_id}
     * @secure
     */
    deleteStore: (companyId: string, gameId: string, storeId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/stores/${storeId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stores
     * @name GetStores
     * @summary Get Stores
     * @request GET:/v1/companies/{company_id}/games/{game_id}/stores
     * @secure
     */
    getStores: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<StoreReadStat[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/stores`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stores
     * @name CreateStore
     * @summary Create Store
     * @request POST:/v1/companies/{company_id}/games/{game_id}/stores
     * @secure
     */
    createStore: (companyId: string, gameId: string, data: StoreCreate, params: RequestParams = {}) =>
      this.request<StoreRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/stores`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes multiple stores.
     *
     * @tags Stores
     * @name BulkDeleteStores
     * @summary Bulk Delete Stores
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/stores
     * @secure
     */
    bulkDeleteStores: (companyId: string, gameId: string, data: StoreBulkDelete[], params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/stores`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store Items
     * @name GetStoreItems
     * @summary Get Store Items
     * @request GET:/v1/companies/{company_id}/games/{game_id}/store_items/{store_id}
     * @secure
     */
    getStoreItems: (companyId: string, gameId: string, storeId: string, params: RequestParams = {}) =>
      this.request<StoreItemRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/store_items/${storeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store Items
     * @name CreateStoreItem
     * @summary Create Store Item
     * @request POST:/v1/companies/{company_id}/games/{game_id}/store_items/{store_id}
     * @secure
     */
    createStoreItem: (
      companyId: string,
      gameId: string,
      storeId: string,
      data: StoreItemCreate,
      params: RequestParams = {},
    ) =>
      this.request<StoreItemRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/store_items/${storeId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes multiple store items.
     *
     * @tags Store Items
     * @name BulkDeleteStoreItems
     * @summary Bulk Delete Store Items
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/store_items/{store_id}
     * @secure
     */
    bulkDeleteStoreItems: (
      companyId: string,
      gameId: string,
      storeId: string,
      data: StoreItemBulkDelete[],
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/store_items/${storeId}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Updates multiple store items.
     *
     * @tags Store Items
     * @name BulkUpdateStoreItems
     * @summary Bulk Update Store Items
     * @request PATCH:/v1/companies/{company_id}/games/{game_id}/store_items/{store_id}
     * @secure
     */
    bulkUpdateStoreItems: (
      companyId: string,
      gameId: string,
      storeId: string,
      data: StoreItemBulkUpdate[],
      params: RequestParams = {},
    ) =>
      this.request<StoreItemRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/store_items/${storeId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store Items
     * @name UpdateStoreItem
     * @summary Update Store Item
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/store_items/{store_id}/{store_item_id}
     * @secure
     */
    updateStoreItem: (
      storeItemId: string,
      companyId: string,
      gameId: string,
      storeId: string,
      data: StoreItemUpdate,
      params: RequestParams = {},
    ) =>
      this.request<StoreItemRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/store_items/${storeId}/${storeItemId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store Items
     * @name DeleteStoreItem
     * @summary Delete Store Item
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/store_items/{store_id}/{store_item_id}
     * @secure
     */
    deleteStoreItem: (
      storeItemId: string,
      companyId: string,
      gameId: string,
      storeId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/store_items/${storeId}/${storeItemId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store Graphs
     * @name GetStoreGraph
     * @summary Get Store Graph
     * @request GET:/v1/companies/{company_id}/games/{game_id}/store_graphs/graph
     * @secure
     */
    getStoreGraph: (companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/store_graphs/graph`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store Graphs
     * @name GetBaseStoreGraph
     * @summary Get Base Store Graph
     * @request GET:/v1/companies/{company_id}/games/{game_id}/store_graphs
     * @secure
     */
    getBaseStoreGraph: (companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<StoreGraphResponse, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/store_graphs`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store Graphs
     * @name CreateOrUpdateStoreGraph
     * @summary Create Or Update Store Graph
     * @request POST:/v1/companies/{company_id}/games/{game_id}/store_graphs
     * @secure
     */
    createOrUpdateStoreGraph: (companyId: string, gameId: string, data: StoreGraphUpdate, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/store_graphs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store Graphs
     * @name PreviewStore
     * @summary Preview Store
     * @request POST:/v1/companies/{company_id}/games/{game_id}/store_graphs/preview
     * @secure
     */
    previewStore: (companyId: string, gameId: string, data: StoreGraphPreview, params: RequestParams = {}) =>
      this.request<StoreGraphPreviewResult, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/store_graphs/preview`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Item Categories
     * @name GetItemCategory
     * @summary Get Item Category
     * @request GET:/v1/companies/{company_id}/games/{game_id}/item_categories/{item_category_id}
     * @secure
     */
    getItemCategory: (companyId: string, gameId: string, itemCategoryId: string, params: RequestParams = {}) =>
      this.request<ItemCategoryRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_categories/${itemCategoryId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Item Categories
     * @name UpdateItemCategory
     * @summary Update Item Category
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/item_categories/{item_category_id}
     * @secure
     */
    updateItemCategory: (
      companyId: string,
      gameId: string,
      itemCategoryId: string,
      data: ItemCategoryUpdate,
      params: RequestParams = {},
    ) =>
      this.request<ItemCategoryRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_categories/${itemCategoryId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Item Categories
     * @name DeleteItemCategory
     * @summary Delete Item Category
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/item_categories/{item_category_id}
     * @secure
     */
    deleteItemCategory: (companyId: string, gameId: string, itemCategoryId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_categories/${itemCategoryId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Item Categories
     * @name GetItemCategories
     * @summary Get Item Categories
     * @request GET:/v1/companies/{company_id}/games/{game_id}/item_categories
     * @secure
     */
    getItemCategories: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<ItemCategoryRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_categories`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Item Categories
     * @name CreateItemCategory
     * @summary Create Item Category
     * @request POST:/v1/companies/{company_id}/games/{game_id}/item_categories
     * @secure
     */
    createItemCategory: (companyId: string, gameId: string, data: ItemCategoryCreate, params: RequestParams = {}) =>
      this.request<ItemCategoryRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_categories`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes multiple item_categories.
     *
     * @tags Item Categories
     * @name BulkDeleteItemCategories
     * @summary Bulk Delete Item Categories
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/item_categories
     * @secure
     */
    bulkDeleteItemCategories: (
      companyId: string,
      gameId: string,
      data: ItemCategoryBulkDelete[],
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_categories`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Updates multiple categories.
     *
     * @tags Item Categories
     * @name BulkUpdateItemCategories
     * @summary Bulk Update Item Categories
     * @request PATCH:/v1/companies/{company_id}/games/{game_id}/item_categories
     * @secure
     */
    bulkUpdateItemCategories: (
      companyId: string,
      gameId: string,
      data: ItemCategoryBulkUpdate[],
      params: RequestParams = {},
    ) =>
      this.request<ItemCategoryRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_categories`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetOffers
     * @summary Get Offers
     * @request GET:/v1/companies/{company_id}/games/{game_id}/offers
     * @secure
     */
    getOffers: (
      companyId: string,
      gameId: string,
      query?: {
        /** Campaign Id */
        campaign_id?: string
        /** User Id */
        user_id?: string
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<OfferUserRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/offers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetOfferStat
     * @summary Get Offer Stat
     * @request GET:/v1/companies/{company_id}/games/{game_id}/offers/report
     * @secure
     */
    getOfferStat: (
      companyId: string,
      gameId: string,
      query?: {
        /** Campaign Id */
        campaign_id?: string
        /** User Id */
        user_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<OfferStat, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/offers/report`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name BulkDeleteCampaignOffers
     * @summary Bulk Delete Campaign Offers
     * @request PATCH:/v1/companies/{company_id}/games/{game_id}/offers/campaign/{campaign_id}
     * @secure
     */
    bulkDeleteCampaignOffers: (
      companyId: string,
      gameId: string,
      campaignId: string,
      data: OfferBulkDelete[],
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/offers/campaign/${campaignId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name BulkDeleteUserOffers
     * @summary Bulk Delete User Offers
     * @request PATCH:/v1/companies/{company_id}/games/{game_id}/offers/user/{user_id}
     * @secure
     */
    bulkDeleteUserOffers: (
      companyId: string,
      gameId: string,
      userId: string,
      data: OfferBulkDelete[],
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/offers/user/${userId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name DeleteAllCampaignOffers
     * @summary Delete All Campaign Offers
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/offers/campaign/{campaign_id}/delete_all
     * @secure
     */
    deleteAllCampaignOffers: (companyId: string, gameId: string, campaignId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/offers/campaign/${campaignId}/delete_all`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name DeleteAllUserOffers
     * @summary Delete All User Offers
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/offers/user/{user_id}/delete_all
     * @secure
     */
    deleteAllUserOffers: (companyId: string, gameId: string, userId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/offers/user/${userId}/delete_all`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name DeleteOffer
     * @summary Delete Offer
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/offers/{offer_id}
     * @secure
     */
    deleteOffer: (offerId: string, companyId: string, gameId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/offers/${offerId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Webhook Logs
     * @name GetWebhookLogByOrder
     * @summary Get Webhook Log By Order
     * @request GET:/v1/companies/{company_id}/games/{game_id}/webhook_logs/order_log/{order_id}
     * @secure
     */
    getWebhookLogByOrder: (companyId: string, gameId: string, orderId: string, params: RequestParams = {}) =>
      this.request<WebhookLogRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/webhook_logs/order_log/${orderId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Webhook Logs
     * @name GetWebhookLog
     * @summary Get Webhook Log
     * @request GET:/v1/companies/{company_id}/games/{game_id}/webhook_logs/{webhook_id}
     * @secure
     */
    getWebhookLog: (
      companyId: string,
      gameId: string,
      webhookId: string,
      query?: {
        /** Search String */
        search_string?: string
        /** An enumeration. */
        status?: WebhookLogStatus
        /** An enumeration. */
        event_type?: WebhookEventType
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
        /**
         * Last Count
         * @max 3000
         * @default 30
         */
        last_count?: number
        /**
         * Tz
         * @default 0
         */
        tz?: number
        /** @default "day" */
        granularity?: DataGranularity
        /** @default "period" */
        type?: FilterType
      },
      params: RequestParams = {},
    ) =>
      this.request<WebhookLogRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/webhook_logs/${webhookId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Webhook Logs
     * @name ResendWebhook
     * @summary Resend Webhook
     * @request POST:/v1/companies/{company_id}/games/{game_id}/webhook_logs/{webhook_id}/resend/{webhook_log_id}
     * @secure
     */
    resendWebhook: (
      webhookLogId: string,
      companyId: string,
      gameId: string,
      webhookId: string,
      params: RequestParams = {},
    ) =>
      this.request<ResendResult, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/webhook_logs/${webhookId}/resend/${webhookLogId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of properties.
     *
     * @tags Item Properties
     * @name GetItemProperties
     * @summary Get Item Properties
     * @request GET:/v1/companies/{company_id}/games/{game_id}/item_properties
     * @secure
     */
    getItemProperties: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<ItemPropertyRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_properties`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new property.
     *
     * @tags Item Properties
     * @name CreateItemProperty
     * @summary Create Item Property
     * @request POST:/v1/companies/{company_id}/games/{game_id}/item_properties
     * @secure
     */
    createItemProperty: (companyId: string, gameId: string, data: ItemPropertyCreate, params: RequestParams = {}) =>
      this.request<ItemPropertyRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_properties`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a single property.
     *
     * @tags Item Properties
     * @name GetItemProperty
     * @summary Get Item Property
     * @request GET:/v1/companies/{company_id}/games/{game_id}/item_properties/{property_id}
     * @secure
     */
    getItemProperty: (companyId: string, gameId: string, propertyId: string, params: RequestParams = {}) =>
      this.request<ItemPropertyRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_properties/${propertyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates a property.
     *
     * @tags Item Properties
     * @name UpdateItemProperty
     * @summary Update Item Property
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/item_properties/{property_id}
     * @secure
     */
    updateItemProperty: (
      companyId: string,
      gameId: string,
      propertyId: string,
      data: ItemPropertyUpdate,
      params: RequestParams = {},
    ) =>
      this.request<ItemPropertyRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_properties/${propertyId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes a property.
     *
     * @tags Item Properties
     * @name DeleteItemProperty
     * @summary Delete Item Property
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/item_properties/{property_id}
     * @secure
     */
    deleteItemProperty: (companyId: string, gameId: string, propertyId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_properties/${propertyId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Returns a list of properties for an item.
     *
     * @tags Item Properties Relations
     * @name GetItemPropertiesRelations
     * @summary Get Item Properties Relations
     * @request GET:/v1/companies/{company_id}/games/{game_id}/item_properties_relations/{item_id}
     * @secure
     */
    getItemPropertiesRelations: (
      companyId: string,
      gameId: string,
      itemId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<ItemPropertyRelationRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_properties_relations/${itemId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new property for an item.
     *
     * @tags Item Properties Relations
     * @name CreateItemPropertyRelation
     * @summary Create Item Property Relation
     * @request POST:/v1/companies/{company_id}/games/{game_id}/item_properties_relations/{item_id}
     * @secure
     */
    createItemPropertyRelation: (
      companyId: string,
      gameId: string,
      itemId: string,
      data: ItemPropertyRelationCreate,
      params: RequestParams = {},
    ) =>
      this.request<ItemPropertyRelationRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_properties_relations/${itemId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates an existing property for an item.
     *
     * @tags Item Properties Relations
     * @name UpdateItemProperty2
     * @summary Update Item Property
     * @request PUT:/v1/companies/{company_id}/games/{game_id}/item_properties_relations/{item_id}/{item_propery_relation_id}
     * @originalName updateItemProperty
     * @duplicate
     * @secure
     */
    updateItemProperty2: (
      companyId: string,
      gameId: string,
      itemId: string,
      itemProperyRelationId: string,
      data: ItemPropertyRelationUpdate,
      params: RequestParams = {},
    ) =>
      this.request<ItemPropertyRelationRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_properties_relations/${itemId}/${itemProperyRelationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes an existing property for an item.
     *
     * @tags Item Properties Relations
     * @name DeleteItemProperty2
     * @summary Delete Item Property
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/item_properties_relations/{item_id}/{item_propery_relation_id}
     * @originalName deleteItemProperty
     * @duplicate
     * @secure
     */
    deleteItemProperty2: (
      companyId: string,
      gameId: string,
      itemId: string,
      itemProperyRelationId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_properties_relations/${itemId}/${itemProperyRelationId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign Templates
     * @name GetCampaignTemplates
     * @summary Get Campaign Templates
     * @request GET:/v1/companies/{company_id}/games/{game_id}/campaign_templates
     * @secure
     */
    getCampaignTemplates: (
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<CampaignTemplateRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/campaign_templates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign Templates
     * @name CreateOrUpdateCampaignTemplate
     * @summary Create Or Update Campaign Template
     * @request POST:/v1/companies/{company_id}/games/{game_id}/campaign_templates
     * @secure
     */
    createOrUpdateCampaignTemplate: (
      companyId: string,
      gameId: string,
      data: CampaignTemplateCreate,
      params: RequestParams = {},
    ) =>
      this.request<CampaignTemplateRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/campaign_templates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign Templates
     * @name DeleteCampaignTemplate
     * @summary Delete Campaign Template
     * @request DELETE:/v1/companies/{company_id}/games/{game_id}/campaign_templates/{campaign_template_id}
     * @secure
     */
    deleteCampaignTemplate: (
      companyId: string,
      gameId: string,
      campaignTemplateId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/campaign_templates/${campaignTemplateId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Item Claimed
     * @name GetClaimedItems
     * @summary Get Claimed Items
     * @request GET:/v1/companies/{company_id}/games/{game_id}/item_claimed/user/{user_id}
     * @secure
     */
    getClaimedItems: (
      userId: string,
      companyId: string,
      gameId: string,
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<UserItemClaimed[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/games/${gameId}/item_claimed/user/${userId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company Profile
     * @name GetCompanyProfile
     * @summary Get Company Profile
     * @request GET:/v1/companies/{company_id}/profiles/me
     * @secure
     */
    getCompanyProfile: (companyId: string, params: RequestParams = {}) =>
      this.request<CustomerCompany, HTTPValidationError>({
        path: `/v1/companies/${companyId}/profiles/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invites
     * @name GetCompanyInvites
     * @summary Get Company Invites
     * @request GET:/v1/companies/{company_id}/invites
     * @secure
     */
    getCompanyInvites: (
      companyId: string,
      query?: {
        /**
         * Limit
         * The limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 200
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<InviteRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/invites`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invites
     * @name GetOrCreateInvite
     * @summary Get Or Create Invite
     * @request POST:/v1/companies/{company_id}/invites
     * @secure
     */
    getOrCreateInvite: (companyId: string, data: InviteCreate, params: RequestParams = {}) =>
      this.request<InviteRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/invites`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invites
     * @name ResendInvite
     * @summary Resend Invite
     * @request POST:/v1/companies/{company_id}/invites/{invite_id}/resend
     * @secure
     */
    resendInvite: (companyId: string, inviteId: string, params: RequestParams = {}) =>
      this.request<InviteRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/invites/${inviteId}/resend`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invites
     * @name RemoveInvite
     * @summary Remove Invite
     * @request DELETE:/v1/companies/{company_id}/invites/{invite_id}
     * @secure
     */
    removeInvite: (companyId: string, inviteId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/invites/${inviteId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name GetCompanies
     * @summary Get Companies
     * @request GET:/v1/companies
     * @secure
     */
    getCompanies: (params: RequestParams = {}) =>
      this.request<PersonalCompany[], any>({
        path: `/v1/companies`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name CreateCompany
     * @summary Create Company
     * @request POST:/v1/companies
     * @secure
     */
    createCompany: (data: CompanyCreate, params: RequestParams = {}) =>
      this.request<Company, HTTPValidationError>({
        path: `/v1/companies`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name GetAllGames
     * @summary Get All Games
     * @request GET:/v1/companies/games
     * @secure
     */
    getAllGames: (params: RequestParams = {}) =>
      this.request<CompanyGame[], any>({
        path: `/v1/companies/games`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name GetCompany
     * @summary Get Company
     * @request GET:/v1/companies/{company_id}
     * @secure
     */
    getCompany: (companyId: string, params: RequestParams = {}) =>
      this.request<PersonalCompany, any>({
        path: `/v1/companies/${companyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name UpdateCompany
     * @summary Update Company
     * @request PUT:/v1/companies/{company_id}
     * @secure
     */
    updateCompany: (companyId: string, data: CompanyUpdate, params: RequestParams = {}) =>
      this.request<Company, HTTPValidationError>({
        path: `/v1/companies/${companyId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name DeleteCompany
     * @summary Delete Company
     * @request DELETE:/v1/companies/{company_id}
     * @secure
     */
    deleteCompany: (companyId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/companies/${companyId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name GetCompanyMembers
     * @summary Get Company Members
     * @request GET:/v1/companies/{company_id}/members
     * @secure
     */
    getCompanyMembers: (
      companyId: string,
      query?: {
        /**
         * Limit
         * The limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 200
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerCompanyRead[], HTTPValidationError>({
        path: `/v1/companies/${companyId}/members`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name GetCompanyMember
     * @summary Get Company Member
     * @request GET:/v1/companies/{company_id}/members/{member_id}
     * @secure
     */
    getCompanyMember: (memberId: string, companyId: string, params: RequestParams = {}) =>
      this.request<CustomerCompanyRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/members/${memberId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name UpdateMember
     * @summary Update Member
     * @request PUT:/v1/companies/{company_id}/members/{member_id}
     * @secure
     */
    updateMember: (memberId: string, companyId: string, data: CustomerCompanyUpdate, params: RequestParams = {}) =>
      this.request<CustomerCompanyRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/members/${memberId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name RemoveMemberFromCompany
     * @summary Remove Member From Company
     * @request DELETE:/v1/companies/{company_id}/members/{member_id}
     * @secure
     */
    removeMemberFromCompany: (memberId: string, companyId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/members/${memberId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Return a URL to upload an image to S3. Ensure that you include a content-type header that matches the given image format in the request body.
     *
     * @tags Images
     * @name CreateUploadLink
     * @summary Create Upload Link
     * @request POST:/v1/images/create_upload_link
     * @secure
     */
    createUploadLink: (data: CreateUploadLinkRequest, params: RequestParams = {}) =>
      this.request<CreateUploadLinkResponse, HTTPValidationError>({
        path: `/v1/images/create_upload_link`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AI
     * @name CreateImage
     * @summary Create Image
     * @request POST:/v1/ai/create_image
     * @secure
     */
    createImage: (data: CreateImageRequest, params: RequestParams = {}) =>
      this.request<CreateImageResponse, HTTPValidationError>({
        path: `/v1/ai/create_image`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AI
     * @name GetCompletion
     * @summary Get Completion
     * @request POST:/v1/ai/get_completion
     * @secure
     */
    getCompletion: (data: GetCompletionRequest, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/ai/get_completion`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AI
     * @name GetTranslation2
     * @summary Get Translation
     * @request POST:/v1/ai/get_translation
     * @originalName getTranslation
     * @duplicate
     * @secure
     */
    getTranslation2: (data: TranslateTextRequest, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/ai/get_translation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account Details
     * @name GetAccountDetails
     * @summary Get Account Details
     * @request GET:/v1/companies/{company_id}/account_details
     * @secure
     */
    getAccountDetails: (companyId: string, params: RequestParams = {}) =>
      this.request<AccountRead, HTTPValidationError>({
        path: `/v1/companies/${companyId}/account_details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account Details
     * @name EditAccountDetails
     * @summary Edit Account Details
     * @request PUT:/v1/companies/{company_id}/account_details
     * @secure
     */
    editAccountDetails: (companyId: string, data: AccountDetails, params: RequestParams = {}) =>
      this.request<AccountDetails, HTTPValidationError>({
        path: `/v1/companies/${companyId}/account_details`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account Details
     * @name SubmitAccountDetailsForReview
     * @summary Submit Account Details For Review
     * @request POST:/v1/companies/{company_id}/account_details/submit_for_review
     * @secure
     */
    submitAccountDetailsForReview: (companyId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/companies/${companyId}/account_details/submit_for_review`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account Details
     * @name VerifyAccountDetails
     * @summary Verify Account Details
     * @request POST:/v1/companies/{company_id}/account_details/verify
     * @secure
     */
    verifyAccountDetails: (companyId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/companies/${companyId}/account_details/verify`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Onboarding
     * @name OnboardGame
     * @summary Onboard Game
     * @request POST:/v1/companies/onboarding
     * @secure
     */
    onboardGame: (data: OnBoardingRequest, params: RequestParams = {}) =>
      this.request<OnBoardingResponse, HTTPValidationError>({
        path: `/v1/companies/onboarding`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Website Templates
     * @name GetAllWebsitesTemplates
     * @summary Get All Websites Templates
     * @request GET:/v1/website_templates
     * @secure
     */
    getAllWebsitesTemplates: (
      query?: {
        /**
         * Limit
         * A limit on the number of objects to be returned
         * @exclusiveMin 1
         * @max 200
         * @default 10
         */
        limit?: number
        /**
         * Offset
         * The number of objects to skip
         * @min 0
         */
        offset?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<WebsiteTemplate[], HTTPValidationError>({
        path: `/v1/website_templates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Website Templates
     * @name CreateWebsiteTemplate
     * @summary Create Website Template
     * @request POST:/v1/website_templates
     * @secure
     */
    createWebsiteTemplate: (data: WebsiteTemplateCreate, params: RequestParams = {}) =>
      this.request<WebsiteTemplate, HTTPValidationError>({
        path: `/v1/website_templates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Website Templates
     * @name GetWebsiteTemplate
     * @summary Get Website Template
     * @request GET:/v1/website_templates/{website_template_id}
     * @secure
     */
    getWebsiteTemplate: (websiteTemplateId: string, params: RequestParams = {}) =>
      this.request<WebsiteTemplate, HTTPValidationError>({
        path: `/v1/website_templates/${websiteTemplateId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Website Templates
     * @name UpdateWebsiteTemplate
     * @summary Update Website Template
     * @request PUT:/v1/website_templates/{website_template_id}
     * @secure
     */
    updateWebsiteTemplate: (websiteTemplateId: string, data: WebsiteTemplateUpdate, params: RequestParams = {}) =>
      this.request<WebsiteTemplate, HTTPValidationError>({
        path: `/v1/website_templates/${websiteTemplateId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Website Templates
     * @name DeleteWebsite2
     * @summary Delete Website
     * @request DELETE:/v1/website_templates/{website_template_id}
     * @originalName deleteWebsite
     * @duplicate
     * @secure
     */
    deleteWebsite2: (websiteTemplateId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/website_templates/${websiteTemplateId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profiles
     * @name GetMyProfile
     * @summary Get My Profile
     * @request GET:/v1/profiles/me
     * @secure
     */
    getMyProfile: (params: RequestParams = {}) =>
      this.request<Customer, any>({
        path: `/v1/profiles/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profiles
     * @name UpdateProfile
     * @summary Update Profile
     * @request PUT:/v1/profiles/me
     * @secure
     */
    updateProfile: (data: ProfileUpdate, params: RequestParams = {}) =>
      this.request<Customer, HTTPValidationError>({
        path: `/v1/profiles/me`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name PostLogin
     * @summary Post Login
     * @request POST:/v1/auth/post_login
     * @secure
     */
    postLogin: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/auth/post_login`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetOutdatedAgreements
     * @summary Get Outdated Agreements
     * @request GET:/v1/customers/me/outdated_agreements
     * @secure
     */
    getOutdatedAgreements: (params: RequestParams = {}) =>
      this.request<OutdatedAgreements, any>({
        path: `/v1/customers/me/outdated_agreements`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of coupons.
     *
     * @tags Customers
     * @name AcceptAgreements
     * @summary Accept Agreements
     * @request POST:/v1/customers/me/accept_agreements
     * @secure
     */
    acceptAgreements: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/customers/me/accept_agreements`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CheckDocsAccess
     * @summary Check Docs Access
     * @request POST:/v1/customers/check_docs_access
     */
    checkDocsAccess: (data: CheckDocsAccessRequest, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/customers/check_docs_access`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name GetMyLead
     * @summary Get My Lead
     * @request GET:/v1/leads/me
     * @secure
     */
    getMyLead: (params: RequestParams = {}) =>
      this.request<LeadRead, any>({
        path: `/v1/leads/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name CreateOrUpdateLead
     * @summary Create Or Update Lead
     * @request PUT:/v1/leads
     * @secure
     */
    createOrUpdateLead: (data: LeadCreateOrUpdate, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/v1/leads`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invites
     * @name AcceptInvite
     * @summary Accept Invite
     * @request POST:/v1/invites/{invite_id}/accept
     * @secure
     */
    acceptInvite: (inviteId: string, params: RequestParams = {}) =>
      this.request<InviteAcceptResponse, HTTPValidationError>({
        path: `/v1/invites/${inviteId}/accept`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invites
     * @name GetInvitePreview
     * @summary Get Invite Preview
     * @request GET:/v1/invites/{invite_id}
     * @secure
     */
    getInvitePreview: (inviteId: string, params: RequestParams = {}) =>
      this.request<InvitePreview, HTTPValidationError>({
        path: `/v1/invites/${inviteId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
}
