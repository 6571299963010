import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { useGenerateCompanyPath } from '@/libs'
import { LocaleRead, LocaleStatus } from '@/api/dashboard'
import { localesQueryOptions } from './localesQueryOptions'
import { localeQueryOptions } from './localeQueryOptions'

interface LocaleTranslateAllMutate {
  data: LocaleRead
}

export const localeTranslateAllMutation = () => {
  const ql = useQueryClient()
  const { companyId, gameId } = useGenerateCompanyPath()

  return useMutation({
    mutationFn: async ({ data }: LocaleTranslateAllMutate) => {
      const r = await dashboardClient.v1.scheduleTranslateAllJob(companyId, gameId, { locale_id: data.id })
      return r.data
    },
    onMutate: variables => {
      ql.setQueryData(localesQueryOptions(companyId, gameId).queryKey, (old = []) => {
        return old.map(locale => {
          if (locale.id === variables.data.id) {
            return { ...locale, status: LocaleStatus.Translating, stats: { ...locale.stats, done: 0 } }
          }
          return locale
        })
      })

      ql.setQueryData(localeQueryOptions(companyId, gameId, variables.data.id).queryKey, old => {
        if (!old) {
          return old
        }
        return { ...old, status: LocaleStatus.Translating, stats: { ...old.stats, done: 0 } }
      })
    },
  })
}
