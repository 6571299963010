import Box from '@mui/material/Box'
import { Grid, Skeleton } from '@mui/material'

import { useTheme } from '@mui/material/styles'
import { MAX_WIDTH, SURFACE_SURFACE_FRAME_FILL } from '../../Settings'
import { TextSkeleton } from '../TextSkeleton'

export default function DashboardSkeleton() {
  const theme = useTheme()

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
        height={theme.spacing(7)}
        sx={{
          gap: theme.spacing(1.25),
          maxWidth: MAX_WIDTH,
        }}
      >
        <TextSkeleton variant="text" width="505px" sx={{ marginRight: 'auto' }} height={theme.spacing(3.5)} />

        <TextSkeleton variant="text" width="93px" height={theme.spacing(3.5)} />
        <TextSkeleton variant="text" width="93px" height={theme.spacing(3.5)} />
        <TextSkeleton variant="text" width="93px" height={theme.spacing(3.5)} />
      </Box>

      <Grid container spacing={3} marginTop={0} marginBottom={0}>
        <Grid item md={12} xs={12} lg={12} sx={{ paddingTop: '0 !important' }}>
          <Box
            sx={{
              backgroundColor: SURFACE_SURFACE_FRAME_FILL,
              borderRadius: theme.spacing(0.5),
              flexDirection: 'row',
              display: 'flex',
              gap: theme.spacing(2.5),
            }}
          >
            <Box sx={{ minWidth: '505px' }}>
              <Skeleton
                height="287px"
                sx={{
                  transform: 'none',
                  borderRadius: theme.spacing(1.5),
                  backgroundColor: theme.palette.background.fgskeleton,
                }}
              />
            </Box>
            <Box sx={{ width: '100%' }}>
              <Skeleton
                height="287px"
                sx={{
                  flexGrow: 1,
                  transform: 'none',
                  borderRadius: theme.spacing(1.5),
                  backgroundColor: theme.palette.background.fgskeleton,
                }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item md={12} xs={12} lg={12}>
          <Skeleton
            width="100%"
            height="287px"
            sx={{
              transform: 'none',
              borderRadius: theme.spacing(1.5),
              backgroundColor: theme.palette.background.fgskeleton,
            }}
          />
        </Grid>

        <Grid item md={12} xs={12} lg={12}>
          <Skeleton
            width="100%"
            height="960px"
            sx={{
              transform: 'none',
              borderRadius: theme.spacing(1.5),
              backgroundColor: theme.palette.background.fgskeleton,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
