import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

type Query = {
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 100
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number

  /** Segment Group Id */
  segment_group_id?: string
}

/**
 *
 * @param companyId
 * @param gameId
 * @param query
 */
export const useSegmentsQuery = (companyId: string, gameId: string, query: Query) => {
  return useQuery({
    queryKey: ['segments', gameId, query],
    queryFn: () => dashboardClient.v1.getSegments(companyId, gameId, query),
    select: response => response.data,
  })
}
