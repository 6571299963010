import { HTMLAttributes, ReactNode } from 'react'
import { cn } from '../../../libs/cn'

interface DrawerContentProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

export const DrawerContent = ({ children, ...rest }: DrawerContentProps) => (
  <div {...rest} className={cn('h-auto bg-fg-primary p-5', rest.className)}>
    {children}
  </div>
)
