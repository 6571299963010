import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { hubWebsitesQuery } from '@/layouts/hub/api'
import { Button } from '@/ui'
import { HUB_PATH, cn, useGenerateCompanyPath } from '@/libs'
import { calcGenerationProgress, getGenerationProgressIsStuck } from '../libs'
import { GetStartedStepState } from '../types'
import { useGenerationProgressListener } from './useGenerationProgressListener'
import { useGetStarted } from './useGetStarted'

type HubStepTasks = 'news' | 'updates' | 'events' | 'achievements' | 'leaderboards'

/**
 * Two minutes
 */
const MAX_WAITING_TIME_MS = 60 * 2 * 1000

export const useHubStep = (): GetStartedStepState<HubStepTasks> => {
  const { t } = useTranslation()
  const { generatePath, companyId, gameId } = useGenerateCompanyPath()
  const { data: progressState, isLoading } = useGenerationProgressListener()
  const { data: website } = useQuery({
    ...hubWebsitesQuery(companyId, gameId),
    select: websites => websites.at(0),
  })

  const { check, complete } = useGetStarted()

  const id = 'hub'
  const progress = useMemo(() => (!isLoading ? calcGenerationProgress(progressState) : 0), [isLoading, progressState])
  const status = check('visit_game_hub')
    ? 'completed'
    : !isLoading
      ? progress === 100
        ? 'idle'
        : 'generating'
      : 'loading'

  const isStuck = progressState ? getGenerationProgressIsStuck(progressState, MAX_WAITING_TIME_MS) : false

  const onComplete = async () => {
    await complete('visit_game_hub')
  }

  return {
    id,
    loading: false,
    status,
    progress: !isLoading ? { value: progress, isStuck } : undefined,
    img: website?.config?.favicon_url,
    title: t(`get-started.steps.${id}.title`),
    desc: t(`get-started.steps.${id}.desc`),
    tasks: {
      news: {
        label: t(`get-started.steps.${id}.tasks.news`),
        status: progress >= 20 ? 'completed' : 'loading',
      },
      updates: {
        label: t(`get-started.steps.${id}.tasks.updates`),
        status: progress >= 35 ? 'completed' : 'loading',
      },
      events: {
        label: t(`get-started.steps.${id}.tasks.events`),
        status: progress >= 50 ? 'completed' : 'loading',
      },
      achievements: {
        label: t(`get-started.steps.${id}.tasks.achievements`),
        status: progress >= 75 ? 'completed' : 'loading',
      },
      leaderboards: {
        label: t(`get-started.steps.${id}.tasks.leaderboards`),
        status: progress >= 100 ? 'completed' : 'loading',
      },
    },
    cta: (
      <Link to={generatePath(HUB_PATH)} className={cn((isLoading || progress !== 100) && 'pointer-events-none')}>
        <Button
          className="min-w-40"
          variant={status === 'completed' ? 'outline' : 'secondary-brand'}
          size="lg"
          disabled={isLoading || progress !== 100}
          onClick={onComplete}
        >
          {t(`get-started.steps.${id}.cta.label`)}
        </Button>
      </Link>
    ),
  }
}
