import { useCallback, useId } from 'react'
import { modalStore } from '../Modal/modalStore'
import { ToastSeverity } from './types'

export interface ToastProps {
  message: string
  severity: ToastSeverity
}

export const useToast = () => {
  const id = useId()

  const { pushToast } = modalStore()

  return useCallback(
    (props: ToastProps) => {
      pushToast(id, props.message, props.severity)
    },
    [pushToast, id],
  )
}
