import { CSSProperties, forwardRef, useId } from 'react'
import { DotsHorIcon } from '../../../icons/Icons'
import { Floating } from '../Floating'
import { Menu, MenuOption } from '../Menu'
import { SelectOption } from '../Select'
import { ButtonIcon } from '../ButtonIcon'

type OnChange = (value: SelectOption['value']) => void

interface GridRowEditButtonProps {
  options?: (SelectOption | undefined | false)[]
  style?: CSSProperties
  menuWidth?: string
  onChange: OnChange
}

const ButtonMenu = (props: { options: SelectOption[]; onClose?: () => void; onChange: OnChange }) => {
  const id = useId()
  const { options, onChange, onClose } = props
  return (
    <Menu style={{ maxWidth: 'none', minWidth: '210px' }}>
      {options.map(option => (
        <MenuOption
          key={`menu-option-${id}-${option.value}`}
          {...option}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            if (onClose) {
              onClose()
            }
            onChange(option.value)
          }}
        >
          {option.children}
        </MenuOption>
      ))}
    </Menu>
  )
}

export const TableRowEditButton = forwardRef<HTMLDivElement, GridRowEditButtonProps>(function Select({
  style,
  options = [],
  onChange = [],
}) {
  options = options.filter(it => !!it)

  return (
    <Floating
      stopPropagation={true}
      hasArrow={true}
      menu={<ButtonMenu options={options.filter(it => !!it) as SelectOption[]} onChange={onChange as OnChange} />}
      placement="bottom-end"
      referenceWidth={true}
    >
      <ButtonIcon variant="tertiary-gray" style={style}>
        <DotsHorIcon />
      </ButtonIcon>
    </Floating>
  )
})
