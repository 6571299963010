import { HTMLAttributes, ReactNode } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '@/libs'

interface ToolbarFilterInnerButtonProps extends HTMLAttributes<HTMLButtonElement>, VariantProps<typeof variants> {
  children: ReactNode
}

const variants = cva(
  [
    'pointer-events-auto flex items-center gap-1 rounded px-1.5 py-1 text-caption-md leading-none transition-colors',
    'focus-visible:outline-border-brand',
  ],
  {
    variants: {
      variant: {
        empty: ['text-inherit'],
        clear: [
          'text-text-tertiary',
          'hover:border-border-primary-hover hover:bg-fg-primary-hover hover:text-text-secondary',
        ],
        value: ['font-semibold text-text-brand-primary', 'hover:bg-fg-brand-tertiary'],
      },
      defaultVariants: {
        variant: 'default',
      },
    },
  },
)

export const ToolbarFilterInnerButton = ({ children, variant = 'empty', ...rest }: ToolbarFilterInnerButtonProps) => {
  return (
    <button {...rest} className={cn(variants({ variant }), rest.className)} type="button">
      {children}
    </button>
  )
}
