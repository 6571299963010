import { queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

interface LocalesQueryParams {
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 200
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number
}

/**
 *
 * @param companyId
 * @param gameId
 * @param params
 */
export const localesQueryOptions = (
  companyId: string,
  gameId: string,
  params: LocalesQueryParams = { limit: 200, offset: 0 },
) =>
  queryOptions({
    queryKey: [gameId, 'locales', params],
    queryFn: () => dashboardClient.v1.getLocales(companyId, gameId, params).then(r => r.data),
  })
