import i18next from 'i18next'
import { useParams } from 'react-router-dom'
import { SelectMultiple, SelectMultipleOption, SelectMultipleProps } from '../../../../components/ui'
import { usePaymentMethods } from '../../../../api/usePaymentMethods'
import { useMemo } from 'react'

// @ts-ignore
interface SelectPMProps extends SelectMultipleProps {
  options?: SelectMultipleOption[]
}

export const SelectPaymentMethod = (props: SelectPMProps) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const { data: items = [] } = usePaymentMethods(companyId, gameId)

  let options = useMemo(() => {
    return items
      .filter(it => it.settings.global_enabled && it.dashboard_show)
      .map(it => ({
        value: it.id as string,
        icon: <img src={it.logo_url} style={{ width: '32px', height: '32px' }} alt={it.caption} />,
        children: it.caption,
      }))
  }, [items])

  return <SelectMultiple {...props} options={options} placeholder={i18next.t('select-payment-methods')} />
}
