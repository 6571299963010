import { forwardRef } from 'react'
import { Select, SelectProps } from '@/components/ui'
import { useTranslation } from 'react-i18next'
import { getCountries } from '@/translates'

export const SelectCountry = forwardRef<HTMLDivElement, Omit<SelectProps, 'options'>>(
  function SelectCountry(props, ref) {
    const countries = getCountries()
    const { t } = useTranslation()
    return (
      <Select
        {...props}
        ref={ref}
        placeholder={t('select-country')}
        options={Object.entries(countries).map(([value, label]) => ({
          value,
          children: label as string,
          icon: (
            <img className="w-6" src={`https://static.aghanim.com/country-flags/${value}.svg`} loading="lazy" alt="" />
          ),
          extraRight: <span className="text-text-disabled">{value}</span>,
        }))}
      />
    )
  },
)
