import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LocaleRead } from '@/api/dashboard'
import { ModalConfirm, ModalProps } from '@/ui'
import { LOCALIZATION_PATH, useGenerateCompanyPath } from '@/libs'
import { localeDeleteMutation } from '../api'

interface L10nLocaleModalDeleteProps extends ModalProps {
  locale: LocaleRead
}

export const L10nLocaleModalDelete = ({ locale, ...rest }: L10nLocaleModalDeleteProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { generatePath } = useGenerateCompanyPath()
  const { mutateAsync } = localeDeleteMutation()

  const onConfirm = async () => {
    await mutateAsync({ localeId: locale.id })
    navigate(generatePath(LOCALIZATION_PATH))
  }

  return (
    <ModalConfirm
      {...rest}
      message={t('localization.locale.delete-modal.title')}
      subMessage={t('localization.locale.delete-modal.text')}
      confirmButtonText={t('localization.locale.delete-modal.confirm')}
      color="error"
      onConfirm={onConfirm}
    />
  )
}
