import { useAuth0 } from '@auth0/auth0-react'
import UserAvatar from '../../components/UserAvatar'
import { Floating, MenuOption, useModal } from '../../components/ui'
import { useContext } from 'react'
import { AuthContext } from '../../Context'
import { Exit, UserOutline } from '../../components/icons'
import i18next from 'i18next'
import AccountDetailsModal from '@/layouts/header/AccountDetailsModal'
import { useFeatureFlags } from '@/libs/hooks/useFeatureFlags'

const ProfileMenu = (props: { onOpenAccountModal: () => void; onClose?: () => void }) => {
  const { logout, user } = useAuth0()
  const context = useContext(AuthContext)
  let userName = context?.customer?.name || user?.name || ''
  if (userName == user?.email) {
    userName = userName.split('@')[0]
  }

  const { featureFlags } = useFeatureFlags()

  return (
    <div
      style={{
        boxShadow: '0px 12px 16px -4px #10182814, 0px 4px 6px -2px #10182808',
      }}
      className="flex w-[264px] flex-col rounded-2xl1 border border-border-primary bg-fg-primary p-3"
    >
      <div className="flex w-full gap-3 ">
        <UserAvatar url={context?.customer?.avatar_url || user?.picture} size="48px" />
        <div className="flex grow flex-col overflow-hidden">
          <div className="w-full truncate text-sm font-semibold text-text-primary">{userName}</div>
          <div className="w-full truncate text-xs font-normal text-text-brand-primary">{user?.email}</div>
        </div>
      </div>
      <div className="mt-3 w-full border-b border-border-secondary" />

      {featureFlags.account_settings_enabled && (
        <MenuOption
          icon={<UserOutline />}
          onClick={() => {
            props.onOpenAccountModal()
            props.onClose?.()
          }}
        >
          {i18next.t('profile-button.account-profile')}
        </MenuOption>
      )}

      <MenuOption
        onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
        data-testid="logout-button"
        icon={<Exit />}
      >
        {i18next.t('profile-button.logout')}
      </MenuOption>
    </div>
  )
}

const ProfileButton = () => {
  const { user } = useAuth0()
  const context = useContext(AuthContext)

  const openAccountModal = useModal(props => <AccountDetailsModal {...props} />, { mode: 'rush' })

  return (
    <Floating
      menu={
        <ProfileMenu
          onOpenAccountModal={() => {
            openAccountModal()
          }}
        />
      }
      placement="bottom-end"
    >
      <div className="cursor-pointer" data-testid="profile-button">
        <UserAvatar url={context?.customer?.avatar_url || user?.picture} />
      </div>
    </Floating>
  )
}

export default ProfileButton
