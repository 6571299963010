import { ReactNode } from 'react'
import { cn } from '@/libs'

interface SidebarSectionProps {
  children: ReactNode
  label?: ReactNode
  footer?: boolean
}

export const SidebarSection = ({ children, label, footer }: SidebarSectionProps) => (
  <div className={cn('mb-3 last-of-type:mb-0', footer && 'mt-auto')}>
    {label && <div className="mb-1 px-3 text-[10px] uppercase text-text-tertiary">{label}</div>}
    <div>{children}</div>
  </div>
)
