import { BlockError, EventActionNodeType } from '../../campaigns/types'
import AddStoreEditor from './AddStoreEditor'
import { AddStoreItemsNode, AddStoreNode, Node, RemoveItemsStoreNode, SortStoreItemsNode } from '../../../api/dashboard'
import SettingsHeader from '../../campaigns/SettingsHeader'
import ErrorChip from '../../../components/ErrorChip'
import AddStoreItemsNodeEditor from './AddStoreItemsNodeEditor'
import RemoveItemsNodeEditor from './RemoveItemsNodeEditor'
import SortItemsNodeEditor from './SortItemsNodeEditor'

export const StoreNodeEditor = (props: {
  node: Node
  error?: BlockError | null
  onClose: () => void
  setNode: (node: Node) => void
}) => {
  let editor = <></>
  switch (props.node?.model_type) {
    case EventActionNodeType.AddStoreNode:
      editor = <AddStoreEditor error={props.error} node={props.node as AddStoreNode} setNode={props.setNode} />
      break
    case EventActionNodeType.AddStoreItemsNode:
      return (
        <AddStoreItemsNodeEditor
          error={props.error}
          node={props.node as AddStoreItemsNode}
          setNode={props.setNode}
          onClose={props.onClose}
        />
      )
    case EventActionNodeType.RemoveItemsStoreNode:
      editor = (
        <RemoveItemsNodeEditor error={props.error} node={props.node as RemoveItemsStoreNode} setNode={props.setNode} />
      )
      break

    case EventActionNodeType.SortStoreItemsNode:
      return (
        <SortItemsNodeEditor
          onClose={props.onClose}
          error={props.error}
          node={props.node as SortStoreItemsNode}
          setNode={props.setNode}
        />
      )
  }

  return (
    <div>
      <SettingsHeader node={props.node} onClose={props.onClose} />
      <div className="p-6">
        {editor}
        <div style={{ height: '24px' }} />
        {props.error && props.error?.blockId == props.node?.id && <ErrorChip text={props.error?.errorText} />}
      </div>
    </div>
  )
}
