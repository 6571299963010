import { useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { Badge, Button, FieldGroup, Input } from '../../../../components/ui'
import { Textarea } from '../../../../components/ui/Textarea'
import { Plus, XClose } from '../../../../components/icons'
import { WebhookFormData, WebhookFormStage } from './WebhookForm'
import { stageMotionProps } from './stageMotionProps'
import { cn } from '../../../../libs/cn'
import { urlRegexp } from '../../../../libs/string'
import { useNavigateBack } from '../../../../libs/hooks'
import { GAME_WEBHOOKS_PATH } from '../../../../libs/routerPaths'

interface GeneralStageProps {
  setStage: (stage: WebhookFormStage) => void
}

export const GeneralStage = ({ setStage }: GeneralStageProps) => {
  const { t } = useTranslation()
  const {
    formState: { errors, isSubmitting },
    register,
    watch,
    setValue,
  } = useFormContext<WebhookFormData>()
  const back = useNavigateBack({ fallback: GAME_WEBHOOKS_PATH })

  const events = watch('events')

  const onClickSelectEvents = () => {
    setStage('select-events')
  }

  const onClickRemoveEvent = (event: string) => {
    setValue(
      'events',
      events.filter(e => e !== event),
    )
  }

  return (
    <motion.div className={cn('flex size-full flex-col')} {...stageMotionProps}>
      <div className="mb-6">
        <h2 className="mb-3 text-title-t1">{t('webhook.form.header.title')}</h2>
        <p
          className="text-paragraph-lg text-text-secondary"
          dangerouslySetInnerHTML={{ __html: t('webhook.form.header.sub') }}
        />
      </div>

      <div className="mb-6">
        <FieldGroup label={t('webhook.form.endpoint-url')} required={true}>
          <Input
            {...register('url', {
              required: t('validation.required'),
              pattern: { value: urlRegexp, message: t('validation.invalid_url') },
              maxLength: {
                message: t('validation.maxLength', {
                  field: t('webhook.form.endpoint-url'),
                  value: 255,
                }),
                value: 255,
              },
            })}
            placeholder="https://example.com"
            autoFocus={true}
            errors={errors}
          />
        </FieldGroup>

        <FieldGroup label={t('webhook.form.desc')}>
          <Textarea
            {...register('description', {
              maxLength: {
                message: t('validation.maxLength', {
                  field: t('webhook.form.desc'),
                  value: 255,
                }),
                value: 255,
              },
            })}
            placeholder={t('webhook.form.description-placeholder')}
            errors={errors}
          />
        </FieldGroup>
      </div>

      <FieldGroup>
        <div className="mb-6 text-title-t5">{t('webhook.form.select-events.title')}</div>

        {!!events.length && (
          <div className="mb-4 flex flex-wrap gap-3">
            {events.map(event => (
              <Badge key={`event-${event}`} variant="brand-blue-tertiary">
                <span className="truncate">{t(`webhook.event.${event}.label`)}</span>
                <button
                  className="-mx-0.5 cursor-pointer rounded-md p-0.5 transition-all hover:text-text-brand-primary"
                  type="button"
                  onClick={() => onClickRemoveEvent(event)}
                >
                  <XClose size={16} />
                </button>
              </Badge>
            ))}
          </div>
        )}

        <Button variant="secondary-brand" type="button" onClick={onClickSelectEvents}>
          <Plus size={14} />
          <span>{t('webhook.form.select-events.button')}</span>
        </Button>
      </FieldGroup>

      <div className="mt-auto flex gap-3">
        <Button variant="primary" size="lg" type="submit" isLoading={isSubmitting}>
          {t('webhook.form.add-endpoint')}
        </Button>
        <Link {...back}>
          <Button size="lg" type="button">
            {t('webhook.form.cancel')}
          </Button>
        </Link>
      </div>
    </motion.div>
  )
}
