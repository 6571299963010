import { InputHTMLAttributes, MouseEvent, forwardRef, useImperativeHandle, useRef } from 'react'
import { Search, XCircle } from '../../icons'
import { cn } from '../../../libs/cn'

interface TableSearchProps extends InputHTMLAttributes<HTMLInputElement> {
  showClear?: boolean
  fullWidth?: boolean
}

export const TableSearch = forwardRef<HTMLInputElement, TableSearchProps>(function TableSearch(
  { fullWidth, ...rest },
  ref,
) {
  const innerRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => innerRef.current!, [])

  const onMouseDownClear = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
  }

  const onClickClear = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!innerRef?.current) {
      return
    }
    // Imitation of the native onChange event.
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set
    nativeInputValueSetter?.call(innerRef.current, '')
    innerRef.current.dispatchEvent(new Event('change', { bubbles: true }))
  }

  return (
    <div className={cn('relative flex ', fullWidth ? 'w-full' : 'w-max')}>
      <input
        {...rest}
        ref={innerRef}
        className={cn(
          'peer h-8 w-full text-ellipsis rounded-md border border-transparent px-3 pl-[38px] pr-10 text-text-tertiary outline-none transition-colors hover:bg-fg-primary-hover focus:border-border-primary focus:bg-fg-primary-alt focus:text-text-secondary',
          rest.className,
        )}
        type="text"
      />
      <Search className="pointer-events-none absolute left-3 top-1/2 -translate-y-1/2 text-text-tertiary transition-colors peer-[:focus]:text-text-secondary" />
      <button
        className="invisible absolute right-1 top-1/2 flex h-6 -translate-y-1/2 items-center justify-center rounded bg-transparent px-2 text-text-quarterary-hover transition-colors hover:bg-fg-secondary-alt active:bg-fg-primary-alt active:text-text-tertiary peer-[:not(:placeholder-shown):focus]:visible"
        onMouseDown={onMouseDownClear}
        onClick={onClickClear}
      >
        <XCircle />
      </button>
    </div>
  )
})
