import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ItemBulkDelete } from '../../../api/dashboard'
import { dashboardClient } from '../../../api'

interface ItemBulkDeleteMutate {
  data: ItemBulkDelete[]
}

/**
 *
 * @param companyId
 * @param gameId
 */
export const useItemBulkDeleteMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ data }: ItemBulkDeleteMutate) => dashboardClient.v1.bulkDeleteItems(companyId, gameId, data),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['items', gameId], exact: false })
    },
  })
}
