import styled from '@emotion/styled'
import i18next from 'i18next'

import { Campaign } from '../../../../api/dashboard'
import { getDateFormatParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'

const Header = styled.div`
  color: ${p => p.theme.palette.text.secondary};
  font-size: 12px;
  font-weight: 600;
`

const DateText = styled.span`
  color: ${p => p.theme.palette.text.tertiary};
  font-size: 12px;
  font-weight: 400;
`

const Label = styled.span`
  color: ${p => p.theme.palette.text.tertiary};
  font-size: 12px;
  font-weight: 500;
`

const TimeLimitBox = styled.div`
  width: 176px;
`

const TimeBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export default function TimeLimitInfo({ campaign }: { campaign: Campaign }) {
  const { t } = useTranslation()
  return (
    <TimeLimitBox>
      <Header>{i18next.t('campaign.editor.campaign-time-limit')}</Header>

      {campaign.start_at && (
        <TimeBox>
          <Label>{t('campaign.editor.campaign-time-limit.starts')}</Label>
          <DateText>{t('intl.DateTime', getDateFormatParams(campaign.start_at))}</DateText>
        </TimeBox>
      )}
      {campaign.end_at && (
        <TimeBox>
          <Label>{t('campaign.editor.campaign-time-limit.end')}</Label>
          <DateText>{t('intl.DateTime', getDateFormatParams(campaign.end_at))}</DateText>
        </TimeBox>
      )}
    </TimeLimitBox>
  )
}
