import { Box } from '@mui/material'
import { Position } from 'reactflow'

import { useTheme } from '@mui/material/styles'
import { DescBlock, IOHandle, getNodeHoverStyle, getSvgHoverStyle, hasNextNode } from './common'
import { OctagonIcon, getNodeColor, getNodeIcon } from '../icons'
import { IBlockProps } from '../types'
import { NextNode } from '../components/NextNode/NextNode'

export default function MobilePopupNode(props: IBlockProps) {
  const theme = useTheme()
  const nodeColor = getNodeColor(props.data.node.model_type, theme)
  return (
    <>
      <IOHandle color={theme.palette.purple['A100']} position={Position.Top} type="target" id="input" />
      <Box
        sx={{
          position: 'relative',
          cursor: 'pointer',
          ...getNodeHoverStyle(nodeColor),
          ...getSvgHoverStyle(nodeColor),
        }}
        width={theme.spacing(8.5)}
        height={theme.spacing(8.5)}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <OctagonIcon shadowColor={props.selected ? nodeColor : ''} style={{ color: nodeColor }} />
        <Box
          sx={{ position: 'absolute' }}
          component="span"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          {getNodeIcon(props.data.node.model_type, theme)}
        </Box>

        <DescBlock node={props.data.node} width={'224px'} selected={props.selected} />
        <NextNode {...props} />
      </Box>
      <IOHandle
        color={theme.palette.purple['A100']}
        hasNext={hasNextNode(props.data.graph, props.data.node)}
        position={Position.Bottom}
        type="source"
        id="output"
      />
    </>
  )
}
