import { HTMLAttributes, ReactNode } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { cn, getMotionProps } from '@/libs'

interface TableZeroStateProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title: ReactNode
  message?: ReactNode
  buttons?: ReactNode
}

export const buttonMotionProps = getMotionProps({
  initial: { y: 4, marginTop: 0, height: 0, opacity: 0 },
  animate: { y: 0, marginTop: 24, height: 'auto', opacity: 1 },
  exit: { y: -4, marginTop: 0, height: 0, opacity: 0 },
})

export const TableZeroState = ({ title, message, buttons, ...rest }: TableZeroStateProps) => (
  <div {...rest} className={cn('h-full', rest.className)}>
    <div className="flex h-full items-center justify-center gap-6 rounded-xl bg-fg-primary-hover">
      <div className="flex w-[358px] flex-col">
        <span className="text-[16px] font-semibold text-text-primary">{title}</span>
        {message && <span className="mt-3 text-[14px] text-text-tertiary">{message}</span>}
        <AnimatePresence initial={false}>
          {buttons && (
            <motion.div {...buttonMotionProps} className="flex shrink-0 gap-2">
              {buttons}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  </div>
)
