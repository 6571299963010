import { useContext, useEffect, useRef, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

import { ReactFlowProvider, Viewport } from 'reactflow'
import 'reactflow/dist/style.css'

import i18next from 'i18next'

import { Campaign, ConditionNode, GraphRoot, Node, ResourceState, SegmentGroupRead } from '../../api/dashboard'
import { AnyNode, BlockError, EventActionNodeType } from '../campaigns/types'
import { dashboardClient } from '../../api'
import { START_BLOCK_WIDTH } from '../campaigns/blocks/StartBlock'
import { Flow, FlowApi } from '../campaigns/Flow'
import { uuid4 } from '../../util'
import { ROOT_NODE_ID, hasMoreOneChildren } from '../campaigns/Settings'

import { calculateWarning } from '../campaigns/util'
import { NODE_VALIDATORS } from '../campaigns/validation'
import { GAME_SEGMENT_GROUPS_PATH } from '../../libs/routerPaths'
import { getHubEditorHeaderHeight } from '../../Settings'
import ConditionEditor from '../campaigns/expr/ConditionEditor'
import { DrawerSidebar } from '../../components/ui/DrawerSidebar'
import { Button, ConfirmDialog } from '../../components/ui'
import CenteredProgress from '../../components/CenteredProgress'
import { Alert, Snackbar } from '@mui/material'
import { NodeFieldEditor } from '../campaigns/editors/NodeEditor'
import { CampaignContext, EditorMode, ICampaignContext } from '../campaigns/Context'
import { Header } from '../../components/ui/Header'
import { BackIcon } from '../../icons/Icons'
import { useGameSettingsQuery } from '../../api/useGameSettingsQuery'
import { canEdit } from '../../security'
import { AuthContext, IAuthContext } from '../../Context'
import { DescContext } from '../campaigns/blocks/descriptions/types'
import { useSegmentsQuery } from './api/useSegmentsQuery'
import { DEFAULT_LOAD_LIMIT, useGameItems } from '../../api/useGameItems'
import { ToastSeverity } from '../../components/ui/Toast/types'
import { useToast } from '../../components/ui/Toast/useToast'
import { useBanner } from '@/libs/hooks/useBanner'
import { cn } from '@/libs'

export default function SegmentGroupGraphEditor() {
  const theme = useTheme()
  const navigate = useNavigate()
  const { getStickyTop, getStickyHeight } = useBanner()
  const { companyId, gameId, groupId } = useParams() as {
    companyId: string
    gameId: string
    groupId: string
  }

  const [loading, setLoading] = useState(true)
  const [graph, setGraph] = useState<GraphRoot>({} as GraphRoot)
  const [segmentGroup, setSegmentGroup] = useState<SegmentGroupRead | null>(null)

  const [error, setError] = useState<BlockError | null>(null)
  const [viewPort, setViewPort] = useState<Viewport | undefined>(undefined)
  const [selectedBlockId, setSelectedBlockId] = useState('')
  const [selectedEdgeId, setSelectedEdgeId] = useState('')
  const [nodeSettingsVisible, setNodeSettingsVisible] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [showConfirmBack, setShowConfirmBack] = useState(false)
  const [confirmSaveWithWarning, setConfirmSaveWithWarning] = useState('')
  const { data: segments = [] } = useSegmentsQuery(companyId, gameId, {
    limit: 100,
  })
  const { data: items = [] } = useGameItems(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })

  const showToast = useToast()
  const context = useContext(AuthContext) as IAuthContext
  const isReadonly = !canEdit(context.customer)

  const { data: settings } = useGameSettingsQuery(companyId, gameId)

  const flowRef = useRef<FlowApi | null>(null)

  useEffect(() => {
    loadGraph()
  }, [gameId])

  useEffect(() => {
    if (!selectedBlockId) {
      setNodeSettingsVisible(false)
      return
    }

    if (!error) {
      setNodeSettingsVisible(true)
      return
    }

    setNodeSettingsVisible(!error.useToast)
  }, [selectedBlockId])

  const setGraphAndDirty = (newGraph: GraphRoot) => {
    setIsDirty(true)
    setGraph({
      ...newGraph,
    })
  }

  const setNode = (newNode: Node) => {
    setGraph({
      ...graph,
      nodes: {
        ...graph.nodes,
        [newNode.id as string]: {
          ...newNode,
        },
      },
    })
    setIsDirty(true)
  }

  const loadGraph = async () => {
    setLoading(true)
    try {
      let { data: c } = await dashboardClient.v1.getSegmentGroup(companyId, gameId, groupId)
      setSegmentGroup(c)

      let { data: item } = await dashboardClient.v1.getSegmentGroupGraph(companyId, gameId, groupId)

      if (!item) {
        let x = document.body.offsetWidth / 2 - START_BLOCK_WIDTH / 2
        item = {
          id: ROOT_NODE_ID,
          nodes: {},
          styles: {},
          title: '',
          x: x - (x % 20),
          y: 20,
          model_type: EventActionNodeType.GraphRoot,
        }
      } else if (item.view_port) {
        setViewPort(item.view_port)
      }

      setGraph(item)
    } finally {
      setLoading(false)
    }
  }

  const getContextValue = () => {
    return {
      segmentGroupId: groupId,
      isReadOnly: isReadonly,
      playerCustomAttributes: settings?.player_attributes,
      campaign: {
        type: segmentGroup?.name || '',
        name: segmentGroup?.name || '',
      } as Campaign,
      graph: graph,
      mode: EditorMode.Segment,
    } as ICampaignContext
  }

  const validate = (): BlockError | null => {
    for (let id in graph.nodes) {
      let node = graph.nodes[id]

      let [warning, , itemName] = calculateWarning(node, graph)
      if (warning) {
        return {
          useToast: true,
          blockId: id,
          errorText: i18next.t('campaign.block.exit.warning.toast', { name: itemName }),
          errors: [],
        }
      }

      let validator = NODE_VALIDATORS[node.model_type]
      if (!validator) {
        continue
      }

      let errors = validator(
        node as AnyNode,
        {
          segments: segments,
          items: items,
          stores: [],
          graph: graph,
        } as DescContext,
      )

      if (errors.length) {
        return {
          blockId: id,
          errorText: errors[0].message,
          errors: errors,
        }
      }
    }
    return null
  }

  const onConfirmSaveWithWarning = () => {
    setConfirmSaveWithWarning('')
    saveClick(true)
  }

  const saveClick = async (skipWarnings: boolean = false) => {
    let error = validate()
    if (error) {
      if (error.useToast) {
        if (!skipWarnings) {
          setConfirmSaveWithWarning(error.errorText)
          // setError(error)
          // setSelectedBlockId(error.blockId as string)
          return
        }
      } else {
        showError(error)
        return
      }
    }

    graph.view_port = viewPort

    setError(null)

    let { error: srvError } = await dashboardClient.v1.updateSegmentGroup(companyId, gameId, groupId, {
      graph: graph,
    })

    setIsDirty(false)

    if (srvError?.detail?.length) {
      let path = srvError.detail[0].loc
      let errorBlockId = path.find(it => graph.nodes && graph.nodes[it])
      if (errorBlockId) {
        showError({ blockId: errorBlockId, errorText: srvError.detail[0].msg, errors: [] })
      }
    } else {
      showToast({ message: i18next.t('saved'), severity: ToastSeverity.success })
    }
  }

  const showError = (error: BlockError) => {
    setSelectedBlockId(error.blockId as string)
    setNodeSettingsVisible(true)
    setError(error)
  }

  const duplicateNode = (id: string) => {
    if (!graph.nodes || isReadonly) {
      return
    }

    let node = JSON.parse(JSON.stringify(graph.nodes[id]))
    node.next_node_id = null
    node.true_node_id = null
    node.false_node_id = null
    node.x += hasMoreOneChildren(node.model_type) ? 300 : 100
    node.y += hasMoreOneChildren(node.model_type) ? 300 : 100

    node.id = `node_${uuid4()}`

    graph.nodes[node.id] = node

    setGraphAndDirty(graph)

    setSelectedBlockId(node.id)
  }

  const onBackClick = () => {
    if (isDirty && !isReadonly) {
      setShowConfirmBack(true)
    } else {
      onBackClickConfirm()
    }
  }

  const onBackClickConfirm = () => {
    navigate(generatePath(GAME_SEGMENT_GROUPS_PATH, { companyId, gameId }))
  }

  const closePanels = () => {}

  let isCondEdit = graph.nodes && graph.nodes[selectedBlockId]?.model_type == EventActionNodeType.ConditionNode

  return (
    <div className={cn('absolute left-0 z-50 w-full bg-white', getStickyTop(), getStickyHeight())}>
      <CampaignContext.Provider value={getContextValue()}>
        <div className="flex size-full flex-col justify-start">
          <Header
            title={segmentGroup?.name || ''}
            titleAction={
              <Button onClick={onBackClick} color="secondary" style={{ marginRight: '8px' }}>
                <BackIcon />
                {i18next.t('Back')}
              </Button>
            }
          >
            <Button variant="primary" onClick={() => saveClick(false)} disabled={!isDirty}>
              {i18next.t('Save2')}
            </Button>
          </Header>

          {showConfirmBack && (
            <ConfirmDialog
              color="error"
              cancelButtonText={i18next.t('campaign.confirm.no-stay')}
              confirmButtonText={i18next.t('campaign.confirm.yes')}
              subMessage={i18next.t('campaign.confirm.lost-changes')}
              onCancel={() => setShowConfirmBack(false)}
              onConfirm={onBackClickConfirm}
            />
          )}

          {confirmSaveWithWarning && (
            <ConfirmDialog
              cancelButtonText={i18next.t('campaign.confirm.no-stay')}
              confirmButtonText={i18next.t('Save')}
              subMessage={i18next.t(confirmSaveWithWarning) + '\n' + i18next.t('campaign.confirm.save-with-warnings')}
              onCancel={() => setConfirmSaveWithWarning('')}
              onConfirm={onConfirmSaveWithWarning}
            />
          )}

          <div
            className="relative flex flex-row justify-start bg-fg-primary"
            style={{
              height: `calc(100% - ${getHubEditorHeaderHeight(theme)})`,
            }}
          >
            {graph?.nodes && (
              <DrawerSidebar
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                open={nodeSettingsVisible && !!selectedBlockId && selectedBlockId != ROOT_NODE_ID}
                width="840px"
              >
                <div style={{ pointerEvents: isReadonly ? 'none' : 'all', opacity: isReadonly ? 0.6 : 1 }}>
                  {isCondEdit ? (
                    <ConditionEditor
                      className="p-6"
                      error={error}
                      onClose={() => {
                        setSelectedBlockId('')
                        setNodeSettingsVisible(false)
                        closePanels()
                        if (error?.blockId == selectedBlockId) {
                          setError(null)
                        }
                      }}
                      node={graph.nodes[selectedBlockId] as ConditionNode}
                      setNode={n => setNode(n as Node)}
                    />
                  ) : (
                    <NodeFieldEditor
                      error={error}
                      onClose={() => {
                        setSelectedBlockId('')
                        setNodeSettingsVisible(false)
                        closePanels()
                      }}
                      node={graph.nodes[selectedBlockId]}
                      setNode={setNode}
                    />
                  )}
                </div>

                {!isReadonly && (
                  <div className="mt-auto flex gap-2 p-6">
                    <Button onClick={() => duplicateNode(selectedBlockId)} variant="primary">
                      {i18next.t('campaign.editor.duplicate-node')}
                    </Button>
                    <Button
                      onClick={() => {
                        flowRef.current?.removeBlock(selectedBlockId)
                        setNodeSettingsVisible(false)
                      }}
                      variant="error"
                    >
                      {i18next.t('campaign.editor.delete-node')}
                    </Button>
                  </div>
                )}
              </DrawerSidebar>
            )}

            <Box
              sx={{
                backgroundColor: theme.palette.background.primary,
                flexGrow: 1,
                height: '100%',
                position: 'relative',
              }}
            >
              {loading ? (
                <CenteredProgress />
              ) : (
                graph && (
                  <ReactFlowProvider>
                    <Flow
                      actions={[EventActionNodeType.ConditionNode, EventActionNodeType.AddToSegmentNode]}
                      ref={flowRef}
                      setGraphAndDirty={setGraphAndDirty}
                      isReadOnly={isReadonly}
                      graph={graph}
                      selectedBlockId={selectedBlockId}
                      selectedEdgeId={selectedEdgeId}
                      onViewPortChanged={vp => setViewPort(vp)}
                      viewPort={viewPort}
                      onNodeMoved={() => setIsDirty(true)}
                      onBlockSelected={id => {
                        setSelectedEdgeId('')
                        closePanels()
                        setSelectedBlockId(id)
                      }}
                      onEdgeClick={id => {
                        setSelectedEdgeId(id)
                      }}
                      setNode={setNode}
                      setGraph={setGraph}
                      setSelectedBlockId={setSelectedBlockId}
                      setSelectedEdgeId={setSelectedEdgeId}
                    />
                  </ReactFlowProvider>
                )
              )}
            </Box>
          </div>
          {error?.useToast && (
            <Snackbar open={true} autoHideDuration={60000} onClose={() => setError(null)}>
              <Alert onClose={() => setError(null)} severity="error" variant="filled">
                {error.errorText}
              </Alert>
            </Snackbar>
          )}
        </div>
      </CampaignContext.Provider>
    </div>
  )
}
