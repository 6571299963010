import {
  AddStoreItemsNode,
  AddStoreNode,
  ConditionNode,
  CreateCouponNode,
  CreateItemBannerActionNode,
  CreateVirtualSKUNode,
  GraphRoot,
  ItemBonusOfferActionNode,
  ItemDiscountOfferActionNode,
  ItemOfferActionNode,
  Node,
  RemoveItemsStoreNode,
} from '../../api/dashboard'

export enum EventActionNodeType {
  GraphRoot = 'GraphRoot',
  TimerNode = 'TimerNode',

  SplitNode = 'SplitNode',
  ConditionNode = 'ConditionNode',

  WebhookActionNode = 'WebhookActionNode',
  EmailActionNode = 'EmailActionNode',
  MobilePushActionNode = 'MobilePushActionNode',
  BrowserPushNotificationActionNode = 'BrowserPushNotificationActionNode',
  MobilePopupActionNode = 'MobilePopupActionNode',
  BrowserPopupNotificationActionNode = 'BrowserPopupNotificationActionNode',

  ItemOfferActionNode = 'ItemOfferActionNode',
  ItemDiscountOfferActionNode = 'ItemDiscountOfferActionNode',
  ItemBonusOfferActionNode = 'ItemBonusOfferActionNode',
  CreateItemBannerActionNode = 'CreateItemBannerActionNode',
  CreateCouponNode = 'CreateCouponNode',
  AddToSegmentNode = 'AddToSegmentNode',
  AddStoreItemsNode = 'AddStoreItemsNode',
  RemoveItemsStoreNode = 'RemoveItemsStoreNode',
  AddStoreNode = 'AddStoreNode',
  SortStoreItemsNode = 'SortStoreItemsNode',
  CreateVirtualSKUNode = 'CreateVirtualSKUNode',
}

export type AnyNode =
  | CreateCouponNode
  | ItemBonusOfferActionNode
  | ItemDiscountOfferActionNode
  | ConditionNode
  | CreateItemBannerActionNode
  | AddStoreNode
  | RemoveItemsStoreNode
  | AddStoreItemsNode
  | ItemOfferActionNode
  | CreateVirtualSKUNode

export interface ErrorInfo {
  field: string
  message: string
}

export interface BlockError {
  blockId: string | number
  errorText: string
  errors: ErrorInfo[]
  useToast?: boolean
}

export interface IBlockProps {
  data: {
    node: Node
    graph: GraphRoot
    setNode: (node: Node) => void
    addClick: (anchorEl: HTMLElement, isTrue?: boolean) => void
    removeClick: () => void
  }
  selected: boolean
  id: string
  isConnectable: boolean
}

export enum NodeResult {
  TIMER_DONE = 'timer_done',
  NO_EMAIL = 'no_email',
  ITEM_NOT_FOUND = 'item_not_found',
  CONTEXT_ITEM_NOT_FOUND = 'context_item_not_found',
  OFFER_NOT_FOUND = 'offer_not_found',
  COUPON_NOT_FOUND = 'coupon_not_found',
  PREV_NODE_NOT_FOUND = 'prev_node_not_found',
  PREV_NODE_UNSUPPORTED_TYPE = 'prev_node_unsupported_type',

  SENT = 'sent',
  NOT_IMPLEMENTED = 'not_implemented',
  CUSTOM = 'custom',
  ERROR = 'error',
  NO_WEBHOOK = 'no_webhook',
  SPAM_LIMIT = 'spam_limit',
  WEBHOOK_ERROR = 'webhook_error',
}
