import { CustomerCompany } from '../../api/dashboard'
import { useCallback, useContext } from 'react'
import { AuthContext, IAuthContext } from '../../Context'

type CanGuardFn = (customer: CustomerCompany | null | undefined) => boolean

/**
 *
 */
export const useCanGuard = () => {
  const context = useContext(AuthContext) as IAuthContext
  const can = useCallback((fn: CanGuardFn) => fn(context.customer), [context.customer])
  return { can }
}
