import { ReactNode } from 'react'

export const GeneralBlock = (props: { title: string; children: ReactNode }) => {
  return (
    <div
      className="flex h-[129px] w-[33%] flex-col rounded-2xl1 border-2 border-border-primary p-6"
      style={{
        boxShadow: '0px 1px 2px 0px #1018280D',
      }}
    >
      <div className="font-medium text-text-tertiary">{props.title}</div>
      <div className="my-3 border-t border-border-secondary" />
      <div>{props.children}</div>
    </div>
  )
}
