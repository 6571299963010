import { ReactElement } from 'react'
import { create } from 'zustand'
import { ModalParams, ToastSeverity } from '@/ui'

interface ModalStoreState {
  modals: { id: string; element: ReactElement; params: Partial<ModalParams> }[]
  toasts: { id: string; message: string; severity: ToastSeverity }[]
  push: (id: string, element: ReactElement, params: Partial<ModalParams>) => void
  pushToast: (id: string, message: string, severity: ToastSeverity) => void
  removeCurrentToast: () => void
  shift: (id: string) => void
  reset: () => void
}

export const modalStore = create<ModalStoreState>(set => {
  return {
    modals: [],
    toasts: [],
    push: (id, element, params = {}) => {
      set(s => ({ ...s, modals: [...s.modals, { id, element, params }] }))
    },
    pushToast: (id, message, severity) => {
      set(s => ({ ...s, toasts: [...s.toasts, { id, message, severity }] }))
    },
    removeCurrentToast: () => {
      set(s => ({ ...s, toasts: s.toasts.slice(0, -1) }))
    },
    shift: id => {
      set(s => ({
        ...s,
        modals: s.modals.filter(modal => modal.id !== id),
      }))
    },
    reset: () => {
      set(s => {
        return { ...s, modals: [] }
      })
    },
  }
})
