import { useTheme } from '@mui/material/styles'
import { Position } from 'reactflow'
import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { IOHandle, hasNextNode } from './common'
import i18next from 'i18next'
import { CampaignContext, ICampaignContext } from '../Context'
import { isCustomEvent } from '../Settings'
import { IBlockProps } from '../types'
import { NextNode } from '../components/NextNode/NextNode'

export const START_BLOCK_WIDTH = 228

export default function StartBlock(props: IBlockProps) {
  const theme = useTheme()
  const context = useContext(CampaignContext) as ICampaignContext

  return (
    <>
      <IOHandle color={'transparent'} position={Position.Top} type="target" id="input" />
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          position: 'relative',
          padding: theme.spacing(1.25),
          borderRadius: theme.spacing(8),
        }}
        width={START_BLOCK_WIDTH + 'px'}
        alignItems="center"
        justifyContent="center"
        height="44px"
        display="flex"
      >
        <Typography variant="h6" color={theme.palette.text.contrastText} textAlign="center">
          {isCustomEvent(context.campaign.type)
            ? context.campaign.type
            : i18next.t(`campaign.type.${context.campaign.type}`)}
        </Typography>

        <NextNode {...props} />
      </Box>
      <IOHandle
        color={theme.palette.primary.main}
        position={Position.Bottom}
        hasNext={hasNextNode(props.data.graph, props.data.node)}
        type="source"
        id="output"
      />
    </>
  )
}
