import { Box } from '@mui/material'
import { Handle, Position } from 'reactflow'
import { useContext, useMemo } from 'react'
import {
  DescBlock,
  IOHandle,
  IOHandleSize,
  getIOStyle,
  getLineColor,
  getNodeHoverStyle,
  getWarningLineColor,
} from './common'
import { useTheme } from '@mui/material/styles'
import { getNodeColor, getNodeIcon } from '../icons'
import EdgeLabel from './EdgeLabel'
import PlusButton from './PlusButton'
import { ADD_NEXT_PREFIX_ID } from '../Settings'
import { GraphRoot, Node, SplitNode } from '../../../api/dashboard'
import { ExitNode } from '../components/ExitNode/ExitNode'
import { calculateWarning } from '../util'
import { IBlockProps } from '../types'
import { BranchLeft } from '../components/BranchLeft/BranchLeft'
import { BranchRight } from '../components/BranchRight/BranchRight'
import { CampaignContext, ICampaignContext } from '../Context'

function hasLeftNextNode(graph: GraphRoot, node: SplitNode): boolean {
  let id = node.distribution[0].next_node_id

  return !!(id && graph.nodes && graph.nodes[id])
}

function hasRightNextNode(graph: GraphRoot, node: SplitNode): boolean {
  let id = node.distribution[1].next_node_id

  return !!(id && graph.nodes && graph.nodes[id])
}

export default function SplitBlock(props: IBlockProps) {
  const theme = useTheme()
  const context = useContext(CampaignContext) as ICampaignContext
  const color = theme.palette.text.input
  const nodeColor = getNodeColor(props.data.node.model_type, theme)
  const [warningText, warningDescText] =
    useMemo(() => {
      return calculateWarning(props.data.node, props.data.graph)
    }, [props.data.graph]) || []

  const node = props.data.node as SplitNode

  return (
    <>
      <IOHandle color={color} position={Position.Top} type="target" id="input" />

      <Box
        sx={{
          position: 'relative',
          cursor: 'pointer',
          ...getNodeHoverStyle(nodeColor),
        }}
        width={theme.spacing(8.5)}
        height={theme.spacing(8.5)}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Box
          sx={{
            transform: 'rotate(45deg)',
            position: 'absolute',
            backgroundColor: theme.palette.background.content,
            border: `2px solid ${nodeColor}`,
            boxShadow: props.selected ? `1px 4px 22px 0px ${nodeColor}` : '',
          }}
          width="75%"
          height="75%"
          alignItems="center"
          justifyContent="center"
          display="flex"
        />

        <Box
          sx={{ position: 'absolute' }}
          width="100%"
          component="span"
          height="100%"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          {getNodeIcon(props.data.node.model_type, theme)}
        </Box>

        <DescBlock node={props.data.node as Node} width={'224px'} selected={props.selected} />
      </Box>
      <IOHandle color={color} position={Position.Bottom} type="source" id={'output'} isConnectable={false} />

      {!hasLeftNextNode(props.data.graph, node) && (
        <Box position="absolute" top={`calc(100% + ${theme.spacing(IOHandleSize / 2)})`} left="-149px">
          <BranchLeft
            animate={context.historyMode && node.random_value! < node.distribution[0].value}
            style={{ color: warningText ? getWarningLineColor(theme) : getLineColor(theme) }}
          />
          <EdgeLabel
            value={node.distribution[0].value + '%'}
            sx={{
              top: '82px',
              left: '66px',
            }}
          />
          <PlusButton
            visible={true}
            id={ADD_NEXT_PREFIX_ID + props.data.node.id}
            color={warningText ? getWarningLineColor(theme) : getLineColor(theme)}
            style={{
              position: 'absolute',
              top: '128px',
              left: '-5px',
            }}
            onClick={(node: HTMLElement) => props.data.addClick(node, false)}
          />
          <Handle
            type={'source'}
            id={'output-left'}
            position={Position.Bottom}
            isConnectable={true}
            style={{
              ...getIOStyle(theme),
              borderColor: warningText ? getWarningLineColor(theme) : getLineColor(theme),
              left: theme.spacing(1),
              bottom: theme.spacing(-1),
              zIndex: 0,
            }}
          />
          <ExitNode
            uiHandle={false}
            warningText={warningText}
            warningDescText={warningDescText}
            style={{
              left: '-27px',
              position: 'absolute',
              bottom: '-42px',
            }}
          />
        </Box>
      )}

      {!hasRightNextNode(props.data.graph, props.data.node as SplitNode) && (
        <Box position="absolute" top={`calc(100% + ${theme.spacing(IOHandleSize / 2)})`} right="-149px">
          <BranchRight
            animate={context.historyMode && node.random_value! > node.distribution[0].value}
            style={{ color: warningText ? getWarningLineColor(theme) : getLineColor(theme) }}
          />
          <EdgeLabel
            value={100 - node.distribution[0].value + '%'}
            sx={{
              top: '82px',
              left: '66px',
            }}
          />
          <PlusButton
            visible={true}
            id={ADD_NEXT_PREFIX_ID + props.data.node.id}
            color={warningText ? getWarningLineColor(theme) : getLineColor(theme)}
            style={{
              position: 'absolute',
              top: '128px',
              right: '-4px',
            }}
            onClick={(node: HTMLElement) => props.data.addClick(node, true)}
          />
          <Handle
            type={'source'}
            id={'output-right'}
            position={Position.Bottom}
            isConnectable={true}
            style={{
              ...getIOStyle(theme),
              borderColor: warningText ? getWarningLineColor(theme) : getLineColor(theme),
              left: 'auto',
              right: theme.spacing(-1),
              bottom: theme.spacing(-1),
              zIndex: 0,
            }}
          />
          <ExitNode
            uiHandle={false}
            warningText={warningText}
            warningDescText={warningDescText}
            style={{
              right: '-27px',
              position: 'absolute',
              bottom: '-42px',
            }}
          />
        </Box>
      )}
    </>
  )
}
