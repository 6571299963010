import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const Newton: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 14 14" ref={ref} size={14}>
      <g clipPath="url(#clip0_3220_49643)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8335 2.65745V3.17596C11.688 3.17596 10.7594 4.10456 10.7594 5.25004H10.2409C10.2409 4.10456 9.31231 3.17597 8.16683 3.17597V2.65745C9.31231 2.65745 10.2409 1.72885 10.2409 0.583374H10.7594C10.7594 1.72885 11.688 2.65745 12.8335 2.65745ZM8.75016 4.61242H5.21127V3.50004H4.12239V4.61242H0.583496V5.7248H5.93573C5.81259 6.17713 5.55035 6.82195 5.0575 7.6173C4.90777 7.85894 4.73774 8.11281 4.54541 8.3775C4.16766 7.83059 3.89602 7.30618 3.70517 6.83717H2.53975C2.78866 7.56623 3.20047 8.40959 3.83868 9.27471C3.13422 10.1051 2.22421 11.0133 1.06005 11.9648L1.74027 12.8334C2.89117 11.8928 3.81409 10.984 4.54776 10.1361C4.95383 10.5803 5.42449 11.0222 5.96821 11.4494L6.63212 10.5677C6.09153 10.1429 5.63422 9.70579 5.24848 9.27155C5.52745 8.90252 5.76921 8.54848 5.97754 8.21228C6.58784 7.22739 6.92644 6.37015 7.0554 5.7248H8.75016V4.61242ZM10.7737 8.16671H9.64325L7.5835 13.4167H8.71399L9.10247 12.4265H11.3145L11.703 13.4167H12.8335L10.7737 8.16671ZM10.2085 9.60743L10.9026 11.3765H9.51442L10.2085 9.60743ZM13.4168 5.70577V5.37764C13.0242 5.37764 12.7059 5.05935 12.7059 4.66671H12.3778C12.3778 5.05935 12.0595 5.37765 11.6668 5.37765V5.70577C12.0595 5.70577 12.3778 6.02407 12.3778 6.41671H12.7059C12.7059 6.02407 13.0242 5.70577 13.4168 5.70577Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3220_49643">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </IconBase>
  )
})
