import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const Check: AghanimIcon = forwardRef(function ({ strokeWidth, ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M20 6L9 17L4 12"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  )
})
