import { cn } from '../../../libs/cn'

export interface PageBlockProps {
  children: React.ReactNode
  className?: string
  title: string
  description?: string | React.ReactNode
}

export default function DescBlock(props: PageBlockProps) {
  return (
    <div className={cn('flex gap-[140px]', props.className)}>
      <div className="w-[332px]">
        <div className="sticky top-20 z-[1] bg-fg-primary text-xl font-semibold">{props.title}</div>
        <div className="sticky top-[110px] mt-3 text-text-tertiary">{props.description}</div>
      </div>
      <div className="w-[686px]">{props.children}</div>
    </div>
  )
}
