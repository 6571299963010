import { ReactNode } from 'react'
import styled from '@emotion/styled'

interface MenuGroupProps {
  children: ReactNode
  label: ReactNode
}

const StyledMenuGroup = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  &:last-child {
    margin-bottom: 0;
  }
`

const Label = styled.div`
  color: ${p => p.theme.palette.text.tertiary};
  margin-bottom: 6px;
  padding: 0 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
`

export const MenuGroup = ({ children, label }: MenuGroupProps) => (
  <StyledMenuGroup>
    <Label>{label}</Label>
    {children}
  </StyledMenuGroup>
)
