import { Link, generatePath, useParams } from 'react-router-dom'
import { CouponType, ItemRead, UserCouponRead } from '../../api/dashboard'
import i18next from 'i18next'
import { DEFAULT_LOAD_LIMIT, useGameItems } from '../../api/useGameItems'
import {
  ModalConfirm,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  useModal,
} from '../../components/ui'
import { usePlayerCouponQuery } from './api/usePlayerCouponQuery'
import { Trash } from '../../components/icons'
import { useContext, useState } from 'react'
import { AuthContext } from '../../Context'
import { canEdit } from '../../security'
import { useCouponDeleteMutate } from '../coupon/api/useCouponDeleteMutate'
import { SKU_ITEMS_DETAILS_PATH } from '../../libs/routerPaths'
import { DateTimeValue } from '@/components/ui/DateTimeValue'

export default function UserCouponsTable(props: { userId: string; endTs?: number; exclude_payment_number?: string }) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const LIMIT = 10

  const [page, setPage] = useState(1)

  const query = { offset: (page - 1) * LIMIT, limit: LIMIT }
  const { data: coupons = [], refetch, isLoading } = usePlayerCouponQuery(companyId, gameId, props.userId, query)
  const { mutateAsync: deleteMutateAsync } = useCouponDeleteMutate(companyId, gameId)
  const { data: items = [] } = useGameItems(companyId, gameId, { limit: DEFAULT_LOAD_LIMIT })

  const context = useContext(AuthContext)
  const canUserEdit = canEdit(context?.customer)

  const needShowPagination = () => {
    if (isLoading) {
      return false
    }
    if (coupons.length === 0 && page == 1) {
      return false
    }

    return true
  }

  const getCouponInfo = (it: UserCouponRead) => {
    switch (it.type) {
      case CouponType.FreeItem:
        return i18next.t('coupon.type.free_item')
      case CouponType.Discount:
        return i18next.t(`coupon.type.${it.type}`) + ': ' + it.discount_percent + '%'
      case CouponType.Bonus:
        return i18next.t(`coupon.type.${it.type}`) + ': ' + it.bonus_percent + '%'
    }
    return ''
  }

  const getItemInfo = (coupon: UserCouponRead) => {
    let item: ItemRead | undefined

    if (coupon.item_id) {
      item = items.find(it => it.id === coupon.item_id)
    } else if (coupon.order_item_id) {
      item = items.find(it => it.id === coupon.order_item_id)
    } else if (coupon.applicable_item_ids?.length) {
      item = items.find(it => it.id == coupon.applicable_item_ids![0])
    }

    if (item) {
      return (
        <Link to={generatePath(SKU_ITEMS_DETAILS_PATH, { companyId: companyId, gameId: gameId, itemId: item.id })}>
          {item.name}
        </Link>
      )
    }
  }

  const onDeleteConfirm = async (item: UserCouponRead) => {
    await deleteMutateAsync({ id: item.id })
    refetch()
  }

  const openDeleteModal = useModal<{ item: UserCouponRead }>(props => (
    <ModalConfirm
      color={'error'}
      subMessage={i18next.t('confirm.text', { name: props.item.code })}
      confirmButtonText={i18next.t('remove')}
      onConfirm={async () => await onDeleteConfirm(props.item)}
      {...props}
    />
  ))

  if (!isLoading && !coupons.length) {
    return (
      <TableZeroState
        className="mt-4 h-[147px]"
        title={i18next.t('coupon.no-codes')}
        message={i18next.t('coupon.no-codes.desc')}
      />
    )
  }

  if (isLoading) {
    return <TableRowSkeleton rowCount={5} columnCount={5} />
  }

  return (
    <>
      <Table>
        <TableRow variant="header">
          <TableCell>{i18next.t('coupon.code')}</TableCell>
          <TableCell>{i18next.t('coupon.type')}</TableCell>
          <TableCell>{i18next.t('coupon.item')}</TableCell>
          <TableCell>{i18next.t('coupon.expires_at')}</TableCell>
          <TableCell>{i18next.t('coupon.redeemed_at')}</TableCell>
          <TableCell width="15%" />
        </TableRow>

        {coupons?.map((it, idx) => (
          <TableRow key={it.id + '' + idx}>
            <TableCell>{it.code}</TableCell>
            <TableCell>{getCouponInfo(it)}</TableCell>
            <TableCell>{getItemInfo(it)}</TableCell>
            <TableCell>
              {it.expires_at ? <DateTimeValue value={it.expires_at} /> : i18next.t('coupon.no_expire')}
            </TableCell>
            <TableCell>{it.redeemed_at && <DateTimeValue value={it.redeemed_at} />}</TableCell>
            <TableCell width="15%">
              <div className="text-right">
                {!it.redeemed_at && canUserEdit && (
                  <TableRowEditButton
                    onChange={v => {
                      switch (v) {
                        case 'remove':
                          openDeleteModal({ item: it })
                          break
                      }
                    }}
                    options={[
                      {
                        icon: <Trash className="text-text-error-primary" />,
                        children: <span className="text-text-error-primary">{i18next.t('grid.remove')} </span>,
                        value: 'remove',
                      },
                    ]}
                  />
                )}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </Table>

      {needShowPagination() && (
        <Pagination
          style={{
            position: 'relative',
          }}
          hasNext={coupons.length >= query.limit}
          page={page}
          onPageChange={p => setPage(p)}
          pageItems={coupons.length}
        />
      )}
    </>
  )
}
