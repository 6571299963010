import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { ItemCategoryUpdate, StoreRead } from '../../../api/dashboard'

interface ItemCategoryUpdateQuery {
  id: string
  update: ItemCategoryUpdate
}

export const useItemCategoryUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, update }: ItemCategoryUpdateQuery) =>
      dashboardClient.v1.updateItemCategory(companyId, gameId, id, update),
    onSuccess: (_, variables) => {
      queryClient.setQueryData<StoreRead>(['item-category', gameId, variables.id], old => ({
        ...(old as StoreRead),
        ...variables.update,
      }))
      queryClient.invalidateQueries({ queryKey: ['item-category', gameId] })
    },
  })
}
