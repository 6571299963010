import { useState } from 'react'
import { Variants, motion } from 'framer-motion'

interface AiFallingStarProps {
  speed?: number
}

const getRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const generateStarVariants = (randomX: number, randomY: number, speed: number, delay: number): Variants => ({
  initial: { x: randomX, y: randomY, opacity: 0, rotate: 0, scale: 1 },
  animate: {
    x: 12,
    y: 12,
    opacity: 1,
    rotate: [300, 360],
    scale: [1, 0],
    transition: {
      duration: speed,
      repeat: Infinity,
      repeatType: 'loop',
      delay,
    },
  },
  exit: {
    rotate: 320,
    opacity: 0,
    scale: 0,
    transition: { duration: 0.5 },
  },
})

const Star = ({ speed, delay }: { speed: number; delay: number }) => {
  const [randomX] = useState(() => getRandomNumber(-48, 48 + 24))
  const [randomY] = useState(() => getRandomNumber(-48, 48 + 24))

  return (
    <motion.svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute"
      variants={generateStarVariants(randomX, randomY, speed, delay)}
    >
      <path
        d="M12 2C12.5021 2 12.9091 2.40701 12.9091 2.90909C12.9091 7.42779 16.5722 11.0909 21.0909 11.0909C21.593 11.0909 22 11.4979 22 12C22 12.5021 21.593 12.9091 21.0909 12.9091C16.5722 12.9091 12.9091 16.5722 12.9091 21.0909C12.9091 21.593 12.5021 22 12 22C11.4979 22 11.0909 21.593 11.0909 21.0909C11.0909 16.5722 7.42778 12.9091 2.90909 12.9091C2.40701 12.9091 2 12.5021 2 12C2 11.4979 2.40701 11.0909 2.90909 11.0909C7.42778 11.0909 11.0909 7.42779 11.0909 2.90909C11.0909 2.40701 11.4979 2 12 2Z"
        fill="currentColor"
      />
    </motion.svg>
  )
}

export const AiFallingStar = ({ speed = 3 }: AiFallingStarProps) => {
  const stars = Array.from({ length: 15 }, (_, i) => <Star key={i} delay={Math.random() * speed} speed={speed} />)
  return (
    <motion.div initial="initial" animate="animate" exit="exit" className="relative size-12 text-text-brand-primary">
      {stars}
    </motion.div>
  )
}
