import { AnimatePresence } from 'framer-motion'
import { Spinner } from '@/ui'
import { Check, ClockOutline } from '@/icons'
import { cn } from '@/libs'
import { GetStartedStepState } from '../types'
import { AiFallingStar } from './AiFallingStar'

interface StepStatusProps {
  pos: number
  img: string | undefined
  status: GetStartedStepState['status']
}

export const StepStatus = ({ pos, img, status }: StepStatusProps) => (
  <div className="relative z-10">
    <div
      className={cn(
        'flex size-12 shrink-0 items-center justify-center overflow-hidden rounded-xl border-2 border-border-primary bg-fg-primary text-center transition-colors',
        !!img && status === 'idle' && 'border-none text-text-quarterary',
        status === 'idle' && 'text-text-quarterary',
        status === 'loading' && 'border-fg-primary-alt bg-fg-primary-alt text-text-quarterary',
        status === 'generating' && 'border-none bg-fg-brand-tertiary text-text-brand-primary',
        status === 'review' && 'border-border-warning-ring bg-fg-warning-tertiary text-text-warning-solid',
        status === 'completed' && 'border-border-success-ring bg-fg-success-secondary text-text-success-solid',
      )}
    >
      <AnimatePresence>
        {status === 'generating' && (
          <div className="absolute left-0 top-0 -z-10 size-12">
            <AiFallingStar />
          </div>
        )}
      </AnimatePresence>

      {status === 'idle' && (
        <div className="-mb-0.5 font-nohemi text-[20px] font-medium leading-normal">
          {img ? <img src={img} className="size-full" data-categories="essential" alt="" /> : pos}
        </div>
      )}
      {status === 'loading' && <Spinner size={18} />}
      {status === 'generating' && <Spinner size={18} />}
      {status === 'review' && <ClockOutline size={18} />}
      {status === 'completed' && <Check size={18} strokeWidth={3} />}
    </div>
  </div>
)
