import './Loader.css'

export default function Loader() {
  return (
    <div className="loader">
      <span />
      <span />
      <span />
    </div>
  )
}
