import i18next from 'i18next'
import { PlusContained2 } from '@/icons'

export default function AddNewCampaignButton(props: { onClick: () => void }) {
  return (
    <div onClick={props.onClick} data-testid="add-new-campaign-button" className="inline-flex cursor-pointer flex-col">
      <div className="flex size-[180px] items-center justify-center rounded-2xl border border-dashed border-border-primary p-3">
        <div className="flex size-full items-center justify-center rounded-2xl bg-fg-primary-hover">
          <PlusContained2 className="text-text-quarterary" size={36} />
        </div>
      </div>
      <div className="mt-3 text-caption-md font-semibold text-text-secondary">{i18next.t('campaigns.add')}</div>
    </div>
  )
}
