import { CouponType, CreateCouponNode } from '../../../../api/dashboard'

import i18next from 'i18next'
import { DescContext } from './types'

export function getCouponDesc(node: CreateCouponNode, context: DescContext) {
  let subTitle = ''
  switch (node.type) {
    case CouponType.Bonus:
      subTitle = `${i18next.t('coupon.type.bonus')} ${node.bonus_percent}%`
      break
    case CouponType.Discount:
      subTitle = `${i18next.t('coupon.type.discount')} ${node.discount_percent}%`
      break
    case CouponType.FreeItem:
      let item = context.items.find(it => it.id == node.item_id)
      subTitle = `${i18next.t('coupon.type.free_item')} ${item?.name || ''}`
      break
  }
  return [node.title || i18next.t('campaign.node-type.CreateCouponNode'), subTitle]
}
