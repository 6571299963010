import { InputHTMLAttributes, ReactNode, forwardRef, useId } from 'react'
import { FieldErrors } from 'react-hook-form'
import styled from '@emotion/styled'
import shouldForwardProp from '@emotion/is-prop-valid'
import { css } from '@emotion/react'
import { ErrorMessage } from '@hookform/error-message'
import { FieldValidationMessage } from '../FieldValidationMessage'

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  color?: 'default' | 'success' | 'warning' | 'error'
  extraLeft?: ReactNode
  extraRight?: ReactNode
  errors?: FieldErrors
}

const StyledInput = styled('div', { shouldForwardProp })<{
  $color: InputProps['color']
}>`
  position: relative;
  display: flex;
  gap: 6px;
  width: 100%;
  border: 1px solid ${p => p.theme.palette.grey.borderPrimary};
  padding: 0 ${p => p.theme.spacing(1.5)};
  border-radius: 6px;
  align-items: center;
  height: ${p => p.theme.spacing(6)};
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  transition:
    background-color ease-in-out 160ms,
    border-color ease-in-out 160ms,
    box-shadow ease-in-out 160ms;

  font-size: 16px;
  line-height: 1;

  ${p =>
    p.$color === 'default' &&
    css`
      background: ${p.theme.palette.background.fgPrimary};
      border-color: ${p.theme.palette.grey.borderPrimary};
      color: ${p.theme.palette.text.secondary};

      &:has(input:hover),
      &:has(input:focus) {
        border-color: #cbd5e1;
        color: #334155;
        box-shadow: 0 0 0 2px rgba(0, 151, 228, 0.14);
      }

      &:has(input:focus) {
        color: #000000;
        border-color: ${p.theme.palette.primary.main};
      }
    `}

  ${p =>
    p.$color === 'success' &&
    css`
      background: ${p.theme.palette.background.fgPrimary};
      border-color: ${p.theme.palette.success.main};
      color: ${p.theme.palette.text.secondary};

      &:has(input:hover),
      &:has(input:focus) {
        color: #334155;
        box-shadow: 0 0 0 3px rgba(34, 197, 94, 0.14);
      }

      &:has(input:focus) {
        color: #000000;
        border-color: ${p.theme.palette.success.main};
      }
    `}

  ${p =>
    p.$color === 'warning' &&
    css`
      background: ${p.theme.palette.background.fgPrimary};
      border-color: ${p.theme.palette.warning.main};
      color: ${p.theme.palette.text.secondary};

      &:has(input:hover),
      &:has(input:focus) {
        color: #334155;
        box-shadow: 0 0 0 3px rgba(251, 146, 60, 0.14);
      }

      &:has(input:focus) {
        color: #000000;
        border-color: ${p.theme.palette.warning.main};
      }
    `}

  ${p =>
    p.$color === 'error' &&
    css`
      background: ${p.theme.palette.background.fgPrimary};
      border-color: ${p.theme.palette.error.main};
      color: ${p.theme.palette.text.secondary};

      &:has(input:hover),
      &:has(input:focus) {
        color: #334155;
        box-shadow: 0 0 0 3px rgba(240, 68, 56, 0.14);
      }

      &:has(input:focus) {
        color: #000000;
        border-color: ${p.theme.palette.error.main};
      }
    `}

  &:has(input:disabled) {
    background-color: #f1f5f9;
    border-color: #e2e8f0;
    color: #94a3b8;
    pointer-events: none;
  }

  & label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: text;
    opacity: 0;
    z-index: 0;
  }
`

const TextField = styled.input`
  position: relative;
  display: flex;
  border: none;
  padding: 0;
  width: 100%;
  height: 100%;
  color: inherit;
  z-index: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  font-family:
    'InterAg',
    'SF Pro Display',
    -apple-system,
    BlinkMacSystemFont,
    'Open Sans',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  line-height: 1;

  &::placeholder {
    color: #64748b;
  }
`

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { color = 'default', extraLeft, extraRight, errors, ...rest },
  ref,
) {
  const id = useId()
  return (
    <>
      <StyledInput $color={color}>
        {extraLeft}
        <TextField
          {...rest}
          id={id}
          onInput={e => {
            if (rest.type == 'number' && rest.min != undefined && (rest.min as number) >= 0) {
              let inp = e.target as HTMLInputElement
              inp.value = inp.value.replace(/[^\d]/g, '')
            }
          }}
          ref={ref}
          data-testid={rest.name}
          onWheel={event => event.currentTarget.blur()}
        />
        {extraRight}
        <label htmlFor={id} />
      </StyledInput>
      {rest.name && errors && (
        <ErrorMessage
          name={rest.name}
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      )}
    </>
  )
})
