import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { StoreCreate } from '../../../api/dashboard'

interface StoreCreateMutate {
  create: StoreCreate
}

export const useStoreCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ create }: StoreCreateMutate) => dashboardClient.v1.createStore(companyId, gameId, create),
    onSuccess: data => {
      queryClient.setQueryData(['stores', gameId], data.data)
      queryClient.invalidateQueries({ queryKey: ['stores', gameId] })
    },
  })
}
