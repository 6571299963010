import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const ArrowNarrowRight: AghanimIcon = forwardRef(function ({ strokeWidth = 2, ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M4 12H20M20 12L14 6M20 12L14 18"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  )
})
