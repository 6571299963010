import { useContext, useEffect, useState } from 'react'
import i18next from 'i18next'
import { useParams } from 'react-router-dom'
import {
  Button,
  FieldGroup,
  FieldValidationMessage,
  Input,
  Modal,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
} from '../../components/ui'
import { Controller, useForm } from 'react-hook-form'

import { InviteRead, Role } from '../../api/dashboard'
import { ResponseError, dashboardClient, getErrorText } from '../../api'
import { isEmail, isSuperAdmin } from '../../util'
import { AuthContext, IAuthContext } from '../../Context'
import { ErrorMessage } from '@hookform/error-message'
import { useToast } from '../../components/ui/Toast/useToast'
import { ToastSeverity } from '../../components/ui/Toast/types'
import { useQueryClient } from '@tanstack/react-query'

interface EditInviteDialogProps extends ModalProps {
  item: InviteRead
}

const EditInviteDialog = (props: EditInviteDialogProps) => {
  const { ...rest } = props
  const { companyId } = useParams() as { companyId: string }
  const context = useContext(AuthContext) as IAuthContext
  const [helperText, setHelperText] = useState('')
  const showToast = useToast()
  const queryClient = useQueryClient()
  const {
    control,
    handleSubmit,
    register,
    setFocus,
    watch,
    formState: { errors },
  } = useForm<InviteRead>({
    defaultValues: {
      ...props.item,
    },
  })

  const onSaveClick = async (data: InviteRead) => {
    setHelperText('')
    try {
      await dashboardClient.v1.getOrCreateInvite(companyId, data)
      if (data.role != Role.Viewer) {
        showToast({ message: i18next.t('users.invite-sent'), severity: ToastSeverity.success })
      }
      queryClient.invalidateQueries({ queryKey: ['invite', companyId] })
      props.onClose?.()
      return true
    } catch (e) {
      setHelperText(getErrorText(e as ResponseError))
    }
  }

  useEffect(() => {
    if (setFocus) {
      setTimeout(() => {
        setFocus('email')
      }, 10)
    }
  }, [setFocus])

  useEffect(() => {
    setHelperText('')
  }, [watch('email')])

  return (
    <Modal {...rest}>
      <ModalTitle>{i18next.t(props.item.id ? 'users.invite-user.edit' : 'users.invite')}</ModalTitle>

      <div className="mt-10 flex gap-3">
        <div style={{ width: '304px' }}>
          <FieldGroup label={i18next.t('users.email')}>
            <Input
              maxLength={254}
              placeholder="example@email.com"
              {...register('email', {
                required: i18next.t('validation.required'),
                validate: v => {
                  if (!isEmail(v)) {
                    return i18next.t('validation.invalid_email')
                  }
                  return true
                },
              })}
              onKeyPress={e => e.key === 'Enter' && handleSubmit(onSaveClick)()}
            />
            <ErrorMessage
              name="email"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>
        </div>
        <div className="grow">
          <FieldGroup label={i18next.t('users.role')}>
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <Select
                  dropDownWidth="360px"
                  {...field}
                  options={[
                    isSuperAdmin(context.customer)
                      ? {
                          value: Role.Viewer,
                          children: i18next.t('users.role.viewer'),
                          desc: i18next.t('users.role.viewer.desc'),
                        }
                      : undefined,
                    {
                      value: Role.User,
                      children: i18next.t('users.role.user'),
                      desc: i18next.t('users.role.user.desc'),
                    },
                    {
                      value: Role.Editor,
                      children: i18next.t('users.role.editor'),
                      desc: i18next.t('users.role.editor.desc'),
                    },
                    {
                      value: Role.Admin,
                      children: i18next.t('users.role.admin'),
                      desc: i18next.t('users.role.admin.desc'),
                    },
                  ]}
                />
              )}
            />
          </FieldGroup>
        </div>
      </div>

      {helperText && <FieldValidationMessage>{helperText}</FieldValidationMessage>}

      <ModalFooter>
        <Button size="lg" onClick={props.onClose}>
          {i18next.t('Cancel')}
        </Button>
        <Button size="lg" variant="primary" onClick={handleSubmit(onSaveClick)}>
          {i18next.t(props.item.id ? 'Save2' : 'users.invite-dialog-button')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditInviteDialog
