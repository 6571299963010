import { AghanimIcon, IconBase } from '../../../components/icons/IconBase'
import { forwardRef } from 'react'

export const ArrowsUpIcon: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 16 15" ref={ref}>
      <path
        d="M5.31694 2.05806C5.07286 1.81398 4.67714 1.81398 4.43306 2.05806L1.93306 4.55806C1.68898 4.80214 1.68898 5.19786 1.93306 5.44194C2.17714 5.68602 2.57286 5.68602 2.81694 5.44194L4.25 4.00888L4.25 12.5C4.25 12.8452 4.52982 13.125 4.875 13.125C5.22018 13.125 5.5 12.8452 5.5 12.5L5.5 4.00888L6.93306 5.44194C7.17714 5.68602 7.57286 5.68602 7.81694 5.44194C8.06102 5.19786 8.06102 4.80214 7.81694 4.55806L5.31694 2.05806Z"
        fill="currentColor"
      />
      <path
        d="M11.5669 5.18306C11.3229 4.93898 10.9271 4.93898 10.6831 5.18306L8.18306 7.68306C7.93898 7.92714 7.93898 8.32286 8.18306 8.56694C8.42714 8.81102 8.82286 8.81102 9.06694 8.56694L10.5 7.13388L10.5 12.5C10.5 12.8452 10.7798 13.125 11.125 13.125C11.4702 13.125 11.75 12.8452 11.75 12.5V7.13388L13.1831 8.56694C13.4271 8.81102 13.8229 8.81102 14.0669 8.56694C14.311 8.32286 14.311 7.92714 14.0669 7.68306L11.5669 5.18306Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
