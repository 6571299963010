import DashboardCard from '../../../../components/shared/DashboardCard'
import i18next from 'i18next'
import { LoaderContainer } from '../Loader/LoaderContainer'
import Loader from '../Loader/Loader'
import { Fragment, HTMLAttributes, useContext } from 'react'
import { Link, generatePath, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { useGameItems } from '../../../../api/useGameItems'
import ZeroState from '../ZeroState/ZeroState'
import { SkuImageName } from '../../../game-items/components/SkuImageName'
import { SKU_ITEMS_DETAILS_PATH } from '../../../../libs/routerPaths'
import { ItemClaimedType, ResourceState } from '../../../../api/dashboard'

import { AuthContext, IAuthContext } from '@/Context'
import { canEdit } from '@/security'
import { useTopClaimedQuery } from '@/layouts/dashboard/api/useTopClaimedQuery'
import { AnalyticsDataContext, IAnalyticsDataContext } from '@/layouts/dashboard/Context'
import { getUrlParamsObject } from '@/layouts/dashboard/util'
import { formatNumber } from '@/util'

const Separator = styled.div`
  width: calc(100% - 38px - 12px);
  height: 1px;
  background-color: ${p => p.theme.palette.background.fgskeleton};
  margin-left: auto;
`

interface TopSkuItemsProps extends HTMLAttributes<HTMLDivElement> {}

export default function TopClaimedSkuItems(props: TopSkuItemsProps) {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const authContext = useContext(AuthContext) as IAuthContext
  //const { t } = useTranslation()
  //const [selectedType, setSelectedType] = useState<ItemClaimedType>(ItemClaimedType.StoreItem)
  const context = useContext(AnalyticsDataContext) as IAnalyticsDataContext
  const { data = [], isLoading } = useTopClaimedQuery(companyId, gameId, {
    ...getUrlParamsObject(context.selectedFilter),
    item_type: ItemClaimedType.StoreItem,
    count: 5,
  })

  const { data: items = [] } = useGameItems(companyId, gameId, {
    ids: data.map(it => it.category).join(','),
    state: ResourceState.Active,
  })

  const getItemCells = (id: string) => {
    const item = items.find(it => it.id === id)
    if (!item) {
      return <></>
    }

    if (!canEdit(authContext.customer)) {
      return (
        <div className="flex w-full items-center gap-3">
          <SkuImageName item={item} />
        </div>
      )
    }

    return (
      <Link
        className="flex w-full items-center gap-3"
        to={generatePath(SKU_ITEMS_DETAILS_PATH, { companyId, gameId, itemId: item.id || '' })}
      >
        <SkuImageName item={item} />
      </Link>
    )
  }

  const total = data?.length ? data.filter(it => !!it.value).reduce((acc, item) => acc + item.value!, 0) : 0

  return (
    <DashboardCard
      title={i18next.t('dashboard.sales.top-5-claimed-items')}
      style={{
        ...props.style,
        gap: '8px',
      }}
    >
      {isLoading ? (
        <LoaderContainer style={{ height: '460px' }}>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          {/*<div className="h-[52px]" data-testid="dashboard/chart/top5-claimed-sku/filter">*/}
          {/*  <Select*/}
          {/*    options={[*/}
          {/*      {*/}
          {/*        children: t('claimed.type.store_item'),*/}
          {/*        value: ItemClaimedType.StoreItem,*/}
          {/*      },*/}
          {/*      {*/}
          {/*        children: t('claimed.type.daily_reward'),*/}
          {/*        value: ItemClaimedType.DailyReward,*/}
          {/*      },*/}
          {/*      {*/}
          {/*        children: t('claimed.type.loyalty_program'),*/}
          {/*        value: ItemClaimedType.LoyaltyProgram,*/}
          {/*      },*/}
          {/*    ]}*/}
          {/*    value={selectedType as string}*/}
          {/*    onChange={v => setSelectedType(v as ItemClaimedType)}*/}
          {/*  />*/}
          {/*</div>*/}
          <div
            data-testid="dashboard/chart/top5-claimed-sku/total"
            className="flex h-[52px] items-center text-title-t3 font-semibold text-text-secondary"
          >
            {formatNumber(total)}
          </div>
          {data.length > 0 ? (
            <div style={{ maxHeight: '384px' }}>
              {data.map((item, index) => (
                <Fragment key={item.category}>
                  <div className="flex h-[66px] items-center">
                    <div className="w-full truncate">{getItemCells(item.category)}</div>
                    <div className="text-right">
                      <div
                        className="text-text-tertiary"
                        data-testid={`dashboard/chart/top5-claimed-sku/${item.category}/count`}
                      >
                        {item.value}
                      </div>
                    </div>
                  </div>
                  {index < data.length - 1 && <Separator />}
                </Fragment>
              ))}
            </div>
          ) : (
            <ZeroState style={{ height: '384px' }} />
          )}
        </>
      )}
    </DashboardCard>
  )
}
