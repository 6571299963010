import { forwardRef } from 'react'
//import { AnimatePresence, motion } from 'framer-motion'
import { Skeleton } from '@mui/material'
//import { getMotionProps } from '../../../libs'
import { AghanimGameSchemasGame } from '../../../api/dashboard'

interface SidebarGameButtonProps {
  game?: AghanimGameSchemasGame
}

// const entryMotionProps = getMotionProps({
//   initial: { y: 1, opacity: 0 },
//   animate: { y: 0, opacity: 1 },
//   exit: { y: -1, opacity: 0 },
// })

export const SidebarGameButton = forwardRef<HTMLDivElement, SidebarGameButtonProps>(function SidebarGameButton(
  { game, ...rest },
  ref,
) {
  return (
    <div
      data-testid="game-switch-menu"
      {...rest}
      ref={ref}
      className="flex h-10 w-full cursor-pointer items-center gap-2 overflow-hidden rounded-md bg-bg-secondary p-1 transition-all [&:not([data-open='true'])]:hover:shadow-xs [&:not([data-open='true'])]:hover:ring-1 [&:not([data-open='true'])]:hover:ring-border-primary-hover [&[data-open='true']]:shadow-sm [&[data-open='true']]:ring-1"
    >
      {game ? (
        <>
          <img
            className="size-8 shrink-0 rounded-md object-cover"
            src={game.logo_url}
            data-categories="essential"
            alt=""
          />
          <div className="truncate font-medium leading-normal text-text-secondary">{game.name}</div>
        </>
      ) : (
        <>
          <Skeleton className="shrink-0 rounded-md" variant="rectangular" width={32} height={32} />
          <Skeleton className="rounded-md" variant="rectangular" width="50%" height={20} />
        </>
      )}
    </div>
  )
})
