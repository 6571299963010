import { autoUpdate, flip, offset, shift, size, useFloating } from '@floating-ui/react'

const MAX_HEIGHT = 600

const BOTTOM_PADDING = 16

/**
 *
 * @param isOpen
 */
export const useSelectFloating = (isOpen: boolean) => {
  return useFloating({
    open: isOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [
      shift({ crossAxis: false, padding: 4 }),
      offset(4),
      flip({ mainAxis: true }),
      size(() => ({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${Math.min(availableHeight - BOTTOM_PADDING, MAX_HEIGHT)}px`,
          })
        },
      })),
    ],
  })
}
