import { EventActionNodeType } from '../../types'
import { useTheme } from '@mui/material/styles'
import { Box, Popover, Typography } from '@mui/material'
import i18next from 'i18next'

import { Menu, StyledMenuOption, Tooltip } from '../../../../components/ui'
import { getNodeIcon } from '../../icons'
import { LinkBrokenIcon } from '../../icons/LinkBrokenIcon'
import { isIntegrationRequire } from '../../util'

export const SelectActionNode = (props: {
  onClose: () => void
  onSelect: (value: EventActionNodeType) => void
  anchorEl: HTMLElement
  actions: EventActionNodeType[]
}) => {
  const theme = useTheme()

  return (
    <Popover
      open={true}
      sx={{ marginTop: theme.spacing(1) }}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box p={theme.spacing(2.25)} width="380px" sx={{ borderBottom: `1px solid ${theme.palette.grey['100']}` }}>
        <Typography variant="h6" color={theme.palette.text.focus}>
          {i18next.t('campaign.editor.select-block-type')}
        </Typography>
      </Box>

      <Menu style={{ width: '100%', maxWidth: 'none' }}>
        {props.actions
          .filter(it => it != EventActionNodeType.GraphRoot)
          .map(it => (
            <StyledMenuOption
              onClick={() => props.onSelect(it)}
              key={it}
              style={{
                gap: '8px',
                padding: '6px 14px',
              }}
            >
              {getNodeIcon(it, theme)}
              {i18next.t(`campaign.node-type.${it}`)}
              {isIntegrationRequire(it) && (
                <Tooltip message={i18next.t('campaign.editor.integration-require')}>
                  <LinkBrokenIcon style={{ marginLeft: 'auto', opacity: 0.8 }} />
                </Tooltip>
              )}
            </StyledMenuOption>
          ))}
      </Menu>
    </Popover>
  )
}
