import { Suspense, lazy } from 'react'
import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical'

const ImageNodeRender = lazy(() => import('./ImageNodeRender').then(module => ({ default: module.ImageNodeRender })))

export type Position = 'left' | 'right' | 'full' | undefined

export interface InlineImagePayload {
  src: string
  alt: string
  width?: number
  height?: number
  position?: Position
  key?: NodeKey
}

export interface UpdateInlineImagePayload {
  position?: Position
}

const $convertInlineImageElement = (domNode: Node): null | DOMConversionOutput => {
  if (domNode instanceof HTMLImageElement) {
    const node = $applyNodeReplacement(
      new InlineImageNode(
        domNode.src,
        domNode.alt,
        domNode.width || 'inherit',
        domNode.height || 'inherit',
        (domNode.getAttribute('data-position') as Position) || 'full',
      ),
    )
    return { node }
  }
  return null
}

export type SerializedInlineImageNode = Spread<
  { src: string; alt: string; width?: number; height?: number; position?: Position },
  SerializedLexicalNode
>

export class InlineImageNode extends DecoratorNode<JSX.Element> {
  __src: string
  __alt: string
  __width: 'inherit' | number
  __height: 'inherit' | number
  __position: Position

  constructor(
    src: string,
    alt: string,
    width: 'inherit' | number,
    height: 'inherit' | number,
    position: Position,
    key?: NodeKey,
  ) {
    super(key)
    this.__src = src
    this.__alt = alt
    this.__width = width || 'inherit'
    this.__height = height || 'inherit'
    this.__position = position
  }

  static getType(): string {
    return 'inline-image'
  }

  static clone(node: InlineImageNode): InlineImageNode {
    return new InlineImageNode(node.__src, node.__alt, node.__width, node.__height, node.__position, node.__key)
  }

  static importJSON(node: SerializedInlineImageNode): InlineImageNode {
    return $createInlineImageNode(node)
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: () => ({
        conversion: $convertInlineImageElement,
        priority: 0,
      }),
    }
  }

  isIsolated() {
    return false
  }

  isInline() {
    return false
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('img')
    element.setAttribute('src', this.__src)
    element.setAttribute('alt', this.__alt)
    element.setAttribute('width', this.__width.toString())
    element.setAttribute('height', this.__height.toString())
    element.setAttribute('data-position', this.__position || 'full')
    return { element }
  }

  exportJSON(): SerializedInlineImageNode {
    return {
      type: 'inline-image',
      src: this.getSrc(),
      alt: this.getAlt(),
      width: this.__width === 'inherit' ? 0 : this.__width,
      height: this.__height === 'inherit' ? 0 : this.__height,
      position: this.__position,
      version: 1,
    }
  }

  getSrc(): string {
    return this.__src
  }

  getAlt(): string {
    return this.__alt
  }

  setAltText(alt: string): void {
    const writable = this.getWritable()
    writable.__alt = alt
  }

  setWidthAndHeight(width: 'inherit' | number, height: 'inherit' | number): void {
    const writable = this.getWritable()
    writable.__width = width
    writable.__height = height
  }

  getPosition(): Position {
    return this.__position
  }

  setPosition(position: Position): void {
    const writable = this.getWritable()
    writable.__position = position
  }

  update({ position }: UpdateInlineImagePayload): void {
    const writable = this.getWritable()
    if (position !== undefined) {
      writable.__position = position
    }
  }

  createDOM(): HTMLElement {
    const span = document.createElement('span')
    span.className = `editor-image position-${this.__position}`
    return span
  }

  updateDOM(prevNode: InlineImageNode, dom: HTMLElement): false {
    const position = this.__position
    if (position !== prevNode.__position) {
      dom.className = `editor-image position-${position}`
    }
    return false
  }

  decorate(): JSX.Element {
    return (
      <Suspense fallback={null}>
        <ImageNodeRender
          nodeKey={this.getKey()}
          src={this.__src}
          alt={this.__alt}
          width={this.__width}
          height={this.__height}
          position={this.__position}
        />
      </Suspense>
    )
  }
}

export function $createInlineImageNode({
  src,
  alt,
  width,
  height,
  position,
  key,
}: InlineImagePayload): InlineImageNode {
  return $applyNodeReplacement(new InlineImageNode(src, alt, width || 'inherit', height || 'inherit', position, key))
}

export function $isInlineImageNode(node: LexicalNode | null | undefined): node is InlineImageNode {
  return node?.__type === 'inline-image'
}
