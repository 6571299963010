import { createContext } from 'react'
import { Campaign, CustomAttribute, GraphRoot } from '../../api/dashboard'
import { Viewport } from 'reactflow'

export enum EditorMode {
  Campaign,
  Segment,
  Store,
  StoreItem,
}

export interface ICampaignContext {
  campaign: Campaign
  playerCustomAttributes: CustomAttribute[]
  graph: GraphRoot
  historyMode?: boolean
  readOnly?: boolean
  segmentGroupId?: string
  mode?: EditorMode
  viewPort?: Viewport | undefined
}

export const CampaignContext = createContext<ICampaignContext | null>(null)
