import { ItemImageCreate, ItemImageRead, ItemRead } from '../../../api/dashboard'
import { getAspectRationStyle } from '../libs'

interface PreviewStoreCardProps {
  sku: ItemRead
  image: ItemImageRead | ItemImageCreate
}

export const PreviewStoreCard = ({ sku, image }: PreviewStoreCardProps) => (
  <div className="pointer-events-none flex w-[180px] select-none flex-col rounded-[12px] border border-[#3F3F46] bg-[#27272A] p-[4px]">
    <div className="mb-[4px]">
      {image.cropped_url || image.original_url ? (
        <img
          className="flex w-full rounded-[6px] object-cover"
          style={{ aspectRatio: image.crop?.aspect_ratio ? getAspectRationStyle(image.crop?.aspect_ratio) : undefined }}
          src={image.cropped_url || image.original_url}
          alt=""
        />
      ) : (
        <div className="aspect-square w-full" />
      )}
    </div>
    <div className="p-[6px]">
      <div className="mb-[2px] truncate text-center text-[12px] font-semibold leading-[18px] text-white">
        {sku.name && sku.quantity} {sku.name}
      </div>
      <div className="mb-[14px] line-clamp-2 text-center text-[8px] leading-[10px] text-[#D4D4D8]">
        {sku.description}
      </div>
      <div className="flex h-[24px] items-center justify-center rounded-[6px] bg-[#3F3F46]">
        <div className="h-[8px] w-[32px] rounded-[20px] bg-[#52525B]" />
      </div>
    </div>
  </div>
)
