import { QueryClient, useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { PageLayoutType } from '../../../api/dashboard'

type Query = {
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 100
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number

  parent_id?: string
  /**
   * Ignore Parent Id
   * @default false
   */
  ignore_parent_id?: boolean

  types?: PageLayoutType[]
}

/**
 *
 * @param companyId
 * @param gameId
 * @param query
 */
export const usePagesQuery = (companyId: string, gameId: string, query: Query) => {
  return useQuery({
    queryKey: ['pages', gameId, query],
    queryFn: () => dashboardClient.v1.getPages(companyId, gameId, query),
    select: response => response.data,
  })
}

export const invalidatePages = (queryClient: QueryClient, gameId: string) => {
  queryClient.invalidateQueries({ queryKey: ['pages', gameId] })
}
