import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

interface ItemCategoryDeleteMutate {
  id: string
}

export const useItemCategoryDeleteMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id }: ItemCategoryDeleteMutate) => dashboardClient.v1.deleteItemCategory(companyId, gameId, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['item-category', gameId] })
    },
  })
}
