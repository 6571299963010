import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const BarChart: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        d="M6.75012 9H3.45012C3.03008 9 2.82006 9 2.65963 9.08175C2.51851 9.15365 2.40377 9.26839 2.33187 9.40951C2.25012 9.56994 2.25012 9.77996 2.25012 10.2V14.55C2.25012 14.97 2.25012 15.1801 2.33187 15.3405C2.40377 15.4816 2.51851 15.5964 2.65963 15.6683C2.82006 15.75 3.03008 15.75 3.45012 15.75H6.75012M6.75012 15.75H11.2501M6.75012 15.75L6.75012 6.45C6.75012 6.02996 6.75012 5.81994 6.83187 5.65951C6.90377 5.51839 7.01851 5.40365 7.15963 5.33175C7.32006 5.25 7.53008 5.25 7.95012 5.25H10.0501C10.4702 5.25 10.6802 5.25 10.8406 5.33175C10.9817 5.40365 11.0965 5.51839 11.1684 5.65951C11.2501 5.81994 11.2501 6.02996 11.2501 6.45V15.75M11.2501 15.75H14.5501C14.9702 15.75 15.1802 15.75 15.3406 15.6683C15.4817 15.5964 15.5965 15.4816 15.6684 15.3405C15.7501 15.1801 15.7501 14.97 15.7501 14.55V3.45C15.7501 3.02996 15.7501 2.81994 15.6684 2.65951C15.5965 2.51839 15.4817 2.40365 15.3406 2.33175C15.1802 2.25 14.9702 2.25 14.5501 2.25H12.4501C12.0301 2.25 11.8201 2.25 11.6596 2.33175C11.5185 2.40365 11.4038 2.51839 11.3319 2.65951C11.2501 2.81994 11.2501 3.02996 11.2501 3.45V6"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  )
})
