import { generatePath, useSearchParams } from 'react-router-dom'

/**
 *
 * @param take
 */
export const usePagination = (take = 10) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')

  const query = { offset: (page - 1) * take, limit: take }

  const needShowPagination = (isLoading: boolean, items: Array<unknown>) => {
    if (isLoading) {
      return false
    }
    if (items.length === 0 && page == 1) {
      return false
    }

    if (items.length < take && page == 1) {
      return false
    }
    return true
  }

  const onPageChange = (page: number) => {
    setSearchParams({ page: page.toString() })
  }

  return { page, query, onPageChange, needShowPagination }
}

export const generatePathWithPage = (path: string, params: Record<string, string>, page: number) => {
  let res = generatePath(path, params)

  if (page > 1) {
    res += `?page=${page}`
  }

  return res
}
