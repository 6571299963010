import { ItemRead } from './dashboard'
import { formatNumber } from '@/util'

export function getItemName(item: ItemRead | undefined | null) {
  if (item) {
    return item.is_stackable && item.quantity ? `${formatNumber(item.quantity)} · ${item.name}` : item.name
  }

  return ''
}
