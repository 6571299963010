import { PaymentStatus } from '../../../pay-types'
import i18next from 'i18next'

import { AlertTriangleIcon, CheckIcon, SlashCircle } from '../../../icons/Icons'
import { Badge } from '../../../components/ui'
import { Clock, CloseOutline, Coins, CoinsHandOutline } from '../../../components/icons'
import { PaymentRead } from '../../../api/dashboard'

export const getStatusIcon = (payment: PaymentRead[]) => {
  let status = payment[0].status as PaymentStatus

  if (status == PaymentStatus.created && payment.find(p => p.status != PaymentStatus.created)) {
    return <Clock />
  }

  switch (status) {
    case PaymentStatus.created:
    case PaymentStatus.captured:
      return <Coins />
    case PaymentStatus.failed:
      return <AlertTriangleIcon />
    case PaymentStatus.refunded:
      return <CoinsHandOutline />

    case PaymentStatus.canceled:
      return <CloseOutline />

    case PaymentStatus.rejected:
      return <SlashCircle />

    case PaymentStatus.done:
      return <CheckIcon />
  }

  return <></>
}

export const getStatusClassTextColor = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.canceled:
    case PaymentStatus.rejected:
    case PaymentStatus.failed:
      return 'text-text-error-solid'
    case PaymentStatus.done:
      return 'text-text-success-solid'
    case PaymentStatus.refunded:
      return 'text-text-violet-solid'
  }

  return 'text-text-gray-primary'
}

export const getStatusClassBackgroundColorAsText = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.canceled:
    case PaymentStatus.rejected:
    case PaymentStatus.failed:
      return 'text-fg-error-secondary'
    case PaymentStatus.done:
      return 'text-fg-success-secondary'
    case PaymentStatus.refunded:
      return 'text-fg-violet-secondary'
  }

  return 'text-fg-gray-secondary'
}

export default function PaymentStatusChip({ showText, payment }: { payment: PaymentRead[]; showText?: boolean }) {
  let status = payment[0].status as PaymentStatus

  if (status == PaymentStatus.refund_requested) {
    status = PaymentStatus.refunded
  }

  let variant = 'gray-secondary'

  switch (status) {
    case PaymentStatus.created:
      variant = 'gray-secondary'
      break
    case PaymentStatus.canceled:
    case PaymentStatus.rejected:
    case PaymentStatus.failed:
      variant = 'red-secondary'
      break
    case PaymentStatus.done:
      variant = 'green-secondary'
      break
    case PaymentStatus.refunded:
      variant = 'violet-secondary'
      break
  }

  if (showText !== false) {
    return (
      // @ts-ignore
      <Badge variant={variant}>
        {i18next.t(`transactions-table.status.${status}`)}
        {getStatusIcon(payment)}
      </Badge>
    )
  }

  return (
    // @ts-ignore
    <Badge variant={variant} className="px-1">
      {getStatusIcon(payment)}
    </Badge>
  )
}
