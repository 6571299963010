import { DescContext } from '../../../campaigns/blocks/descriptions/types'
import { SortStoreItemsNode } from '@/api/dashboard'
import i18next from 'i18next'
import { capitalizeFirstLetter } from '@/util'

export function getDescAddStoreItemsNode(n: SortStoreItemsNode, _: DescContext) {
  return [
    i18next.t('campaign.node-type.SortStoreItemsNode'),
    n.sort_by && n.order
      ? `${i18next.t('store.SortStoreItemsNode.sort_by')} '${capitalizeFirstLetter(n.sort_by)}' (${n.order})`
      : '',
  ]
}
