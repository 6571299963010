import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { PageBulkUpdate } from '../../../api/dashboard'

interface PagesBulkUpdateMutate {
  data: PageBulkUpdate[]
}

export const usePagesBulkUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ data }: PagesBulkUpdateMutate) => dashboardClient.v1.bulkUpdatePage(companyId, gameId, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['pages', gameId] })
    },
  })
}
