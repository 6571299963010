import { useCallback, useEffect } from 'react'
import { $getRoot, $insertNodes } from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $generateNodesFromDOM } from '@lexical/html'

interface HtmlValuePluginProps {
  value: string
}

export const HtmlValuePlugin = ({ value }: HtmlValuePluginProps) => {
  const [editor] = useLexicalComposerContext()

  const updateEditorContent = useCallback(() => {
    setTimeout(() => {
      editor.update(() => {
        const parser = new DOMParser()
        const dom = parser.parseFromString(value, 'text/html')
        const nodes = $generateNodesFromDOM(editor, dom)
        $getRoot().clear()
        $getRoot().select()
        $insertNodes(nodes)
      })
    })
  }, [editor, value])

  useEffect(() => {
    updateEditorContent()
  }, [updateEditorContent])

  return null
}
