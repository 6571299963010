import { Box, TextField, styled } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import i18next from 'i18next'
import { useState } from 'react'
import { HUB_API } from '../HubAPI'
import { CreateUploadLinkResponse } from '../types'
import UploadIcon from '../icons/upload.svg?react'
import { TEXT_SV_COLOR } from '../Settings'
import CircularProgress from '@mui/material/CircularProgress'
import { getImageBorderRadius } from '../theme/Styles'
import { Autocomplete } from '@mui/lab'
import { StyledInputLabel } from './StyledTextField'
import { FieldGroup, Input } from './ui'

export interface ITextFieldProps {
  label?: string
  label2?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  backgroundSize?: string | undefined | null
  type?: string
  margin?: boolean
  fullWidth?: boolean
  onChange?: (url: string) => void
  disabled?: boolean
  bucket: string
  options?: string[]
  errorText?: string
}

const StyledDiv = styled('div')(({ theme }) => ({
  '&:hover > div': {
    opacity: 0.6,
  },
  border: `1px solid ${theme.palette.grey['300']}`,
  width: '198px',
  height: '198px',
  borderRadius: getImageBorderRadius(theme),
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
}))

export const StyledInputFile = styled('input')({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
  cursor: 'pointer',
})

export default function ImageUpload(props: ITextFieldProps) {
  const [isLoading, setIsLoading] = useState(false)
  //const [progress, setProgress] = useState(0)

  const onFileSelected = async (file: File | undefined) => {
    if (!file) {
      return
    }

    setIsLoading(true)
    //setProgress(5)

    let data = await HUB_API.post<CreateUploadLinkResponse>('/images/create_upload_link', {
      image_format: file.type.split('/')[1],
      bucket: props.bucket,
    })

    let request = new XMLHttpRequest()
    request.open('PUT', data.upload_url)
    request.setRequestHeader('Content-Type', file.type)

    request.upload.addEventListener('progress', () => {
      // let percent_complete = (e.loaded / e.total) * 100;
      // if (percent_complete > 5)
      //    setProgress(percent_complete)
    })

    request.addEventListener('load', () => {
      if (request.status == 200) {
        setIsLoading(false)

        if (props.onChange) {
          props.onChange(data.get_url)
        }
      }
    })

    request.send(file)
  }

  const theme = useTheme()
  return (
    <Box
      sx={{
        marginTop: props.margin ? theme.spacing(2) : '',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <FieldGroup label={props.label || i18next.t('Image')}>
        <StyledDiv>
          <div
            style={{
              backgroundImage: `url(${props.value})`,
              backgroundColor: TEXT_SV_COLOR,
              backgroundSize: props.backgroundSize || 'cover',
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}
          />
          {!props.disabled && (
            <StyledInputFile
              type="file"
              accept={'image/png, image/jpg, image/jpeg, image/webp'}
              onChange={e => onFileSelected(e.target.files![0])}
            />
          )}

          {isLoading ? (
            <CircularProgress sx={{ color: theme.palette.primary.light }} />
          ) : (
            <UploadIcon style={{ zIndex: 1, pointerEvents: 'none' }} />
          )}
        </StyledDiv>
      </FieldGroup>

      {!props.disabled && props.options ? (
        <>
          <StyledInputLabel
            label={props.label2 || i18next.t('UrlToImage')}
            error={!!props.errorText}
            style={{ marginTop: theme.spacing(2) }}
          />

          <Autocomplete
            value={props.value}
            renderOption={(props, option) => (
              <li {...props}>
                <img src={option} alt="image" />
              </li>
            )}
            onChange={(_, newValue: string) => {
              props.onChange && props.onChange(newValue)
            }}
            inputValue={props.value}
            onInputChange={(_, newInputValue) => {
              props.onChange && props.onChange(newInputValue)
            }}
            options={props.options}
            fullWidth={props.fullWidth}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderInput={(params: any) => (
              <TextField {...params} value={props.value} size="small" variant="outlined" fullWidth={props.fullWidth} />
            )}
          />
        </>
      ) : (
        <div className="mt-2">
          <FieldGroup label={i18next.t('UrlToImage')} error={props.errorText}>
            <Input
              disabled={props.disabled}
              placeholder={i18next.t('Paste a link')}
              value={props.value}
              onChange={e => props.onChange && props.onChange(e.target.value)}
            />
          </FieldGroup>
        </div>
      )}
    </Box>
  )
}
