import i18next from 'i18next'
import { Button, ButtonVariant } from '../index'
import Modal from '../Dialog/Modal'

export function ConfirmDialog(props: {
  message?: string
  confirmButtonText: string
  cancelButtonText?: string
  subMessage?: string
  onCancel: () => void
  onConfirm: () => void
  children?: React.ReactNode
  confirmEnabled?: boolean
  color?: ButtonVariant
}) {
  return (
    <Modal width="540px" onClose={props.onCancel}>
      <div>
        <div className="mb-3 text-[24px] font-semibold text-text-primary">
          {props.message || i18next.t('confirm.title')}
        </div>
        {props.subMessage && <div className="text-text-secondary">{props.subMessage}</div>}
        {props.children}
      </div>

      <div className="mt-6 flex justify-end gap-3">
        <Button size="lg" onClick={props.onCancel} data-testid="confirm-cancel-button">
          {props.cancelButtonText || i18next.t('Cancel')}
        </Button>
        <Button size="lg" variant={props.color} onClick={props.onConfirm} data-testid="confirm-button">
          {props.confirmButtonText}
        </Button>
      </div>
    </Modal>
  )
}
