import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query'
import { InfinitePaginatedResponseTranslationHtmlStrippedRead, TranslationRead } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { useGenerateCompanyPath } from '@/libs'
import { translationsInfiniteQueryOptions } from './translationsInfiniteQueryOptions'
import { translationQueryOptions } from './translationQueryOptions'

interface TranslationCreateMutate {
  localeId: string
  data: TranslationRead
}

const getUpdaterInfiniteDataFn =
  (data: TranslationRead) =>
  (
    queries: InfiniteData<InfinitePaginatedResponseTranslationHtmlStrippedRead, unknown> | undefined,
  ): InfiniteData<InfinitePaginatedResponseTranslationHtmlStrippedRead, unknown> | undefined => {
    if (!queries) {
      return queries
    }

    return {
      ...queries,
      pages: queries.pages.map(page => {
        return {
          ...page,
          data: page.data.map(translation => {
            if (translation.id ? translation.id === data.id : translation.original_id === data.original_id) {
              return { ...translation, ...data }
            }
            return translation
          }),
        }
      }),
    }
  }

export const translationCreateOrUpdateMutation = () => {
  const ql = useQueryClient()
  const { companyId, gameId } = useGenerateCompanyPath()

  return useMutation({
    mutationFn: ({ data }: TranslationCreateMutate) => {
      if (data.id) {
        return dashboardClient.v1.updateTranslation(companyId, gameId, data.id, data).then(r => r.data)
      }
      return dashboardClient.v1.createTranslation(companyId, gameId, data).then(r => r.data)
    },
    onMutate: ({ localeId, data }) => {
      if (data.id) {
        ql.setQueryData(translationQueryOptions(companyId, gameId, data.id).queryKey, data)
      }

      ql.setQueriesData<InfiniteData<InfinitePaginatedResponseTranslationHtmlStrippedRead, unknown>>(
        translationsInfiniteQueryOptions(companyId, gameId, { locale_id: localeId }),
        getUpdaterInfiniteDataFn(data),
      )
    },
    onSuccess: (data, { localeId }) => {
      if (data.id) {
        ql.setQueryData(translationQueryOptions(companyId, gameId, data.id).queryKey, data)
      }

      ql.setQueriesData<InfiniteData<InfinitePaginatedResponseTranslationHtmlStrippedRead, unknown>>(
        translationsInfiniteQueryOptions(companyId, gameId, { locale_id: localeId }),
        getUpdaterInfiniteDataFn(data),
      )
    },
  })
}
