import { CSSProperties } from 'react'
import { useTheme } from '@mui/material/styles'

export default function ImageCellNoText(props: {
  image_url: string | undefined
  default_image_url?: string | undefined
  image_size?: string | undefined
  onClick?: () => void
  style?: CSSProperties
}) {
  const theme = useTheme()
  let placeHolderUrl = `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/placeholder.png`

  return (
    <img
      src={props.image_url || props.default_image_url || placeHolderUrl}
      alt="image cell"
      onClick={props.onClick}
      onError={e => {
        ;(e.target as HTMLImageElement).src = props.default_image_url || placeHolderUrl
      }}
      style={{
        borderRadius: theme.spacing(0.5),
        height: props.image_size || theme.spacing(12.25),
        width: props.image_size || theme.spacing(12.25),
        ...props.style,
      }}
    />
  )
}
