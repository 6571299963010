import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { ItemCategoryCreate } from '../../../api/dashboard'

interface ItemCategoryCreateMutate {
  create: ItemCategoryCreate
}

export const useItemCategoryCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ create }: ItemCategoryCreateMutate) =>
      dashboardClient.v1.createItemCategory(companyId, gameId, create),
    onSuccess: data => {
      queryClient.setQueryData(['item-category', gameId], data.data)
      queryClient.invalidateQueries({ queryKey: ['item-category', gameId] })
    },
  })
}
