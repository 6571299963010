import { queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

/**
 *
 * @param companyId
 * @param gameId
 * @param localeId
 */
export const localeQueryOptions = (companyId: string, gameId: string, localeId: string) =>
  queryOptions({
    queryKey: [gameId, 'locales', localeId],
    queryFn: () => dashboardClient.v1.getLocale(companyId, gameId, localeId).then(r => r.data),
  })
