import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <path
      d="M12,4.5c.41,0,.75.34.75.75v1.59c1.29.33,2.25,1.51,2.25,2.91,0,.9-.4,1.71-1.03,2.26l1.36,2.73c.47-.32.9-.7,1.26-1.13.27-.32.74-.36,1.06-.09.32.27.36.74.09,1.06-.5.59-1.08,1.11-1.74,1.52l1.16,2.33c.19.37.04.82-.34,1.01-.37.19-.82.04-1.01-.34l-1.16-2.33c-.65.25-1.32.4-2.01.46-.98.09-1.97-.02-2.91-.32-.14-.04-.28-.09-.41-.14l-1.16,2.33c-.19.37-.64.52-1.01.34-.37-.19-.52-.64-.34-1.01l1.16-2.33c-.28-.18-.55-.38-.81-.59-.34-.28-.64-.59-.92-.92-.27-.32-.22-.79.09-1.06.32-.27.79-.22,1.06.09.22.27.47.51.74.74.17.14.34.27.52.39l1.36-2.73c-.63-.55-1.03-1.36-1.03-2.26,0-1.4.96-2.57,2.25-2.91v-1.59c0-.41.34-.75.75-.75ZM12,8.25c-.83,0-1.5.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5ZM11.37,12.68l-1.36,2.73c.06.02.12.04.19.06.75.24,1.54.32,2.33.25.5-.04,1-.15,1.47-.32l-1.36-2.73c-.2.04-.41.07-.63.07s-.43-.02-.63-.07Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'fill',
    <path
      d="M12,4.5c.41,0,.75.34.75.75v1.59c1.29.33,2.25,1.51,2.25,2.91,0,.9-.4,1.71-1.03,2.26l1.36,2.73c.47-.32.9-.7,1.26-1.13.27-.32.74-.36,1.06-.09.32.27.36.74.09,1.06-.5.59-1.08,1.11-1.74,1.52l1.16,2.33c.19.37.04.82-.34,1.01-.37.19-.82.04-1.01-.34l-1.16-2.33c-.65.25-1.32.4-2.01.46-.98.09-1.97-.02-2.91-.32-.14-.04-.28-.09-.41-.14l-1.16,2.33c-.19.37-.64.52-1.01.34-.37-.19-.52-.64-.34-1.01l1.16-2.33c-.28-.18-.55-.38-.81-.59-.34-.28-.64-.59-.92-.92-.27-.32-.22-.79.09-1.06.32-.27.79-.22,1.06.09.22.27.47.51.74.74.17.14.34.27.52.39l1.36-2.73c-.63-.55-1.03-1.36-1.03-2.26,0-1.4.96-2.57,2.25-2.91v-1.59c0-.41.34-.75.75-.75ZM11.37,12.68l-1.36,2.73c.06.02.12.04.19.06.75.24,1.54.32,2.33.25.5-.04,1-.15,1.47-.32l-1.36-2.73c-.2.04-.41.07-.63.07s-.43-.02-.63-.07Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'duotone',
    <>
      <rect x="0" y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12C0,21.31,2.69,24,6,24h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <path
        d="M12,4.5c.41,0,.75.34.75.75v1.59c1.29.33,2.25,1.51,2.25,2.91,0,.9-.4,1.71-1.03,2.26l1.36,2.73c.47-.32.9-.7,1.26-1.13.27-.32.74-.36,1.06-.09.32.27.36.74.09,1.06-.5.59-1.08,1.11-1.74,1.52l1.16,2.33c.19.37.04.82-.34,1.01-.37.19-.82.04-1.01-.34l-1.16-2.33c-.65.25-1.32.4-2.01.46-.98.09-1.97-.02-2.91-.32-.14-.04-.28-.09-.41-.14l-1.16,2.33c-.19.37-.64.52-1.01.34-.37-.19-.52-.64-.34-1.01l1.16-2.33c-.28-.18-.55-.38-.81-.59-.34-.28-.64-.59-.92-.92-.27-.32-.22-.79.09-1.06.32-.27.79-.22,1.06.09.22.27.47.51.74.74.17.14.34.27.52.39l1.36-2.73c-.63-.55-1.03-1.36-1.03-2.26,0-1.4.96-2.57,2.25-2.91v-1.59c0-.41.34-.75.75-.75ZM11.37,12.68l-1.36,2.73c.06.02.12.04.19.06.75.24,1.54.32,2.33.25.5-.04,1-.15,1.47-.32l-1.36-2.73c-.2.04-.41.07-.63.07s-.43-.02-.63-.07Z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </>,
  ],
])

export const AppConnect = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
