import { AnimatePresence, motion } from 'framer-motion'

interface StepProgressBarProps {
  value: number
}

export const StepProgressBar = ({ value }: StepProgressBarProps) => (
  <AnimatePresence initial={false}>
    <div className="h-2 w-full overflow-hidden rounded-full bg-fg-secondary-alt">
      <motion.div
        className="flex h-full min-w-2 rounded-full bg-fg-brand-primary"
        initial={{ width: 0 }}
        animate={{ width: `${value}%` }}
      />
    </div>
  </AnimatePresence>
)
