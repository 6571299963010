import { GameStore, SocialMedia, SocialMediaName } from '../../../api/dashboard'
import { SOCIAL_ICONS_MAP } from '../../../icons/Icons'
import { PlusContained, Trash } from '../../../components/icons'
import { Button, useModal } from '../../../components/ui'
import { SocialModal } from './SocialModal'
import { StoreSocialMode } from '../tyles'

export const SocialMediaList = (props: {
  items: SocialMedia[] | GameStore[]
  onDelete?: (item: SocialMedia | GameStore) => void
  onAdd?: (item: SocialMedia | GameStore) => void
  mode: StoreSocialMode
}) => {
  const openAddItem = useModal(p => (
    <SocialModal mode={props.mode} onAdd={it => props.onAdd?.(it as SocialMedia | GameStore)} onClose={p.onClose} />
  ))

  return (
    <div className="flex flex-col gap-1.5">
      {props.items.map(it => (
        <div
          key={it.url}
          className="flex w-full items-center gap-3 rounded-xl border-2 border-border-primary bg-fg-primary p-3 shadow-xs"
        >
          <img
            src={it.name == SocialMediaName.Custom ? it.custom_icon_url : SOCIAL_ICONS_MAP[it.name] || ''}
            alt={it.name}
            className="size-6"
          />
          <div className="overflow-hidden whitespace-nowrap">
            <div className="flex h-[24px] items-center font-medium capitalize text-text-secondary">
              {it.name == SocialMediaName.Custom ? it.custom_name : it.name}
            </div>
            <a href={it.url} target="_blank" className="flex h-[24px] items-center font-[14px] text-text-tertiary">
              {it.url}
            </a>
          </div>

          {props.onDelete && (
            <Button variant="tertiary-gray" size="md" className="ml-auto" onClick={() => props.onDelete?.(it)}>
              <Trash />
            </Button>
          )}
        </div>
      ))}

      {props.onAdd && (
        <div
          onClick={() => openAddItem()}
          className="flex h-[72px] cursor-pointer items-center justify-center rounded-xl border-2 border-dashed border-border-primary hover:bg-fg-secondary-hover"
        >
          <PlusContained color="#CBD5E1" />
        </div>
      )}
    </div>
  )
}
