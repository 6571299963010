import { CSSProperties, ReactNode } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import shouldForwardProp from '@emotion/is-prop-valid'
import { useBanner } from '@/libs/hooks/useBanner'
import { cn } from '@/libs/cn'

interface SidebarProps {
  children: ReactNode
  open: boolean
  width?: string
  position?: 'left' | 'right'
  style?: CSSProperties
}

const Root = styled('div', { shouldForwardProp })<{ $position: SidebarProps['position']; $open: SidebarProps['open'] }>`
  z-index: 10;
  width: 300px;
  overflow: auto;
  position: fixed;
  background-color: ${p => p.theme.palette.background.content};
  box-shadow: 5px 20px 35px 0 rgba(0, 0, 0, 0.13);
  transition: transform 0.3s ease;

  ${p =>
    p.$position === 'right' &&
    css`
      transform: translateX(${p.$open ? '0' : '100%'});
      right: 0;
    `}

  ${p =>
    p.$position === 'left' &&
    css`
      transform: translateX(${p.$open ? '0' : '-100%'});
      left: 0;
    `}
`
export const DrawerSidebar = ({ children, open, width, position = 'left', style }: SidebarProps) => {
  const { getStickyTop, getStickyHeight } = useBanner()
  return (
    <Root
      $position={position}
      $open={open}
      className={cn(getStickyTop(), getStickyHeight())}
      style={{ ...style, width: width }}
    >
      {children}
    </Root>
  )
}
