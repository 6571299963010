import { getLastLang, initI18 } from '@/translates'
import CenteredProgress from '@/components/CenteredProgress'

import FullScreenActionBackground from '../layouts/components/FullScreenActionBackground/FullScreenActionBackground'
import { useEffect, useState } from 'react'
import { ThemeContext, lightTheme } from '../App'
import { ThemeProvider } from '@mui/material'
import Header from '../layouts/errors/Header'

export default function ThemeLangOutlet(props: { children: React.ReactNode }) {
  const [langLoading, setLangLoading] = useState(true)
  const [theme] = useState(lightTheme)

  const load = async () => {
    await initI18(getLastLang() || 'en')
    setLangLoading(false)
  }

  useEffect(() => {
    load()
  }, [])

  if (langLoading) {
    return <CenteredProgress />
  }

  return (
    <ThemeContext.Provider value={{ theme: theme }}>
      <ThemeProvider theme={theme}>
        <FullScreenActionBackground header={<Header />}>{props.children}</FullScreenActionBackground>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}
