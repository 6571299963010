import { SplitNode } from '../../../api/dashboard'
import { AnyNode, EventActionNodeType } from '../types'
import { getCouponDesc } from './descriptions/getCouponDesc'
import { getDescItemDiscountOfferActionNode } from './descriptions/getDescItemDiscountOfferActionNode'
import { getDescItemBonusOfferActionNode } from './descriptions/getDescItemBonusOfferActionNode'
import { getCreateItemBannerActionNode } from './descriptions/getCreateItemBannerActionNode'
import { getAddToSegmentNode } from './descriptions/getAddToSegmentNode'
import { getDescCommunicationNode } from './descriptions/getDescCommunicationNode'
import { DescContext } from './descriptions/types'
import { getDescTimerNode } from './descriptions/getDescTimerNode'
import { getDescConditionNode } from './descriptions/getDescConditionNode'
import { getDescItemOfferActionNode } from './descriptions/getDescItemOfferActionNode'
import { getDescVirtualSKUActionNode } from '@/layouts/campaigns/blocks/descriptions/getDescVirtualSKUActionNode'

function getDescSplitNode(node: SplitNode) {
  let arr = node.distribution
  if (arr?.length) {
    return [node.title, arr[0].value + '/' + (arr[1].value - arr[0].value)]
  }
  return []
}

export type DescFunc = (node: AnyNode, context: DescContext) => [string, React.ReactNode]

export const DESCRIPTIONS = {
  [EventActionNodeType.CreateCouponNode]: getCouponDesc,
  [EventActionNodeType.ItemDiscountOfferActionNode]: getDescItemDiscountOfferActionNode,
  [EventActionNodeType.ItemBonusOfferActionNode]: getDescItemBonusOfferActionNode,
  [EventActionNodeType.ItemOfferActionNode]: getDescItemOfferActionNode,
  [EventActionNodeType.CreateVirtualSKUNode]: getDescVirtualSKUActionNode,
  [EventActionNodeType.CreateItemBannerActionNode]: getCreateItemBannerActionNode,
  [EventActionNodeType.AddToSegmentNode]: getAddToSegmentNode,
  [EventActionNodeType.EmailActionNode]: getDescCommunicationNode,
  [EventActionNodeType.MobilePushActionNode]: getDescCommunicationNode,
  [EventActionNodeType.BrowserPushNotificationActionNode]: getDescCommunicationNode,
  [EventActionNodeType.MobilePopupActionNode]: getDescCommunicationNode,
  [EventActionNodeType.BrowserPopupNotificationActionNode]: getDescCommunicationNode,

  [EventActionNodeType.ConditionNode]: getDescConditionNode,
  [EventActionNodeType.SplitNode]: getDescSplitNode,
  [EventActionNodeType.TimerNode]: getDescTimerNode,
} as unknown as { [key: string]: DescFunc }
