import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, TableZeroState } from '../../../components/ui'
import { Plus } from '../../../components/icons'
import { useGenerateCompanyPath } from '../../../libs/hooks'
import { GAME_WEBHOOKS_NEW_PATH } from '../../../libs/routerPaths'
import { useContext } from 'react'
import { AuthContext, IAuthContext } from '@/Context'
import { isAdmin } from '@/security'

export const WebhookTableZeroState = () => {
  const { t } = useTranslation()
  const { generatePath } = useGenerateCompanyPath()
  const context = useContext(AuthContext) as IAuthContext
  return (
    <TableZeroState
      title={t('webhook.zero-state.title')}
      message={t('webhook.zero-state.message')}
      buttons={
        <>
          {isAdmin(context.customer) && (
            <Link to={generatePath(GAME_WEBHOOKS_NEW_PATH)}>
              <Button variant="primary">
                <Plus size={14} />
                <span>{t('webhook.zero-state.add-button')}</span>
              </Button>
            </Link>
          )}
          <a href="https://docs.aghanim.com/webhooks/overview" target="_blank">
            <Button variant="tertiary-gray">{t('webhook.zero-state.learn-more-button')}</Button>
          </a>
        </>
      }
    />
  )
}
