import { Link, generatePath, useParams } from 'react-router-dom'
import i18next from 'i18next'
import { Pagination, Table, TableCell, TableRow, TableRowSkeleton, TableZeroState } from '@/ui'
import { useState } from 'react'
import { usePlayerClaimedItemsQuery } from '@/layouts/player/api/usePlayerClaimedItemsQuery'
import { ItemRead, UserItemClaimed } from '@/api/dashboard'
import { SKU_ITEMS_DETAILS_PATH } from '@/libs'
import { SkuImageName } from '@/layouts/game-items/components/SkuImageName'
import { DateTimeValue } from '@/components/ui/DateTimeValue'

export default function UserClaimedItemsTable(props: {
  userId: string
  endTs?: number
  exclude_payment_number?: string
}) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const LIMIT = 10

  const [page, setPage] = useState(1)

  const query = { offset: (page - 1) * LIMIT, limit: LIMIT }
  const { data: items = [], isLoading } = usePlayerClaimedItemsQuery(companyId, gameId, props.userId, query)

  const needShowPagination = () => {
    if (isLoading) {
      return false
    }
    if (items.length === 0 && page == 1) {
      return false
    }

    if (items.length < LIMIT && page == 1) {
      return false
    }

    return true
  }

  const getItemInfo = (claimed: UserItemClaimed) => {
    return (
      <Link
        className="flex items-center gap-2"
        to={generatePath(SKU_ITEMS_DETAILS_PATH, { companyId: companyId, gameId: gameId, itemId: claimed.item_id })}
      >
        <SkuImageName
          item={
            {
              id: claimed.item_id,
              name: claimed.item_name?.['en'],
              is_stackable: claimed.item_is_stackable,
              sku: claimed.item_sku,
              image_url: claimed.item_image_url,
            } as ItemRead
          }
        />
      </Link>
    )
  }

  if (!isLoading && !items.length) {
    return (
      <TableZeroState
        className="mt-4 h-[147px]"
        title={i18next.t('claimed.no-items')}
        message={i18next.t('claimed.no-items.desc')}
      />
    )
  }

  if (isLoading) {
    return <TableRowSkeleton rowCount={5} columnCount={5} />
  }

  return (
    <>
      <Table>
        <TableRow variant="header">
          <TableCell width="200%">{i18next.t('claimed.item')}</TableCell>
          <TableCell>{i18next.t('claimed.type')}</TableCell>
          <TableCell>{i18next.t('claimed.created_at')}</TableCell>
        </TableRow>

        {items.map(it => (
          <TableRow key={it.item_id}>
            <TableCell width="200%">{getItemInfo(it)}</TableCell>
            <TableCell>{i18next.t(`claimed.type.${it.object_type}`)}</TableCell>
            <TableCell>
              <DateTimeValue value={it.created_at} />
            </TableCell>
          </TableRow>
        ))}
      </Table>

      {needShowPagination() && (
        <Pagination
          style={{
            position: 'relative',
          }}
          hasNext={items.length >= query.limit}
          page={page}
          onPageChange={p => setPage(p)}
          pageItems={items.length}
        />
      )}
    </>
  )
}
