import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <>
      <path
        d="M9.26,10.37c-.42,0-.78.16-1.09.48s-.47.72-.47,1.18.15.88.46,1.21c.31.33.67.5,1.09.5h.01c.42,0,.79-.16,1.09-.49.3-.33.46-.74.46-1.21s-.15-.87-.46-1.19-.67-.48-1.09-.48Z"
        fill="currentColor"
      />
      <polygon points="14.37 13.65 14.36 13.65 14.36 13.65 14.37 13.65" fill="currentColor" />
      <path
        d="M15.25,10.03c-.27-.17-.56-.25-.89-.25s-.61.09-.88.26c-.27.17-.49.4-.65.69-.16.29-.24.62-.24.97,0,.54.18,1,.53,1.38.35.38.76.57,1.24.57.48,0,.9-.19,1.24-.57.35-.38.53-.85.53-1.4,0-.36-.08-.68-.24-.97-.16-.29-.38-.52-.65-.68Z"
        fill="currentColor"
      />
      <path
        d="M15.5,4.53h-7c-2.21,0-4,1.79-4,4v7c0,2.21,1.79,4,4,4h7c2.21,0,4-1.79,4-4v-7c0-2.21-1.79-4-4-4ZM18,15.53c0,.35-.07.68-.2.97-.08-.16-.16-.32-.27-.48-.31-.42-.73-.77-1.27-1.05-.54-.28-1.17-.42-1.9-.42-.57,0-1.08.09-1.54.27s-.85.41-1.18.7c-.33.29-.57.6-.75.94-.17.34-.26.67-.26.99,0,.25.05.44.14.57h-1.01c-.06-.18-.1-.38-.1-.59,0-.46.11-.9.32-1.34.21-.44.5-.83.88-1.17-.21-.13-.44-.22-.72-.29-.27-.07-.57-.1-.89-.1-.64,0-1.21.13-1.7.4-.49.27-.88.61-1.16,1.02-.1.15-.16.3-.23.44-.1-.28-.17-.57-.17-.88v-7,.02c.01-1.38,1.13-2.5,2.51-2.5h7c1.38,0,2.5,1.12,2.5,2.5v7Z"
        fill="currentColor"
      />
    </>,
  ],
  [
    'fill',
    <path
      d="M15.5,4.53h-7c-2.21,0-4,1.79-4,4v7c0,2.21,1.79,4,4,4h7c2.21,0,4-1.79,4-4v-7c0-2.21-1.79-4-4-4ZM8.17,10.86c.31-.32.68-.48,1.09-.48s.78.16,1.09.48c.31.31.46.71.46,1.18s-.15.89-.46,1.22c-.3.33-.66.49-1.09.49s-.78-.16-1.09-.49-.46-.74-.46-1.22.15-.86.46-1.18ZM12.82,10.73c.17-.29.38-.52.65-.7.27-.17.57-.25.89-.25s.62.08.89.25.48.4.64.68c.17.29.25.62.25.97,0,.56-.18,1.02-.53,1.4s-.77.57-1.25.57-.9-.19-1.25-.57-.52-.84-.53-1.38c0-.36.08-.68.24-.97ZM8.5,18.03c-1.07,0-1.99-.68-2.34-1.63.06-.15.14-.29.24-.43.29-.41.67-.75,1.16-1.02s1.06-.4,1.7-.4c.32,0,.62.03.89.1.28.06.52.16.72.28-.38.35-.67.73-.88,1.17s-.32.88-.32,1.34c0,.21.03.41.09.59h-1.26ZM15.5,18.03h-4.72c-.1-.13-.15-.32-.15-.57,0-.32.09-.65.26-.99.18-.34.43-.65.75-.94.33-.29.72-.52,1.18-.7.45-.18.97-.27,1.54-.27.73,0,1.36.14,1.9.42s.96.63,1.27,1.05c.11.15.21.3.28.45-.37.91-1.26,1.55-2.31,1.55Z"
      fill="currentColor"
    />,
  ],
  [
    'duotone',
    <>
      <rect y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12c0,3.31,2.69,6,6,6h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <path
        d="M15.5,4.5h-7c-2.21,0-4,1.79-4,4v7c0,2.21,1.79,4,4,4h7c2.21,0,4-1.79,4-4v-7c0-2.21-1.79-4-4-4ZM8.17,10.83c.31-.32.68-.48,1.09-.48s.78.16,1.09.48c.31.31.46.71.46,1.18s-.15.89-.46,1.22c-.3.33-.66.49-1.09.49s-.78-.16-1.09-.49c-.31-.33-.46-.74-.46-1.22s.15-.86.46-1.18ZM12.82,10.7c.17-.29.38-.52.65-.7.27-.17.57-.25.89-.25s.62.08.89.25c.27.17.48.4.64.68.17.29.25.62.25.97,0,.56-.18,1.02-.53,1.4s-.77.57-1.25.57-.9-.19-1.25-.57-.52-.84-.53-1.38c0-.36.08-.68.24-.97ZM8.5,18c-1.07,0-1.99-.68-2.34-1.63.06-.15.14-.29.24-.43.29-.41.67-.75,1.16-1.02.49-.27,1.06-.4,1.7-.4.32,0,.62.03.89.1.28.06.52.16.72.28-.38.35-.67.73-.88,1.17s-.32.88-.32,1.34c0,.21.03.41.09.59h-1.26ZM15.5,18h-4.72c-.1-.13-.15-.32-.15-.57,0-.32.09-.65.26-.99.18-.34.43-.65.75-.94.33-.29.72-.52,1.18-.7.45-.18.97-.27,1.54-.27.73,0,1.36.14,1.9.42.54.28.96.63,1.27,1.05.11.15.21.3.28.45-.37.91-1.26,1.55-2.31,1.55Z"
        fill="currentColor"
      />
    </>,
  ],
])

export const Team = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
