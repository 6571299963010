import DashboardCard from '../../../../components/shared/DashboardCard'
import ReactECharts from 'echarts-for-react'
import i18next from 'i18next'
import { useTheme } from '@mui/material'
import { PointValue } from '../../../../api/dashboard'
import { LoaderContainer } from '../../../dashboard/components/Loader/LoaderContainer'
import Loader from '../../../dashboard/components/Loader/Loader'
import ZeroState from '../../../dashboard/components/ZeroState/ZeroState'
import { Legend } from '../../../dashboard/components/Legend'
import { getDateCategoryParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'

const NotificationByDateChart = (props: {
  loading: boolean
  totalByDate: PointValue[]
  totalByDateRead: PointValue[]
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const totalByDate = props.totalByDate
  const totalByDateRead = props.totalByDateRead

  const options = {
    title: {
      show: false,
    },
    tooltip: {
      backgroundColor: '#000000CC',
      borderColor: 'transparent',
      trigger: 'axis',
      formatter: function (params: { axisValue: string; value: number; dataIndex: number }[]) {
        let cat = totalByDate[params[0].dataIndex].category
        let sent = params[0]
        let read = params[1]

        let day = t('intl.DateTime', getDateCategoryParams(cat))

        return `<div class="chart-tooltip">
                  <div class="chart-tooltip--title">${day}</div>
                  <div class="chart-tooltip--separator"></div>
                  <div class="chart-tooltip--subtitle">
                      <span>${i18next.t('campaign.notifications.total')}:</span>
                      <span>${sent?.value || 0}</span>
                  </div>
                  <div class="chart-tooltip--subtitle">
                    <span>${i18next.t('campaign.notifications.read')}:</span>
                    <span>${read?.value || 0}</span>
                  </div>
              </div>`
      },
    },
    legend: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    grid: {
      top: 5,
      left: 20,
      right: 20,
      bottom: 20,
      containLabel: false,
    },
    xAxis: [
      {
        axisLabel: {
          fontSize: 12,
          color: theme.palette.text.tertiary,
          fontWeight: '500',
          fontFamily: 'InterAg',
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        type: 'category',
        boundaryGap: false,
        data: totalByDate.map(it => t('intl.DateTime', getDateCategoryParams(it.category))),
        splitLine: {
          show: true,
          lineStyle: {
            color: theme.palette.grey.borderSecondary,
            type: 'solid',
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
      },
    ],
    axisPointer: {
      lineStyle: {
        color: '#000',
        width: 1,
        type: 'solid',
      },
    },
    series: [
      {
        name: i18next.t('campaign.notifications.total'),
        type: 'line',
        stack: 'Total',
        showSymbol: false,
        color: '#0EA5E9',
        lineStyle: {
          width: 2,
        },
        smooth: 0.1,
        data: totalByDate.map(it => it.value),
      },
      {
        name: i18next.t('campaign.notifications.read'),
        type: 'line',
        showSymbol: false,
        smooth: 0.1,
        color: '#86EFAC',
        data: totalByDateRead.map(it => it.value),
        lineStyle: {
          width: 2,
        },
      },
    ],
  }

  return (
    <DashboardCard
      title={i18next.t('campaign.notifications.by-date')}
      style={{ height: '289px', flexGrow: 1, gap: '8px' }}
    >
      {props.loading ? (
        <LoaderContainer style={{ height: '221px' }}>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          {options.xAxis[0].data.length > 0 ? (
            <>
              <ReactECharts
                option={options}
                style={{
                  height: '221px',
                  width: '100%',
                }}
              />
              <Legend
                className="mt-2"
                items={[
                  {
                    name: i18next.t('campaign.notifications.total'),
                    classColor: 'bg-fg-brand-primary',
                    type: 'line',
                  },
                  {
                    name: i18next.t('campaign.notifications.read'),
                    classColor: 'bg-fg-success-primary',
                    type: 'line',
                  },
                ]}
              />
            </>
          ) : (
            <ZeroState />
          )}
        </>
      )}
    </DashboardCard>
  )
}

export default NotificationByDateChart
