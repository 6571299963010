import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { HUB_API } from '../HubAPI'
import i18next from 'i18next'
import { Button } from '../components/ui'
import { ResponseError, dashboardClient, getErrorText } from '../api'
import { InvitePreview } from '../api/dashboard'
import AcceptForm from '../layouts/accept-form/AcceptForm'
import CenteredProgress from '../components/CenteredProgress'
import UserAvatar from '../components/UserAvatar'
import { HOME_PATH } from '../libs/routerPaths'
import { capitalizeFirstLetter } from '../util'

export const INVITE_ID_STORAGE_KEY = 'inviteId'

export default function AcceptInvite() {
  const navigate = useNavigate()
  const { inviteId } = useParams() as { inviteId: string }
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect, isLoading, user } = useAuth0()

  const [acceptForm, setAcceptForm] = useState<boolean>(false)
  const [info, setInfo] = useState<InvitePreview | null>(null)
  const [error, setError] = useState<ResponseError | null>(null)
  const acceptInvite = async () => {
    try {
      let { data } = await dashboardClient.v1.acceptInvite(inviteId)
      navigate(generatePath(HOME_PATH, { companyId: data.company_id, gameId: data?.game_id || '' }))
    } catch (e) {
      console.log(e)
    } finally {
      localStorage.removeItem(INVITE_ID_STORAGE_KEY)
    }
  }

  const declineClick = async () => {
    localStorage.removeItem(INVITE_ID_STORAGE_KEY)
    navigate('/')
  }

  const loadInfo = async () => {
    try {
      const { data } = await dashboardClient.v1.getInvitePreview(inviteId)
      setInfo(data)
    } catch (e) {
      localStorage.removeItem(INVITE_ID_STORAGE_KEY)
      const err = e as ResponseError
      switch (err.status) {
        case 451:
          setAcceptForm(true)
          break
        case 401:
          navigate('/')
          break
        case 403:
          navigate('/403')
          break
        case 404:
          navigate('/404')
          break
        case 400:
        default:
          setError(err)
          break
      }
    }
  }

  const load = async () => {
    if (!HUB_API.getToken()) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: import.meta.env.VITE_AUTH0_API_AUDIENCE,
          scope: 'read:current_user',
        },
      })
      HUB_API.setToken(accessToken)
    }

    let hubApiUrl = import.meta.env.VITE_HUB_API_URL || ''
    if (hubApiUrl.indexOf('localhost') > -1 || window.location.href.indexOf('localhost') > -1) {
      setTimeout(loadInfo, 2000)
    } else {
      loadInfo()
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      load()
    }
  }, [isAuthenticated])

  const renderLoader = () => {
    return (
      <div className="flex w-full items-center justify-center" style={{ height: '100vh' }}>
        <CenteredProgress />
      </div>
    )
  }

  if (isLoading) {
    return renderLoader()
  }

  if (!isAuthenticated) {
    localStorage.setItem(INVITE_ID_STORAGE_KEY, inviteId!)
    loginWithRedirect()
  }

  if (!acceptForm && !error) {
    if (!info) {
      return renderLoader()
    }
  }

  const renderMainForm = () => {
    return (
      <div
        style={{
          width: '690px',
          boxShadow: '0px 24px 48px -12px #1018282E',
          background: '#FFFFFF',
          zIndex: 1,
        }}
        className="flex flex-col items-center rounded-[36px] bg-fg-primary p-12"
      >
        <div className="flex flex-col items-center">
          <div className="flex gap-3">
            <UserAvatar name={info?.invited_by_user || ''} size="64px" />
            <svg width="48" height="64" viewBox="0 0 48 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 19.5C11.7239 19.5 11.5 19.7239 11.5 20C11.5 20.2761 11.7239 20.5 12 20.5V19.5ZM33.3333 20C33.3333 21.4728 34.5272 22.6667 36 22.6667C37.4728 22.6667 38.6667 21.4728 38.6667 20C38.6667 18.5272 37.4728 17.3333 36 17.3333C34.5272 17.3333 33.3333 18.5272 33.3333 20ZM14 20.5C14.2761 20.5 14.5 20.2761 14.5 20C14.5 19.7239 14.2761 19.5 14 19.5V20.5ZM18 19.5C17.7239 19.5 17.5 19.7239 17.5 20C17.5 20.2761 17.7239 20.5 18 20.5V19.5ZM22 20.5C22.2761 20.5 22.5 20.2761 22.5 20C22.5 19.7239 22.2761 19.5 22 19.5V20.5ZM26 19.5C25.7239 19.5 25.5 19.7239 25.5 20C25.5 20.2761 25.7239 20.5 26 20.5V19.5ZM30 20.5C30.2761 20.5 30.5 20.2761 30.5 20C30.5 19.7239 30.2761 19.5 30 19.5V20.5ZM34 19.5C33.7239 19.5 33.5 19.7239 33.5 20C33.5 20.2761 33.7239 20.5 34 20.5V19.5ZM12 20.5H14V19.5H12V20.5ZM18 20.5H22V19.5H18V20.5ZM26 20.5H30V19.5H26V20.5ZM34 20.5H36V19.5H34V20.5Z"
                fill="#0EA5E9"
              />
              <path
                d="M9.33333 44C9.33333 45.4728 10.5272 46.6667 12 46.6667C13.4728 46.6667 14.6667 45.4728 14.6667 44C14.6667 42.5272 13.4728 41.3333 12 41.3333C10.5272 41.3333 9.33333 42.5272 9.33333 44ZM36 44.5C36.2761 44.5 36.5 44.2761 36.5 44C36.5 43.7239 36.2761 43.5 36 43.5V44.5ZM14 44.5C14.2761 44.5 14.5 44.2761 14.5 44C14.5 43.7239 14.2761 43.5 14 43.5V44.5ZM18 43.5C17.7239 43.5 17.5 43.7239 17.5 44C17.5 44.2761 17.7239 44.5 18 44.5V43.5ZM22 44.5C22.2761 44.5 22.5 44.2761 22.5 44C22.5 43.7239 22.2761 43.5 22 43.5V44.5ZM26 43.5C25.7239 43.5 25.5 43.7239 25.5 44C25.5 44.2761 25.7239 44.5 26 44.5V43.5ZM30 44.5C30.2761 44.5 30.5 44.2761 30.5 44C30.5 43.7239 30.2761 43.5 30 43.5V44.5ZM34 43.5C33.7239 43.5 33.5 43.7239 33.5 44C33.5 44.2761 33.7239 44.5 34 44.5V43.5ZM12 44.5H14V43.5H12V44.5ZM18 44.5H22V43.5H18V44.5ZM26 44.5H30V43.5H26V44.5ZM34 44.5H36V43.5H34V44.5Z"
                fill="#0EA5E9"
              />
            </svg>

            <UserAvatar name={user?.name} url={user?.picture} size="64px" />
          </div>

          <span className="mt-[48px] text-[24px] font-semibold text-text-primary" style={{ lineHeight: '24px' }}>
            {i18next.t('invite.welcome')}
          </span>

          <span
            className="mt-3 text-center text-[18px] text-text-primary"
            dangerouslySetInnerHTML={{
              __html: i18next.t('invite.message', {
                company: info!.company_name,
                user: info?.invited_by_user
                  ? capitalizeFirstLetter(info.invited_by_user)
                  : i18next.t('invite.unknown-customer'),
              }),
            }}
          />
        </div>

        <div className="mt-6 flex w-full flex-col rounded-xl bg-fg-secondary p-6">
          <span className="font-semibold text-text-primary">{i18next.t(`users.role.${info!.role}`)}</span>
          <span className="text-text-secondary">{i18next.t(`users.role.${info!.role}.desc`)}</span>
        </div>

        <div className="mt-6 flex w-full gap-4">
          <Button size={'lg'} style={{ width: '50%' }} onClick={declineClick}>
            {i18next.t('invite.decline')}
          </Button>

          <Button onClick={acceptInvite} variant="primary" size={'lg'} style={{ width: '50%' }}>
            {i18next.t('invite.accept')}
          </Button>
        </div>
      </div>
    )
  }

  const renderError = () => {
    return (
      <div
        style={{
          width: '690px',
          height: '360px',
          boxShadow: '0px 24px 48px -12px #1018282E',
          background: 'linear-gradient(273.02deg, rgba(255, 255, 255, 0) 5.02%, #FFFFFF 94.98%)',
          zIndex: 1,
        }}
        className="flex flex-col items-center gap-6 rounded-[36px] bg-fg-primary p-12"
      >
        <div className="flex flex-col items-center gap-3">
          <span
            style={{ width: '56px' }}
            className="inline-block rounded-md border border-border-primary bg-fg-primary px-3 py-2 text-[16px] font-semibold"
          >
            400
          </span>

          <div className="text-[36px] font-semibold" style={{ lineHeight: '43px' }}>
            Oops
          </div>

          <div className="text-center text-[16px] text-text-secondary">{getErrorText(error!)}</div>
        </div>

        <div className="w-full text-center">
          <Button onClick={() => navigate('/')} variant="primary" size={'xl'} style={{ width: '50%' }}>
            {i18next.t('go-home')}
          </Button>
        </div>
        <span
          className="text-[16px] text-text-secondary"
          dangerouslySetInnerHTML={{ __html: i18next.t('access-denied.footer') }}
        />
      </div>
    )
  }

  if (acceptForm) {
    return (
      <AcceptForm
        onClose={() => {
          load()
          setAcceptForm(false)
        }}
      />
    )
  }

  return error ? renderError() : renderMainForm()
}
