import { Position } from 'reactflow'

import { useTheme } from '@mui/material/styles'
import { DescBlock, IOHandle, getNodeHoverStyle, hasNextNode } from '../../campaigns/blocks/common'
import { getNodeColor, getNodeIcon } from '../../campaigns/icons'
import { EventActionNodeType, IBlockProps } from '../../campaigns/types'
import { NextNode } from '../../campaigns/components/NextNode/NextNode'
import { DESCRIPTIONS, DescFunc } from '../../campaigns/blocks/descriptions'
import { getDescAddStoreNode } from './desc/getDescAddStoreNode'
import { Box } from '@mui/material'

DESCRIPTIONS[EventActionNodeType.AddStoreNode] = getDescAddStoreNode as DescFunc

export default function AddStoreNode(props: IBlockProps) {
  const theme = useTheme()
  const nodeColor = getNodeColor(props.data.node.model_type, theme)
  return (
    <>
      <IOHandle color={theme.palette.purple['A100']} position={Position.Top} type="target" id="input" />

      <Box
        sx={{
          border: `2px solid ${nodeColor}`,
          cursor: 'pointer',
          backgroundColor: theme.palette.background.content,
          position: 'relative',
          padding: theme.spacing(1.75),
          borderRadius: theme.spacing(0.75),
          boxShadow: props.selected ? `1px 4px 22px 0px ${nodeColor}` : '',
          '&:hover': {
            boxShadow: `1px 4px 22px 0px ${nodeColor}`,
          },
          ...getNodeHoverStyle(nodeColor),
        }}
        width={theme.spacing(8.5)}
        height={theme.spacing(8.5)}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        {getNodeIcon(props.data.node.model_type, theme)}
        <DescBlock node={props.data.node} width={'224px'} selected={props.selected} />
      </Box>

      <NextNode {...props} />
      <IOHandle
        color={theme.palette.purple['A100']}
        hasNext={hasNextNode(props.data.graph, props.data.node)}
        position={Position.Bottom}
        type="source"
        id="output"
      />
    </>
  )
}
