import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <>
      <path
        d="M18.34,4.5h-7.67c-1.1,0-2,.9-2,2v4.33c0,1.1.9,2,2,2h7.67c1.1,0,2-.9,2-2v-4.33c0-1.1-.9-2-2-2ZM18.84,10.83c0,.28-.22.5-.5.5h-7.67c-.28,0-.5-.22-.5-.5v-4.33c0-.28.22-.5.5-.5h7.67c.28,0,.5.22.5.5v4.33Z"
        fill="currentColor"
      />
      <path
        d="M14.58,14.09c-.41,0-.75.34-.75.75v2.66c0,.28-.23.5-.5.5h-7.67c-.28,0-.5-.23-.5-.5v-5.16c0-.27.22-.5.5-.5h1c.41,0,.75-.34.75-.75s-.34-.75-.75-.75h-1c-1.1,0-2,.9-2,2v5.16c0,1.1.9,2,2,2h7.67c1.1,0,2-.9,2-2v-2.66c0-.41-.34-.75-.75-.75Z"
        fill="currentColor"
      />
      <path
        d="M6.03,16.17c0,.46.37.83.83.83h2.5c.46,0,.83-.37.83-.83s-.37-.83-.83-.83h-2.5c-.46,0-.83.37-.83.83Z"
        fill="currentColor"
      />
      <circle cx="14.5" cy="8.66" r="1.67" fill="currentColor" />
    </>,
  ],
  [
    'fill',
    <>
      <path
        d="M18.34,4.5h-7.67c-1.11,0-2,.9-2,2v4.33c0,1.1.89,2,2,2h7.67c1.1,0,2-.9,2-2v-4.33c0-1.1-.9-2-2-2ZM14.5,10.34c-.92,0-1.67-.75-1.67-1.68s.75-1.67,1.67-1.67,1.67.75,1.67,1.67-.75,1.68-1.67,1.68Z"
        fill="currentColor"
      />
      <path
        d="M14.33,14.09h-3.62c-1.82,0-3.3-1.48-3.3-3.3,0-.25-.2-.45-.45-.45h-1.3c-1.1,0-2,.9-2,2v5.16c0,1.1.9,2,2,2h7.67c1.1,0,2-.9,2-2v-2.41c0-.55-.45-1-1-1ZM9.36,17h-2.5c-.46,0-.83-.37-.83-.83s.37-.83.83-.83h2.5c.46,0,.83.37.83.83s-.37.83-.83.83Z"
        fill="currentColor"
      />
    </>,
  ],
  [
    'duotone',
    <>
      <rect y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12c0,3.31,2.69,6,6,6h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <g>
        <path
          d="M18.34,4.5h-7.67c-1.11,0-2,.9-2,2v4.33c0,1.1.89,2,2,2h7.67c1.1,0,2-.9,2-2v-4.33c0-1.1-.9-2-2-2ZM14.5,10.34c-.92,0-1.67-.75-1.67-1.68s.75-1.67,1.67-1.67,1.67.75,1.67,1.67-.75,1.68-1.67,1.68Z"
          fill="currentColor"
        />
        <path
          d="M14.33,14.09h-3.62c-1.82,0-3.3-1.48-3.3-3.3,0-.25-.2-.45-.45-.45h-1.3c-1.1,0-2,.9-2,2v5.16c0,1.1.9,2,2,2h7.67c1.1,0,2-.9,2-2v-2.41c0-.55-.45-1-1-1ZM9.36,17h-2.5c-.46,0-.83-.37-.83-.83s.37-.83.83-.83h2.5c.46,0,.83.37.83.83s-.37.83-.83.83Z"
          fill="currentColor"
        />
      </g>
    </>,
  ],
])

export const PaymentMethod = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
