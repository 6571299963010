import { CreateUploadLinkResponse, S3Bucket } from '../../types'
import { dashboardClient } from '../index'

interface UploadImageParams {
  bucket: S3Bucket
  onProgress?: (progress: number) => void
}

/**
 *
 * @param file
 * @param params
 */
export const fetchUploadImage = async (file: File, params: UploadImageParams) => {
  const { data } = await dashboardClient.v1.createUploadLink({
    image_format: file.type.split('/')[1],
    bucket: params.bucket,
  })

  return new Promise<CreateUploadLinkResponse>((resolve, reject) => {
    const request = new XMLHttpRequest()
    request.open('PUT', data.upload_url)
    request.setRequestHeader('Content-Type', file.type)

    request.upload.addEventListener('progress', e => {
      if (params.onProgress) {
        params.onProgress(Math.round((e.loaded / e.total) * 100))
      }
    })

    request.addEventListener('load', () => {
      resolve(data)
    })

    request.addEventListener('error', () => {
      reject()
    })

    request.send(file)
  })
}
