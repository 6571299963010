import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { CouponUpdate } from '../../../api/dashboard'

interface CouponUpdateMutate {
  id: string
  update: CouponUpdate
}

export const useCouponUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, update }: CouponUpdateMutate) => dashboardClient.v1.updateCoupon(companyId, gameId, id, update),
    onSuccess: data => {
      queryClient.setQueryData(['coupons', gameId], data.data)
      queryClient.invalidateQueries({ queryKey: ['coupons', gameId] })
    },
  })
}
