/**
 *
 * @param string
 * @param length
 */
export const truncateString = (string: string, length: number) => {
  if (string.length > length) {
    return string.slice(0, length) + '...'
  } else {
    return string
  }
}
