import { Globe1 } from '../../../components/icons'

interface NewsSlugCaptionProps {
  base: string | undefined
  slug: string
  parentSlug?: string
}

export const PagesSlugCaption = ({ base, slug, parentSlug }: NewsSlugCaptionProps) => {
  if (!slug) {
    return null
  }
  const href = `${base}/${parentSlug ? `${parentSlug}/` : ''}${slug}`
  return (
    <a className="flex items-center gap-3 p-1.5" target="_blank" href={href}>
      <Globe1 size={18} />
      <div className="text-text-secondary">{href}</div>
    </a>
  )
}
