import { Check, Copy03 } from '@/icons'
import { forwardRef, useEffect, useState } from 'react'
import { Input, InputProps } from '@/ui'

export const InputCopyButton = forwardRef<HTMLInputElement, InputProps>(function InputCopyButton(
  { value, ...rest },
  ref,
) {
  const [success, setSuccess] = useState(false)

  const onCopyClick = () => {
    setSuccess(true)
    navigator.clipboard.writeText(value as string)
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false)
      }, 2500)
    }
  }, [success, setSuccess])

  return (
    <Input
      {...rest}
      value={value}
      ref={ref}
      extraRight={
        success ? (
          <Check className="ml-auto text-text-brand-primary" size={16} strokeWidth={3} />
        ) : (
          <Copy03 size={16} onClick={onCopyClick} className="z-10 ml-auto cursor-pointer" />
        )
      }
    />
  )
})
