import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '../types'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <path
      d="M19,8.96c-.14-1.26-1.18-2.21-2.43-2.21h-.44c-.41,0-.75.34-.75.76s.34.76.75.76h.44c.48,0,.88.36.94.85l.13,1.3c.02.17-.15.29-.31.23-.37-.15-.78-.23-1.21-.23-1.43,0-2.65.91-3.15,2.18-.32-.08-.64-.11-.97-.11h0c-.33,0-.65.04-.97.11-.5-1.27-1.72-2.18-3.16-2.18-.42,0-.83.08-1.21.23-.16.06-.32-.05-.31-.23l.13-1.3c.05-.49.46-.85.94-.85h.44c.41,0,.75-.34.75-.76s-.34-.76-.75-.76h-.44c-1.25,0-2.29.95-2.43,2.21,0,0-.5,2.99-.5,5.01.07,1.82,1.56,3.28,3.37,3.28s3.22-1.38,3.36-3.13c.25-.08.5-.11.76-.11h0c.26,0,.52.04.76.11.14,1.75,1.6,3.13,3.36,3.13s3.31-1.46,3.37-3.28c0-2.03-.49-5.01-.5-5.01ZM7.87,15.73c-1.04,0-1.87-.84-1.88-1.9,0-.15.02-.29.04-.42.19-.85.94-1.49,1.84-1.49,1.04,0,1.87.85,1.87,1.9s-.84,1.9-1.87,1.9ZM18.01,13.84c0,1.05-.84,1.9-1.88,1.9s-1.87-.85-1.87-1.9.84-1.9,1.87-1.9c.9,0,1.65.64,1.84,1.49.03.13.04.27.04.41h0Z"
      fill="currentColor"
    />,
  ],
  [
    'fill',
    <path
      d="M19,8.96c-.14-1.26-1.18-2.21-2.43-2.21h-.44c-.41,0-.75.34-.75.76s.34.76.75.76h.44c.48,0,.89.36.94.85l.14,1.3c.02.17-.15.29-.31.23-.38-.15-.78-.23-1.21-.23-1.43,0-2.65.91-3.15,2.18-.32-.08-.65-.11-.98-.11s-.65.04-.97.11c-.5-1.27-1.73-2.18-3.16-2.18-.42,0-.83.08-1.21.23-.16.06-.32-.05-.31-.23l.13-1.3c.05-.49.46-.85.94-.85h.44c.41,0,.75-.34.75-.76s-.34-.76-.75-.76h-.44c-1.25,0-2.3.95-2.43,2.21,0,0-.5,2.99-.5,5.01.07,1.82,1.56,3.28,3.38,3.28s3.22-1.38,3.36-3.13c.26-.08.5-.11.77-.11s.52.04.77.11c.14,1.75,1.6,3.13,3.36,3.13s3.31-1.46,3.38-3.28c0-2.03-.5-5.01-.5-5.01ZM7.86,12.51c-.62,0-1.11.51-1.11,1.12,0,.21-.17.38-.38.38s-.38-.17-.38-.38c0-1.04.83-1.88,1.86-1.88.21,0,.37.17.37.38s-.16.38-.37.38ZM16.12,12.51c-.61,0-1.11.51-1.11,1.12,0,.21-.17.38-.38.38s-.38-.17-.38-.38c0-1.04.83-1.88,1.86-1.88.21,0,.38.17.38.38s-.17.38-.38.38Z"
      fill="currentColor"
    />,
  ],
  [
    'duotone',
    <>
      <rect x="0" y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12C0,21.31,2.69,24,6,24h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <path
        d="M19,8.96c-.14-1.26-1.18-2.21-2.43-2.21h-.44c-.41,0-.75.34-.75.76s.34.76.75.76h.44c.48,0,.89.36.94.85l.14,1.3c.02.17-.15.29-.31.23-.38-.15-.78-.23-1.21-.23-1.43,0-2.65.91-3.15,2.18-.32-.08-.65-.11-.98-.11s-.65.04-.97.11c-.5-1.27-1.73-2.18-3.16-2.18-.42,0-.83.08-1.21.23-.16.06-.32-.05-.31-.23l.13-1.3c.05-.49.46-.85.94-.85h.44c.41,0,.75-.34.75-.76s-.34-.76-.75-.76h-.44c-1.25,0-2.3.95-2.43,2.21,0,0-.5,2.99-.5,5.01.07,1.82,1.56,3.28,3.38,3.28s3.22-1.38,3.36-3.13c.26-.08.5-.11.77-.11s.52.04.77.11c.14,1.75,1.6,3.13,3.36,3.13s3.31-1.46,3.38-3.28c0-2.03-.5-5.01-.5-5.01ZM7.86,12.51c-.62,0-1.11.51-1.11,1.12,0,.21-.17.38-.38.38s-.38-.17-.38-.38c0-1.04.83-1.88,1.86-1.88.21,0,.37.17.37.38s-.16.38-.37.38ZM16.12,12.51c-.61,0-1.11.51-1.11,1.12,0,.21-.17.38-.38.38s-.38-.17-.38-.38c0-1.04.83-1.88,1.86-1.88.21,0,.38.17.38.38s-.17.38-.38.38Z"
        fill="currentColor"
      />
    </>,
  ],
])

export const Experts = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
