import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from '@/ui'
import { GAME_WEBHOOKS_PATH, useGenerateCompanyPath } from '@/libs'
import { GetStartedStepState } from '../types'
import { useGetStarted } from './useGetStarted'

/**
 *
 */
export const useWebhookTestStep = (): GetStartedStepState => {
  const { t } = useTranslation()
  const { generatePath } = useGenerateCompanyPath()
  const { check, complete } = useGetStarted()

  const id = 'webhookTest'

  const status = check('test_webhook') ? 'completed' : 'idle'

  const onComplete = () => {
    complete('test_webhook')
  }

  return {
    id,
    loading: false,
    status,
    title: t(`get-started.steps.${id}.title`),
    desc: t(`get-started.steps.${id}.desc`),
    cta: (
      <Link to={generatePath(GAME_WEBHOOKS_PATH)}>
        <Button
          className="min-w-40"
          variant={status === 'completed' ? 'outline' : 'secondary-brand'}
          size="lg"
          onClick={onComplete}
        >
          {t(`get-started.steps.${id}.cta.label`)}
        </Button>
      </Link>
    ),
  }
}
