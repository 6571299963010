import { HTMLAttributes, ReactNode, forwardRef } from 'react'
import { cn } from '@/libs'

interface SidebarProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

export const Sidebar = forwardRef<HTMLDivElement, SidebarProps>(function ({ children, ...rest }, ref) {
  return (
    <div ref={ref} {...rest} className={cn('px-3', rest.className)} role="listbox">
      {children}
    </div>
  )
})
