import { forwardRef } from 'react'

import { Input, InputProps } from '../Input'

export const ColorPicker = forwardRef<HTMLInputElement, InputProps>(function ColorPicker({ ...rest }, ref) {
  return (
    <Input
      {...rest}
      ref={ref}
      extraLeft={
        <div
          className="rounded-sm border border-border-primary"
          style={{
            backgroundColor: rest.value as string,
            width: '24px',
            height: '24px',
            zIndex: 4000,
          }}
        >
          <input
            type="color"
            value={rest.value as string}
            onChange={rest.onChange}
            style={{ width: '24px', position: 'relative' }}
            className="cursor-pointer opacity-0"
          />
        </div>
      }
    />
  )
})
