import { useTheme } from '@mui/material/styles'
import { Box, ListItemButton, ListItemIcon, ListItemText, Menu, Popover } from '@mui/material'
import { CSSProperties, useRef, useState } from 'react'
import { Action, Placeholder, SearchInput, SelectField, Value } from '../index'
import { ChevronRight, ChevronSelectorVertical } from '@/icons'

export interface NestedMenuItemProps {
  name: string
  description?: string
  value: string
  type?: string
  children?: NestedMenuItemProps[]
  icon?: React.ReactNode
  rightIcon?: React.ReactNode
}

export interface SelectorSubMenuProps {
  menuWidth?: number
  items: NestedMenuItemProps[]
  label?: string
  helperText?: string
  value: string
  margin?: boolean
  fullWidth?: boolean
  disabled?: boolean
  onChange: (item: NestedMenuItemProps) => void
  style?: CSSProperties
  placeholder?: string
  color?: 'default' | 'success' | 'warning' | 'error'
}

let hoverId: ReturnType<typeof setTimeout> | null = null

export const SelectSubMenu = (props: SelectorSubMenuProps) => {
  const theme = useTheme()
  const [mainAnchorEl, setMainAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [hoverItem, setHoverItem] = useState<null | NestedMenuItemProps>(null)
  const rootRef = useRef<HTMLDivElement | null>(null)
  const [search, setSearch] = useState('')
  const menuWidth = props.menuWidth || 240

  const onClose = () => {
    setMainAnchorEl(null)
    setAnchorEl(null)
    setHoverItem(null)
  }

  const renderNestedMenuItem = (item: NestedMenuItemProps) => {
    return (
      <ListItemButton
        disableRipple
        key={item.value}
        onMouseEnter={e => {
          if (item.children?.length) {
            let t = e.currentTarget
            if (hoverId) {
              clearTimeout(hoverId)
            }
            hoverId = setTimeout(() => {
              if (mainAnchorEl) {
                setAnchorEl(t)
                setHoverItem(item)
              }
            }, 146)
          }
        }}
      >
        <ListItemText>{item.name}</ListItemText>
        <ListItemIcon sx={{ minWidth: '1px' }}>
          <ChevronRight />
        </ListItemIcon>
      </ListItemButton>
    )
  }

  const renderSimpleItem = (item: NestedMenuItemProps) => {
    let name: React.ReactNode = item.name
    if (search) {
      let startIndex = item.name.toLowerCase().indexOf(search.toLowerCase())
      let endIndex = startIndex + search.length
      name = (
        <span>
          {item.name.substring(0, startIndex)}
          <span
            style={{
              color: theme.palette.text.contrastText,
              backgroundColor: theme.palette.primary.main,
            }}
          >
            {item.name.substring(startIndex, endIndex)}
          </span>
          {item.name.substring(endIndex)}
        </span>
      )
    }

    return (
      <ListItemButton
        sx={{ pointerEvents: 'auto' }}
        key={item.value}
        onMouseEnter={() => {
          if (hoverId) {
            clearTimeout(hoverId)
          }
          hoverId = null
        }}
        onClick={() => {
          setSearch('')
          onClose()
          props.onChange(item)
        }}
      >
        <ListItemText secondary={item.description}>
          <Box display="flex" alignItems="center" gap={1}>
            {item.icon}
            {name}
            {item.rightIcon && <div style={{ marginLeft: 'auto' }}>{item.rightIcon}</div>}
          </Box>
        </ListItemText>
      </ListItemButton>
    )
  }

  // let borderColor: string
  // if (mainAnchorEl) {
  //   borderColor = theme.palette.primary.main
  // } else if (props.error) {
  //   borderColor = theme.palette.error.dark
  // } else {
  //   borderColor = theme.palette.grey.border
  // }

  let items = props.items

  if (search) {
    items = items
      .map(it => [...(it.children || [])])
      .flat()
      .filter(c => c.name.toLowerCase().includes(search.toLowerCase()))
  }

  return (
    <SelectField
      $color={props.color}
      ref={rootRef}
      aria-disabled={props.disabled}
      style={{
        ...props.style,
      }}
      onClick={() => {
        if (!mainAnchorEl) {
          setMainAnchorEl(rootRef.current)
        }
      }}
    >
      {props.value ? <Value>{props.value}</Value> : <Placeholder>{props.placeholder}</Placeholder>}
      <Action>
        <ChevronSelectorVertical size={16} />
      </Action>

      <Popover
        sx={{
          minWidth: menuWidth + 'px',
          maxHeight: '400px',
          maxWidth: '400px',
        }}
        open={!!mainAnchorEl}
        anchorEl={mainAnchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="border-b border-border-secondary" style={{ pointerEvents: 'auto', minWidth: menuWidth + 'px' }}>
          <SearchInput
            autoFocus
            onClick={() => {
              setAnchorEl(null)
            }}
            value={search}
            onChange={e => {
              setSearch(e.target.value.trim())
            }}
          />
        </div>
        {search
          ? items.map(item => renderSimpleItem(item))
          : items.filter(it => it.children?.length).map(item => renderNestedMenuItem(item))}
      </Popover>

      {hoverItem?.children?.length && anchorEl && (
        <Menu
          sx={{
            pointerEvents: 'none',
            '& .MuiPaper-root': {
              minWidth: menuWidth + 'px',
              width: '400px',
            },
          }}
          hideBackdrop
          open={!!anchorEl && !!mainAnchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {hoverItem.children
            .filter(it => !search || it.name.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(item => renderSimpleItem(item))}
        </Menu>
      )}
    </SelectField>
  )
}
