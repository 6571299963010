import { Button, Divider } from '@mui/material'

import Box from '@mui/material/Box'
import { MAIN_MARGIN } from '../Settings'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Theme, useTheme } from '@mui/material/styles'

interface PaginatorProps {
  enablePrev: boolean
  enableNext: boolean
  prevClick: () => void
  nextClick: () => void
  page?: number
}

const Paginator = (props: PaginatorProps) => {
  let theme = useTheme()

  let sxButton = {
    width: '37px',
    minWidth: '37px',
    height: '37px',
  }

  return (
    <Box
      sx={{
        height: getPaginatorPanelHeight(theme),
        paddingLeft: MAIN_MARGIN,
        paddingRight: MAIN_MARGIN,
      }}
    >
      <Divider />
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: theme.spacing(1.25),
        }}
      >
        <Button
          onClick={() => props.prevClick()}
          sx={sxButton}
          disabled={!props.enablePrev}
          size="medium"
          variant={
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            'grey' as any
          }
        >
          <NavigateBeforeIcon />
        </Button>

        <Button
          onClick={() => props.nextClick()}
          sx={sxButton}
          disabled={!props.enableNext}
          size="medium"
          variant={
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            'grey' as any
          }
        >
          <NavigateNextIcon />
        </Button>
      </Box>
    </Box>
  )
}

export function getPaginatorPanelHeight(theme: Theme) {
  return theme.spacing(9.125)
}

export default Paginator
