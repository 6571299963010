import { browserTracingIntegration, feedbackIntegration, replayIntegration, init as sentryInit } from '@sentry/react'
import * as Sentry from '@sentry/react'
import { getReleaseFromBranch } from '@/.build/getReleaseFromBranch'
import i18next from 'i18next'

const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT || 'local'
const SENTRY_TRACES_SAMPLE_RATE =
  import.meta.env.VITE_SENTRY_DASHBOARD_TRACES_SAMPLE_RATE ||
  import.meta.env.VITE_NEXT_PUBLIC_SENTRY_DASHBOARD_TRACES_SAMPLE_RATE ||
  import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ||
  import.meta.env.VITE_NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE ||
  '0.01'
const SENTRY_PROFILES_SAMPLE_RATE =
  import.meta.env.VITE_SENTRY_DASHBOARD_PROFILES_SAMPLE_RATE ||
  import.meta.env.VITE_NEXT_PUBLIC_DASHBOARD_SENTRY_PROFILES_SAMPLE_RATE ||
  import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE ||
  import.meta.env.VITE_NEXT_PUBLIC_SENTRY_PROFILES_SAMPLE_RATE ||
  '0.01'

export function initSentry() {
  sentryInit({
    enabled: import.meta.env.MODE !== 'development',
    environment: SENTRY_ENVIRONMENT,
    // TODO: user value from .env
    dsn: 'https://87afe35e26bafdf79c2351514cd90076@o4506109598695424.ingest.us.sentry.io/4506931340443648',
    release: getReleaseFromBranch(import.meta.env.VITE_RENDER_GIT_BRANCH || 'dev'),
    integrations: [
      browserTracingIntegration(),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracePropagationTargets: [import.meta.env.VITE_HUB_API_URL as string],
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    profilesSampleRate: SENTRY_PROFILES_SAMPLE_RATE,
    replaysOnErrorSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
  })
}

export function initFeedbackIntegration(email: string | undefined, lang = 'en') {
  const feedback = feedbackIntegration({
    lang: lang,
    autoInject: true,
    showEmail: false,
    showName: false,
    showBranding: false,
    addScreenshotButtonLabel: i18next.t('header.feedback.add-screenshot-button-label'),
    formTitle: i18next.t('header.feedback.form-title'),
    messageLabel: i18next.t('header.feedback.message-label'),
    messagePlaceholder: i18next.t('header.feedback.message-placeholder'),
    successMessageText: i18next.t('header.feedback.success-message-text'),
    submitButtonLabel: i18next.t('header.feedback.submit-button-label'),
    cancelButtonLabel: i18next.t('Cancel'),
    confirmButtonLabel: i18next.t('confirm.title'),
    removeScreenshotButtonLabel: i18next.t('header.feedback.remove-screenshot-button-label'),
    colorScheme: 'light',
    isRequiredLabel: false,
  })

  feedback.attachTo(document.querySelector('#bug-report')!, {
    email: email,
  })
}

export function setUserInSentry(id: string, name: string, email: string) {
  Sentry.setUser({
    email: email,
    username: name,
    id: id,
  })
}
