import { ReactNode, useCallback, useLayoutEffect, useRef } from 'react'
import { cn } from '@/libs'

interface FullscreenTopbarProps {
  children: ReactNode
}

export const FullscreenTopbar = ({ children }: FullscreenTopbarProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const onScroll = useCallback(
    (e: Event) => {
      ref.current?.setAttribute('data-sticky', (e.currentTarget as Window).scrollY > 8 ? 'true' : 'false')
    },
    [ref.current],
  )

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [ref])

  return (
    <div
      ref={ref}
      className={cn(
        "sticky top-0 z-20 mb-5 flex h-14 w-full items-center border-b border-b-transparent bg-linear-bg-topbar-gradient backdrop-blur transition-[border,shadow] [&[data-sticky='true']]:border-b-border-primary [&[data-sticky='true']]:shadow-xs",
      )}
    >
      <div className="container flex items-center">{children}</div>
    </div>
  )
}
