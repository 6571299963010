import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { XClose } from '../../icons'
import { ButtonIcon } from '../ButtonIcon'
import { TableCell } from './TableCell'

interface TableCellBulkToolbarProps {
  children: ReactNode
  selected: Set<string> | unknown[]
  onReset: () => void
}

export const TableCellBulkToolbar = ({ children, selected, onReset }: TableCellBulkToolbarProps) => {
  const { t } = useTranslation()
  const size = selected instanceof Set ? selected.size : selected.length
  return (
    <TableCell className="flex select-none items-center gap-3 py-0">
      <div className="flex items-center gap-1 text-xs font-semibold text-text-primary">
        <span>{t('sku.items.count-selected', { count: size })}</span>
        <ButtonIcon variant="secondary-gray" size="xs" onClick={onReset}>
          <XClose size={14} />
        </ButtonIcon>
      </div>
      <div className="flex items-center gap-1.5">{children}</div>
    </TableCell>
  )
}
