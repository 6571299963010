import { AghanimIcon, IconBase } from '../IconBase'
import { forwardRef } from 'react'

export const Logo: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 26 26" ref={ref} size={26}>
      <g filter="url(#filter0_i_4845_17805)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.71429 7.53776C2.77018 9.13927 2.22857 11.0064 2.22857 13C2.22857 14.9936 2.77018 16.8607 3.71429 18.4622V7.53776ZM5.94286 5.94286V16.4512L11.197 5.94286H5.94286ZM13 7.32019L6.63152 20.0571H19.3685L13 7.32019ZM18.4622 22.2857H7.53776C9.13927 23.2298 11.0064 23.7714 13 23.7714C14.9936 23.7714 16.8607 23.2298 18.4622 22.2857ZM22.2857 18.4622C23.2298 16.8607 23.7714 14.9936 23.7714 13C23.7714 11.0064 23.2298 9.13927 22.2857 7.53776V18.4622ZM18.4622 3.71429H7.53776C9.13927 2.77018 11.0064 2.22857 13 2.22857C14.9936 2.22857 16.8607 2.77018 18.4622 3.71429ZM20.0571 5.94286H14.803L20.0571 16.4512V5.94286ZM0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13ZM2.55494 4.13075L1.44389 3.01969C1.00873 2.58454 1.00873 1.87901 1.44389 1.44386C1.87904 1.0087 2.58457 1.0087 3.01972 1.44386L4.13078 2.55491L2.55494 4.13075ZM21.8692 2.55494L22.9803 1.44386C23.4155 1.0087 24.121 1.0087 24.5562 1.44386C24.9913 1.87901 24.9913 2.58454 24.5562 3.01969L23.4451 4.13078L21.8692 2.55494ZM24.5562 24.5561C24.121 24.9913 23.4155 24.9913 22.9803 24.5561L21.8692 23.445L23.4451 21.8692L24.5562 22.9803C24.9913 23.4155 24.9913 24.121 24.5562 24.5561ZM1.44386 24.5561C1.00871 24.121 1.00871 23.4155 1.44387 22.9803L2.55494 21.8692L4.13078 23.4451L3.0197 24.5561C2.58455 24.9913 1.87902 24.9913 1.44386 24.5561Z"
          fill="black"
          fillOpacity="0.1"
        />
      </g>
      <path
        d="M3.96429 7.53776V6.6214L3.49892 7.4108C2.53276 9.04972 1.97857 10.9605 1.97857 13C1.97857 15.0395 2.53276 16.9503 3.49892 18.5892L3.96429 19.3786V18.4622V7.53776ZM5.94286 5.69286H5.69286V5.94286V16.4512V17.5103L6.16646 16.563L11.4207 6.05466L11.6016 5.69286H11.197H5.94286ZM13.2236 7.20839L13 6.76117L12.7764 7.20839L6.40792 19.9453L6.22702 20.3071H6.63152H19.3685H19.773L19.5921 19.9453L13.2236 7.20839ZM18.5892 22.5011L19.3786 22.0357H18.4622H7.53776H6.6214L7.4108 22.5011C9.04972 23.4672 10.9605 24.0214 13 24.0214C15.0395 24.0214 16.9503 23.4672 18.5892 22.5011ZM22.0357 18.4622V19.3786L22.5011 18.5892C23.4672 16.9503 24.0214 15.0395 24.0214 13C24.0214 10.9605 23.4672 9.04972 22.5011 7.4108L22.0357 6.6214V7.53776V18.4622ZM18.4622 3.96429H19.3786L18.5892 3.49892C16.9503 2.53276 15.0395 1.97857 13 1.97857C10.9605 1.97857 9.04972 2.53276 7.4108 3.49892L6.6214 3.96429H7.53776H18.4622ZM20.3071 5.94286V5.69286H20.0571H14.803H14.3984L14.5793 6.05466L19.8335 16.563L20.3071 17.5103V16.4512V5.94286ZM0.25 13C0.25 5.95837 5.95837 0.25 13 0.25C20.0416 0.25 25.75 5.95837 25.75 13C25.75 20.0416 20.0416 25.75 13 25.75C5.95837 25.75 0.25 20.0416 0.25 13ZM2.84295 1.62063L3.77722 2.55491L2.55494 3.77719L1.62066 2.84292C1.28314 2.50539 1.28314 1.95816 1.62066 1.62063C1.95819 1.28311 2.50542 1.28311 2.84295 1.62063ZM23.4451 3.77722L22.2228 2.55494L23.1571 1.62063C23.4946 1.28311 24.0419 1.28311 24.3794 1.62063C24.7169 1.95816 24.7169 2.50539 24.3794 2.84292L23.4451 3.77722ZM24.3794 24.3794C24.0419 24.7169 23.4946 24.7169 23.1571 24.3794L22.2228 23.445L23.4451 22.2227L24.3794 23.1571C24.7169 23.4946 24.7169 24.0418 24.3794 24.3794ZM1.62064 24.3794C1.28312 24.0418 1.28312 23.4946 1.62064 23.1571L2.55494 22.2228L3.77722 23.4451L2.84293 24.3794C2.5054 24.7169 1.95817 24.7169 1.62064 24.3794Z"
        stroke="black"
        strokeOpacity="0.1"
        strokeWidth="0.5"
        strokeMiterlimit="10.4334"
      />
      <defs>
        <filter
          id="filter0_i_4845_17805"
          x="0"
          y="0"
          width="26"
          height="26.3"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.3" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_4845_17805" />
        </filter>
      </defs>
    </IconBase>
  )
})
