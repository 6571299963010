import { cn } from '../../../libs/cn'
import { LegendItem, LegendItemType } from './LegendItem'
import { CSSProperties } from 'react'

interface LegendItemProps {
  name: string
  classColor?: string
  type: LegendItemType
  style?: CSSProperties
}

interface LegendProps {
  items: (LegendItemProps | undefined)[]
  className?: string
}

export const Legend = (props: LegendProps) => {
  return (
    <div className={cn('flex justify-start gap-3 text-[12px] font-medium text-text-secondary', props.className)}>
      {props.items.map(
        it =>
          it && (
            <div className="flex items-center gap-1.5" key={it.name}>
              <LegendItem classColor={it.classColor} type={it.type} style={it.style} />
              <div>{it.name}</div>
            </div>
          ),
      )}
    </div>
  )
}
