import { queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { AccountRead, VerifyState } from '@/api/dashboard'

export const accountDetailsQuery = (companyId: string, isAdm = true) => {
  return queryOptions({
    queryKey: [companyId, 'account-details'],
    queryFn: () => {
      if (!isAdm) {
        return Promise.resolve({
          details: {
            company_legal: undefined,
            authorized_signatory: undefined,
            business_owners: [],
            have_no_ubo: false,
          },
          verify_state: VerifyState.Unverified,
        } satisfies AccountRead)
      }

      return dashboardClient.v1
        .getAccountDetails(companyId)
        .then(r => r.data)
        .catch(() => {
          return {
            details: {
              company_legal: undefined,
              authorized_signatory: undefined,
              business_owners: [],
              have_no_ubo: false,
            },
            verify_state: VerifyState.Unverified,
          } satisfies AccountRead
        })
    },
    retry: false,
  })
}
