import './spinnerAghanim.css'

export const SpinnerAghanim = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 32 32" fill="none">
      <path
        id="circle"
        d="M20.3769,25.2048C19.0418,25.852,17.545,26.2143,15.9618,26.2143C14.3786,26.2143,12.8818,25.852,11.5467,25.2048L8.29993,25.2048C10.3844,26.9508,13.0694,28.001,16.0005,28.001C18.9316,28.001,21.6176,26.9508,23.7011,25.2048L20.3769,25.2048ZM25.0877,8.16294L25.0877,11.6628C25.7349,12.9979,26.0972,14.4947,26.0972,16.0779C26.0972,17.6611,25.7349,19.1579,25.0877,20.493L25.0877,23.838C26.9032,21.7357,28,18.9962,28,16.0005C28,13.0048,26.9022,10.2653,25.0877,8.16294ZM16.0005,4C13.0694,4,10.3834,5.05017,8.29993,6.79615L11.8822,6.79615C13.1309,6.24625,14.5106,5.94153,15.9618,5.94153C17.413,5.94153,18.7937,6.24625,20.0414,6.79615L23.7011,6.79615C21.6166,5.05017,18.9316,4,16.0005,4ZM6.68001,8.44088C5.00451,10.5045,4,13.1349,4,16.0005C4,18.8661,5.00352,21.4965,6.68001,23.5601L6.68001,20.1585C6.13011,18.9098,5.82539,17.5301,5.82539,16.0789C5.82539,14.6277,6.13011,13.247,6.68001,11.9993L6.68001,8.44187L6.68001,8.44088Z"
        fill="currentColor"
        transform="translate(16,16.0005) translate(-16,-16.0005)"
      />
      <path
        id="square"
        d="M23.6514,7.4563L17.0159,7.4563L17.9509,9.3204L22.4474,9.3204C22.5119,9.3204,22.5635,9.37301,22.5635,9.43654L22.5635,18.5148L24.4276,22.2301L24.4276,8.23351C24.4276,7.80669,24.0782,7.4563,23.6504,7.4563L23.6514,7.4563ZM8.11729,7.4563C7.69048,7.4563,7.34009,7.8057,7.34009,8.23351L7.34009,22.2966L9.20418,18.5774L9.20418,9.43753C9.20418,9.37301,9.25679,9.32139,9.32032,9.32139L13.8426,9.32139L14.7766,7.45729L8.11531,7.45729L8.11729,7.4563Z"
        fill="currentColor"
        transform="translate(15.8838,14.8764) translate(-15.8838,-14.8764)"
      />
      <path
        id="triangle"
        d="M24.3988,23.703L16.4035,7.76682C16.1901,7.33603,15.5757,7.33505,15.3603,7.76485L7.37284,23.701C7.17928,24.0881,7.46118,24.5437,7.89395,24.5437L23.8767,24.5437C24.3085,24.5437,24.5904,24.0901,24.3988,23.703ZM21.3019,22.7054L10.4251,22.7054C10.1303,22.7054,9.92579,22.7491,10.0578,22.486L15.7275,11.0682C15.8734,10.7734,15.8784,10.7476,16.0382,11.0682L21.6563,22.4831C21.7873,22.7461,21.5958,22.7064,21.301,22.7064L21.3019,22.7054Z"
        fill="currentColor"
        transform="translate(15.8853,15.9934) translate(-15.8853,-15.9934)"
      />
      <path
        id="x"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.85748,6.27516L6.60253,5.02022L6.60183,5.02092C6.2551,4.67419,5.6922,4.6742,5.34547,5.02092L4.93277,5.43362C4.58605,5.78035,4.58605,6.34325,4.93277,6.68997L6.18772,7.94492L7.85748,6.27516ZM4.95294,25.4216L6.20788,24.1666L7.87763,25.8364L6.62268,27.0913C6.27596,27.4381,5.71306,27.4381,5.36633,27.0913L4.95363,26.6786C4.60691,26.3319,4.60691,25.769,4.95364,25.4223L4.95294,25.4216ZM24.056,25.8595L25.3109,27.1144L25.3116,27.1137C25.6583,27.4605,26.2212,27.4605,26.568,27.1137L26.9807,26.701C27.3274,26.3543,27.3274,25.7914,26.9807,25.4447L25.7257,24.1897L24.056,25.8595ZM25.7459,7.96753L27.0008,6.71259L27.0001,6.71188C27.3469,6.36516,27.3469,5.80226,27.0001,5.45554L26.5874,5.04283C26.2407,4.69611,25.6778,4.69612,25.3311,5.04284L24.0761,6.29779L25.7459,7.96753Z"
        fill="currentColor"
        transform="translate(15.9665,16.0673) translate(-15.9665,-16.0673)"
        style={{ animation: '1.2s linear infinite both spinnerAghanimKeyframes' }}
      />
    </svg>
  )
}
