import { ReactNode } from 'react'
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion'
import { getMotionProps } from '../../../libs'
import { cn } from '../../../libs/cn'
import { SpinnerAghanim } from '../../ui'
import { useBanner } from '@/libs/hooks/useBanner'

interface FullscreenLayoutProps extends HTMLMotionProps<'div'> {
  children?: ReactNode
  isLoading?: boolean
}

export const fullscreenLayoutMotionProps = getMotionProps({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})

export const spinnerMotionProps = getMotionProps({
  initial: { y: 4, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { delay: 0.1 } },
  exit: { y: -4, opacity: 0 },
})

export const entryMotionProps = getMotionProps({
  initial: { y: 4, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: -4, opacity: 0 },
})

export const FullscreenLayout = ({ children, className, isLoading, ...rest }: FullscreenLayoutProps) => {
  const { getStickyTop } = useBanner()

  return (
    <motion.div
      {...fullscreenLayoutMotionProps}
      {...rest}
      className={cn('absolute left-0 z-50 w-full bg-white', className, getStickyTop())}
    >
      <AnimatePresence mode="popLayout">
        {isLoading ? (
          <motion.div
            {...spinnerMotionProps}
            className="flex h-screen w-full items-center justify-center"
            key="spinner"
          >
            <SpinnerAghanim />
          </motion.div>
        ) : (
          <motion.div {...entryMotionProps} key="entry">
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}
