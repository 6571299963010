import { cn } from '@/libs'

interface LangKnobProps {
  locale: string
  target?: boolean
}

export const LocaleKnob = ({ locale, target }: LangKnobProps) => (
  <div
    className={cn(
      'flex h-[18px] w-6 shrink-0 items-center justify-center rounded bg-text-quarterary text-center text-[12px] font-semibold uppercase text-text-inverse',
      target && 'bg-text-quarterary-hover',
    )}
  >
    {locale}
  </div>
)
