import Dialog from '@mui/material/Dialog'

export default function Modal(props: { children: React.ReactNode; width?: string; onClose: () => void }) {
  return (
    <Dialog
      onClose={props.onClose}
      open={true}
      sx={{ '& .MuiDialog-paper': { width: props.width || '572px', padding: '40px', borderRadius: '24px', margin: 0 } }}
    >
      {props.children}
    </Dialog>
  )
}
