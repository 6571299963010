import './styles.css'

export default function AnimatedDots(props: { style?: React.CSSProperties }) {
  return (
    <span className="dots" style={props.style}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  )
}
