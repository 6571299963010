import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const BulletList: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path d="M4.5 5.5H6.5L7 6V8L6.5 8.5H4.5L4 8V6L4.5 5.5Z" fill="currentColor" />
      <path d="M6.5 10.5H4.5L4 11V13L4.5 13.5H6.5L7 13V11L6.5 10.5Z" fill="currentColor" />
      <path d="M6.5 15.5H4.5L4 16V18L4.5 18.5H6.5L7 18V16L6.5 15.5Z" fill="currentColor" />
      <path d="M21 6H8.5V8H21V6Z" fill="currentColor" />
      <path d="M21 11H8.5V13H21V11Z" fill="currentColor" />
      <path d="M8.5 16H21V18H8.5V16Z" fill="currentColor" />
    </IconBase>
  )
})
