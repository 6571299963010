import i18next from 'i18next'
import { AttrType, CustomAttribute } from '../../api/dashboard'
import {
  Button,
  FieldGroup,
  FieldValidationMessage,
  Input,
  Modal,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
} from '../../components/ui'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

interface EditGameAttrDialogProps extends ModalProps {
  item: CustomAttribute
  isNew: boolean
  onSave: (item: CustomAttribute) => void
}

export default function EditGameAttrModal(props: EditGameAttrDialogProps) {
  const { ...rest } = props

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CustomAttribute>({
    mode: 'onChange',
    values: { ...props.item },
  })

  const onSaveClick = (item: CustomAttribute) => {
    props.onSave(item)
    props.onClose?.()
  }

  return (
    <Modal {...rest}>
      <ModalTitle>{i18next.t(props.isNew ? 'player.attr-table.add-attr' : 'player.attr-table.edit-attr')}</ModalTitle>

      <div className="mt-10">
        <FieldGroup label={i18next.t('player.attr-table.name')}>
          <Input
            {...register('name', { required: i18next.t('validation.required') })}
            autoFocus
            disabled={!props.isNew}
          />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('player.attr-table.type')}>
          <Controller
            control={control}
            name="type"
            render={({ field: { value, onChange } }) => (
              <Select
                onChange={v => {
                  onChange(v)
                }}
                placeholder={i18next.t('segments.select-group')}
                value={value as AttrType}
                options={Object.values(AttrType)
                  .filter(it => it != AttrType.List)
                  .map(it => ({ value: it, children: it }))}
              />
            )}
          />
          <ErrorMessage
            name="email"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>
      </div>

      <ModalFooter>
        <Button size="lg" onClick={props.onClose}>
          {i18next.t('Cancel')}
        </Button>
        <Button size="lg" variant="primary" onClick={handleSubmit(onSaveClick)}>
          {i18next.t('Save2')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
