import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from './index'
import { GameSettingsUpdate } from './dashboard'

interface GameSettingsCreateMutate {
  data: GameSettingsUpdate
}

/**
 *
 * @param companyId
 * @param gameId
 */
export const useGameSettingsCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ data }: GameSettingsCreateMutate) =>
      dashboardClient.v1.createGameSettings(companyId, gameId, data).then(r => r.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['game-settings', gameId] })
    },
  })
}
