import i18next from 'i18next'
import { cn } from '@/libs'

export default function AddStoreItem(props: { onClick: () => void; className: string }) {
  return (
    <div
      style={{
        width: '201px',
        height: '310px',
      }}
      className={cn(
        'cursor-pointer rounded-md border-2 border-dashed border-border-primary p-2 hover:shadow-2xl',
        props.className,
      )}
      data-testid="sku/store/add-item"
      onClick={props.onClick}
    >
      <div className="flex size-full items-center justify-center rounded-md bg-border-primary">
        <span className="text-[18px] font-semibold text-text-primary opacity-50">{i18next.t('store.add-item')}</span>
      </div>
    </div>
  )
}
