import { useSearchParams } from 'react-router-dom'
import { useCallback } from 'react'

export interface LocaleSearchFilter {
  q?: string
  type?: string
  state?: string
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
const removeEmpty = (obj: any) => {
  let newObj = {}
  Object.keys(obj).forEach(key => {
    if (obj[key] === Object(obj[key])) {
      // @ts-ignore
      newObj[key] = removeEmpty(obj[key])
    } else if (obj[key] !== undefined && obj[key] !== null) {
      // @ts-ignore
      newObj[key] = obj[key]
    }
  })
  return newObj
}

export const useLocaleSearchFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const filter: LocaleSearchFilter = {
    q: searchParams.get('q') || undefined,
    type: searchParams.get('type') || undefined,
    state: searchParams.get('state') || undefined,
  }

  const onChange = useCallback(
    (filter: LocaleSearchFilter) => {
      setSearchParams(removeEmpty(filter))
    },
    [setSearchParams],
  )

  return {
    filter,
    onChange,
  }
}
