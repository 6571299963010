import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

export const useStoreQuery = (companyId: string, gameId: string, id: string) => {
  return useQuery({
    queryKey: ['store', id],
    select: response => response.data,
    queryFn: () => dashboardClient.v1.getStore(companyId, gameId, id),
  })
}
