import { ObjectType } from '@/api/dashboard'
import { AghanimIcon, ItemSolid } from '@/icons'

export const mapTypeIcon = new Map<string | undefined, AghanimIcon>([
  [undefined, ItemSolid],
  [ObjectType.Achievement, ItemSolid],
  [ObjectType.News, ItemSolid],
  [ObjectType.Item, ItemSolid],
  [ObjectType.Leaderboard, ItemSolid],
  [ObjectType.Page, ItemSolid],
  [ObjectType.Store, ItemSolid],
  [ObjectType.ItemCategory, ItemSolid],
  [ObjectType.GameSettings, ItemSolid],
  [ObjectType.ItemProperty, ItemSolid],
  [ObjectType.StoreItem, ItemSolid],
])
