import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { Page } from '@/ui'
import colorLights from '../assets/color-lights.webp'
import { GetStartedStepper } from '../widgets'

export const GetStartedPage = () => {
  const { t } = useTranslation()
  const { user } = useAuth0()

  return (
    <Page>
      <div
        className="pointer-events-none absolute -inset-x-0 top-[-60px] z-0 h-screen bg-right-top bg-no-repeat"
        style={{ backgroundImage: `url(${colorLights})` }}
      />

      <div className="mx-auto w-full max-w-[926px] py-12">
        <div className="mb-12">
          <h1 className="mb-3 text-title-t1">
            {user?.given_name ? t('get-started.title', { name: user?.given_name }) : t('get-started.title-empty')}
          </h1>
          <p className="text-paragraph-lg text-text-secondary">{t('get-started.text')}</p>
        </div>

        <GetStartedStepper />
      </div>
    </Page>
  )
}
