import './ai-desc-styles.css'
import SSE from './sse'
import { HUB_API, URL_HUB_API } from '../HubAPI'
import { useRef, useState } from 'react'
import { AI_DONE } from '@/Settings'
import Box from '@mui/material/Box'
import AnimatedDots from '../components/animated-dots/AnimatedDots'
import i18next from 'i18next'
import { Textarea } from '@/components/ui/Textarea'
import { FieldGroup, Floating, Input, Menu, MenuOption } from '@/ui'
import { AIIcon } from './AIIcon'
import { Send03 } from '@/icons'

interface CMenuProps {
  onClose?: () => void
  onGenerateTextClick: () => void
  onInputPromptClick: () => void
}

const CMenu = (props: CMenuProps) => {
  return (
    <Menu style={{ maxWidth: 'none', width: '200px' }}>
      <MenuOption
        onClick={() => {
          props.onClose?.()
          props.onGenerateTextClick()
        }}
      >
        {i18next.t('ai.generate-text')}
      </MenuOption>
      <MenuOption
        onClick={() => {
          props.onClose?.()
          props.onInputPromptClick()
        }}
      >
        {i18next.t('ai.input-prompt')}
      </MenuOption>
    </Menu>
  )
}

export default function AIDescriptionEditor(props: {
  prompt: string
  context: string
  onDone: (text: string) => void
  label: string
  value: string
  maxLength?: number
  onChange: (v: string) => void
  refEvent?: {
    startGenerate?: () => void | null
  }
}) {
  const [currentSSE, setCurrentSSE] = useState<SSE | null>(null)
  const [currentStreamMessage, setCurrentStreamMessage] = useState<string>('')
  const [customPromptVis, setCustomPromptVis] = useState(false)

  const aiPromtBoxRef = useRef<HTMLDivElement | null>(null)
  const [customPrompt, setCustomPrompt] = useState('')

  const onSSEMessage = (e: { data: string }) => {
    //here context is lost

    let messagePart = e.data
    if (messagePart) {
      if (messagePart != AI_DONE) {
        setCurrentStreamMessage(prev => prev + messagePart)
      } else {
        stopAnswer()
      }
    }
  }

  const stopAnswer = () => {
    //here context can be lost

    setTimeout(() => {
      setCurrentStreamMessage(prev => {
        props.onDone(prev)
        return ''
      })
    }, 100) //sse can send events sync

    setCurrentSSE(prev => {
      if (prev) {
        prev.close()
      }
      return null
    })
  }

  const onStandartGenerateTextClick = () => {
    if (aiPromtBoxRef.current) {
      aiPromtBoxRef.current.className = 'ai-message-input--hide'
    }

    setTimeout(() => setCustomPromptVis(false), 150)
    onGenerateTextClick(props.prompt)
  }

  if (props.refEvent) {
    props.refEvent.startGenerate = onStandartGenerateTextClick
  }

  const onGenerateTextClick = (prompt: string) => {
    if (currentSSE || !props.context || !prompt) {
      return
    }

    setCurrentStreamMessage('')

    try {
      const sse = new SSE(`${URL_HUB_API}/dashboard/v1/ai/get_completion`, {
        withCredentials: true,
        debug: true,
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: 'Bearer ' + HUB_API.getToken(),
        },
        method: 'POST',
        payload: JSON.stringify({
          prompt: prompt,
          context: props.context,
        }),
      })

      setCurrentSSE(sse)

      sse.addEventListener('message', (e: { data: string }) => onSSEMessage(e))
      sse.addEventListener('error', () => {
        onSSEMessage({ data: "I can't answer" })
      })
    } catch (e) {
      onSSEMessage({ data: "I can't answer" })
    }
  }

  return (
    <>
      {customPromptVis && (
        <Box display="flex" position="relative" className="ai-message-input--show" ref={aiPromtBoxRef}>
          <Input
            placeholder={i18next.t('ai.ask-desc')}
            value={customPrompt}
            onChange={e => {
              setCustomPrompt(e.target.value)
            }}
            extraRight={
              <div
                className="flex h-full cursor-pointer items-center justify-center"
                style={{ zIndex: 1 }}
                onClick={() => onGenerateTextClick(customPrompt)}
              >
                <Send03 />
              </div>
            }
          />
        </Box>
      )}

      <div className="relative">
        {currentSSE ? (
          <div className="absolute right-0 top-0">
            <AnimatedDots />
          </div>
        ) : (
          props.prompt && (
            <Floating
              menu={
                <CMenu
                  onInputPromptClick={() => setCustomPromptVis(true)}
                  onGenerateTextClick={onStandartGenerateTextClick}
                />
              }
              referenceWidth={true}
            >
              <div className="absolute right-0 top-0 flex cursor-pointer items-center gap-1 text-sm text-text-brand-primary">
                <AIIcon />
                {i18next.t('ask.newton')}
              </div>
            </Floating>
          )
        )}

        <FieldGroup label={props.label}>
          <Textarea
            value={currentStreamMessage || props.value}
            onChange={e => {
              let v = e.target.value
              if (props.maxLength) {
                if (v.length > props.maxLength) {
                  v = v.substring(0, props.maxLength)
                }
              }
              props.onChange(v)
            }}
          />
        </FieldGroup>
      </div>
    </>
  )
}
