import { AghanimIcon, IconBase } from '../../../components/icons/IconBase'
import { forwardRef } from 'react'

export const SettingsIcon: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 16 16" ref={ref}>
      <path
        d="M6.2632 12.9141L6.65283 13.7904C6.76865 14.0513 6.95768 14.2729 7.19698 14.4284C7.43628 14.584 7.71557 14.6668 8.00098 14.6667C8.28639 14.6668 8.56568 14.584 8.80497 14.4284C9.04427 14.2729 9.2333 14.0513 9.34912 13.7904L9.73875 12.9141C9.87745 12.6032 10.1108 12.3439 10.4054 12.1734C10.7019 12.0023 11.045 11.9295 11.3854 11.9652L12.3388 12.0667C12.6225 12.0967 12.9089 12.0438 13.1632 11.9143C13.4175 11.7848 13.6287 11.5843 13.7713 11.3371C13.9141 11.0901 13.9822 10.8069 13.9672 10.522C13.9522 10.237 13.8548 9.96258 13.6869 9.73189L13.1225 8.95634C12.9215 8.67813 12.8141 8.34325 12.8158 8.00004C12.8157 7.65777 12.9241 7.32427 13.1254 7.04745L13.6899 6.27189C13.8578 6.04121 13.9551 5.76675 13.9701 5.48182C13.9851 5.19689 13.9171 4.91373 13.7743 4.66671C13.6317 4.41953 13.4205 4.21903 13.1662 4.08953C12.9119 3.96002 12.6255 3.90706 12.3417 3.93708L11.3884 4.03856C11.0479 4.07431 10.7049 4.00145 10.4084 3.83041C10.1131 3.65888 9.87977 3.39828 9.74172 3.08597L9.34912 2.20967C9.2333 1.94882 9.04427 1.72718 8.80497 1.57164C8.56568 1.4161 8.28639 1.33333 8.00098 1.33337C7.71557 1.33333 7.43628 1.4161 7.19698 1.57164C6.95768 1.72718 6.76865 1.94882 6.65283 2.20967L6.2632 3.08597C6.12515 3.39828 5.89178 3.65888 5.59653 3.83041C5.30001 4.00145 4.95698 4.07431 4.61653 4.03856L3.66024 3.93708C3.37646 3.90706 3.09006 3.96002 2.83578 4.08953C2.5815 4.21903 2.37025 4.41953 2.22764 4.66671C2.08486 4.91373 2.01683 5.19689 2.03182 5.48182C2.04681 5.76675 2.14417 6.04121 2.31209 6.27189L2.87653 7.04745C3.07783 7.32427 3.18622 7.65777 3.18616 8.00004C3.18622 8.34232 3.07783 8.67581 2.87653 8.95263L2.31209 9.72819C2.14417 9.95887 2.04681 10.2333 2.03182 10.5183C2.01683 10.8032 2.08486 11.0863 2.22764 11.3334C2.37039 11.5804 2.58166 11.7808 2.83591 11.9103C3.09015 12.0398 3.37648 12.0928 3.66024 12.063L4.61357 11.9615C4.95402 11.9258 5.29705 11.9986 5.59357 12.1697C5.88992 12.3407 6.12438 12.6014 6.2632 12.9141Z"
        stroke="#0097E4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99979 10C9.10435 10 9.99979 9.10461 9.99979 8.00004C9.99979 6.89547 9.10435 6.00004 7.99979 6.00004C6.89522 6.00004 5.99979 6.89547 5.99979 8.00004C5.99979 9.10461 6.89522 10 7.99979 10Z"
        stroke="#0097E4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  )
})
