import { CSSProperties } from 'react'

export function BranchLeft(props: { style?: CSSProperties; animate?: boolean }) {
  return (
    <svg width="184" height="191" viewBox="0 0 186 191" fill="none" style={props.style}>
      <path
        style={
          props.animate
            ? {
                animation: 'animate_svg_branch 5s linear infinite',
                strokeDasharray: 5,
                strokeWidth: 2,
              }
            : { strokeWidth: 2 }
        }
        d="M185 1V84.4136C185 93.2502 177.837 100.414 169 100.414H96H23C14.1634 100.414 7 107.577 7 116.414V189s"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M1 180L7.20225 185.969L13.4045 180"
        style={{ strokeWidth: 2 }}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
