import { DataGranularity } from '@/api/dashboard'

export function getDateFormatParams(datetime_at: number | string, dateStyle: 'long' | 'short' = 'short') {
  return {
    val: typeof datetime_at == 'string' ? new Date(datetime_at) : new Date(datetime_at * 1000),
    formatParams: { val: { dateStyle: dateStyle } },
  }
}

export function getDateTimeFormatParams(datetime_at: string | number, variant: 'medium' | 'short' = 'medium') {
  return {
    val: typeof datetime_at == 'string' ? new Date(datetime_at) : new Date(datetime_at * 1000),
    formatParams: { val: { timeStyle: variant, dateStyle: variant } },
  }
}

export function getFormatYearMonthParams(datetime_at: number) {
  return {
    val: new Date(datetime_at * 1000),
    formatParams: {
      val: { month: 'short', year: 'numeric' },
    },
  }
}

export function getDateCategoryParams(date: string | Date, granularity: DataGranularity = DataGranularity.Day) {
  if (typeof date == 'string') {
    date = new Date(date)
  }

  if (granularity == DataGranularity.Hour) {
    return {
      val: date,
      formatParams: {
        val: { hour: '2-digit', minute: '2-digit' },
      },
    }
  }

  return {
    val: date,
    formatParams: {
      val: { month: 'short', day: 'numeric' },
    },
  }
}
