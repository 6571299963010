import { Box } from '@mui/material'
import AnimatedDots from '../components/animated-dots/AnimatedDots'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

export const Logout = () => {
  const { logout } = useAuth0()

  useEffect(() => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }, [])

  return (
    <Box
      sx={{
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
      }}
    >
      Logout <AnimatedDots />
    </Box>
  )
}
