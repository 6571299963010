import { useEffect } from 'react'
import { useModal } from '@/ui'
import { L10nTranslationModalRush } from '../widgets'

export const L10nTranslationPage = () => {
  const openModal = useModal(props => <L10nTranslationModalRush {...props} />, { mode: 'rush' })

  useEffect(() => {
    setTimeout(() => {
      openModal()
    })
  }, [])

  return null
}
