import { ItemOfferActionNode } from '../../../../api/dashboard'
import i18next from 'i18next'
import { DescContext } from './types'
import { Badge } from '../../../../components/ui'
import { getContextItemName } from '@/layouts/campaigns/blocks/descriptions/getContextItemName'

export function getDescItemOfferActionNode(n: ItemOfferActionNode, context: DescContext) {
  let item = context.items.find(it => it.id == n.item_id)
  if (item) {
    return [
      i18next.t('campaign.node-type.ItemOfferActionNode'),
      <>
        {item.name} <Badge variant="gray-secondary">{item.sku}</Badge> {n.discount_percent || n.bonus_percent}%
      </>,
    ]
  }

  if (n.use_event_item) {
    return [n.title || i18next.t('campaign.node-type.ItemOfferActionNode'), getContextItemName(context.type)]
  }

  return [n.title || i18next.t('campaign.node-type.ItemOfferActionNode'), '']
}
