import { queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { DataGranularity, FilterType, WebhookEventType, WebhookLogStatus } from '@/api/dashboard'

export const webhookLogQuery = (
  companyId: string,
  gameId: string,
  webhookId: string,
  query?: {
    limit?: number
    offset?: number
    status?: WebhookLogStatus
    last_count?: number
    granularity?: DataGranularity
    type?: FilterType
    search_string?: string
    event_type?: WebhookEventType
  },
) =>
  queryOptions({
    queryKey: [gameId, 'webhooks/log', webhookId, query],
    queryFn: () => dashboardClient.v1.getWebhookLog(companyId, gameId, webhookId, query).then(r => r.data),
  })
