import { Theme, useTheme } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

import { SxProps } from '@mui/system/styleFunctionSx'

export default function EdgeLabel(props: { sx?: SxProps<Theme>; labelX?: number; labelY?: number; value: string }) {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={theme.spacing(1.5)}
      sx={{
        position: 'absolute',
        transform:
          props.labelX && props.labelY ? `translate(-50%, -50%) translate(${props.labelX}px,${props.labelY}px)` : '',
        background: theme.palette.grey['250'],
        border: `1px solid ${theme.palette.grey.border}`,
        padding: theme.spacing(0.75, 1.5),
        borderRadius: theme.spacing(1),
        ...props.sx,
      }}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="nodrag nopan"
    >
      <Typography variant="subtitle1" fontWeight="500" color={theme.palette.text.table}>
        {props.value}
      </Typography>
    </Box>
  )
}
