import {
  Attribute,
  CAMPAIGN_ATTRIBUTES,
  CalcPlayerAttributes,
  GameHubVisitStoreAttributes,
  PLAYER_ATTRIBUTES,
} from './types'

export function findAttr(attr: string) {
  return (
    PLAYER_ATTRIBUTES[attr] ||
    Object.values(CAMPAIGN_ATTRIBUTES)
      .flat()
      .find(it => it.id == attr)
  )
}

export function findAttrByName(attr: string) {
  if (attr == 'purchased_item_ids') {
    return PLAYER_ATTRIBUTES[CalcPlayerAttributes.has_purchase]
  }

  let arr = Object.values(PLAYER_ATTRIBUTES)
  return [...arr, ...Object.values(CAMPAIGN_ATTRIBUTES).flat()].find(it => it.name == attr)
}

export function findAttrById(id: string): Attribute | undefined {
  if (id == 'purchased_item_ids') {
    return PLAYER_ATTRIBUTES[CalcPlayerAttributes.has_purchase]
  }

  if (PLAYER_ATTRIBUTES[id]) {
    return PLAYER_ATTRIBUTES[id]
  }

  let arr = Object.values(PLAYER_ATTRIBUTES)
  return [...arr, ...Object.values(CAMPAIGN_ATTRIBUTES).flat(), ...GameHubVisitStoreAttributes].find(it => it.id == id)
}
