import { FunctionComponent, ReactElement, useCallback, useId, useMemo } from 'react'
import { modalStore } from './modalStore'
import { ModalParams, ModalProps } from './types'

const defaultParams: ModalParams = {
  mode: 'default',
}

/**
 * This hook is used to show modal windows.
 * @param component
 * @param _params
 */
export const useModal = <T = ModalProps>(
  component: FunctionComponent<T>,
  _params: Partial<ModalParams> = defaultParams,
) => {
  const id = useId()
  const { push, shift } = modalStore()

  const params = useMemo(() => ({ ...defaultParams, ..._params }), [_params])

  const closeModal = useCallback(() => {
    if (params.mode === 'rush') {
      const outlet = document.querySelector('[data-id="main-outlet-root"]')
      setTimeout(() => {
        outlet?.setAttribute('data-modal-rush', 'false')
      }, 50)
    }
    shift(id)
  }, [id, shift, params])

  return useCallback(
    (props: T = {} as T) => {
      if (params.mode === 'rush') {
        const outlet = document.querySelector('[data-id="main-outlet-root"]')
        outlet?.setAttribute('data-modal-rush', 'true')
      }
      push(id, component({ ...props, onClose: closeModal }) as ReactElement, params)
    },
    [push, id, component, params, closeModal],
  )
}
