import { Box, styled } from '@mui/material'

import { useTheme } from '@mui/material/styles'

interface Item {
  icon: React.ReactNode
  value: string | number
}

interface IProps {
  items: Item[]
  value: Item['value']
  onSelected: (value: Item['value']) => void
  itemWidth?: number
}

const ITEM_SIZE = 3.625

const SelectionBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(0.25),
  height: theme.spacing(ITEM_SIZE),
  backgroundColor: theme.palette.background.content,
  transition: 'left 0.2s ease-in-out',
  position: 'absolute',
}))

export default function ButtonIconGroup({ value, items, onSelected, itemWidth }: IProps) {
  const theme = useTheme()
  let width = itemWidth || ITEM_SIZE

  let left = 0
  let idx = items.findIndex(it => it.value == value)
  if (idx > -1) {
    left = width * idx + 0.25 + idx * 0.25
  } //item_size + padding-left + gap

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        padding: theme.spacing(0.25),
        gap: theme.spacing(0.25),
        borderRadius: theme.spacing(0.25),
        backgroundColor: theme.palette.grey['250'],
      }}
    >
      {left > 0 && <SelectionBox sx={{ left: theme.spacing(left), width: theme.spacing(width) }} />}
      {items.map((it, index) => {
        return (
          <Box
            key={index}
            sx={{
              textAlign: 'center',
              zIndex: 1,
              cursor: 'pointer',
              padding: theme.spacing(0.5),
              boxSizing: 'border-box',
              borderRadius: theme.spacing(0.25),
              height: theme.spacing(ITEM_SIZE),
              width: theme.spacing(width),
              color: value == it.value ? theme.palette.primary.main : theme.palette.info.dark,
              //backgroundColor: value == it.value ? theme.palette.background.content : 'transparent',
              '&:hover': {
                backgroundColor: theme.palette.background.content,
              },
            }}
            onClick={() => onSelected(it.value)}
          >
            {it.icon}
          </Box>
        )
      })}
    </Box>
  )
}
