import { useEffect, useRef } from 'react'
import { useInView } from 'framer-motion'

interface PaginationInfiniteObserverProps {
  onNextPage: () => void
}

export const PaginationInfiniteObserver = ({ onNextPage }: PaginationInfiniteObserverProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const inView = useInView(ref, { margin: '256px' })

  useEffect(() => {
    if (inView) {
      onNextPage()
    }
  }, [inView, onNextPage])

  return <div ref={ref} />
}
