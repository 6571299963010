import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { dashboardClient } from '@/api'
import { KeyValue } from '@/types'

export async function initI18(lang = 'en'): Promise<void> {
  return new Promise((resolve, reject) => {
    i18n
      .use(initReactI18next)
      .use(Backend)
      .init(
        {
          react: {
            useSuspense: false,
          },
          fallbackLng: lang,
          interpolation: {
            escapeValue: false,
          },
          debug: true,
          ns: ['translation', 'transaction-error-codes', 'countries'],
          backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
          },
        },
        err => {
          if (err) {
            reject()
          } else {
            console.log('lang loaded')
            resolve()
          }
        },
      )
  })
}

export async function loadProfileInitLang() {
  let { data: customer } = await dashboardClient.v1.getMyProfile()
  if (customer.locale) {
    await initI18(customer.locale)
    document.querySelector('html')?.setAttribute('lang', customer.locale)
  } else {
    await initI18()
  }
}

export function setLastLang(lang: string) {
  localStorage.setItem('lastLang', lang)
}

export function getLastLang() {
  return localStorage.getItem('lastLang')
}

export function getCountries(): KeyValue {
  return (i18n.getResourceBundle(i18n.language, 'countries') || {}) as KeyValue
}
