import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { AchievementCreate } from '../../../api/dashboard'

interface AchievementCreateMutate {
  create: AchievementCreate
}

export const useAchievementCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ create }: AchievementCreateMutate) =>
      dashboardClient.v1.createAchievement(companyId, gameId, create),
    onSuccess: data => {
      queryClient.setQueryData(['achievements', gameId], data.data)
      queryClient.invalidateQueries({ queryKey: ['achievements', gameId] })
    },
  })
}
