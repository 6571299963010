/**
 * This function calculates the percentage of a number.
 * @param current
 * @param total
 */
export const getPercentFrom = (current: number, total: number): number => {
  if (current === 0 && total === 0) {
    return 100
  }
  return Math.round((current / total) * 100)
}
