import SSE from '@/ai/sse'
import { HUB_API, URL_HUB_API } from '@/HubAPI'

interface Event {
  data: string
}

export interface SSETranslationEventPart {
  type: 'part'
  payload: string
}

export interface SSETranslationEventDone {
  type: 'done'
}

export interface SSETranslationEventError {
  type: 'error'
}

export type SSETranslationCallback = (
  e: SSETranslationEventPart | SSETranslationEventDone | SSETranslationEventError,
) => void

interface SSETranslationParams {
  locale: string
  content: string
  callback: SSETranslationCallback
}

const EVENT_DATA_DONE = '[done]'

const EVENT_DATA_ERROR = 'ERROR'

export const fetchSSETranslation = ({ locale, content, callback }: SSETranslationParams) => {
  const sse = new SSE(`${URL_HUB_API}/dashboard/v1/ai/get_translation`, {
    withCredentials: true,
    debug: true,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + HUB_API.getToken(),
    },
    method: 'POST',
    payload: JSON.stringify({
      from_locale: 'en',
      to_locale: locale,
      original_text: content,
    }),
  })

  sse.addEventListener('message', (e: Event) => {
    switch (e.data) {
      case EVENT_DATA_DONE:
        callback({ type: 'done' } satisfies SSETranslationEventDone)
        sse.close()
        break
      case EVENT_DATA_ERROR:
        callback({ type: 'error' } satisfies SSETranslationEventError)
        sse.close()
        break
      default:
        callback({ type: 'part', payload: e.data } satisfies SSETranslationEventPart)
        break
    }
  })

  sse.addEventListener('error', () => {
    callback({ type: 'error' } satisfies SSETranslationEventError)
    sse.close()
  })
}
