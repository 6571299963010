import { ReactNode } from 'react'

interface DrawerFooterProps {
  children: ReactNode
}

export const DrawerFooter = ({ children }: DrawerFooterProps) => (
  <div className="sticky bottom-0 left-0 z-50 mt-auto flex justify-end border-t border-border-secondary bg-fg-secondary p-5">
    {children}
  </div>
)
