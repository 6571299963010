/**
 *
 * @param string
 */
export const getStringInitials = (string: string) => {
  return (
    string
      .match(/(\b\S)?/g)
      ?.join('')
      ?.match(/(^\S|\S$)?/g)
      ?.join('') || ''
  )
}
