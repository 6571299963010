import { Grid, InputLabel, TextField } from '@mui/material'

import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { InfoCircleIcon } from '../icons/Icons'
import { SxProps } from '@mui/system'
import { useState } from 'react'
import { InputBaseProps } from '@mui/material/InputBase/InputBase'

export interface ITextFieldProps {
  label?: string
  helperText?: string
  value: string | number | null
  type?: string
  margin?: boolean
  fullWidth?: boolean
  rows?: number
  maxRows?: number
  inputRef?: React.Ref<HTMLElement>
  onChange?: OutlinedInputProps['onChange']
  onBlur?: OutlinedInputProps['onBlur']
  onKeyUp?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  onEmpty?: () => void
  multiline?: boolean
  disabled?: boolean
  InputProps?: Partial<OutlinedInputProps>
  inputProps?: InputBaseProps['inputProps']
  min?: number
  max?: number
  placeholder?: string
  focused?: boolean
  errorText?: string
  sx?: SxProps<Theme>
  subLabel?: string
}

export function StyledInputLabel(props: { label: string; style?: React.CSSProperties; error?: boolean }) {
  const theme = useTheme()
  return (
    <InputLabel shrink={false}>
      <Typography
        variant="subtitle1"
        style={{
          marginBottom: theme.spacing(0.5),
          lineHeight: '16.94px',
          color: props.error ? theme.palette.error.dark2 : theme.palette.text.inputLabel,
          fontWeight: 500,
          ...props.style,
        }}
      >
        {props.label}
      </Typography>
    </InputLabel>
  )
}

export function ErrorMessage(props: { label: string; sx?: SxProps<Theme> }) {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        color: theme.palette.error.dark2,
        fontSize: '0.875rem',
        marginTop: theme.spacing(0.75),
        padding: theme.spacing(0.75),
        backgroundColor: theme.palette.error.light2,
        borderRadius: theme.spacing(0.75),
        ...props.sx,
      }}
    >
      <InfoCircleIcon />
      <span style={{ marginLeft: theme.spacing(1.5) }}>{props.label}</span>
    </Box>
  )
}

export default function StyledTextField(props: ITextFieldProps) {
  const theme = useTheme()
  const [empty, setEmpty] = useState(false)

  return (
    <Grid
      container
      sx={{
        marginTop: props.margin ? theme.spacing(2) : '',
        opacity: props.disabled ? 0.4 : 1,
        ...props.sx,
      }}
    >
      <Grid item xs={12}>
        {props.label && <StyledInputLabel label={props.label} error={!!props.errorText} />}
        <TextField
          type={props.type != 'number' ? props.type : 'text'}
          focused={props.focused}
          rows={props.rows}
          inputRef={props.inputRef}
          maxRows={props.maxRows}
          InputProps={props.InputProps}
          inputProps={props.inputProps}
          disabled={props.disabled}
          fullWidth={props.fullWidth}
          placeholder={props.placeholder}
          multiline={props.multiline}
          value={empty ? '' : props.value}
          error={!!props.errorText}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          variant="outlined"
          onBlur={props.onBlur}
          onKeyUp={props.onKeyUp}
          onChange={e => {
            if (props.type == 'number') {
              if (!e.target.value) {
                setEmpty(true)
                if (props.onEmpty) {
                  props.onEmpty()
                }
                return
              }
              let v = parseInt(e.target.value)
              if (e.target.value && isNaN(v)) {
                setEmpty(true)
                if (props.onEmpty) {
                  props.onEmpty()
                }
                return false
              }
              setEmpty(false)
              if (props.min != undefined) {
                if (v < props.min && e.target.value != '') {
                  return false
                }
              }
              if (props.max != undefined) {
                if (v > props.max && e.target.value != '') {
                  return false
                }
              }
            }
            if (props.onChange) {
              props.onChange(e)
            }
          }}
        />
        {props.subLabel && (
          <Typography
            color={props.errorText ? theme.palette.error.dark2 : theme.palette.text.tertiary}
            mt={theme.spacing(1)}
          >
            {props.subLabel}
          </Typography>
        )}
        {props.errorText && <ErrorMessage label={props.errorText} />}
      </Grid>
    </Grid>
  )
}
