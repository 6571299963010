import i18next from 'i18next'
import { CSSProperties } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@mui/material/styles'
import { WarningTooltip } from '../WarningTooltip/WarningTooltip'
import { AghanimTheme } from '../../../../types/theme'
import { IOHandleSize, getWarningLineColor } from '../../blocks/common'

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.palette.background.content};
  border: 2px solid ${p => p.theme.palette.primary.main};
  box-shadow: 1px 4px 9px 0 rgba(0, 0, 0, 0.09);
  border-radius: 64px;
  width: 69px;
  height: 44px;
  position: relative;
  z-index: 2;
  &:hover {
    z-index: 3;
  }
`

const Label = styled.span`
  color: #494459;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
const Warning = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${p => p.theme.palette.warning.light3};
  background-color: ${p => p.theme.palette.background.content};
  color: #af6705;
  font-size: 19px;
  font-weight: 600;
  position: absolute;
  right: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FakeUIHandle = styled.div`
  position: absolute;
  top: -11px;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none;
  border-radius: 16px 16px 0 0;
  background-color: transparent;
  height: 11px;
  width: ${p => p.theme.spacing(IOHandleSize)};
  border-top: 2px solid ${p => getWarningLineColor(p.theme as AghanimTheme)};
  border-left: 2px solid ${p => getWarningLineColor(p.theme as AghanimTheme)};
  border-right: 2px solid ${p => getWarningLineColor(p.theme as AghanimTheme)};
`

export function ExitNode(props: {
  style?: CSSProperties
  uiHandle?: boolean
  warningText?: string
  warningDescText?: string
}) {
  const theme = useTheme()

  return (
    <Root
      style={{
        ...props.style,
        borderColor: props.warningText ? theme.palette.warning.light3 : '',
      }}
    >
      <Label>{i18next.t('campaign.block.exit')}</Label>

      {props.warningText && (
        <>
          {props.uiHandle !== false && <FakeUIHandle />}
          <WarningTooltip message={props.warningText} desc={props.warningDescText!}>
            <Warning>
              <span>!</span>
            </Warning>
          </WarningTooltip>
        </>
      )}
    </Root>
  )
}
